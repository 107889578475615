import React from "react";
import { Button, Tag, Popover, Typography, Space, Modal } from "antd";
import { DownloadOutlined, DeleteOutlined } from "@ant-design/icons";
import DupliTextParent from "../../components/MyOrderActions/DupliTextParent";
import { useParams } from "react-router-dom";
import usePermissions from "../../hooks/usePermissions";
const { Text } = Typography;

const SolutionFilesMobile = ({
  finalFilesData,
  handleDelete,
  isModalVisible,
  setIsModalVisible,
  activeKey,
  similarityReportData,
  otherFilesData,
  handleSubmissionClicked,
}) => {
  const { hasPermission } = usePermissions();

  const { orderKey } = useParams();
  // Helper function to truncate file name for display
  const truncateText = (text, wordCount = 4) => {
    const words = text.split(" ");
    return words.length > wordCount
      ? words.slice(0, wordCount).join(" ") + "..."
      : text;
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          justifyContent: "center",
          width: "100%",
        }}>
        {hasPermission("can_create_duplitext_submissions") && (
          <Button
            style={{
              background: "#36c6d3",
              color: "white",
              border: "none",

              width: "max-content",
            }}
            onClick={handleSubmissionClicked}>
            Duplitext Submission
          </Button>
        )}

        {activeKey === "1" &&
          finalFilesData?.map((file, index) => (
            <div
              key={file.uid}
              style={{
                minWidth: "80%",
                background: "white",
                borderRadius: "8px",
                overflow: "hidden",
                border: "1px solid #e8e8e8",
              }}>
              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                }}>
                <tbody>
                  <tr>
                    <td
                      style={{
                        padding: "8px",
                        fontSize: "12px",
                        fontWeight: "bold",
                        backgroundColor: "#f5f5f5",
                        borderRight: "1px solid #e8e8e8",
                      }}>
                      File Name
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        fontSize: "12px",
                      }}>
                      {file.file_name && (
                        <Popover
                          content={<Text>{file.file_name}</Text>}
                          title="Full File Name"
                          trigger="click">
                          <Text>{truncateText(file.file_name)}</Text>
                        </Popover>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "8px",
                        fontSize: "12px",
                        fontWeight: "bold",
                        backgroundColor: "#f5f5f5",
                        borderRight: "1px solid #e8e8e8",
                      }}>
                      File Type
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        fontSize: "12px",
                      }}>
                      {file.file_type && <Tag>{file.file_type}</Tag>}
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* Action buttons (fixed at the bottom) */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  padding: "10px",
                  borderTop: "1px solid #e8e8e8",
                }}>
                <a
                  href={file.file_url}
                  download
                  target="_blank"
                  rel="noopener noreferrer">
                  <Button
                    style={{ background: "transparent" }}
                    type="primary"
                    icon={
                      <DownloadOutlined style={{ color: " #004DBC" }} />
                    }></Button>
                </a>
                <Button
                  type="danger"
                  icon={<DeleteOutlined />}
                  onClick={() =>
                    handleDelete(orderKey, [file.file_uid])
                  }></Button>
              </div>
            </div>
          ))}
        {activeKey === "2" &&
          similarityReportData?.map((file, index) => (
            <div
            key={file.uid}
              style={{
                minWidth: "80%",
                background: "white",
                borderRadius: "8px",
                overflow: "hidden",
                border: "1px solid #e8e8e8",
              }}>
              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                }}>
                <tbody>
                  <tr>
                    <td
                      style={{
                        padding: "8px",
                        fontSize: "12px",
                        fontWeight: "bold",
                        backgroundColor: "#f5f5f5",
                        borderRight: "1px solid #e8e8e8",
                      }}>
                      File Name
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        fontSize: "12px",
                      }}>
                      {file.file_name && (
                        <Popover
                          content={<Text>{file.file_name}</Text>}
                          title="Full File Name"
                          trigger="click">
                          <Text>{truncateText(file.file_name)}</Text>
                        </Popover>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "8px",
                        fontSize: "12px",
                        fontWeight: "bold",
                        backgroundColor: "#f5f5f5",
                        borderRight: "1px solid #e8e8e8",
                      }}>
                      File Type
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        fontSize: "12px",
                      }}>
                      {file.file_type && <Tag>{file.file_type}</Tag>}
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* Action buttons (fixed at the bottom) */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "10px",
                  borderTop: "1px solid #e8e8e8",
                }}>
                <a
                  href={file.file_url}
                  download
                  target="_blank"
                  rel="noopener noreferrer">
                  <Button
                    type="primary"
                    icon={<DownloadOutlined style={{ color: " #004DBC" }} />}
                    style={{ background: "transparent" }}></Button>
                </a>
                <Button
                  type="danger"
                  icon={<DeleteOutlined />}
                  onClick={() =>
                    handleDelete(orderKey, [file.file_uid])
                  }></Button>
              </div>
            </div>
          ))}
        {activeKey === "3" &&
          otherFilesData?.map((file, index) => (
            <div
            key={file.uid}
              style={{
                minWidth: "80%",
                background: "white",
                borderRadius: "8px",
                overflow: "hidden",
                border: "1px solid #e8e8e8",
              }}>
              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                }}>
                <tbody>
                  <tr>
                    <td
                      style={{
                        padding: "8px",
                        fontSize: "12px",
                        fontWeight: "bold",
                        backgroundColor: "#f5f5f5",
                        borderRight: "1px solid #e8e8e8",
                      }}>
                      File Name
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        fontSize: "12px",
                      }}>
                      {file.file_name && (
                        <Popover
                          content={<Text>{file.file_name}</Text>}
                          title="Full File Name"
                          trigger="click">
                          <Text>{truncateText(file.file_name)}</Text>
                        </Popover>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "8px",
                        fontSize: "12px",
                        fontWeight: "bold",
                        backgroundColor: "#f5f5f5",
                        borderRight: "1px solid #e8e8e8",
                      }}>
                      File Type
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        fontSize: "12px",
                      }}>
                      {file.file_type && <Tag>{file.file_type}</Tag>}
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* Action buttons (fixed at the bottom) */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "10px",
                  borderTop: "1px solid #e8e8e8",
                }}>
                <a
                  href={file.file_url}
                  download
                  target="_blank"
                  rel="noopener noreferrer">
                  <Button
                    style={{ background: "transparent" }}
                    type=""
                    icon={<DownloadOutlined style={{ color: "#004DBC" }} />}
                    // style={{ width: "45%" }}
                  ></Button>
                </a>
                <Button
                  type="danger"
                  icon={<DeleteOutlined />}
                  onClick={() =>
                    handleDelete(orderKey, [file.file_uid])
                  }></Button>
              </div>
            </div>
          ))}
      </div>

      {/* Modal for additional actions */}
      <Modal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}>
        <DupliTextParent />
      </Modal>
    </div>
  );
};

export default SolutionFilesMobile;
