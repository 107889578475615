import {
  HomeOutlined,
  TeamOutlined,
  ShoppingCartOutlined,
  WalletOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import usePermissions from "../hooks/usePermissions";
export const useFilteredNavMenu = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { hasPermission } = usePermissions();
  if (!user?.user?.permissions) {
    console.log("No permissions found.");
    return [];
  }

  if (hasPermission("can_view_move_to_fl_associate_orders")) {
    console.log("yesy it is");
  } else {
    console.log("NOOO09876543WQ");
  }
  const filteredNavMenu = [
    {
      key: "home",
      icon: <HomeOutlined />,
      label: "Home",
      route: "/",
      onClick: () => navigate("/"),
    },
    hasPermission("can_view_sales_operations") && {
      key: "sales-operations",
      icon: <SolutionOutlined />,
      label: "Sales Operations",
      children: [
        hasPermission("can_view_my_clients") && {
          key: "clients",
          label: "Clients",
          route: "/sales-operations/clients",
          onClick: () => navigate("/sales-operations/clients"),
        },
        hasPermission("can_view_assigned_leads") && {
          key: "leads",
          label: "Leads",
          route: "/sales-operations/leads",
          onClick: () => navigate("/sales-operations/leads"),
        },
        hasPermission("can_view_calling_and_sms") && {
          key: "client-connect",
          label: "Client Connect",
          route: "/sales-operations/client-connect",
          onClick: () => navigate("/sales-operations/client-connect"),
        },
        hasPermission("can_view_sales_and_analytics") && {
          key: "sales-analytics",
          label: "Sales Analytics",
          route: "/sales-operations/sales-analytics",
          onClick: () => navigate("/sales-operations/sales-analytics"),
        },
      ].filter(Boolean),
    },
    hasPermission("can_view_order_management") && {
      key: "order-management",
      icon: <ShoppingCartOutlined />,
      label: "Order Management",
      children: [
        hasPermission("can_view_order_list") && {
          key: "my-orders",
          label: "My Orders",
          route: "/order-management/my-orders",
          onClick: () => navigate("/order-management/my-orders"),
        },
        hasPermission("can_view_order_operations") && {
          key: "order-operations",
          label: "Order Operations",
          children: [
            hasPermission("can_view_my_call_allocation_tasks") && {
              key: "call-allocation",
              label: "Call Allocation",
              route: "/order-management/order-operations/call-allocation",
              onClick: () => navigate("/order-management/order-operations/call-allocation"),
            },
            hasPermission("can_view_my_subscriber_deductions") && {
              key: "deduction",
              label: "Deductions",
              route: "/order-management/order-operations/deductions",
              onClick: () => navigate("/order-management/order-operations/deductions"),
            },
            hasPermission("can_view_move_to_fl_associate_orders") && {
              key: "movetoflassociate",
              label: "Moved to FL Associates",
              route: "/order-management/order-operations/moved-to-fl-associates",
              onClick: () => navigate("/order-management/order-operations/moved-to-fl-associates"),
            },
          ].filter(Boolean), // Ensure children are not removed due to empty array
        },
      ].filter(Boolean),
    },
    hasPermission("can_view_payment_operations") && {
      key: "payment-operations",
      icon: <WalletOutlined />,
      label: "Payment Operations",
      children: [
        hasPermission("can_view_my_transactions") && {
          key: "transactions",
          label: "Transactions",
          route: "/payment-operations/transactions",
          onClick: () => navigate("/payment-operations/transactions"),
        },
        hasPermission("can_view_wallet") && {
          key: "wallet",
          label: "Wallet",
          route: "/payment-operations/wallet",
          onClick: () => navigate("/payment-operations/wallet"),
        },
      ].filter(Boolean),
    },
    {
      key: "team-management",
      icon: <TeamOutlined />,
      label: "Team Management",
      route: "/hierarchy-form",
      onClick: () => navigate("/hierarchy-form"),
    },
  ].filter(Boolean);
  
  
  return filteredNavMenu;
};
