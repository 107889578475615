import React, { useState, useEffect, useCallback } from "react";
import { Tabs, Select, Button, Typography, message } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import TransactionsTable from "../../page-components/PaymentAndOperations/TransactionsTable";
import PaymentOperationsFilters from "../../page-components/PaymentAndOperations/PaymentOperationsFilters";
import clientService from "../../services/clientService";

const { Option } = Select;
const { TabPane } = Tabs;
const { Paragraph } = Typography;

const PaymentOperations = () => {
  const [activeTab, setActiveTab] = useState("myTransactions");
  const [dropdownValue, setDropdownValue] = useState("myTransactions");
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const [filters, setFilters] = useState({});
  const [showFilters, setShowFilters] = useState(false);

  const fetchTransactions = useCallback(async () => {
    setLoading(true);
    const apiCall =
      dropdownValue === "allTransactions"
        ? clientService.getAllTransactions
        : clientService.getMyTransactions;

    const result = await apiCall(
      pagination.current,
      pagination.pageSize,
      filters
    );

    if (result.success) {
      // console.log(result , 'result')
      setTransactions(result?.data?.data?.results);
      setPagination({
        ...pagination,
        total: result?.data?.count || 0,
      });
    }
    setLoading(false);
  }, [dropdownValue, pagination.current, pagination.pageSize, filters]);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  const handleTabChange = (key) => {
    setActiveTab(key);
    setDropdownValue(
      key === "myTransactions" ? "allTransactions" : "allRefunds"
    );
    setPagination({ ...pagination, current: 1 });
  };

  const handleDropdownChange = (value) => {
    setDropdownValue(value);
    setPagination({ ...pagination, current: 1 });
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", padding: "20px" }}>
      <Tabs activeKey={activeTab} onChange={handleTabChange}>
        <TabPane tab="My Transactions" key="myTransactions" />
        <TabPane tab="My Refunds" key="myRefunds" />
      </Tabs>

      <div>
        {showFilters && <PaymentOperationsFilters />}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}>
          <Paragraph style={{ fontWeight: 500, opacity: 0.6 }}>
            Showing{" "}
            {Math.min(
              (pagination.current - 1) * pagination.pageSize + 1,
              pagination.total
            )}{" "}
            -{" "}
            {Math.min(
              pagination.current * pagination.pageSize,
              pagination.total
            )}{" "}
            of {pagination.total}
          </Paragraph>
          <div style={{ display: "flex", gap: "10px" }}>
            <Select
              value={dropdownValue}
              onChange={handleDropdownChange}
              // style={{ width: 200 }}
            >
              {activeTab === "myTransactions" ? (
                <>
                  <Option value="allTransactions">All Transactions</Option>
                  <Option value="myTransactions">My Transactions</Option>
                </>
              ) : (
                <>
                  <Option value="allRefunds">All Refunds</Option>
                  <Option value="myRefunds">My Refunds</Option>
                </>
              )}
            </Select>
            <Button
              type="primary"
              icon={<FilterOutlined />}
              onClick={toggleFilters}
            />
          </div>
        </div>

        <TransactionsTable
          transactions={transactions}
          loading={loading}
          pagination={pagination}
          setPagination={setPagination}
          dropdownValue={dropdownValue}
        />
      </div>
    </div>
  );
};

export default PaymentOperations;
