// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expanded-row {
  border: 2px solid #1890ff; /* Use your desired border color */
  background-color: rgb(241 245 249);
  transition: none!important; /* Ensure no transition/animation */
  
}



`, "",{"version":3,"sources":["webpack://./src/page-components/Leads/Table.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB,EAAE,kCAAkC;EAC7D,kCAAkC;EAClC,0BAA0B,EAAE,mCAAmC;;AAEjE","sourcesContent":[".expanded-row {\n  border: 2px solid #1890ff; /* Use your desired border color */\n  background-color: rgb(241 245 249);\n  transition: none!important; /* Ensure no transition/animation */\n  \n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
