import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "../pages/Dashboard/Dashboard";
import Login from "../pages/Login/Login";
import Signup from "../pages/Signup/Signup";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import ResetPasswordConfirm from "../pages/ResetPassword/ResetPasswordConfirm";
import OrderDetail from "../pages/OrderDetail/OrderDetail";
import Leads from "../pages/Leads/Leads";
import { useSelector } from "react-redux";
import AllClient from "../pages/All Clients/AllClient";
import Orders from "../pages/Orders/Orders";
import UnauthorizedPage from "../components/Error/UnauthorizedPage";
import NotFoundPage from "../components/Error/NotFoundPage";
import PermissionProtectedRoute from "./PermissionProtectedRoute"; // Import your component
import Home from "../pages/Home/Home";
import LeadsPage from "../pages/Leads/Leads";
import Notification from "../pages/Notifications/Notification";
import PaymentOperations from "../pages/PaymentOperations/PaymentOperations";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
export const AppRoutes = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { user, permissions } = useSelector((state) => state.auth);

  useEffect(() => {
    // Log the current state when fetching
    if (user?.access_token) {
      dispatch({ type: "FETCH_COMMON_INFO" });
    }
  }, [dispatch, location.pathname, user?.access_token]);

  return (
    <Routes>
      {/* Auth Routes */}
      <Route path="/login" element={<Login />} />
      <Route path="/sign-up" element={<Signup />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route
        path="/reset-password/confirm/:uid/:token"
        element={<ResetPasswordConfirm />}
      />

      {/* Protected Routes */}
      <Route element={<PermissionProtectedRoute permissionsRequired={[]} />}>
        <Route
          path="/sales-operations/sales-analytics"
          element={<Dashboard />}
        />
        <Route path="/" element={<Home />} />
        {/* Order Management Routes */}
        <Route path="/orders" element={<Orders />} />
        <Route path="/order-management/my-orders" element={<Orders />} />
        <Route
          path="/order-management/my-orders/:orderKey"
          element={<OrderDetail />}
        />

        {/* Leads Management Routes */}
        <Route path="/sales-operations/leads" element={<Leads />} />

        {/* Sales Operatiaons */}
        <Route path="/sales-operations/clients" element={<AllClient />} />
        {/* Payment Operations Here  */}
        <Route
          path="/payment-operations/transactions"
          element={<PaymentOperations />}
        />
        <Route path="/notifications" element={<Notification />} />
      </Route>

      {/* Error Handling */}
      <Route path="/unauthorized" element={<UnauthorizedPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
