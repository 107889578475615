import axiosInstance from "./axiosInstance";
import { catchError } from "../utils/errorHandler";
import { Device } from "@twilio/voice-sdk";
import store from "../redux/store";
import { startLoading, stopLoading } from "../redux/slices/loadingSlice";
const AllocationDataList = {

    fetchAllocationData: async (
        urgencyLevel,
        filters = {},
        page = 1,
        pageSize = 20,
        selectedOption
    ) => {
        try {
            store.dispatch(startLoading());
            //   if (filters.subscriber && typeof filters.subscriber === "string") {
            //     filters.subscriber = filters.subscriber.split(",");
            // }

            // console.log("filters inside ", filters)

            // Create query string with dynamic parameters
            const query = new URLSearchParams({
                page,
                page_size: pageSize,
                urgency_level: urgencyLevel, // Always include urgency_level
                status: filters.status,  // If status is undefined or empty, send an empty string
                ...(filters.subscriber && filters.subscriber.length > 0 && { subscriber: filters.subscriber.join(",") }), // Only include subscriber if it's not empty or undefined
                ...(filters.source && filters.source.length > 0 && { source: filters.source }), // Only include source if it's not empty or undefined
                ...(filters.visibility && filters.visibility.length > 0 && { visibility: filters.visibility }), // Only include visibility if it's not empty or undefined
                ...(filters.key && { key: filters.key }),
                ...(filters.start_date && { start_date: filters.start_date }),
                ...(filters.end_date && { end_date: filters.end_date }),
                ...(filters.client_deadline_start && { client_deadline_start: filters.client_deadline_start }),
                ...(filters.client_deadline_end && { client_deadline_end: filters.client_deadline_end }),
                ...(filters.order && { order: filters.order }),
                ...(filters.sort && { sort: filters.sort }),
            }).toString();

            console.log("query", query)
            // Make API request with query parameters

            const response = await axiosInstance.get(`task/v2/allocation-adjustment-tasks/?${query}`);

            store.dispatch(stopLoading());

            if (response.status === 200) {
                return { success: true, data: response.data };
            }

            return { success: false };
        } catch (error) {
            store.dispatch(stopLoading());
            await catchError(error);
            return { success: false };
        }
    },

    getSubscribersDropDown: async () => {
        try {
            store.dispatch(startLoading());
            const response = await axiosInstance.get(
                `task/v2/allocation-adjustment-tasks/subscriber/dropdown/`
            );
            store.dispatch(stopLoading());

            if (response.status === 200) {
                return { success: true, data: response.data };
            }
            return { success: false };
        } catch (error) {
            store.dispatch(stopLoading());
            await catchError(error);
            return { success: false };
        }
    },

    ownerDropdownList: async () => {
        try {
            store.dispatch(startLoading());
            const response = await axiosInstance.get(
                `task/v2/allocation-adjustment-tasks/owner/dropdown/`
            );
            store.dispatch(stopLoading());

            if (response.status === 200) {
                return { success: true, data: response.data };
            }
            return { success: false };
        } catch (error) {
            store.dispatch(stopLoading());
            await catchError(error);
            return { success: false };
        }
    },

}

export default AllocationDataList;