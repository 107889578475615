import React, { useState } from "react";
import {
  Card,
  Checkbox,
  Tag,
  Button,
  Tooltip,
  Typography,
  Space,
  Popover,
  Flex,
  Skeleton,
  Spin,
} from "antd";
import {
  EditOutlined,
  EyeFilled,
  FacebookFilled,
  LinkedinFilled,
} from "@ant-design/icons";
import usePermissions from "../../hooks/usePermissions";
import ManageLeadContent from "./ManageLeadContent";
import LeadsList from "../../services/LeadsList";
import moment from "moment";
const { Text } = Typography;

const LeadMobileView = ({
  data,

  loading,
  selectedUids,
  setSelectedUids,
  leadType,
  fetchData,
}) => {
  console.log(data, "data");
  const [loadingDetails, setLoadingDetails] = useState(false); // Track loading state for a specific record

  const { hasPermission } = usePermissions();
  const [expandedInfoKeys, setExpandedInfoKeys] = useState([]);
  const [leadDetails, setLeadDetails] = useState({});

  const [managedRowKey, setManagedRowKey] = useState(null);

  const onCheckboxChange = (uid) => {
    setSelectedUids((prevUids) =>
      prevUids.includes(uid)
        ? prevUids.filter((id) => id !== uid)
        : [...prevUids, uid]
    );
  };
  const handleInfoExpand = async (record) => {
    if (!record) return;

    // Check if we're already loading the details for this record
    if (loadingDetails === record.uid) return;

    // Call handleViewDetails with the record to fetch details
    await handleViewDetails(record);

    const newExpandedInfoKeys = expandedInfoKeys.includes(record.key)
      ? expandedInfoKeys.filter((key) => key !== record.key)
      : [record.key];
    setExpandedInfoKeys(newExpandedInfoKeys);
    setManagedRowKey(null);
  };
  const handleViewDetails = async (record) => {
    if (!record) return;

    // Set the loading state for the specific record
    setLoadingDetails(true);

    try {
      const result = await LeadsList.getLeadDetail(record.uid);
      if (result.success) {
        setLoadingDetails(false);

        // message.success("Data fetched successfully");
        setLeadDetails((prevDetails) => ({
          ...prevDetails,
          [record.uid]: result.data,
        }));
      } else {
        setLoadingDetails(false);

        // message.error("Failed to fetch lead details");
      }
    } catch (error) {
      setLoadingDetails(false);

      console.error(error);
      // message.error("An error occurred while fetching lead details");
    } finally {
      // Reset loading state after fetching
      setLoadingDetails(false);
    }
  };
  const handleManageExpand = (uid) => {
    setManagedRowKey(managedRowKey === uid ? null : uid);
    setExpandedInfoKeys((prevKeys) => prevKeys.filter((key) => key !== uid));
  };
  const expandedInfoContent = (details) => {
    const { social_links, tags, email, phone_no, assignee, type, author } =
      details;
    const socialLinks = social_links || {};
    const tagList = tags || [];
    console.log(details, ";details");
    // Prepare social links for rendering
    const socialLinkItems = [];
    if (socialLinks.Facebook) {
      socialLinkItems.push(
        <Tooltip title="Facebook">
          <a
            href={socialLinks.Facebook}
            target="_blank"
            rel="noopener noreferrer">
            <FacebookFilled style={{ fontSize: "20px" }} />
          </a>
        </Tooltip>
      );
    }
    if (socialLinks.LinkedIn) {
      socialLinkItems.push(
        <Tooltip title="LinkedIn">
          <a
            href={socialLinks.LinkedIn}
            target="_blank"
            rel="noopener noreferrer">
            <LinkedinFilled style={{ fontSize: "20px" }} />
          </a>
        </Tooltip>
      );
    }

    // Prepare tag items for rendering
    const tagItems = tagList.map((tag, index) => (
      <Tag color="geekblue" key={index}>
        {tag}
      </Tag>
    ));

    return (
      <div style={{ marginTop: "1px" }}>
        {loadingDetails ? (
          <div>
            <Spin />
          </div>
        ) : (
          <div>
            {renderRow("Email", email, "email")}
            {renderRow("Phone", phone_no, "phone")}
            {renderRow("Assignee", assignee, "assignee")}
            {renderRow("Type", type, "type")}
            {renderRow("Author", author, "author")}

            {/* Render Social Links Rows */}
            {socialLinkItems.length > 0 && (
              <div style={{ marginTop: "8px" }}>
                {renderRow("Social Links", socialLinkItems, "social-links")}
              </div>
            )}

            {/* Render Tags Rows */}
            {tagItems.length > 0 && (
              <div style={{ marginTop: "8px" }}>
                {renderRow("Tags", tagItems, "tags")}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderRow = (label, value, key, isNameRow = false, uid = null) => (
    <div
      key={key}
      style={{
        display: "flex",
        borderBottom: "1px solid #e8e8e8",
        alignItems: "center",
        minHeight: "44px",
        justifyContent: "space-between",
      }}>
      <div
        style={{
          width: "35%",
          padding: "8px",
          fontWeight: "bold",
          borderRight: "1px solid #e8e8e8",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}>
        {label}
      </div>
      <div
        style={{
          width: isNameRow ? "60%" : "65%", // Adjust width if it's the name row
          padding: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          textAlign: "left",
        }}>
        {value}
      </div>
      {isNameRow && (
        <div style={{ width: "5%", paddingRight: "8px" }}>
          <Checkbox
            checked={selectedUids.includes(uid)}
            onChange={() => onCheckboxChange(uid)}
          />
        </div>
      )}
    </div>
  );

  const renderValue = (record, field) => {
    switch (field) {
      case "name":
        return (
          <Flex vertical>
            <Text style={{ fontWeight: 600, wordBreak: "break-word" }}>
              {record.name}
            </Text>
            {record.assignedOn && (
              <div style={{ fontSize: "12px", color: "gray", opacity: 0.7 }}>
                {moment(record.assignedOn).local().format("DD-MM-YYYY hh:mm A")}
              </div>
            )}
          </Flex>
        );
      case "status":
        return <Tag color="blue">{record.status}</Tag>;
      case "activeTill":
        return (
          <Text>
            {moment(record.activeTill).local().format("DD-MM-YYYY hh:mm A")}
          </Text>
        );

      case "assignee":
        return <Text>{record.assignee}</Text>;
      case "manager":
        return <Text>{record.manager}</Text>;
      case "source":
        return <Tag color="blue">{record.source}</Tag>;
      case "next_activity":
        return (
          <Text>
            {moment(record.nextActivity).local().format("DD-MM-YYYY hh:mm A")}
          </Text>
        );
      default:
        return record[field];
    }
  };

  return (
    <div>
      {data.map((lead) => {
        const details = leadDetails[lead.uid]?.data || {};

        return (
          <Card
            key={lead.uid}
            style={{ marginBottom: "10px" }}
            actions={[
              hasPermission("can_view_lead_details") && (
                <EyeFilled
                  style={{ marginTop: "5px" }}
                  onClick={() => handleInfoExpand(lead)}
                /> // Pass the entire lead object
              ),
              hasPermission("can_manage_leads") && (
                <Button
                  type="link"
                  icon={<EditOutlined />}
                  onClick={() => handleManageExpand(lead.uid)} // Ensure you're using the uid for management
                />
              ),
            ]}>
            {renderRow(
              "Name",
              renderValue(lead, "name"),
              `${lead.uid}-name`,
              true,
              lead.uid
            )}
            {renderRow(
              "Status",
              renderValue(lead, "status"),
              `${lead.uid}-status`
            )}
            {renderRow(
              "Assignee",
              renderValue(lead, "assignee"),
              `${lead.uid}-assignee`
            )}
            {renderRow(
              "Manager",
              renderValue(lead, "manager"),
              `${lead.uid}-manager`
            )}
            {renderRow(
              "Active Till",
              renderValue(lead, "activeTill"), // Fixed reference
              `${lead.uid}-activeTill` // Corrected key format
            )}
            {renderRow(
              "Next Activity",
              renderValue(lead, "next_activity"), // Fixed reference
              `${lead.uid}-next_activity` // Corrected key format
            )}
            {renderRow(
              "Source",
              renderValue(lead, "source"), // Fixed reference
              `${lead.uid}-source` // Corrected key format
            )}
            {expandedInfoKeys.includes(lead.uid) &&
              expandedInfoContent(details)}
            {managedRowKey === lead.uid && (
              <ManageLeadContent
                record={lead}
                fetchData={fetchData}
                leadType={leadType}
              />
            )}
          </Card>
        );
      })}
    </div>
  );
};

export default LeadMobileView;
