import React, { useState } from "react";
import {
  Modal,
  Form,
  Select,
  DatePicker,
  Input,
  Button,
  message,
  Flex,
  Typography,
} from "antd";
import moment from "moment";
import AcceptRejectOrdersService from "../../services/acceptRejectOrdersService";
import dayjs from "dayjs";
const { Option } = Select;

const REJECTION_REASONS = [
  { value: "not_doable", label: "Not Doable" },
  { value: "deadline_required_until", label: "Deadline Require Until" },
  { value: "require_details", label: "Require Details" },
];

const RejectOrderModal = ({
  visible,
  onClose,
  selectedOrder,

  fetchOrders,
  setSelectedOrder,
  setRejectModalVisible,
}) => {
  const [form] = Form.useForm();
  const [selectedReason, setSelectedReason] = useState(null);
  const [possibilityMessage, setPossibilityMessage] = useState("");
  const [showAcceptButton, setShowAcceptButton] = useState(false);
  const [suggestedDeadline, setSuggestedDeadline] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [acceptingLoader, setAcceptingLoader] = useState(false);

  const confirmAccept = async () => {
    if (!selectedOrder) return;
    try {
      setAcceptingLoader(true);
      const formattedDeadline = moment(suggestedDeadline).format(
        "YYYY-MM-DDTHH:mm:ssZ"
      );
      const response = await AcceptRejectOrdersService.acceptOrder(
        selectedOrder?.key,
        { suggested_deadline: formattedDeadline }
      );
      if (response.success) {
        message.success("Order accepted successfully!");
        setAcceptingLoader(false);
        fetchOrders();
      }
    } catch (error) {
      setAcceptingLoader(false);

      message.error("Error accepting order.");
    } finally {
      setAcceptingLoader(false);
      setRejectModalVisible(false);
      // setConfirmAcceptModal(false);
      setSelectedOrder(null);
    }
  };
  const handleReject = async (values) => {
    setRejectLoading(true);

    try {
      if (selectedReason === "not_doable") {
        await AcceptRejectOrdersService.rejectOrder(selectedOrder?.key, {
          change_reason: values.change_reason,
        });
        message.success("Order rejected successfully.");
        await fetchOrders();
      } else if (selectedReason === "deadline_required_until") {
        const formattedDeadline = moment(values.suggested_deadline).format(
          "YYYY-MM-DDTHH:mm:ssZ"
        );
        await AcceptRejectOrdersService.rejectOrder(selectedOrder?.key, {
          change_reason: values.change_reason,
          suggested_deadline: formattedDeadline,
        });
        await fetchOrders();
        message.success("Order rejected successfully.");
      } else if (selectedReason === "require_details") {
        await AcceptRejectOrdersService.requireTaskDetails(
          selectedOrder?.key,
          values.details
        );
        await fetchOrders();
        message.success("Details requested successfully.");
      }

      // ✅ Reset form and states after successful submission
      form.resetFields();
      setSelectedReason(null);
      setPossibilityMessage("");
      setShowAcceptButton(false);
      setSuggestedDeadline(null);

      onClose();
    } catch (error) {
      message.error("An error occurred while processing the request.");
    } finally {
      setRejectLoading(false);
    }
  };

  const checkPossibility = () => {
    if (!suggestedDeadline) {
      message.warning("Please select a suggested deadline.");
      return;
    }

    setLoading(true); // Start loading

    const clientDeadline = moment.utc(selectedOrder.client_deadline).local(); // Convert to local
    const suggestedMoment = moment(suggestedDeadline).local(); // Ensure consistency

    setTimeout(() => {
      if (suggestedMoment.isSameOrBefore(clientDeadline, "minute")) {
        setPossibilityMessage(
          "Selected deadline accepted. Ensure timely completion. Do not delay beyond this date."
        );
        setShowAcceptButton(true);
      } else {
        setPossibilityMessage(
          "Cannot proceed. Suggested deadline must be on or before the client deadline."
        );
        setShowAcceptButton(false);
      }
      setLoading(false); // Stop loading after check
    }, 1000);
  };

  return (
    <Modal
      title={
        <div
          className="custom-modal-header"
          style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography.Title
            level={3}
            style={{
              color: "white",
              margin: 0,
              position: "relative",
              left: "12px",
            }}>
            Reject Order
          </Typography.Title>
          {/* <CloseOutlined
              style={{ fontSize: "16px", marginRight: "10px" }}
              onClick={onClose}
            /> */}
        </div>
      }
      className="create-order"
      visible={visible}
      onCancel={() => {
        form.resetFields();
        onClose();
        setSelectedReason(null); // Reset selected reason when modal closes

        setSuggestedDeadline(null);
        setPossibilityMessage("");
        setShowAcceptButton(false);
      }}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,

        <Button
          key="submit"
          loading={rejectLoading}
          onClick={() => form.submit()}
          style={{ background: "#E02424", color: "white" }}>
          Reject
        </Button>,
      ]}>
      <Form form={form} layout="vertical" onFinish={handleReject}>
        <Form.Item
          label="Rejection Reason"
          name="change_reason"
          rules={[{ required: true, message: "Please select a reason!" }]}>
          <Select
            onChange={(value) => setSelectedReason(value)}
            placeholder="Select Reason">
            {REJECTION_REASONS.map((reason) => (
              <Option key={reason.value} value={reason.value}>
                {reason.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {selectedReason === "deadline_required_until" && (
          <>
            <Flex
              align="center"
              justify="space-between"
              style={{ width: "100%" }}>
              <Form.Item
                style={{ width: "60%" }}
                label="Suggested Deadline"
                name="suggested_deadline"
                rules={[
                  { required: true, message: "Please select a deadline!" },
                ]}>
                <DatePicker
                  style={{ width: "100%" }}
                  showTime
                  onChange={(date) => {
                    if (date) {
                      const localDate = dayjs(date).format("YYYY-MM-DD");

                      setSuggestedDeadline(localDate);
                      setPossibilityMessage(""); // Hide previous message on new selection
                      setShowAcceptButton(false);
                    }
                  }}
                />
              </Form.Item>
              <Button onClick={checkPossibility} loading={loading}>
                Check Possibility
              </Button>
            </Flex>

            <Flex
              align="flex-end"
              justify="flex-end"
              vertical
              style={{
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Soft shadow
                border: "1px solid #D1D5DB", // Grey border
                padding: "10px", // Optional: Add padding for better appearance
                borderRadius: "5px", // Optional: Rounded corners
                backgroundColor: "#fff", // Optional: White background for better visibility
              }}>
              {possibilityMessage && (
                <p style={{ color: "green" }}>{possibilityMessage}</p>
              )}

              {showAcceptButton && (
                <Button
                  key="accept"
                  style={{ background: "#22c55e", color: "white" }}
                  onClick={() => confirmAccept(selectedOrder?.key)}
                  loading={acceptingLoader}>
                  Accept
                </Button>
              )}
            </Flex>
          </>
        )}

        {selectedReason === "require_details" && (
          <Form.Item
            label="Please write details"
            name="details"
            rules={[{ required: true, message: "Please enter details!" }]}>
            <Input.TextArea rows={3} />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default RejectOrderModal;
