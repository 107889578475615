import React from 'react';

const UnauthorizedPage = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>403 Forbidden</h1>
      <p>Sorry, you don't have permission to access this page.</p>
    </div>
  );
};

export default UnauthorizedPage;
