import React, { useState, useEffect } from "react";
import { Flex, Col, Spin } from "antd";
import usePermissions from "../../hooks/usePermissions";
import orderService from "../../services/OrderLists";

import ClientComments from "./CommentComp/ClientComments";
import FreelancerComments from "./CommentComp/FreelancerComments";
import InternalComments from "./CommentComp/InternalComments";

const CommentsSection = ({
  orderData,
  loading,
  handleTabChange,
  moveToPortal,
  setOrderData,
}) => {
  const { hasPermission } = usePermissions();
  const [userTypes, setUserTypes] = useState([]);

  useEffect(() => {
    const fetchUserTypes = async () => {
      const types = await orderService.getUserTypes();
      setUserTypes(types);
    };
    fetchUserTypes();
  }, []);

  if (loading) return <Spin size="large" />;

  // Determine which components are visible
  const isInternalVisible = hasPermission("can_view_internal_comments");
  const isClientVisible = hasPermission("can_view_client_comments");
  const isFreelancerVisible =
    hasPermission("can_view_freelancer_comments") && moveToPortal;

  // Count the number of visible components
  const visibleCount = [
    isInternalVisible,
    isClientVisible,
    isFreelancerVisible,
  ].filter(Boolean).length;

  // Calculate the width for each column


  return (
    <Flex
      style={{ padding: "10px", width: "100%", flexWrap: "wrap" }}
      align="flex-start"
      flexWrap="wrap">
      {isInternalVisible && (
        <Col sm={24} md={visibleCount === 3 ? 8 : 12} >
          <InternalComments
            internalComments={orderData.internal_comments || []}
            handleTabChange={handleTabChange}
            userTypes={userTypes}
            setOrderData={setOrderData}
            moveToPortal={moveToPortal}
          />
        </Col>
      )}
      {isClientVisible && (
        <Col sm={24} md={visibleCount === 3 ? 8 : 12}>
          <ClientComments
            clientComments={orderData.client_comments || []}
            handleTabChange={handleTabChange}
            userTypes={userTypes}
            setOrderData={setOrderData}
            moveToPortal={moveToPortal}
          />
        </Col>
      )}

      {isFreelancerVisible && (
        <Col sm={24} md={visibleCount === 3 ? 8 : 12}>
          <FreelancerComments
            freelancerComments={orderData.fl_comments || []}
            handleTabChange={handleTabChange}
            userTypes={userTypes}
            setOrderData={setOrderData}
            moveToPortal={moveToPortal}
          />
        </Col>
      )}
    </Flex>
  );
};

export default CommentsSection;
