import React from "react";
import { Table, Collapse, Button, Modal } from "antd";



const SubscriberInfoPanel = ({ subscriberInfo, isModalVisible, handleViewMore, handleModalClose }) => {
    const { Panel } = Collapse;
   
   
    const subscriberColumns = [
        {
          title: 'User Type',
          dataIndex: 'user_type',
          key: 'user_type',
        },
        {
          title: 'Word Count',
          dataIndex: 'no_of_word',
          key: 'no_of_word',
        },
      ];
      const subscriberArray = subscriberInfo?.data || [];
      const visibleSubscriberInfo = subscriberArray.slice(0, 4);
      const hiddenSubscriberInfo = subscriberArray.slice(4);
    
  return (
    <>

        <Table
          columns={subscriberColumns}
          dataSource={visibleSubscriberInfo}
          pagination={false}
          bordered
          size="small"
          style={{ width: "100%" }}
        />
        {hiddenSubscriberInfo.length > 0 && (
          <Button type="link" onClick={handleViewMore}>View More</Button>
        )}

      <Modal
        title="Subscriber Information"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        width={800}
      >
        <Table
          columns={subscriberColumns}
          dataSource={subscriberArray}
          pagination={false}
          bordered
          size="small"
        />
      </Modal>
    </>
  );
};

export default SubscriberInfoPanel