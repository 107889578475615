import React from "react";
import Marquee from "react-fast-marquee";
import { Button } from "antd";
import { BulbFilled } from "@ant-design/icons";

const CarouselButton = ({ onClick }) => {
  return (
    <div style={{ width: "100%", overflow: "hidden" }}>
      <Marquee speed={50} gradient={false}>
        {[...Array(6)].map((_, index) => (
          <Button
            key={index}
            type="primary"
            icon={<BulbFilled style={{ color: "#FF991F" }} />}
            style={{
              color: "#FF991F",
              background: "transparent",
              border: "none",
              margin: "0 ", // Space between buttons
              fontWeight: 600,
            }}
            onClick={onClick}>
            Check enabled countries before making a call. Click to view.
          </Button>
        ))}
      </Marquee>
    </div>
  );
};

export default CarouselButton;
