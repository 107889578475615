// import axios from "axios";
// import { logout } from "../redux/slices/authSlice";
// import store from "../redux/store";
// import { message } from "antd";

// const axiosInstance = axios.create({
//   baseURL: process.env.REACT_APP_API_URL,
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

// axiosInstance.interceptors.request.use(
//   (config) => {
//     const state = store.getState();
//     const user = state.auth.user;
//     if (user && user.access_token) {
//       config.headers.Authorization = `Bearer ${user.access_token}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// axiosInstance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     if (error.response.status === 401) {

//       message.error(error?.response?.data?.message)
//       // store.dispatch(logout());
//     }
//     return Promise.reject(error);
//   }
// );

// export default axiosInstance;
import axios from "axios";
import { logout } from "../redux/slices/authSlice";
import store from "../redux/store";
import { message } from "antd";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const user = state.auth.user;
    if (user && user.access_token) {
      config.headers.Authorization = `Bearer ${user.access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      const errorData = error.response.data;

      // Check if the error code is token_not_valid
      if (errorData.code === "token_not_valid") {
        message.error("Session expired. Please log in again.");
        store.dispatch(logout()); // Dispatch logout if token is invalid
      } else {
        message.error(errorData.message || "Unauthorized request.");
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
