import React, { useState } from "react";
import { List, Tag, Button, Checkbox, Pagination } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import { Flex } from "antd";
import { Circle, Dot } from "lucide-react";
import { useMediaQuery } from "react-responsive";

import moment from "moment";
const NotificationList = ({
  notifications,
  onMarkAsRead,
  onNotificationClick,
  pagination,
  setPagination,
}) => {
  const [expandedItems, setExpandedItems] = useState({});
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const toggleExpand = (uid) => {
    setExpandedItems((prev) => ({
      ...prev,
      [uid]: !prev[uid],
    }));
  };

  const onPageChange = (page, pageSize) => {
    setPagination((prev) => ({
      ...prev,
      current: page,
      pageSize: pageSize,
    }));
  };

  const renderPreview = (preview, uid) => {
    if (!preview) {
      return null; // or a fallback UI like <div>No preview available</div>
    }
    const words = preview.split(" ");
    const isExpanded = expandedItems[uid];
    const shouldCollapse = words.length > 10;

    if (shouldCollapse && !isExpanded) {
      return (
        <>
          "{words.slice(0, 10).join(" ")}..."
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={(e) => {
              e.stopPropagation();
              toggleExpand(uid);
            }}>
            Show More
          </Button>
        </>
      );
    }

    return (
      <>
        "{preview}"
        {shouldCollapse && (
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={(e) => {
              e.stopPropagation();
              toggleExpand(uid);
            }}>
            Show Less
          </Button>
        )}
      </>
    );
  };

  return (
    <Flex vertical style={{ width: "100%" }} align="flex-end">
      <List
        style={{ width: "100%", background: "transparent", height: "100%" }}
        dataSource={notifications}
        renderItem={(item) => (
          <List.Item
            style={{
              borderBottom: "1px solid #D1D5DB",
              borderRadius: 4,
              padding: isMobile ? 0 : 16,
              marginBottom: 12,
              cursor: "pointer",
            }}
            onClick={() => onNotificationClick(item)}>
            <Flex
              justify="space-between"
              align={isMobile ? "flex-end" : "center"}
              dir={isMobile ? "flex-column" : "flex-row"}
              style={{
                width: "100%",
                flexDirection: isMobile ? "column" : "row",
              }}>
              <Flex
                style={{ width: isMobile ? "100%" : "80%" }}
                align="flex-start">
                <Flex
                  style={{ maxWidth: isMobile ? "100%" : "80%" }}
                  vertical
                  align="flex-start">
                  <Flex align="flex-start">
                    <div
                      style={{ color: "#595959", margin: 0, padding: 0 }}
                      dangerouslySetInnerHTML={{ __html: item.verb }}></div>
                  </Flex>

                  <Flex align={expandedItems[item.uid] ? "flex-start" : "center"} gap={10}>
                    <p
                      style={{
                        color: "#6B7280",
                        margin: 0,
                        padding: 0,
                        textAlign: "left",
                      }}>
                      {item?.data?.preview
                        ? renderPreview(item?.data?.preview, item.uid)
                        : null}
                    </p>
                    <p
                      style={{
                        color: "#6B7280",
                        margin: 0,
                        padding: 0,
                        textAlign: "left",
                        width: "max-content",

                        whiteSpace: "nowrap",
                      }}>
                      {item?.data?.file_count
                        ? `Files Attached: ${item?.data?.file_count} `
                        : null}
                    </p>
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                style={{
                  height: "max-content",
                  flexDirection: isMobile ? "row-reverse" : "column",
                }}
                align={isMobile ? "center" : "flex-end"}
                gap={isMobile ? 10 : 0}
                justify="flex-end">
                {item?.data?.type ? (
                  <Tag color="#040724" style={{ width: "max-content" }}>
                    {item.data.type}
                  </Tag>
                ) : null}
                <Flex align="center">
                  {item.unread && <Dot color="#1C64F2" size={40} />}

                  <p
                    style={{
                      color: "#8c8c8c",
                      fontSize: 12,
                      margin: 0,
                      padding: 0,
                    }}>
                    <ClockCircleOutlined />{" "}
                    {moment(item.timestamp)
                      .local()
                      .format("DD-MM-YYYY hh:mm a")}
                  </p>
                </Flex>
              </Flex>
            </Flex>
          </List.Item>
        )}
      />
      <Pagination
        current={pagination.current}
        pageSize={pagination.pageSize}
        total={pagination.total}
        onChange={onPageChange}
        style={{ marginTop: 20, textAlign: "center" }}
      />
    </Flex>
  );
};

export default NotificationList;