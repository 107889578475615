import React, { useEffect, useState } from 'react';
import axiosInstance from '../../services/axiosInstance';
import { useParams } from 'react-router-dom';
import { Empty, Table, Spin } from 'antd';
import { useMediaQuery } from 'react-responsive';
import SpinLoading from './SpinLoading';
import moment from 'moment';

const MobileDeductionListLogs = ({ data }) => {


    const transformDataForTable = (item) => [
        { key: 'Subscriber', value: item.subscriber || 'N/A' },
        { key: 'Word Count Deducted', value: item.word === null ? 0 : item.word },
        { key: 'Remaining Words', value: item.remaining_word || 'N/A' },
        { key: 'Added By', value: item.author || 'N/A' },
        { key: 'Added On', value: item.added_on ? moment(item.added_on).local().format("DD-MM-YYYY hh:mm A") : 'N/A', },
    ];

    const columns = [
        {
            dataIndex: 'key',
            key: 'key',
            render: (text) => <strong>{text}</strong>,
        },
        {
            dataIndex: 'value',
            key: 'value',
            render: (text) => text,
        },
    ];

    return (
        <div style={{ backgroundColor: "#f2f6f9", padding: '10px' }}>
            {data && data.length > 0 ? (
                data.map((item, index) => (
                    <Table
                        key={index}
                        columns={columns}
                        dataSource={transformDataForTable(item)}
                        pagination={false}
                        bordered
                        size="small"
                        style={{ marginBottom: '25px', borderRadius: '5px' }}
                        showHeader={false}
                    />
                ))
            ) : (
                <Empty description="No data available" />
            )}
        </div>
    );
};


const SubscriberDeductionsList = () => {
    const { orderKey } = useParams();
    const [loading, setLoading] = useState(false);
    const [deductionList, setDeductionsList] = useState([]);
    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

    const fetchDeductionsList = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get(`task/v2/task-subscribers-deduction-list/${orderKey}`);
            if (response.status === 200 && response.data.status === "success") {
                setDeductionsList(response.data.data);
            }
        } catch (err) {
            console.error("Error fetching deductions list", err);
            setDeductionsList([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDeductionsList();
    }, [orderKey]);

    const columns = [
        {
            title: 'Subscriber',
            dataIndex: 'subscriber',
            key: 'subscriber',
        },
        {
            title: 'Word Count Deducted',
            dataIndex: 'word',
            key: 'word',
            render: (word) => word === null ? 0 : word
        },
        {
            title: 'Remaining Words',
            dataIndex: 'remaining_word',
            key: 'remaining_word',
        },

        {
            title: 'Added By',
            dataIndex: 'author',
            key: 'author',
        },
        {
            title: 'Added On',
            dataIndex: 'added_on',
            key: 'added_on',
            render: (date) => date ? moment(date).local().format("DD-MM-YYYY hh:mm A") : 'N/A',
        },

    ];

    return (
        <div>
            <h3>Subscriber Deductions List - {orderKey}</h3>
            {loading ? (
                <SpinLoading />
            ) : isMobile ? (
                <MobileDeductionListLogs data={deductionList} />
            ) : (
                <Table
                    columns={columns}
                    dataSource={deductionList}
                    rowKey={(record) => record.uid}
                    pagination={false}
                />
            )}
        </div>
    );
};

export default SubscriberDeductionsList;
