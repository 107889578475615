import axiosInstance from "./axiosInstance";
import { catchError } from "../utils/errorHandler";

const salesService = {
  fetchCurrencyDeals: async (startDate, endDate, custom, uids) => {
    try {
      const response = await axiosInstance.post('/analytics/total-sales-volume/', {
        start_date: startDate,
        end_date: endDate,
        custom: custom,
        uuids: uids,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer YOUR_TOKEN_HERE' // Replace with your actual token
        }
      });
      if (response.status === 200) {
        return { success: true, data: response.data.data  };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  fetchCurrencyDealsRegionwise: async (startDate, endDate, custom, uids) => {
    try {
      const response = await axiosInstance.post('/analytics/total-sales-volume/?monthwiseregion=yes', {
        start_date: startDate,
        end_date: endDate,
        custom: custom,
        uuids: uids,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer YOUR_TOKEN_HERE' // Replace with your actual token
        }
      });
      if (response.status === 200) {
        return { success: true, data: response.data.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  }
};

export default salesService;
