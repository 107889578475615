import { useState, useEffect } from 'react';
import moment from 'moment';

export const useLocalTime = (utcDate) => {
  const [localTime, setLocalTime] = useState('');

  useEffect(() => {
    if (!utcDate) return; // Handle case when utcDate is undefined or null

    // Convert UTC to local time
    const local = moment(utcDate).local();
    
    // Format the date - you can customize this format
    setLocalTime(local.format('DD-MM-YYYY hh:mm A'));
  }, [utcDate]); // Re-run when utcDate changes

  return localTime;
};
