import React, { useEffect, useState } from 'react';
import { Form, Select, Button, message, Spin } from 'antd';
import axiosInstance from '../../services/axiosInstance';
import { catchError } from '../../utils/errorHandler';
import { useParams } from 'react-router-dom';

const EditInternalTaskStatus = ({ orderData, fetchOrderDetails, handleModalClose }) => {
    const [submitLoading, setSubmitLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [statusOptions, setStatusOptions] = useState([]);
    const { orderKey } = useParams()

    const fetchStatusOptions = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get(`/task/v2/internal-status/`);
            if (response.status === 200 && response.data.status === 'success') {
                setStatusOptions(response.data.data);
            }
        } catch (err) {
            console.error("Error in fetching the status options", err);
        } finally {
            setLoading(false);
        }
    };
    const initialValues = {
        status: orderData?.data?.internal_status || null,
    };
    useEffect(() => {
        fetchStatusOptions();
    }, []);

    const handleSubmit = async (values) => {
        setSubmitLoading(true);

        const payload = {
            internal_status_uid: values.status,
        };

        try {
            const response = await axiosInstance.put(`/task/v2/add-internal-status/${orderKey}/`, payload);

            if (response.status === 200 && response.data.status === 'success') {
                message.success(response.data.data || 'Status updated successfully');
                fetchOrderDetails();
                handleModalClose();
            } else {
                message.error('Failed to update status');
            }

        } catch (error) {
            await catchError(error);
        } finally {
            setSubmitLoading(false);
        }
    };

    if (loading) {
        return <Spin tip="Loading status options..." style={{ display: 'block', marginTop: '20%' }} />;
    }

    return (
        <Form
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={initialValues}
        >
            <Form.Item
                style={{ width: "100%" }}
                name="status"
                label="Internal Status"
                rules={[{ required: true, message: 'Please select a status!' }]}
            >
                <Select
                    placeholder="Select status"
                    options={statusOptions.map(option => ({
                        value: option.uid,
                        label: option.name
                    }))}
                />
            </Form.Item>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20, width: "100%" }}>
                {submitLoading ? (
                    <Spin />
                ) : (
                    <Button type="primary" htmlType="submit" loading={submitLoading}>
                        Submit
                    </Button>
                )}
            </div>
        </Form>
    );
};

export default EditInternalTaskStatus;
