import axiosInstance from "./axiosInstance";
import { catchError } from "../utils/errorHandler";
import { Device } from "@twilio/voice-sdk";
import { message } from "antd";
const OrderProgressTraclingService = {
  fetchOrderProgresTrackingList: async (page, pageSize, filters = {}) => {
    const { key, status, hierarchy, started_on } = filters;
    const query = new URLSearchParams({
      page,
      page_size: pageSize,
      status: status || "started",
      ...(key && { key }),

      ...(hierarchy && { hierarchy }),
      ...(started_on && { started_on }),
    }).toString();

    try {
      const response = await axiosInstance.get(
        `task/v2/order/progress-tracking-list/?${query}`
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  getOrderTrackingHierarchy: async () => {
    try {
      const response = await axiosInstance.get(
        `task/v2/order/progress-tracking-hierarchy/`
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
};

export default OrderProgressTraclingService;
