import React, { useState, useEffect } from "react";
import { Input, Button, Select, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import useIsMobile from "../../hooks/useIsMobile";
import { DatePicker } from "antd";
const { Option } = Select;

const FlOrderDeductionsFilter = ({
  onSearch,
  pagination,
  onCancel,
  filtersList,
  typeChoices,
  statusList,
  flList,
}) => {
  const { isMobile } = useIsMobile();
  const currentMonth = dayjs().format("MM");
  const currentYear = dayjs().format("YYYY");

  const [filters, setFilters] = useState({
    month: currentMonth,
    year: currentYear,
  });

  const handleSearch = () => {
    const formattedClientDeadline = filters.client_deadline
      ? dayjs(filters.client_deadline).isValid()
        ? dayjs(filters.client_deadline).format("YYYY-MM-DD")
        : null
      : null;

    const formattedFlDeadline = filters.fl_deadline
      ? dayjs(filters.fl_deadline).isValid()
        ? dayjs(filters.fl_deadline).format("YYYY-MM-DD")
        : null
      : null;

    const updatedFilters = { ...filters };

    // Apply formatted dates if valid, otherwise remove them
    if (formattedClientDeadline) {
      updatedFilters.client_deadline = formattedClientDeadline;
    } else {
      delete updatedFilters.client_deadline;
    }

    if (formattedFlDeadline) {
      updatedFilters.fl_deadline = formattedFlDeadline;
    } else {
      delete updatedFilters.fl_deadline;
    }

    // Call the search function with updated filters
    onSearch(updatedFilters);
  };

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, [key]: value };

      if (!value) {
        delete updatedFilters[key]; // ✅ Remove key if value is cleared
      }

      return updatedFilters;
    });
  };

  const handleCancel = async () => {
    setFilters({ month: currentMonth, year: currentYear }); // ✅ Reset to current month & year
    await onCancel();
  };
  const formatPlaceholder = (key) => {
    return key
      .replace(/_/g, " ") // Remove underscores
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter
  };

  const renderFilter = (filter) => {
    switch (filter) {
      case "fl_deadline":
        return (
          <DatePicker
            style={{ width: isMobile ? "80vw" : 300 }}
            placeholder="Filter by FL Deadline"
            value={filters.fl_deadline ? dayjs(filters.fl_deadline) : null}
            onChange={(value) => handleFilterChange("fl_deadline", value)}
            allowClear
          />
        );

      case "month":
        return (
          <Select
            placeholder="Select Month"
            style={{ width: isMobile ? "80vw" : 300, textAlign: "left" }}
            value={filters.month}
            onChange={(value) => handleFilterChange("month", value)}
            allowClear>
            {[
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ].map((month, index) => (
              <Select.Option
                key={index + 1}
                value={String(index + 1).padStart(2, "0")}>
                {month}
              </Select.Option>
            ))}
          </Select>
        );

      case "year":
        return (
          <Select
            placeholder="Select Year"
            style={{ width: isMobile ? "80vw" : 300, textAlign: "left" }}
            value={filters.year}
            onChange={(value) => handleFilterChange("year", value)}
            allowClear>
            {[...Array(11)].map((_, i) => (
              <Select.Option
                key={currentYear - 5 + i}
                value={String(currentYear - 5 + i)}>
                {currentYear - 5 + i}
              </Select.Option>
            ))}
          </Select>
        );

      default:
        return (
          <Input
            placeholder={
              filter === "fl_name" ? "Filter by FL Name" : `Filter by ${filter}`
            }
            style={{ width: 300 }}
            value={filters[filter] || ""}
            onChange={(e) => handleFilterChange(filter, e.target.value)}
          />
        );
    }
  };

  const isWebsiteFilterPresent = filtersList.includes("website");

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{ background: "#eceff1", padding: "20px", borderRadius: "8px" }}>
        <div
          style={{
            marginBottom: 16,
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}>
          {filtersList.map((filter) => (
            <div key={filter} style={{ marginRight: 16 }}>
              {renderFilter(filter)}
            </div>
          ))}
          <div
            style={{
              display: "flex",
              gap: "20px",
              marginRight: isMobile ? 0 : "12px",
              justifyContent: isWebsiteFilterPresent
                ? "flex-end"
                : "space-between",
              width: isWebsiteFilterPresent ? "100%" : "auto",
            }}>
            <Button
              type="primary"
              style={{ width: 140, background: "#040724" }}
              icon={<SearchOutlined />}
              onClick={handleSearch}>
              Search
            </Button>
            <Button
              style={{ width: 140 }}
              type="default"
              onClick={handleCancel}>
              Reset
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlOrderDeductionsFilter;
