import React from "react";
import { Card, Descriptions, Tag, Typography ,Pagination,Flex} from "antd";
import { Link } from "react-router-dom";
import moment from "moment";

const { Paragraph } = Typography;

const MobileViewDeductions = ({ deductions,pagination ,setPagination}) => {
  return (
    <Flex vertical style={{ padding: "10px" }}>
      {deductions.map((deduction) => (
        <Card
          key={deduction.key}
          style={{ marginBottom: "16px", borderRadius: "8px" }}
       >
          <Descriptions column={1} bordered>
          <Descriptions.Item label="Key">
              <Link
                to={`/order-management/my-orders/${deduction.key}`}
                style={{ color: "#1890ff" }}
              >
                {deduction.key}
              </Link>
            </Descriptions.Item>

            {Object.entries(deduction).map(([key, value]) => {
              if (["uid", "id", "added_on","key"].includes(key)) return null; // Skip unwanted keys

              let displayValue = value;

              if (key === "key") {
                displayValue = (
                  <Link
                    to={`/order-management/my-orders/${deduction.key}`}
                    style={{ color: "#1890ff" }}>
                    {deduction.key}
                  </Link>
                );
              } else if (key === "remaining_words") {
                displayValue = <Tag color="purple">{value}</Tag>;
              } else if (key === "deducted_word") {
                displayValue = <Tag color="red">{value}</Tag>;
              } else if (key === "added_by" && deduction.added_on) {
                displayValue = (
                  <div>
                    <div>{value}</div>
                    <div style={{ fontSize: "12px", color: "#888" }}>
                      {moment(deduction.added_on)
                        .local()
                        .format("DD-MM-YYYY hh:mm a")}
                    </div>
                  </div>
                );
              } else if (value === null || value === undefined) {
                displayValue = "N/A";
              }

              return (
                <Descriptions.Item
                  label={key
                    .replace(/_/g, " ")
                    .replace(/\b\w/g, (char) => char.toUpperCase())}
                  key={key}>
                  {displayValue}
                </Descriptions.Item>
              );
            })}
          </Descriptions>
        </Card>
      ))}
        <Pagination
        current={pagination.current}
        pageSize={pagination.pageSize}
        total={pagination.total}
        onChange={(page, pageSize) =>
          setPagination((prev) => ({ ...prev, current: page, pageSize }))
        }
        style={{ textAlign: "center", marginTop: 16 }}
      />
    </Flex>
  );
};

export default MobileViewDeductions;
