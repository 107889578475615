import React, { useEffect, useState } from "react";
import {
  Table,
  Empty,
  Tag,
  Typography,
  Spin,
  Checkbox,
  Input,
  Button,
  message,
  Flex,
} from "antd";
import "./Details.css";
import orderService from "../../services/OrderLists";
import { useParams } from "react-router-dom";
import moment from "moment";
const { TextArea } = Input;

const SendFilesToClient = () => {
  const { orderKey } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [solutionFiles, setSolutionFiles] = useState({
    finalFilesData: [],
    otherFilesData: [],
  });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [comment, setComment] = useState(""); // State to hold the comment

  useEffect(() => {
    getSolutionFiles();
  }, []);

  const getSolutionFiles = async () => {
    try {
      setIsLoading(true);
      const result = await orderService.getSolutionFiles(orderKey);
      if (result.success && result.data.status === "success") {
        setIsLoading(false);
        const { final_files, other_files } = result.data.data;

        // Initialize with final files data
        const finalizedFiles = final_files || [];

        // Only set final files as selected by default
        const selectedFileIds = finalizedFiles.map((file) => file.file_uid);

        setSolutionFiles({
          finalFilesData: finalizedFiles,
          otherFilesData: other_files || [],
        });
        setSelectedFiles(selectedFileIds);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const handleFileSelection = (file, isFinalFile) => {
    const { file_uid } = file;

    if (isFinalFile) {
      // Handle selection/deselection of files in Final Files table
      if (selectedFiles.includes(file_uid)) {
        // Uncheck individual file
        setSelectedFiles((prevSelected) =>
          prevSelected.filter((id) => id !== file_uid)
        );
      } else {
        // Check individual file
        setSelectedFiles((prevSelected) => [...prevSelected, file_uid]);
      }
    } else {
      // Handle selection of files in Other Files table
      // Move file to Final Files and mark as selected
      setSolutionFiles((prevFiles) => ({
        finalFilesData: [...prevFiles.finalFilesData, file],
        otherFilesData: prevFiles.otherFilesData.filter(
          (f) => f.file_uid !== file_uid
        ),
      }));
      // Add to selected files when moved to Final Files
      setSelectedFiles((prevSelected) => [...prevSelected, file_uid]);
    }
  };

  const handleSubmitFiles = async () => {
    try {
      // Construct the data to be sent
      const fileData = {
        file_uuids: selectedFiles,
        update_status: true,
        comment: comment,
      };

      const response = await orderService.moveFileToClient(orderKey, fileData);

      if (response.success) {
        message.success("Files sent to the client successfully!");
        // Reset selected files and comment after submission if needed
        setSelectedFiles([]);
        setComment("");
        getSolutionFiles(); // Refresh the file list if needed
      } else {
        // message.error("Failed to send files to the client.");
      }
    } catch (error) {
      console.error(error);
      //   message.error("An error occurred while sending files.");
    }
  };

  const renderTable = (data, isFinal = false) => (
    <Table
      style={{ width: "100%" }}
      columns={[
        {
          title: "Select",
          dataIndex: "file_uid",
          key: "select",
          render: (_, record) => (
            <Checkbox
              checked={
                isFinal ? selectedFiles.includes(record.file_uid) : false
              }
              onChange={() => handleFileSelection(record, isFinal)}
            />
          ),
        },
        {
          title: "Title",
          dataIndex: "file_name",
          key: "file_name",
        },
        {
          title: "File Type",
          dataIndex: "file_type",
          key: "file_type",
          render: (text) => (
            <Tag color={text === "PDF" ? "green" : "blue"}>{text}</Tag>
          ),
        },
        {
          title: "Created At",
          dataIndex: "created",
          key: "created",
          render: (text) => (
            <Typography.Text>
              {" "}
              {moment
                (text) // use text as the date value
                .local()
                .format("YYYY-MM-DD hh:mm A")}
            </Typography.Text>
          ),
        },
      ]}
      dataSource={data}
      rowKey="file_uid"
      pagination={false}
      bordered
      locale={{ emptyText: <Empty description="No Files" /> }}
    />
  );

  return (
    <div style={{ padding: "0px", border: "none" }}>
      {isLoading ? (
        <Flex justify="center" align="center" style={{ width: "100%" }}>
          <Spin />
        </Flex>
      ) : (
        <Flex vertical>
          <Typography.Title level={4}>Final Files</Typography.Title>
          {renderTable(solutionFiles.finalFilesData, true)}

          <Typography.Title level={4} style={{ marginTop: 20 }}>
            Other Files
          </Typography.Title>
          {renderTable(solutionFiles.otherFilesData)}

          <div
            style={{
              marginTop: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}>
            <TextArea
              placeholder="Add comments here"
              rows={4}
              value={comment}
              onChange={(e) => setComment(e.target.value)} // Update comment state
            />

            <Button
              type="primary"
              style={{ marginTop: 12 }}
              onClick={handleSubmitFiles} // Trigger the submit function
            >
              Send
            </Button>
          </div>
        </Flex>
      )}
    </div>
  );
};

export default SendFilesToClient;
