import React, { useState } from "react";
import NotificationList from "../../page-components/Notification/NotificationsList";
import NotificationsTabs from "../../page-components/Notification/NotificationTabs";
import { Flex } from "antd";

const initialNotifications = [
  {
    id: 1,
    category: "urgent",
    label: "Comment",
    categoryColor: "red",
    description: "Vaibhav has forwared an internal comment  to client comment on order 21I8LNWB9L",
    timestamp: "8 hours ago",
    read: false,
  },
  {
    id: 2,
    category: "urgent",
    label: "Reminder",
    categoryColor: "red",
    description: "Gaurav has initiated a refund for order 91I8LNWY9L",
    timestamp: "2 hours ago",    read: false,
  },
  {
    id: 3,
    category: "urgent",
    label: "Solution",
    categoryColor: "red",
    description: "Solution has been added to order 91I8LNWY9L by the FL",
    timestamp: "9 hours ago",    read: false,
  },
  // Add other notifications here
];

const Notification = () => {
  const [activeTab, setActiveTab] = useState("urgent");
  const [notifications, setNotifications] = useState(initialNotifications);
  const [showUnread, setShowUnread] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const handleMarkAsRead = (id) => {
    setNotifications((prev) =>
      prev.map((notif) =>
        notif.id === id ? { ...notif, read: !notif.read } : notif
      )
    );
  };

  const handleShowUnreadToggle = () => {
    setShowUnread(!showUnread);
  };

  const handleCheckboxChange = (id) => {
    setSelectedIds((prev) =>
      prev.includes(id) ? prev.filter((itemId) => itemId !== id) : [...prev, id]
    );
  };

  const filteredNotifications = notifications.filter((notif) =>
    showUnread ? !notif.read && notif.category === activeTab : notif.category === activeTab
  );

  return (
    <Flex
      vertical
      align="flex-start"
      justify="flex-start"
      style={{
        width: "100%",
        margin: "0 auto",
        padding: "20px",
        height: "100%",
        background: "white",
      }}
    >
      <NotificationsTabs
        activeTab={activeTab}
        onTabChange={handleTabChange}
        onShowUnreadToggle={handleShowUnreadToggle}
        showUnread={showUnread}
      />
      <NotificationList
        notifications={filteredNotifications}
        onMarkAsRead={handleMarkAsRead}
        onCheckboxChange={handleCheckboxChange}
      />
    </Flex>
  );
};

export default Notification;
