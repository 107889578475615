import React from "react";
import { Avatar, Table, Tooltip } from "antd";

const SalesInfoPanel = ({ orderData }) => {
  // console.log(orderData, "kjhgfdsdfyuioiuyt");
  // Prepare the sales-related data based on the presence of values
  const clientData = [];

  if (orderData.data.visibility) {
    clientData.push({
      key: "salesTeam",
      field: "Visibility",
      value: (
        <Avatar.Group>
          {orderData.data.visibility.map((name, index) => (
            <Tooltip title={name} placement="top" key={index}>
              <Avatar style={{ backgroundColor: "#f56a00" }}>
                {name.charAt(0)}
              </Avatar>
            </Tooltip>
          ))}
        </Avatar.Group>
      ),
    });
  }

  if (orderData.data.created_by) {
    clientData.push({
      key: "createdBy",
      field: "Created By",
      value: orderData.data.created_by || "N/A",
    });
  }

  if (orderData.data.ownership) {
    clientData.push({
      key: "primarySalesRep",
      field: "Ownership",
      value: orderData.data.ownership || "N/A",
    });
  }

  const clientColumns = [
    { title: "Field", dataIndex: "field", key: "field" },
    { title: "Value", dataIndex: "value", key: "value" },
  ];

  return (
    <Table
      columns={clientColumns}
      dataSource={clientData}
      pagination={false}
      showHeader={false}
      bordered
      size="small"
      style={{ width: "100%" }}
    />
  );
};

export default SalesInfoPanel;
