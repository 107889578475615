// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row-red {
    background-color: #ffcccc !important; /* Light red */
  }
  `, "",{"version":3,"sources":["webpack://./src/page-components/OrderProgressTracking/orderProgress.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC,EAAE,cAAc;EACtD","sourcesContent":[".row-red {\n    background-color: #ffcccc !important; /* Light red */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
