import React from "react";
import { Input } from "antd";

const { Search } = Input;

const SearchBar = ({ searchKey, setSearchKey, handleSearch, handleClearSearch }) => (
  <Search
    placeholder="Search by Order Key"
    enterButton="Search"
    size="large"
    style={{ width: 400 }}
    onSearch={handleSearch}
    value={searchKey}
    onChange={(e) => {
      const { value } = e.target;
      setSearchKey(value);
      if (!value) {
        handleClearSearch();
      }
    }}
  />
);

export default SearchBar;
