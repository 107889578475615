import React, { useState, useEffect } from "react";
import {
    Button,
    Checkbox,
    Col,
    Form,
    Input,
    Row,
    Select,
    Table,
    Switch,
    message,
} from "antd";
import { useParams } from "react-router-dom";
import orderService from "../../services/OrderLists";
import commonService from "../../services/commonService";
import moment from "moment";
import SpinLoading from "./SpinLoading";
import { catchError } from "../../utils/errorHandler";
import { useMediaQuery } from "react-responsive";

const { Option } = Select;

const AddTransactionsComp = ({
    orderData,
    handleModalClose,
    fetchOrderDetails,
}) => {
    const [form] = Form.useForm();
    const [selectedUid, setSelectedUid] = useState(null);
    const [loading, setLoading] = useState(true);
    const [submitLoading, setSubmitLoading] = useState(false);
    const { orderKey } = useParams();
    const [transactionList, setTransactionList] = useState([]);
    const currency = orderData.data.payment.currency;
    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

    const [transactionData, setTransactionData] = useState({
        amount: null,
        notify: false,
        client_transaction: null,
        currency: currency,
    });

    useEffect(() => {
        const fetchTransactionList = async () => {
            setLoading(true);
            try {
                const response = await orderService.getTransaction(orderKey);
                setTransactionList(response.data);
            } catch (error) {
                message.error("Failed to load transaction list");
            } finally {
                setLoading(false);
            }
        };

        fetchTransactionList();
    }, [orderKey]);

    const handleCheckboxChange = (checked, uid) => {
        const updatedUid = checked ? uid : null;

        setSelectedUid(updatedUid);
        setTransactionData((prev) => ({
            ...prev,
            client_transaction: updatedUid,
        }));
    };

    const handleNotifyClient = (checked) => {
        setTransactionData((prevData) => ({ ...prevData, notify: checked }));
    };

    const columns = [
        {
            title: "Select",
            key: "select",
            render: (text, record) => (
                <Checkbox
                    checked={selectedUid === record.uid} // Single selection
                    disabled={
                        currency === "INR" || record.currency === "INR" // Enable all rows if INR is selected or the row's currency is INR
                            ? false
                            : currency !== record.currency // Enable rows matching the selected currency
                    }
                    onChange={(e) => handleCheckboxChange(e.target.checked, record.uid)}
                />
            ),
        },
        {
            title: "Remaining Amount",
            dataIndex: "remaining_amount",
            key: "remaining_amount",
        },
        // {
        //   title: "Transaction ID",
        //   dataIndex: "transaction_id",
        //   key: "transaction_id",
        // },
        {
            title: "Original Amount",
            dataIndex: "amount",
            key: "amount",
        },
        // {
        //   title: "Total Mapped Amount",
        //   dataIndex: "total_mapped_amount",
        //   key: "total_mapped_amount",
        // },
        {
            title: "Currency",
            dataIndex: "currency",
            key: "currency",
        },
        {
            title: "Created At",
            dataIndex: "created_at",
            key: "created_at",
            render: (text) =>
                text ? moment(text).local().format("DD-MM-YYYY hh:mm A") : "N/A",
        },

    ];

    const handleAddTransactions = async () => {
        try {
            const isValid = await form.validateFields();
            if (isValid) {
                setSubmitLoading(true);
                const addTransaction = await orderService.addTransaction(
                    orderKey,
                    transactionData
                );

                if (addTransaction.success) {
                    message.success("Payment added successfully!");

                    setTransactionData({
                        amount: null,
                        notify: true,
                        client_transaction: null,
                    });
                    handleModalClose();
                    fetchOrderDetails();
                }
                setSubmitLoading(false);
            }
        } catch (error) {
            setSubmitLoading(false);
            await catchError(error)
        }
    };

    return (
        <Form form={form} layout="vertical" style={{ marginTop: "1rem" }}>
            {loading ? (
                <SpinLoading />
            ) : (
                <>
                    {/* Currency and Amount Inputs */}
                    <Row gutter={16} style={{ width: "100%" }}>
                        <Col sm={24} md={12}>
                            <Form.Item
                                label="Amount"
                                name="amount"
                                rules={[{ required: true, message: "Amount is required" }]}>
                                <Input
                                    type="number"
                                    value={transactionData.amount}
                                    placeholder="Enter Amount"
                                    onChange={(e) =>
                                        setTransactionData((prev) => ({
                                            ...prev,
                                            amount: e.target.value,
                                        }))
                                    }
                                />
                            </Form.Item>
                        </Col>

                        <Col sm={24} md={12}>
                            <Form.Item label="Currency" required>
                                <Input value={currency} disabled />
                            </Form.Item>
                        </Col>
                    </Row>

                    {/* Transaction Table */}
                    <Table
                        style={{ marginTop: "18px", width: "100%" }}
                        columns={columns}
                        dataSource={transactionList?.data || []}
                        rowKey="uid"
                        pagination={false}
                        scroll={isMobile ? { x: "max-content" } : undefined} // Conditionally apply scrolling
                    />

                    {/* Notify Client Switch */}
                    <Row
                        style={{
                            marginBottom: "16px",
                            width: "100%",
                            marginTop: "20px",
                            flexWrap: "wrap",
                        }}
                        gutter={[16, 16]}>
                        <Col
                            xs={24}
                            md={16}
                            style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                            <div
                                style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                                <div>Notify Client</div>
                                <Form.Item valuePropName="checked" style={{ marginBottom: 0 }}>
                                    <Switch
                                        checked={transactionData.notify}
                                        onChange={handleNotifyClient}
                                    />
                                </Form.Item>
                            </div>
                        </Col>

                        {/* Add Transaction Button */}
                        <Col
                            xs={24}
                            md={8}
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                            }}>
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                {submitLoading ? (
                                    <SpinLoading />
                                ) : (
                                    <Button type="primary" onClick={handleAddTransactions}>
                                        Add Transaction
                                    </Button>
                                )}
                            </div>
                        </Col>
                    </Row>
                </>
            )}
        </Form>
    );
};

export default AddTransactionsComp;
