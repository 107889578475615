import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  Select,
  Upload,
  Flex,
  message,
  Button,
} from "antd";
import {
  FileTextOutlined,
  DeleteOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import dayjs from "dayjs";
import orderService from "../../services/OrderLists";
import { useParams } from "react-router-dom";
import moment from "moment";
const { Dragger } = Upload;
const { Option } = Select;

const AddFeedbackFlOrder = (orderData, handleTabChange, handleModalClose , ) => {

  const [timezonesData, setTimeZonesData] = useState([]);
  const [formData, setFormData] = useState({
    details: "",
    deadline_hard: orderData?.orderData?.data?.deadline_hard || "",
    timezone: "Asia/Kolkata",
    extra_word_count: "",
    files: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [deadlineHard, setDeadlineHard] = useState(
    orderData?.orderData?.data?.deadline_hard
      ? dayjs(orderData.orderData.data.deadline_hard)
      : null
  );
  const [fileList, setFileList] = useState([]);
  const [description, setDescription] = useState("");
  const [form] = Form.useForm();
  const { orderKey } = useParams();

  const deadlineDate = moment(orderData?.orderData?.data?.deadline_hard);


  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const timezoneData = await orderService.getTimezonesDropdown();
        setTimeZonesData(timezoneData.data);
      } catch (error) {
        console.error("Error fetching dropdown data:", error);
      }
    };

    fetchDropdownData();
  }, []);
  const handleDescriptionChange = (value) => {
    setDescription(value);
    setFormData({ ...formData, details: value });
  };

  const handleFileChange = (info) => {
    const updatedFileList = info.fileList.slice(-5);
    setFileList(updatedFileList);
  };

  const handleDeleteFile = (index) => {
    const newFileList = [...fileList];
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const getFormattedFileName = (fileName) => {
    const extensionIndex = fileName.lastIndexOf(".");
    const baseName =
      extensionIndex !== -1 ? fileName.substring(0, extensionIndex) : fileName;
    const extension =
      extensionIndex !== -1 ? fileName.substring(extensionIndex) : "";

    if (baseName.length > 14) {
      return {
        displayName: `${baseName.substring(0, 14)}...${extension}`,
        fullName: fileName,
      };
    }
    return {
      displayName: fileName,
      fullName: fileName,
    };
  };

  const getFileIcon = (fileName) => {
    const extension = fileName?.split(".").pop().toLowerCase();
    switch (extension) {
      case "pdf":
        return "https://i.ibb.co/Xzcwvy6/images-q-tbn-ANd9-Gc-TGAA4-Wd4bco5-Xv33-Gas-Xrn-Dd-QT5-OFXwa3-HUQ-s.png";
      case "doc":
      case "docx":
        return "https://i.ibb.co/mXGsc7g/images-q-tbn-ANd9-Gc-Rs3n-Wc9jmd-M7-Wm-Dc-EAy3-PC2z-Mur7-A1ee-bw-s.png";
      case "jpg":
      case "jpeg":
        return "https://i.ibb.co/NC2DbKN/images-q-tbn-ANd9-Gc-Qgd-Crtwnin-NU1-Nxzpu-IP6-F9h-Kkpfv-SSgr7cw-s.png";
      case "png":
        return "https://i.ibb.co/mtLjYdr/2048px-pptx-icon-2019-svg.png";
      case "zip":
        return "https://i.ibb.co/WV6wJv4/zip-file-line-icon-vector.jpg";
      default:
        return "https://i.ibb.co/NC2DbKN/images-q-tbn-ANd9-Gc-Qgd-Crtwnin-NU1-Nxzpu-IP6-F9h-Kkpfv-SSgr7cw-s.png";
    }
  };
  const handleSubmit = async () => {
    if (!formData.details || !formData.extra_word_count) {
      message.error("Please provide Details and Word count.");
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append("details", formData.details);
    formDataToSend.append("deadline_hard", formData.deadline_hard);
    formDataToSend.append("extra_word_count", formData.extra_word_count);
    formDataToSend.append("timezone", formData.timezone);
    fileList.forEach((file) =>
      formDataToSend.append("files", file.originFileObj)
    );

    try {
      setIsLoading(true);
      const response = await orderService.addFeedbackFlOrder(orderKey, formDataToSend);

      if (response?.success) {
        message.success("Feedback submitted successfully.");
        orderData?.handleModalClose();
        await orderData?.handleTabChange("4");
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      encType="multipart/form-data"
      >
      <Flex vertical justify="center" style={{ width: "100%" }} gap={24}>
        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item label="Description" required>
              <ReactQuill
                value={description}
                onChange={handleDescriptionChange}
                style={{ height: "150px" }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item label="Deadline Hard" required>
              <DatePicker
                value={deadlineHard}
                disabledDate={(current) =>
                  current && current.isBefore(moment())
                }
                style={{ width: "100%" }}
                showTime
                format="YYYY-MM-DD HH:mm A"
                onChange={(date) => {
                  setDeadlineHard(date);
                  setFormData({
                    ...formData,
                    deadline_hard: date ? date.toISOString() : null,
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Extra Word Count" required>
              <Input
                placeholder="Enter extra word count"
                suffix={<FileTextOutlined />}
                value={formData.extra_word_count}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    setFormData((prev) => ({
                      ...prev,
                      extra_word_count: value,
                    }));
                  } else {
                    message.warning("Only numeric values are allowed.");
                  }
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item label="Timezone">
              <Select
                defaultValue="Asia/Kolkata"
                onChange={(value) =>
                  setFormData({ ...formData, timezone: value })
                }>
                {timezonesData.map((timezone) => (
                  <Option key={timezone} value={timezone}>
                    {timezone}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <label>Files</label>
          <div className="file-parent">
            <div className="file-container">
              {fileList.length === 0 ? (
                <Flex
                  vertical
                  justify="center"
                  style={{ width: "100%", minHeight: "150px" }}
                  className="file-preview-container">
                  <Dragger
                    style={{
                      width: "100%",
                      background: "none",
                      border: "none",
                    }}
                    showUploadList={false} // Hide Ant Design's automatic file list
                    beforeUpload={() => false} // Prevent automatic upload
                    onChange={handleFileChange} // Handle file selection
                    fileList={fileList} // Set fileList state for controlled uploads
                    multiple // Allow multiple file uploads
                    listType="text" // Display file names below the input
                  >
                    <p
                      className="ant-upload-text "
                      style={{ color: "gray", marginTop: "20px" }}>
                      Drag & drop files here or click to upload
                    </p>
                  </Dragger>
                </Flex>
              ) : (
                <div
                  className="file-preview-container"
                  style={{ display: "flex", flexWrap: "wrap" }}>
                  {fileList.map((file, index) => {
                    const { displayName, fullName } = getFormattedFileName(
                      file.name
                    );

                    return (
                      <div
                        key={index}
                        className="file-preview-item"
                        style={{
                          margin: "10px",
                          display: "flex",
                          alignItems: "center",
                          border: "1px solid #d9d9d9",
                          padding: "2px 4px",
                          borderRadius: "4px",
                        }}>
                        <img
                          style={{
                            height: "40px",
                            width: "40px",
                            marginRight: "8px",
                          }}
                          src={getFileIcon(file.name)}
                          alt="file-icon"
                        />
                        <span title={fullName}>{displayName}</span>
                        <DeleteOutlined
                          style={{ marginLeft: "8px", cursor: "pointer" }}
                          onClick={() => handleDeleteFile(index)} // Call delete function on click
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <Upload
              showUploadList={false} // Hide Ant Design's automatic file list
              beforeUpload={() => false} // Prevent automatic upload
              onChange={handleFileChange}
              fileList={fileList} // Set fileList state for controlled uploads
              multiple
              listType="text">
              <div
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}>
                <PaperClipOutlined
                  style={{ fontSize: "24px", marginRight: "8px" }}
                />
              </div>
            </Upload>
          </div>
        </Row>

        <Flex style={{ width: "100%" }} justify="flex-end" align="flex-end">
          <Button type="primary" loading={isLoading} onClick={handleSubmit}>
            Submit
          </Button>
        </Flex>
        {/* <Row>
        <label>Files</label>
        <div className="file-container">
          {fileList.length === 0 ? (
            <Dragger
              style={{ width: "100%", minHeight: "150px" }}
              fileList={fileList}
              beforeUpload={() => false}
              onChange={handleFileChange}
              multiple>
              <p>Drag & drop files here or click to upload</p>
            </Dragger>
          ) : (
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {fileList.map((file, index) => {
                const { displayName, fullName } = getFormattedFileName(
                  file.name
                );

                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #d9d9d9",
                      padding: "4px",
                      marginBottom: "5px",
                    }}>
                    <img
                      src={getFileIcon(file.name)}
                      alt="file-icon"
                      style={{ height: "40px", marginRight: "8px" }}
                    />
                    <span title={fullName}>{displayName}</span>
                    <DeleteOutlined
                      style={{ marginLeft: "auto", cursor: "pointer" }}
                      onClick={() => handleDeleteFile(index)}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </Row> */}
      </Flex>
    </Form>
  );
};

export default AddFeedbackFlOrder;
