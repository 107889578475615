import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  message,
  Typography,
  Flex,
  DatePicker,
  Radio,
  Tooltip,
  Button,
} from "antd";
import PhoneInput from "react-phone-input-2";
import "./css/ReusableModal.css";
import { CloseOutlined } from "@ant-design/icons";
import { catchError } from "../utils/errorHandler";
import axiosInstance from "../services/axiosInstance";
import clientService from "../services/clientService";

const { Option } = Select;

const ReusableModal = ({
  visible,
  onClose,
  title,
  fields = [],
  viewMode,
  getMyClients,
  getAllClients
  // confirmLoading = false,
}) => {
  const { Title } = Typography;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState(null);

  const [isOtpStep, setIsOtpStep] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [showInputOtp, setSHowInputOtp] = useState(false);
  const [submitData, setSubmitData] = useState({ method: "whatsapp" });
  const [valuesOtp, setValuesOtp] = useState("");

  // Resend OTP
  const [timeLeft, setTimeLeft] = useState(30);
  const [isDisabled, setIsDisabled] = useState(true);

  const handleRadioChange = (value) => {
    setSelectedRadio(value);
  };

  const handleCloseSkip = () => {
    onClose();
    if (viewMode === "myClients") {
      getMyClients();
    } else {
      getAllClients();
    }
  };
  
  const handleSubmit = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      // await onSubmit(values);
      const response = await clientService.createClient(values);
      if (response.success) {
        message.success("Client added successfully");
        setIsOtpStep(true);
        setValuesOtp(values);
        form.resetFields();
      }
      // setIsOtpStep(true);
    } catch (error) {
      message.error(`Failed to ${title.toLowerCase()}`);
      console.error("Error:", error);
      setIsOtpStep(false);
    } finally {
      setLoading(false);
    }
  };

  const handleOtp = async () => {
    setLoading(true);
    try {
      const payload = {
        phone_number: valuesOtp?.contact_no,
        method: submitData?.method,
      };

      const res = await axiosInstance.post("useraccount/v2/otp/send/", payload);
      if (res.status === 200) {
        setSHowInputOtp(true);
        message.success("OTP Sent");
        setLoading(false);
      }
    } catch (err) {
      await catchError(err);
      setLoading(false);
    }
  };

  const handleOtpSubmit = async () => {
    setLoading(true);
    try {
      const payload = {
        otp: submitData?.otp,
        phone_number: valuesOtp?.contact_no,
      };
      const res = await axiosInstance.post(
        "/useraccount/v2/otp/verify/",
        payload
      );
      // console.log("res", res);
      if (res.status === 200) {
        if (viewMode === "myClients") {
          getMyClients();
        } else {
          getAllClients();
        }
        onClose();
        message.success(res.data.data);
      }
    } catch (err) {
      setLoading(false);
      await catchError(err);
    }
  };

  useEffect(() => {
    if (showInputOtp && timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else if (timeLeft === 0) {
      setIsDisabled(false);
    }
  }, [timeLeft, showInputOtp]);

  useEffect(() => {
    if (!showInputOtp) {
      setTimeLeft(30);
      setIsDisabled(true);
    }
  }, [showInputOtp]);

  return (
    <Modal
      className="custom-modal"
      visible={visible}
  
      title={
        <Flex justify="space-between" className="custom-modal-header">
          <Title
            level={3}
            style={{
              color: "white",
              margin: 0,
              position: "relative",
              marginLeft: "10px",
            }}
          >
            {isOtpStep ? "Contact Verification" : title}
          </Title>
          <CloseOutlined
            style={{ fontSize: "16px", marginRight: "10px" }}
            onClick={onClose}
          />
        </Flex>
      }
      onCancel={onClose}
      onOk={
        isOtpStep ? (showInputOtp ? handleOtpSubmit : handleOtp) : handleSubmit
      }
      okText={isOtpStep ? (showInputOtp ? "Verify OTP" : "Send OTP") : "Submit"}
      closeIcon={false}
      cancelButtonProps={{ style: { display: "none" } }}
      confirmLoading={loading}
    >
      {!isOtpStep ? (
        <Form form={form} layout="vertical">
          {fields.map((field) => (
            <Form.Item key={field.name} name={field.name} rules={field.rules}>
              {field.type === "input" && (
                <Flex vertical gap={8}>
                  <label style={{ marginLeft: "10px" }}>{field.label}</label>
                  <Input
                    style={{
                      width: "220px",
                      borderRadius: "4px",
                      marginLeft: "10px",
                    }}
                  />
                </Flex>
              )}
              {field.type === "select" && (
                <Flex gap={10}>
                  <Flex vertical gap={8}>
                    {" "}
                    <label style={{ marginLeft: "10px" }}>{field.label}</label>
                    <Select
                      onChange={field.onChange || (() => {})}
                      style={{
                        width: "220px",
                        background: "transparent",
                        marginLeft: "10px",
                      }}
                      placeholder={field.label}
                      value={field.value || undefined}
                    >
                      {field.options.map((option) => (
                        <Option key={option.id} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  </Flex>
                </Flex>
              )}
              {field.type === "radio" && (
                <Flex vertical gap={"10px"} style={{ position: "relative" }}>
                  <label
                    style={{ color: "black", marginLeft: "10px" }}
                    aria-required
                  >
                    {field.label || "Select website"}
                  </label>
                  <div className="custom-radio-container">
                    {field.options.map((option) => (
                      <div key={option.id} style={{ marginLeft: "10px" }}>
                        <input
                          type="radio"
                          id={`custom-radio-${option.id}`}
                          name={field.name}
                          value={option.value}
                          className="custom-radio-input"
                          checked={selectedRadio === option.value}
                          onChange={() => handleRadioChange(option.value)}
                        />
                        <label
                          htmlFor={`custom-radio-${option.id}`}
                          className="custom-radio-label"
                        >
                          {option.label}
                        </label>
                      </div>
                    ))}
                  </div>
                </Flex>
              )}
              {field.type === "phone" && (
                <Flex vertical gap={10}>
                  <label style={{ marginLeft: "10px" }}>Phone no.</label>
                  <PhoneInput
                    inputStyle={{
                      width: "220px",
                      borderRadius: "4px",
                      height: "32.5px",
                    }}
                    containerStyle={{ borderRadius: "4px", marginLeft: "10px" }}
                    country={"au"}
                    placeholder={field.label}
                  />
                </Flex>
              )}
              {field.type === "datepicker" && (
                <Flex vertical gap={8} style={{ marginLeft: "14px" }}>
                  <label style={{ marginLeft: "10px" }}>{field.label}</label>
                  <DatePicker
                    style={{
                      width: "220px",

                      marginLeft: "12px",
                    }}
                    showTime={field.showTime}
                    format={field.format || "YYYY-MM-DD HH:mm:ss"}
                  />
                </Flex>
              )}
            </Form.Item>
          ))}
        </Form>
      ) : (
        <Flex
          vertical
          gap={15}
          style={{ alignItems: "center", textAlign: "center" }}
        >
          <Typography.Text style={{ fontSize: "16px" }}>
            {!showInputOtp ? "OTP will be sent to" : "OTP Sent to "}{" "}
            <span style={{ fontWeight: "bold" }}>
              {valuesOtp?.contact_no || ""}
            </span>
          </Typography.Text>

          {!showInputOtp ? (
            <>
              <Typography.Text>
                Select how you want to receive the OTP
              </Typography.Text>
              <Flex gap={20}>
                <Radio.Group
                  onChange={(e) =>
                    setSubmitData({ ...submitData, method: e.target.value })
                  }
                  value={submitData.method}
                >
                  <Radio value="whatsapp">WhatsApp</Radio>
                  <Radio value="sms">SMS</Radio>
                </Radio.Group>
              </Flex>
            </>
          ) : (
            <div>
              <Input
                maxLength={6}
                placeholder="Enter OTP"
                style={{
                  width: "180px",
                  textAlign: "center",
                  marginTop: "10px",
                }}
                onChange={(e) =>
                  setSubmitData({ ...submitData, otp: e.target.value })
                }
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "20px",
                }}
              >
                <Button onClick={handleOtp} disabled={isDisabled}>
                  {isDisabled ? `Resend OTP in ${timeLeft} sec` : "Resend OTP"}
                </Button>
              </div>
            </div>
          )}

          <Flex
            justify="center"
            gap={20}
            style={{ width: "100%", marginTop: "10px" }}
          >
            <Tooltip
              placement="bottom"
              title="Phone number verification is required to download the order files."
            >
              <Typography.Link style={{color:"#D3D3D3"}} onClick={handleCloseSkip}>
                Skip Verification
              </Typography.Link>
            </Tooltip>
            {/* <Typography.Link onClick={() => setShowContent(!showContent)}>
              Contact Support
            </Typography.Link> */}
          </Flex>
          {showContent && (
            <div
              style={{
                textAlign: "center",
                marginTop: "12px",
                fontSize: "14px",
              }}
            >
              If OTP verification failed, please try again or contact your
              representative. For support, call
              {valuesOtp?.website == 1 && (
                <>
                  <strong> +44 7497 786317</strong> /{" "}
                  <strong>+44 7411 155185</strong> or email us at{" "}
                  <a href="mailto:support@locusassignments.com">
                    support@locusassignments.com
                  </a>
                </>
              )}
              {valuesOtp?.website == 2 && (
                <>
                  <strong> +61 430090460</strong> /{" "}
                  <strong>+44 7497786317</strong> or email us at{" "}
                  <a href="mailto:contact@ozassignments.com">
                    contact@ozassignments.com
                  </a>
                </>
              )}
              {valuesOtp?.website == 3 && (
                <>
                  <strong> +44 7497786317 </strong> or email us at{" "}
                  <a href="mailto:info@anayalearning.com">
                    info@anayalearning.com
                  </a>
                </>
              )}
              .
            </div>
          )}
        </Flex>
      )}
    </Modal>
  );
};

export default ReusableModal;
