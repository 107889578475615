import React, { useEffect, useState } from "react";
import { Card, Table, Typography, Row, Col, Flex, Tag, Descriptions } from "antd";
import { motion } from "framer-motion";
import axiosInstance from "../../services/axiosInstance";
import dayjs from "dayjs";
import moment from "moment";
import { CalendarOutlined, CheckOutlined, ExclamationCircleOutlined, FileTextOutlined, WarningOutlined } from "@ant-design/icons";
import SpinLoading from "../../components/MyOrderActions/SpinLoading";
import { useMediaQuery } from "react-responsive";

const { Title, Text } = Typography;

const getStatusColor = (status) => {
    const statusColors = {
        Unassigned: "#F0F0F0",
        "Assigned to manager": "#C0C0C0",
        "In Progress": "#D0E4F2",
        "Quality Check": "#A9C3E0",
        Completed: "#C8E6C8",
        Delivered: "#8FCF8F",
        Rejected: "#E8A0A0",
        Cancelled: "#A0A0A0",
        Failed: "#D09090",
        Feedback: "#C2A892",
    };
    return statusColors[status] || "default";
};

const columns = [
    {
        title: "Order ID",
        dataIndex: "key",
        key: "key",
        render: (text) => (
            <a
                href={`/order-management/my-orders/${text}`}
                target="_blank"
                style={{ color: "#1677ff" }}
            >
                <strong>{text}</strong>
            </a>
        ),
        width: "33%"
    },
    {
        title: "Allotted Deadline",
        dataIndex: "alloted_deadline",
        key: "alloted_deadline",
        render: (text) => (
            <Flex align="center" gap="small">
                <CalendarOutlined style={{ color: '#8c8c8c' }} />
                {text ? moment(text).local().format("DD-MM-YYYY hh:mm A") : 'N/A'}
            </Flex>
        ),
        width: "33%"
    },
    {
        title: "Order Status",
        dataIndex: "status",
        key: "status",
        render: (status) =>
            <Tag
                style={{ color: 'black', fontWeight: 500 }}
                color={getStatusColor(status)}>
                {status}
            </Tag>,
        width: "33%"
    }
];

const AnimatedCard = ({ children }) => (
    <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
        {children}
    </motion.div>
);

const renderCard = (task) => (
    <Card
        key={task?.key}
        style={{
            margin: 0,
            padding: 0,
            marginBottom: 10,
        }}
        bodyStyle={{ padding: 0, border: "none" }}
    >
        <Descriptions
            style={{ margin: 0, padding: 0 }}
            size="small"
            column={1}
            bordered
        >
            <Descriptions.Item
                label="Order ID"
                labelStyle={{ background: "none", color: "#222", fontWeight: 600 }}
            >
                <Typography.Link href={`/order-management/my-orders/${task.key}`} target="_blank" style={{ color: '#1677ff' }}>
                    {task.key}
                </Typography.Link>
            </Descriptions.Item>

            <Descriptions.Item
                label="Allotted Deadline"
                labelStyle={{ background: "none", color: "#222", fontWeight: 600 }}
            >
                <Typography.Text>
                    {task.alloted_deadline
                        ? moment(task.alloted_deadline).local().format("DD-MM-YYYY hh:mm A")
                        : "N/A"}
                </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item
                label="Order Status"
                labelStyle={{ background: "none", color: "#222", fontWeight: 600 }}
            >
                <Tag style={{ color: 'black', fontWeight: 500 }} color={getStatusColor(task.status)}>
                    <Typography.Text>{task.status}</Typography.Text>
                </Tag>
            </Descriptions.Item>
        </Descriptions>
    </Card>
);


const WorkSchedule = () => {
    const [workSchedule, setWorkSchedule] = useState({});
    const [opsSummary, setOpsSummary] = useState({});
    const [loading, setLoading] = useState(false);

    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

    useEffect(() => {
        const getData = async () => {
            try {
                setLoading(true);
                const response = await axiosInstance.get("/task/v2/work-schedule/");
                if (response?.status && response?.data?.status) {
                    setWorkSchedule(response?.data?.data);
                }
                setLoading(false);
            } catch (error) {
                console.error("Error fetching work schedule data:", error);
                setLoading(false);
            }
        };

        const getOpsSummary = async () => {
            try {
                setLoading(true);
                const response = await axiosInstance.get("/task/v2/ops-summary/");
                if (response?.status && response?.data?.status) {
                    setOpsSummary(response?.data?.data);
                }
            } catch (error) {
                console.error("Error fetching ops summary data:", error);
            } finally {
                setLoading(false);
            }
        };

        getData();
        getOpsSummary();
    }, []);

    // Generate dynamic "Next Two Days" title
    const startDate = dayjs().add(2, "day").format("DD MMMM YYYY");
    const endDate = dayjs().add(3, "day").format("DD MMMM YYYY");
    const nextTwoDaysTitle = `${startDate} - ${endDate}`;

    const tableSections = [
        { title: "Delayed", data: workSchedule?.delayed_tasks || [] },
        { title: "Today", data: workSchedule?.today_tasks || [] },
        { title: "Tomorrow", data: workSchedule?.tomorrow_tasks || [] },
        { title: nextTwoDaysTitle, data: workSchedule?.next_two_days_tasks || [] },
    ];

    const summaryData = {
        achieved_word_count: {
            icon: <FileTextOutlined style={{ fontSize: "24px", color: "#52c41a" }} />,
            color: "#f6ffed",
        },
        feedback_count: {
            icon: <WarningOutlined style={{ fontSize: "24px", color: "#faad14" }} />,
            color: "#fffbe6",
        },
        complete_tasks: {
            icon: <CheckOutlined style={{ fontSize: "24px", color: "#1677ff" }} />,
            color: "#e6f4ff",
        },
        deducted_word_count: {
            icon: <ExclamationCircleOutlined style={{ fontSize: "24px", color: "#ff4d4f" }} />,
            color: "#fff1f0",
        },
    };

    return (
        <Flex vertical gap="large" style={{ padding: "0px 0px 24px 0px", width: "100%" }}>

            <Row gutter={[16, 16]}>
                {Object.entries(opsSummary || {}).map(([key, value], index) => {
                    const { color } = summaryData[key] || {};
                    const displayValue = value ?? 0;
                    return (
                        <Col xs={24} sm={12} md={6} key={index}>
                            <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: index * 0.1 }}
                            >
                                <AnimatedCard>
                                    <Card
                                        bordered={false}
                                        style={{
                                            background: color || "#fff",
                                            borderRadius: "12px",
                                            height: "100%",
                                        }}
                                        hoverable
                                    >
                                        <Title level={3} style={{ color: "#36c6d3", marginTop: "10px" }}>
                                            {displayValue}
                                        </Title>
                                        <Text type="secondary">{key.replace(/_/g, ' ').toUpperCase()}</Text>
                                    </Card>
                                </AnimatedCard>
                            </motion.div>
                        </Col>
                    );
                })}
            </Row>


            {loading ? (
                <Flex justify="center" align="center" style={{ minHeight: "200px" }}>
                    <SpinLoading size="large" />
                </Flex>
            ) : (
                tableSections?.map((section, index) => (
                    section?.data && (
                        <AnimatedCard key={index}>
                            <Card
                                title={
                                    <Flex justify="space-between" align="center">
                                        <Title level={isMobile ? 5 : 4} style={{ margin: 0, color: "#1F2937" }}>{section.title}</Title>
                                        <Tag
                                            style={{
                                                fontSize: "14px",
                                                padding: "6px 12px",
                                                borderRadius: "10px",
                                                color: "#023047",
                                                backgroundColor: "#36c6d3",
                                                fontWeight: 600
                                            }}
                                        >
                                            <strong>{isMobile ? "" : "Count:"} {section?.data?.count}</strong>
                                        </Tag>
                                    </Flex>
                                }
                                bordered={false}
                                style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", borderRadius: "12px" }}
                            >
                                {section?.data?.count > 0 ? (
                                    isMobile ? (
                                        section?.data?.tasks?.map((task) => renderCard(task))
                                    ) : (
                                        <Table columns={columns} dataSource={section?.data?.tasks} pagination={false} />
                                    )
                                ) : (
                                    <div>No Orders available</div>
                                )}
                            </Card>
                        </AnimatedCard>
                    )
                ))
            )}

        </Flex>
    );
};

export default WorkSchedule;
