import {
  Form,
  Input,
  Row,
  Col,
  Select,
  DatePicker,
  Upload,
  Spin,
  Button,
  Flex,
} from "antd";
import {
  UserOutlined,
  FileTextOutlined,
  DeleteOutlined,
  PaperClipOutlined,
  TagOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import ReactQuill from "react-quill";

const { Option } = Select;
const { Dragger } = Upload;

function CreateSolution({
  loading,
  isSubmitLoading,
  handleFileChange,

  onSubmit,
  subscribers,
  fileTypes,
  formData,
  setFormData,
  dropdownData,
  handleTimezoneChange,
  handleDescriptionChange,
  setFileList,
  fileList,
  selectedRadio,
  handleRadioChange,
}) {
  const [form] = Form.useForm();

  // Remove duplicate file types based on 'file_type' property
  const uniqueFileTypes = fileTypes.reduce((acc, current) => {
    const x = acc.find((item) => item.file_type === current.file_type);
    if (!x) acc.push(current);
    return acc;
  }, []);
  const getFileIcon = (fileName) => {
    const extension = fileName?.split(".").pop().toLowerCase();

    switch (extension) {
      case "pdf":
        return "https://i.ibb.co/Xzcwvy6/images-q-tbn-ANd9-Gc-TGAA4-Wd4bco5-Xv33-Gas-Xrn-Dd-QT5-OFXwa3-HUQ-s.png";
      case "doc":
      case "docx":
        return "https://i.ibb.co/mXGsc7g/images-q-tbn-ANd9-Gc-Rs3n-Wc9jmd-M7-Wm-Dc-EAy3-PC2z-Mur7-A1ee-bw-s.png";
      case "jpg":
      case "jpeg":
        return "https://i.ibb.co/NC2DbKN/images-q-tbn-ANd9-Gc-Qgd-Crtwnin-NU1-Nxzpu-IP6-F9h-Kkpfv-SSgr7cw-s.png";
      case "png":
        return "https://i.ibb.co/mtLjYdr/2048px-pptx-icon-2019-svg.png";
      case "zip":
        return "https://i.ibb.co/WV6wJv4/zip-file-line-icon-vector.jpg";
      default:
        return "https://i.ibb.co/NC2DbKN/images-q-tbn-ANd9-Gc-Qgd-Crtwnin-NU1-Nxzpu-IP6-F9h-Kkpfv-SSgr7cw-s.png";
    }
  };

  const getFormattedFileName = (fileName) => {
    const extensionIndex = fileName.lastIndexOf(".");
    const baseName =
      extensionIndex !== -1 ? fileName.substring(0, extensionIndex) : fileName;
    const extension =
      extensionIndex !== -1 ? fileName.substring(extensionIndex) : "";

    if (baseName.length > 14) {
      return {
        displayName: `${baseName.substring(0, 14)}...${extension}`,
        fullName: fileName,
      };
    }
    return {
      displayName: fileName,
      fullName: fileName,
    };
  };

  const handleDeleteFile = (index) => {
    const updatedFileList = [...fileList];
    updatedFileList.splice(index, 1); // Remove the file at the specified index
    setFileList(updatedFileList); // Update the state with the new file list

    // Update formData.files to remove the corresponding file
    const updatedFiles = [...formData.files];
    updatedFiles.splice(index, 1); // Remove the file at the specified index
    setFormData((prevData) => ({ ...prevData, files: updatedFiles })); // Update files in formData
  };
  return (
    <Flex style={{ width: "100%" }}>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 200,
            width: "100%",
          }}>
          <Spin size="large" />
        </div>
      ) : (
        <Form
          form={form}
          layout="vertical"
          encType="multipart/form-data"
          style={{ width: "100%", height: "max-content", width: "100%" }}>
          <Col sm={24} style={{ width: "100%" }}>
            <Form.Item style={{ marginTop: "6px", width: "100%" }}>
              <label>Files</label>
              <div
                className="file-parent "
                style={{
                  marginTop: "8px",
                  minHeight: "160px",
                  maxHeight: "160px",
                  overflowY: "scroll",
                  width: "100%",
                }}>
                <div className="file-container">
                  {fileList?.length === 0 ? (
                    <Flex
                      vertical
                      justify="center"
                      style={{ width: "100%", minHeight: "100px" }}
                      className="file-preview-container">
                      <Dragger
                        style={{
                          width: "100%",
                          background: "none",
                          border: "none",
                        }}
                        showUploadList={false} // Hide Ant Design's automatic file list
                        beforeUpload={() => false} // Prevent automatic upload
                        onChange={handleFileChange} // Handle file selection
                        fileList={fileList} // Set fileList state for controlled uploads
                        multiple // Allow multiple file uploads
                        listType="text" // Display file names below the input
                      >
                        <p
                          className="ant-upload-text "
                          style={{ color: "gray", marginTop: "50px" }}>
                          Drag & drop files here or click to upload
                        </p>
                      </Dragger>
                    </Flex>
                  ) : (
                    <div
                      className="file-preview-container"
                      style={{ display: "flex", flexWrap: "wrap" }}>
                      {fileList?.map((file, index) => {
                        const { displayName, fullName } = getFormattedFileName(
                          file.name
                        );

                        return (
                          <div
                            key={index}
                            className="file-preview-item"
                            style={{
                              margin: "10px",
                              display: "flex",
                              alignItems: "center",
                              border: "1px solid #d9d9d9",
                              padding: "2px 4px",
                              borderRadius: "4px",
                            }}>
                            <img
                              style={{
                                height: "40px",
                                width: "40px",
                                marginRight: "8px",
                              }}
                              src={getFileIcon(file.name)}
                              alt="file-icon"
                            />
                            <span title={fullName}>{displayName}</span>
                            <DeleteOutlined
                              style={{ marginLeft: "8px", cursor: "pointer" }}
                              onClick={() => handleDeleteFile(index)}
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
                <Upload
                  showUploadList={false} // Hide Ant Design's automatic file list
                  beforeUpload={() => false} // Prevent automatic upload
                  onChange={handleFileChange}
                  fileList={fileList} // Set fileList state for controlled uploads
                  multiple // Allow multiple file uploads
                  listType="text" // Display file names below the input
                >
                  <div
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <PaperClipOutlined
                      style={{ fontSize: "24px", marginRight: "8px" }}
                    />
                  </div>
                </Upload>
              </div>
            </Form.Item>
          </Col>
          <Form.Item
            label="File types"
            name="file-Types"
            style={{ width: "100%" }}
            rules={[{ required: true, message: "Please Select a File Type" }]}>
            <Select
              showSearch
              placeholder="Select a File Type"
              placement="bottom"
              onChange={(value) =>
                setFormData({ ...formData, file_type: value })
              }
              filterOption={(input, option) =>
                (option?.children ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }>
              {uniqueFileTypes.map((type) => (
                <Option key={type.uuid} value={type.uuid}>
                  {type.file_type}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Row style={{ width: "100%" }}>
            <Col sm={24} style={{ width: "100%" }}>
              <Form.Item label="Remarks" required>
                <Input.TextArea
                  required={true}
                  placeholder="Enter the remark"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      remarks: e.target.value,
                    })
                  }
                  rows={4} // Adjust the number of rows as needed
                />
              </Form.Item>
            </Col>
          </Row>
      
          <Row justify="end" style={{ width: "100%", marginTop: "20px" }}>
            <Button
              loading={isSubmitLoading}
              type="primary"
              htmlType="submit"
              onClick={onSubmit}>
              Submit
            </Button>
          </Row>
          {/* <Form.Item
            label="Subscriber"
            name="subscriber"
            style={{ width: "100%" }}
            rules={[
              { required: true, message: "Please select a subscriber!" },
            ]}>
            <Select
              placeholder="Select a subscriber"
              onChange={(value) =>
                setFormData({ ...formData, subscriberId: value })
              }>
              {subscribers?.map((subscriber) => (
                <Option key={subscriber.username} value={subscriber.username}>
                  {subscriber.subscriber}{" "}
                  {/* Adjust property to match your data */}

          {/* Remaining form sections */}
        </Form>
      )}
    </Flex>
  );
}

export default CreateSolution;
