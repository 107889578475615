import { Button, Empty, Modal, Table, message } from 'antd';
import moment from 'moment';
import React, { act, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import axiosInstance from '../../../services/axiosInstance';
import { catchError } from '../../../utils/errorHandler';



const MobileBidsTable = ({ data, action }) => {

  const columns = [
    {
      title: 'Key',
      dataIndex: 'key',
      key: 'key',
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: (text) => (
        <span style={{ wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
          {text || 'N/A'}
        </span>
      ),
    },
  ];

  const transformDataForTable = (log) => [
    { key: 'Name', value: log.name },
    { key: 'Amount', value: log.amount },
    { key: 'Deadline', value: log.deadline ? moment(log.deadline).local().format("DD-MM-YYYY hh:mm A") : 'N/A' },
    { key: 'Currency', value: log.currency },
    {
      key: "Action",
      value: <Button type="link" onClick={() => action(log.uid)}>
        Accept
      </Button>
    },
  ];


  return (
    <div style={{ backgroundColor: '#f2f6f9', padding: '10px' }}>
      {data && data.length > 0 ? (
        data.map((log, index) => (
          <Table
            key={index}
            columns={columns}
            dataSource={transformDataForTable(log)}
            pagination={false}
            bordered
            size="small"
            style={{ marginBottom: '25px', borderRadius: '5px' }}
            showHeader={false}
          />
        ))
      ) : (
        <Empty description="No data available" />
      )}
    </div>
  );
};



const BidsComp = ({ bids, fetchOrderDetails }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const showModal = () => setIsModalVisible(true);
  const closeModal = () => setIsModalVisible(false);

  const dataSource = Array.isArray(bids)
    ? bids.map((log, index) => ({
      key: log.uid,
      name: log.name,
      amount: log.amount,
      deadline: log.deadline,
      currency: log.currency,
    }))
    : [];


  const handleAction = async (record) => {

    try {
      const response = await axiosInstance.post('task/v2/accept-bid/', { uid: record },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status === 200 && response.data.status === 'success') {
        message.success(response.data.data)
        fetchOrderDetails()
        closeModal()
      }
    } catch (err) {
      await catchError(err);
    }
  };


  return (
    <div style={{ marginBottom: '10px' }}>

      <Table
        dataSource={[
          {
            key: '1',
            label: 'Freelancer Bid:',
            value: typeof bids === 'string' ? (
              <div>{bids}</div>
            ) : Array.isArray(bids) && bids.length > 0 ? (
              <Button type="link" onClick={showModal}>
                Check Bids
              </Button>
            ) : (
              <p>No bids available.</p>
            ),
          },
        ]}
        pagination={false}
        bordered
        showHeader={false}
        rowKey="key"
      >
        <Table.Column
          title="Key"
          dataIndex="label"
          key="label"
          render={(text) => <strong>{text}</strong>}
          width="50%"
        />
        <Table.Column
          title="Value"
          dataIndex="value"
          key="value"
          render={(value) => <div style={{ textAlign: 'center' }}>{value}</div>}
          width="50%"
        />
      </Table>


      <Modal
        title="Bid Details"
        visible={isModalVisible}
        onOk={closeModal}
        onCancel={closeModal}
        footer={[
          <Button key="close" onClick={closeModal}>
            Close
          </Button>,
        ]}
        width={1000}
        style={{
          maxHeight: "500px",
          overflowY: "auto",
        }}
      >
        {isMobile ? (
          <MobileBidsTable data={bids} action={handleAction} />
        ) : (
          <Table dataSource={dataSource}
            columns={[
              { title: 'Name', dataIndex: 'name', key: 'name', render: (text) => text || 'N/A' },
              { title: 'Amount', dataIndex: 'amount', key: 'amount', render: (text) => text || 'N/A' },
              {
                title: 'Deadline',
                dataIndex: 'deadline',
                key: 'deadline',
                render: (text) =>
                  text ? moment(text).local().format("DD-MM-YYYY hh:mm A") : 'N/A',
              },
              { title: 'Currency', dataIndex: 'currency', key: 'currency', render: (text) => text || 'N/A' },
              {
                title: 'Action',
                dataIndex: 'action',
                key: 'action',
                render: (text, record) => (
                  <Button type="link" onClick={() => handleAction(record.key)}>
                    Accept
                  </Button>
                ),
              }
            ]}
            pagination={false}
            bordered
            size="small"
            footer={null}
            scroll={{ y: 350 }}
          />
        )}
      </Modal>

    </div>
  );
};

export default BidsComp;