import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Typography,
  Radio,
  Flex,
  Spin,
} from "antd";
import PayoutTable from "./PayoutTable";

import FlOrdersForPayoutService from "../../services/flOrdersForPayout";
import usePermissions from "../../hooks/usePermissions";
const PayoutModal = ({
  modalType,
  handleCloseModal,
  handleOpenModal,
  handleFormSubmit,
  form,
  data,
  isloading,
  currency,
  selectedRecord,
  submittingForm,
  tableData,
  setTableData,
  visible,
}) => {
  const [selectedStatus, setSelectedStatus] = useState(null); // Track status selection
  const { hasPermission } = usePermissions();
  const [formLoading, setFormLoading] = useState(true);
  const [statusList, setStatusList] = useState([]);
  const [pancardAvailaible, setPanCardAvailaible] = useState(false); // Moved state here

  const getStatusList = async () => {
    const response = await FlOrdersForPayoutService.payoutStatusChangeList();

    if (response.success) {
      setStatusList(response.data.data); // Store the status list from API
    }
  };

  useEffect(() => {
    getStatusList();
    console.log(selectedRecord, "selectedRecord");
    if (selectedRecord?.currency) {
      form.setFieldsValue({ currency: selectedRecord.currency });
      setFormLoading(false);
    }
  }, [selectedRecord, modalType]);

  return (
    <Modal
      className="create-order"
      visible={visible}
      width={1000}
      title={
        <div
          className="custom-modal-header"
          style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography.Title
            level={3}
            style={{
              color: "white",
              margin: 0,
              position: "relative",
              left: "12px",
            }}>
            {modalType === "deduction"
              ? "Add Deduction"
              : modalType === "payoutList"
              ? "Payout List"
              : "Add FL Payout"}
          </Typography.Title>
        </div>
      }
      footer={null}
      onCancel={handleCloseModal}>
      <Flex style={{ width: "100%" }} align="flex-end" justify="flex-end">
        {hasPermission("can_view_fl_payment_request_detail") &&
          modalType !== "payout" && (
            <Button type="link" onClick={() => handleOpenModal("payout")}>
              View Payout List
            </Button>
          )}

        {hasPermission("can_add_fl_payment_request_deduction") &&
          modalType !== "deduction" && (
            <Button
              type="link"
              onClick={() => handleOpenModal("deduction", currency)}>
              Add Deduction
            </Button>
          )}

        {hasPermission("can_update_fl_payment_request_status") &&
          modalType !== "status" && (
            <Button type="link" onClick={() => handleOpenModal("status")}>
              Change Status
            </Button>
          )}
      </Flex>

      {formLoading ? (
        <Spin
          size="large"
          style={{ display: "block", textAlign: "center", margin: "20px 0" }}
        />
      ) : modalType === "deduction" ? (
        <Form form={form} onFinish={handleFormSubmit} layout="vertical">
          <Flex gap="small">
            <Form.Item
              name="amount"
              label="Amount"
              style={{ flex: 1 }}
              rules={[{ required: true }]}>
              <Input placeholder="Deduction Amount" />
            </Form.Item>
            <Form.Item name="arear" label="Arear" style={{ flex: 1 }}>
              <Input placeholder="Arear" />
            </Form.Item>
          </Flex>

          <Form.Item name="currency" label="Currency" style={{ width: "100%" }}>
            <Input disabled />
          </Form.Item>

          <Form.Item
            name="reason"
            label="Reason"
            rules={[{ required: true }]}
            style={{ width: "100%" }}>
            <Input.TextArea placeholder="Reason for deduction" />
          </Form.Item>

          <Flex style={{ width: "100%" }} align="flex-end" justify="flex-end">
            <Button type="primary" htmlType="submit" loading={submittingForm}>
              Submit Deduction
            </Button>
          </Flex>
        </Form>
      ) : modalType === "status" ? (
        <Form form={form} onFinish={handleFormSubmit} layout="vertical">
          {/* Status Selection */}
          <Form.Item name="status" label="Status">
            <Radio.Group
              value={selectedStatus}
              onChange={(e) => {
                setSelectedStatus(e.target.value);
              }}>
              {statusList.map((status) => (
                <Radio key={status.key} value={status.key}>
                  {status.value}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>

          {/* Additional Fields (Show only when Approved) */}
          {selectedStatus === "Approved" && (
            <>
              <Form.Item
                name="amount"
                label="Amount"
                rules={[{ required: true }]}>
                <Input placeholder="Amount" />
              </Form.Item>

              <Form.Item name="currency" label="Currency">
                <Input value={selectedRecord.currency} disabled />
              </Form.Item>

              {/* <Form.Item
                name="reason"
                label="Reason"
                rules={[{ required: true }]}>
                <Input.TextArea placeholder="Enter reason (if applicable)" />
              </Form.Item> */}
            </>
          )}
          {selectedStatus && (
            <>
              <Form.Item
                name="reason"
                label="Reason"
                required
                rules={[{ required: true }]}>
                <Input.TextArea placeholder="Enter reason (if applicable)" />
              </Form.Item>
            </>
          )}

          <Typography.Text>
            Pancard :{" "}
            <span
              style={{
                color: isloading ? "blue" : pancardAvailaible ? "green" : "red",
                fontWeight: "bold",
              }}>
              {isloading
                ? "Fetching availability..."
                : pancardAvailaible
                ? "Available"
                : "Not Available"}
            </span>
          </Typography.Text>

          <Flex style={{ width: "100%" }} align="flex-end" justify="flex-end">
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Flex>
        </Form>
      ) : modalType === "payout" ? (
        <div style={{ width: "100%" }}>
          <PayoutTable
            data={data}
            loading={isloading}
            selectedRecord={selectedRecord}
            setPanCardAvailaible={setPanCardAvailaible}
            modalType={modalType}
            tableData={tableData}
            setTableData={setTableData}
          />
        </div>
      ) : null}
    </Modal>
  );
};

export default PayoutModal;
