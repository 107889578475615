import React, { useEffect, useState } from 'react';
import orderService from '../../services/OrderLists';
import { useParams } from 'react-router-dom';
import { Button, Checkbox, Table, Empty, message } from 'antd';
import SpinLoading from './SpinLoading';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import axiosInstance from '../../services/axiosInstance';
import { catchError } from '../../utils/errorHandler';

const DeleteTaskFiles = ({ fetchOrderDetails }) => {
    const [orderFiles, setOrderFiles] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const { orderKey } = useParams();

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const fetchOrderFiles = async () => {
        setLoading(true);
        try {
            const result = await orderService.getOrderFiles(orderKey);
            if (result?.data?.status === 'success') {
                setOrderFiles(result?.data?.data || []);
            }
        } catch (error) {
            console.error('Error fetching order files:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOrderFiles();
    }, [orderKey]);

    const handleFileSelect = (id) => {

        setSelectedFiles((prev) =>
            prev.includes(id) ? prev.filter((fileId) => fileId !== id) : [...prev, id]
        );
    };

    const handleDeleteFiles = async () => {
        try {
            setLoading(true);
            const result = await axiosInstance.post(`task/v2/remove-task-files/${orderKey}/`, {
                files_uid: selectedFiles
            });
            if (result?.data?.status === 'success') {
                setSelectedFiles([])
                fetchOrderDetails();
                message.success(result.data.data)
            }
        } catch (error) {
            await catchError(error)
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        {
            title: 'Select',
            dataIndex: 'checkbox',
            key: 'checkbox',
            render: (_, record) => (
                <Checkbox
                    checked={selectedFiles.includes(record.uid)}
                    onChange={() => handleFileSelect(record.uid)}
                />
            ),
        },
        {
            title: 'File Name',
            dataIndex: 'file_name',
            key: 'file_name',
        },
        {
            title: 'File Size',
            dataIndex: 'file_size',
            key: 'file_size',
            render: (text) => `${text} KB`,
        },
        {
            title: 'Created',
            dataIndex: 'created',
            key: 'created',
            render: (text) => (text ? moment(text).local().format("DD-MM-YYYY hh:mm A") : 'N/A'),
        },
    ];


    return (
        <div >
            {loading ? (
                <SpinLoading />
            ) : orderFiles.length > 0 ? (
                <>
                    {isMobile ? (
                        <div>
                            {orderFiles.map((file) => (
                                <div
                                    key={file.file_name}
                                    style={{
                                        background: '#fff',
                                        padding: '10px',
                                        marginBottom: '10px',
                                        borderRadius: '8px',
                                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                    }}
                                >
                                    <strong>{file.file_name}</strong>
                                    <p>Size: {file.file_size} KB</p>
                                    <p>Created: {moment(file.created).local().format("DD-MM-YYYY hh:mm A")}</p>
                                    <Checkbox
                                        checked={selectedFiles.includes(file.uid)}
                                        onChange={() => handleFileSelect(file.uid)}
                                    >
                                        Select
                                    </Checkbox>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <Table
                            rowKey="id"
                            columns={columns}
                            dataSource={orderFiles}
                            pagination={false}
                        />
                    )}

                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                        <Button
                            className="handle_submit_action_button"
                            onClick={handleDeleteFiles}
                            disabled={selectedFiles.length === 0}
                        >
                            Delete File
                        </Button>
                    </div>
                </>
            ) : (
                <Empty description="No files found" />
            )}
        </div>
    );
};

export default DeleteTaskFiles;
