import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Flex,
  Select,
  Table,
  message,
  Button,
  Typography,
  Tag,
  Spin,
  Popover,
  Avatar,
} from "antd";
import orderService from "../../services/OrderLists";
import { FilterOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import moment from "moment";
import { debounce } from "lodash";
import moveToFlAssociatesService from "../../services/moveFlAssociatesService";
import MovedToFlAssociateFilters from "../../page-components/Moved To Fl Associates/MovedToFlAssocaitesFilters";
import { useMediaQuery } from "react-responsive";
import MobileViewDeductions from "../../page-components/Deduction/MobileViewDeductions";
import MobileViewMovedToFLAssociates from "../../page-components/Moved To Fl Associates/MobleViewMovedToFLAssociates";
const { Option } = Select;
const { Paragraph } = Typography;

const MoveToFLassociates = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [moveToFLassociatesOrderList, setMoveToFLassociatesOrderList] =
    useState([]);
  const [statusList, setStatusList] = useState([]);
  const [flList, setFlList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const [filtersList, setFiltersList] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({});

  // Fetch moveToFLassociatesOrderList with debounce to prevent excessive API calls
  const fetchStatusList = async () => {
    try {
      const response = await orderService.getStatusList();

      if (response) {
        const newStatusList = {};
        for (const [key, value] of Object.entries(response.data.data)) {
          newStatusList[value] = parseInt(key);
        }
        setStatusList(newStatusList);
      } else {
        console.error("Failed to fetch status list or invalid response format");
        console.error("Response:", response);
      }
    } catch (error) {
      console.error("Error fetching status list:", error);
    }
  };
  const fetchFlList = async () => {
    try {
      const response = await moveToFlAssociatesService.getFlList();

      if (response) {
       console.log(response.data.data)
        setFlList(response.data.data);
        // console.log(newStatusList , 'new')
      } else {
        console.error("Failed to fetch status list or invalid response format");
        console.error("Response:", response);
      }
    } catch (error) {
      console.error("Error fetching status list:", error);
    }
  };
  const fetchDeductions = useCallback(
    debounce(async () => {
      setLoading(true);
      try {
        const response =
          await moveToFlAssociatesService.getmoveToFlAssociatesOrderList(
            pagination.current,
            pagination.pageSize,
            filters
          );

        if (response.success) {
          setFiltersList(response?.data?.data?.filters);
          setMoveToFLassociatesOrderList(response.data.data.results);

          setPagination((prev) => ({
            ...prev,
            total: response.data.data.count || 0,
          }));
        }
      } catch (error) {
        console.error("Error fetching moveToFLassociatesOrderList:", error);
      } finally {
        setLoading(false);
      }
    }, 300),
    [pagination] // Depend on `pagination` to ensure it has the latest values
  );

  // Fetch data when selectedOption or filters change
  useEffect(() => {
    fetchStatusList();
    fetchFlList()
    if ((pagination.current && pagination.pageSize, filters)) {
      fetchDeductions();
    }
  }, [filters, pagination.current, pagination.pageSize]);

  const columns = useMemo(() => {
    if (moveToFLassociatesOrderList.length === 0) return [];

    const keys = Object.keys(moveToFLassociatesOrderList[0]).filter(
      (key) =>
        ![
          "uid",
          "id",
          "added_on",
          "tags",
          "status",
          "key",
          "fl_deadline",
          "freelancer",
        ].includes(key)
    );

    const keyColumn = {
      title: "Key",
      dataIndex: "key",
      key: "key",
      fixed: "left",
      width: 150,
      render: (text) => (
        <Link
          to={`/order-management/my-orders/${text}`}
          style={{ color: "#1890ff" }}>
          {text}
        </Link>
      ),
    };

    const statusColors = {
      "In Progress": "orange",
      Cancelled: "volcano",
      Completed: "green",
      Unpaid: "red",
    };

    const statusColumn = {
      title: "Status",
      dataIndex: "status",
      key: "status",
      fixed: "right",
      width: 200,
      render: (text) => (
        <Tag
          color={statusColors[text] || "purple"}
          style={{ padding: "5px 10px", borderRadius: 15 }}>
          {text}
        </Tag>
      ),
    };

    const freelancerColumn = {
      title: "Freelancer",
      dataIndex: "freelancer",
      key: "freelancer",
      width: 220,
      render: (text, record) => {
        if (!record.freelancer) return "N/A";

        const nameParts = record.freelancer.split(" ");
        const initials =
          nameParts.length > 1
            ? `${nameParts[0][0]}${
                nameParts[nameParts.length - 1][0]
              }`.toUpperCase()
            : nameParts[0][0].toUpperCase();

        return (
          <Popover
            content={
              <div style={{ textAlign: "center" }}>
                <Typography.Text>{record.freelancer}</Typography.Text>
              </div>
            }
            title="Freelancer Details">
            <Flex align="flex-start" vertical gap={2}>
              <Avatar
                style={{ backgroundColor: "#1890ff", fontWeight: "bold" }}>
                {initials}
              </Avatar>
              <span style={{ color: "grey" }}>
                {" "}
                {record.fl_deadline
                  ? moment(record.fl_deadline).format("DD-MM-YYYY hh:mm A")
                  : null}
              </span>
            </Flex>
          </Popover>
        );
      },
    };

    const otherColumns = keys.map((key) => ({
      title: key
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase()),
      dataIndex: key,
      key: key,
      width: key === "title" ? 200 : 180,

      ellipsis: key === "title",
      render: (text, record) => {
        if (key === "title" && text) {
          return (
            <Popover
              content={<Typography.Text>{text}</Typography.Text>}
              title="Full Title">
              <Typography.Text ellipsis>{text}</Typography.Text>
            </Popover>
          );
        }
        if (key === "remaining_words") {
          return <Tag color="purple">{text}</Tag>;
        }
        if (key === "moved_to_fl") {
          return (
            <Typography.Text strong>
              {record.moved_to_fl ? "Yes" : "No"}
            </Typography.Text>
          );
        }
        if (key === "feedback_due") {
          return (
            <Typography.Text strong>
              {record.feedback_due ? "Yes" : "No"}
            </Typography.Text>
          );
        }
        if (key === "move_to_fl_date" && record.move_to_fl_date) {
          return (
            <span style={{ color: "grey" }}>
              {moment(record.move_to_fl_date)
                .local()
                .format("DD-MM-YYYY hh:mm a")}
            </span>
          );
        }
        if (key === "start_date" && record.start_date) {
          return (
            <span style={{ color: "grey" }}>
              {moment(record.start_date).local().format("DD-MM-YYYY hh:mm a")}
            </span>
          );
        }
        if (key === "client_deadline" && record.client_deadline) {
          return (
            <span style={{ color: "grey" }}>
              {moment(record.client_deadline)
                .local()
                .format("DD-MM-YYYY hh:mm a")}
            </span>
          );
        }
        return text ?? "N/A";
      },
    }));

    return [keyColumn, ...otherColumns, freelancerColumn, statusColumn];
  }, [moveToFLassociatesOrderList]);

  const handleSearchClick = (newFilters) => {
    setFilters(newFilters);
    setPagination((prev) => ({ ...prev, current: 1 }));
  };

  const handleCancelSearch = () => {
    setFilters({});
    setPagination((prev) => ({ ...prev, current: 1 }));
  };

  return (
    <Flex vertical style={{ padding: 10 }} gap={10}>
      {showFilters && (
        <div className="filter-container">
          <MovedToFlAssociateFilters
            filtersList={filtersList}
            onSearch={handleSearchClick}
            onCancel={handleCancelSearch}
            statusList={statusList}
            flList={flList}
          />
        </div>
      )}
      {!loading && (
        <Flex
          gap={isMobile ? 8 : 0}
          style={{ width: "100%", flexDirection: isMobile ? "column" : "row" }}
          justify="space-between"
          align={isMobile ? "flex-end" : "center"}>
          <Paragraph
            style={{
              fontWeight: 500,
              opacity: 0.6,
              textAlign: "left",
              margin: 0,
              padding: 0,
            }}>
            Showing{" "}
            {Math.min(
              ((pagination.current ?? 1) - 1) * (pagination.pageSize ?? 20) + 1,
              pagination.total ?? 0
            )}{" "}
            -{" "}
            {Math.min(
              (pagination.current ?? 1) * (pagination.pageSize ?? 20),
              pagination.total ?? 0
            )}{" "}
            of {pagination.total ?? 0} Orders
          </Paragraph>

          <Flex gap={4} align="center">
            {/* <Select
              defaultValue="myDeductions"
              style={{ width: 200, textAlign: "left" }}
              onChange={setSelectedOption}>
              <Option value="myDeductions">My Deductions</Option>
              <Option value="allDeductions">All Deductions</Option>
            </Select> */}
            <Button
              type="primary"
              icon={<FilterOutlined />}
              onClick={() => setShowFilters(!showFilters)}
              style={{ marginLeft: "10px", background: "#040724" }}
            />
          </Flex>
        </Flex>
      )}
      {isMobile ? (
        loading ? (
          <Spin size="large" />
        ) : (
          <MobileViewMovedToFLAssociates
            moveToFLassociatesOrderList={moveToFLassociatesOrderList}
            pagination={pagination}
            setPagination={setPagination}
          />
        )
      ) : (
        <Table
          columns={columns}
          dataSource={moveToFLassociatesOrderList}
          loading={loading}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            onChange: (page, pageSize) => {
              setPagination({ ...pagination, current: page, pageSize });
            },
          }}
        //   onChange={fetchDeductions}
          rowKey="id"
          scroll={{ x: 1800 }} // Ensures horizontal scrolling
        />
      )}
    </Flex>
  );
};

export default MoveToFLassociates;
