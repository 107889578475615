import React, { useState, useEffect } from "react";
import { Button, Flex, message, Modal, Typography, Tabs, Select } from "antd";
import MobileViewAcceptRejectList from "./MobileViewAcceptRejectList";
import AcceptRejectOrdersTable from "./AcceptRejectOrdersTable";
import AcceptRejectOrdersService from "../../services/acceptRejectOrdersService";
import RejectOrderModal from "./RejectOrderModal";
import AcceptRejectFilters from "./AcceptRejectFilters";
import { useMediaQuery } from "react-responsive";
import { FilterOutlined } from "@ant-design/icons";
const { TabPane } = Tabs;

const AcceptRejectOrdersMain = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const [payoutList, setPayoutList] = useState([]);
  const [urgencyFilter, setUrgencyFilter] = useState("all"); // Default filter to "All"

  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const [showFilters, setShowFilters] = useState(false);

  const [filters, setFilters] = useState({});
  const [filtersList, setFiltersList] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [confirmAcceptModal, setConfirmAcceptModal] = useState(false);
  const [rejectModalVisible, setRejectModalVisible] = useState(false);
  const [acceptingLoader, setAcceptingLoader] = useState(false);
  const [timeElapsedArray, setTimeElapsedArray] = useState([]);
  const tabConfig = [
    { key: "all", label: "All", color: "red", bgColor: "#9CA3AF" },

    { key: "red", label: "Deadline Missed", color: "red", bgColor: "#FFCCCB" },
    {
      key: "orange",
      label: "Deadline > 1 Day",
      color: "orange",
      bgColor: "#FFE5B4",
    },
    {
      key: "yellow",
      label: "Deadline in 2-4 Days",
      color: "#FACA15",
      bgColor: "#FFF9C4",
    },
    {
      key: "green",
      label: "Deadline > 4 Days",
      color: "green",
      bgColor: "#C8E6C9",
    },
  ];

  const fetchOrders = async (filterKey) => {
    setLoading(true);
    try {
      const response = await AcceptRejectOrdersService.acceptRejectOrdersList(
        urgencyFilter,
        pagination.current,
        pagination.pageSize,
        filterKey
      );
      if (response.success) {
        setPayoutList(response.data.data.results || []);
        setPagination((prev) => ({
          ...prev,
          total: response.data.data.count || 0,
        }));
      } else {
        message.error("Failed to fetch orders.");
      }
    } catch (error) {
      message.error("Error fetching orders.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders(urgencyFilter);
  }, [urgencyFilter, pagination.current, pagination.pageSize]);

  const handleAcceptClick = (record) => {
    setSelectedOrder(record);
    setConfirmAcceptModal(true);
  };

  const confirmAccept = async () => {
    if (!selectedOrder) return;
    try {
      setAcceptingLoader(true);
      const response = await AcceptRejectOrdersService.acceptOrder(
        selectedOrder?.key
      );
      if (response.success) {
        message.success("Order accepted successfully!");
        setAcceptingLoader(false);
        fetchOrders();
      }
    } catch (error) {
      setAcceptingLoader(false);

      message.error("Error accepting order.");
    } finally {
      setAcceptingLoader(false);
      setRejectModalVisible(false);
      setConfirmAcceptModal(false);
      setSelectedOrder(null);
    }
  };

  const handleRejectClick = (record) => {
    setSelectedOrder(record);
    setRejectModalVisible(true);
  };
  const handleSearchClick = (newFilters) => {
    setFilters(newFilters);
    setPagination((prev) => ({ ...prev, current: 1 }));
  };

  const handleCancelSearch = () => {
    setFilters({});
    setPagination((prev) => ({ ...prev, current: 1 }));
  };
  return (
    <Flex vertical style={{ padding: 10 }}>
      {!loading && (
        <Flex
          gap={isMobile ? 8 : 0}
          style={{ width: "100%", flexDirection: isMobile ? "column" : "row" }}
          justify="space-between"
          align={isMobile ? "flex-end" : "center"}>
          <Typography.Paragraph
            style={{
              fontWeight: 500,
              opacity: 0.6,
              textAlign: "left",
              margin: 0,
              padding: 0,
            }}>
            Showing{" "}
            {Math.min(
              (pagination.current - 1) * pagination.pageSize + 1,
              pagination.total
            )}{" "}
            -{" "}
            {Math.min(
              pagination.current * pagination.pageSize,
              pagination.total
            )}{" "}
            of {pagination.total} Orders
          </Typography.Paragraph>
        </Flex>
      )}
      {isMobile ? (
        <>
          <Select
            value={urgencyFilter}
            onChange={setUrgencyFilter}
            style={{ width: "100%", marginBottom: 16 }}
            options={tabConfig.map(({ key, label }) => ({ value: key, label }))}
          />
          <MobileViewAcceptRejectList
            payoutData={payoutList}
            onAccept={handleAcceptClick}
            onReject={handleRejectClick}
          />
        </>
      ) : (
        <Tabs activeKey={urgencyFilter} onChange={setUrgencyFilter}>
          {tabConfig.map((tab) => (
            <TabPane
              key={tab.key}
              tab={
                <span
                  style={{
                    color: "black",
                    padding: "8px 16px",
                    borderRadius: "8px 8px 0 0",
                    backgroundColor: tab.bgColor,
                  }}>
                  {tab.label}
                </span>
              }>
              <AcceptRejectOrdersTable
                payoutData={payoutList}
                loading={loading}
                pagination={pagination}
                setPagination={setPagination}
                onAccept={handleAcceptClick}
                onReject={handleRejectClick}
              />
            </TabPane>
          ))}
        </Tabs>
      )}

      <Modal
        title={
          <div
            className="custom-modal-header"
            style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography.Title
              level={3}
              style={{
                color: "white",
                margin: 0,
                position: "relative",
                left: "12px",
              }}>
              Confirm Acceptance
            </Typography.Title>
            {/* <CloseOutlined
                style={{ fontSize: "16px", marginRight: "10px" }}
                onClick={onClose}
              /> */}
          </div>
        }
        className="create-order"
        visible={confirmAcceptModal}
        onOk={confirmAccept}
        onCancel={() => setConfirmAcceptModal(false)}
        okText="Accept"
        footer={
          <Button
            key="accept"
            style={{ background: "#22c55e", color: "white" }}
            onClick={() => confirmAccept(selectedOrder?.key)}
            loading={acceptingLoader}>
            Accept
          </Button>
        }
        cancelText="Cancel">
        <p>Are you sure you want to accept this order?</p>
      </Modal>

      <RejectOrderModal
        visible={rejectModalVisible}
        onClose={() => setRejectModalVisible(false)}
        selectedOrder={selectedOrder}
        confirmAccept={confirmAccept}
        acceptingLoader={acceptingLoader}
        fetchOrders={fetchOrders}
        setSelectedOrder={setSelectedOrder}
        setRejectModalVisible={setRejectModalVisible}
        
    
      />
    </Flex>
  );
};

export default AcceptRejectOrdersMain;
