import React from "react";
import { Table } from "antd";
import { maskContact, maskEmail } from "../../helpers/helper";

const ClientDetailsPanel = ({ clientInfo }) => {
  const clientColumns = [
    { title: 'Field', dataIndex: 'field', key: 'field' },
    { title: 'Value', dataIndex: 'value', key: 'value' },
  ];

  // Dynamically generate the data based on the keys present in clientInfo
  const clientData = Object.keys(clientInfo || {}).map((key) => {
    let value = clientInfo[key];

    // Mask sensitive information
    if (key === "email" && value) {
      value = maskEmail(value);
    } else if (key === "contact_no" && value) {
      value = maskContact(value);
    }

    // Convert camelCase or snake_case keys to readable format
    const formattedKey = key
      .replace(/_/g, " ") // Replace underscores with spaces
      .replace(/([A-Z])/g, " $1") // Add space before capital letters
      .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter

    return {
      key,
      field: formattedKey,
      value: value || "N/A",
    };
  });

  return (
    <Table
      columns={clientColumns}
      dataSource={clientData}
      pagination={false}
      showHeader={false}
      bordered
      size="small"
      style={{ width: "100%" }}
      rowKey={(record) => record.key}
    />
  );
};

export default ClientDetailsPanel;
