export const customTheme = {
  token: {
    colorPrimary: '#004DBC',
  },
  components: {
    Button: {
      colorPrimary: '#004DBC',
    },
    Form: {
      controlHeightLG: 48,
      controlHeightSM: 32,
    },
  },
};
