import React, { useState, useEffect } from "react";
import { Input, Button, Select, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import orderService from "../../services/OrderLists";
import OrderProgressTraclingService from "../../services/orderProgressTrackingService";
import dayjs from "dayjs";
import useIsMobile from "../../hooks/useIsMobile";
import { DatePicker } from "antd";

const { Option } = Select;

const OrderProgressTrackingFilter = ({
  onSearch,
  onCancel,
  filtersList,
}) => {
  const { isMobile } = useIsMobile();
  const [filters, setFilters] = useState({});
  
  // ✅ Status Options
  const [statusOptions, setStatusOptions] = useState([]);
  const [statusLoading, setStatusLoading] = useState(false);

  // ✅ Hierarchy Options
  const [hierarchyOptions, setHierarchyOptions] = useState([]);
  const [hierarchyLoading, setHierarchyLoading] = useState(false);

  // ✅ Fetch Status Options
  useEffect(() => {
    const fetchStatusOptions = async () => {
      setStatusLoading(true);
      try {
        const statusResponse = await orderService.getWriterStatusDropdown();
        if (
          statusResponse.success &&
          Array.isArray(statusResponse.data.data) &&
          statusResponse.data.data.length > 0
        ) {
          const updatedStatusList = [
            ...statusResponse.data.data,
            { label: "Not Assigned", key: "not_assigned" }, // ✅ Add hardcoded option
          ];
          setStatusOptions(updatedStatusList);
        }
      } catch (error) {
        message.error("Failed to load status options");
      } finally {
        setStatusLoading(false);
      }
    };
    fetchStatusOptions();
  }, []);

  // ✅ Fetch Hierarchy Options
  useEffect(() => {
    const fetchHierarchyOptions = async () => {
      setHierarchyLoading(true);
      try {
        const hierarchyResponse = await OrderProgressTraclingService.getOrderTrackingHierarchy();
        if (
          hierarchyResponse.success &&
          Array.isArray(hierarchyResponse.data.data)
        ) {
          // ✅ Map UID to Name for display
          const formattedHierarchy = hierarchyResponse.data.data.map((item) => ({
            key: item.uid,
            label: item.name,
          }));
          setHierarchyOptions(formattedHierarchy);
        }
      } catch (error) {
        message.error("Failed to load hierarchy options");
      } finally {
        setHierarchyLoading(false);
      }
    };
    fetchHierarchyOptions();
  }, []);

  const handleSearch = () => {
    const formattedStartDeadline = filters.started_on
      ? dayjs(filters.started_on).isValid()
        ? dayjs(filters.started_on).format("YYYY-MM-DD")
        : null
      : null;

    const updatedFilters = { ...filters };
    if (formattedStartDeadline) {
      updatedFilters.started_on = formattedStartDeadline;
    } else {
      delete updatedFilters.started_on;
    }

    onSearch(updatedFilters);
  };

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, [key]: value };
      if (!value) delete updatedFilters[key]; 
      return updatedFilters;
    });
  };

  const handleCancel = async () => {
    setFilters({});
    await onCancel();
  };

  const renderFilter = (filter) => {
    switch (filter) {
      case "hierarchy":
        return (
          <Select
            placeholder="Select Hierarchy"
            style={{ width: isMobile ? "80vw" : 300, textAlign: "left" }}
            value={filters.hierarchy ?? undefined}
            onChange={(value) => handleFilterChange("hierarchy", value)}
            allowClear
            loading={hierarchyLoading} // ✅ Show loading state while fetching
          >
            {hierarchyOptions.map(({ label, key }) => (
              <Option key={key} value={key}>
                {label}
              </Option>
            ))}
          </Select>
        );

        case "status":
          const defaultStatus = statusOptions.find(({ key }) => key === "started")?.key;
        
          return (
            <Select
              placeholder="Status"
              style={{ width: isMobile ? "80vw" : 300, textAlign: "left" }}
              value={filters.status ?? defaultStatus} // ✅ Set default if not selected
              onChange={(value) => handleFilterChange("status", value)}
              showSearch
              optionFilterProp="children"
              allowClear
              loading={statusLoading} // ✅ Show loading while fetching
            >
              {statusOptions.map(({ label, key }) => (
                <Option key={key} value={key}>
                  {label}
                </Option>
              ))}
            </Select>
          );
        
        case "started_on":
          return (
            <DatePicker
              style={{ width: isMobile ? "80vw" : 300 }}
              placeholder="Filter by Start Date"
              value={filters.started_on}
              onChange={(value) => handleFilterChange("started_on", value)}
              allowClear
            />
          );
      default:
        return (
          <Input
            placeholder={`Filter by ${filter}`}
            style={{ width: 300 }}
            value={filters[filter] || ""}
            onChange={(e) => handleFilterChange(filter, e.target.value)}
          />
        );
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={{ background: "#eceff1", padding: "20px", borderRadius: "8px" }}>
        <div style={{
          marginBottom: 16,
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}>
          {filtersList.map((filter) => (
            <div key={filter} style={{ marginRight: 16 }}>
              {renderFilter(filter)}
            </div>
          ))}
          <div style={{
            display: "flex",
            gap: "20px",
            justifyContent: "space-between",
            width: "auto",
          }}>
            <Button
              type="primary"
              style={{ width: 140, background: "#040724" }}
              icon={<SearchOutlined />}
              onClick={handleSearch}>
              Search
            </Button>
            <Button style={{ width: 140 }} type="default" onClick={handleCancel}>
              Reset
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderProgressTrackingFilter;
