import React from 'react';
import { Empty, Table } from 'antd';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';

const MobileTaskLogs = ({ data }) => {

  const columns = [
    {
      title: 'Key',
      dataIndex: 'key',
      key: 'key',
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: (text) => text || 'N/A',
    },
  ];

  const transformDataForTable = (log) => [
    { key: 'Task', value: log.title },
    { key: 'Date/Time', value: log.date_time ? moment(log.date_time).local().format("YYYY-MM-DD hh:mm A") : 'N/A' },
    { key: 'Status', value: log.status },
    { key: 'Word', value: log.word },
    { key: 'Word Internal', value: log.word_internal },
    { key: 'Deadline Soft', value: log.deadline_soft ? moment(log.deadline_soft).local().format("YYYY-MM-DD hh:mm A") : 'N/A' },
    { key: 'Deadline Hard', value: log.deadline_hard ? moment(log.deadline_hard).local().format("YYYY-MM-DD hh:mm A") : 'N/A' },
    { key: 'Moved to Freelancer', value: log.moved_to_freelancer_portal ? 'True' : 'False' },
    { key: 'Moved to Freelancer Team', value: log.moved_to_freelancer_team ? 'True' : 'False' },
    { key: 'History Change Reason', value: log.history_change_reason },
    { key: 'Changed By', value: log.changed_by },
    { key: 'Comment', value: log.comment },
    {
      key: 'Latest Changed Field',
      value: Array.isArray(log.latest_changed_field) ? log.latest_changed_field.join(', ') : 'N/A'
    },
  ];

  return (
    <div style={{ backgroundColor: "#f2f6f9", padding: '10px' }}>
      {data && data.length > 0 ? (
        data.map((log, index) => (
          <Table
            key={index}
            columns={columns}
            dataSource={transformDataForTable(log)}
            pagination={false}
            bordered
            size="small"
            style={{ marginBottom: '25px' }}
            showHeader={false}
          />
        ))
      ) : (
        <Empty description="No data available" />
      )}
    </div>
  );
};

const TaskLogsComp = ({ taskLogsData }) => {

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const columns = [
    { title: 'Task', dataIndex: 'task', key: 'task', render: (text) => text || 'N/A' },
    {
      title: 'Date/Time', dataIndex: 'date_time', key: 'date_time',
      render: (text) => {
        return text
          ? moment(text).local().format("YYYY-MM-DD hh:mm A")
          : 'N/A'
      }
    },
    { title: 'Status', dataIndex: 'status', key: 'status', render: (text) => text || 'N/A' },
    { title: 'Word', dataIndex: 'word', key: 'word', render: (text) => text || 'N/A' },
    { title: 'Word Internal', dataIndex: 'word_internal', key: 'word_internal', render: (text) => text || 'N/A' },
    {
      title: 'Deadline Soft', dataIndex: 'deadline_soft', key: 'deadline_soft',
      render: (text) => {
        return text
          ? moment(text).local().format("YYYY-MM-DD hh:mm A")
          : 'N/A'
      }
    },
    {
      title: 'Deadline Hard', dataIndex: 'deadline_hard', key: 'deadline_hard',
      render: (text) => {
        return text
          ? moment(text).local().format("YYYY-MM-DD hh:mm A")
          : 'N/A'
      }
    },
    { title: 'Moved to Freelancer', dataIndex: 'moved_to_freelancer', key: 'moved_to_freelancer', render: (text) => (text ? 'True' : 'False') },
    { title: 'Moved to Freelancer Team', dataIndex: 'moved_to_freelancer_team', key: 'moved_to_freelancer_team', render: (text) => (text ? 'True' : 'False') },
    { title: 'History Change Reason', dataIndex: 'history_change_reason', key: 'history_change_reason', render: (text) => text || 'N/A' },
    { title: 'Changed By', dataIndex: 'changed_by', key: 'changed_by', render: (text) => text || 'N/A' },
    { title: 'Comment', dataIndex: 'comment', key: 'comment', render: (text) => text || 'N/A' },
    {
      title: 'Latest Changed Field',
      dataIndex: 'latest_changed_field',
      key: 'latest_changed_field',
      render: (text) => (text && Array.isArray(text)) ? text.join(', ') : 'N/A',
    },
  ];

  const dataSource = taskLogsData.map((log, index) => ({
    key: index,
    task: log.title,
    date_time: log.date_time,
    status: log.status,
    word: log.word,
    word_internal: log.word_internal,
    deadline_soft: log.deadline_soft,
    deadline_hard: log.deadline_hard,
    moved_to_freelancer: log.moved_to_freelancer_portal,
    moved_to_freelancer_team: log.moved_to_freelancer_team,
    history_change_reason: log.history_change_reason,
    changed_by: log.changed_by,
    comment: log.comment,
    latest_changed_field: log.latest_changed_field,
  }));

  return (
    <div>
      <h3>TASK HISTORY</h3>
      {isMobile ? (
        <MobileTaskLogs data={taskLogsData} />
      ) : (
        <div className="task_logs_css">
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            bordered
            scroll={{ y: 350 }}
          />
        </div>
      )}
    </div>
  );
};

export default TaskLogsComp;
