// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-tooltip {
  background-color: #e2dcdc;
  padding: 10px;
}
.inputsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/page-components/SalesAndAnalytics/salesGraph.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;AACf;AACA;EACE,aAAa;EACb,eAAe;EACf,SAAS;EACT,mBAAmB;AACrB","sourcesContent":[".custom-tooltip {\n  background-color: #e2dcdc;\n  padding: 10px;\n}\n.inputsContainer {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 15px;\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
