import React, { useState, useEffect } from "react";
import { Input, Button, Select, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import clientService from "../../services/clientService";

import dayjs from "dayjs";
import useIsMobile from "../../hooks/useIsMobile";
import { DatePicker } from "antd";
const { Option } = Select;

const DeductionSearchFilters = ({
  onSearch,
  pagination,
  getAllClients,
  filtersList,
  typeChoices,
}) => {
  const { isMobile } = useIsMobile();
  const [filters, setFilters] = useState({});

  const [websites, setWebsites] = useState([]);
  const [owners, setOwners] = useState([]);
  const [visibilities, setVisibilities] = useState([]);


//   useEffect(() => {
//     const fetchWebsites = async () => {
//       const result = await clientService.getWebsitesList();
//       if (result) {
//         setWebsites(result.data.data);
//       } else {
//         message.error("Failed to load websites");
//       }
//     };

//     const fetchOwners = async () => {
//       const result = await clientService.getOwnersList();
//       if (result.success) {
//         setVisibilities(result.data.data);
//         setOwners(result.data.data);
//       } else {
//         message.error("Failed to load owners");
//       }
//     };

//     fetchWebsites();
//     fetchOwners();
//   }, []);

//   const websiteOptions = websites.map((website) => ({
//     label: website.name,
//     value: website.name,
//   }));

  // };

  const handleSearch = () => {
    const formattedAddedOnDate = filters.added_on
      ? dayjs(filters.added_on).format("YYYY-MM-DD") // Formats as ISO string
      : null;
    onSearch({
      ...filters,

      added_on: formattedAddedOnDate,
    });
  };

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const handleCancel = async () => {
    setFilters({});
    await getAllClients();
  };

  const renderFilter = (filter) => {
    switch (filter) {
      case "added_on":
        return (
          <DatePicker
            style={{ width: isMobile ? "80vw" : 300 }}
            placeholder="Filter by Added On"
            value={filters.start_date}
            onChange={(value) => handleFilterChange("added_on", value)}
          />
        );

      default:
        return (
          <Input
            placeholder={`Filter by ${filter}`}
            style={{ width: 300 }}
            value={filters[filter] || ""}
            onChange={(e) => handleFilterChange(filter, e.target.value)}
          />
        );
    }
  };

  const isWebsiteFilterPresent = filtersList.includes("website");

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{ background: "#eceff1", padding: "20px", borderRadius: "8px" }}>
        <div
          style={{
            marginBottom: 16,
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}>
          {filtersList.map((filter) => (
            <div key={filter} style={{ marginRight: 16 }}>
              {renderFilter(filter)}
            </div>
          ))}
          <div
            style={{
              display: "flex",
              gap: "20px",
              marginRight: isMobile ? 0 : "12px",
              justifyContent: isWebsiteFilterPresent
                ? "flex-end"
                : "space-between",
              width: isWebsiteFilterPresent ? "100%" : "auto",
            }}>
            <Button
              type="primary"
              style={{ width: 140, background: "#040724" }}
              icon={<SearchOutlined />}
              onClick={handleSearch}>
              Search
            </Button>
            <Button
              style={{ width: 140 }}
              type="default"
              onClick={handleCancel}>
              Reset
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeductionSearchFilters;
