import React, { useState, useEffect } from "react";
import { Input, Button, Select, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import clientService from "../../services/clientService";

import dayjs from "dayjs";
import useIsMobile from "../../hooks/useIsMobile";
import { DatePicker } from "antd";
const { Option } = Select;

const MovedToFlAssociateFilters = ({
  onSearch,
  pagination,
  onCancel,
  filtersList,
  typeChoices,
  statusList,
  flList,
}) => {
  const { isMobile } = useIsMobile();
  const [filters, setFilters] = useState({});

  // };

  const handleSearch = () => {
    const formattedClientDeadline = filters.client_deadline
      ? dayjs(filters.client_deadline).isValid()
        ? dayjs(filters.client_deadline).format("YYYY-MM-DD")
        : null
      : null;

    const formattedFlDeadline = filters.fl_deadline
      ? dayjs(filters.fl_deadline).isValid()
        ? dayjs(filters.fl_deadline).format("YYYY-MM-DD")
        : null
      : null;

    const updatedFilters = { ...filters };

    // Apply formatted dates if valid, otherwise remove them
    if (formattedClientDeadline) {
      updatedFilters.client_deadline = formattedClientDeadline;
    } else {
      delete updatedFilters.client_deadline;
    }

    if (formattedFlDeadline) {
      updatedFilters.fl_deadline = formattedFlDeadline;
    } else {
      delete updatedFilters.fl_deadline;
    }

    // Call the search function with updated filters
    onSearch(updatedFilters);
  };
  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, [key]: value };

      if (!value) {
        delete updatedFilters[key]; // ✅ Remove key if value is cleared
      }

      return updatedFilters;
    });
  };

  //   const handleFilterChange = (key, value) => {
  //     setFilters((prevFilters) => ({
  //       ...prevFilters,
  //       [key]: value,
  //     }));
  //   };

  const handleCancel = async () => {
    setFilters({});
    await onCancel();
  };

  const renderFilter = (filter) => {
    switch (filter) {
      case "client_deadline":
        return (
          <DatePicker
            style={{ width: isMobile ? "80vw" : 300 }}
            placeholder="Filter by Client Deadline"
            value={filters.client_deadline}
            onChange={(value) => handleFilterChange("client_deadline", value)}
            allowClear
          />
        );
      case "fl_deadline":
        return (
          <DatePicker
            style={{ width: isMobile ? "80vw" : 300 }}
            placeholder="Filter by FL Deadline"
            value={filters.fl_deadline}
            onChange={(value) => handleFilterChange("fl_deadline", value)}
            allowClear
          />
        );
      case "freelancer":
        return (
          <Select
            placeholder="Select Freelancer"
            style={{ width: isMobile ? "80vw" : 300, textAlign: "left" }}
            value={filters.freelancer ?? undefined}
            onChange={(value) =>
              handleFilterChange("freelancer", value || null)
            } // ✅ If cleared, send null
            showSearch
            optionFilterProp="children"
            allowClear
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }>
            {flList.map((freelancer) => (
              <Select.Option
                key={freelancer.username}
                value={freelancer.username}>
                {freelancer.full_name}
              </Select.Option>
            ))}
          </Select>
        );

      case "status":
        return (
          <Select
            placeholder="Status"
            style={{
              width: isMobile ? "80vw" : 300,
              textAlign: "left",
            }}
            value={filters.status || undefined}
            onChange={(value) => handleFilterChange("status", value)}
            showSearch={true} // Enable search
            optionFilterProp="children" // Filter options by their label
            allowClear>
            {Object.entries(statusList || {}).map(([label, value]) => (
              <Select.Option key={value.toString()} value={value.toString()}>
                {label}
              </Select.Option>
            ))}
          </Select>
        );
      default:
        return (
          <Input
            placeholder={`Filter by ${filter}`}
            style={{ width: 300 }}
            value={filters[filter] || ""}
            onChange={(e) => handleFilterChange(filter, e.target.value)}
          />
        );
    }
  };

  const isWebsiteFilterPresent = filtersList.includes("website");

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{ background: "#eceff1", padding: "20px", borderRadius: "8px" }}>
        <div
          style={{
            marginBottom: 16,
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}>
          {filtersList.map((filter) => (
            <div key={filter} style={{ marginRight: 16 }}>
              {renderFilter(filter)}
            </div>
          ))}
          <div
            style={{
              display: "flex",
              gap: "20px",
              marginRight: isMobile ? 0 : "12px",
              justifyContent: isWebsiteFilterPresent
                ? "flex-end"
                : "space-between",
              width: isWebsiteFilterPresent ? "100%" : "auto",
            }}>
            <Button
              type="primary"
              style={{ width: 140, background: "#040724" }}
              icon={<SearchOutlined />}
              onClick={handleSearch}>
              Search
            </Button>
            <Button
              style={{ width: 140 }}
              type="default"
              onClick={handleCancel}>
              Reset
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MovedToFlAssociateFilters;
