import React from "react";
import { useNavigate } from "react-router-dom";
import { Drawer, Menu, message, Button, Flex, Typography } from "antd";
import {
  PoweroffOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import { Layout } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebarDrawer, toggleSidebar } from "../../redux/slices/uiSlice";
import { logout } from "../../redux/slices/authSlice";
import { useFilteredNavMenu } from "../../config/NavMenu";

const { Sider } = Layout;
const { Title } = Typography;

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { collapsed, drawerOpen } = useSelector((state) => ({
    collapsed: state.ui.collapsed,
    drawerOpen: state.ui.drawerOpen,
  }));

  const navMenu = useFilteredNavMenu();

  const handleMenuClick = (e) => {
    const selectedItem = navMenu
      .flatMap((item) => (item.children ? [item, ...item.children] : [item]))
      .find((item) => item.key === e.key);

    if (selectedItem) {
      navigate(selectedItem.route);
      if (window.innerWidth <= 768) {
        // Close the drawer on mobile after selecting a menu item
        dispatch(toggleSidebarDrawer());
      }
    }
  };

  const handleLogout = async () => {
    try {
      await dispatch(logout());
      message.success("Logged Out Successfully");
      navigate('/login')
    } catch (error) {
      message.error("Logout failed");
    }
  };

  const toggleCollapsed = () => {
    dispatch(toggleSidebar());
  };

  return (
    <>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        width={270}
        collapsedWidth={100}
        className="global_aside"
      >
        <Flex
          vertical
          justify="space-between"
          align={collapsed ? "center" : "center"}
          style={{ height: "100%", padding: "10px 0px" }}
        >
          <Flex
            vertical
            align={collapsed ? "center" : "center"}
            style={{ width: "100%" }}
            gap={10}
          >
            <div className="logo_case">
              <Title
                level={collapsed ? 4 : 2}
                style={{ color: "white", margin: 0, textAlign: "center" }}
              >
                {collapsed ? "LR" : "Locus RAGS"}
              </Title>
            </div>

            <Menu
              theme="dark"
              mode="inline"
              defaultSelectedKeys={["1"]}
              items={navMenu}
              onClick={handleMenuClick}
            />
          </Flex>
          <Button
            type="primary"
            style={{ background: "red", width: collapsed ? "40px" : "90%" }}
            icon={<PoweroffOutlined />}
            onClick={handleLogout}
            className="logoutButton"
          >
            {!collapsed && "Logout"}
          </Button>
        </Flex>
      </Sider>
      <Drawer
        placement="left"
        open={drawerOpen}
        onClose={() => dispatch(toggleSidebarDrawer())}
      >
        <Flex
          vertical
          align="flex-start"
          justify="space-between"
          style={{ height: "100%" }}
        >
          <Flex vertical align="flex-start" gap={10}>
            <Title
              level={2}
              style={{ color: "white", margin: 0, textAlign: "center" }}
            >
              Locus RAGS
            </Title>
            <Menu
              defaultSelectedKeys={["1"]}
              mode="inline"
              items={navMenu}
              onClick={handleMenuClick}
            />
          </Flex>
          <Button
            type="primary"
            style={{ background: "red", width: "90%" }}
            icon={<PoweroffOutlined />}
            onClick={handleLogout}
            className="logoutButton"
          >
            Logout
          </Button>
        </Flex>
      </Drawer>
    </>
  );
};

export default Sidebar;
