import React from 'react'
import FlOrderForPayoutTable from '../../page-components/Fl Order For Payout/FlOrderForPayoutTable'
import { Flex } from 'antd'

function FlOrdersForPayoutParent() {
  return (
<Flex vertical style={{padding:10}}>
<FlOrderForPayoutTable />


</Flex>



  )
}

export default FlOrdersForPayoutParent