import React, { useState, useEffect } from "react";
import { Row, Col, Typography } from "antd";
import "./grid.css";
import UserHierarchy from "./UserHierarchy";
import store from "../../redux/store";
import SalesGraph from "./SalesGraph";

function Grid() {
  const state = store.getState();
  const user = state.auth.user;
  const { Title } = Typography;
  const [isGraph, setIsGraph] = useState(true);
  const [selectedUids, setSelectedUids] = useState([]);
  const [custom, setCustom] = useState(false);
  const [hierarchyClickCount, setHierarchyClickCount] = useState(0);

  const onSelectNode = (uids) => {
    const updatedUids = uids.length > 0 ? uids : [user.user.uid];
    setSelectedUids(updatedUids);
    setCustom(updatedUids.length > 0);
    setHierarchyClickCount((prev) => prev + 1);
  };

  useEffect(() => {

    if (user && user.user && user.user.uid) {
      // setSelectedUids([user.user.uid]);
      // setCustom(true);
    }
  }, [user]);

  // useEffect(() => {
  //   setSelectedUids([user.user.uid]);
  //   setCustom(true);
  // }, [user.user.uid]);

  return (
    <div className="grid">
 
      <Row gutter={[16, 16]} justify="center" >
        <Col
          xs={24}
          sm={24}
          md={16}
          lg={isGraph ? 16 : 20}
          xl={isGraph ? 16 : 20}
        >
          <div className="box box1">
            <SalesGraph
              setIsGraph={setIsGraph}
              custom={custom}
              uids={selectedUids}
              hierarchyClicked={hierarchyClickCount}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={8} lg={isGraph ? 8 : 4} xl={isGraph ? 8 : 4}>
          <div className="box box2">
            <UserHierarchy onSelectNode={onSelectNode} />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Grid;
