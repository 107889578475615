import React from "react";
import { Table, Tag, Button, Popover, Card, message , Avatar , Tooltip ,Flex} from "antd";
import moment from "moment";
const OrderSummaryTable = ({ orderDetails, columns }) => {
  const data = [];

  // Extract payment links from orderDetails directly
  const getPaymentLinks = (links) => {
    if (!links || links.length === 0) return { firstLink: null, restLinks: [] };

    const firstLink = links[0];
    const restLinks = links.slice(1);
    return { firstLink, restLinks };
  };

  const { firstLink, restLinks } = getPaymentLinks(orderDetails.payment_links);

  // Helper function to get the domain name from a URL
  const getDomainName = (url) => {
    try {
      const { hostname } = new URL(url);
      return hostname;
    } catch (error) {
      return url;
    }
  };

  const getLinkText = (url) => {
    const domain = getDomainName(url);
    if (domain.includes("oz")) {
      return "Link for OZ Assignments";
    } else if (domain.includes("anayalearning")) {
      return "Link for Anaya Learning";
    } else if (domain.includes("locusrags")) {
      return "Link for Locusrags";
    } else if (domain.includes("locusassignments")) {
      return "Link for Locus Assignments";
    } else {
      return "Payment Link";
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      message.success("Payment link copied to clipboard!");
    });
  };

  const paymentLinkContent = (
    <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
      {/* First link displayed outside popover */}
      {firstLink && (
        <Button
          style={{ padding: 0 }}
          type="link"
          onClick={() => copyToClipboard(firstLink)}
        >
          <Flex vertical >
          {getLinkText(firstLink)}  </Flex>
        </Button>
      )}

      {/* The rest of the payment links inside a popover */}
      {restLinks.length > 0 && (
        <Popover
          content={
            <Flex vertical  align="flex-start">
              {restLinks.map((link, index) => (
                <Button
                  key={index}
                  style={{ padding: 0 }}
                  type="link"
                  onClick={() => copyToClipboard(link)}
                >
                  {getLinkText(link)}
                </Button>
              ))}
       </Flex>
          }
          title="Other Payment Links"
          trigger="click"
        >
          <Button type="link" style={{ paddingLeft: 0 }}>
            View More
          </Button>
        </Popover>
      )}
    </div>
  );
  const renderVisibility = (visibility) => {
    if (!visibility || visibility.length === 0) return "N/A";

    const displayedVisibility = visibility.slice(0, 2);
    const remainingVisibility = visibility.slice(2);

    return (
      <Avatar.Group maxCount={3}>
        {displayedVisibility.map((name, index) => (
          <Tooltip key={index} title={name}>
            <Avatar style={{ backgroundColor: "#1890ff" }}>
              {name.charAt(0).toUpperCase()}
            </Avatar>
          </Tooltip>
        ))}
        {remainingVisibility.length > 0 && (
          <Popover
            content={
              <div>
                {remainingVisibility.map((name, index) => (
                  <p key={index}>{name}</p>
                ))}
              </div>
            }
            title="Additional Visibility Entries">
            <Avatar style={{ backgroundColor: "#f56a00" }}>
              +{remainingVisibility.length}
            </Avatar>
          </Popover>
        )}
      </Avatar.Group>
    );
  };

  const excludeFields = [
    "description",
    "start_date", 
    "alloted_deadline",
    "otherPaymentLinks",
    "requirement_files_count",
    "solution_files_count",
    "feedback_count",
    "salessituation",
    "uid",
    "key",
    "title",
    "payment_status",
    "internal_status",
    "writer_word_count",
    "moved_to_freelancer_portal",
    "payment",
    "visibility",
    "created_by",
    "ownership",
    "fl_status",
    "comment_count"
  ];

  // Helper function to format field names
  const formatFieldName = (name) => {
    if (!name) return "";
    return name
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  // Construct table data dynamically based on provided columns
  columns.forEach((column) => {
    const { key, field, value } = column;

    // Skip unwanted fields
    if (excludeFields.includes(key)) return;

    // Use switch-case to handle various keys
    switch (key) {
      case "allowed_deadline":
      case "deadline_hard":
        if (key === "alloted_deadline" && orderDetails.alloted_deadline) {
          data.push({
            key: "alloted_deadline",
            field: "Alloted Deadline",
            value: moment(orderDetails.alloted_deadline).local().format("YYYY-MM-DD HH:mm"),
          });
        } else if (key === "deadline_hard" && orderDetails.deadline_hard && !orderDetails.allowed_deadline) {
        return null
        }
        break;

      case "payment_links":
        data.push({
          key: "payment_links",
          field: "Payment Link",
          value: paymentLinkContent,
        });
        break;

      case "tags":
        data.push({
          key: "tags",
          field: "Tags",
          value: orderDetails.tags && orderDetails.tags.length > 0 ? (
            <div>
              {orderDetails.tags.map((tag) => (
                <Tag style={{ marginTop: "10px" }} key={tag} color="purple">
                  {tag}
                </Tag>
              ))}
            </div>
          ) : (
            "N/A"
          ),
        });
        break;

   

      case "status":
      case "writer_status":
        data.push({
          key: "status",
          field: formatFieldName(field),
          value: (
            <Tag color="blue">
              {value}
            </Tag>
          ),
        });
        break;

      case "boolean":
        data.push({
          key,
          field: formatFieldName(field),
          value: value ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>,
        });
        break;

        // case "bypass_otp_verification":
        // if (key === "bypass_otp_verification") {
        //   data.push({
        //     key: "bypass_otp_verification",
        //     field: "OTP Verification",
        //     value: (
        //       <Tag
        //         color={
        //           orderDetails?.bypass_otp_verification === false
        //             ? "volcano"
        //             : "green"
        //         }
        //       >
        //         {orderDetails?.bypass_otp_verification === false
        //           ? "Disabled"
        //           : "Enabled"}
        //       </Tag>
        //     ),
        //   });
        // }

            case "bypass_otp_verification":
            if (key !== "bypass_otp_verification") {
              data.push({
                key: "bypass_otp_verification",
                field: "OTP Verification",
                value: ""
              });
            }
            break;

      default:
        // For all other keys, format the field name and display the value
        const formattedField = formatFieldName(field);
        data.push({
          key,
          field: formattedField,
          value: value || "N/A",
        });
        break;
    }
  });

  return (
    <Card
      title={<span>Order Details</span>}
      style={{ marginBottom: "20px", textAlign: "left" }}
      headStyle={{ textAlign: "left" }}
    >
      <Table
        columns={[
          {
            dataIndex: "field",
            key: "field",
            render: (text) => (
              <span className="field-label" style={{ fontWeight: "bold" }}>
                {text}
              </span>
            ),
          },
          {
            dataIndex: "value",
            key: "value",
            render: (text) => text,
          },
        ]}
        dataSource={data}
        pagination={false}
        bordered
        showHeader={false}
        size="middle"
        scroll={{ x: "100%" }}
      />
    </Card>
  );
};

export default OrderSummaryTable;
