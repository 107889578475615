import React, { useEffect, useState } from 'react';
import axiosInstance from '../../services/axiosInstance';
import { useParams } from 'react-router-dom';
import { Button, Spin, Checkbox, Form, Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { catchError } from '../../utils/errorHandler';

const { Dragger } = Upload;

const AddTaskFiles = ({ handleModalClose, fetchOrderDetails }) => {
    const { orderKey } = useParams();
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(false);
    const [subscribersLoading, setSubscribersLoading] = useState(true);
    const [selectedAuthors, setSelectedAuthors] = useState([]);
    const [fileList, setFileList] = useState([]);

    const fetchSubscribersList = async () => {
        setSubscribersLoading(true);
        try {
            const res = await axiosInstance.get(`task/v2/task-subscribers-list/${orderKey}/`);
            const data = res?.data?.data || [];
            setDataSource(data);
        } catch (err) {
            console.error('Error fetching subscribers list', err);
        } finally {
            setSubscribersLoading(false);
        }
    };

    useEffect(() => {
        fetchSubscribersList();
    }, []);

    const handleAuthorSelection = (author) => {
        setSelectedAuthors((prev) =>
            prev.includes(author) ? prev.filter((item) => item !== author) : [...prev, author]
        );
    };

    const handleFileChange = (info) => {
        const { fileList } = info;
        setFileList(fileList);
    };

    const handleSubmit = async () => {
        if (selectedAuthors.length === 0 || fileList.length === 0) {
            message.error('Please select authors and upload files before submitting.');
            return;
        }

        const payload = new FormData();
        payload.append('subscribers', JSON.stringify(selectedAuthors));
        fileList.forEach((file) => {
            payload.append('files', file.originFileObj);
        });


        setLoading(true);
        try {
            const response = await axiosInstance.post(`task/v2/add-files/${orderKey}/`, payload, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
           
            if (response.status === 201 && response.data.status === "success") {
                message.success(response.data.data)
                handleModalClose();
                fetchOrderDetails();
            }

        } catch (err) {
            await catchError(err)
        } finally {
            setLoading(false);
        }
    };


    if (subscribersLoading) {
        return <Spin tip="Loading authors..." style={{ display: 'block', marginTop: '20px' }} />;
    }

    return (
        <div>
            <div style={{ marginBottom: 20 }}>
                <Form>
                    <Form.Item
                        style={{ width: '100%' }}
                        name="files"
                        valuePropName="fileList"
                        getValueFromEvent={(e) => (Array.isArray(e) ? e : e && e.fileList)}
                        rules={[{ required: true, message: 'Please upload at least one file!' }]}
                    >
                        <Dragger
                            fileList={fileList}
                            beforeUpload={() => false}
                            onChange={handleFileChange}
                            multiple
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag files to this area to upload</p>
                        </Dragger>
                    </Form.Item>

                </Form>
            </div>

            <div>
                <h3>Task Subscriber :</h3>
                {dataSource.map((item) => (
                    <div key={item.uuid}>
                        <Checkbox
                            onChange={() => handleAuthorSelection(item.username)}
                            checked={selectedAuthors.includes(item.username)}
                        >
                            {item.author} - {item.no_of_word} words
                        </Checkbox>
                    </div>
                ))}
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                <Button
                    className="handle_submit_action_button"
                    onClick={handleSubmit}
                    loading={loading}
                    type="primary"
                >
                    Add
                </Button>
            </div>
        </div>
    );
};

export default AddTaskFiles;
