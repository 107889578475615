import React from 'react';
import { Table } from 'antd';

const calculateCurrencyTotals = (data) => {
  const totals = {};
  Object.keys(data).forEach((region) => {
    totals[region] = {};
    Object.keys(data[region]).forEach((currency) => {
      const months = Object.keys(data[region][currency]);
      const totalAmount = months.reduce((sum, month) => sum + parseFloat(data[region][currency][month].amount), 0);
      totals[region][currency] = totalAmount.toFixed(2); // keep two decimal points
    });
  });
  return totals;
};

const RegionTable = ({ data }) => {
  const columns = [
    {
      title: 'Region',
      dataIndex: 'region',
      key: 'region',
      render: (text) => <div style={{ height: '100%' , padding:'4px 10px' }}>{text}</div>,
    },
    {
      title: 'USD',
      dataIndex: 'USD',
      key: 'USD',
      render: (text) => <div style={{ backgroundColor: '#f0f0f0', height: '100%', padding:'4px 10px'  }}>{text}</div>,
    },
    {
      title: 'AUD',
      dataIndex: 'AUD',
      key: 'AUD',
      render: (text) => <div style={{ backgroundColor: '#f0f8ff', height: '100%' , padding:'4px 10px' }}>{text}</div>,
    },
    {
      title: 'CAD',
      dataIndex: 'CAD',
      key: 'CAD',
      render: (text) => <div style={{ backgroundColor: '#e6f7ff', height: '100%' , padding:'4px 10px' }}>{text}</div>,
    },
    {
      title: 'GBP',
      dataIndex: 'GBP',
      key: 'GBP',
      render: (text) => <div style={{ backgroundColor: '#d9f7be', height: '100%' , padding:'4px 10px' }}>{text}</div>,
    },
    {
      title: 'EUR',
      dataIndex: 'EUR',
      key: 'EUR',
      render: (text) => <div style={{ backgroundColor: '#fffbe6', height: '100%' , padding:'4px 10px' }}>{text}</div>,
    },
    {
      title: 'INR',
      dataIndex: 'INR',
      key: 'INR',
      render: (text) => <div style={{ backgroundColor: '#fff1f0', height: '100%', padding:'4px 10px'  }}>{text}</div>,
    },
    {
      title: 'SGD',
      dataIndex: 'SGD',
      key: 'SGD',
      render: (text) => <div style={{ backgroundColor: '#f9f0ff', height: '100%', padding:'4px 10px'  }}>{text}</div>,
    },
    {
      title: 'Total INR Amount',
      dataIndex: 'total_inr_amount',
      key: 'total_inr_amount',
      render: (text) => <div style={{ backgroundColor: '#fafafa', height: '100%' , padding:'4px 10px' }}>{text}</div>,
    },
  ];

  const currencyTotals = calculateCurrencyTotals(data);
  const tableData = Object.entries(currencyTotals).map(([region, regionData], index) => ({
    key: index,
    region,
    USD: regionData.USD || '0.00',
    AUD: regionData.AUD || '0.00',
    CAD: regionData.CAD || '0.00',
    GBP: regionData.GBP || '0.00',
    EUR: regionData.EUR || '0.00',
    INR: regionData.INR || '0.00',
    SGD: regionData.SGD || '0.00',
    total_inr_amount: Object.values(regionData).reduce((sum, amount) => sum + parseFloat(amount), 0).toFixed(2),
  }));

  return <Table columns={columns} dataSource={tableData} />;
};

export default RegionTable;
