import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  Descriptions,
  Button,
  Flex,
  Popover,
  Typography,
  Tooltip,
  Avatar,
} from "antd";
import { CheckCircleOutlined, CloseOutlined } from "@ant-design/icons";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const MobileViewAcceptRejectList = ({ payoutData, onAccept, onReject }) => {
  const [timeElapsedArray, setTimeElapsedArray] = useState({});
  const timeElapsedRef = useRef({});

  // Function to calculate elapsed time
  const calculateTimeElapsed = (created_at) => {
    if (!created_at) return "N/A";

    const createdDate = moment(created_at);
    const now = moment();
    const duration = moment.duration(now.diff(createdDate));

    const days = duration.days();
    const hours = String(duration.hours()).padStart(2, "0");
    const minutes = String(duration.minutes()).padStart(2, "0");
    const seconds = String(duration.seconds()).padStart(2, "0");

    return `${days > 0 ? `${days}d ` : ""}${hours}:${minutes}:${seconds}`;
  };

  // Update "Time Elapsed" every second
  useEffect(() => {
    if (!payoutData || payoutData.length === 0) return;

    const updateElapsedTimes = () => {
      payoutData.forEach((record) => {
        timeElapsedRef.current[record.key] = calculateTimeElapsed(
          record.created_at
        );
      });

      setTimeElapsedArray({ ...timeElapsedRef.current });
    };

    updateElapsedTimes(); // Initial update
    const interval = setInterval(updateElapsedTimes, 1000); // Update every second

    return () => clearInterval(interval);
  }, [payoutData]);

  const navigate = useNavigate();

  return (
    <Flex vertical gap={12}>
      {payoutData.map((record) => (
        <Card key={record.key} style={{ borderRadius: 8 }}>
          <Descriptions bordered size="small">
            {/* Manually Render Time Elapsed */}
            {Object.entries(record).map(([key, value]) => {
              if (
                ["created_at", "tags", "source", "client_deadline"].includes(
                  key
                )
              )
                return null; // Exclude source

              const label = key
                .replace(/_/g, " ")
                .replace(/\b\w/g, (c) => c.toUpperCase());

              switch (key) {
                case "key":
                  return (
                    <Descriptions.Item key={key} label="Order ID">
                      <Flex vertical align="flex-start">
                        <Typography.Link
                          onClick={() =>
                            navigate(`/order-management/my-orders/${value}`)
                          }>
                          {value}
                        </Typography.Link>
                        <small style={{ color: "grey" }}>
                          {moment(record.created_at)
                            .local()
                            .format("DD-MM-YYYY hh:mm a")}
                        </small>
                      </Flex>
                    </Descriptions.Item>
                  );
                case "title":
                  return (
                    <Descriptions.Item key={key} label="Title">
                      {value.length > 20 ? (
                        <Tooltip title={value}>
                          {value.substring(0, 20)}...
                        </Tooltip>
                      ) : (
                        value
                      )}
                    </Descriptions.Item>
                  );

                case "description":
                  return (
                    <Descriptions.Item key={key} label="Description">
                      <Popover
                        content={
                          <div dangerouslySetInnerHTML={{ __html: value }} />
                        }>
                        <Button type="link" style={{ padding: 0 }}>
                          View
                        </Button>
                      </Popover>
                    </Descriptions.Item>
                  );

                case "allotted_deadline":
                  const deadline =
                    record.source === "manual"
                      ? record.allotted_deadline
                      : record.client_deadline; // Use client_deadline if source is null or automatic

                  return (
                    <Descriptions.Item key={key} label="Allotted Deadline">
                      {deadline
                        ? moment(deadline).local().format("DD-MM-YYYY hh:mm a")
                        : "-"}
                    </Descriptions.Item>
                  );

                case "move_to_call_allocation":
                  return (
                    <Descriptions.Item
                      key={key}
                      label="Move to Call Allocation">
                      {value ? "Yes" : "No"}
                    </Descriptions.Item>
                  );

                case "visibility":
                  return (
                    <Descriptions.Item key={key} label="Visibility">
                      {value?.length > 0 ? (
                        <Flex gap={4}>
                          {value.slice(0, 2).map((name, index) => (
                            <Tooltip title={name} key={index}>
                              <Avatar style={{ backgroundColor: "#9F580A" }}>
                                {name
                                  .split(" ")
                                  .map((part) => part[0])
                                  .join("")
                                  .toUpperCase()}
                              </Avatar>
                            </Tooltip>
                          ))}
                          {value.length > 2 && (
                            <Popover
                              content={value.slice(2).map((name, index) => (
                                <div key={index}>{name}</div>
                              ))}
                              title="More Members">
                              <Avatar style={{ backgroundColor: "#ccc" }}>
                                +{value.length - 2}
                              </Avatar>
                            </Popover>
                          )}
                        </Flex>
                      ) : (
                        "-"
                      )}
                    </Descriptions.Item>
                  );

                case "files":
                  return (
                    <Descriptions.Item key={key} label="Files">
                      {value?.length > 0 ? (
                        <Popover
                          content={value.map((file, index) => (
                            <div key={index}>
                              <a
                                href={file.url}
                                target="_blank"
                                rel="noopener noreferrer">
                                {file.name || `File ${index + 1}`}
                              </a>
                            </div>
                          ))}
                          title="Files">
                          <Button type="link" style={{ padding: 0 }}>
                            {value.length > 1 ? "View Files" : "View File"}
                          </Button>
                        </Popover>
                      ) : (
                        "-"
                      )}
                    </Descriptions.Item>
                  );

                default:
                  return (
                    <Descriptions.Item key={key} label={label}>
                      {value || "-"}
                    </Descriptions.Item>
                  );
              }
            })}
            <Descriptions.Item label="Time Elapsed">
              <div style={{ color: "red" }}>
                {timeElapsedArray[record.key] || "Calculating..."}
              </div>
            </Descriptions.Item>
          </Descriptions>

          <Flex justify="end" gap={8} style={{ marginTop: 12 }}>
            <Button
              style={{ background: "#22c55e", color: "white" }}
              icon={<CheckCircleOutlined />}
              onClick={() => onAccept(record)}>
              Accept
            </Button>
            <Button
              icon={<CloseOutlined style={{ color: "white" }} />}
              onClick={() => onReject(record)}
              style={{ background: "#E02424", color: "white" }}>
              Reject
            </Button>
          </Flex>
        </Card>
      ))}
    </Flex>
  );
};

export default MobileViewAcceptRejectList;
