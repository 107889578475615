import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Button, message, Spin } from 'antd';
import axiosInstance from '../../services/axiosInstance';
import { useParams } from 'react-router-dom';
import { catchError } from '../../utils/errorHandler';

const { Option } = Select;
const FreelancerBudget = ({ fetchOrderDetails, handleModalClose }) => {
    const { orderKey } = useParams();
    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [currencyData, setCurrencyData] = useState([]);

    const fetchCurrencies = async () => {
        setLoading(true);
        try {
            const res = await axiosInstance.get('api/v2/currency/all/');
            // console.log("RESSS", res)
            if (res?.data?.status === "success") {
                setCurrencyData(res?.data?.data?.currency);
            }
        } catch (err) {
            console.error("Error fetching currencies:", err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCurrencies();
    }, []);

    const handleSubmit = async (values) => {
        setSubmitLoading(true);
        const payload = {
            budget: values.budget,
            currency: values.currency,
        };

        try {
            const response = await axiosInstance.post(`/task/v2/add-fl-budget/${orderKey}/`, payload);
            if (response.status === 200 && response.data.status === 'success') {
                message.success(response.data.data);
                fetchOrderDetails()
                handleModalClose()
            }
        } catch (error) {
            await catchError(error)
        } finally {
            setSubmitLoading(false);
        }
    };

    return (
        <Spin spinning={loading}>
            <Form layout="vertical" onFinish={handleSubmit}>
                {/* Budget Input */}
                <Form.Item
                    style={{ width: "100%" }}
                    name="budget"
                    label="Budget"
                    rules={[{ required: true, message: 'Please enter your budget!' }]}
                >
                    <Input placeholder="Enter budget amount" type="number" />
                </Form.Item>

                {/* Currency Dropdown */}
                <Form.Item
                    style={{ width: "100%" }}
                    name="currency"
                    label="Currency"
                    rules={[{ required: true, message: 'Please select a currency!' }]}
                >
                    <Select placeholder="Select a currency" style={{ width: '100%' }}>
                        {currencyData.length > 0 ? (
                            currencyData.map((currency) => (
                                <Option key={currency} value={currency}>
                                    {currency}
                                </Option>
                            ))
                        ) : (
                            <Option value="" disabled>
                                No currencies available
                            </Option>
                        )}
                    </Select>
                </Form.Item>

                {/* Submit Button */}
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20, width: "100%" }}>
                    <Button type="primary" htmlType="submit" loading={submitLoading}>
                        Submit
                    </Button>
                </div>
            </Form>
        </Spin>
    );
};

export default FreelancerBudget;
