import React, { useEffect, useState } from "react";
import FixedOrderDetails from "../../page-components/OrderDetail/FixedOrderDetails";
import FixedDetails2 from "../../page-components/OrderDetail/FixedDetails2";
import CustomTabs from "../../page-components/OrderDetail/Tabs";
import orderService from "../../services/OrderLists"; // Adjust the path as needed
import { useParams } from "react-router-dom";
import { Spin, Flex, message } from "antd";
import CallFunc from "../../page-components/OrderDetail/CallFunc";
import { useSelector } from "react-redux";
import { catchError } from "../../utils/errorHandler";
import axiosInstance from "../../services/axiosInstance";
import usePermissions from "../../hooks/usePermissions";
function OrderDetail() {
  const [orderData, setOrderData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activeOrderTab, setActiveOrderTab] = useState("Overview");
  const [errorMessage, setErrorMessage] = useState(null); // New state for error message
  const [loading, setLoading] = useState({
    orderFiles: false,
    solutionFiles: false,
    comments: false,
    feedback: false,
  });
  const { hasPermission } = usePermissions();

  const [orderFiles, setOrderFiles] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  console.log(orderData);
  const [solutionFiles, setSolutionFiles] = useState({
    finalFilesData: [],
    similarityReportData: [],
    otherFilesData: [],
  });
  const [comments, setComments] = useState({
    client_comments: [],
    fl_comments: [],
    internal_comments: [],
  });
  const [feedback, setFeedback] = useState([]);

  const { orderKey } = useParams();
  useEffect(() => {
    fetchOrderDetails();
  }, [orderKey]);
  const getSolutionFiles = async () => {
    try {
      setLoading((prev) => ({ ...prev, solutionFiles: true }));
      const result = await orderService.getSolutionFiles(orderKey);
      if (result.success && result.data.status === "success") {
        setLoading((prev) => ({ ...prev, solutionFiles: false }));
        const { final_files, other_files, similarity_files } = result.data.data;
        setSolutionFiles({
          finalFilesData: final_files || [],
          similarityReportData: similarity_files || [],
          otherFilesData: other_files || [],
        });
      }
    } catch (error) {
      message.error("hello");
      setLoading((prev) => ({ ...prev, solutionFiles: false }));
    } finally {
      setLoading((prev) => ({ ...prev, solutionFiles: false }));
    }
  };
  const fetchOrderDetails = async () => {
    try {
      setIsLoading(true);
      const result = await axiosInstance.get(`task/v2/detail/${orderKey}`);

      if (result.status === 200) {

        setOrderData(result?.data || null);
        setErrorMessage(null); // Clear any existing error message on success
      }
    } catch (error) {
      if (error.response?.status === 402) {
        setIsLoading(false);
        // message.error("Request failed with status code 402");
      } else if (error.response?.status === 401) {
        setErrorMessage("Unauthorized access - please check your permissions.");
        setIsLoading(false);
        // message.error("Unauthorized access - please check your permissions.");
      } else {
        await catchError(error);
        setIsLoading(false);
      }
    } finally {
      setIsLoading(false);
    }
  };


  const handleTabChange = async (key) => {
    if (key === "1") {
      setActiveOrderTab("Overview");
    } else if (key === "2") {
      setActiveOrderTab("SolutionFiles");
      getSolutionFiles();
    } else if (key === "3") {
      setLoading((prev) => ({ ...prev, comments: true }));
      setActiveOrderTab("Comments");

      // Call the getClientComments function
      if (hasPermission("can_view_client_comments")) {
        const result = await orderService.getClientComments(orderKey);
        console.log(result.data, "clg");
        if (result.data.status === "success") {
          setComments((prev) => ({
            ...prev,
            client_comments: result.data.data || [],
          }));
        }
      }

      if (hasPermission("can_view_internal_comments")) {
        const internalResult = await orderService.getInternalComments(orderKey);
        if (internalResult.data.status === "success") {
          setComments((prev) => ({
            ...prev,
            internal_comments: internalResult.data.data || [],
          }));
        }
      }

      if (hasPermission("can_view_freelancer_comments")) {
        const freelancerResult = await orderService.getFreelancerComments(
          orderKey
        );
        if (freelancerResult.data.status === "success") {
          setComments((prev) => ({
            ...prev,
            fl_comments: freelancerResult.data.data || [],
          }));
        }
      }
      setLoading((prev) => ({ ...prev, comments: false }));
    } else if (key === "4") {
      setLoading((prev) => ({ ...prev, feedback: true }));
      setActiveOrderTab("Feedback");
      const result = await orderService.getOrderFeedback(orderKey);
      console.log(result.data.data, "order feed");
      if (result.data.status === "success") {
        setFeedback(result.data.data || []);
      }
      setLoading((prev) => ({ ...prev, feedback: false }));
    }
  };
  const { user } = useSelector((state) => state.auth);

  if (!user) {
    console.log("No user permissions available in order Details Main Page");
    return [];
  }

  const permissions = user?.user?.permissions;

  if (errorMessage) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <h2>{errorMessage}</h2>
      </div>
    );
  }

  return isLoading ? (
    <Flex
      justify="center"
      align="center"
      style={{ width: "100%", height: "80vh" }}>
      <Spin size="large" />
    </Flex>
  ) : (
    <>
      <FixedOrderDetails orderData={orderData} />
      <FixedDetails2 orderData={orderData} />
      <CustomTabs
      solutionFiles={solutionFiles}
        orderData={orderData}
        setActiveOrderTab={setActiveOrderTab}
        orderKey={orderData?.data?.data?.key}
        moveToPortal={orderData?.data?.moved_to_freelancer_portal}
        orderDataComments={comments}
        setOrderDataComments={setComments}
        setLoading={setLoading}
        setSolutionFiles={setSolutionFiles}
        loading={loading}
        handleTabChange={handleTabChange}
        feedbackData={{ feedback }}
        getSolutionFiles={getSolutionFiles}
        finalFilesData={solutionFiles.finalFilesData}
        similarityReportData={solutionFiles.similarityReportData}
        otherFilesData={solutionFiles.otherFilesData}
        fetchOrderDetails={fetchOrderDetails}
      />
      <div style={{ position: "fixed", bottom: "30px", right: "35px" }}>
        <CallFunc
          orderData={orderData}
          activeOrderTab={activeOrderTab}
          permissions={permissions}
          fetchOrderDetails={fetchOrderDetails} // Pass the fetch function
          handleTabChange={handleTabChange}
          getSolutionFiles={getSolutionFiles}
          
        />
      </div>
    </>
  );
}

export default OrderDetail;
