import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  Select,
  Upload,
  Flex,
  message,
  Button,
  Modal,
  Typography,
} from "antd";
import {
  FileTextOutlined,
  DeleteOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import dayjs from "dayjs";
import orderService from "../../services/OrderLists";
import { useParams } from "react-router-dom";

import moment from "moment";
const { Dragger } = Upload;
const { Option } = Select;
const { Title } = Typography;
const EditFeedback = ({
  isVisible,
  setIsModalVisible,
  feedbackKey,
  feedbackData,
  getFeedBack
}) => {

  const selectedFeedback = feedbackData.feedback.find(
    (fb) => fb.key === feedbackKey
  );

  const [timezonesData, setTimeZonesData] = useState([]);
  const [formData, setFormData] = useState({
    details: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [deadlineHard, setDeadlineHard] = useState(
    selectedFeedback?.deadline_hard
      ? dayjs(selectedFeedback?.deadline_hard)
      : null
  );

  const [description, setDescription] = useState(selectedFeedback?.details);
  const [form] = Form.useForm();
  const { orderKey } = useParams();

  //   const deadlineDate = moment(orderData?.orderData?.data?.deadline_hard);

  const handleDescriptionChange = (value) => {
    setDescription(value);
    setFormData({ ...formData, details: value });
  };

  const handleSubmit = async () => {
    const formDataToSend = new FormData();
  
    // Use the current `description` or fallback to the initial value
    formDataToSend.append("details", description || selectedFeedback?.details);
    formDataToSend.append(
      "deadline_hard",
      formData.deadline_hard || selectedFeedback?.deadline_hard
    );
  
    try {
      setIsLoading(true);
      const response = await orderService.editFeedback(feedbackKey, formDataToSend);
  
      if (response?.success) {
        message.success("Feedback edited successfully.");
        await getFeedBack("4")
        setIsModalVisible()
      }
    } catch (error) {
      // Format errors into the specified format
      const errorResponse = error?.response?.data || {};
      for (const [key, messages] of Object.entries(errorResponse)) {
        message.error(`${key}: ${messages.join(", ")}`);
      }
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <Modal
      loading={isLoading}
      className="custom-modal2"
      title={
        <Flex justify="space-between" className="custom-modal-header">
          <Title
            level={3}
            style={{
              color: "white",
              margin: 0,
              position: "relative",
              marginLeft: "10px",
            }}>
            Edit Feedback
          </Title>
        </Flex>
      }
      visible={isVisible}
      onCancel={() => setIsModalVisible()} // Ensures modal closes on cancel
      footer={
        <Flex style={{ width: "100%" }} justify="flex-end" align="flex-end">
          <Button loading={isLoading} type="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Flex>
      }>
      <Form
        form={form}
        layout="vertical"
        encType="multipart/form-data"
        onFinish={() => console.log("Form submitted", formData)}>
        <Flex vertical justify="center" style={{ width: "100%" }} gap={24}>
          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item label="Description" required>
                <ReactQuill
                  value={description}
                  onChange={handleDescriptionChange}
                  style={{ height: "150px" }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item label="Client's Deadline" required>
                <DatePicker
                  value={deadlineHard}
                  disabledDate={(current) =>
                    current && current.isBefore(moment())
                  }
                  style={{ width: "100%" }}
                  showTime
                  format="YYYY-MM-DD HH:mm A"
                  onChange={(date) => {
                    setDeadlineHard(date);
                    setFormData({
                      ...formData,
                      deadline_hard: date ? date.toISOString() : null,
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* <Flex style={{ width: "100%" }} justify="flex-end" align="flex-end">
          <Button type="primary" loading={isLoading} onClick={handleSubmit}>
            Submit
          </Button>
        </Flex> */}
          {/* <Row>
        <label>Files</label>
        <div className="file-container">
          {fileList.length === 0 ? (
            <Dragger
              style={{ width: "100%", minHeight: "150px" }}
              fileList={fileList}
              beforeUpload={() => false}
              onChange={handleFileChange}
              multiple>
              <p>Drag & drop files here or click to upload</p>
            </Dragger>
          ) : (
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {fileList.map((file, index) => {
                const { displayName, fullName } = getFormattedFileName(
                  file.name
                );

                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #d9d9d9",
                      padding: "4px",
                      marginBottom: "5px",
                    }}>
                    <img
                      src={getFileIcon(file.name)}
                      alt="file-icon"
                      style={{ height: "40px", marginRight: "8px" }}
                    />
                    <span title={fullName}>{displayName}</span>
                    <DeleteOutlined
                      style={{ marginLeft: "auto", cursor: "pointer" }}
                      onClick={() => handleDeleteFile(index)}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </Row> */}
        </Flex>
      </Form>
    </Modal>
  );
};

export default EditFeedback;
