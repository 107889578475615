import React, { useEffect, useState, useCallback } from "react";
import { debounce } from "lodash";
import OrderProgressTrackingTableList from "../../page-components/OrderProgressTracking/OrderProgressTrackingTableList";
import { Flex, Spin, Typography, Button } from "antd";
import OrderProgressTraclingService from "../../services/orderProgressTrackingService";
import OrderProgressTrackingFilter from "../../page-components/OrderProgressTracking/OrderProgressTrackingFilters";
import { FilterOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";

const { Paragraph } = Typography;

function OrderProgressTracking() {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const [filtersList, setFiltersList] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({});

  // ✅ Debounce API call to prevent excessive re-fetching
  const fetchOrderProgress = useCallback(
    debounce(async (currentFilters, currentPage, pageSize) => {
      setLoading(true);
      try {
        const response =
          await OrderProgressTraclingService.fetchOrderProgresTrackingList(
            currentPage,
            pageSize,
            currentFilters
          );

        if (response.success) {
          setData(response?.data?.data?.results);
          setFiltersList(response?.data?.data?.filters);

          setPagination((prev) => ({
            ...prev,
            total: response.data.data.count || 0,
          }));
        }
      } catch (err) {
        console.error("Error fetching order progress data:", err);
        setError("Failed to fetch order progress data");
      } finally {
        setLoading(false);
      }
    }, 500), // Debounce delay of 500ms
    []
  );

  // ✅ Use Effect to fetch data when dependencies change
  useEffect(() => {
    fetchOrderProgress(filters, pagination.current, pagination.pageSize);
  }, [filters, pagination.current, pagination.pageSize]);

  // ✅ Handle filter changes
  const handleSearchClick = (newFilters) => {
    setFilters(newFilters);
    setPagination((prev) => ({ ...prev, current: 1 }));
  };

  const handleCancelSearch = () => {
    setFilters({});
    setPagination((prev) => ({ ...prev, current: 1 }));
  };

  return (
    <Flex
      style={{ height: "100%", width: "100%", padding: 10 }}
      vertical
      gap={10}>
      {showFilters ? (
        <div >
          <OrderProgressTrackingFilter
            filtersList={filtersList || []}
            onSearch={handleSearchClick}
            onCancel={handleCancelSearch}
          />
        </div>
      ) : null}

      {!loading && (
        <Flex
          gap={isMobile ? 8 : 0}
          style={{ width: "100%", flexDirection: isMobile ? "column" : "row" }}
          justify="space-between"
          align={isMobile ? "flex-end" : "center"}>
          <Paragraph
            style={{
              fontWeight: 500,
              opacity: 0.6,
              textAlign: "left",
              margin: 0,
              padding: 0,
            }}>
              {console.log(pagination)}
            Showing{" "}
            {Math.min(
              ((pagination.current ?? 1) - 1) * (pagination.pageSize ?? 20) + 1,
              pagination.total ?? 0
            )}{" "}
            -{" "}
            {Math.min(
              (pagination.current ?? 1) * (pagination.pageSize ?? 20),
              pagination.total ?? 0
            )}{" "}
            of {pagination.total ?? 0} Orders
          </Paragraph>

          <Flex gap={4} align="center">
            <Button
              type="primary"
              icon={<FilterOutlined />}
              onClick={() => setShowFilters(!showFilters)}
              style={{ marginLeft: "10px", background: "#040724" }}
            />
          </Flex>
        </Flex>
      )}

      {loading ? (
        <Spin size="large" style={{ margin: "20px auto" }} />
      ) : (
        <OrderProgressTrackingTableList
          data={data}
          pagination={pagination}
          setPagination={setPagination}
        />
      )}
    </Flex>
  );
}

export default OrderProgressTracking;
