import React, { useState } from "react";
import { Card, Tag, Tooltip, Row, Col, Button, Table, Popover } from "antd";
import {
  CopyOutlined,
  FileOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import moment from "moment";

const FeedbackCard = ({ feedbackData }) => {
  const [expandedKeys, setExpandedKeys] = useState({});

  const toggleExpand = (key) => {
    setExpandedKeys((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  // Function to strip HTML tags
  const stripHtml = (html) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  // Function to count words in HTML content
  const countWords = (html) => {
    const text = stripHtml(html); // Strip HTML tags
    const words = text.trim().split(/\s+/); // Split by spaces to get words
    return words.length;
  };

  // Helper function to truncate HTML content to a certain number of words
  const truncateHtml = (html, wordLimit) => {
    const text = stripHtml(html); // Strip HTML tags
    const words = text.trim().split(/\s+/);
    const truncatedWords = words.slice(0, wordLimit).join(" ");
    return truncatedWords + (words.length > wordLimit ? "..." : "");
  };

  return (
    <Row gutter={[16, 16]} justify="start">
      {feedbackData?.feedback.map((feedback) => (
        <Col xs={24} sm={12} lg={12} key={feedback.key}>
          <Card
            style={{
              maxHeight: 300,
              minHeight: 300,
              overflow: "hidden",
              overflowY: "scroll",
              textAlign: "left",
            }}
            title={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>
                <span style={{ fontWeight: "500" }}>{feedback.key}</span>
                {Array.isArray(feedback.files) && feedback.files.length > 0 && (
                  <div
                    style={{
                      borderTop: "1px solid #f0f0f0",
                      paddingTop: "12px",
                    }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "8px",
                      }}>
                      {/* File Count with Popover */}
                      <Tooltip title="Click to view attached files">
                        <Popover
                          placement="left"
                          trigger="hover"
                          content={
                            <Table
                              style={{ width: 500 }}
                              dataSource={feedback.files}
                              columns={[
                                {
                                  title: "File Name",
                                  dataIndex: "file_name",
                                  key: "file_name",
                                  render: (text, record) => (
                                    <Popover content={text}>
                                      <a
                                        href={record.file_url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                          color: "#1890ff",
                                          display: "block",
                                          maxWidth: "300px",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                        }}>
                                        {text}
                                      </a>
                                    </Popover>
                                  ),
                                },
                                {
                                  title: "Created At",
                                  dataIndex: "created_at",
                                  key: "created_at",
                                  render: (date) =>
                                    moment(date)
                                      .local()
                                      .format("DD-MM-YYYY hh:mm A"),
                                },
                              ]}
                              pagination={false}
                              scroll={{ y: 150 }}
                              rowKey="uid"
                              size="small"
                            />
                          }>
                          <span style={{ cursor: "pointer", color: "#1890ff" }}>
                            <FileOutlined /> {feedback.files.length} files
                          </span>
                        </Popover>
                      </Tooltip>
                    </div>
                  </div>
                )}
              </div>
            }>
            {/* Feedback Details Section */}

            {/* Truncated Details */}

            {/* Show More / Show Less Button */}
            {/* {countWords(feedback.details) > 60 && (
                <Button
                  type="link"
                  onClick={() => toggleExpand(feedback.key)}
                  style={{ marginLeft: "8px", flexShrink: 0 }}
                >
                  {expandedKeys[feedback.key] ? "Show Less" : "Show More"}
                </Button>
              )} */}

            {/* Deadline */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "8px",
                textAlign: "left",
              }}>
              <span style={{ fontWeight: "500" }}>Deadline:</span>
              <span style={{ color: "#ff4d4f" }}>
                {moment(feedback.deadline_hard)
                  .local()
                  .format("DD-MM-YYYY hh:mm A")}
              </span>
            </div>

            {/* Word Count */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "8px",
                textAlign: "left",
              }}>
              <span style={{ fontWeight: "500" }}>Word Count:</span>
              <span>{feedback.extra_word_count}</span>
            </div>

            {/* Status */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "8px",
                textAlign: "left",
              }}>
              <span style={{ fontWeight: "500" }}>Status:</span>
              <Tag color={feedback.status === "Pending" ? "orange" : "green"}>
                {feedback.status}
              </Tag>
            </div>

            {/* Display truncated details or full details */}
            <div
              dangerouslySetInnerHTML={{
                __html: feedback.details,
              }}
            />
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default FeedbackCard;
