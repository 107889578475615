import { Empty, Table } from 'antd';
import moment from 'moment';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';


const MobileSubscribersLogs = ({ data }) => {
    const columns = [
        {
            title: 'Key',
            dataIndex: 'key',
            key: 'key',
            render: (text) => <strong>{text}</strong>,
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            render: (text) => text || 'N/A',
        },
    ];

    const transformDataForTable = (item) => {
        return [
            { key: 'Subscriber', value: item.subscriber },
            { key: 'Word Count', value: item.no_of_word },
            { key: 'Completion Date', value: item.date_of_completion ? moment(item.date_of_completion).local().format("YYYY-MM-DD hh:mm A") : 'N/A' },
            { key: 'Type', value: item.type },
            { key: 'Alloted Deadline', value: item.deadline ? moment(item.deadline).local().format("YYYY-MM-DD hh:mm A") : 'N/A' },
            { key: 'Author', value: item.author },
            { key: 'Created By', value: item.created_by ? moment(item.created_by).local().format("YYYY-MM-DD hh:mm A") : 'N/A' },
            { key: 'Deleted By', value: item.deleted_by },
        ];
    };

    return (
        <div style={{ backgroundColor: "#f2f6f9", padding: '10px' }}>
            {data && data.length > 0 ? (
                data.map((item, index) => (
                    <Table
                        key={index}
                        columns={columns}
                        dataSource={transformDataForTable(item)}
                        pagination={false}
                        bordered
                        size="small"
                        style={{ marginBottom: '25px' }}
                        showHeader={false}
                    />
                ))
            ) : (
                <Empty description="No data available" />
            )}
        </div>

    );
};

const SubscriberLogs = ({ subscriberLogsData }) => {

    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
    const { orderKey } = useParams();

    const columns = [
        {
            title: 'Subscriber',
            dataIndex: 'subscriber',
            key: 'subscriber',
            render: (text) => text || 'N/A',
        },
        {
            title: 'Word Count',
            dataIndex: 'no_of_word',
            key: 'no_of_word',
            render: (text) => text || 'N/A',
        },
        {
            title: 'Completion Date',
            dataIndex: 'date_of_completion',
            key: 'date_of_completion',
            render: (text) => {
                return text
                    ? moment(text).local().format("YYYY-MM-DD hh:mm A")
                    : 'N/A';
            },
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (text) => text || 'N/A',
        },
        {
            title: 'Alloted Deadline',
            dataIndex: 'deadline',
            key: 'deadline',
            render: (text) => {
                return text
                    ? moment(text).local().format("YYYY-MM-DD hh:mm A")
                    : 'N/A';
            },
        },
        {
            title: 'Author',
            dataIndex: 'author',
            key: 'author',
            render: (text) => text || 'N/A',
        },
        {
            title: 'Created By',
            dataIndex: 'created_by',
            key: 'created_by',
            render: (text) => {
                return text
                    ? moment(text).local().format("YYYY-MM-DD hh:mm A")
                    : 'N/A';
            },
        },
        {
            title: 'Deleted By',
            dataIndex: 'deleted_by',
            key: 'deleted_by',
            render: (text) => text || 'N/A',
        },
    ];

    const dataSource = subscriberLogsData
        ? subscriberLogsData.map((log, index) => ({
            key: index,
            subscriber: log.subscriber,
            no_of_word: log.no_of_word,
            date_of_completion: log.date_of_completion,
            type: log.type,
            deadline: log.deadline,
            author: log.author,
            created_by: log.created,
            deleted_by: log.deleted_by,
        }))
        : [];

    return (
        <div style={{ backgroundColor: "" }}>
            <h3>DELETE SUBSCRIBER HISTORY - {orderKey}</h3>
            {
                isMobile
                    ? <MobileSubscribersLogs data={dataSource} />
                    : <Table
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                        bordered
                        scroll={{ y: 350, x: '100%' }}
                    />
            }
        </div>
    );
};

export default SubscriberLogs;
