import React, { useEffect, useState } from 'react';
import { Form, Select, Input, Button, Row, Col, Spin, DatePicker, message } from 'antd';
import { CloseOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../services/axiosInstance';
import SpinLoading from './SpinLoading';
import { catchError } from '../../utils/errorHandler';
import moment from 'moment';

const { Option } = Select;

const MyForm = ({ setShowAssign, setFormSubmit, fetchFreelancerList, fetchOrderDetails }) => {
    const [form] = Form.useForm();
    const [specificationsData, setSpecificationsData] = useState([]);
    const [currencyData, setCurrencyData] = useState([])
    const [flListData, setFlListData] = useState([])
    const [loading, setLoading] = useState(false)
    const [formLoading, setFormLoading] = useState(false)
    const { orderKey } = useParams()

    const fetchSpecifications = async () => {
        setLoading(true)
        try {
            const res = await axiosInstance.get('/task/v2/specification/dropdown');
            if (res?.data?.status === "success") {
                setSpecificationsData(res?.data?.data);
            }
            setLoading(false)
        } catch (err) {
            console.error("Error fetching specifications:", err);
            setLoading(false)
        }
    };

    const fetchCurrencies = async () => {
        setLoading(true);
        try {
            const res = await axiosInstance.get('api/v2/currency/all/');
            if (res?.data?.status === "success") {
                setCurrencyData(res?.data?.data?.currency);
            }
            setLoading(false);
        } catch (err) {
            console.error("Error fetching currencies:", err);
            setLoading(false);
        }
    };

    const getFreelancersList = async () => {
        setLoading(true);
        try {
            const res = await axiosInstance.get('task/v2/fl-users/');
            if (res?.data?.status === "success") {
                setFlListData(res?.data?.data);
            }
            setLoading(false);
        } catch (err) {
            console.error("Error fetching currencies:", err);
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchSpecifications()
        fetchCurrencies()
        getFreelancersList()
    }, [])


    const [formData, setFormData] = useState({
        specification: '',
        currency: '',
        budget: '',
        freelancer: [
            {
                id: Math.random().toString(36).substr(2, 9),
                fl_subscriber: '',
                amount: '',
                currency: '',
                deadline: '',
            },
        ],
    });

    const handleSkillChange = (value) => {
        setFormData({ ...formData, specification: value });
    };

    const handleCurrencyChange = (value) => {
        setFormData({ ...formData, currency: value });
    };

    const handleAmountChange = (e) => {
        setFormData({ ...formData, budget: e.target.value });
    };

    const handleFreelancerChange = (id, key, value) => {
        const updatedFreelancers = formData.freelancer.map(freelancer =>
            freelancer.id === id ? { ...freelancer, [key]: value } : freelancer
        );
        setFormData({ ...formData, freelancer: updatedFreelancers });
    };


    const addFreelancer = () => {
        const newFreelancer = {
            id: Math.random().toString(36).substr(2, 9),
            fl_subscriber: '',
            amount: '',
            currency: '',
            deadline: '',
        };
        setFormData({ ...formData, freelancer: [...formData.freelancer, newFreelancer] });
        form.setFieldsValue({ [`fl_subscriber-${newFreelancer.id}`]: '', [`amount-${newFreelancer.id}`]: '', [`currency-${newFreelancer.id}`]: '' });
    };


    const removeFreelancer = (id) => {
        const updatedFreelancers = formData.freelancer.filter(freelancer => freelancer.id !== id);
        setFormData({ ...formData, freelancer: updatedFreelancers });
        form.resetFields([`fl_subscriber-${id}`, `amount-${id}`, `currency-${id}`, `deadline-${id}`]);
    };


    const handleSubmit = async () => {
        // Function to filter out any fields that are null, undefined, or empty
        const cleanObject = (obj) => {
            return Object.fromEntries(
                Object.entries(obj).filter(([key, value]) =>
                    value !== null && value !== undefined && value !== ''
                )
            );
        };

        const updatedFormData = {
            ...formData,
            freelancer: formData.freelancer.map(freelancer => {
                const cleanedFreelancer = {
                    ...freelancer,
                    deadline: freelancer.deadline
                        ? moment(freelancer.deadline, 'YYYY-MM-DD hh:mm A').toISOString()
                        : null,
                };
                return cleanObject(cleanedFreelancer);
            }),
        };

        setFormLoading(true);
        console.log("updatedFormData", updatedFormData)

        try {
            const res = await axiosInstance.post(`task/v2/assign-multiple-fl/${orderKey}/`, cleanObject(updatedFormData)); // Clean the main formData
            console.log("Response:", res);

            if (res?.data?.status === "success") {
                message.success(res.data.data);
                setShowAssign(false);
                setFormSubmit(true);
                await fetchFreelancerList();
                fetchOrderDetails()
            } else {
                message.error(res?.data?.message || "Failed to assign subscribers.");
            }
        } catch (err) {
            await catchError(err);
        } finally {
            setFormLoading(false);
        }
    };



    // console.log("currencyDatacurrencyData", currencyData)

    return (
        <Form layout='vertical' onFinish={handleSubmit} form={form}>
            <Row
                style={{
                    marginBottom: "20px",
                    width: "100%",
                    flexDirection: window.innerWidth < 768 ? 'column' : 'row',
                }}
                gutter={12}>
                <Col md={6}>
                    <div style={{ padding: "35px 10px 10px 10px", border: "1px solid #d9d9d9" }}>

                        <Form.Item
                        // label="Skills"
                        // name="skills"
                        // rules={[{ required: false, message: 'Please select Skills!' }]}
                        >
                            <Select
                                onChange={handleSkillChange}
                                placeholder="Select Skill"
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.children || '').toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {
                                    loading
                                        ? (
                                            <Option disabled value="loading">
                                                <SpinLoading />
                                            </Option>
                                        )
                                        : (
                                            specificationsData?.map((item) => (
                                                <Option key={item.uid} value={item.uid}>
                                                    {item.name}
                                                </Option>
                                            ))
                                        )
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                        // label="Currency"
                        // name="currency"
                        // rules={[{ required: true, message: 'Please select a Currency!' }]}
                        >
                            <Select
                                onChange={handleCurrencyChange}
                                placeholder="Select Currency"
                            >
                                {
                                    loading ? (
                                        <Option disabled value="loading">
                                            <SpinLoading />
                                        </Option>
                                    ) : (
                                        currencyData?.map((item) => (
                                            <Option key={item} value={item}>
                                                {item}
                                            </Option>
                                        ))
                                    )
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                        // label="Budget"
                        // name="budget"
                        // rules={[{ required: true, message: 'Please enter Budget!' }]}
                        >
                            <Input type="number" onChange={handleAmountChange} placeholder="Enter Budget" />
                        </Form.Item>
                    </div>
                </Col>

                <Col md={18}>
                    <div style={{ border: "1px solid #d9d9d9", padding: "10px", maxHeight: "200px", overflowY: "auto" }}>
                        <div style={{ display: "flex", justifyContent: "end", width: "100%", marginBottom: "10px" }}>
                            <Button type="dashed" onClick={addFreelancer}>
                                <PlusOutlined style={{ color: "black", fontSize: "20px" }} />  <span>Click to add new Freelancer</span>
                            </Button>
                        </div>

                        {formData.freelancer.map((freelancer, index) => (
                            <div
                                style={{
                                    borderTop: index > 0 ? "1px solid #d9d9d9" : "",
                                    marginBottom: "10px",
                                }}
                                key={freelancer.id}
                            >
                                <div style={{ display: "flex", justifyContent: "end", width: "100%" }}>
                                    {index > 0 && (
                                        <Button
                                            type="danger"
                                            onClick={() => removeFreelancer(freelancer.id)}
                                        >
                                            <CloseOutlined className="custom-close-icon" />
                                        </Button>
                                    )}
                                </div>
                                <Row gutter={12}>
                                    <Col xs={24} md={12}>
                                        <Form.Item
                                            name={`fl_subscriber-${freelancer.id}`}
                                            rules={[{ required: true, message: 'Please select a freelancer!' }]}
                                        >
                                            <Select
                                                placeholder="Select Freelancer"
                                                onChange={(value) => handleFreelancerChange(freelancer.id, 'fl_subscriber', value)}
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.children || '').toLowerCase().includes(input.toLowerCase())
                                                }
                                            >
                                                {
                                                    loading ? (
                                                        <Option value="loading" disabled>
                                                            <SpinLoading />
                                                        </Option>
                                                    ) : (
                                                        flListData?.map((item, index) => (
                                                            <Option key={item?.username || index} value={item?.username}>
                                                                {item?.full_name}
                                                            </Option>
                                                        ))
                                                    )
                                                }

                                            </Select>
                                        </Form.Item>

                                    </Col>

                                    <Col xs={24} md={12}>
                                        <Form.Item
                                            name={`amount-${freelancer.id}`}
                                            rules={[{ required: true, message: 'Please enter the amount!' }]}
                                        >
                                            <Input
                                                placeholder="Enter Amount"
                                                type="number"
                                                onChange={(e) => handleFreelancerChange(freelancer.id, 'amount', e.target.value)}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} md={12}>
                                        <Form.Item
                                            name={`currency-${freelancer.id}`}
                                            rules={[{ required: true, message: 'Please select a currency!' }]}
                                        >
                                            <Select
                                                placeholder="Select Currency"
                                                onChange={(value) => handleFreelancerChange(freelancer.id, 'currency', value)}
                                            >
                                                {
                                                    loading ? (
                                                        <Option disabled value="loading">
                                                            <SpinLoading />
                                                        </Option>
                                                    ) : (
                                                        currencyData?.map((item) => (
                                                            <Option key={item} value={item}>
                                                                {item}
                                                            </Option>
                                                        ))
                                                    )
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} md={12}>
                                        <Form.Item
                                            name={`deadline-${freelancer.id}`}
                                            rules={[{ required: true, message: 'Please enter a Deadline!' }]}
                                        >
                                            <DatePicker
                                                style={{ width: window.innerWidth < 768 ? "375px" : "100%" }}
                                                showTime={{ format: "hh:mm A", use12Hours: true }}
                                                format="YYYY-MM-DD hh:mm A"
                                                placeholder="Select Deadline"
                                                onChange={(date, dateString) => {
                                                    handleFreelancerChange(freelancer.id, 'deadline', dateString);
                                                }}
                                            />


                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        ))}

                        <Row justify="end" style={{ gap: "10px" }}>
                            <Col>
                                <Button type="primary" htmlType="submit">{formLoading ? "Loading..." : "Assign Freelancer"}</Button>
                            </Col>
                            <Button
                                type="default"
                                onClick={() => setShowAssign(false)}
                                style={{ width: 'auto', marginLeft: "0px" }}
                            >
                                Cancel
                            </Button>

                        </Row>
                    </div>
                </Col>

            </Row>
        </Form>
    );
};

export default MyForm;
