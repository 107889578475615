import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Tooltip,
  Typography,
  Tag,
  Avatar,
  Popover,
  Descriptions,
} from "antd";
import usePermissions from "../../hooks/usePermissions";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";

const PayoutRequestTable = ({
  payoutData,
  loading,
  pagination,
  setPagination,
  handleOpenModal,
  onRowSelect,
}) => {
  const [columns, setColumns] = useState([]);
  const { hasPermission } = usePermissions();


  useEffect(() => {
    generateColumns(payoutData);
  }, [payoutData, ]); // Ensure component updates when remarks expand

  const generateColumns = (data) => {
    if (!data.length) return;

    const sampleRecord = data[0];
    const dynamicColumns = Object.keys(sampleRecord)
      .map((key) => {
        if (
          key === "uuid" ||
          key === "created_at" ||
          key === "currency" ||
          key === "amount_approved_to_fl" ||
          key === "disbursed_amount" ||
          key === "disbursal_status"
        )
          return null; // Exclude unnecessary columns

        const columnConfig = {
          title: key
            .replace(/_/g, " ")
            .replace(/\b\w/g, (c) => c.toUpperCase()), // Capitalize first letter
          dataIndex: key,
          key,
        };

        switch (key) {
          case "requested_by":
            return {
              ...columnConfig,
              title: "Requested by",
              fixed: "left",
              render: (_, record) => (
                <>
                  <div>{record.requested_by}</div>
                  <small style={{ color: "gray" }}>
                    {moment(record.created_at)
                      .local()
                      .format("DD-MM-YYYY HH:mm A")}
                  </small>
                </>
              ),
            };

          case "total_alloted_amount":
            return {
              ...columnConfig,
              title: " Total Allotted Amount",
              width: 200,
              render: (_, record) => {
                return (
                  <>
                    <div
                      style={{
                        fontWeight: "500",

                        display: "flex",
                        alignItems: "center",
                        gap: 4,
                      }}>
                      {record.total_alloted_amount} {record.currency}
                    </div>
                  </>
                );
              },
            };

          case "payable_amount":
            return {
              ...columnConfig,
              title: "Payable Amount",
              width: 200,
              render: (_, record) => {
                return (
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      color: "#046C4E", // Highlight with an attention-grabbing color
                      // backgroundColor: "#FFF3E0", // Light background for contrast
                      padding: "5px 10px",
                      borderRadius: "5px",
                      display: "inline-block",
                    }}>
                    {record.payable_amount} {record.currency}
                  </div>
                );
              },
            };

          case "fl_deduction":
            return {
              ...columnConfig,
              title: "FL Deduction",
              width: 130,
              render: (_, record) => (
                <Typography.Text
                  style={{
                    color: "red",
                    display: "flex",
                    alignItems: "center",
                    gap: 4,
                  }}>
                  {/* <MinusCircleOutlined />{" "} */}
                  {record.fl_deduction > 0
                    ? `${record.fl_deduction} ${record.currency}`
                    : record.fl_deduction}
                </Typography.Text>
              ),
            };

          case "fl_arear":
            return {
              ...columnConfig,
              title: " FL Arear",
              width: 120,
              render: (_, record) => (
                <Typography.Text
                  style={{
                    color: "green",
                    display: "flex",
                    alignItems: "center",
                    gap: 4,
                  }}>
                  {/* <PlusCircleOutlined />{" "} */}
                  {record.fl_arear > 0
                    ? `${record.fl_arear} ${record.currency}`
                    : record.fl_arear}
                </Typography.Text>
              ),
            };

          case "fl_request_status":
            return {
              ...columnConfig,
              title: "FL Request Status",
              render: (status) => (
                <Tag color={status === "Pending" ? "orange" : "green"}>
                  {status}
                </Tag>
              ),
            };

          case "disbursal_status":
            return {
              ...columnConfig,
              title: "Disbursal Status",
              render: (_, record) => (
                <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                  <Tag
                    color={
                      record.disbursal_status === true
                        ? "green"
                        : record.disbursal_status === false
                        ? "red"
                        : "gray"
                    }>
                    {record.disbursal_status === true
                      ? "Disbursed"
                      : record.disbursal_status === false
                      ? "Not Disbursed"
                      : "N/A"}
                  </Tag>
                  <Button
                    type="link"
                    icon={<EditOutlined />}
                    // onClick={() => handleEditDisbursalStatus(record)}
                  />
                </div>
              ),
            };
          case "author":
            return {
              ...columnConfig,
              title: "Author",
              render: (_, record) => {
                const nameParts = record.author ? record.author.split(" ") : [];
                const initials =
                  nameParts.length > 1
                    ? `${nameParts[0][0]}${nameParts[nameParts.length - 1][0]}`
                    : nameParts[0]?.[0] || "?";

                return (
                  <Tooltip title={record.author}>
                    <Avatar style={{ backgroundColor: "orange" }}>
                      {initials.toUpperCase()}
                    </Avatar>
                  </Tooltip>
                );
              },
            };
          case "remarks":
            return {
              title: "Remarks",
              dataIndex: key,
              render: (_, record, recordIndex) => (
                <Popover
                  content={
                    <div
                      style={{ width: 300, maxHeight: 300, overflowY: "auto" }}>
                      <Descriptions size="small" column={1} bordered>
                        {record.remarks?.map((r, remarkIndex) => {
                          return (
                            <Descriptions.Item
                              key={remarkIndex}
                              label={r.label || "Remark"}>
                              <div
                                style={{
                                  maxHeight: 100, // Set height limit
                                  overflowY: "auto", // Enable scrolling if content exceeds max height
                                  paddingRight: 5, // Prevent text cutoff
                                }}>
                                <Typography.Text
                                  style={{
                                    whiteSpace: "pre-line",
                                    display: "block",
                                  }}>
                                  {r.reason}
                                </Typography.Text>
                              </div>
                            </Descriptions.Item>
                          );
                        })}
                      </Descriptions>
                    </div>
                  }
                  title="Remarks"
                  trigger="click">
                  <Button type="link">View</Button>
                </Popover>
              ),
            };

          default:
            return columnConfig;
        }
      })
      .filter(Boolean); // Remove null values

    // Reordering the columns to group amount-related fields together
    const orderedColumns = [
      ...dynamicColumns.filter((col) => col.key === "requested_by"),

      // Amount-related fields should be grouped together
      ...dynamicColumns.filter((col) =>
        [
          "total_alloted_amount",
          "fl_deduction",
          "fl_arear",
          "payable_amount",
          "amount_approved_to_fl",
          "disbursed_amount",
        ].includes(col.key)
      ),

      // Other columns that are not in the above groups
      ...dynamicColumns.filter(
        (col) =>
          ![
            "requested_by",
            "total_alloted_amount",
            "fl_deduction",
            "fl_arear",
            "payable_amount",
            "amount_approved_to_fl",
            "disbursed_amount",
          ].includes(col.key)
      ),
    ];
    orderedColumns.push({
      title: "Add Payout",
      key: "actions",
      fixed: "right",
      render: (record) => {
        const canViewDetails = hasPermission(
          "can_view_fl_payment_request_detail"
        ); // Check permission

        return (
          <Button
            style={{
              background: canViewDetails ? "#3F83F8" : "gray",
              borderRadius: "50%",
              color: "white",
              opacity: canViewDetails ? 1 : 0.5, // Reduce opacity if disabled
              cursor: canViewDetails ? "pointer" : "not-allowed",
            }}
            icon={<PlusOutlined style={{ fontWeight: 600 }} />}
            onClick={() => {
              if (canViewDetails) {
                console.log(record, "123");
                onRowSelect(record);
                handleOpenModal("payout");
              }
            }}
            disabled={!canViewDetails} // Disable if no permission
          />
        );
      },
    });
    setColumns(orderedColumns);
  };

  return (
    <Table
      style={{ marginTop: 14 }}
      dataSource={payoutData}
      columns={columns}
      loading={loading}
      pagination={{
        ...pagination,
      }}
      onChange={(pagination) => setPagination(pagination)}
      scroll={{ x: "max-content" }}
    />
  );
};

export default PayoutRequestTable;
