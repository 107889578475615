import { Table } from 'antd';
import React from 'react';

const FreelancerAccord = ({ freelancerInfo }) => {
    const budget = freelancerInfo?.budget || [];

    // Columns for the budget data
    const budgetColumns = [
        {
            title: 'INR Budget',
            dataIndex: 'inr_budget',
            key: 'inr_budget',
        },
        {
            title: 'Converted',
            dataIndex: 'converted',
            key: 'converted',
            render: (converted) => (converted ? 'Yes' : 'No'), // Display 'Yes' or 'No' based on converted value
        },
    ];

    return (
        <div style={{ marginBottom: '16px' }}>
    
            <Table
                columns={budgetColumns}
                dataSource={budget}
                rowKey={(record, index) => index} // Use index as unique key for each row
                pagination={false}
                bordered
                size="small"
                style={{ width: '100%' }}
                scroll={{ y: 200 }}
            />
        </div>
    );
};

export default FreelancerAccord;
