import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Col, Form, Input, Row } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { resetconfirm } from "../../redux/slices/authSlice";

const ResetPasswordConfirm = () => {
  const { uid, token } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const next = queryParams.get("next");
  const { loading } = useSelector((state) => state.auth);

  const onFinish = async (values) => {
    let payload = {
      new_password1: values.password1,
      new_password2: values.password2,
      uid: uid,
      token: token,
    };
    const { success } = await dispatch(resetconfirm(payload));
    if (success) {
      navigate(next || "/");
    }
  };

  const onFinishFailed = () => {};

  return (
    <Row justify="space-around" align="middle" style={{ height: "100%" }}>
      <Col xl={6} xxl={6} lg={8} md={12} sm={22} xs={22}>
        <Form
          name="basic"
          style={{
            textAlign: "start",
            padding: "2rem",
            boxShadow:
              "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
          }}
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            wrapperCol={{
              span: 24,
            }}
            style={{ textAlign: "center" }}
          >
            <h2>Reset Password</h2>
          </Form.Item>
          <Form.Item
            name="password1"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Enter a new password"
            />
          </Form.Item>

          <Form.Item
            name="password2"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Confirm new passowrd"
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
              loading={loading}
            >
              Reset
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default ResetPasswordConfirm;
