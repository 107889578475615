import React from "react";
import { Table, Empty } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const TaskDirectUserTable = ({ data }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const columns = [
    {
      title: "Order Id",
      dataIndex: "key",
      key: "key",
      render: (key) => (
        <Link target="_blank" to={`/order-management/my-orders/${key}`}>
          {key}
        </Link>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Deadline Soft",
      dataIndex: "deadline_soft",
      key: "deadline_soft",
      render: (text) => moment(text).format("DD-MM-YYYY hh:mm A"), // Format the date
    },
    {
      title: "Word",
      dataIndex: "word",
      key: "word",
    },
    {
      title: "Order Ownership",
      dataIndex: "ownership",
      key: "ownership",
    },
    {
      title: "Created On",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => moment(text).format("DD-MM-YYYY hh:mm A"), // Format the date
    },
  ];

  const transformDataForMobile = (item) => [
    {
      key: "Order Id",
      value: (
        <Link target="_blank" to={`/order-management/my-orders/${item.key}`}>
          {item.key}
        </Link>
      ),
    },
    { key: "Title", value: item.title },
    {
      key: "Deadline Soft",
      value: item.deadline_soft
        ? moment(item.deadline_soft).format("DD-MM-YYYY hh:mm A")
        : "N/A",
    },
    { key: "Word", value: item.word },
    { key: "Order Visiblity", value: item.ownership },
    {
      key: "Created On",
      value: item.created_at
        ? moment(item.created_at).format("DD-MM-YYYY hh:mm A")
        : "N/A",
    },
  ];

  return (
    <div>
      {data && data.length > 0 ? (
        isMobile ? (
          <div
            style={{
              marginTop: "20px",
            }}
          >
            {data.map((item, index) => (
              <Table
                key={index}
                columns={[
                  { title: "Key", dataIndex: "key", key: "key" },
                  { title: "Value", dataIndex: "value", key: "value" },
                ]}
                dataSource={transformDataForMobile(item)}
                pagination={false}
                bordered
                size="small"
                showHeader={false}
                style={{ marginBottom: "25px" }}
              />
            ))}
          </div>
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            rowKey="key"
            pagination={false}
          />
        )
      ) : (
        <Empty description="No data available" />
      )}
    </div>
  );
};

export default TaskDirectUserTable;
