import { Button, Select, Spin, message } from 'antd';
import React, { useEffect, useState } from 'react';
import axiosInstance from '../../services/axiosInstance';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { catchError } from '../../utils/errorHandler';

const { Option } = Select;

const OrderMoveToFreelancerPortal = () => {
    const [specificationsData, setSpecificationsData] = useState([]);
    const [selectedSpecification, setSelectedSpecification] = useState(null);
    const [loading, setLoading] = useState(false)
    const { orderKey } = useParams()

    const fetchSpecifications = async () => {
        setLoading(true)
        try {
            const res = await axiosInstance.get('/task/v2/specification/dropdown');
            if (res?.data?.status === "success") {
                setSpecificationsData(res?.data?.data);
            }
            setLoading(false)
        } catch (err) {
            console.error("Error fetching specifications:", err);
            setLoading(false)
        }
    };

    useEffect(() => {
        fetchSpecifications();
    }, []);

    const handleSelectChange = (value) => {
        setSelectedSpecification(value);
        console.log("Selected Specification:", value);
    };

    const handleSubmit = async () => {
        setLoading(true)
        try {
            const response = await axiosInstance.post(`task/v2/order-move-to-freelancer-portal/${orderKey}/`, {
                specification: selectedSpecification,
            });
            // console.log("Response:", response);
            if (response.status === 200) {
                if (response?.data?.status === "success") {
                    message.success(response?.data?.data)
                }
            }
            setSelectedSpecification(null);
            setLoading(false)

        } catch (err) {
            await catchError(err)
            setLoading(false)
            setSelectedSpecification(null);

        }
    };

    return (
        <div >
            <div style={{ marginBottom: "10px" }}>Select Skills</div>
            <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Select Specifications"
                onChange={handleSelectChange}
                value={selectedSpecification}
                optionFilterProp="children"
                filterOption={(input, option) =>
                    (option?.children || '').toLowerCase().includes(input.toLowerCase())
                }
            >
                {loading ? (
                    <Option disabled value="loading">
                        <Spin size='small' tip="Loading..." />
                    </Option>
                ) : (
                    specificationsData?.map((item) => (
                        <Option key={item.uid} value={item.uid}>
                            {item.name}
                        </Option>
                    ))
                )}
            </Select>
            <div style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}>
                <Button className='handle_submit_action_button' onClick={handleSubmit} >{loading ? "Loading..." : "Submit"}</Button>
            </div>
        </div>
    );
}

export default OrderMoveToFreelancerPortal;
