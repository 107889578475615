// Tasks.js
import React, { useState, useEffect, useCallback } from "react";
import { message, Button, Flex } from "antd";
import orderService from "../../services/OrderLists";
import OrderListTable from "./OrderListTable";
import SearchBar from "../../components/SearchBar";
import { CSSTransition } from "react-transition-group";
import { FilterOutlined } from "@ant-design/icons";
import "./tasks.css";
import SearchFilters from "./SearchFilters";
import usePermissions from "../../hooks/usePermissions";
import OrderFormParent from "./OrderFormParent";
const Tasks = () => {
  const { hasPermission } = usePermissions();
  const [filtersList, setFiltersList] = useState([]);
  const [fetchingStatusList, setFetchingStatusList] = useState(false);

  const [showFilters, setShowFilters] = useState(false);
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [statusList, setStatusList] = useState({}); // New state for status list
  const [isSearching, setIsSearching] = useState(false);
  const [urgencyFilter, setUrgencyFilter] = useState(""); // Default filter to "Deadline > 1 Day"
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
    showSizeChanger: false,
  });

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const loadOrders = useCallback(
    async (urgencyLevel, page = 1, pageSize = 20) => {
      setLoading(true);
      try {
        const result = await orderService.fetchOrders(
          urgencyFilter || null, // Pass null if urgencyFilter is empty
          filters, // Pass filters as the second argument
          page, // Pass the current page
          pageSize // Pass the page size
        );

        if (result.success) {
          setPagination({
            ...pagination,
            total: result?.data?.data?.count || 0,
            current: page,
            pageSize,
          });

          setFiltersList(result?.data?.data?.filters);
          setOrders(
            result.data.data.results.map((order) => ({
              ...order,
            }))
          );
          setTotal(result.data.data.count);
        } else {
          message.error("Failed to fetch orders");
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
        setLoading(false)
        // message.error("An error occurred while fetching orders");
      } finally {
        setLoading(false);
      }
    },
    [currentPage, urgencyFilter, isSearching, filters]
  );

  // const handleSearch = async (value) => {
  //   setSearchKey(value);
  //   setOrders([]);
  //   if (!value) {
  //     setIsSearching(false);
  //     loadOrders(urgencyFilter);
  //     return;
  //   }
  //   setLoading(true);
  //   const result = await orderService.searchOrders(value);
  //   if (result.success) {
  //     setOrders(result.data.results.map((order) => ({ ...order, key: order.key })));
  //     setIsSearching(true);
  //   } else {
  //     message.error("Failed to fetch orders");
  //   }
  //   setLoading(false);
  // };
  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };
  const handleClearSearch = async () => {
    setFilters({});
    console.log(filters, " filters  in taskssssssssssssssssssssss");
    await loadOrders(urgencyFilter, 1);
  };

  const handleTableChange = (pagination) => {
    if (!isSearching) {
      setCurrentPage(pagination.current);
      loadOrders(urgencyFilter, 1, pagination.pageSize);
    }
  };
  const fetchStatusList = async () => {
    try {
      setFetchingStatusList(true);
      const response = await orderService.getStatusList();

      if (response) {
        setFetchingStatusList(false);
        const newStatusList = {};
        for (const [key, value] of Object.entries(response.data.data)) {
          newStatusList[value] = parseInt(key);
        }
        setStatusList(newStatusList);
      } else {
        setFetchingStatusList(false);

        console.error("Failed to fetch status list or invalid response format");
        console.error("Response:", response);
      }
    } catch (error) {
      setFetchingStatusList(false);
      console.error("Error fetching status list:", error);
    }
  };

  useEffect(() => {
    if (showFilters) {
      fetchStatusList();
    }
  }, [showFilters]);

  useEffect(() => {
    loadOrders(urgencyFilter);
  }, [currentPage, urgencyFilter, isSearching, filters]);

  const handleSearchClick = (newFilters) => {
    console.log(newFilters, "vdi");
    setFilters(newFilters);
    setPagination({ ...pagination, current: 1 });
  };

  return (
    <div className="mainParent">
      {showFilters && (
        <CSSTransition
          in={showFilters}
          timeout={300}
          classNames="filter"
          unmountOnExit>
          <SearchFilters
            filtersList={filtersList}
            onSearch={handleSearchClick}
            loadOrders={handleClearSearch}
            pagination={pagination}
            statusList={statusList}
            fetchingStatusList={fetchingStatusList}
          />
        </CSSTransition>
      )}
      {/* <SearchFilters
        onSearch={handleSearchClick}
        loadOrders={handleClearSearch}
        pagination={pagination}
        statusList={statusList}
      /> */}
      {/* <SearchBar
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        handleSearch={handleSearch}
        handleClearSearch={handleClearSearch}
      /> */}
      <Flex gap={4}>
        {hasPermission("can_create_task") && (
          <Button
            type="primary"
            onClick={showModal}
            disabled={loading}
            style={{ background: "#001529", color: "white" }}>
            Add Order
          </Button>
        )}

        <OrderFormParent
          visible={isModalVisible}
          setVisible={setIsModalVisible}
          onClose={closeModal}
          loadOrders={loadOrders}
        />
        <Button
          type="primary"
          icon={<FilterOutlined />}
          onClick={toggleFilters}
          style={{ background: "#040724", width: "40px" }}
        />
      </Flex>

      <OrderListTable
        orders={orders}
        loading={loading}
        pagination={
          isSearching
            ? false
            : {
                current: currentPage,
                pageSize: pageSize,
                total: total,
                showSizeChanger: false,
              }
        }
        onChange={handleTableChange}
        setUrgencyFilter={setUrgencyFilter}
      />
    </div>
  );
};

export default Tasks;
