// Tasks.js
import React, { useState, useEffect } from "react";
import { Button, Flex, Tabs, } from "antd";
import { CSSTransition } from "react-transition-group";
import { FilterOutlined, NotificationOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { debounce } from "lodash";
import { useMemo } from "react";
import orderService from "../../services/OrderLists";
import FiltersAllocationAdjustment from "../../page-components/AllocationAdjustments/FiltersAllocationAdjustment"
import { useNavigate } from "react-router-dom";
import AllocationDataList from "../../services/allocationDataList";
import AllocationListTable from "../../page-components/AllocationAdjustments/AllocationTable";
import MobileCompAllocation from "../../page-components/AllocationAdjustments/MobileCompAllocation";
import usePermissions from "../../hooks/usePermissions";
import Marquee from "react-fast-marquee";

const MAX_CONCURRENT_REQUESTS = 4;
const requestQueue = [];
let activeRequests = 0;

// Request Queue Manager
const processQueue = async () => {
  if (requestQueue.length === 0 || activeRequests >= MAX_CONCURRENT_REQUESTS) {
    return;
  }

  const { requestFn, resolve, reject } = requestQueue.shift();
  activeRequests++;

  try {
    const result = await requestFn();
    resolve(result);
  } catch (error) {
    reject(error);
  } finally {
    activeRequests--;
    processQueue();
  }
};

// Wrapper for API Calls
const enqueueRequest = (requestFn) =>
  new Promise((resolve, reject) => {
    requestQueue.push({ requestFn, resolve, reject });
    processQueue();
  });


const AllocationAdjustment = () => {

  const { TabPane } = Tabs;
  const navigate = useNavigate();
  const { hasPermission } = usePermissions();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [loading, setLoading] = useState(true);
  const [fetchingStatusList, setFetchingStatusList] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  const [subscribers, setSubscribers] = useState([]);
  const [ownersData, setOwners] = useState([])
  const [allocationData, setALlocationdata] = useState([])
  const [filtersList, setFiltersList] = useState([]);

  const [selectedSortOrder, setSelectedSortOrder] = useState({
    order: "",
    sort: "",
  });

  const [statusList, setStatusList] = useState({}); // New state for status list
  const [isSearching, setIsSearching] = useState(false);
  const [urgencyFilter, setUrgencyFilter] = useState("red"); // Default filter to "Deadline > 1 Day"
  const [filters, setFilters] = useState({
    key: "",
    status: 1,
    visibility: "",
    subscriber: "",
    source: "",
    start_date: "",
    end_date: "",
    client_deadline_start: "",
    client_deadline_end: "",
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
    showSizeChanger: true,
  });

  const handleTabChange = (key) => setUrgencyFilter(key);

  const debouncedLoadOrders = useMemo(
    () =>
      debounce(async (urgencyLevel, page = 1, pageSize = 20) => {
        setLoading(true);
        // console.log("filtersmain", filters)
        try {
          const result = await enqueueRequest(() =>
            AllocationDataList.fetchAllocationData(
              urgencyLevel || null,
              {
                ...filters,
                order: selectedSortOrder.order,
                sort: selectedSortOrder.sort,
                status: filters.status ?? 1,
              },
              page,
              pageSize,
              filters
            )
          );

          if (result.success) {
            setFiltersList(result?.data?.data?.filters || []);
            setALlocationdata(result.data.data.results);
            setPagination({
              ...pagination,
              total: result?.data?.data?.count || 0,
              current: page,
              pageSize,
            });
          }
        } catch (error) {
          console.error("Error fetching orders:", error);
        } finally {
          setLoading(false);
        }
      }, 100),
    [pagination]
  );

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleClearSearch = async () => {
    setFilters({
      key: "",
      status: "", // reset to empty
      visibility: "",
      subscriber: "",
      source: "",
      start_date: "",
      end_date: "",
      client_deadline_start: "",
      client_deadline_end: "",
    });
    setPagination((prev) => ({
      ...prev,
      current: pagination.current,
      pageSize: pagination.pageSize,
    }));
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const order =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setSelectedSortOrder({ order: sorter.field, sort: order });
    setPagination((prev) => ({
      ...prev,
      current: pagination.current,
      pageSize: pagination.pageSize,
    }));
    // loadOrders(urgencyFilter, pagination.current, pagination.pageSize);
    debouncedLoadOrders(
      urgencyFilter,
      pagination.current,
      pagination.pageSize,
      filters
    );
  };

  const fetchStatusList = async () => {
    try {
      setFetchingStatusList(true);
      const response = await orderService.getStatusList();

      if (response) {
        setFetchingStatusList(false);
        const newStatusList = {};
        for (const [key, value] of Object.entries(response.data.data)) {
          newStatusList[value] = parseInt(key);
        }

        setStatusList(newStatusList);
      } else {
        setFetchingStatusList(false);
      }
    } catch (error) {
      setFetchingStatusList(false);
      console.error("Error fetching status list:", error);
    }
  };

  const fetchDropdownList = async () => {
    try {
      const dropdownList = await AllocationDataList.getSubscribersDropDown();
      const ownerDropdownList = await AllocationDataList.ownerDropdownList()

      setSubscribers(dropdownList?.data?.data || []);
      setOwners(ownerDropdownList?.data?.data || [])
    } catch (error) {
      console.error("Error fetching dropdown list:", error);
    }
  };

  useEffect(() => {
    return () => {
      debouncedLoadOrders.cancel();
    };
  }, [debouncedLoadOrders]);

  useEffect(() => {
    fetchStatusList();
    fetchDropdownList();
  }, [])

  useEffect(() => {
    // fetchStatusList();
    // fetchDropdownList();
    debouncedLoadOrders(urgencyFilter, pagination.current, pagination.pageSize);
  }, [
    urgencyFilter,
    pagination.current,
    pagination.pageSize,
    filters,
    selectedSortOrder,
  ]);

  const handleSearchClick = (newFilters) => {
    setFilters(newFilters);
    setPagination({ ...pagination, current: 1 });
  };

  const tabConfig = [
    { key: "red", label: "Deadline Missed", color: "red", bgColor: "#FFCCCB" },
    {
      key: "orange",
      label: "Deadline > 1 Day",
      color: "orange",
      bgColor: "#FFE5B4",
    },
    {
      key: "yellow",
      label: "Deadline in 2-4 Days",
      color: "#FACA15",
      bgColor: "#FFF9C4",
    },
    {
      key: "green",
      label: "Deadline > 4 Days",
      color: "green",
      bgColor: "#C8E6C9",
    },
  ];

  const handlePushAnalytics = () => {
    navigate("/order-management/order-operations/subscriber-analytics");
  };


  return (
    <div className="mainParent">
      <Marquee speed={50} gradient={false}>
        <Button
          type="primary"
          icon={<NotificationOutlined style={{ color: "#FF991F" }} />}
          style={{
            color: "#FF991F",
            background: "transparent",
            border: "none",
            margin: "0 ",
            fontWeight: 600,
          }} >
          Note : Orders with Unassigned status will be displayed by default across all tabs. Reset the filters to view all orders.
        </Button>
      </Marquee>
      {showFilters && (
        <CSSTransition
          in={showFilters}
          timeout={300}
          classNames="filter"
          unmountOnExit>
          <FiltersAllocationAdjustment
            filtersList={filtersList}
            onSearch={handleSearchClick}
            loadOrders={handleClearSearch}
            statusList={statusList}
            // fetchingStatusList={fetchingStatusList}
            subscribers={subscribers}
            ownersData={ownersData}
          />
        </CSSTransition>
      )}

      <Flex style={{ margin: "0px 10px" }} gap={4}>
        <Flex gap={10} align="center">
          {hasPermission("can_view_subscriber_analytics") && (
            <Button
              type="primary"
              onClick={handlePushAnalytics}
              style={{
                background: "linear-gradient(135deg, #FF9800, #FF5722)",
                color: "white",
                fontWeight: "bold",
                border: "none",
                padding: "10px 16px",
                borderRadius: "8px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                transition: "all 0.3s ease",
              }}
              onMouseEnter={(e) => (e.target.style.opacity = "0.8")}
              onMouseLeave={(e) => (e.target.style.opacity = "1")}
            >
              Subscriber Analytics
            </Button>
          )}

          <Button
            type="primary"
            icon={<FilterOutlined />}
            onClick={toggleFilters}
            style={{ background: "#040724", width: "40px" }}
          />
        </Flex>
      </Flex>

      {/* Content Section */}
      {isMobile ? (
        <div style={{ width: "100%" }}>
          <Tabs onChange={handleTabChange} defaultActiveKey="red">
            {tabConfig.map((tab) => (
              <TabPane
                tab={
                  <span
                    style={{
                      color: "black",
                      padding: "8px 16px",
                      borderRadius: "8px 8px 0 0",
                      backgroundColor: tab.bgColor,
                    }}>
                    {tab.label}
                  </span>
                }
                key={tab.key}>
                <Flex
                  vertical
                  // align="flex-end"
                  gap={10}
                  style={{ width: "100%" }}
                >
                  <div style={{ textAlign: "right" }}>
                    {!loading && (
                      <p style={{ margin: 0, padding: 0 }}>
                        Showing{" "}
                        {Math.min(
                          (pagination.current - 1) * pagination.pageSize + 1,
                          pagination.total
                        )}{" "}
                        -{" "}
                        {Math.min(
                          pagination.current * pagination.pageSize,
                          pagination.total
                        )}{" "}
                        of {pagination.total} Orders.
                      </p>
                    )}
                  </div>
                  <MobileCompAllocation
                    data={allocationData}
                    pagination={pagination}
                    setPagination={setPagination}
                    loading={loading}
                  />
                </Flex>
              </TabPane>
            ))}
          </Tabs>
        </div>
      ) : (
        <AllocationListTable
          orders={allocationData}
          loading={loading}
          pagination={!isSearching ? pagination : false}
          onChange={handleTableChange}
          setUrgencyFilter={setUrgencyFilter}
        />
      )}
    </div>
  );
};

export default AllocationAdjustment;
