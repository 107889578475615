// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expanded-row {
    border: 2px solid #1890ff;
    background-color: rgb(241 245 249);
  }
`, "",{"version":3,"sources":["webpack://./src/page-components/All Clients/AllClientsTableSummary.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kCAAkC;EACpC","sourcesContent":[".expanded-row {\n    border: 2px solid #1890ff;\n    background-color: rgb(241 245 249);\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
