import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../services/axiosInstance';
import { Card, List, Spin, Table, Button, message, Empty } from 'antd';
import Title from 'antd/es/typography/Title';
import { catchError } from '../../utils/errorHandler';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import SpinLoading from './SpinLoading';
import AssignFreelancer from './AssignFreelancer';
import MyForm from './test';
import moment from 'moment';

// Mobile view component
const MobileViewComponent = ({ data, onDelete, deletingRow }) => {

    const columns = [
        {
            dataIndex: "key",
            key: "key",
            render: (text) => <strong>{text}</strong>,
        },
        {
            dataIndex: "value",
            key: "value",
            render: (text) => (
                <span style={{ wordWrap: "break-word", wordBreak: "break-word", whiteSpace: "normal" }}>
                    {text || "N/A"}
                </span>
            ),
        },
    ];

    const transformDataForTable = (item) => [
        { key: "Freelancer", value: item.fl_subscriber },
        { key: "Amount", value: item.amount },
        { key: "Amount Deduction", value: item.amount_deduction },
        { key: "Currency", value: item.currency },
        { key: "Deadline", value: item.deadline ? moment(item.deadline).local().format("DD-MM-YYYY hh:mm A") : 'N/A' },
        {
            key: "Delete",
            value: <div onClick={() => onDelete(item.uuid)}>
                {
                    deletingRow === item.uuid
                        ? <SpinLoading />
                        : <DeleteOutlined style={{ color: "red", fontSize: "20px" }} />
                }
            </div>
        },

    ];

    return (
        <div style={{ backgroundColor: "#f2f6f9", padding: "10px" }}>
            {data && data.length > 0 ? (
                data.map((item, index) => (
                    <Table
                        key={index}
                        columns={columns}
                        dataSource={transformDataForTable(item)}
                        pagination={false}
                        bordered
                        size="small"
                        style={{ marginBottom: "25px", borderRadius: "5px" }}
                        showHeader={false}
                    />
                ))
            ) : (
                <Empty description="No data available" />
            )}
        </div>

    );
};

const ManageFreelancer = ({ permissions, orderData, fetchOrderDetails }) => {
    const { orderKey } = useParams();
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(true);
    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
    const [deletingRow, setDeletingRow] = useState(null);
    const [assignFreelancer, setShowAssign] = useState(false);
    const [formSubmit, setFormSubmit] = useState(false);

    const fetchFreelancerList = async () => {
        setLoading(true);
        try {
            const res = await axiosInstance.get(`task/v2/task-fl-subscribers/${orderKey}/`);
            const data = res?.data?.data || [];
            setDataSource(data);
        } catch (err) {
            console.error("Error fetching freelancer list", err);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchFreelancerList();
    }, [orderKey]);

    const handleDeleteButton = async (uuid) => {
        setDeletingRow(uuid);
        try {
            const res = await axiosInstance.post(`task/v2/task-fl-subscribers-delete/${orderKey}/`, {
                uuid: [uuid],
            });
            // console.log("resDelete", res)
            if (res?.data?.status === "success") {
                message.success("Freelancer deleted successfully");
                setDataSource((prev) => prev.filter((item) => item.uuid !== uuid));
                fetchOrderDetails()
            } else {
                message.error("Failed to delete freelancer");
            }
        } catch (err) {
            catchError(err);
            console.error("Error deleting freelancer:", err);
        } finally {
            setDeletingRow(null);
        }
    };

    const columns = [
        {
            title: 'Freelancer',
            dataIndex: 'fl_subscriber',
            key: 'fl_subscriber',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Amount Deduction',
            dataIndex: 'amount_deduction',
            key: 'amount_deduction',
        },
        {
            title: 'Currency',
            dataIndex: 'currency',
            key: 'currency',
        },
        {
            title: 'Deadline',
            dataIndex: 'deadline',
            key: 'deadline',
            render: (text) => {
                return text ? moment(text).local().format("DD-MM-YYYY hh:mm A") : 'N/A'
            },
        },
        ...(permissions.includes("can_view_order_action_delete_fl_subscriber") ? [{
            title: "Delete",
            dataIndex: 'Delete',
            key: "Delete",
            render: (_, record) => (
                <Button onClick={() => handleDeleteButton(record.uuid)} type="danger">
                    {deletingRow === record.uuid ? <SpinLoading /> : <DeleteOutlined style={{ color: "red", fontSize: "20px" }} />}
                </Button>
            ),
        }] : []),
    ];


    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
                <Title level={isMobile ? 5 : 4} style={{ marginTop: 0, textAlign: 'left' }}>
                    TASK FREELANCER LIST - {orderKey}
                </Title>
                {
                    permissions.includes('can_view_order_action_assign_freelancer') && (
                        <div
                            onClick={() => setShowAssign(!assignFreelancer)}
                            style={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                gap: "5px",
                                cursor: "pointer",
                            }}
                        >
                            {!assignFreelancer && (
                                <>
                                    <PlusOutlined style={{ color: "black", fontSize: "20px" }} />
                                    <div>Assign Freelancer</div>
                                </>
                            )}
                        </div>
                    )
                }
            </div>
            {loading ? (
                <SpinLoading />
            ) : (
                <>
                    {assignFreelancer && (
                        <MyForm
                            orderData={orderData}
                            setShowAssign={setShowAssign}
                            setFormSubmit={setFormSubmit}
                            fetchFreelancerList={fetchFreelancerList}
                            fetchOrderDetails={fetchOrderDetails}
                        />
                    )}

                    {isMobile ? (
                        <MobileViewComponent data={dataSource} onDelete={handleDeleteButton} />
                    ) : (
                        <Table dataSource={dataSource} columns={columns} pagination={false} rowKey="id" />
                    )}
                </>
            )}
        </div>
    );
};

export default ManageFreelancer;

