import React, { useState, useEffect } from "react";
import {
  Table,
  Tabs,
  Tag,
  Typography,
  Space,
  Popover,
  Flex,
  Spin,
  Avatar,
  Tooltip,
} from "antd";
import moment from "moment";
import MobileViewComponent from "./MobileViewComponent";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import orderService from "../../services/OrderLists";
import "./tasks.css";
import { CloudHail } from "lucide-react";

const { TabPane } = Tabs;
const { Text } = Typography;

const OrderListTable = ({
  orders,
  loading,
  pagination,
  onChange,
  setUrgencyFilter,
  loadOrders, // Correctly passing loadOrders here
  setPagination,
}) => {
 
  const [freelancerData, setFreelancerData] = useState(null);
  const [columns, setColumns] = useState([]);
  const [flLoading, setFlLoading] = useState(false);

  const handleTabChange = (key) => setUrgencyFilter(key);

  useEffect(() => {
    const renderSubscribers = (subscribers) => {
      if (!subscribers || subscribers.length === 0) return "N/A";

      const displayedSubscribers = subscribers.slice(0, 2);
      const remainingSubscribers = subscribers.slice(2);

      return (
        <Avatar.Group maxCount={3}>
          {displayedSubscribers.map((name, index) => (
            <Tooltip key={index} title={name}>
              <Avatar style={{ backgroundColor: "#1890ff" }}>
                {name.charAt(0).toUpperCase()}
              </Avatar>
            </Tooltip>
          ))}
          {remainingSubscribers.length > 0 && (
            <Popover
              content={
                <div>
                  {remainingSubscribers.map((name, index) => (
                    <p key={index}>{name}</p>
                  ))}
                </div>
              }
              title="Additional Subscribers">
              <Avatar style={{ backgroundColor: "#f56a00" }}>
                +{remainingSubscribers.length}
              </Avatar>
            </Popover>
          )}
        </Avatar.Group>
      );
    };

    if (orders.length > 0) {
      const dynamicColumns = Object.keys(orders[0])
        .map((key) => {
          const dynamicTitle = key
            .replace(/_/g, " ")
            .replace(/\b\w/g, (letter) => letter.toUpperCase()); // Dynamically creating title

          switch (key) {
            case "alloted_deadline":
              return {
                title: dynamicTitle,
                dataIndex: "alloted_deadline",
                key: "alloted_deadlinet",
                render: (text) => (
                  <Text>
                    {moment(text).local().format("DD-MM-YYYY hh:mm A")}
                  </Text>
                ),
              };
            case "key":
              return {
                title: dynamicTitle,
                dataIndex: "key",
                key: "key",
                fixed: "left",
                width: 150,
                render: (text, record) => (
                  <Link to={`/order-management/my-orders/${record.key}`}>
                    <Text style={{ color: "black", fontWeight: 600 }}>
                      {text}
                    </Text>
                  </Link>
                ),
              };

            case "title":
              return {
                title: dynamicTitle,
                dataIndex: "title",
                key: "title",
                width: 200,
                render: (text) => (
                  <Text ellipsis={{ tooltip: text }} style={{ maxWidth: 150 }}>
                    {text}
                  </Text>
                ),
              };

            case "start_date":
              return {
                title: dynamicTitle,
                dataIndex: "start_date",
                key: "start_date",
                render: (text) => (
                  <Text>
                    {moment(text).local().format("DD-MM-YYYY hh:mm A")}
                  </Text>
                ),
              };
            case "client_deadline":
              return {
                title: dynamicTitle,
                dataIndex: "client_deadline",
                key: "client_deadline",
                render: (text) => (
                  <Text>
                    {moment(text).local().format("DD-MM-YYYY hh:mm A")}
                  </Text>
                ),
              };
            case "tags":
              return null; // Skip rendering "tags" column by returning null here
            case "payment":
              return [
                {
                  title: dynamicTitle,
                  dataIndex: "budget",
                  key: "budget",
                  width: 180,
                  render: (_, record) => {
                    const payment = record?.payment;

                    if (!payment) return "N/A";

                    const { budget, amount_paid, currency, payment_status } =
                      payment;

                    const statusDotColor = {
                      Full: "green",
                      Partial: "yellow",
                      Unpaid: "red",
                    };

                    return (
                      <Space direction="vertical" size={0}>
                        <Text strong>
                          {!budget ? "N/A" : `${currency} ${budget}`}
                        </Text>
                        <Space>
                          <span
                            style={{
                              display: "inline-block",
                              width: "8px",
                              height: "8px",
                              borderRadius: "50%",
                              backgroundColor:
                                statusDotColor[payment_status] || "gray",
                            }}></span>
                          <Text
                            type={
                              payment_status === "Full"
                                ? "success"
                                : payment_status === "Partial"
                                ? "warning"
                                : "danger"
                            }>
                            {payment_status === "Full"
                              ? "Paid"
                              : payment_status === "Partial"
                              ? "Partial"
                              : "Unpaid"}
                          </Text>
                        </Space>
                      </Space>
                    );
                  },
                },
                {
                  title: "Amount Paid",
                  dataIndex: "payment",
                  key: "amount_paid",
                  width: 180,
                  render: (_, record) => {
                    const { amount_paid, currency } = record?.payment || {};
                    return (
                      <Text>
                        {amount_paid ? `${currency} ${amount_paid}` : "N/A"}
                      </Text>
                    );
                  },
                },
              ];

            case "moved_to_fl":
              return {
                title: dynamicTitle,
                dataIndex: "moved_to_fl",
                key: "moved_to_fl",
                width: 150,
                render: (_, record) => {
                  const fl = record?.moved_to_fl ? "Yes" : "No";

                  const handlePopoverOpen = async () => {
                    try {
                      setFlLoading(true);
                      if (record?.moved_to_fl_team && !freelancerData) {
                        const response = await orderService.getFreelancerDetail(
                          record.key
                        );
                        if (response) {
                          setFreelancerData(response?.data?.data);
                        }
                      }
                    } catch (error) {
                      setFlLoading(false);
                      console.error(
                        "Error fetching freelancer details:",
                        error
                      );
                    } finally {
                      setFlLoading(false);
                    }
                  };

                  // Define columns for Ant Design Table
                  const columns = [
                    {
                      title: "Key",
                      dataIndex: "key",
                      key: "key",
                      width: 120,
                      render: (text) => <strong>{text}</strong>,
                    },
                    {
                      title: "Value",
                      dataIndex: "value",
                      key: "value",
                      render: (text) => <span>{text}</span>,
                    },
                  ];

                  // Transforming data to match table structure
                  const tableData = freelancerData
                    ? Object.entries(freelancerData).flatMap(
                        ([key, value], index) => {
                          if (Array.isArray(value)) {
                            // Handle nested arrays (like budget or subscribers)
                            if (
                              value.length > 0 &&
                              typeof value[0] === "object"
                            ) {
                              return value.flatMap((item, subIndex) =>
                                Object.entries(item).map(
                                  ([subKey, subValue], idx) => ({
                                    key: `${key}-${subIndex}-${idx}`,
                                    keyLabel: `${subKey}`,
                                    value: String(subValue),
                                  })
                                )
                              );
                            }
                            return [
                              {
                                key: index,
                                keyLabel: key,
                                value: value.join(", "),
                              },
                            ];
                          }
                          return [
                            {
                              key: index,
                              keyLabel: key,
                              value: String(value),
                            },
                          ];
                        }
                      )
                    : [];

                  // Popover content
                  const content = flLoading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      <Spin size="large" />
                    </div>
                  ) : (
                    <Table
                      columns={columns}
                      dataSource={tableData}
                      pagination={false}
                      size="small"
                      bordered
                      style={{ width: 300 }}
                    />
                  );

                  return record?.moved_to_freelancer_portal ? (
                    <Popover
                      content={content}
                      title="Freelancer Details"
                      trigger="hover"
                      // onVisibleChange={handlePopoverOpen}
                    >
                      <Text>{fl}</Text>
                    </Popover>
                  ) : (
                    <Text>{fl}</Text>
                  );
                },
              };
            case "moved_to_fl_team":
              return {
                title: dynamicTitle,
                dataIndex: "moved_to_fl_team",
                key: "moved_to_fl_team",
                render: (text, record) => {
                  // Check the value and return "Yes" or "No"
                  const movedToTeam = record?.moved_to_fl_team ? "Yes" : "No";
                  return <Text>{movedToTeam}</Text>;
                },
              };
            case "is_executive":
              return {
                title: dynamicTitle,
                dataIndex: "is_executive",
                key: "is_executive",
                render: (text, record) => {
                  // Check the value and return "Yes" or "No"
                  const movedToTeam = record?.is_executive ? "Yes" : "No";
                  return <Text>{movedToTeam}</Text>;
                },
              };
            case "subscribers":
              return {
                title: dynamicTitle,
                dataIndex: "subscribers",
                key: "subscribers",
                width: 200,
                render: (subscribers) => renderSubscribers(subscribers),
              };
            case "status":
              return null;

            default:
              return {
                title: key
                  .replace(/_/g, " ")
                  .replace(/\b\w/g, (letter) => letter.toUpperCase()),
                dataIndex: key,
                key,
                render: (text) => <Text>{text || "N/A"}</Text>,
              };
          }
        })
        .flat()
        .filter((column) => column);
      // Reorder columns: move 'alloted_deadline' to immediately after 'key'
      const keyColumnIndex = dynamicColumns.findIndex(
        (column) => column.dataIndex === "key"
      );
      const allotedDeadlineColumnIndex = dynamicColumns.findIndex(
        (column) => column.dataIndex === "alloted_deadline"
      );

      if (keyColumnIndex !== -1 && allotedDeadlineColumnIndex !== -1) {
        const [allotedDeadlineColumn] = dynamicColumns.splice(
          allotedDeadlineColumnIndex,
          1
        );
        dynamicColumns.splice(keyColumnIndex + 2, 0, allotedDeadlineColumn);
      }

      dynamicColumns.push({
        title: "Status",
        dataIndex: "status",
        key: "status",
        fixed: "right",
        width: 150,
        sorter: (a, b) => {
          if (a.status > b.status) return 1;
          if (a.status < b.status) return -1;
          return 0;
        },
        sortDirections: ["ascend", "descend"],
        render: (text) => {
          const statusColors = {
            "In Progress": "orange",
            Cancelled: "volcano",
            Completed: "green",
            Unpaid: "red",
          };
          return <Tag color={statusColors[text] || "default"}>{text}</Tag>;
        },
      });

      // Set the updated columns
      setColumns(dynamicColumns);
    }
  }, [orders]);

  const tabConfig = [
    { key: "all", label: "All Orders", color: "blue", bgColor: "grey" },
    { key: "red", label: "Deadline Missed", color: "red", bgColor: "#FFCCCB" },
    {
      key: "orange",
      label: "Deadline > 1 Day",
      color: "orange",
      bgColor: "#FFE5B4",
    },
    {
      key: "yellow",
      label: "Deadline in 2-4 Days",
      color: "#FACA15",
      bgColor: "#FFF9C4",
    },
    {
      key: "green",
      label: "Deadline > 4 Days",
      color: "green",
      bgColor: "#C8E6C9",
    },
  ];


  return (
    <div style={{ width: "100%" }}>
      <Tabs onChange={handleTabChange} defaultActiveKey="all">
        {tabConfig.map((tab) => (
          <TabPane
            tab={
              <span
                style={{
                  color: "black",
                  padding: "8px 16px",
                  borderRadius: "8px 8px 0 0",
                  backgroundColor: tab.bgColor,
                }}>
                {tab.label}
              </span>
            }
            key={tab.key}>
            <Flex vertical align="flex-end" gap={10} style={{ width: "100%" }}>
              {!loading && (
                <p style={{ margin: 0, padding: 0 }}>
                  {" "}
                  Showing{" "}
                  {Math.min(
                    (pagination.current - 1) * pagination.pageSize + 1,
                    pagination.total
                  )}{" "}
                  -
                  {Math.min(
                    pagination.current * pagination.pageSize,
                    pagination.total
                  )}{" "}
                  of {pagination.total} Orders.
                </p>
              )}

              <Table
                style={{ width: "100%" }}
                columns={columns}
                dataSource={orders}
                loading={loading}
                pagination={pagination}
                onChange={onChange}
                scroll={{ x: "max-content" }}
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "even-row" : "odd-row"
                }
              />
            </Flex>
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default OrderListTable;
