import React, { useState, useEffect } from "react";
import { Modal, Table, Button, Typography, message } from "antd";
import orderService from "../../services/OrderLists";
import { useParams } from "react-router-dom";

const { Title } = Typography;

const RemoveFeedbackFiles = ({ isVisible, setIsModalVisible, feedbackKey ,getFeedBack}) => {
  const { orderKey } = useParams();
  const [feedBackFiles, setFeedbackFiles] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!feedbackKey) return;

    const fetchFeedbackFiles = async () => {
      try {
        setIsLoading(true);
        const response = await orderService.getFeedbackFiles(feedbackKey);
        if (response.success) {
          setIsLoading(false);

          setFeedbackFiles(response?.data?.data || []);
        } else {
          setIsLoading(false);

          message.error("Failed to fetch feedback files.");
        }
      } catch (error) {
        setIsLoading(false);

        console.error("Error fetching feedback files:", error);
        message.error("An error occurred while fetching files.");
      }
    };

    fetchFeedbackFiles();
  }, [feedbackKey]);

  const handleRowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  const handleSubmit = async () => {
    if (selectedRowKeys.length === 0) {
      message.warning("Please select at least one file.");
      return;
    }

    try {
      setIsLoading(true);

      const payload = {
        files_uid: selectedRowKeys, // Send selectedRowKeys directly as a list
      };

      const response = await orderService.removeFeedbackFiles(
        feedbackKey,
        payload
      );

      if (response?.success) {
        message.success("Files removed successfully.");
        await getFeedBack("4")
        setIsModalVisible();
      } else {
        message.error("Failed to remove files.");
      }
    } catch (error) {
      console.error("Error removing feedback files:", error);
      message.error("An error occurred while removing files.");
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      title: "File Name",
      dataIndex: "file_name",
      key: "file_name",
    },
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      render: (date) => new Date(date).toLocaleString(),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <a href={record.file_url} target="_blank" rel="noopener noreferrer">
          Download
        </a>
      ),
    },
  ];

  return (
    <Modal
      width={800}
      className="custom-modal2"
      title={
        <div className="custom-modal-header" >
          <Title level={3} style={{ color: "white", margin: 0  , padding:'0px 18px'}}>
             Remove Feedback Files
          </Title>
        </div>
      }
      visible={isVisible}
      onCancel={() => setIsModalVisible(false)}
      footer={
        <Button
          loading={isLoading}
          style={{ color: "white" }}
          type="primary"
          onClick={handleSubmit}
          disabled={selectedRowKeys.length === 0}>
          Submit
        </Button>
      }>
      <Table
        rowKey="uid"
        rowSelection={handleRowSelection}
        columns={columns}
        dataSource={feedBackFiles}
        loading={isLoading}
      />
    </Modal>
  );
};

export default RemoveFeedbackFiles;
