import React, { useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  Checkbox,
  DatePicker,
  Descriptions,
  Popover,
  Flex,
  Tag,
  Card,
  Alert,
  Table,
} from "antd";
import dayjs from "dayjs";
import { InfoCircleFilled } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
const ComparisonAnalytics = ({ data }) => {
  const { monthwise = {}, yearwise = {} } = data?.comparision_analytics || {};

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const [showYearwise, setShowYearwise] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [showInrValues, setShowInrValues] = useState(false);
  const [showOrderAnalytics, setShowOrderAnalytics] = useState(false);

  const handleCheckboxChange = (e) => {
    setShowOrderAnalytics(e.target.checked);
  };
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length > 0) {
      const currencyData = payload[0].payload.currencywiseAmounts;
// console.log(payload[0].payload.order_analytics.task_count , 'pay')
const {task_count,word_count} = payload[0].payload.order_analytics
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            background: "#f3f4f6",
            minWidth: "210px",
            backdropFilter: "10px",
            padding: "10px",
            borderRadius: "10px",
            border: " 1px solid #9ca3af",
            gap: "10px",
          }}>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
              }}>
              <p style={{ margin: 0 }}>Total Task</p>
              <strong>{task_count}</strong>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                justifyContent: "flex-end",
                textAlign: "right",
              }}>
              <p style={{ margin: 0 }}>Word Count</p>
              <strong>{word_count}</strong>
            </div>
          </div>

          <div
            style={{
              backdropFilter: 10,
              background: "white",
              border: " 1px solid #9ca3af",
              padding: "10px",
              borderRadius: "10px",
            }}>
            {showInrValues ? (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}>
                <p style={{ margin: 0, color: "gray" }}>INR</p>
                <strong>{payload[0].payload.totalInrAmount}</strong>
              </div>
            ) : (
              Object.entries(currencyData).map(([currency, amount]) => (
                <div
                  key={currency}
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}>
                  <p style={{ margin: 0, color: "gray" }}>{currency}</p>
                  <strong>{amount}</strong>
                </div>
              ))
            )}
          </div>
        </div>
      );
    }

    return null; // Return null if active is false
  };
  const monthwiseData = Object.entries(monthwise).map(([month, values]) => {
    const totalInrAmount = values.total_inr_amount || 0;
    const currencywiseAmounts = (values.currencywise_amount || []).reduce(
      (acc, item) => {
        acc[item.currency] = item.amount;
        return acc;
      },
      {}
    );
    const orderAnalytics = values.order_analytics || {};
    return {
      name: dayjs(month, "YYYY-MM").format("MMM"),
      totalInrAmount,
      currencywiseAmounts,
      order_analytics: orderAnalytics,
    };
  });

  const yearwiseData = Object.entries(yearwise).map(([month, values]) => {
    const totalInrAmount = values.total_inr_amount;
    const currencywiseAmounts = values.currencywise_amount.reduce(
      (acc, item) => {
        acc[item.currency] = item.amount;
        return acc;
      },
      {}
    );
    const orderAnalytics = values.order_analytics || {};
    return {
      name: month,
      totalInrAmount,
      currencywiseAmounts,
      order_analytics: orderAnalytics,
    };
  });
  // Filter data by date range
  const filteredData = showYearwise ? yearwiseData : monthwiseData;
  const filteredChartData = filteredData.filter((data) => {
    const dataDate = showYearwise ? data.name : dayjs(data.name, "MMMM");
    return (
      (!dateRange[0] || dataDate.isAfter(dateRange[0], "day")) &&
      (!dateRange[1] || dataDate.isBefore(dateRange[1], "day"))
    );
  });

  const isDataAvailable = filteredChartData.length > 0;
  const OrderAnalyticsTable = ({ data }) => {
    console.log(data, 12); // Log data to ensure correctness

    // Adjusting to map the correct data structure
    const monthwiseData = data.map((item) => ({
      month: item.name || "N/A", // Use 'name' as 'month' or default to 'N/A'
      amount_paid: item.order_analytics?.amount_paid || [],
      order_budget: item.order_analytics?.order_budget || [],
      task_count: item.order_analytics?.task_count || 0,
      word_count: item.order_analytics?.word_count || 0,
    }));

    if (isMobile) {
      return (
        <div style={{minHeight:'300px' , maxHeight:'300px' ,overflowY:'auto'}}>
          {monthwiseData.map((item, index) => (
            <Descriptions
              key={index}
              bordered
              column={1}
              title={`Month: ${item.month}`}
              style={{ marginBottom: 16 }}>
              <Descriptions.Item label="Amount Paid">
                {item.amount_paid.length > 0 ? (
                  item.amount_paid.map((payment, idx) => (
                    <Tag key={idx}>
                      {payment.currency}: {new Intl.NumberFormat("en-IN").format(payment.amount)}
                    </Tag>
                  ))
                ) : (
                  <span>-</span>
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Order Budget">
                {item.order_budget.length > 0 ? (
                  item.order_budget.map((budget, idx) => (
                    <Tag key={idx}>
                      {budget.currency}: {new Intl.NumberFormat("en-IN").format(budget.amount)}
                    </Tag>
                  ))
                ) : (
                  <span>-</span>
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Task Count">{item.task_count}</Descriptions.Item>
              <Descriptions.Item label="Word Count">{item.word_count}</Descriptions.Item>
            </Descriptions>
          ))}
        </div>
      );
    }

    const columns = [
      {
        title: "Month",
        dataIndex: "month",
        key: "month",
        width:'80px',
        render: (month, record) => <span>{month}</span>,

      },
      {
        title: "Amount Paid",
        dataIndex: "amount_paid",
        key: "amount_paid",
        width:'130px',
        render: (amount) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              maxHeight: "150px", // Adjust the max height as needed
              overflowY: "auto", // Enables scrolling
              overflowX: "hidden", // Enables scrolling
            }}>
            {Array.isArray(amount) && amount.length > 0 ? (
              amount.map((item, idx) => (
                <Tag key={idx} style={{ minWidth: 'max-content' }}>
                  {item.currency}:{" "}
                  {new Intl.NumberFormat("en-IN").format(item.amount)}
                </Tag>
              ))
            ) : (
              <span>-</span>
            )}
          </div>
        ),
      },
      {
        title: "Order Budget",
        dataIndex: "order_budget",
        key: "order_budget",
        width:'130px',
        render: (budget) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              maxHeight: "150px", // Adjust the max height as needed
              overflowY: "auto", // Enables scrolling
              overflowX: "hidden", // Enables scrolling
            }}>
            {Array.isArray(budget) && budget.length > 0 ? (
              budget.map((item, idx) => (
                <Tag key={idx} style={{ minWidth: 'max-content' }}>
                  {item.currency}:{" "}
                  {new Intl.NumberFormat("en-IN").format(item.amount)}
                </Tag>
              ))
            ) : (
              <span>-</span>
            )}
          </div>
        ),
      },
      {
        title: "Order Count",
        dataIndex: "task_count",
        key: "task_count",
        render: (taskCount) => <span>{taskCount}</span>,
      },
      {
        title: "Word Count",
        dataIndex: "word_count",
        key: "word_count",
        render: (wordCount) => <span>{wordCount}</span>,
      },
    ];

    return <Table scroll={{y:200}} dataSource={monthwiseData} columns={columns} rowKey="month"  pagination={false} style={{marginTop:'10px'}}/>;
  };


  return (
    <Flex
      vertical
      justify="flex-end"
      align="flex-end"
      style={{ width: "100%" }}>
      <Flex gap={10} align={isMobile? 'flex-end' : 'center'} style={{flexDirection : isMobile ?'column' : 'row'}}>
        <Checkbox
          checked={showYearwise}
          onChange={(e) => setShowYearwise(e.target.checked)}>
          Show Yearwise
        </Checkbox>
        <Checkbox
          checked={showInrValues}
          onChange={(e) => {
            setShowInrValues(e.target.checked);
            setShowOrderAnalytics(false); // Use lowercase `false`
          }}>
          Show INR Values
        </Checkbox>

        <Checkbox
          checked={showOrderAnalytics}
          onChange={(e) => setShowOrderAnalytics(e.target.checked)}>
          Show Order Analytics
        </Checkbox>
      </Flex>

      {isDataAvailable ? (
        <>
          {showOrderAnalytics ? (
            // Render Order Analytics Table when `showOrderAnalytics` is true

            <OrderAnalyticsTable
              data={filteredChartData}
              showYearwise={showYearwise}
            />
          ) : (
            // Render AreaChart (Graph) when `showOrderAnalytics` is false
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "column",
              }}>
              <ResponsiveContainer
                width="100%"
                height={300}
                style={{ marginTop: "8px", marginRight: 20 }}>
                <AreaChart data={filteredChartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" markerWidth={20} />
                  <YAxis tick={false} />

                  <Tooltip content={<CustomTooltip />} />

           
                  {/* Rendering only INR */}
                  <Area
                    type="monotone"
                    dataKey="totalInrAmount"
                    stroke="#82ca9d"
                    fill="#36c6d3"
                    name="Total INR"
                  />
                </AreaChart>
                {showInrValues && (
                  <Area
                    type="monotone"
                    dataKey="totalInrAmount"
                    stroke="#82ca9d"
                    fill="#36c6d3"
                    name="Total INR"
                  />
                )}
              </ResponsiveContainer>
              <p
                style={{
                  marginTop: "10px",
                  fontSize: "12px",
                  color: "#666",
                  textAlign: "right",
                }}>
                <InfoCircleFilled style={{ marginRight: 2 }} />
                {showYearwise
                  ? "This graph shows data for the last 3 years."
                  : "This graph shows data for the last 3 months."}
                {showInrValues && " Currently displaying INR values."}
              </p>
            </div>
          )}
        </>
      ) : (
        <p>No data available for the selected view.</p>
      )}
    </Flex>
  );
};

export default ComparisonAnalytics;
