import React from "react";
import { Table, Collapse, Button, Modal, Card, Descriptions ,Typography, Empty} from "antd";
import moment from "moment";
const SubscriberInfoPanel = ({
  subscriberInfo,
  isModalVisible,
  handleViewMore,
  handleModalClose,
}) => {
  const { Panel } = Collapse;

  const subscriberColumns = [
    {
      title: "User Type",
      dataIndex: "user_type",
      key: "user_type",
      width: !isModalVisible ? 130 :'',
    },
    {
      title: "Word Count",
      dataIndex: "no_of_word",
      key: "no_of_word",
      // width: 80,
    },
    {
      title: "Deadline",
      dataIndex: "deadline",
      key: "deadline",
      render: (text) => (
        <div>
          {text && (
            <div>
                 {moment(text).local().format("DD-MM-YYYY hh:mm A")}
             </div>
          )}
        </div>
      ),
    },
  ];
  const subscriberArray =
    subscriberInfo?.data?.map((item, index) => ({
      ...item,
      key: index, // Ensure each row has a unique key
    })) || [];
  const visibleSubscriberInfo = subscriberArray
  const hiddenSubscriberInfo = subscriberArray.slice(4);

  const renderCard = (subscriber) => (
    <Card
      key={subscriber?.key}
      style={{
        margin: 0,
        padding: 0,
        marginBottom: 10,
      }}
      bodyStyle={{ padding: 0,border:"none" }}
    >
      <Descriptions
        style={{ margin: 0, padding: 0 }}
        size="small"
        column={1}
        bordered
      >
        <Descriptions.Item
          label="Subscriber"
          labelStyle={{ background: "none", color: "#222", fontWeight: 600 }}
        >
          <Typography.Text>{subscriber?.subscriber}</Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item
          label="Word Count"
          labelStyle={{ background: "none", color: "#222", fontWeight: 600 }}
        >
          <Typography.Text>{subscriber.no_of_word}</Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item
          label="Deadline"
          labelStyle={{ background: "none", color: "#222", fontWeight: 600 }}
        >
          <Typography.Text>
            {subscriber.deadline
              ? moment(subscriber.deadline).local().format("DD-MM-YYYY hh:mm A")
              : "N/A"}
          </Typography.Text>
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
  

  return (
    <>
      {/* Display first 4 subscriber cards */}
      {visibleSubscriberInfo?.length>0 ? 
      <div style={{maxHeight:"280px", overflowY:"auto"}}>
        {visibleSubscriberInfo?.map(renderCard)}
      </div> 
     : <Empty description="No data available" />}
       
      {/* "View More" button if there are hidden subscribers */}
      {/* {hiddenSubscriberInfo.length > 0 && (
        <Button type="link" onClick={handleViewMore}>
          View More
        </Button>
      )} */}

      <Modal
        title="Subscriber Information"
        visible={isModalVisible}
        onCancel={handleModalClose}
        width={800}
        footer={[
          <Button key="close" onClick={handleModalClose}>
            Close
          </Button>,
        ]}
        >
        <Table
          columns={subscriberColumns}
          dataSource={subscriberArray}
          pagination={false}
          bordered
          size="small"
          footer={null}
          scroll={{ y: 350 }}
        />
      </Modal>
    </>
  );
};

export default SubscriberInfoPanel;
