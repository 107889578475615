// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  background: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.anticon-close {
  color: white !important;
}

.ant-menu {
  background-color: #040724 !important;
  color: whitesmoke !important;
}

.ant-menu-item-icon {
  color: whitesmoke !important;
}
.ant-menu-title-content {
  color: whitesmoke !important;
  font-weight: 600 !important;
}

.ant-menu-item-selected {
  background-color: #36c6d3 !important;
  font-weight: 600 !important;
}
.ant-menu-submenu-arrow {
  color: #fefefe !important;
}

.ant-table-cell-row-hover {
  background: #eceff1 !important;
}

.ant-menu.ant-menu-inline-collapsed {
  max-width: 100px !important;
}

@media (max-width: 700px) {
  .ant-layout-sider {
    display: none !important; /* Make sure it's hidden */
  }
  .header_btn {
    display: none !important;
  }
  .mobile_toggle {
    display: block !important; /* Show mobile toggle icon */
  }
  .ant-drawer .ant-drawer-content {
    background-color: #040724 !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,iBAAiB;EACjB;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;AACA;EACE,uBAAuB;AACzB;;AAEA;EACE,oCAAoC;EACpC,4BAA4B;AAC9B;;AAEA;EACE,4BAA4B;AAC9B;AACA;EACE,4BAA4B;EAC5B,2BAA2B;AAC7B;;AAEA;EACE,oCAAoC;EACpC,2BAA2B;AAC7B;AACA;EACE,yBAAyB;AAC3B;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE;IACE,wBAAwB,EAAE,0BAA0B;EACtD;EACA;IACE,wBAAwB;EAC1B;EACA;IACE,yBAAyB,EAAE,4BAA4B;EACzD;EACA;IACE,oCAAoC;EACtC;AACF","sourcesContent":["body {\n  margin: 0;\n  background: white;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n.anticon-close {\n  color: white !important;\n}\n\n.ant-menu {\n  background-color: #040724 !important;\n  color: whitesmoke !important;\n}\n\n.ant-menu-item-icon {\n  color: whitesmoke !important;\n}\n.ant-menu-title-content {\n  color: whitesmoke !important;\n  font-weight: 600 !important;\n}\n\n.ant-menu-item-selected {\n  background-color: #36c6d3 !important;\n  font-weight: 600 !important;\n}\n.ant-menu-submenu-arrow {\n  color: #fefefe !important;\n}\n\n.ant-table-cell-row-hover {\n  background: #eceff1 !important;\n}\n\n.ant-menu.ant-menu-inline-collapsed {\n  max-width: 100px !important;\n}\n\n@media (max-width: 700px) {\n  .ant-layout-sider {\n    display: none !important; /* Make sure it's hidden */\n  }\n  .header_btn {\n    display: none !important;\n  }\n  .mobile_toggle {\n    display: block !important; /* Show mobile toggle icon */\n  }\n  .ant-drawer .ant-drawer-content {\n    background-color: #040724 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
