import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Tabs,
  Select,
  Input,
  Button,
  Table,
  Flex,
  message,
  Dropdown,
  Menu,
  Tooltip,
  DatePicker,
  Skeleton,
  Modal,
  Typography,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  FacebookFilled,
  FacebookOutlined,
  LinkedinFilled,
  MailOutlined,
  PhoneOutlined,
  WhatsAppOutlined,
  TwitterCircleFilled,
  InstagramFilled,
} from "@ant-design/icons";
import usePermissions from "../../hooks/usePermissions";
import { useMediaQuery } from "react-responsive";
import LeadsList from "../../services/LeadsList";
import orderService from "../../services/OrderLists";
import CallModal from "./CallModal";
import "./Table.css";
const { TabPane } = Tabs;
const { Option } = Select;

const ManageLeadContent = ({ record, fetchData, leadType }) => {
  const [currentConnection, setCurrentConnection] = useState(null);
  const [twilioNumbers, setTwilioNumbers] = useState([]);
  const [callDuration, setCallDuration] = useState(0);
  const [isCallModalVisible, setIsCallModalVisible] = useState(false);
  const [isOutcomeModalVisible, setIsOutcomeModalVisible] = useState(false);
  const { hasPermission } = usePermissions();
  const [callStatus, setCallStatus] = useState(""); // New state for call status
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
const [disableHangUp , setDisableHangUp] = useState(false)
  const [activeTab, setActiveTab] = useState("Call");
  const [outcomeOptions, setOutcomeOptions] = useState({});
  const [statusOptions, setStatusOptions] = useState([]);
  const [outcomeLogs, setOutcomeLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [savingOutcome, setSavingOutcome] = useState(false);
  const [activityLogs, setActivityLogs] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });

  const { Title } = Typography;

  const [commonFormValues, setCommonFormValues] = useState({
    outcome: undefined,
    next_activity: null,
    status: record.status || "",
    remarks: "",
  });

  const [modalFormValues, setModalFormValues] = useState({
    outcome: undefined,
    next_activity: null,
    status:"",
    remarks: "",
    call_sid: null,
  });
  const [actionsData, setActionsData] = useState({});

  const intervalRef = useRef(null);

  
  // Fetch actions data when component loads
  const getActionsData = async () => {
    try {
      const response = await LeadsList.getActionsData(record.uid);

      if (response.success) {
        setActionsData(response?.data?.data); // Store the actions data
      }
    } catch (error) {
      console.error("Failed to fetch actions data:", error);
    }
  };

  const handleSelectNumber = async ({ key }) => {
    console.log(record.uid, "key");

    await handleButtonClick(record.uid, "call");
    const selectedNumber = twilioNumbers.find((item) => item.phone_no === key);
    if (selectedNumber) {
      await handleCall(record.key, selectedNumber.uid);
    } else {
      message.error("Failed to find the selected number");
    }
  };

  const menu = (
    <Menu onClick={handleSelectNumber}>
      {twilioNumbers.map(({ phone_no }) => (
        <Menu.Item key={phone_no}>{phone_no}</Menu.Item>
      ))}
    </Menu>
  );

  const handleCall = useCallback(async (lead_uid, uid, type) => {
    try {
      setDisableHangUp(true)
      setIsCallModalVisible(true);
      const callType = "NewLead";
      console.log("Initiating Twilio call...");
      const connection = await LeadsList.makeTwilioCall(
        lead_uid,
        uid,
        callType
      );

      if (connection) {
        setDisableHangUp(false)

        console.log(
          "Call initiated successfully",
          connection.parameters.CallSid
        );
        setCurrentConnection(connection);

        connection.on("statusChange", (status) => {
          console.log(`Call status changed to: ${status}`);
          setCallStatus(status); // Update call status

          if (status === "ringing") {
            const timeoutId = setTimeout(() => {
              setCallStatus("Ringing"); // Reset call status on error

              if (connection.status === "ringing") {
                console.log("Call not answered in time, hanging up...");
                handleHangUp();
              }
            }, 30000);
            // Store the timeout ID to clear it if needed
            connection.timeoutId = timeoutId;
          } else if (status === "open") {
            setCallStatus("Connected"); // Set status to Connected when call is answered

            console.log("Call answered, conversation started");
            startTimer();
            // Clear the ringing timeout if it exists
            if (connection.timeoutId) {
              clearTimeout(connection.timeoutId);
            }
          } else if (status === "closed" || status === "disconnected") {
            console.log("Call closed or disconnected");
            setCallStatus(""); // Reset call status on error

            handleHangUp();
          }
        });

        if (connection._onAnswer) {
          connection._onAnswer = (payload) => {
            setModalFormValues({
              call_sid: payload.callsid,
            });

            startTimer();
          };
        }

        connection.on("disconnect", () => {
          console.log("'disconnect' event fired");
          handleHangUp();
        });

        connection.on("error", (error) => {
          console.error("Twilio connection error:", error);
          // Instead of showing an error message, just log it and handle the hangup
          console.log("Call encountered an error, hanging up...");
          handleHangUp();
        });
        const checkStatus = setInterval(() => {
          const currentStatus = connection.status();
          console.log("Current call status:", currentStatus);
          setCallStatus(currentStatus);

          if (currentStatus === "closed") {
            clearInterval(checkStatus);
            handleHangUp();
          }
        }, 1000);
      } else {
       message.error("something went wrong")
      }
    } catch (error) {
      console.error("Error in handleCall:", error);
      setIsCallModalVisible(false);
      message.error(`Failed to initiate call`);
    }
  }, []);

  const startTimer = () => {
    console.log("Starting timer");
    intervalRef.current = setInterval(() => {
      setCallDuration((prev) => {
        const newDuration = prev + 1;
        console.log(`Call duration: ${newDuration} seconds`);
        return newDuration;
      });
    }, 1000);
  };

  const handleHangUp = useCallback(() => {
    console.log("Hanging up call");
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      console.log("Timer cleared");
    }
    setCallDuration(0);
    setIsCallModalVisible(false);
    setCallStatus(""); // Reset call status
    if (currentConnection) {
      currentConnection.disconnect();
      console.log("Connection disconnected");
    }
    // Show outcome modal after disconnecting
    setTimeout(() => setIsOutcomeModalVisible(true), 0);
  }, [currentConnection]);

  const fetchTwilioNumbers = async () => {
    const response = await orderService.getTwilioNumberAndUid();
    if (response.success) {
      setTwilioNumbers(response.data);
    }
  };

  useEffect(() => {
    getActionsData(record.uid);
  }, []);
  useEffect(() => {
    fetchOutcomeLogs(activeTab);
    fetchOutcomes();
    fetchStatusOptions();
    fetchTwilioNumbers();
    if (activeTab === "Activity Logs") {
      getActivityLogs(record.uid);
    }
  }, [activeTab ]);

  const fetchOutcomes = async () => {
    const response = await LeadsList.getOutcomesDropdownList();
    if (response.success) {
      setOutcomeOptions(response.data);
    } else {
      message.error("Failed to fetch outcomes.");
    }
  };

  const fetchStatusOptions = async () => {
    const response = await LeadsList.getStatusDropdownList();
    if (response.success) {
      setStatusOptions(response.data);
      console.log(response.data)
      // const matchedStatus = response.data.find((option) => option.value === record.status);
      if (record?.status) {
        const matchedStatus = response.data.find(
          (option) => option.label === record.status
        );
  
        if (matchedStatus) {
          // Set the default value in commonFormValues
          handleCommonFormChange("status", matchedStatus.value);
        } else {
          console.warn("No matching status found for:", record.status);
        }
      }
      // handleCommonFormChange("status", matchedStatus.value); 
    } else {

      message.error("Failed to fetch statuses.");
    }
  };
 // Set initial status based on record.status

  const fetchOutcomeLogs = async (tabKey) => {
    setLoading(true);
    try {
      const response = await LeadsList.getOutcomesLogs(record.uid, tabKey);
      if (response.success && response.data.status === "success") {
        setOutcomeLogs(response.data.data.results || []);
      } else {
        setOutcomeLogs([]);
      }
    } catch (error) {
      console.error("Failed to fetch outcome logs:", error);
      setOutcomeLogs([]);
    } finally {
      setLoading(false);
    }
  };

  const getActivityLogs = async (uid, page = 1) => {
    setLoading(true);
    try {
      const response = await LeadsList.getActivityLogs(uid, page);
      if (response.success) {
        setLoading(false);
        const data = response.data.data;
        setActivityLogs(data.results || []);
        setPagination({
          current: page,
          pageSize: 20,
          total: data.count,
        });
      }
    } catch (error) {
      setLoading(false);
      console.error("Failed to fetch activity logs:", error);
      setActivityLogs([]);
    } finally {
      setLoading(false);
    }
  };
  const handleTableChange = (pagination) => {
    getActivityLogs(record.uid, pagination.current);
  };

  const handleTabChange = (key) => {
    setCommonFormValues({
      outcome: undefined,
      next_activity: null,
      status: record.status || "",
      remarks: "",
    });
    setActiveTab(key);
  };

  const handleCommonFormChange = (field, value) => {
    setCommonFormValues((prev) => ({ ...prev, [field]: value }));
  };

  const handleModalFormChange = (field, value) => {
    setModalFormValues((prev) => ({ ...prev, [field]: value }));
  };

  const handleSaveCommonOutcome = async () => {
    setSavingOutcome(true);
    const outcomeData = {
      type: activeTab,
      ...commonFormValues,
    };

    try {
      const response = await LeadsList.submitOutcome(record.uid, outcomeData);
      if (response) {
        message.success("Outcome saved successfully");
        setCommonFormValues({
          outcome: undefined,
          next_activity: null,
     
          remarks: "",
        });
        await fetchOutcomeLogs(activeTab);
        await fetchData();
       
      } else {
        message.error("Failed to save outcome");
      }
    } catch (error) {
      message.error("Failed to save outcome");
      console.error("Failed to save outcome:", error);
    } finally {
      setSavingOutcome(false);
    }
  };
  const handleResetForm = () => {
    setCommonFormValues({
      next_activity: null,
      status: record.status || "",
      remarks: "",
      call_sid: null,
    });
  };
  const handleSaveModalOutcome = async () => {
    setSavingOutcome(true);
    const outcomeData = {
      type: activeTab,
      ...modalFormValues,
    };

    try {
      const response = await LeadsList.submitOutcome(record.uid, outcomeData);
      if (response) {
        message.success("Outcome saved successfully");
        setModalFormValues({
          outcome: undefined,
          next_activity: null,
          status: record.status || "",
          remarks: "",
          callSid: null,
        });

        await fetchData();
        await fetchOutcomeLogs("Call");

        setIsOutcomeModalVisible(false);
      } else {
        // message.error("Failed to save outcome");
      }
    } catch (error) {
      message.error("Failed to save outcome");
      console.error("Failed to save outcome:", error);
    } finally {
      setSavingOutcome(false);
    }
  };

  const handleButtonClick = async (uid, type) => {
    try {
      console.log(`Button clicked: ${type}, ${uid}`);
      const response = await LeadsList.postActivityLogs(uid, type);
      // Fetch email, phone_no, and social_links from actionsData
      const { email, phone_no, social_links } = actionsData;

      // WhatsApp Button Click
      if (type === "whatsapp" && phone_no) {
        const whatsappUrl = `https://wa.me/${phone_no.replace("+", "")}`;
        window.open(whatsappUrl, "_blank");
      }

      // Email Button Click
      if (type === "mail" && email) {
        const emailDomain = email.split("@")[1];
        let mailUrl = `mailto:${email}`;

        // Open specific email client based on domain
        if (emailDomain.includes("gmail")) {
          mailUrl = `https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${email}`;
        } else if (
          emailDomain.includes("outlook") ||
          emailDomain.includes("hotmail")
        ) {
          mailUrl = `https://outlook.live.com/mail/0/deeplink/compose?to=${email}`;
        }

        window.open(mailUrl, "_blank");
      }

      // Social Media Button Click
      if (type === "Social Media" && social_links) {
        const socialMediaPlatforms = {
          Facebook: "Facebook",
          LinkedIn: "LinkedIn",
          Twitter: "Twitter",
          Instagram: "Instagram",
        };

        // Loop through available social media links and open respective platforms
        for (const [platform, link] of Object.entries(social_links)) {
          if (socialMediaPlatforms[platform]) {
            window.open(link, "_blank");
            break; // Open the first available link and exit loop
          }
        }
      }

      // Log success message
      // message.success(`Activity log posted successfully for action: ${type}`);
    } catch (error) {
      console.error(`Failed to post activity log for action: ${type}`, error);
      message.error(`Failed to perform action: ${type}`);
    }
  };

  const tabContents = [
    { key: "Call", label: "Call" },
    { key: "Whatsapp", label: "WhatsApp" },
    { key: "Mail", label: "Mail" },
    { key: "Social Media", label: "Social Media" },
    ...(hasPermission("can_view_lead_activity_logs")
      ? [{ key: "Activity Logs", label: "Activity Logs" }]
      : []),
  ].map(({ key, label }) => (
    <TabPane tab={<span>{label}</span>} key={key}>
      {/* Conditional rendering for Outcome Logs tab */}
      {key !== "Activity Logs" ? (
        loading && activeTab === key ? (
          <Skeleton style={{ width: "100%" }} />
        ) : outcomeLogs.length > 0 ? (
          <Table
            style={{
              width: isMobile ? "80vw" : "100%", // Adjust table width based on mobile
            }}
            dataSource={outcomeLogs.map((log, index) => ({
              key: index,
              verb: log.verb,
              remarks: log.remarks,
              created_at: new Date(log.created_at).toLocaleString(),
            }))}
            columns={[
              {
                title: "Outcome",
                dataIndex: "verb",
                key: "verb",
                render: (text) => (
                  <span dangerouslySetInnerHTML={{ __html: text }} />
                ),
              },
            ]}
            scroll={{ y: 300 }} // You can adjust the scroll height based on mobile as needed
            pagination={false}
          />
        ) : (
          <p>No outcome logs available.</p>
        )
      ) : /* Conditional rendering for Activity Logs tab */
      loading && activeTab === "Activity Logs" ? (
        <Skeleton style={{ width: "100%" }} />
      ) : activityLogs?.length > 0 ? (
        <Table
          style={{ width: isMobile ? "80vw" : "100%" }}
          dataSource={activityLogs.map((log, index) => ({
            key: index,
            user: log.user,
            action: log.action,
            created_at: new Date(log.created_at).toLocaleString(),
          }))}
          columns={[
            {
              title: "Changed By",
              dataIndex: "user",
              key: "user",
            },
            {
              title: "Action",
              dataIndex: "action",
              key: "action",
            },
            {
              title: "Action at",
              dataIndex: "created_at",
              key: "created_at",
            },
          ]}
          scroll={{ y: 300 }}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            onChange: handleTableChange,
          }}
        />
      ) : (
        <p>No activity logs available.</p>
      )}
    </TabPane>
  ));

  const renderSocialMediaButtons = () => {
    if (!actionsData?.social_links) return null;

    return (
      <Flex dir="row" style={{ gap: "2px", alignItems: "center" }}>
        {Object.entries(actionsData.social_links).map(([platform, link]) => {
          if (platform === "Facebook") {
            return (
              <Button
                key="facebook"
                style={{
                  borderRadius: "100%",
                  background: "transparent",
                  border: "none",
                }}
                icon={
                  <FacebookFilled
                    style={{
                      color: "#0866FF",
                      fontSize: "25px",
                      borderRadius: "100%",
                    }}
                  />
                }
                onClick={() => (
                  handleButtonClick(record.uid, "social_media"),
                  window.open(link, "_blank")
                )}
              />
            );
          }
          if (platform === "LinkedIn") {
            return (
              <Button
                key="linkedin"
                style={{
                  borderRadius: "100%",
                  background: "transparent",
                  border: "none",
                }}
                icon={
                  <LinkedinFilled
                    style={{
                      color: "#0866FF",
                      fontSize: "25px",
                      borderRadius: "100%",
                    }}
                  />
                }
                onClick={() => (
                  handleButtonClick(record.uid, "social_media"),
                  window.open(link, "_blank")
                )}
              />
            );
          }
          if (platform === "Twitter") {
            return (
              <Button
                key="twitter"
                style={{
                  borderRadius: "100%",
                  background: "transparent",
                  border: "none",
                }}
                icon={
                  <TwitterCircleFilled
                    style={{
                      color: "#1DA1F2",
                      fontSize: "25px",
                      borderRadius: "100%",
                    }}
                  />
                }
                onClick={() => (
                  handleButtonClick(record.uid, "social_media"),
                  window.open(link, "_blank")
                )}
              />
            );
          }
          if (platform === "Instagram") {
            return (
              <Button
                key="instagram"
                style={{
                  borderRadius: "100%",
                  background: "transparent",
                  border: "none",
                }}
                icon={
                  <InstagramFilled
                    style={{
                      color: "#C13584",
                      fontSize: "25px",
                      borderRadius: "100%",
                    }}
                  />
                }
                onClick={() => (
                  handleButtonClick(record.uid, "social_media"),
                  window.open(link, "_blank")
                )}
              />
            );
          }
          return null;
        })}
      </Flex>
    );
  };

  return (
    <Flex
      justify="flex-start"
      align="flex-start"
      vertical
      gap={16}
      style={{ width: isMobile ? "80vw" : "100%" }}>
      <Flex
        justify="space-between"
        align={isMobile ? "flex-end" : "flex-start"}
        dir={isMobile ? "column" : "row"}
        style={{
          width: "100%",
          flexDirection: isMobile ? "column-reverse" : "row",

          justifyContent: isMobile ? "flex-end" : "center",
          marginTop: isMobile ? "20px" : 0,
        }}>
        <Tabs
          activeKey={activeTab}
          onChange={handleTabChange}
          style={{ width: "100%" }}>
          {tabContents}
        </Tabs>
        {(leadType === "assignedLeads" || leadType === "managedLeads") &&
          hasPermission("can_call_lead") &&
          activeTab === "Call" && (
            <Dropdown overlay={menu} trigger={["click"]}>
              <Tooltip title="Call Client">
                <Button
                  style={{ background: "#040724" }}
                  type="primary"
                  icon={<PhoneOutlined rotate={90} color="#040724" />}
                  shape="circle"
                />
              </Tooltip>
            </Dropdown>
          )}
         
        {(leadType === "assignedLeads" || leadType === "managedLeads") &&
          hasPermission("can_call_lead") &&
          activeTab === "Whatsapp" && (
            <Tooltip title="Whatsapp Client">
              <Button
                // type="primary"
                style={{ background: "	#25D366", borderRadius: "100%" }}
                icon={<WhatsAppOutlined style={{ color: "white" }} />}
                onClick={() =>
                  handleButtonClick(record.uid, "whatsapp")
                }></Button>
            </Tooltip>
          )}
          {/* {console.log(record.status , 'record.status')} */}
        {(leadType === "assignedLeads" || leadType === "managedLeads") &&
          hasPermission("can_call_lead") &&
          activeTab === "Mail" && (
            <Tooltip title="Mail Client">
              <Button
                style={{ background: "	#040724", borderRadius: "100%" }}
                // type="primary"
                icon={<MailOutlined style={{ color: "white" }} />}
                onClick={() => handleButtonClick(record.uid, "mail")}></Button>
            </Tooltip>
          )}
        {(leadType === "assignedLeads" || leadType === "managedLeads") &&
          hasPermission("can_call_lead") &&
          activeTab === "Social Media" &&
          renderSocialMediaButtons()}
      </Flex>

      <CallModal
        visible={isCallModalVisible}
        onHangUp={handleHangUp}
        callDuration={callDuration}
        onCancel={handleHangUp}
        record={record}
        disableHangUp={disableHangUp}
      />

      {/* Common outcome form */}
      {(leadType === "assignedLeads" || leadType === "managedLeads") &&
        hasPermission("can_post_lead_outcomes") && (
          <Flex
            justify="space-between"
            align="center"
            gap={isMobile ? "20px" : 0}
            style={{
              width: "100%",
              flexWrap: isMobile ? "wrap" : "nowrap",
              justifyContent: isMobile ? "flex-end" : "space-between",
            }}>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                flexWrap: isMobile ? "wrap" : "nowrap",
              }}>
              <Select
                placeholder="Select Outcome"
                value={commonFormValues.outcome}
                style={{ width: isMobile ? "100%" : 200, textAlign: "left" }}
                onChange={(value) => handleCommonFormChange("outcome", value)}
                options={outcomeOptions[activeTab]?.map((outcome) => ({
                  value: outcome,
                  label: outcome,
                }))}
                allowClear
              />
              <DatePicker
                showTime
                placeholder="Next Activity"
                style={{ width: isMobile ? "100%" : 240, textAlign: "left" }}
                value={commonFormValues.next_activity}
                onChange={(value) =>
                  handleCommonFormChange("next_activity", value)
                }
              />
              <Select
                placeholder="Select Status"
                value={commonFormValues.status}
                style={{ width: isMobile ? "100%" : 200, textAlign: "left" }}
                onChange={(value) => handleCommonFormChange("status", value)}>
                {statusOptions.map(({ value, label }) => (
                  <Option key={value} value={value}>
                    {label}
                  </Option>
                ))}
              </Select>
              <Input.TextArea
                placeholder="Enter remarks"
                value={commonFormValues.remarks}
                onChange={(e) =>
                  handleCommonFormChange("remarks", e.target.value)
                }
                rows={1}
                style={{ width: isMobile ? "100%" : 200, textAlign: "left" }}
              />
            </div>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Button
                type="primary"
                shape="circle"
                style={{ background: "#040724" }}
                loading={savingOutcome}
                icon={<CheckOutlined style={{ fontSize: "14px" }} />}
                onClick={handleSaveCommonOutcome}
              />
              <Button type="default" shape="circle" onClick={handleResetForm}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="20"
                  height="20"
                  viewBox="0 0 50 50">
                  <path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"></path>
                </svg>
              </Button>
            </div>
          </Flex>
        )}
      {/* Modal outcome form */}
      <Modal
        className="outcome"
        closable={false}
        cancelButtonProps={{ style: { display: "none" } }} // Hide cancel button
        visible={isOutcomeModalVisible}
        title={
          <Flex justify="space-between" className="custom-modal-header">
            <Title
              level={3}
              style={{
                color: "white",
                margin: 0,
                position: "relative",
                marginLeft: "10px",
              }}>
              Outcome
            </Title>
            <CloseOutlined
              style={{ fontSize: "16px", marginRight: "10px" }}
              onClick={() => setIsOutcomeModalVisible(false)}
            />
          </Flex>
        }
        onOk={handleSaveModalOutcome}
        // onCancel={() => setIsOutcomeModalVisible(false)}
        confirmLoading={savingOutcome}>
        <Flex vertical gap={16} style={{ padding: "10px" }}>
          <Select
            placeholder="Select Outcome"
            value={modalFormValues.outcome}
            style={{ width: "100%" }}
            onChange={(value) => handleModalFormChange("outcome", value)}
            options={outcomeOptions[activeTab]?.map((outcome) => ({
              value: outcome,
              label: outcome,
            }))}
            allowClear
          />
          <DatePicker
            showTime
            placeholder="Next Activity"
            style={{ width: "100%" }}
            value={modalFormValues.next_activity}
            onChange={(value) => handleModalFormChange("next_activity", value)}
          />
          <Select
            placeholder="Select Status"
            value={modalFormValues.status}
            style={{ width: "100%" }}
            onChange={(value) => handleModalFormChange("status", value)}>
            {statusOptions.map(({ value, label }) => (
              <Option key={value} value={value}>
                {label}
              </Option>
            ))}
          </Select>
          <Input.TextArea
            placeholder="Enter remarks"
            value={modalFormValues.remarks}
            onChange={(e) => handleModalFormChange("remarks", e.target.value)}
            rows={4}
            style={{ width: "100%" }}
          />
        </Flex>
      </Modal>
    </Flex>
  );
};

export default ManageLeadContent;
