import axiosInstance from "./axiosInstance";
import { catchError } from "../utils/errorHandler";
import { message } from "antd";
import axios from "axios";
const clientService = {
  getAllClients: async (page = 1, pageSize = 10, filters = {}) => {
    const {
      username,
      email,
      ownership,
      task_count,
      website,
      inactive_since_last_quarter,
      visibility,
    } = filters;
    const query = new URLSearchParams({
      page,
      page_size: pageSize,
      ...(username && { username }),
      ...(email && { email }),
      ...(ownership && { ownership }),
      ...(task_count && { task_count }),
      ...(website && { website }),
      ...(visibility && { visibility }),
      ...(inactive_since_last_quarter && { inactive_since_last_quarter }),
    }).toString();
    try {
      const response = await axiosInstance.get(`/client/v2/all/?${query}`);
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      if (error.response && error.response.status === 500) {
        message.error("Internal Server Error");
      }
      await catchError(error);
      return { success: false };
    }
  },
  getMyClients: async (page = 1, pageSize = 10, filters = {}) => {
    const {
      username,
      email,
      ownership,
      task_count,
      website,
      visibility,
      inactive_since_last_quarter,
    } = filters;
    const query = new URLSearchParams({
      page,
      page_size: pageSize,
      ...(username && { username }),
      ...(email && { email }),
      ...(ownership && { ownership }),
      ...(visibility && { visibility }),
      ...(task_count && { task_count }),
      ...(website && { website }),
      ...(inactive_since_last_quarter && { inactive_since_last_quarter }),
    }).toString();
    try {
      const response = await axiosInstance.get(`/client/v2/my/?${query}`);
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      if (error.response && error.response.status === 500) {
        message.error("Internal Server Error");
      }
      await catchError(error);
      return { success: false };
    }
  },
  getContactRequests: async (page = 1, pageSize = 10, filters = {}) => {
    const {
      username,
      email,
      ownership,
      task_count,
      website,
      visibility,
      inactive_since_last_quarter,
    } = filters;
    const query = new URLSearchParams({
      page,
      page_size: pageSize,
      ...(username && { username }),
      ...(email && { email }),
      ...(ownership && { ownership }),
      ...(visibility && { visibility }),
      ...(task_count && { task_count }),
      ...(website && { website }),
      ...(inactive_since_last_quarter && { inactive_since_last_quarter }),
    }).toString();
    try {
      const response = await axiosInstance.get(`/client/v2/my/?${query}`);
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      if (error.response && error.response.status === 500) {
        message.error("Internal Server Error");
      }
      await catchError(error);
      return { success: false };
    }
  },
  getAdLeads: async (page = 1, pageSize = 10, filters = {}) => {
    const {
      start_date,
    end_date,
      email,
      ownership,
      task_count,
      website,
      visibility,
      inactive_since_last_quarter,
      contact_no
    } = filters;
    const query = new URLSearchParams({
      page,
      page_size: pageSize,
      ...(start_date && { start_date }),
      ...(end_date && { end_date }),
      ...(contact_no && { contact_no }),
      ...(email && { email }),
      ...(ownership && { ownership }),
      ...(visibility && { visibility }),
      ...(task_count && { task_count }),
      ...(website && { website }),
      ...(inactive_since_last_quarter && { inactive_since_last_quarter }),
    }).toString();
    try {
      const response = await axiosInstance.get(`/task/v2/oz-new-upload/?${query}`);
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      if (error.response && error.response.status === 500) {
        message.error("Internal Server Error");
      }
      await catchError(error);
      return { success: false };
    }
  },
  getClientReferrals: async (page = 1, pageSize = 10, filters = {}) => {
    const { start_date, end_date } = filters;
    const query = new URLSearchParams({
      page,
      page_size: pageSize,
      ...(start_date && { start_date }),
      ...(end_date && { end_date }),
    }).toString();
    try {
      const response = await axiosInstance.get(
        `/referral/v2/my-client-referral-list/?${query}`
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      if (error.response && error.response.status === 500) {
        message.error("Internal Server Error");
      }
      await catchError(error);
      return { success: false };
    }
  },
  getSupportReferrals: async (page = 1, pageSize = 10, filters = {}) => {
    const { start_date, end_date } = filters;
    const query = new URLSearchParams({
      page,
      page_size: pageSize,
      ...(start_date && { start_date }),
      ...(end_date && { end_date }),
    }).toString();
    try {
      const response = await axiosInstance.get(
        `/referral/v2/support-executives-referrals/?${query}`
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      if (error.response && error.response.status === 500) {
        message.error("Internal Server Error");
      }
      await catchError(error);
      return { success: false };
    }
  },
  getAgentsList: async (page = 1, pageSize = 10, filters = {}) => {
    const { start_date, end_date } = filters;
    const query = new URLSearchParams({
      page,
      page_size: pageSize,
      ...(start_date && { start_date }),
      ...(end_date && { end_date }),
    }).toString();
    try {
      const response = await axiosInstance.get(
        `/client/v2/become-agent-list/?${query}`
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      if (error.response && error.response.status === 500) {
        message.error("Internal Server Error");
      }
      await catchError(error);
      return { success: false };
    }
  },
  getSelfRegisteredList: async (page = 1, pageSize = 10, filters = {}) => {
    const { start_date, end_date, website } = filters;
    const query = new URLSearchParams({
      page,
      page_size: pageSize,
      ...(start_date && { start_date }),
      ...(end_date && { end_date }),
      ...(website && { website }),
    }).toString();
    try {
      const response = await axiosInstance.get(
        `/useraccount/v2/self-registered/?${query}`
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      if (error.response && error.response.status === 500) {
        message.error("Internal Server Error");
      }
      await catchError(error);
      return { success: false };
    }
  },
  getAllReferrals: async (page = 1, pageSize = 10, filters = {}) => {
    const { start_date, end_date } = filters;
    const query = new URLSearchParams({
      page,
      page_size: pageSize,
      ...(start_date && { start_date }),
      ...(end_date && { end_date }),
    }).toString();
    try {
      const response = await axiosInstance.get(`/referral/v2/all/?${query}`);
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      if (error.response && error.response.status === 500) {
        message.error("Internal Server Error");
      }
      await catchError(error);
      return { success: false };
    }
  },
  splitTransaction: async (uuid, splitData) => {
    try {
      const response = await axiosInstance.post(
        `/client/v2/transaction/split/${uuid}/`,
        splitData
      );
      if (response.status === 201) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  createClient: async (clientData) => {
    try {
      const response = await axiosInstance.post(
        `/client/v2/create/`,
        clientData
      );
      if (response.status === 201) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  assignAdLead: async (data) => {
    try {
      const response = await axiosInstance.post(
        `task/v2/oz-new-upload/assign-bdm/`,
        data
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  createReferral: async (referralData) => {
    try {
      const response = await axiosInstance.post(
        `/referral/v2/add-referral-api/`,
        referralData
      );
      if (response.status === 201) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  addReferralRemarks: async (uid, remarks) => {
    try {
      const response = await axiosInstance.post(
        `/referral/v2/add-referral-remarks/${uid}/`,
        remarks
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  updateAdLeadRemarks: async (uid,data) => {

    try {
      const response = await axiosInstance.post(
        `/task/v2/oz-new-upload/remarks/${uid}/`,
        data
      );

      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  updateAgentRemarks: async (data) => {
    try {
      const response = await axiosInstance.post(
        `/client/v2/become-agent-list/`,
        data
      );

      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  getClientDetail: async (uuid) => {
    try {
      const response = await axiosInstance.get(`/client/v2/detail/${uuid}/`);
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },

  getClientTransactions: async (uuid, startDate, endDate) => {
    const query = new URLSearchParams({
      ...(startDate && { start_date: startDate }),
      ...(endDate && { end_date: endDate }),
    }).toString();
    try {
      const response = await axiosInstance.get(
        `/client/v2/transactions/${uuid}/?${query}`
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },

  getClientTasks: async (uuid, startDate, endDate) => {
    const query = new URLSearchParams({
      ...(startDate && { start_date: startDate }),
      ...(endDate && { end_date: endDate }),
    }).toString();
    try {
      const response = await axiosInstance.get(
        `/client/v2/tasks/${uuid}/?${query}`
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },

  getClientRefunds: async (uuid) => {
    try {
      const response = await axiosInstance.get(`/client/v2/refunds/${uuid}/`);
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  getClientRevenue: async (uuid, startDate, endDate) => {
    const query = new URLSearchParams({
      ...(startDate && { start_date: startDate }),
      ...(endDate && { end_date: endDate }),
      ...(!startDate && !endDate && { last_week: "true", last_month: "true" }),
    }).toString();
    console.log(query, "quert");
    try {
      const response = await axiosInstance.get(
        `/client/v2/revenue/${uuid}/?${query}`
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },

  getAllCallAllocation: async (page = 1, pageSize = 10, filters = {}) => {
    const {
      key,
      flag_status,
      start_date,
      is_urgent,
      called_by,
      end_date,
    } = filters;
    const query = new URLSearchParams({
      page,
      page_size: pageSize,
      ...(key && { key }),
      ...(flag_status && { flag_status }),
      ...(start_date && { start_date }),
      ...(end_date && { end_date }),
      ...(is_urgent && { is_urgent }),
      ...(called_by && { called_by }),
    }).toString();
    try {
      const response = await axiosInstance.get(`task/v2/call-associate-tasks/?${query}`);
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      if (error.response && error.response.status === 500) {
        message.error("Internal Server Error");
      }
      await catchError(error);
      return { success: false };
    }
  },
  getMyAllocations: async (page = 1, pageSize = 10, filters = {}) => {
    const {
      key,
      flag_status,
      start_date,
      called_by,
      end_date,
    } = filters;
    const query = new URLSearchParams({
      page,
      page_size: pageSize,
      ...(key && { key }),
      ...(flag_status && { flag_status }),
      ...(start_date && { start_date }),
      ...(end_date && { end_date }),
      ...(called_by && { called_by }),
    }).toString();
    try {
      const response = await axiosInstance.get(`task/v2/my-call-associate-tasks/?${query}`);
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      if (error.response && error.response.status === 500) {
        message.error("Internal Server Error");
      }
      await catchError(error);
      return { success: false };
    }
  },

  getWebsitesList: async () => {
    try {
      const response = await axiosInstance.get("/client/v2/websites/");
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },

  getOwnersList: async () => {
    try {
      const response = await axiosInstance.get(
        "/client/v2/client-owners-list/"
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },

  getActiveInactiveStatus: async () => {
    try {
      const response = await axiosInstance.get("/status");
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  getAllTransactions: async (page = 1, pageSize = 10, filters = {}) => {
    const {
      payment_status,
      is_approved,
      payment_mode,
      created,
      author,
      client,
      transaction_id,
      start_date,
      end_date,
      action_taken,
      ownership,
    } = filters;
    const query = new URLSearchParams({
      page,
      page_size: pageSize,
      ...(payment_status && { payment_status }),
      ...(is_approved && { is_approved }),
      ...(payment_mode && { payment_mode }),
      ...(created && { created }),
      ...(author && { author }),
      ...(client && { client }),
      ...(transaction_id && { transaction_id }),
      ...(start_date && { start_date }),
      ...(end_date && { end_date }),
      ...(action_taken && { action_taken }),
      ...(ownership && { ownership }),
    }).toString();
    try {
      const response = await axiosInstance.get(
        `/transactions/v2/all-transactions/?${query}`
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      if (error.response && error.response.status === 500) {
        message.error("Internal Server Error");
      }
      await catchError(error);
      return { success: false };
    }
  },
  getMyTransactions: async (page = 1, pageSize = 10, filters = {}) => {
    const {
      payment_status,
      is_approved,
      payment_mode,
      created,
      author,
      client,
      transaction_id,
      start_date,
      end_date,
      action_taken,
      ownership,
    } = filters;
    const query = new URLSearchParams({
      page,
      page_size: pageSize,
      ...(payment_status && { payment_status }),
      ...(is_approved && { is_approved }),
      ...(payment_mode && { payment_mode }),
      ...(created && { created }),
      ...(author && { author }),
      ...(client && { client }),
      ...(transaction_id && { transaction_id }),
      ...(start_date && { start_date }),
      ...(end_date && { end_date }),
      ...(action_taken && { action_taken }),
      ...(ownership && { ownership }),
    }).toString();
    try {
      const response = await axiosInstance.get(
        `/transactions/v2/my-transactions/?${query}`
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      if (error.response && error.response.status === 500) {
        message.error("Internal Server Error");
      }
      await catchError(error);
      return { success: false };
    }
  },
};

export default clientService;
