import React, { useState, useEffect } from "react";
import { Input, Button, Select, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import clientService from "../../services/clientService";
import AddClientModal from "./AddClientModal";
import useIsMobile from "../../hooks/useIsMobile";

const { Option } = Select;

const SearchFilters = ({
  onSearch,
  pagination,
  getAllClients,
  filtersList,
}) => {
  const { isMobile } = useIsMobile();
  const [filters, setFilters] = useState({
    email: "",
    username: "",
    ownership: "",
    inactive_since_last_quarter: "",
    visibility: "",
  });

  const [websites, setWebsites] = useState([]);
  const [owners, setOwners] = useState([]);
  const [visibilities, setVisibilities] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    const fetchWebsites = async () => {
      const result = await clientService.getWebsitesList();
      if (result) {
        setWebsites(result.data.data);
      } else {
        message.error("Failed to load websites");
      }
    };

    const fetchOwners = async () => {
      const result = await clientService.getOwnersList();
      if (result.success) {
        setVisibilities(result.data.data);
        setOwners(result.data.data);
      } else {
        message.error("Failed to load owners");
      }
    };

    fetchWebsites();
    fetchOwners();
  }, []);

  const websiteOptions = websites.map((website) => ({
    label: website.name,
    value: website.name,
  }));

  const handleSearch = () => {
    onSearch(filters);
  };

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const handleCancel = async () => {
    setFilters({
      email: "",
      username: "",
      ownership: "",
      inactive_since_last_quarter: "",
      visibility: "",
    });
    await getAllClients(1, pagination.pageSize);
  };

  const renderFilter = (filter) => {
    switch (filter) {
      case "email":
        return (
          <Input
            placeholder="Filter by Email"
            style={{ width: isMobile ? "80vw" : 300 }}
            value={filters.email}
            onChange={(e) => handleFilterChange("email", e.target.value)}
          />
        );
      case "inactive_since_last_quarter":
        return (
          <Select
            placeholder="Filter by Status"
            style={{ width: isMobile ? "80vw" : 300, textAlign: "left" }}
            value={filters.inactive_since_last_quarter || undefined}
            onChange={(value) =>
              handleFilterChange("inactive_since_last_quarter", value)
            }
          >
            <Option value="1">Active</Option>
            <Option value="0">Inactive</Option>
          </Select>
        );
      case "ownership":
        return (
          <Select
            placeholder="Filter by Ownership"
            style={{ width: 300, textAlign: "left" }}
            value={filters.ownership || undefined}
            options={owners.map((owner) => ({
              label: owner.user,
              value: owner.username,
            }))}
            onChange={(value) => handleFilterChange("ownership", value)}
          />
        );
      case "visibility":
        return (
          <Select
            placeholder="Filter by Visibility"
            style={{ width: 300, textAlign: "left" }}
            value={filters.visibility || undefined}
            options={visibilities.map((visibilitie) => ({
              label: visibilitie.user,
              value: visibilitie.username,
            }))}
            onChange={(value) => handleFilterChange("visibility", value)}
          />
        );
      case "username":
        return (
          <Input
            placeholder="Filter by Username"
            style={{ width: 300 }}
            value={filters.username}
            onChange={(e) => handleFilterChange("username", e.target.value)}
          />
        );
      case "website":
        return (
          <Select
            placeholder="Website"
            style={{ width: isMobile ? "80vw" : 300, textAlign: "left" }}
            value={filters.website || undefined}
            options={websiteOptions}
            onChange={(value) => handleFilterChange("website", value)}
          />
        );
      default:
        return null;
    }
  };

  const isWebsiteFilterPresent = filtersList.includes("website");

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{ background: "#eceff1", padding: "20px", borderRadius: "8px" }}
      >
        <div
          style={{
            marginBottom: 16,
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          {filtersList.map((filter) => (
            <div key={filter} style={{ marginRight: 16 }}>
              {renderFilter(filter)}
            </div>
          ))}
          <div
            style={{
              display: "flex",
              gap: "20px",
              marginRight: isMobile ? 0 : "12px",
              justifyContent: isWebsiteFilterPresent ? 'flex-end' : 'space-between',
              width: isWebsiteFilterPresent ? '100%' : 'auto',
     
            }}
          >
            <Button
              type="primary"
              style={{ width: 140, background: "#040724" }}
              icon={<SearchOutlined />}
              onClick={handleSearch}
            >
              Search
            </Button>
            <Button
              style={{ width: 140 }}
              type="default"
              onClick={handleCancel}
            >
              Reset
            </Button>
          </div>
        </div>
      </div>
      <AddClientModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        websites={websites}
      />
    </div>
  );
};

export default SearchFilters;
