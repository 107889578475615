import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Drawer,
  Menu,
  message,
  Button,
  Flex,
  Typography,
  Avatar,
  Card,
  Tooltip
} from "antd";
import {
  PoweroffOutlined,
  UserOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Layout } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebarDrawer, toggleSidebar } from "../../redux/slices/uiSlice";
import { logout } from "../../redux/slices/authSlice";
import { useFilteredNavMenu } from "../../config/NavMenu";

const { Sider } = Layout;
const { Title, Text } = Typography;

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const { collapsed, drawerOpen } = useSelector((state) => ({
    collapsed: state.ui.collapsed,
    drawerOpen: state.ui.drawerOpen,
  }));

  const navMenu = useFilteredNavMenu();

  const handleMenuClick = (e) => {
    const selectedItem = navMenu
      .flatMap((item) => (item.children ? [item, ...item.children] : [item]))
      .find((item) => item.key === e.key);

    if (selectedItem) {
      navigate(selectedItem.route);
      if (window.innerWidth <= 768) {
        dispatch(toggleSidebarDrawer());
      }
    }
  };

  const handleLogout = async () => {
    try {
      await dispatch(logout());
      message.success("Logged Out Successfully");
      navigate("/login");
    } catch (error) {
      message.error("Logout failed");
    }
  };

  return (
    <>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        width={270}
        collapsedWidth={100}
        className="global_aside">
        <Flex
          vertical
          justify="space-between"
          align="center"
          style={{ height: "100%", padding: "10px 0px" }}>
          <Flex vertical align="center" style={{ width: "100%" }} gap={10}>
            <div className="logo_case">
              <Title
                level={collapsed ? 4 : 2}
                style={{ color: "white", margin: 0, textAlign: "center" }}>
                {collapsed ? "LR" : "Locus RAGS"}
              </Title>
            </div>

            <Menu
              theme="dark"
              mode="inline"
              defaultSelectedKeys={["1"]}
              items={navMenu}
              onClick={handleMenuClick}
            />
          </Flex>

          <Flex vertical align="center" style={{ width: "100%" }} gap={10}>
            {/* User Profile Card */}
            {collapsed ? (
              <Tooltip title={
                <Flex
                  style={{ flexDirection: "column" }}
                  align="flex-start"
                  gap={5}
                >
                  <Text strong style={{ fontSize: "16px", color: "white" }}>
                    {user?.user?.full_name}
                  </Text>
                  <Text
                    type="secondary"
                    style={{ fontSize: "12px", color: "whitesmoke" }}
                  >
                    {user?.user?.email}
                  </Text>
                </Flex>
              }>
                <Avatar
                  style={{
                    backgroundColor: "#f56a00",
                  }}
                  size={40}
                  icon={<UserOutlined />}
                />
              </Tooltip>
            ) : (
              <div
                className="profile-card"
                style={{
                  width: "90%",
                  borderRadius: "10px",
                  boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
                  cursor: "pointer",
                  padding: "10px",
                  background: "rgba(255, 255, 255, 0.1)", // Semi-transparent white
                  backdropFilter: "blur(10px)", // Blur effect
                  WebkitBackdropFilter: "blur(10px)", // Safari support
                  border: "1px solid rgba(255, 255, 255, 0.2)", // Subtle border
                }}>
                <Flex
                  style={{ flexDirection: "row" }}
                  align="center"
                  gap={10}
                >
                  <Avatar
                    style={{
                      backgroundColor: "#f56a00",
                    }}
                    size={40}
                    icon={<UserOutlined />}
                  />
                  <Flex vertical>
                    <Text strong style={{ fontSize: "16px", color: "white" }}>
                      {user?.user?.full_name}
                    </Text>
                    <Text
                      type="secondary"
                      style={{ fontSize: "12px", color: "whitesmoke" }}
                    >
                      {user?.user?.email}
                    </Text>
                  </Flex>
                </Flex>
              </div>
            )}
          
        

            {/* Logout Button */}
            <Button
              type="primary"
              style={{
                background: "red",
                width: collapsed ? "40px" : "90%",
                marginTop: 10,
              }}
              icon={<PoweroffOutlined />}
              onClick={handleLogout}
              className="logoutButton">
              {!collapsed && "Logout"}
            </Button>
          </Flex>
        </Flex>
      </Sider>

      <Drawer
        placement="left"
        open={drawerOpen}
        onClose={() => dispatch(toggleSidebarDrawer())}>
        <Flex
          vertical
          align="flex-start"
          justify="space-between"
          style={{ height: "100%" }}>
          <Flex vertical align="flex-start" gap={10}>
            <Title
              level={2}
              style={{ color: "white", margin: 0, textAlign: "center" }}>
              Locus RAGS
            </Title>
            <Menu
              defaultSelectedKeys={["1"]}
              mode="inline"
              items={navMenu}
              onClick={handleMenuClick}
            />
          </Flex>

          {/* User Profile in Drawer */}
          <Flex vertical align="center" style={{ width: "100%" }} gap={10}>
            {/* User Profile Card */}

            <div
              className="profile-card"
              style={{
                width: "90%",
                borderRadius: "10px",
                boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
                cursor: "pointer",
                padding: "10px",
                background: "rgba(255, 255, 255, 0.1)", // Semi-transparent white
                backdropFilter: "blur(10px)", // Blur effect
                WebkitBackdropFilter: "blur(10px)", // Safari support
                border: "1px solid rgba(255, 255, 255, 0.2)", // Subtle border
              }}>
              <Flex
                style={{ flexDirection: "column" }}
                align={"flex-start"}>
                <Flex
                  align={"flex-start"}
                  style={{ flexDirection: "row" }}
                  gap={10}>
                  <Avatar
                    style={{
                      backgroundColor: "#f56a00",
                    }}
                    size={40}
                    icon={<UserOutlined />}
                  />
                  <Flex vertical>
                    <Text strong style={{ fontSize: "16px", color: "white" }}>
                      {user?.user?.full_name}
                    </Text>
                    <Text
                      type="secondary"
                      style={{ fontSize: "12px", color: "whitesmoke" }}>
                      {user?.user?.email}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </div>

            {/* Logout Button */}
            <Button
              type="primary"
              style={{
                background: "red",
                width: collapsed ? "40px" : "90%",
                marginTop: 10,
              }}
              icon={<PoweroffOutlined />}
              onClick={handleLogout}
              className="logoutButton">
              {!collapsed && "Logout"}
            </Button>
          </Flex>
       
        </Flex>
      </Drawer>
    </>
  );
};

export default Sidebar;
