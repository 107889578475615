import React, { useState } from "react";
import ReusableModal from "../../components/ReusableModal";
import { message } from "antd";
import clientService from "../../services/clientService";

const AddClientModal = ({
  visible,
  onClose,
  websites,
  viewMode,
  getMyClients,
  getAllClients,
}) => {
  // const [confirmLoading, setConfirmLoading] = useState(false);

  // const handleSubmit = async (values) => {
  //   try {
  //     setConfirmLoading(true);

  //     // Custom logic to handle website, if the field is included
  //     if (values.customWebsiteName) {
  //       values.website = values.customWebsiteName;
  //       delete values.customWebsiteName;
  //     }

  //     const response = await clientService.createClient(values);
  //     if (response.success) {
  //       message.success("Client added successfully");
  //     } else {
  //       message.error("Failed to add client");
  //     }
  //   } catch (error) {
  //     message.error("An error occurred while adding the client");
  //     console.error(error);
  //   } finally {
  //     setConfirmLoading(false);
  //     // onClose();
  //   }
  // };

  const clientFields = [
    {
      name: "full_name",
      label: "Full Name",
      type: "input",
      rules: [{ required: true, message: "Please enter the full name" }],
    },
    {
      name: "email",
      label: "Email",
      type: "input",
      rules: [
        { required: true, message: "Please enter the email" },
        { type: "email", message: "Please enter a valid email" },
      ],
    },
    {
      name: "username",
      label: "Username",
      type: "input",
    },
    {
      name: "contact_no",
      label: "Contact Number",
      type: "phone",
      rules: [{ required: true, message: "Please enter the contact number" }],
    },

    {
      name: "website",
      label: "Website",
      type: "radio",
      options: websites.map((website) => ({
        id: website.id,
        value: website.id, // You can use website.id as the value
        label: website.name, // Use website.name for the display label
      })),
      rules: [{ required: true, message: "Please select a website" }],
    },
  ];

  return (
    <ReusableModal
      visible={visible}
      onClose={onClose}
      title="Create Client"
      fields={clientFields}
      viewMode={viewMode}
      getMyClients={getMyClients}
      getAllClients={getAllClients}
      // confirmLoading={confirmLoading}
    />
  );
};

export default AddClientModal;
