import React from "react";
import { Row, Col, Flex } from "antd";
import {
  ShoppingOutlined,
  ClockCircleOutlined,
  TruckOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import Img1 from "../../img/icon1.jpeg";
import { useMediaQuery } from "react-responsive";
import closedWon from "../../img/lead-generation.png";
import openLeads from "../../img/sales.png";

import ClosedWonSVG from "../../img/closed-won.svg";
import OpenLeadsSVG from "../../img/open-leads.svg";
import TotalLeadsSVG from "../../img/total-leads.svg";
import ClosedLostSvg from "../../img/closed-lost.svg";
const OrderStatistics = ({ statistics }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const StatItem = ({ icon, value, label }) => (
    <Col>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          background: "#eceff1",
          padding: "10px 12px",
          minWidth: isMobile ? "170px" : "230px",
          borderRadius: "4px",
        }}>
        <div
          style={{
            backgroundColor: "whitesmoke",
            borderRadius: "50%",
            width: "40px",
            height: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "10px",
          }}>
          <img
            src={icon}
            alt={label}
            style={{ width: "30px", height: "30px" }}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column"  , alignItems:'flex-start'}}>
          <div style={{ fontSize: "22px", fontWeight: "bold", color: "#333" }}>
            {value}
          </div>
          <div style={{ fontSize: "13px", color: "#666" }}>{label}</div>
        </div>
      </div>
    </Col>
  );

  return (
    <Flex
      justify={isMobile ? "flex-start" : "space-between"}
      style={{
        display: "flex",
        borderRadius: "8px",
        flexDirection: "row",
        flexWrap: isMobile ? "wrap" : "nowrap",
        width: isMobile ? "95vw" : "100%",

        gap:isMobile ? "14px" : 0,
        
      }}
    
>
      <StatItem
        icon={TotalLeadsSVG}
        value={statistics.totalLeads}
        label="Total Leads"
      />

      <StatItem
        icon={OpenLeadsSVG}
        value={statistics.openLeads}
        label="Open Leads"
      />
      <StatItem
        icon={ClosedWonSVG}
        value={statistics.closedWon}
        label="Closed Won"
      />

      <StatItem
        icon={ClosedLostSvg}
        value={statistics.closedLost}
        label="Closed Lost "
      />
</Flex>
  );
};

export default OrderStatistics;
