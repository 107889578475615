import React, { useState } from 'react';
import { Form, Select, Upload, Button, Input, message, Spin } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import axiosInstance from '../../services/axiosInstance';
import { catchError } from '../../utils/errorHandler';
import { useParams } from 'react-router-dom';

const { TextArea } = Input;
const { Dragger } = Upload;

const RejectTask = ({ handleModalClose, fetchOrderDetails }) => {
    const [form] = Form.useForm();
    const [submitLoading, setSubmitLoading] = useState(false);
    const [fileList, setFileList] = useState([]);
    const { orderKey } = useParams();

    const reasonOptions = [
        { value: 'Rejected', label: 'Rejected' },
        { value: 'Cancelled', label: 'Cancelled' },
        { value: 'Failed', label: 'Failed' },
    ];

    const handleFileChange = ({ fileList }) => {
        setFileList(fileList);
    };

    const handleSubmit = async (values) => {
        const files = fileList.length > 0 ? fileList.map(file => file.originFileObj) : [];

        if (files.length === 0) {
            message.error('Please upload at least one file.');
            return;
        }

        const payload = new FormData();
        payload.append('rejection_type', values.reason_type);
        payload.append('rejection_reason', values.rejected_reason);

        files.forEach(file => {
            payload.append('files', file);
        });

        setSubmitLoading(true);
        try {
            const response = await axiosInstance.post(`/task/v2/reject-task/${orderKey}/`, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            if (response?.status === 200 && response?.data?.status === "success") {
                message.success(response?.data?.message || 'Order rejected successfully!');
                handleModalClose();
                fetchOrderDetails()
            } else {
                message.error('Failed to reject the Order.');
            }
        } catch (error) {
            await catchError(error);
        } finally {
            setSubmitLoading(false);
        }
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
        >
            <Form.Item
                style={{ width: "100%" }}
                name="reason_type"
                label="Reason Type"
                rules={[{ required: true, message: 'Please select a reason type!' }]}
            >
                <Select
                    placeholder="Select reason type"
                    options={reasonOptions}
                />
            </Form.Item>

            <Form.Item
                style={{ width: "100%" }}
                name="files"
                label="Upload Files"
                valuePropName="fileList"
                getValueFromEvent={(e) => (Array.isArray(e) ? e : e && e.fileList)}
                rules={[{ required: false, message: 'Please upload at least one file!' }]}
            >
                <Dragger
                    fileList={fileList}
                    beforeUpload={() => false}
                    onChange={handleFileChange}
                    maxCount={1}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                </Dragger>
            </Form.Item>

            <Form.Item
                style={{ width: "100%" }}
                name="rejected_reason"
                label="Rejection Reason"
                rules={[{ required: true, message: 'Please provide a rejection reason!' }]}
            >
                <TextArea rows={4} placeholder="Provide a reason for rejection" />
            </Form.Item>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20, width: "100%" }}>
                {submitLoading ? (
                    <Spin />
                ) : (
                    <Button type="primary" htmlType="submit" loading={submitLoading}>
                        Submit Rejection
                    </Button>
                )}
            </div>
        </Form>
    );
};

export default RejectTask;
