import React from "react";
import Tasks from "../../page-components/Orders/Tasks";
function Orders() {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ padding: "10px" }}>
   
      </div>
      <Tasks />
    </div>
  );
}

export default Orders;
