import { message } from "antd";
 
export const catchError = async (response) => {
  try {
    // Check if the response indicates success
    if (response.success) {
      message.success("Operation completed successfully!");
      return; // Exit the function after showing the success message
    }
 
    // Handle errors if the response does not indicate success
    const errorData = response.data?.message || response.data || response?.response?.data?.message;
    console.log(errorData, "errorData");
    
    if (errorData) {
      if (typeof errorData === "object") {
        const errs = [];
        const keys = Object.keys(errorData);
        const firstKey = keys[0];
        const firstValue = errorData[firstKey];
        errs.push(`${firstKey}: ${firstValue}`);
        if (errs.length > 0) {
          message.error(errs);
        }
      } else {
        message.error(errorData);
      }
    } else {
      // message.error("An unknown error occurred.");
    }
  } catch (error) {
    console.log("Error:", error);
    message.error("An unexpected error occurred.");
  }
};