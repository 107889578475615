import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import uiReducer from "./slices/uiSlice";
import authReducer from "./slices/authSlice";
import loadingReducer from "./slices/loadingSlice";
import commonInfoMiddleware from "./slices/commonInfoMiddleware";
import { thunk } from "redux-thunk";


const authPersistConfig = {
  key: "auth",
  storage,
  whitelist: ["user", "isAuthenticated", "userType", "permissions"], // Persist permissions too
};

const persistedAuthReducer = persistReducer(authPersistConfig, authReducer);

const store = configureStore({
  reducer: {
    ui: uiReducer,
    auth: persistedAuthReducer,
    loading: loadingReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(thunk, commonInfoMiddleware),
});

export const persistor = persistStore(store);
export default store;
