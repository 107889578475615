import React, { useState } from "react";
import {
  Table,
  Pagination,
  Spin,
  Button,
  Input,
  Form,
  Modal,
  Typography,
  message,
  Popover,
  Card,
  Descriptions,
  Tag,
} from "antd";
import moment from "moment";
import { PlusCircleFilled, EditOutlined } from "@ant-design/icons";
import clientService from "../../services/clientService";
import { useMediaQuery } from "react-responsive";
import usePermissions from "../../hooks/usePermissions";
import { updateRemarks } from "../../services/contactReq";
function MobileViewContactRequestsList({
  contactRequests,
  showModal,
  pagination,
  setPagination,
}) {
  const { hasPermission } = usePermissions();
  return (
    <div>
      {contactRequests.map((agent) => (
        <Card
          key={agent.uuid}
          style={{ marginBottom: "1rem" }}
          actions={[
            hasPermission("can_update_become_agent_remarks") && (
              <Button
                type="link"
                icon={<EditOutlined />}
                onClick={() => showModal(agent)}>
                Edit Remarks
              </Button>
            ),
          ]}>
          <Descriptions bordered>
            <Descriptions.Item label="Name">
              {agent.name || "N/A"}
            </Descriptions.Item>
            {/* <Descriptions.Item label="Client Username">
              {agent.obj_created_}
            </Descriptions.Item> */}
            <Descriptions.Item label="Contact">
              {agent.contact_number || "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Email">
              {agent?.email || "N/A"}
            </Descriptions.Item>
            {/* <Descriptions.Item label="Referred By">
              {agent.referred_by}
            </Descriptions.Item> */}
            {/* <Descriptions.Item label="Status">{agent.status}</Descriptions.Item> */}
            <Descriptions.Item label="Remarks">
              {agent.remarks ? (
                <Popover
                  content={<div style={{ wordBreak: "break-word", maxWidth: "190px" }}>{agent.remarks}</div>}
                  title="Remarks"
                  trigger="click">
                  <Button type="link">View</Button>
                </Popover>
              ) : (
                "No remarks available"
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Description">
              {agent.description ? (
                <Popover
                  content={
                    <div style={{ wordBreak: "break-word", maxWidth: "190px" }}>
                      {agent.description}
                    </div>
                  }
                  title="Description"
                  trigger="click">
                  <Button type="link">View</Button>
                </Popover>
              ) : (
                "N/A"
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Created At">
              {moment(agent.obj_created_at)
                .local()
                .format("DD-MM-YYYY hh:mm A")}
            </Descriptions.Item>
            <Descriptions.Item label="Updated At">
              {agent.updated_at
                ? moment(agent.updated_at).local().format("DD-MM-YYYY hh:mm A")
                : "Not Updated"}
            </Descriptions.Item>
          </Descriptions>
        </Card>
      ))}
      <Pagination
        current={pagination.current}
        pageSize={pagination.pageSize}
        total={pagination.total}
        onChange={(page, pageSize) =>
          setPagination({ ...pagination, current: page, pageSize })
        }
        style={{ textAlign: "center", marginTop: 16 }}
      />
    </div>
  );
}
function ContactRequestsTable({
  contactRequests,
  loading,
  pagination,
  setPagination,
  getContactRequest,
}) {
  const { hasPermission } = usePermissions();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [remarks, setRemarks] = useState(""); // Track remarks per record
  const [isModalVisible, setIsModalVisible] = useState(false); // State to control modal visibility
  const [currentRecord, setCurrentRecord] = useState(null); // Track the current record for modal
  const [submittingRemarks, setSubmittingRemarks] = useState(false);
  const { Title } = Typography;

  const handleSaveRemarks = async () => {
    if (!currentRecord) return;
    setSubmittingRemarks(true);

    try {
      const response = await updateRemarks(currentRecord.uuid, { remarks });

      if (response.success) {
        message.success("Remarks updated successfully");
        setIsModalVisible(false);
        setRemarks(""); // Clear remarks
        await getContactRequest();
      }
    } catch (error) {
      message.error("Failed to update remarks");
    } finally {
      setSubmittingRemarks(false);
      setIsModalVisible(false);
    }
  };

  const showModal = (record) => {
    setCurrentRecord(record);
    setRemarks(record.remarks || ""); // Pre-fill with existing remarks if available
    setIsModalVisible(true);
  };

  const columns = React.useMemo(() => {
    if (contactRequests && contactRequests.length > 0) {
      const baseColumns = [
        ...Object.keys(contactRequests[0])
          .filter(
            (key) =>
              key !== "uuid" &&
              key !== "become_agent_requested_at" &&
              key !== "email" &&
              key !== "created_at" &&
              key !== "obj_created_at" &&
              key !== "updated_at" &&
              key !== "description" &&
              key !== "remarks"
          )
          .map((key) => {
            if (key === "name") {
              return {
                title: "Name",
                dataIndex: "client_name",
                key: "client_name",
                render: (text, record) => (
                  <>
                    {record.name}
                    <br />
                    <small style={{ color: "gray" }}>
                      {moment(record.obj_created_at)
                        .local()
                        .format("DD-MM-YYYY hh:mm a")}
                    </small>
                  </>
                ),
              };
            }

            if (key === "contact_number") {
              return {
                title: "Contact",
                dataIndex: "client_email",
                key: "client_email",
                render: (text, record) => (
                  <>
                    {record.contact_number}
                    <br />
                    <small style={{ color: "gray" }}>{record.email}</small>
                  </>
                ),
              };
            }

            if (key === "site") {
              return {
                title: "Site",
                dataIndex: "site",
                key: "site",
              };
            }

            if (key === "type") {
              return {
                title: "Type",
                dataIndex: key,
                key,
                render: (record) =>
                  record ? <Tag color="orange">{record}</Tag> : "No action",
              };
            }

            return {
              title: key
                .replace(/_/g, " ")
                .toLowerCase()
                .replace(/\b\w/g, (char) => char.toUpperCase()),
              dataIndex: key,
              key,
            };
          }),
      ];

      // Inject Description Column
      baseColumns.push({
        title: "Description",
        dataIndex: "description",
        key: "description",
        render: (record) => (
          <Popover
            content={<div style={{ wordBreak: "break-word", maxWidth: "190px" }}>{record || "No Description available"}</div>}
            title="Description"
            trigger="hover"
            placement="topLeft">
            <Button type="link" style={{ padding: 0 }}>
              View
            </Button>
          </Popover>
        ),
      });

      // Inject Remarks Column
      baseColumns.push({
        title: "Remarks",
        dataIndex: "remarks",
        key: "remarks",
        render: (text, record) => (
          <Popover
            content={<div style={{ wordBreak: "break-word", maxWidth: "190px" }}>{record.remarks || "No remarks available"}</div>}
            title={
              record.updated_at
                ? `Remarks updated at ${moment(record.updated_at)
                    .local()
                    .format("DD-MM-YYYY hh:mm a")}`
                : "Remarks"
            }
            trigger="hover"
            placement="topLeft">
            <Button type="link" style={{ padding: 0 }}>
              View
            </Button>
          </Popover>
        ),
      });

      // Conditionally add Edit Remark Column
      if (hasPermission("can_update_become_agent_remarks")) {
        baseColumns.push({
          title: "Edit Remark",
          key: "edit_remark",
          render: (_, record) => (
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => showModal(record)}
            />
          ),
        });
      }

      return baseColumns;
    }

    return [];
  }, [contactRequests, hasPermission]);

  const handlePageChange = (page, pageSize) => {
    setPagination({ ...pagination, current: page, pageSize });
  };

  return (
    <div>
      {loading ? (
        <Spin size="large" />
      ) : isMobile ? (
        <MobileViewContactRequestsList
          contactRequests={contactRequests}
          showModal={showModal}
          pagination={pagination}
          setPagination={setPagination}
        />
      ) : (
        <Table
          dataSource={contactRequests}
          columns={columns}
          rowKey="refer_id"
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            onChange: (page, pageSize) =>
              setPagination({ ...pagination, current: page, pageSize }),
          }}
        />
      )}
      {/* Modal for editing remarks */}
      <Modal
        className="custom-modal2"
        title={
          <div
            className="custom-modal-header"
            style={{ display: "flex", justifyContent: "space-between" }}>
            <Title
              level={3}
              style={{
                color: "white",
                margin: 0,
                position: "relative",
                marginLeft: "10px",
              }}>
              Edit Remarks
            </Title>
          </div>
        }
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button
            key="cancel"
            onClick={() => setIsModalVisible(false)}
            disabled={submittingRemarks}>
            Cancel
          </Button>,
          <Button
            key="save"
            type="primary"
            onClick={handleSaveRemarks}
            loading={submittingRemarks}>
            Save
          </Button>,
        ]}
        destroyOnClose>
        <Form>
          <Form.Item>
            <Input.TextArea
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
              placeholder="Enter remarks"
              rows={4}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default ContactRequestsTable;
