import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Flex } from "antd";
import orderService from "../../services/OrderLists";

import DuplitextSubmissionList from "./DuplitextSubmissionList";

const DupliTextParent = () => {
  const { orderKey } = useParams();
  const [submissionList, setSubmissionList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetchSubmissionList = async () => {
    try {
      setIsLoading(true);
      const response = await orderService.duplitextSubmissionList(orderKey);
      if (response.success) {
        setIsLoading(false)
        setSubmissionList(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {

 
    fetchSubmissionList();
  }, [orderKey]);



  return (
    <Flex style={{width:'100%'
    
    }}>
      <DuplitextSubmissionList orderKey={orderKey} submissionList={submissionList} setSubmissionList={setSubmissionList} isLoading={isLoading} setIsLoading={setIsLoading} getSubmissionList={fetchSubmissionList} />
      {/* Render additional details here, for example: */}
     
    </Flex>
  );
};

export default DupliTextParent;
