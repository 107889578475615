import React, { useState, useEffect } from "react";
import { Flex } from "antd";
import LeadsList from "../../services/LeadsList";
import SearchFilters from "./SearchFilters";
import { useCallback } from "react";
import LeadsTable from "./LeadsTable";
import { message, Typography, Button, DatePicker, Modal, Select } from "antd";
import { CSSTransition } from "react-transition-group";
import { useSelector } from "react-redux";
import { FilterOutlined, CloseOutlined } from "@ant-design/icons";
import CreateLeadForm from "./CreateLeadForm";
import dayjs from "dayjs";
import ReusableModal from "../../components/ReusableModal";
import { useMediaQuery } from "react-responsive";
import "./Table.css";
import OrderStatistics from "./OrderStatistics";
import usePermissions from "../../hooks/usePermissions";
import { source } from "framer-motion/client";
import ImportLeadsModal from "./ImportLeadsModal";
const { Option } = Select;

const Leads = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [filtersList, setFiltersList] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const { hasPermission } = usePermissions();
  const { Paragraph } = Typography;
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState([]);
  const [sourcesList, setSourcesList] = useState([]);
  const [authorsList, setAuthorsList] = useState([]);
  const [leadDetails, setLeadDetails] = useState({});

  const [statstics, setStatstics] = useState({
    totalLeads: null,
    openLeads: null,
    closedWon: null,
    closedLost: null,
  });
  const filteredData = [];
  const [isFiltersDataFetched, setIsFiltersDataFetched] = useState(false);
  const [isImportLeadsMOdalVisible, setIsImportLeadsModalVisible] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
    showSizeChanger: false,
  });
  const options = [];
  if (hasPermission("can_view_all_leads")) {
    options.push({ value: "allLeads", label: "All Leads" });
  }
  if (hasPermission("can_view_assigned_leads")) {
    options.push({ value: "assignedLeads", label: "Assigned Leads" });
  }
  if (hasPermission("can_view_managed_leads")) {
    options.push({ value: "managedLeads", label: "Manage Leads" });
  }

  const [showFilters, setShowFilters] = useState(false);

  const [selectedUids, setSelectedUids] = useState([]);
  const [filters, setFilters] = useState({});
  const [assignees, setAssignees] = useState([]);
  const [managers, setManagers] = useState([]);
  const [filteredAssignees, setFilteredAssignees] = useState([]);
  const [filteredmanagers, setFilteredManagers] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  // Active till states
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [assigneeActiveTill, setAssigneeActiveTill] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [selectedAssignee, setSelectedAssignee] = useState("");
  const [selectedManager, setSelectedManager] = useState("");
  const [managerActiveTill, setManagerActiveTill] = useState("");
  // const defaultLeadType = hasPermission("can_view_assigned_leads")
  //   ? "assignedLeads"
  //   : "allLeads";
  const defaultLeadType = hasPermission("can_view_all_leads")
    ? "allLeads"
    : hasPermission("can_view_assigned_leads")
    ? "assignedLeads"
    : "managedLeads";

  const [leadType, setLeadType] = useState(defaultLeadType);
  // Handler for Assignee Active Till Date

  const handleAssigneeActiveTillChange = (date) => {
    setAssigneeActiveTill(
      date ? dayjs(date).format("YYYY-MM-DD HH:mm:ss") : null
    );
  };

  // Handler for Manager Active Till Date
  const handleManagerActiveTillChange = (date) => {
    setManagerActiveTill(
      date ? dayjs(date).format("YYYY-MM-DD HH:mm:ss") : null
    );
  };

  // Updated handleSubmitAssignee to accept 'data' as a parameter
  const handleSubmitAssignee = async (data) => {
    try {
      setIsFetching(true);

      if (!data.uids || data.uids.length === 0) {
        message.error("Please select leads to assign");
        setIsFetching(false);
        return; // Exit early if no leads selected
      }
      console.log(data, "data");
      const requestData = {
        ...data,
        ...(assigneeActiveTill && assigneeActiveTill.trim() !== ""
          ? { assignee_active_till: assigneeActiveTill }
          : {}),
      };
      console.log(requestData, "requestData");
      const result = await LeadsList.updateAssignee(requestData);

      if (result.success) {
        message.success("Assigned to assignee successfully");
        // setShowFilters(false)
        // setFilters({});
        await fetchData(pagination.current, pagination.pageSize);
        setSelectedUids([]);
        setModalType(null);
      } else {
        throw new Error("Failed to assign assignee");
      }
    } catch (error) {
      console.error("Error assigning assignee:", error);
      message.error("Something went wrong while assigning the assignee!");
    } finally {
      setIsFetching(false);
    }
  };

  const handleAssignManager = async (managerData) => {
    try {
      setIsFetching(true);

      if (!managerData.uids || managerData.uids.length === 0) {
        message.error("Please select leads to assign");
        setIsFetching(false);
        return; // Exit early if no leads selected
      }
      const requestData = {
        ...managerData,
        ...(managerActiveTill !== null && {
          manager_active_till: managerActiveTill,
        }),
      };
      const result = await LeadsList.updateManager(requestData);

      if (result.success) {
        message.success("Assigned to manager successfully");
        // setShowFilters(false)
        // setFilters({});
        await fetchData(pagination.current, pagination.pageSize);
        setSelectedUids([]);
        setModalType(null);
      } else {
        throw new Error("Failed to assign manager");
      }
    } catch (error) {
      console.error("Error assigning manager:", error);
      message.error("Something went wrong while assigning the manager!");
    } finally {
      setIsFetching(false);
    }
  };

  const handleSuccess = (data) => {
    console.log("Lead created successfully:", data);
  };
  const handleModalSubmit = async (values) => {
    // Check selectedUids length before proceeding
    if (selectedUids.length === 0) {
      message.error("Please select leads to assign");
      return;
    }

    setIsFetching(true);

    try {
      if (modalType === "assignee") {
        const data = {
          uids: selectedUids,
          assignee_uid: selectedAssignee,
          ...(assigneeActiveTill && {
            assignee_active_till: assigneeActiveTill,
          }),
        };
        await handleSubmitAssignee(data);
      } else if (modalType === "manager") {
        const data = {
          uids: selectedUids,
          manager_uid: selectedManager,
          ...(managerActiveTill && {
            active_till: managerActiveTill,
          }),
        };
        await handleAssignManager(data);
      }

      handleModalClose();
    } catch (error) {
      console.error("Error in modal submission:", error);
      message.error("Failed to process the assignment");
    } finally {
      setIsFetching(false);
    }
  };

  const fetchStatusOptions = async () => {
    setIsFiltersDataFetched(true);
    const response = await LeadsList.getStatusDropdownList();
    if (response.success) {
      setIsFiltersDataFetched(false);

      setStatusOptions(response.data);
    } else {
      setIsFiltersDataFetched(false);

      message.error("Failed to fetch statuses.");
    }
  };
  const handleModalOpen = (type) => {
    setModalType(type);
    // Fetch data based on the type ('assignee' or 'manager')
    setModalVisible(true);
  };

  // const handleModalOpen = (type) => {
  //   fetchLists();
  //   setModalType(type);
  //   setModalVisible(true);
  // };

  const handleModalClose = () => {
    setModalVisible(false);
    setModalType(null);
    setSelectedAssignee("");
    setSelectedManager("");
    setManagerActiveTill("");
    setAssigneeActiveTill("");
  };

  const formatOptions = (list) =>
    list.map(({ uid, name }) => ({
      value: uid,
      label: name,
    }));
  useEffect(() => {
    if (showFilters) {
      fetchStatusOptions();
    }
  }, [showFilters]);

  useEffect(() => {
    fetchData(pagination.current, pagination.pageSize);
    fetchLists();
  }, [pagination.current, pagination.pageSize, filters, leadType]);

  const fetchData = useCallback(
    async (page = 1, pageSize = 20) => {
      setLoading(true);

      let result;
      if (leadType === "assignedLeads") {
        result = await LeadsList.fetchAssignedLeads(
          pagination.current,
          pagination.pageSize,
          filters
        );
      } else if (leadType === "allLeads") {
        result = await LeadsList.fetchNewLeads(
          pagination.current,
          pagination.pageSize,
          filters
        );
      } else if (leadType === "managedLeads") {
        result = await LeadsList.fetchManagerLeads(
          pagination.current,
          pagination.pageSize,
          filters
        );
      }

      if (result.success) {
        setFiltersList(result?.data?.data?.filters);
        const formattedData = result?.data?.data?.results.map((item) => ({
          key: item.uid,
          uid: item.uid,
          name: item.name,
          assignedOn: item.assignee_assigned_on,
          managerAssignedOn: item?.manager_assigned_on,
          activeTill: item.assignee_active_till,
          status: item.status,
          nextActivity: item.next_activity,
          assignee: item.assignee,
          manager: item.manager,
          source: item.source,
          created: item?.created,
        }));

        setData(formattedData);
        setStatstics({
          totalLeads: result?.data?.data?.total_assigned_lead,
          openLeads: result?.data?.data?.total_open_lead,
          closedWon: result?.data?.data?.total_closed_won_lead,
          closedLost: result?.data?.data?.total_closed_lost_lead,
          assignedLeads: result?.data?.data?.total_assigned_leads,
          manageLeads: result?.data?.data?.total_managed_leads,
        });
        setPagination({
          ...pagination,
          total: result?.data?.data?.count || 0,
          current: page,
          pageSize,
        });
      }

      setLoading(false);
    },
    [pagination.current, pagination.pageSize, filters, leadType]
  );

  const fetchLists = async () => {
    setIsFiltersDataFetched(true); // Show loader
    setIsFetching(true);
    try {
      const assigneesResult = await LeadsList.fetchAssignees();
      if (assigneesResult.success) {
        const filteredAssignees = assigneesResult.data.filter(
          (assignee) => assignee.uid !== "not-assigned"
        );
        setFilteredAssignees(filteredAssignees);
        setAssignees(assigneesResult.data);
        setIsFetching(false);
      } else {
        message.error("Failed to fetch assignees.");
        setIsFetching(false);
      }

      const managersResult = await LeadsList.fetchManagers();

      if (managersResult.success) {
        setIsFetching(false);
        const filteredManagers = managersResult.data.filter(
          (manager) => manager.uid !== "not-assigned"
        );
        setFilteredManagers(filteredManagers);
        setManagers(managersResult.data);
      } else {
        setIsFetching(false);
        message.error("Failed to fetch managers.");
      }

      const authorsResult = await LeadsList.fetchAuthorsList();
      if (authorsResult.success) {
        setIsFetching(false);

        setAuthorsList(authorsResult?.data?.data);
      } else {
        setIsFetching(false);
        message.error("Failed to fetch managers.");
      }
      const sourcesListResult = await LeadsList.fetchSourceList();
      if (sourcesListResult.success) {
        setIsFetching(false);
        setSourcesList(sourcesListResult?.data);
      } else {
        setIsFetching(false);
        message.error("Failed to fetch managers.");
      }
    } catch (error) {
      setIsFetching(false);
      message.error("Error fetching data.");
    } finally {
      setIsFetching(false);
      setIsFiltersDataFetched(false); // Hide loader
    }
  };

  // const fetchLists = async () => {
  //   setIsFiltersDataFetched(true);
  //   const [assigneesResult, managersResult] = await Promise.all([
  //     LeadsList.fetchAssignees(),
  //     LeadsList.fetchManagers(),
  //   ]);
  //   if (assigneesResult.success) {
  //     setIsFiltersDataFetched(false);
  //     setAssignees(assigneesResult.data);
  //   }
  //   if (managersResult.success) {
  //     setIsFiltersDataFetched(false);
  //     setManagers(managersResult.data);
  //   }
  // };

  const handleSearchClick = (newFilters) => {
    setFilters(newFilters);
    setPagination({ ...pagination, current: 1 });
  };
  const handleCancelSearch = async () => {
    setFilters({});

    setPagination({ ...pagination, current: 1 });
  };
  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  // const handleModalSubmit = async (values) => {
  //   // console.log(values , 'VALUES IS HERE')
  //   const formattedActiveTill = values.activeTill
  //     ? dayjs(values.activeTill).format("YYYY-MM-DD HH:mm:ss")
  //     : null;

  //     console.log(selectedUids , 'sedl')

  //   if (modalType === "assignee") {
  //     const data = {
  //       uids: selectedUids, // Fetch selected UIDs from state
  //       assignee_uid: selectedAssignee, // Selected assignee UID
  //       assignee_active_till: formattedActiveTill,
  //     };
  //     console.log(data, "data");
  //     // if (!data.uids.length) {
  //     //   message.error("Please select leads to assign.");
  //     //   return;
  //     // }
  //     // if (!data.assignee_uid) {
  //     //   message.error("Please select an assignee.");
  //     //   return;
  //     // }

  //     await handleSubmitAssignee(data);
  //   } else if (modalType === "manager") {
  //     const managerData = {
  //       uids: selectedUids, // Fetch selected UIDs from state
  //       manager_uid: selectedManager, // Selected manager UID
  //       active_till: formattedActiveTill,
  //     };

  //     // if (!managerData.uids.length) {
  //     //   message.error("Please select leads to assign.");
  //     //   return;
  //     // }
  //     // if (!managerData.manager_uid) {
  //     //   message.error("Please select a manager.");
  //     //   return;
  //     // }

  //     await handleAssignManager(managerData);
  //   }

  //   handleModalClose(); // Close the modal after successful submission
  // };

  const options2 =
    modalType === "assignee"
      ? filteredAssignees.map((assignee) => ({
          label: assignee.name,
          value: assignee.uid,
        }))
      : filteredmanagers.map((manager) => ({
          label: manager.name,
          value: manager.uid,
        }));

  const handleLeadTypeChange = (value) => {
    setShowFilters(false);
    setLeadType(value);
  };

  return (
    <Flex
      vertical
      justify="center"
      gap="large"
      style={{ height: "100%", padding: "30px 20px" }}>
      <OrderStatistics
        statistics={statstics}
        leadType={leadType}
        loading={loading}
      />
      {showFilters && (
        <CSSTransition
          in={showFilters}
          timeout={300}
          classNames="filter"
          unmountOnExit>
          <SearchFilters
            onSearch={handleSearchClick}
            isFiltersDataFetched={isFiltersDataFetched}
            assignees={assignees}
            filtersList={filtersList}
            managers={managers}
            pagination={pagination}
            statusOptions={statusOptions}
            selectedUids={selectedUids}
            authorsList={authorsList}
            sourcesList={sourcesList}
            onCancel={handleCancelSearch}
          />
        </CSSTransition>
      )}

      <Flex
        gap={isMobile ? "20px" : 0}
        style={{
          margin: 0,
          width: "100%",
          flexDirection: isMobile ? "column-reverse" : "row",
        }}
        align={isMobile ? "flex-start" : "flex-end"}
        justify="space-between">
        <p style={{ margin: 0, padding: 0 }}>
          {" "}
          Showing{" "}
          {Math.min(
            (pagination.current - 1) * pagination.pageSize + 1,
            pagination.total
          )}{" "}
          -
          {Math.min(pagination.current * pagination.pageSize, pagination.total)}{" "}
          of {pagination.total} Leads
        </p>

        <Flex align="center" gap={"middle"} wrap={isMobile ? "wrap" : "nowrap"}>
          {leadType !== "assignedLeads" &&
            hasPermission("can_assign_leads_to_assignee") && (
              <Button
                type="primary"
                style={{ background: "#040724" }}
                onClick={() => handleModalOpen("assignee")}>
                Assign Assignee
              </Button>
            )}

          {leadType !== "assignedLeads" &&
            hasPermission("can_assign_leads_to_manager") && (
              <Button
                type="primary"
                style={{ background: "#040724" }}
                onClick={() => handleModalOpen("manager")}>
                Assign Manager
              </Button>
            )}
          {hasPermission("can_create_lead") && (
            <Button
              type="primary"
              style={{ background: "#040724" }}
              onClick={() => setIsModalVisible(true)}>
              Create Lead
            </Button>
          )}
          {hasPermission("can_create_lead") && (
            <Button
              type="primary"
              style={{ background: "#040724" }}
              onClick={() => setIsImportLeadsModalVisible(true)}>
              Import Leads
            </Button>
          )}

          <ImportLeadsModal
            fetchData={fetchData}
            isVisible={isImportLeadsMOdalVisible}
            onClose={() => setIsImportLeadsModalVisible(false)}
          />

          <CreateLeadForm
            fetchData={fetchData}
            isVisible={isModalVisible}
            onClose={() => setIsModalVisible(false)}
          />
          {/* {hasPermission("can_assign_leads_to_assignee") && (
            <Button
              type="primary"
              style={{ background: "#040724" }}
              onClick={() => handleModalOpen("assignee")}>
              Assign Assignee
            </Button>
          )}

          {hasPermission("can_assign_leads_to_manager") && (
            <Button
              type="primary"
              style={{ background: "#040724" }}
              onClick={() => handleModalOpen("manager")}>
              Assign Manager
            </Button>
          )} */}

          <Select
            value={leadType}
            onChange={handleLeadTypeChange}
            options={options}
            style={{ width: "180px", textAlign: "left" }}
            customStyles={{
              backgroundColor: "black", // Custom background color for dropdownas
            }}
          />

          {/* <CustomSelect
            value={leadType}
            onChange={handleLeadTypeChange}
            options={options}
            customStyles={{
              backgroundColor: "black", // Custom background color for dropdownas
            }}
          /> */}

          <Button
            type="primary"
            icon={<FilterOutlined />}
            onClick={toggleFilters}
            style={{ background: "#040724", width: "40px" }}
          />
        </Flex>
        <Modal
          loading={isFetching}
          className="custom-modal"
          visible={modalVisible}
          onClose={handleModalClose}
          title={
            <Flex justify="space-between" className="custom-modal-header">
              <Typography.Title
                level={3}
                style={{
                  color: "white",
                  margin: 0,
                  position: "relative",
                  marginLeft: "10px",
                }}>
                {modalType === "assignee"
                  ? "Assign Assignee"
                  : "Assign Manager"}
              </Typography.Title>
              <CloseOutlined
                style={{
                  fontSize: "16px",
                  marginRight: "10px",
                  color: "white",
                }}
                onClick={handleModalClose}
              />
            </Flex>
          }
          onCancel={handleModalClose}
          onOk={handleModalSubmit}
          okText="Submit"
          closeIcon={false}
          cancelButtonProps={{ style: { display: "none" } }}
          confirmLoading={loading}>
          <Select
            showSearch
            options={options2} // Use predefined options directly
            placeholder={
              modalType === "assignee" ? "Select Assignee" : "Select Manager"
            }
            style={{ width: "100%", marginBottom: "16px" }}
            onChange={(value) => {
              if (modalType === "assignee") {
                setSelectedAssignee(value);
              } else if (modalType === "manager") {
                setSelectedManager(value);
              }
            }}
            value={
              modalType === "assignee"
                ? selectedAssignee || undefined
                : selectedManager || undefined
            }
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
          />

          {modalType === "assignee" && (
            <DatePicker
              style={{ width: "100%" }}
              placeholder="Select Assignee Active Till"
              showTime
              format="YYYY-MM-DD HH:mm:ss"
              value={assigneeActiveTill ? dayjs(assigneeActiveTill) : null}
              onChange={handleAssigneeActiveTillChange}
            />
          )}

          {modalType === "manager" && (
            <DatePicker
              style={{ width: "100%", marginTop: "10px" }}
              placeholder="Select Manager Active Till"
              showTime
              format="YYYY-MM-DD HH:mm:ss"
              value={managerActiveTill ? dayjs(managerActiveTill) : null}
              onChange={handleManagerActiveTillChange}
            />
          )}
        </Modal>
      </Flex>

      <LeadsTable
        fetchData={fetchData}
        data={filteredData.length > 0 ? filteredData : data}
        leadDetails={leadDetails}
        loading={loading}
        onSearch={handleSearchClick}
        pagination={pagination}
        setPagination={setPagination}
        selectedUids={selectedUids}
        setSelectedUids={setSelectedUids}
        leadType={leadType}
      />
    </Flex>
  );
};

export default Leads;
