import React, { useEffect, useState } from "react";
import {
  Form,
  Select,
  Input,
  Button,
  DatePicker,
  message,
  Row,
  Col,
  Spin,
} from "antd";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import axiosInstance from "../../services/axiosInstance";
import { catchError } from "../../utils/errorHandler";
import orderService from "../../services/OrderLists";
const { Option } = Select;

const AssignSubscriber = ({ orderData, setShowAssign, setFormSubmit, fetchOrderDetails }) => {

  const start_date = orderData?.data?.start_date;
  const no_of_word = orderData?.data?.writer_word_count || "";
  const deadline = orderData?.data?.deadline_hard
    ? dayjs(orderData.data.deadline_hard)
    : null;

  const [userTypesData, setUserTypesData] = useState([]);
  const [subscriberList, setSubscriberList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(deadline);

  const [formData, setFormData] = useState({
    userType: null,
    subscriber: null,
    no_of_word,
    deadline,
  });

  const { orderKey } = useParams();

  const fetchUserTypes = async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get("/task/v2/user-types/");
      if (res?.data?.status === "success") {
        setUserTypesData(res?.data?.data);
      }
    } catch (err) {
      console.error("Error fetching user types:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserTypes();
  }, []);

  const handleUserTypeChange = (selectedUserType) => {
    const selectedUserTypeData = userTypesData.find(
      (item) => item.user_type === selectedUserType
    );
    if (selectedUserTypeData) {
      const users = selectedUserTypeData.users;
      setSubscriberList(users);
      setFormData((prev) => ({
        ...prev,
        subscriber: users.length > 0 ? null : null,
      }));
    } else {
      setSubscriberList([]);
      setFormData((prev) => ({ ...prev, subscriber: null }));
    }
  };

  const handleInputChange = (changedValues) => {
    setFormData((prev) => ({
      ...prev,
      ...changedValues,
    }));
  };

  const handleFinish = async () => {
    const { subscriber, no_of_word, deadline } = formData;
    setLoading(true);

    const postData = {
      subscriber,
      no_of_word,
      deadline: deadline ? deadline.toISOString() : null,
    };
    try {
      const res = await axiosInstance.post(
        `/task/v2/assign-subscribers/${orderKey}/`,
        postData
      );
      if (res?.data?.status === "success") {
        await orderService.getSubscriberInfo(orderKey);
        message.success(res?.data?.data);
        setShowAssign(false);
        setFormSubmit(true);
        fetchOrderDetails()
      } else {
        message.error("Failed to assign subscriber.");
      }
    } catch (error) {
      await catchError(error);
      console.error("Error assigning subscriber:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    handleInputChange({ deadline: date });
  };

  // Calculate min and max dates for the date picker
  const minDate = dayjs().startOf('day');
  const maxDate = dayjs().add(1, 'year'); // Restrict to 1 year from now

  return (
    <div
      style={{
        marginTop: "0px",
        marginBottom: "20px",
        borderRadius: "10px",
        backgroundColor: "#D3D3D3",
        padding: "10px",
      }}>
      <Form
        layout="vertical"
        onFinish={handleFinish}
        initialValues={{
          ...formData,
          deadline: selectedDate
        }}
      >
        <Row
          style={{
            flexDirection: window.innerWidth < 768 ? "column" : "row",
            width: "100%",
          }}
          gutter={24}>
          <Col order={1} lg={6}>
            <Form.Item
              name="userType"
              label="User Type"
              rules={[
                { required: true, message: "Please select a user type!" },
              ]}>
              <Select
                placeholder="Select User Type"
                onChange={(value) => {
                  handleUserTypeChange(value);
                  handleInputChange({ userType: value });
                }}
                style={{ width: "100%" }}>
                {loading ? (
                  <Option disabled value="loading">
                    <Spin size="small" tip="Loading..." />
                  </Option>
                ) : (
                  userTypesData?.map((item) => (
                    <Option key={item.user_type} value={item.user_type}>
                      {item.user_type}
                    </Option>
                  ))
                )}
              </Select>
            </Form.Item>
          </Col>

          <Col order={2} lg={6}>
            <Form.Item
              name="subscriber"
              label="Subscriber"
              rules={[
                { required: true, message: "Please select a subscriber!" },
              ]}>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.children || "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                placeholder="Select Subscriber"
                onChange={(value) => {
                  handleInputChange({ subscriber: value });
                }}>
                {subscriberList?.map((subscriber) => (
                  <Option key={subscriber.username} value={subscriber.username}>
                    {subscriber.full_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col order={3} lg={6}>
            <Form.Item
              name="deadline"
              label="Deadline"
              rules={[
                { required: true, message: "Please select a deadline!" },
              ]}>
              <DatePicker
                value={selectedDate}
                // disabledDate={(current) =>
                //   current && (current < start_date || current > deadline)
                // }
                style={{ width: window.innerWidth < 768 ? "375px" : "100%" }}
                showTime={{
                  format: "HH:mm",
                  use12Hours: true
                }}
                format="YYYY-MM-DD hh:mm A"
                onChange={handleDateChange}
              // popupStyle={{ position: 'fixed' }}
              />
            </Form.Item>
          </Col>

          <Col order={4} lg={6}>
            <Form.Item
              name="no_of_word"
              label="No of Words"
              rules={[
                {
                  required: true,
                  message: "Please enter the number of words!",
                },
              ]}>
              <Input
                type="number"
                placeholder="Enter number of words"
                onChange={(e) => {
                  handleInputChange({ no_of_word: e.target.value });
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          style={{
            display: "flex",
            margin: "0px",
            justifyContent: "flex-end",
            width: "100%",
            gap: "10px",
          }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            style={{ width: "auto" }}>
            {loading ? "Loading..." : "Assign Subscriber"}
          </Button>
          <Button
            type="default"
            onClick={() => {
              setShowAssign(false);
            }}
            style={{ width: "auto", marginLeft: "10px" }}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AssignSubscriber;