import React, { useState,useEffect, useMemo, useCallback } from "react";
import { Input,Table, Tooltip, Avatar, Popover, Radio, Space, Button, Tag, Modal, message, Flex, Typography, Form, Spin } from "antd";
import {
  EyeFilled,
  CheckCircleOutlined,
  CloseCircleOutlined,
  EyeInvisibleFilled,
  EditOutlined,
} from "@ant-design/icons";
import ClientTabs from "./ClientTabs";
import "./AllClientsTableSummary.css";
import clientService from "../../services/clientService";
import usePermissions from "../../hooks/usePermissions";
import dayjs from "dayjs";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import ClientsMobileView from "./ClientsMobileView";
import { catchError } from "../../utils/errorHandler";
import axiosInstance from "../../services/axiosInstance";
import PhoneInput from "react-phone-input-2";

// import { useLocalTime } from './hooks/useLocalTime'; // Assuming you have this hook
const AllClientsTableSummary = ({
  allclients,
  loading,
  pagination,
  setPagination,
  viewMode,
  getMyClients,
  getAllClients
}) => {
  const { Title } = Typography;
  const { hasPermission } = usePermissions();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [clientDetails, setClientDetails] = useState({});
  const [cllientUsername, setClientUsername] = useState();
  const [detailsLoading, setDetailsLoading] = useState({});

  //opening a modal for OTP verification status chnage
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectFlag, setSelectedFlag] = useState(null);
  const [buttonloading, setLoading] = useState(false);

  const handleEditClick = (record) => {
    setSelectedRecord(record?.uid);
    setIsModalVisible(true);
    setSelectedFlag(record?.bypass_otp_verification);
  };
  
  const handleConfirm = async () => {
    setLoading(true);
    if (selectedRecord?.bypass_otp_verification) setIsModalVisible(false);
    const flag = selectFlag === false ? true : false;
    
    try {
      const res = await axiosInstance.post(
        `client/v2/bypass-otp-verfication/${selectedRecord}/`,
        {
          flag: flag,
        }
      );
      if (res.status === 200) {
        message.success(res.data.data);
        if (viewMode === "myClients") {
          getMyClients();
        } else {
          getAllClients();
        }
        handleCancel();
        setLoading(false);
      }
    } catch (err) {
      await catchError(err);
      setLoading(false);
    }
  };
  
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  
  //opening Modal for call _ Phone Number verifications
  const [calllLoading, setCallLoading] = useState(false);
  const [isOtpModalVisible, setIsOtpModalVisible] = useState(false);
  const [saveContact, setSaveContact] = useState("");
  const [showInputOtp, setSHowInputOtp] = useState(false);
  const [submitData, setSubmitData] = useState({ method: "whatsapp" });

  const [timeLeft, setTimeLeft] = useState(30);
  const [isDisabled, setIsDisabled] = useState(true);

  const handleOpenOtpClick = (mobileNomber) => {
    setSaveContact(mobileNomber?.contact_no);
    setIsOtpModalVisible(true);
  };

  const handleOtpCancel = () => {
    setIsOtpModalVisible(false);
    setSHowInputOtp(false);
  };

  const handleOtp = async () => {
    setCallLoading(true);
    try {
      const payload = {
        phone_number: saveContact,
        method: submitData?.method,
      };

      const res = await axiosInstance.post("useraccount/v2/otp/send/", payload);
      if (res.status === 200) {
        message.success("OTP Sent");
        setSHowInputOtp(true);
        setCallLoading(false);
      }
     } catch (err) {
      await catchError(err);
      setCallLoading(false);
    }
  };

  const handleOtpSubmit = async () => {
    setCallLoading(true);
    try {
      const payload = {
        otp: submitData?.otp,
        phone_number: saveContact,
      };
      const res = await axiosInstance.post(
        "/useraccount/v2/otp/verify/",
        payload
      );
      console.log("res", res);
      if (res.status === 200) {
        if (viewMode === "myClients") {
          getMyClients();
        } else {
          getAllClients();
        }
        message.success(res.data.data);
        handleOtpCancel();
      }
    } catch (err) {
      setCallLoading(false);
      await catchError(err);
    }
  };

  useEffect(() => {
    if (showInputOtp && timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else if (timeLeft === 0) {
      setIsDisabled(false);
    }
  }, [timeLeft, showInputOtp]);

  useEffect(() => {
    if (!showInputOtp) {
      setTimeLeft(30);
      setIsDisabled(true);
    }
  }, [showInputOtp]);

  // ---------------------


  const handleUpdateContact = async (uid, values) => {

    const payload = {
      uid: uid,
      phone_number: values?.update.startsWith("+") ? values.update : `+${values.update}`,
    };
    
    console.log(payload);
    

    try {
      const res = await axiosInstance.post(
        `useraccount/v2/client-update-phone-number/`,
        payload
      );
      if (res.status && res?.data?.status) {
        window.location.reload();
        setTimeout(()=>{
          message.success(res?.data?.data);
        },10000)
      }
    } catch (err) {
      await catchError(err);
    }
  };


  const toggleExpand = useCallback(
    async (record) => {
      const isCurrentlyExpanded = expandedRowKeys.includes(record.uid);

      if (isCurrentlyExpanded) {
        setExpandedRowKeys(expandedRowKeys.filter((key) => key !== record.uid));
      } else {
        setExpandedRowKeys([...expandedRowKeys, record.uid]);
        if (hasPermission("can_view_client_details")) {
          if (!clientDetails[record.uid]) {
            setDetailsLoading((prev) => ({ ...prev, [record.uid]: true }));
            try {
              const response = await clientService.getClientDetail(record.uid);
              if (response.success) {
                setClientUsername(
                  response?.data?.data?.username
                    ? response?.data?.data?.username
                    : "N/A"
                );
                setClientDetails((prevDetails) => ({
                  ...prevDetails,
                  [record.uid]: response?.data?.data?.payment_links || [],
                }));
              }
            } catch (error) {
              console.error("Error fetching client details:", error);
            } finally {
              setDetailsLoading((prev) => ({ ...prev, [record.uid]: false }));
            }
          }
        }
      }
    },
    [expandedRowKeys, clientDetails]
  );

  const renderVisibilityAvatars = (visibilityArray) => {
    if (!visibilityArray || visibilityArray.length === 0) return "N/A";

    const displayedAvatars = visibilityArray.slice(0, 2);
    const remainingAvatars = visibilityArray.slice(2);

    return (
      <Avatar.Group maxCount={3}>
        {displayedAvatars.map((name, index) => (
          <Tooltip key={index} title={name}>
            <Avatar style={{ backgroundColor: "#87d068" }}>
              {name.charAt(0).toUpperCase()}
            </Avatar>
          </Tooltip>
        ))}
        {remainingAvatars.length > 0 && (
          <Popover
            content={
              <div>
                {remainingAvatars.map((name, index) => (
                  <p key={index}>{name}</p>
                ))}
              </div>
            }
            title="Additional Visibility"
          >
            <Avatar style={{ backgroundColor: "#f56a00" }}>
              +{remainingAvatars.length}
            </Avatar>
          </Popover>
        )}
      </Avatar.Group>
    );
  };

  const columns = useMemo(() => {
    if (allclients.length === 0) return [];

    const sampleClient = allclients[0];
    const dynamicColumns = Object.keys(sampleClient).reduce((acc, key) => {
      if (
        ![
          "uid",
          "name",
          "created_at",
          "email",
          "contact_no",
          "ownership",
          "visibility",
          "website",
          "inactive_since_last_quarter",
          "bypass_otp_verification",
          "is_contact_verified",
        ].includes(key)
      ) {
        acc.push({
          title: key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, " "),
          dataIndex: key,
          key,
          render: (text) => text || "n/a",
        });
      }
      return acc;
    }, []);

    dynamicColumns.unshift({
      title: "Full Name",
      key: "full_name",
      render: (text, record) => {
        if (!record.name) return null;
        const isExpanded = expandedRowKeys.includes(record.uid);

        const formattedDate = moment(record.created_at)
          .local()
          .format("DD-MM-YYYY hh:mm A"); // Get the formatted date here

        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {hasPermission("can_view_client_details") &&
                (isExpanded ? (
                  <EyeInvisibleFilled
                    style={{ cursor: "pointer", marginRight: "6px" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleExpand(record);
                    }}
                  />
                ) : (
                  <EyeFilled
                    style={{ cursor: "pointer", marginRight: "6px" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleExpand(record);
                    }}
                  />
                ))}
              <p style={{ margin: 0 }}>{record.name}</p>
            </div>
            <span style={{ opacity: 0.5, fontSize: "12px" }}>
              {formattedDate ? formattedDate : "N/A"}{" "}
              {/* Use the formatted date */}
            </span>
          </div>
        );
      },
    });

    dynamicColumns.push({
      title: "Contact",
      key: "contact",
      width: 170,
      render: (text, record) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p style={{ margin: 0 }}>{record.email || "N/A"}</p>
          <div>
            <span style={{ opacity: 0.5, fontSize: "12px" }}>
              {record.contact_no || "N/A"}
            </span>
           {viewMode ==="myClients" && record?.is_contact_verified === false && <Popover
              placement="topLeft"
              content={
                <Form
                  layout="vertical"
                  onFinish={(value) => handleUpdateContact(record?.uid, value)}
                  initialValues={{ update: record?.contact_no }}
                >
                  <Form.Item name="update">
                    <PhoneInput
                      inputStyle={{
                        width: "220px",
                        borderRadius: "4px",
                        height: "32.5px",
                      }}
                      containerStyle={{
                        borderRadius: "4px",
                        marginLeft: "10px",
                      }}
                      country={"au"}
                      placeholder={"Update Contact"}
                      disableDropdown={true}
                      value={record?.contact_no} // Ensure the field has initial value
                      onChange={(phone) => {
                        console.log(phone);  
                      }}
                    />
                  </Form.Item>

                  <div
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "10px",
                    }}
                  >
                    <Button htmlType="submit" type="primary">
                     Update 
                    </Button>
                  </div>
                </Form>
              }
              trigger="click"
            >
              <Button
                type="link"
                icon={
                  <EditOutlined
                    style={{ color: "#1890ff", fontSize: "16px" }}
                  />
                }
              />
            </Popover>}
          </div>
        </div>
      ),
    });

    dynamicColumns.push({
      title: "Ownership",
      key: "ownership",
      render: (text, record) => record.ownership || "N/A",
    });

    dynamicColumns.push({
      title: "Visibility",
      key: "visibility",
      render: (text, record) => renderVisibilityAvatars(record.visibility),
    });

    dynamicColumns.push({
      title: "Bypass OTP Verification",
      key: "bypass_otp_verification",
      width: "100px",
      render: (text, record) => {
        return (
          <Space size={0}>
            <Tag
              color={
                record?.bypass_otp_verification === false ? "volcano" : "green"
              }
            >
              {record?.bypass_otp_verification === false
                ? "Disabled"
                : "Enabled"}
            </Tag>
            {hasPermission("can_bypass_otp_verification_clientwise") && (
              <Button
                type="link"
                icon={
                  <EditOutlined
                    style={{ color: "#1890ff", fontSize: "16px" }}
                  />
                }
                onClick={() => handleEditClick(record)}
              />
            )}
          </Space>
        );
      },
    });

    dynamicColumns.push({
      title: "Contact Verified",
      key: "is_contact_verified",
      render: (text, record) => {
        return (
          <Space size={0}>
            <Tag
              color={record?.is_contact_verified === true ? "green" : "volcano"}
            >
              {record?.is_contact_verified === true ? "Yes" : "No"}
            </Tag>
            {/* //remove the edit Button for the AllClients Tab */}
             {viewMode ==="myClients" && record?.is_contact_verified == false && (
              <Button
                type="link"
                icon={<EditOutlined />}
                onClick={() => handleOpenOtpClick(record)}
              />
            )}
          </Space>
        );
      },
    });

    // Conditionally add the "Website" column if all clients have the website field
    const allClientsHaveWebsite = allclients.every(
      (client) => "website" in client
    );
    if (allClientsHaveWebsite) {
      dynamicColumns.push({
        title: "Website",
        key: "website",
        render: (text, record) => <span>{record.website || "N/A"}</span>,
      });
    }

    dynamicColumns.push({
      title: "Status",
      key: "status",
      render: (text, record) => {
        return record.inactive_since_last_quarter === "Active" ? (
          <Tooltip title="Active">
            <CheckCircleOutlined style={{ color: "green" }} />
          </Tooltip>
        ) : (
          <Tooltip title="Inactive since last Quarter">
            <CloseCircleOutlined style={{ color: "red" }} />
          </Tooltip>
        );
      },
    });

    return dynamicColumns;
  }, [allclients, expandedRowKeys]);
  const mobileViewData = allclients.map((client) => ({
    uid: client.uid,
    name: client.name,
    email: client.email || "N/A",
    username: client.username,
    contactNo: client.contact_no || "N/A",
    ownership: client.ownership || "N/A",
    visibility: client.visibility || [],
    website: client.website || "N/A",
    status: client.inactive_since_last_quarter || "N/A",
    created: client.created_at || "N/A",
    bypass_otp_verification: client.bypass_otp_verification,
    is_contact_verified: client?.is_contact_verified,
  }));
  
  return (
    <div style={{ width: "100%" }}>
      {isMobile ? (
        <ClientsMobileView
          data={mobileViewData}
          clientDetails={clientDetails}
          getDetails={toggleExpand}
          loading={loading}
          pagination={pagination}
          setPagination={setPagination}
          setIsModalVisible={setIsModalVisible}
          setSelectedRecord={setSelectedRecord}
          setSelectedFlag={setSelectedFlag}
          setIsOtpModalVisible={setIsOtpModalVisible}
          setSaveContact={setSaveContact}
          viewMode={viewMode}
        />
      ) : (
        <Table
          style={{ width: "100%" }}
          columns={columns}
          dataSource={allclients}
          expandable={{
            expandedRowRender: (record) => (
              <div>
                <ClientTabs
                  uid={record.uid}
                  username={cllientUsername}
                  clientDetails={clientDetails[record.uid] || []}
                />
              </div>
            ),
            expandedRowKeys,
            onExpand: (expanded, record) => toggleExpand(record),
            expandIconColumnIndex: -1,
            rowExpandable: (record) => record.uid !== "Not Expandable",
          }}
          rowClassName={(record) =>
            expandedRowKeys.includes(record.uid) ? "expanded-row" : ""
          }
          loading={loading}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            onChange: (page, pageSize) => {
              setPagination({ ...pagination, current: page, pageSize });
            },
            onShowSizeChange: (current, size) => {
              setPagination({ ...pagination, current: 1, pageSize: size });
            },
          }}
          rowKey="uid"
        />
      )}

      <Modal
        title="OTP Bypass Verification"
        visible={isModalVisible}
        onOk={handleConfirm}
        onCancel={handleCancel}
        okText="Yes"
        cancelText="Close"
        loading={buttonloading}
      >
        <p>Are you sure you want to change OTP Verification status?</p>
      </Modal>

      <Modal
        className="custom-modal"
        title={
          <Flex justify="space-between" className="custom-modal-header">
            <Title
              level={3}
              style={{
                color: "white",
                margin: 0,
                position: "relative",
                marginLeft: "10px",
              }}
            >
              Contact Verification
            </Title>
          </Flex>
        }
        onCancel={handleOtpCancel}
        visible={isOtpModalVisible}
        // onOk={showInputOtp ? handleOtpSubmit : handleOtp}
        // okText={showInputOtp ? "Verify OTP" : "Send OTP"}
        cancelText="Close"
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={showInputOtp ? handleOtpSubmit : handleOtp}
          >
            {showInputOtp ? "Verify OTP" : "Send OTP"}
          </Button>,
        ]}
        loading={calllLoading}
      >
        <Flex
          vertical
          gap={15}
          style={{ alignItems: "center", textAlign: "center" }}
        >
          <Typography.Text style={{ fontSize: "16px" }}>
            {!showInputOtp ? "OTP will be sent to" : "OTP Sent to "}{" "}
            <span style={{ fontWeight: "bold" }}>{saveContact || ""}</span>
          </Typography.Text>

          {!showInputOtp ? (
            <>
              <Typography.Text>
                Select how you want to receive the OTP
              </Typography.Text>
              <Flex gap={20}>
                <Radio.Group
                  onChange={(e) =>
                    setSubmitData({ ...submitData, method: e.target.value })
                  }
                  value={submitData.method}
                >
                  <Radio value="whatsapp">WhatsApp</Radio>
                  <Radio value="sms">SMS</Radio>
                </Radio.Group>
              </Flex>
            </>
          ) : (
            <div>
              <Input
                maxLength={6}
                placeholder="Enter OTP"
                style={{
                  width: "180px",
                  textAlign: "center",
                  marginTop: "10px",
                }}
                onChange={(e) =>
                  setSubmitData({ ...submitData, otp: e.target.value })
                }
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "20px",
                }}
              >
                <Button onClick={handleOtp} disabled={isDisabled}>
                  {isDisabled ? `Resend OTP in ${timeLeft} sec` : "Resend OTP"}
                </Button>
              </div>
            </div>
          )}

          <Flex
            justify="center"
            gap={20}
            style={{ width: "100%", marginTop: "10px" }}
          >
            <Tooltip
              placement="bottom"
              title="Phone number verification is required to download the order files."
            >
              <Typography.Link
                style={{ color: "#D3D3D3" }}
                onClick={handleOtpCancel}
              >
                Skip Verification
              </Typography.Link>
            </Tooltip>
          </Flex>
        </Flex>
      </Modal>
    </div>
  );
};

export default AllClientsTableSummary;
