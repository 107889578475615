import React from "react";
import "./FixedDetails.css";
import dayjs from "dayjs";
import { Alert, Col, Row } from "antd";
import moment from "moment";
import useLocalTime from "../../hooks/useLocalTime";
const FixedDetails2 = ({ orderData }) => {

  const formatDate = (date) => {
    
    return moment(date).local().format("DD MMM YYYY, hh:mm A");
  };

  const parseDate = (dateStr) => {
    return dayjs(dateStr, ["YYYY-MM-DD", "YYYY/MM/DD", "MM-DD-YYYY", "MM/DD/YYYY"], true); // Add other formats as needed
  };
  
  // const calculateDaysLeft = (start, end) => {
  //   const startDate = parseDate(start);
  //   const endDate = parseDate(end);
  // console.log(startDate , endDate)
  //   if (!startDate.isValid() || !endDate.isValid()) {
  //     console.error("Invalid dates:", start, end);
  //     return 0;
  //   }
  
  //   return endDate.diff(startDate, 'day'); // Calculate difference in days
  // };
  
  // const timeleft = calculateDaysLeft(
  //   orderData?.data?.start_date,
  //   orderData?.data?.deadline_hard
  // );

    // Get deadline info including both title and value
    const getDeadlineInfo = () => {
      const { deadline_hard, alloted_deadline } = orderData?.data || {};
      
      // If both deadlines exist, show alloted deadline
      if (deadline_hard && alloted_deadline) {
        return {
          title: 'Client Deadline',
          value: formatDate(deadline_hard)
        };
      }
      
      // If only hard deadline exists, show it
      if (deadline_hard) {
        return {
          title: 'Client Deadline',
          value: formatDate(deadline_hard)
        };
      }
      
      // If only alloted deadline exists, show it
      if (alloted_deadline) {
        return {
          title: 'Alloted Deadline',
          value: formatDate(alloted_deadline)
        };
      }
      
      // If no deadline exists
      return {
        title: 'Deadline',
        value: 'No deadline set'
      };
    };
  
    const deadlineInfo = getDeadlineInfo();
  


  const data = {
    orderID: orderData?.data?.key,
    wordCount: orderData?.data?.writer_word_count,
    startDate: moment(orderData?.data?.start_date).local().format("DD MMM YYYY, hh:mm A"),
    deadline: deadlineInfo.value,


    // timeleft: `${timeleft} days`,
  };
const cards = [
  {
    key: 'orderID',
    title: 'Order ID',
    value: data.orderID,
    image: 'https://i.ibb.co/HVPjZTm/credit-card-17636356.png'
  },
  {
    key: 'wordCount',
    title: 'Writer Word Count',
    value: data.wordCount,
    image: 'https://i.ibb.co/sRGqhHD/images.png'
  },
  {
    key: 'startDate',
    title: 'Start Date',
    value: data.startDate,
    image: 'https://i.ibb.co/67Jm354B/6428769.png'
  },
  {
    key: 'deadline',
    title: deadlineInfo.title, // Dynamic title based on deadline type
    value: data.deadline,
    image: 'https://i.ibb.co/Hg0N8JP/Whats-App-Image-2024-10-07-at-10-44-20-AM.jpg'
  }
];
  return (
    <div className="parentdiv">
      <Row gutter={[16, 16]} justify="space-between">
        {cards.map(({ key, title, value, image }) => (
          <Col xs={24} sm={12} md={12} lg={6} key={key}>
            <div className="key">
              <div className="value_container">
                <div className="static_field">{title}</div>
                <div className="value">{value}</div>
              </div>
              <div className="image_container">
                <img
                  className="image_inner"
                  src={image}
                  alt={title}
                />
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default FixedDetails2;
