import React, { useState, useEffect } from "react";
import {
    Table,
    Tabs,
    Tag,
    Typography,
    Space,
    Tooltip,
    Flex,
    Avatar,
    Popover,
    Card,
    Button,
} from "antd";
import moment from "moment";
import { Link } from "react-router-dom";

const { TabPane } = Tabs;
const { Text } = Typography;

const calculateTimeElapsed = (accepted_on, created_at) => {

    if (!created_at) return "N/A"; // If `created_at` is missing, return "N/A"
    let result;

    if (!accepted_on) {
        // If `accepted_on` is null, calculate time from `created_at` to now
        const createdDate = moment(created_at);
        const now = moment();
        const duration = moment.duration(now.diff(createdDate));

        const hours = String(Math.floor(duration.asHours()) % 24).padStart(2, "0");
        const minutes = String(duration.minutes()).padStart(2, "0");
        const seconds = String(duration.seconds()).padStart(2, "0");

        result = `${hours}:${minutes}:${seconds}`;
    } else {
        // If `accepted_on` is present, calculate time from `created_at` to `accepted_on`
        const acceptedDate = moment(accepted_on, "DD-MM-YYYY hh:mm:ss");
        const createdDate = moment(created_at, "DD-MM-YYYY hh:mm:ss");
        const duration = moment.duration(acceptedDate.diff(createdDate));

        const days = Math.floor(duration.asDays());
        const hours = String(Math.floor(duration.asHours()) % 24).padStart(2, "0");
        const minutes = String(duration.minutes()).padStart(2, "0");
        const seconds = String(duration.seconds()).padStart(2, "0");

        result = `${hours}:${minutes}:${seconds}`;
    }

    return result;
};


const AllocationListTable = ({
    orders,
    loading,
    pagination,
    onChange,
    setUrgencyFilter,
}) => {

    const [timeElapsedArray, setTimeElapsedArray] = useState([]);

    const columns = [
        {
            title: "Key",
            dataIndex: "key",
            task_key: "key",
            fixed: "left",
            width: 150,
            render: (text, record) => (
                <Link to={`/order-management/my-orders/${record.key}`}>
                    <Text style={{ color: "black", fontWeight: 600 }}>
                        {text}
                    </Text>
                </Link>
            ),
        },
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
            width: 200,
            render: (text) => (
                <Tooltip title={text}>
                    <Text>
                        {text?.length > 20 ? `${text.substring(0, 20)}...` : text}
                    </Text>
                </Tooltip>
            ),
        },
        {
            title: "Word Count",
            dataIndex: "word_count",
            key: "word_count",
            render: (wordCount) => wordCount || "-",
        },
        {
            title: "Source",
            dataIndex: "source",
            key: "source",
        },
        {
            title: "Start Date",
            dataIndex: "start_date",
            key: "start_date",
            render: (date) => moment(date).format("DD-MM-YYYY hh:mm A"),
        },
        {
            title: "Alloted Deadline",
            dataIndex: "allotted_deadline",
            key: "allotted_deadline",
            render: (date) => moment(date).format("DD-MM-YYYY hh:mm A"),
        },
        {
            title: "Client Deadline",
            dataIndex: "client_deadline",
            key: "client_deadline",
            render: (date) => moment(date).format("DD-MM-YYYY hh:mm A"),
        },
        {
            title: "Accepted By",
            key: "accepted",
            render: (record) =>
                record.accepted_by ? (
                    <Space direction="vertical">
                        <Text>{record.accepted_by}</Text>
                        <Text type="secondary">
                            {moment(record.accepted_on).format("DD-MM-YYYY hh:mm A")}
                        </Text>
                    </Space>
                ) : (
                    <>-</>
                ),
        },
        {
            title: "Pending On",
            dataIndex: "pending",
            key: "pending",
            render: (pending) => {
                if (!pending || pending.length === 0) return "-";

                const displayedSubscribers = pending.slice(0, 2);
                const remainingSubscribers = pending.slice(2);
                return (
                    <Avatar.Group max={{ count: 3 }}>
                        {displayedSubscribers.map((name, index) => (
                            <Tooltip key={index} title={name}>
                                <Avatar style={{ backgroundColor: "#1890ff" }}>
                                    {name.charAt(0).toUpperCase()}
                                </Avatar>
                            </Tooltip>
                        ))}
                        {remainingSubscribers.length > 0 && (
                            <Popover
                                content={
                                    <div style={{ maxHeight: "200px", overflowY: "scroll" }}>
                                        {remainingSubscribers.map((name, index) => (
                                            <p key={index}>{name}</p>
                                        ))}
                                    </div>
                                }
                                title="Additional Pending">
                                <Avatar style={{ backgroundColor: "#f56a00" }}>
                                    +{remainingSubscribers.length}
                                </Avatar>
                            </Popover>
                        )}
                    </Avatar.Group>
                );
            },
        },
        // {
        //     title: "Rejected",
        //     key: "accepted",
        //     render: (record) =>
        //         record.rejected_by ? (
        //             <Flex vertical gap={5}>
        //                 <Flex gap={8} align="center" wrap="wrap">
        //                     <Avatar.Group max={{ count: 3 }}>
        //                         {record.rejected_by.map((rejectedUser, index) => (
        //                             <Tooltip key={index} title={rejectedUser}>
        //                                 <Avatar style={{ backgroundColor: "#f56a00", color: "#fff" }}>
        //                                     {rejectedUser.charAt(0).toUpperCase()}
        //                                 </Avatar>
        //                             </Tooltip>
        //                         ))}
        //                     </Avatar.Group>
        //                 </Flex>
        //                 <Text type="secondary">
        //                     {record.rejected_on ? moment(record.rejected_on).format("DD-MM-YYYY hh:mm A") : "-"}
        //                 </Text>

        //             </Flex>
        //         ) : (
        //             <>-</>
        //         ),
        // },
        {
            title: "Rejections By",
            dataIndex: "rejection_reasons",
            key: "rejection_reasons",
            render: (reasons) =>
                reasons && reasons.length > 0 ? (
                    <Popover
                        title={
                            <div style={{ maxHeight: "300px", overflowY: "auto", padding: "0" }}>
                                {reasons.map((reason, index) => (
                                    <Card key={index} style={{ marginBottom: "8px" }} bodyStyle={{ padding: "5px" }}>
                                        <p><strong>By:</strong> {reason.rejected_by}</p>
                                        <p><strong>Reason:</strong> {reason.rejected_reason}</p>
                                        <p>
                                            <strong>Rejected on:</strong>{" "}
                                            {moment(reason.rejected_on).format("DD MM YYYY h:mm A")}
                                        </p>
                                    </Card>
                                ))}
                            </div>
                        }
                    >
                        <Button type="link" style={{ padding: 0 }}>
                            View
                        </Button>
                    </Popover>
                ) : (
                    "-"
                ),
        },
        {
            title: "Visibility",
            dataIndex: "visiblity",
            key: "visiblity",
            render: (owners) =>
                owners && owners.length > 0 ? (
                    <Avatar.Group max={{ count: 3 }}>
                        {owners.map((owner, index) => (
                            <Tooltip key={index} title={owner}>
                                <Avatar style={{ backgroundColor: "#87d068", color: "#fff" }}>
                                    {owner.charAt(0).toUpperCase()}
                                </Avatar>
                            </Tooltip>
                        ))}
                    </Avatar.Group>
                ) : (
                    "-"
                ),
        },
        {
            title: "Time Elapsed",
            dataIndex: "",
            key: "",
            render: (_, record, index) => (
                <div style={{ color: record.accepted_on === null ? "red" : "green" }}>
                    {timeElapsedArray[index] || "Calculating---"}
                </div>
            ),
        },
        {


            title: "Status",
            dataIndex: "status",
            key: "status",
            fixed: "right",
            width: 150,
            // sorter: (a, b) => {
            //     if (a.status > b.status) return 1;
            //     if (a.status < b.status) return -1;
            //     return 0;
            // },
            // sortDirections: ["ascend", "descend"],
            render: (text) => {
                const statusColors = {
                    Unassigned: "#F0F0F0", // Lighter Gray
                    "Assigned to manager": "#C0C0C0", // Lighter Dark Gray
                    "In Progress": "#D0E4F2", // Lighter Blue
                    "Quality Check": "#A9C3E0", // Lighter Medium Blue
                    Completed: "#C8E6C8", // Lighter Light Green
                    Delivered: "#8FCF8F", // Lighter Medium Green
                    Rejected: "#E8A0A0", // Lighter Soft Red
                    Cancelled: "#A0A0A0", // Lighter Dark Gray
                    Failed: "#D09090", // Lighter Darker Red
                    Feedback: "#C2A892", // Lighter Muted Brown
                };
                return (
                    <Tag
                        style={{ color: 'black', fontWeight: 500 }}
                        color={statusColors[text] || "default"}>
                        {text}
                    </Tag>
                );
            },
        },
    ]

    useEffect(() => {
        const interval = setInterval(() => {
            const updatedElapsedTimes = orders.map((record) =>
                calculateTimeElapsed(record.accepted_on, record.created_at)
            );
            setTimeElapsedArray(updatedElapsedTimes);
        }, 1000); // Updates every second

        return () => clearInterval(interval);
    }, [orders, timeElapsedArray]);


    const handleTabChange = (key) => setUrgencyFilter(key);

    const tabConfig = [
        { key: "red", label: "Deadline Missed", color: "red", bgColor: "#FFCCCB" },
        { key: "orange", label: "Deadline > 1 Day", color: "orange", bgColor: "#FFE5B4" },
        { key: "yellow", label: "Deadline in 2-4 Days", color: "#FACA15", bgColor: "#FFF9C4" },
        { key: "green", label: "Deadline > 4 Days", color: "green", bgColor: "#C8E6C9" },
    ];

    return (
        <div style={{ width: "100%" }}>
            <Tabs onChange={handleTabChange} defaultActiveKey="red">
                {tabConfig.map((tab) => (
                    <TabPane
                        tab={
                            <span
                                style={{
                                    color: "black",
                                    padding: "8px 16px",
                                    borderRadius: "8px 8px 0 0",
                                    backgroundColor: tab.bgColor,
                                }}>
                                {tab.label}
                            </span>
                        }
                        key={tab.key}>
                        <Flex vertical align="flex-end" gap={10} style={{ width: "100%" }}>
                            {!loading && (
                                <p style={{ margin: 0, padding: 0 }}>
                                    Showing{" "}
                                    {Math.min(
                                        (pagination.current - 1) * pagination.pageSize + 1,
                                        pagination.total
                                    )}{" "}
                                    -{" "}
                                    {Math.min(
                                        pagination.current * pagination.pageSize,
                                        pagination.total
                                    )}{" "}
                                    of {pagination.total} Orders.
                                </p>
                            )}

                            <Table
                                style={{ width: "100%" }}
                                columns={columns}
                                dataSource={orders}
                                loading={loading}
                                pagination={pagination}
                                onChange={onChange}
                                scroll={{ x: "max-content" }}
                                rowClassName={(record, index) =>
                                    index % 2 === 0 ? "even-row" : "odd-row"
                                }
                            />
                        </Flex>
                    </TabPane>
                ))}
            </Tabs>
        </div>
    );
};

export default AllocationListTable;
