import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import axiosInstance from '../../services/axiosInstance';
import { Button, message } from 'antd';
import { catchError } from '../../utils/errorHandler';

const ByPassTokkenOrder = ({ handleModalClose, fetchOrderDetails, flagBypass }) => {

    const [loading, setLoading] = useState(false);
    const { orderKey } = useParams();

    const flag = flagBypass === false ? true : false;

    const handleSubmitByPass = async () => {
        setLoading(true)
        try {
            const res = await axiosInstance.post(
                `task/v2/bypass-otp-verfication/${orderKey}/`,
                {
                    flag: flag,
                }
            );
            if (res.status === 200) {
                message.success(res.data.data);
                handleModalClose();
                fetchOrderDetails()
                setLoading(false);
            }
        } catch (err) {
            await catchError(err);
            setLoading(false);
        }
    }
    return (
        <div>
            <div>Are you sure you want to change OTP Verification status?</div>
            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
                <Button
                    className='handle_submit_action_button'
                    onClick={handleSubmitByPass}
                    loading={loading}
                >
                    {loading ? "Loading...." : "Submit"}
                </Button>
            </div>
        </div>
    )
}

export default ByPassTokkenOrder