import React, { useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  message,
  Typography,
  Flex,
  DatePicker,
} from "antd";
import PhoneInput from "react-phone-input-2";
import "./css/ReusableModal.css";
import { CloseOutlined } from "@ant-design/icons";

const { Option } = Select;

const ReusableModal = ({
  visible,
  onClose,
  title,
  onSubmit,
  fields = [],
  confirmLoading = false,
}) => {
  const { Title } = Typography;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(confirmLoading);
  const [selectedRadio, setSelectedRadio] = useState(null);

  const handleRadioChange = (value) => {
    setSelectedRadio(value);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      await onSubmit(values);
      form.resetFields();
      onClose();
    } catch (error) {
      message.error(`Failed to ${title.toLowerCase()}`);
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      className="custom-modal"
      visible={visible}
      title={
        <Flex justify="space-between" className="custom-modal-header">
          <Title
            level={3}
            style={{
              color: "white",
              margin: 0,
              position: "relative",
              marginLeft: "10px",
            }}
          >
            {title}
          </Title>
          <CloseOutlined style={{ fontSize: "16px", marginRight: "10px" }} onClick={onClose} />
        </Flex>
      }
      onCancel={onClose}
      onOk={handleSubmit}
      okText="Submit"
      closeIcon={false}
      cancelButtonProps={{ style: { display: "none" } }}
      confirmLoading={loading}
    >
      <Form form={form} layout="vertical">
        {fields.map((field) => (
          <Form.Item key={field.name} name={field.name} rules={field.rules}>
            {field.type === "input" && (
              <Flex vertical gap={8}>
                <label style={{ marginLeft: "10px" }}>{field.label}</label>
                <Input
                  style={{
                    width: "220px",
                    borderRadius: "4px",
                    marginLeft: "10px",
                  }}
                />
              </Flex>
            )}
            {field.type === "select" && (
              <Flex gap={10}>
                <Flex vertical gap={8}>
                  {" "}
                  <label style={{ marginLeft: "10px" }}>{field.label}</label>
                  <Select
                    onChange={field.onChange || (() => {})}
                    style={{
                      width: "220px",
                      background: "transparent",
                      marginLeft: "10px",
                    }}
                    placeholder={field.label}
                    value={field.value || undefined}
                  >
                    {field.options.map((option) => (
                      <Option key={option.id} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                </Flex>
              </Flex>
            )}
            {field.type === "radio" && (
              <Flex vertical gap={"10px"} style={{ position: "relative" }}>
                <label
                  style={{ color: "black", marginLeft: "10px" }}
                  aria-required
                >
                  {field.label || "Select website"}
                </label>
                <div className="custom-radio-container">
                  {field.options.map((option) => (
                    <div key={option.id} style={{ marginLeft: "10px" }}>
                      <input
                        type="radio"
                        id={`custom-radio-${option.id}`}
                        name={field.name}
                        value={option.value}
                        className="custom-radio-input"
                        checked={selectedRadio === option.value}
                        onChange={() => handleRadioChange(option.value)}
                      />
                      <label
                        htmlFor={`custom-radio-${option.id}`}
                        className="custom-radio-label"
                      >
                        {option.label}
                      </label>
                    </div>
                  ))}
                </div>
              </Flex>
            )}
            {field.type === "phone" && (
              <Flex vertical gap={10}>
                <label style={{ marginLeft: "10px" }}>Phone no.</label>
                <PhoneInput
                  inputStyle={{
                    width: "220px",
                    borderRadius: "4px",
                    height: "32.5px",
                  }}
                  containerStyle={{ borderRadius: "4px", marginLeft: "10px" }}
                  country={"au"}
                  placeholder={field.label}
                />
              </Flex>
            )}
            {field.type === "datepicker" && (
              <Flex vertical gap={8} style={{ marginLeft: "14px" }}>
                <label style={{ marginLeft: "10px" }}>{field.label}</label>
                <DatePicker
                  style={{
                    width: "220px",

                    marginLeft: "12px",
                  }}
                  showTime={field.showTime}
                  format={field.format || "YYYY-MM-DD HH:mm:ss"}
                />
              </Flex>
            )}
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};

export default ReusableModal;
