import axiosInstance from "./axiosInstance";
import { catchError } from "../utils/errorHandler";
import moment from "moment";

const FlOrderDeductionsService = {
  flOrderDeductions: async (
    page = 1,
    pageSize = 10,
    filters = {},
    isDownload = false
  ) => {
    const {
      month,
      year,
      key,
      status,
      hierarchy,
      start_date,
      username,
      fl_name,
    } = filters;

    // ✅ Ensure the download param is only appended when needed
    const queryParams = {
      page,
      page_size: pageSize,
      month: month || moment().format("MM"),
      year: year || moment().format("YYYY"),
      ...(key && { key }),
      ...(status && { status }),
      ...(hierarchy && { hierarchy }),
      ...(start_date && { start_date }),
      ...(isDownload ? { download: "true" } : {}), // ✅ Append download only when requested
      ...(username && { username }),
      ...(fl_name && { fl_name }),
    };

    const queryString = new URLSearchParams(queryParams).toString();

    try {
      const response = await axiosInstance.get(
        `task/v2/fl-order-deduction-list/?${queryString}`,
        {
          responseType: isDownload ? "blob" : "json", // ✅ Only fetch as blob when downloading
        }
      );

      if (isDownload) {
        // ✅ Handle file download
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `order_deductions_${moment().format(
          "YYYYMMDD_HHmmss"
        )}.xlsx`; // ✅ Unique filename
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        return { success: true };
      }

      // ✅ Return data for UI
      if (response.status === 200) {
        return { success: true, data: response.data };
      }

      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  // ✅ New function to apply deduction
  addDeduction: async (uuid, deductionData) => {
    try {
      const response = await axiosInstance.post(
        `task/v2/fl-order-deduction/apply/${uuid}/`,
        deductionData
      );

      if (response.status === 201) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  // ✅ New function to apply deduction
  updatePaymentStatus: async (uuid) => {
    try {
      const response = await axiosInstance.post(
        `task/v2/fl-order-deduction/status-change/${uuid}/`
      );

      if (response.status === 200 || response.status === 201) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },

  getDeductionLogs: async (uuid) => {
    try {
      const response = await axiosInstance.get(
        `task/v2/fl-order-deduction/logs/${uuid}/`
      );

      if (response.status === 200 || response.status === 201) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
};

export default FlOrderDeductionsService;
