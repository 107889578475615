import React, { useState, useEffect } from 'react';
import { DatePicker, Button, Input, Form, message, Spin } from 'antd';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { catchError } from '../../utils/errorHandler';
import axiosInstance from '../../services/axiosInstance';
import moment from 'moment';

const { TextArea } = Input;

const EditClientDeadline = ({ orderData, handleModalClose, fetchOrderDetails }) => {
    const [form] = Form.useForm();
    const [submitLoading, setSubmitLoading] = useState(false);
    const { orderKey } = useParams();

    // Extract and format initial deadline using moment
    const myDate = orderData?.data?.deadline_hard;
    const initialDeadline = myDate ? moment.utc(myDate).local().format("YYYY-MM-DD hh:mm A") : null; // Localize the date for the client

    const [selectedDate, setSelectedDate] = useState(initialDeadline); // Initialize with the formatted date
    console.log(selectedDate, initialDeadline)

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleSubmit = async (values) => {
        const { deadline, change_reason } = values;

        if (!deadline || !change_reason.trim()) {
            message.error("All fields are mandatory!");
            return;
        }

        const payload = {
            deadline_hard: deadline ? dayjs(deadline).toISOString() : dayjs(),
            change_reason,
        };
        console.log(2345678, payload);

        setSubmitLoading(true);
        try {
            // const response = await axiosInstance.post(`/task/v2/update-deadline/${orderKey}/`, payload);
            // if (response?.status === 200 && response?.data?.status === "success") {
            //     message.success(response?.data?.data);
            //     // fetchOrderDetails();
            //     handleModalClose();
            // }
        } catch (error) {
            await catchError(error);
        } finally {
            setSubmitLoading(false);
        }
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
        >
            <Form.Item
                style={{ width: "100%" }}
                name="deadline"
                label="Client Deadline"
                initialValue={selectedDate} // Set the initial date here
                rules={[{ required: true, message: 'Please select a deadline!' }]}
            >
                <DatePicker
                    value={selectedDate} // Ensure selected date is passed here
                    style={{ width: "100%" }}
                    showTime={{
                        format: "HH:mm",
                        use12Hours: true
                    }}
                    format="YYYY-MM-DD hh:mm A" // Correct format for display
                    onChange={handleDateChange} // Update selected date when changed
                    popupStyle={{ position: 'fixed' }}
                />
            </Form.Item>

            <Form.Item
                style={{ width: "100%" }}
                name="change_reason"
                label="Change Reason"
                rules={[{ required: true, message: 'Please provide a reason for the deadline change!' }]}
            >
                <TextArea
                    rows={4}
                    placeholder="Provide a reason for the deadline change"
                />
            </Form.Item>

            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20 }}>
                {submitLoading ? (
                    <Spin />
                ) : (
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={submitLoading}
                    >
                        Submit
                    </Button>
                )}
            </div>
        </Form>
    );
};

export default EditClientDeadline;
