import CommentsList from "./CommentList";


const FreelancerComments = ({ freelancerComments, userTypes, handleTabChange }) => (
    <CommentsList
      comments={freelancerComments}
      title="FREELANCER COMMENTS"
      color="#ff9800"
      userTypes={userTypes}
      handleTabChange={handleTabChange}
      permissions={{
        add: "can_add_freelancer_comments",
        forward: "can_move_freelancer_comment_to_internal_comment",
        moveToClient: "can_move_freelancer_comment_to_client_comment",
        delete: "can_delete_freelancer_comments",
      }}
    />
  );

  export default FreelancerComments