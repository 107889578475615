import { Button, Select, message } from 'antd'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import { catchError } from '../../utils/errorHandler';
import axiosInstance from '../../services/axiosInstance';

const OrderMoveToFreelancerAssociates = () => {
    const [selectedAssociates, setSelectedAssociates] = useState(null);
    const [loading, setLoading] = useState(false)
    const { orderKey } = useParams()

    const handleSelectChange = (value) => {
        setSelectedAssociates(value)
    }

    const handleSubmit = async () => {
        setLoading(true)
        try {
            const response = await axiosInstance.post(`task/v2/order-move-to-freelancer-team/${orderKey}/`, {
                order_type: selectedAssociates
            })
            // console.log("res", response)
            if (response.status === 200) {
                if (response?.data?.status === "success") {
                    message.success(response?.data?.data)
                }
            }
            setLoading(false)
            setSelectedAssociates(null)

        } catch (err) {
            await catchError(err)
            setLoading(false)
            setSelectedAssociates(null);
        }
    }

    return (
        <div>

            <div style={{ marginBottom: "10px" }}>Order Type</div>
            <Select
                style={{ width: '100%' }}
                placeholder="Select Order Type"
                onChange={handleSelectChange}
                value={selectedAssociates}
            >
                <Select.Option value="technical">Technical</Select.Option>
                <Select.Option value="non-technical">Non-Technical</Select.Option>
            </Select>
            <div style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}>
                <Button className='handle_submit_action_button' onClick={handleSubmit} >{loading ? "Loading..." : "Submit"}</Button>

            </div>
        </div>
    )
}

export default OrderMoveToFreelancerAssociates