import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state to render fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // Log error details to a monitoring service
    if (process.env.NODE_ENV === "production") {
      console.error("Error logged to monitoring service:", error, info);
      // Send the error to a monitoring service like Sentry, LogRocket, etc.
      // Example: monitoringService.logError(error, info);
    } else {
      // Log error details to the console in non-production environments
      console.error("Error caught by ErrorBoundary:", error, info);
    }
  }

  render() {
    if (this.state.hasError) {
      // Show a generic error message in production
      return (
        <div style={{ padding: "2rem", textAlign: "center" }}>
          <h1>Oops! Something went wrong.</h1>
          <p>We’re working to fix this issue. Please try again later.</p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
