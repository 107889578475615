// helpers.js

export const formatLabel = (key) => {
    const labels = {
      

      payment: "Payment",
    
      qualityRequirement: "Quality",
      referencingStyle: "Referencing Style",
      startDate: "Start Date",
      clientDeadlineIST: "Client Deadline",
      clientDeadlineAsia: "Client Deadline",
      salesTeam: "Sales Team",

      

      writerWordCount: "Writer Word Count",
      clientWordCount: "Client's Word Count",
  
      specification: "Specification",
      primarySalesRep: "Primary Sales Representative",
      createdBy: "Created By",
      paymentLink:'Payment Links'
      
   
     
    };
    return labels[key] || key;
  };
  
  export const maskEmail = (email) => {
    const [user, domain] = email.split("@");
    return `${user[0]}******${user[user.length - 1]}@${domain}`;
  };
  
  export const maskContact = (contact) => {
    return contact.replace(/.(?=.{4,}$)/g, "*");
  };
  
  export const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };
  
  export const getCurrencySymbol = (currencyCode) => {
    const currencySymbols = {
      1: "USD",
      2: "EUR",
      3: "INR",
      4: "GBP",
      // Add more currency codes and symbols as needed
    };
    return currencySymbols[currencyCode] || currencyCode;
  };
  