import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Row,
  Col,
  message,
  Spin,
  Checkbox,
} from "antd";
import { MoneyCollectOutlined, DisconnectOutlined } from "@ant-design/icons"; // Example icons
import "./OrderListTable.css";
import dayjs from "dayjs";

const AddPaymentForm = ({
  loading,
  orderKey,
  quotationData,
  selectedRadio,
  setSelectedRadioPayment,
  setQuotationData,
  dropdownData,
  handleVATChange,
  handleRadioChange,
}) => {
  const [form] = Form.useForm();

  // Fetch currency data on component mount
  useEffect(() => {
    if (dropdownData.currency && dropdownData.currency.length > 0) {
      const defaultCurrency = dropdownData.currency[0]; // This will be "USD"

      setSelectedRadioPayment(defaultCurrency);
      setQuotationData((prev) => ({
        ...prev,
        currency: defaultCurrency,
      }));
    }
  }, [dropdownData.currency]); // Depend on currency data

  return (
    <Form
      form={form}
      layout="vertical"
      encType="multipart/form-data"
      style={{ marginTop: "1rem" }}
    >
      {loading ? (
        <Spin tip="Loading currency options..." />
      ) : (
        <>
          <Row gutter={16}>
            <Col sm={24} md={8}>
              <Form.Item label="Budget Without Discount" required>
                <Input
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  suffix={
                    <MoneyCollectOutlined
                      style={{ color: "rgba(0, 0, 0, 0.25)" }}
                    />
                  } // Add the budget icon here
                  placeholder="Budget Without Discount"
                  onChange={(e) =>
                    setQuotationData({
                      ...quotationData,
                      budget_without_discount: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={8}>
              <Form.Item label="Discount" required>
                <Input
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  suffix={
                    <DisconnectOutlined
                      style={{ color: "rgba(0, 0, 0, 0.25)" }}
                    />
                  } // Add the discount icon here
                  placeholder="Discount"
                  onChange={(e) =>
                    setQuotationData({
                      ...quotationData,
                      discount: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item label="Reminder Mail Date">
                <DatePicker
                  //   style={{ width: "100%" }}
                  disabledDate={(current) => current && current < dayjs().startOf('day')}
                  showTime
                  format="YYYY-MM-DD HH:mm A"
                  onChange={(date) =>
                    setQuotationData({
                      ...quotationData,
                      reminder_mail_date: date,
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <label style={{ color: "black" }}>
              <span style={{ color: "red" }}>*</span> Select Currency
            </label>
            <div
              className="custom-radio-container"
              style={{ marginTop: "10px" }}
            >
              {dropdownData.currency.length > 0 ? (
                dropdownData.currency.map((currency) => (
                  <div key={currency}>
                    <input
                      type="radio"
                      id={`custom-radio-${currency}`}
                      name="currency"
                      value={currency}
                      className="custom-radio-input"
                      checked={selectedRadio === currency}
                      onChange={() => handleRadioChange(currency)}
                    />
                    <label
                      style={{ minWidth: "70px", padding: "2px" }}
                      htmlFor={`custom-radio-${currency}`}
                      className="custom-radio-label"
                    >
                      {currency}
                    </label>
                  </div>
                ))
              ) : (
                <p>No currency options available</p>
              )}
            </div>
          </Row>

          {/* <Row gutter={16} style={{ marginTop: "18px" }}>
            <Col sm={24}>
              <Form.Item label="Reason" required>
                <Input.TextArea
                  placeholder="Enter the reason"
                  onChange={(e) =>
                    setQuotationData({
                      ...quotationData,
                      reason: e.target.value,
                    })
                  }
                  rows={4} // Adjust the number of rows as needed
                />
              </Form.Item>
            </Col>
          </Row> */}

          {/* VAT Inclusive Checkbox */}
          <Row
            gutter={16}
            align={"end"}
            justify={"end"}
            style={{ marginTop: "10px" }}
          >
            <Col
              xs={24}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Form.Item>
                <Checkbox
                  checked={quotationData.is_vat_inclusive}
                  onChange={handleVATChange}
                >
                  VAT Inclusive
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
};

export default AddPaymentForm;
