import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../services/axiosInstance';
import { Card, List, Spin, Table, Button, message, Empty } from 'antd';
import Title from 'antd/es/typography/Title';
import { catchError } from '../../utils/errorHandler';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import SpinLoading from './SpinLoading';
import AssignSubscriber from './AssignSubscriber';
import moment from 'moment';


const MobileViewComponent = ({ data, onDelete, deletingRow }) => {

    const columns = [
        {
            dataIndex: "key",
            key: "key",
            render: (text) => <strong>{text}</strong>,
        },
        {
            dataIndex: "value",
            key: "value",
            render: (text) => (
                <span style={{ wordWrap: "break-word", wordBreak: "break-word", whiteSpace: "normal" }}>
                    {text || "N/A"}
                </span>
            ),
        },
    ];

    const transformDataForTable = (item) => [
        { key: "Type", value: item.type },
        { key: "Name", value: item.subscriber },
        { key: "Word Count", value: item.no_of_word },
        { key: "Completion Date", value: item.date_of_completion ? moment(item.date_of_completion).local().format("DD-MM-YYYY hh:mm A") : 'N/A' },
        { key: "Deadline", value: item.deadline ? moment(item.deadline).local().format("DD-MM-YYYY hh:mm A") : 'N/A' },
        { key: "Author", value: item.author },
        { key: "Created	", value: item.created ? moment(item.created).local().format("DD-MM-YYYY hh:mm A") : 'N/A' },
        {
            key: "Delete",
            value: <div onClick={() => onDelete(item.uuid)}>
                {
                    deletingRow === item.uuid
                        ? <SpinLoading />
                        : <DeleteOutlined style={{ color: "red", fontSize: "20px" }} />
                }
            </div>
        },

    ];

    return (
        <div style={{ backgroundColor: "#f2f6f9", padding: "10px" }}>
            {data && data.length > 0 ? (
                data.map((item, index) => (
                    <Table
                        key={index}
                        columns={columns}
                        dataSource={transformDataForTable(item)}
                        pagination={false}
                        bordered
                        size="small"
                        style={{ marginBottom: "25px", borderRadius: "5px" }}
                        showHeader={false}
                    />
                ))
            ) : (
                <Empty description="No data available" />
            )}
        </div>

    );
};

const ManageSubscriber = ({ permissions, orderData, fetchOrderDetails }) => {
    const { orderKey } = useParams();
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(true);
    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
    const [deletingRow, setDeletingRow] = useState(null);
    const [assignSubscribe, setShowAssign] = useState(false)
    const [formSubmit, setFormSubmit] = useState(false)


    const fetchSubscribersList = async () => {
        setLoading(true);
        try {
            const res = await axiosInstance.get(`task/v2/task-subscribers-list/${orderKey}/`);
            const data = res?.data?.data || [];
            setDataSource(data);
        } catch (err) {
            console.log("Error fetching subscribers list", err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSubscribersList();
    }, [orderKey, formSubmit]);

    const handleDeleteButton = async (uuid) => {


        setDeletingRow(uuid);
        try {
            const res = await axiosInstance.post(`task/v2/task-subscribers-delete/${orderKey}/`, {
                uuid: [uuid],
            });

            if (res?.data?.status === "success") {
                message.success("Subscriber deleted successfully");
                setDataSource(prev => prev.filter(item => item.uuid !== uuid));
                fetchOrderDetails()

            } else {
                message.error("Failed to delete subscriber");
            }
        } catch (err) {
            await catchError(err);
            console.log("Error deleting subscriber:", err);
        } finally {
            setDeletingRow(null);
        }
    };

    const columns = [
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Name',
            dataIndex: 'subscriber',
            key: 'subscriber',
        },
        {
            title: 'Word Count',
            dataIndex: 'no_of_word',
            key: 'no_of_word',
        },
        {
            title: 'Completion Date',
            dataIndex: 'date_of_completion',
            key: 'date_of_completion',
            render: (text) => {
                return text
                    ? moment(text).local().format("DD-MM-YYYY hh:mm A")
                    : 'N/A';
            },
        },
        {
            title: 'Deadline',
            dataIndex: 'deadline',
            key: 'deadline',
            render: (text) => {
                return text
                    ? moment(text).local().format("DD-MM-YYYY hh:mm A")
                    : 'N/A';
            },
        },
        {
            title: 'Author',
            dataIndex: 'author',
            key: 'author',
        },
        {
            title: 'Created',
            dataIndex: 'created',
            key: 'created',
            render: (text) => {
                return text
                    ? moment(text).local().format("DD-MM-YYYY hh:mm A")
                    : 'N/A';
            },
        },
        ...(permissions.includes("can_view_order_action_delete_subscriber") ? [{
            title: "Delete",
            dataIndex: 'Delete',
            key: "Delete",
            render: (_, record) => (
                <Button onClick={() => handleDeleteButton(record.uuid)} type="danger">
                    {
                        deletingRow === record.uuid ? <SpinLoading /> :
                            <DeleteOutlined style={{ color: "red", fontSize: "20px" }} />
                    }
                </Button>
            )
        }] : [])


    ];

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
                <Title level={isMobile ? 5 : 4} style={{ marginTop: 0, textAlign: 'left' }}>
                    TASK SUBSCRIBER LIST  - {orderKey}
                </Title>
                {
                    permissions.includes('can_view_order_action_assign_susbcriber') && (
                        <div
                            onClick={() => setShowAssign(!assignSubscribe)}
                            style={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                gap: "5px",
                                cursor: "pointer",
                            }}
                        >
                            {assignSubscribe === false &&
                                <>
                                    <PlusOutlined style={{ color: "black", fontSize: "20px" }} />
                                    <div>Assign Subscriber</div>
                                </>
                            }
                        </div>
                    )
                }


            </div>
            {loading ? (
                <SpinLoading />
            ) : (
                <>
                    {assignSubscribe && (
                        <AssignSubscriber orderData={orderData} setShowAssign={setShowAssign} setFormSubmit={setFormSubmit} fetchOrderDetails={fetchOrderDetails} />
                    )}

                    {isMobile ? (
                        <MobileViewComponent data={dataSource} onDelete={handleDeleteButton} deletingRow={deletingRow} />

                    ) : (
                        <Table dataSource={dataSource} columns={columns} pagination={false} rowKey="id" />
                    )}
                </>
            )}

        </div>
    );
};

export default ManageSubscriber;