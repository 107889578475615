import React from 'react';
import { Modal, Typography } from 'antd';

const { Title } = Typography;

const CustomModal = ({
    modalKey,
    isModalVisible,
    handleModalClose,
    selectedLabel,
    selectedComponent,
    modalWidth = 600,
}) => {
    return (
        <Modal
            key={modalKey}
            className="custom-modal"
            title={
                <div className="custom-modal-header">
                    <Title
                        style={{
                            color: 'white',
                            marginLeft: '15px',
                            position: 'relative',
                            fontSize: 'clamp(14px, 3vw, 20px)',
                            top: '-5px',
                            padding: '0px',
                        }}
                    >
                        {selectedLabel}
                    </Title>
                </div>
            }
            onCancel={handleModalClose}
            okText="Submit"
            visible={isModalVisible}
            footer={null}
            width={modalWidth}
        >
            <div
                style={{
                    maxHeight: '500px',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                }}
            >
                {selectedComponent}
            </div>
        </Modal>
    );
};

export default CustomModal;
