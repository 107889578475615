import axiosInstance from "./axiosInstance";
import { catchError } from "../utils/errorHandler";
import { message } from "antd";
const clientService = {
  getAllClients: async (page = 1, pageSize = 10, filters = {}) => {
    const {
      username,
      email,
      ownership,
      task_count,
      website,
      inactive_since_last_quarter,
      visibility
    } = filters;
    const query = new URLSearchParams({
      page,
      page_size: pageSize,
      ...(username && { username }),
      ...(email && { email }),
      ...(ownership && { ownership }),
      ...(task_count && { task_count }),
      ...(website && { website }),
      ...(visibility && { visibility }),
      ...(inactive_since_last_quarter && { inactive_since_last_quarter }),
    }).toString();
    try {
      const response = await axiosInstance.get(`/client/v2/all/?${query}`);
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      if (error.response && error.response.status === 500) {
        message.error("Internal Server Error");
      }
      await catchError(error);
      return { success: false };
    }
  },
  getMyClients: async (page = 1, pageSize = 10, filters = {}) => {
    const {
      username,
      email,
      ownership,
      task_count,
      website,
      visibility,
      inactive_since_last_quarter,
    } = filters;
    const query = new URLSearchParams({
      page,
      page_size: pageSize,
      ...(username && { username }),
      ...(email && { email }),
      ...(ownership && { ownership }),
      ...(visibility && { visibility }),
      ...(task_count && { task_count }),
      ...(website && { website }),
      ...(inactive_since_last_quarter && { inactive_since_last_quarter }),
    }).toString();
    try {
      const response = await axiosInstance.get(`/client/v2/my/?${query}`);
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      if (error.response && error.response.status === 500) {
        message.error("Internal Server Error");
      }
      await catchError(error);
      return { success: false };
    }
  },
  splitTransaction: async (uuid, splitData) => {
    try {
      const response = await axiosInstance.post(
        `/client/v2/transaction/split/${uuid}/`,
        splitData
      );
      if (response.status === 201) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  createClient: async (clientData) => {
    try {
      const response = await axiosInstance.post(
        `/client/v2/create/`,
        clientData
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  getClientDetail: async (uuid) => {
    try {
      const response = await axiosInstance.get(`/client/v2/detail/${uuid}/`);
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },

  getClientTransactions: async (uuid, startDate, endDate) => {
    const query = new URLSearchParams({
      ...(startDate && { start_date: startDate }),
      ...(endDate && { end_date: endDate }),
    }).toString();
    try {
      const response = await axiosInstance.get(
        `/client/v2/transactions/${uuid}/?${query}`
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },

  getClientTasks: async (uuid, startDate, endDate) => {
    const query = new URLSearchParams({
      ...(startDate && { start_date: startDate }),
      ...(endDate && { end_date: endDate }),
    }).toString();
    try {
      const response = await axiosInstance.get(
        `/client/v2/tasks/${uuid}/?${query}`
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },

  getClientRefunds: async (uuid) => {
    try {
      const response = await axiosInstance.get(`/client/v2/refunds/${uuid}/`);
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  getClientRevenue: async (uuid, startDate, endDate) => {
    const query = new URLSearchParams({
      ...(startDate && { start_date: startDate }),
      ...(endDate && { end_date: endDate }),
      ...(!startDate && !endDate && { last_week: "true", last_month: "true" }),
    }).toString();
    console.log(query, "quert");
    try {
      const response = await axiosInstance.get(
        `/client/v2/revenue/${uuid}/?${query}`
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },

  getWebsitesList: async () => {
    try {
      const response = await axiosInstance.get("/client/v2/websites/");
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },

  getOwnersList: async () => {
    try {
      const response = await axiosInstance.get(
        "/client/v2/client-owners-list/"
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },

  getActiveInactiveStatus: async () => {
    try {
      const response = await axiosInstance.get("/status");
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  getAllTransactions: async (page = 1, pageSize = 10, filters = {}) => {
    // const {
    //   username,
    //   email,
    //   ownership,
    //   task_count,
    //   website,
    //   inactive_since_last_quarter,
    //   visibility
    // } = filters;
    // const query = new URLSearchParams({
    //   page,
    //   page_size: pageSize,
    //   ...(username && { username }),
    //   ...(email && { email }),
    //   ...(ownership && { ownership }),
    //   ...(task_count && { task_count }),
    //   ...(website && { website }),
    //   ...(visibility && { visibility }),
    //   ...(inactive_since_last_quarter && { inactive_since_last_quarter }),
    // }).toString();
    try {
      const response = await axiosInstance.get(`/transactions/v2/all-transactions/`);
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      if (error.response && error.response.status === 500) {
        message.error("Internal Server Error");
      }
      await catchError(error);
      return { success: false };
    }
  },
  getMyTransactions: async (page = 1, pageSize = 10, filters = {}) => {
    // const {
    //   username,
    //   email,
    //   ownership,
    //   task_count,
    //   website,
    //   visibility,
    //   inactive_since_last_quarter,
    // } = filters;
    // const query = new URLSearchParams({
    //   page,
    //   page_size: pageSize,
    //   ...(username && { username }),
    //   ...(email && { email }),
    //   ...(ownership && { ownership }),
    //   ...(visibility && { visibility }),
    //   ...(task_count && { task_count }),
    //   ...(website && { website }),
    //   ...(inactive_since_last_quarter && { inactive_since_last_quarter }),
    // }).toString();
    try {
      const response = await axiosInstance.get(`/transactions/v2/my-transactions/`);
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      if (error.response && error.response.status === 500) {
        message.error("Internal Server Error");
      }
      await catchError(error);
      return { success: false };
    }
  },
};

export default clientService;
