import React, { useState, useMemo, useCallback } from "react";
import { Table, Tooltip, Avatar, Popover, Spin } from "antd";
import {
  EyeFilled,
  CheckCircleOutlined,
  CloseCircleOutlined,
  EyeInvisibleFilled,
} from "@ant-design/icons";
import ClientTabs from "./ClientTabs";
import "./AllClientsTableSummary.css";
import clientService from "../../services/clientService";
import usePermissions from "../../hooks/usePermissions";
import dayjs from "dayjs";
import moment from "moment";
// import { useLocalTime } from './hooks/useLocalTime'; // Assuming you have this hook
const AllClientsTableSummary = ({
  allclients,
  loading,
  pagination,
  setPagination,
}) => {
  const { hasPermission } = usePermissions();

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [clientDetails, setClientDetails] = useState({});
  const [cllientUsername, setClientUsername] = useState();
  const [detailsLoading, setDetailsLoading] = useState({});

  const toggleExpand = useCallback(
    async (record) => {
      const isCurrentlyExpanded = expandedRowKeys.includes(record.uid);

      if (isCurrentlyExpanded) {
        setExpandedRowKeys(expandedRowKeys.filter((key) => key !== record.uid));
      } else {
        setExpandedRowKeys([...expandedRowKeys, record.uid]);
        if (hasPermission("can_view_client_details")) {
          if (!clientDetails[record.uid]) {
            setDetailsLoading((prev) => ({ ...prev, [record.uid]: true }));
            try {
              const response = await clientService.getClientDetail(record.uid);
              if (response.success) {
                setClientUsername(
                  response?.data?.data?.username
                    ? response?.data?.data?.username
                    : "N/A"
                );
                setClientDetails((prevDetails) => ({
                  ...prevDetails,
                  [record.uid]: response?.data?.data?.payment_links || [],
                }));
              }
            } catch (error) {
              console.error("Error fetching client details:", error);
            } finally {
              setDetailsLoading((prev) => ({ ...prev, [record.uid]: false }));
            }
          }
        }
      }
    },
    [expandedRowKeys, clientDetails]
  );

  const renderVisibilityAvatars = (visibilityArray) => {
    if (!visibilityArray || visibilityArray.length === 0) return "N/A";

    const displayedAvatars = visibilityArray.slice(0, 2);
    const remainingAvatars = visibilityArray.slice(2);

    return (
      <Avatar.Group maxCount={3}>
        {displayedAvatars.map((name, index) => (
          <Tooltip key={index} title={name}>
            <Avatar style={{ backgroundColor: "#87d068" }}>
              {name.charAt(0).toUpperCase()}
            </Avatar>
          </Tooltip>
        ))}
        {remainingAvatars.length > 0 && (
          <Popover
            content={
              <div>
                {remainingAvatars.map((name, index) => (
                  <p key={index}>{name}</p>
                ))}
              </div>
            }
            title="Additional Visibility"
          >
            <Avatar style={{ backgroundColor: "#f56a00" }}>
              +{remainingAvatars.length}
            </Avatar>
          </Popover>
        )}
      </Avatar.Group>
    );
  };

  const columns = useMemo(() => {
    if (allclients.length === 0) return [];
  
    const sampleClient = allclients[0];
    const dynamicColumns = Object.keys(sampleClient).reduce((acc, key) => {
      if (
        ![
          "uid",
          "name",
          "created_at",
          "email",
          "contact_no",
          "ownership",
          "visibility",
          "website",
          "inactive_since_last_quarter",
        ].includes(key)
      ) {
        acc.push({
          title: key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, " "),
          dataIndex: key,
          key,
          render: (text) => text || "n/a",
        });
      }
      return acc;
    }, []);
  
    dynamicColumns.unshift({
      title: "Full Name",
      key: "full_name",
      render: (text, record) => {
        if (!record.name) return null;
        const isExpanded = expandedRowKeys.includes(record.uid);
    
        const formattedDate = moment(record.created_at).local().format('YYYY-MM-DD hh:mm A'); // Get the formatted date here
  
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {hasPermission("can_view_client_details") && (
                isExpanded ? (
                  <EyeInvisibleFilled
                    style={{ cursor: "pointer", marginRight: "6px" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleExpand(record);
                    }}
                  />
                ) : (
                  <EyeFilled
                    style={{ cursor: "pointer", marginRight: "6px" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleExpand(record);
                    }}
                  />
                )
              )}
              <p style={{ margin: 0 }}>{record.name}</p>
            </div>
            <span style={{ opacity: 0.5, fontSize: "12px" }}>
              {formattedDate ? formattedDate : "N/A"} {/* Use the formatted date */}
            </span>
          </div>
        );
      },
    });
  
    dynamicColumns.push({
      title: "Contact",
      key: "contact",
      render: (text, record) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p style={{ margin: 0 }}>{record.email || "N/A"}</p>
          <span style={{ opacity: 0.5, fontSize: "12px" }}>
            {record.contact_no || "N/A"}
          </span>
        </div>
      ),
    });
  
    dynamicColumns.push({
      title: "Ownership",
      key: "ownership",
      render: (text, record) => record.ownership || "N/A",
    });
  
    dynamicColumns.push({
      title: "Visibility",
      key: "visibility",
      render: (text, record) => renderVisibilityAvatars(record.visibility),
    });
  
    // Conditionally add the "Website" column if all clients have the website field
    const allClientsHaveWebsite = allclients.every(
      (client) => "website" in client
    );
    if (allClientsHaveWebsite) {
      dynamicColumns.push({
        title: "Website",
        key: "website",
        render: (text, record) => <span>{record.website || "N/A"}</span>,
      });
    }
  
    dynamicColumns.push({
      title: "Status",
      key: "status",
      render: (text, record) => {
        return record.inactive_since_last_quarter === "Active" ? (
          <Tooltip title="Active">
            <CheckCircleOutlined style={{ color: "green" }} />
          </Tooltip>
        ) : (
          <Tooltip title="Inactive since last Quarter">
            <CloseCircleOutlined style={{ color: "red" }} />
          </Tooltip>
        );
      },
    });
  
    return dynamicColumns;
  }, [allclients, expandedRowKeys]);
  return (
    <div style={{ width: "100%" }}>
      <Table
        style={{ width: "100%" }}
        columns={columns}
        dataSource={allclients}
        expandable={{
          expandedRowRender: (record) => (
            <div>
              <ClientTabs
                uid={record.uid}
                username={cllientUsername}
                clientDetails={clientDetails[record.uid] || []}
              />
            </div>
          ),
          expandedRowKeys,
          onExpand: (expanded, record) => toggleExpand(record),
          expandIconColumnIndex: -1,
          rowExpandable: (record) => record.uid !== "Not Expandable",
        }}
        rowClassName={(record) =>
          expandedRowKeys.includes(record.uid) ? "expanded-row" : ""
        }
        loading={loading}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: pagination.total,
          onChange: (page, pageSize) => {
            setPagination({ ...pagination, current: page, pageSize });
          },
          onShowSizeChange: (current, size) => {
            setPagination({ ...pagination, current: 1, pageSize: size });
          },
        }}
        rowKey="uid"
      />
    </div>
  );
};

export default AllClientsTableSummary;
