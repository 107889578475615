import { Button, Col, Form, Input, Row, Space, Typography, Card } from "antd";
import { reset } from "../../redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { UserOutlined, LockOutlined, MailOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { motion } from "framer-motion";

const { Title, Text } = Typography;

const ResetPassword = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [data, setData] = useState({
    mailSend: false,
  });
  const { loading } = useSelector((state) => state.auth);

  const onFinish = async (values) => {
    const { success } = await dispatch(reset(values));
    if (success) {
      setData((prevData) => ({
        ...prevData,
        mailSend: true,
      }));
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Row
      justify="center"
      align="middle"
      style={{
        minHeight: "100vh",
        background: "#f5f5f5",
        padding: "0px"
      }}
    >
      <Col xl={8} xxl={6} lg={10} md={14} sm={22} xs={22}>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Card
            bordered={false}
            style={{
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)",
              borderRadius: "12px",
              overflow: "hidden"
            }}
          >
            <Form
              form={form}
              name="reset_password"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              layout="vertical"
              style={{ padding: "16px" }}
            >
              {data.mailSend ? (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3 }}
                >
                  <div style={{ textAlign: "center", marginBottom: "24px" }}>
                    <motion.div
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{ delay: 0.2 }}
                    >
                      <MailOutlined
                        style={{
                          fontSize: "48px",
                          color: "#36c6d3",
                          marginBottom: "16px"
                        }}
                      />
                    </motion.div>
                    <Title level={4} style={{ marginBottom: "8px", color: "#262626" }}>
                      Check your email
                    </Title>
                    <Text style={{
                      display: "block",
                      color: "#595959",
                      marginBottom: "8px"
                    }}>
                      We've sent a password reset link to
                    </Text>
                    <Text strong style={{
                      fontSize: "16px",
                      color: "#36c6d3"
                    }}>
                      {form.getFieldValue("email")}
                    </Text>
                  </div>
                  <Space direction="vertical" style={{ width: "100%" }} size={16}>
                    <Button
                      onClick={() => onFinish(form.getFieldValue())}
                      style={{
                        width: "100%",
                        height: "40px",
                        borderColor: "#36c6d3",
                        color: "#36c6d3"
                      }}
                      loading={loading}
                      ghost
                    >
                      Resend Email
                    </Button>
                    <Button
                      type="link"
                      onClick={() => setData(prev => ({ ...prev, mailSend: false }))}
                      style={{
                        width: "100%",
                        height: "40px",
                        color: "#8c8c8c"
                      }}
                    >
                      Try a different email
                    </Button>
                  </Space>
                </motion.div>
              ) : (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3 }}
                >
                  <div style={{ textAlign: "center", marginBottom: "32px" }}>
                    <motion.div
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{ delay: 0.2 }}
                    >
                      <LockOutlined
                        style={{
                          fontSize: "48px",
                          color: "#36c6d3",
                          marginBottom: "16px"
                        }}
                      />
                    </motion.div>
                    <Title level={4} style={{ marginBottom: "8px", color: "#262626" }}>
                      Forgot your password?
                    </Title>
                    <Text style={{ color: "#595959" }}>
                      Don't worry! Enter your email and we'll send you a reset link.
                    </Text>
                  </div>

                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your email address"
                      },
                      {
                        type: "email",
                        message: "Please enter a valid email address"
                      }
                    ]}
                  >
                    <Input
                      prefix={<UserOutlined style={{ color: "#bfbfbf" }} />}
                      placeholder="Enter your email"
                      size="large"
                      style={{ borderRadius: "6px" }}
                    />
                  </Form.Item>

                  <Form.Item style={{ marginBottom: 0 }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="large"
                      loading={loading}
                      style={{
                        width: "100%",
                        height: "40px",
                        background: "#36c6d3",
                        borderColor: "#36c6d3",
                        borderRadius: "6px"
                      }}
                    >
                      Reset Password
                    </Button>
                  </Form.Item>
                </motion.div>
              )}
            </Form>
          </Card>
        </motion.div>
      </Col>
    </Row>
  );
};

export default ResetPassword;
