import axiosInstance from "./axiosInstance";
import { catchError } from "../utils/errorHandler";
import { Device } from "@twilio/voice-sdk";
import { message } from "antd";
const notificationsService = {
  getNotificationsList: async (
    level = "urgent",
    search = "",
    page = 1,
    pageSize = 50
  ) => {
    const query = new URLSearchParams({ level, page, page_size: pageSize });

    // Only add `search` if it's not empty
    if (search) {
      query.append("search", search);
    }

    try {
      const response = await axiosInstance.get(
        `notifications/v2/list/?${query}`
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },

  markAsReadNotification: async (notificationId) => {
    try {
      const response = await axiosInstance.post(
        `notifications/v2/mark-as-read/`,
        {
          uids: notificationId, // Payload includes notification ID
        }
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false, message: "Failed to mark notification as read" };
    } catch (error) {
      await catchError(error);
      return {
        success: false,
        message: "An error occurred while marking notification as read",
      };
    }
  },
};

export default notificationsService;
