import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Button,
  Empty,
  Spin,
  Popover,
  Menu,
  Checkbox,
  Dropdown,
  message,
  Tag,
  Badge,
  Flex,
  Modal,
} from "antd";
import {
  CommentOutlined,
  DeleteOutlined,
  SwapOutlined,
  UserSwitchOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Paperclip } from "lucide-react";
import "./addCooment.css";
import { useLocalTime } from "../../hooks/useLocalTime";

import useIsMobile from "../../hooks/useIsMobile";
import { Forward, Plus } from "lucide-react";
import orderService from "../../services/OrderLists";
import usePermissions from "../../hooks/usePermissions";
import { useParams } from "react-router-dom";
import {
  AddInternalComment,
  AddClientComment,
  AddFreelancerComment,
} from "./AddCmomment";

const { Title } = Typography;

// Separate Comment component to properly use the hook
// Separate Comment component to properly use the hook
const Comment = ({
  comment,
  onDelete,
  color,
  permissions,
  userTypes,
  handleTabChange,
  commentType,
}) => {
  const { orderKey } = useParams();
  console.log(orderKey, "order key is here");
  const localTime = useLocalTime(comment.submit_date);

  const { hasPermission } = usePermissions();
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedUserType, setSelectedUserType] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false); // Control visibility
  const [loading, setLoading] = useState(false); // State for loading
  const [isDeleteLoading, setIsDeleteLoading] = useState(false); // State for loading

  const handleForwardClick = async () => {
    setLoading(true); // Start loading
    const payload = { user_type: selectedUserType }; // Wrap selected user types in an object

    if (commentType === "internal") {
      // If it's an internal comment, call the internal forward function
      const result = await orderService.forwardInternalComment(
        comment.uuid,
        payload
      );
      if (result.success) {
        message.success("Internal comment forwarded successfully");
      }
    } else {
      // If it's a client comment, call the client forward function
      const result = await orderService.forwardClientComment(
        comment.uuid,
        payload
      );
      if (result.success) {
        message.success("Client comment forwarded successfully");
      }
    }

    setLoading(false); // Stop loading
    setDropdownVisible(false); // Close the dropdown after submitting
  };

  const handleMoveToFL = async () => {
    setIsDeleteLoading(true);
    try {
      if (commentType === "internal") {
        const result = await orderService.moveToFreelancerComment(comment.uuid);
        setIsDeleteLoading(false);
        if (result.success) {
          message.success("Comment moved to freelancer successfully");
          handleTabChange("3");
        }
      }
    } catch (error) {
      setIsDeleteLoading(false);
    }
  };

  const handleMoveToClientOrInternal = async () => {
    setIsDeleteLoading(true);
    if (commentType === "internal") {
      const result = await orderService.moveToClientComment(comment.uuid);
      setLoading(false);
      if (result.success) {
        message.success("Comment moved to client successfully");
        handleTabChange("3");
      }
      setIsDeleteLoading(false);
    } else if (commentType === "client") {
      const result = await orderService.moveToInternalCommentClient(
        comment.uuid
      );
      setIsDeleteLoading(false);
      if (result.success) {
        message.success("Comment moved to internal successfully");
        handleTabChange("3");
      }
    }
  };
  const userTypeMenu = (
    <Menu>
      {userTypes?.map((type) => (
        <Menu.Item key={type.value}>
          <Checkbox
            onChange={(e) => {
              const { checked } = e.target;
              if (checked) {
                setSelectedUserType((prev) => [...prev, type.value]);
              } else {
                setSelectedUserType((prev) =>
                  prev.filter((val) => val !== type.value)
                );
              }
            }}>
            {type.name}
          </Checkbox>
        </Menu.Item>
      ))}
      <Menu.Item key="submit">
        <Button
          type="primary"
          onClick={handleForwardClick}
          loading={loading} // Show loading spinner
          disabled={userTypes.length === 0} // Disable if no user types
        >
          Submit
        </Button>
      </Menu.Item>
    </Menu>
  );

  const maxLength = 60;

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const truncateText = (text) => {
    if (!text) return "";
    if (text.length <= maxLength) return text;
    return isExpanded ? text : `${text.slice(0, maxLength)}...`;
  };

  if (!comment) return null;
  // Determine badge color based on urgency
  const bgColor = comment?.comment_type?.is_urgent ? "#fecaca" : "#ecfdf5";

  const tagColor = comment.is_urgent
    ? "red"
    : comment.comment_type && comment.comment_type.name === "internal"
    ? "#007bff"
    : comment.comment_type && comment.comment_type.name === "client"
    ? "#00bfa5"
    : "#ff9800";
  const handleDeleteComment = async (orderKey, uuid) => {
    setIsDeleteLoading(true);
    try {
      if (commentType === "internal") {
        const result = await orderService.deleteInternalComment(orderKey, uuid); // Ensure to use uuid here
        if (result.success) {
          message.success("Comment deleted successfully");
          handleTabChange("3");
        }
        setIsDeleteLoading(false);
      } else if (commentType === "client") {
        const result = await orderService.deleteClientComment(orderKey, uuid); // Ensure to use uuid here
        if (result.success) {
          message.success("Comment deleted successfully");
          handleTabChange("3");
        }
        setIsDeleteLoading(false);
      }
    } catch (error) {
      setIsDeleteLoading(false);
      // return error;
    }
  };

  return isDeleteLoading ? (
    <Spin />
  ) : (
    <Flex
      vertical
      justify="flex-start"
      align="flex-start"
      gap={"4px"}
      style={{
        padding: 10,
        border: "1px solid #F5F5F5",
        marginTop: "10px",
        borderRadius: "10px",
        maxWidth: "100%",
        minWidth: "100%",

        background: bgColor,
      }}>
      {/* <Tag color="blue">General</Tag> */}

      <Flex
        justify="space-between"
        align="flex-start"
        style={{ width: "100%", padding: "0px", marginTop: "10px" }}>
        <Title
          level={5}
          style={{
            textAlign: "left",
            color: "black",
            margin: "0px",
            maxWidth: "70%",
          }}>
          {commentType === "internal"
            ? comment.user?.user_type &&
              `${comment.user.user_type} ${comment.user.user_id} ${
                comment.user?.name || ""
              }`
            : comment.user_type}
        </Title>
        {comment.comment_type && (
          <Tag type="primary" color={"blue"}>
            {comment.comment_type.name}
          </Tag>
        )}
      </Flex>
      {comment?.user_type.length > 0 && (
        <>
          <Typography.Text
            type="secondary"
            style={{
              margin: 0,
              padding: 0,
              textAlign: "left",
              display: "flex",
              alignItems: "flex-start",
              gap: "4px",
            }}>
            {Array.isArray(comment?.user_type) && (
              <svg
                style={{ marginTop: "6px" }}
                width="13"
                height="10"
                viewBox="0 0 13 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.14031 4.35686C2.60382 3.75294 3.20328 3.25099 3.89665 2.8862C4.59002 2.52141 5.36051 2.30263 6.15406 2.24519V1.54519C6.15092 1.31694 6.2175 1.09259 6.34601 0.898372C6.47451 0.704157 6.65963 0.54812 6.87969 0.448523C7.0902 0.353167 7.32527 0.315173 7.55788 0.338906C7.79049 0.362639 8.01121 0.447136 8.19469 0.58269L11.6266 3.09569C11.8605 3.26937 12.0243 3.51209 12.0911 3.78406C12.1579 4.05602 12.1236 4.34109 11.9941 4.59252C11.9078 4.75934 11.7822 4.90573 11.6266 5.02069L8.19594 7.53369C8.01268 7.66969 7.79184 7.75438 7.55907 7.77792C7.3263 7.80147 7.09116 7.76289 6.88094 7.66669C6.66185 7.56662 6.47754 7.41078 6.34926 7.21716C6.22099 7.02353 6.15397 6.8 6.15594 6.57236V6.02286C2.82469 6.77186 2.27344 9.08594 2.24969 9.19677C2.22302 9.32376 2.15188 9.43887 2.04745 9.52399C1.94301 9.6091 1.81122 9.65939 1.67281 9.66694H1.63531C1.5022 9.66707 1.37252 9.62753 1.26516 9.55409C1.1578 9.48064 1.07837 9.37712 1.03844 9.25861C0.83016 8.41587 0.820835 7.54093 1.01111 6.6945C1.20138 5.84806 1.58669 5.0504 2.14031 4.35686Z"
                  fill="#B3B3B3"
                />
              </svg>
            )}

            {Array.isArray(comment?.user_type) && comment.user_type.length > 0
              ? comment.user_type.join(", ")
              : ""}
          </Typography.Text>
        </>
      )}
      <p style={{ textAlign: "left", margin: "0px" }} onClick={toggleExpand}>
        {truncateText(comment?.comment)}
        {comment?.comment.length > maxLength && !isExpanded && (
          <span style={{ color: "blue", cursor: "pointer" }}> Show More</span>
        )}
        {isExpanded && (
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={toggleExpand}>
            {" "}
            Show Less
          </span>
        )}
      </p>

      {comment?.uploads?.length > 0 && (
        <div>
          <Popover
            content={
              <div style={{ display: "flex", flexDirection: "column" }}>
                {comment.uploads.map((upload) => (
                  <Link
                    key={upload.title} // Changed to use title as the key
                    to={upload.url}
                    target="_blank"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "5px",
                    }}>
                    {/* Use your PaperClip icon here if needed */}
                    {upload.title}
                  </Link>
                ))}
              </div>
            }
            title="Uploaded Files"
            trigger="hover" // You can change this to "hover" or another trigger if you prefer
          >
            <p
              style={{
                margin: 0,
                color: "#B3B3B3",
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}>
              <Paperclip
                style={{ color: "#B3B3B3", width: "16px", height: "16px" }}
              />
              {comment.uploads.length} file
              {comment.uploads.length > 1 ? "s" : ""}
            </p>
          </Popover>
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}>
        <div style={{ display: "flex", gap: "2px" }}>
          {hasPermission(permissions.forward) && (
            <Dropdown
              overlay={userTypeMenu}
              trigger={["click"]}
              visible={dropdownVisible}
              onVisibleChange={(visible) => setDropdownVisible(visible)}>
              <Button
                type="link"
                icon={<Forward />}
                title="Forward Comment"
                loading={isDeleteLoading} // Show a spinner while the delete is processing
                // Disable the button while deleting

                disabled={userTypes.length === 0 || isDeleteLoading}
              />
            </Dropdown>
          )}
          {hasPermission(
            commentType === "internal"
              ? permissions.moveToClient
              : commentType === "client"
              ? permissions.moveToInternal
              : commentType === "freelancer"
              ? permissions.moveToInternal
              : ""
          ) && (
            <Button
              type="link"
              icon={<UserSwitchOutlined />}
              onClick={handleMoveToClientOrInternal}
              title={
                commentType === "internal"
                  ? "Move to Client Comments"
                  : "Move to Internal Comments"
              }
              loading={isDeleteLoading} // Show a spinner while the delete is processing
              disabled={isDeleteLoading} // Disable the button while deleting
            />
          )}
          {hasPermission(permissions.moveToFL) && (
            <Button
              type="link"
              icon={<SwapOutlined />}
              onClick={handleMoveToFL}
              title="Move to FL Comments"
            />
          )}
          {hasPermission(permissions.delete) && (
            <Button
              type="link"
              icon={<DeleteOutlined style={{ color: "red" }} />}
              title="Delete Comment"
              loading={isDeleteLoading} // Show a spinner while the delete is processing
              disabled={isDeleteLoading} // Disable the button while deleting
              onClick={() => handleDeleteComment(orderKey, comment.uuid)} // Make sure the correct uuid is passed
            />
          )}
        </div>
        <p style={{ color: "gray", margin: 0 }}>{localTime}</p>
      </div>
    </Flex>
  );
};

const CommentsList = ({
  comments,
  title,
  color,
  onDeleteComment,
  permissions,
  handleTabChange,
  userTypes,
}) => {
  const isMobile = useIsMobile();
  const { hasPermission } = usePermissions();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const { orderKey } = useParams();
  const onClose = () => {
    setIsModalVisible(false);
  };
  const commentType =
    title === "INTERNAL COMMENTS"
      ? "internal"
      : title === "CLIENT COMMENTS"
      ? "client"
      : "freelancer";

  const addComment = async () => {
    try {
      let modalTitle = "";
      let modalContent = null;

      switch (commentType) {
        case "internal":
          modalTitle = "Add Internal Comment";
          modalContent = (
            <AddInternalComment
              orderKey={orderKey}
              handleTabChange={handleTabChange}
              onClose={onClose}
            />
          );
          break;
        case "client":
          modalTitle = "Add Client Comment";
          modalContent = (
            <AddClientComment
              orderKey={orderKey}
              handleTabChange={handleTabChange}
              onClose={onClose}
            />
          );
          break;
        case "freelancer":
          modalTitle = "Add Freelancer Comment";
          modalContent = (
            <AddFreelancerComment
              orderKey={orderKey}
              handleTabChange={handleTabChange}
              onClose={onClose}
            />
          );
          break;
        default:
          message.error("Invalid comment type");
          return;
      }

      setModalTitle(modalTitle);
      setModalContent(modalContent);
      setIsModalVisible(true);
    } catch (error) {
      console.error("Error in addComment:", error);
      message.error("An error occurred while adding the comment");
    }
  };

  return (
    <div style={{ padding: "10px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",

          alignItems: "center",
          width: "100%",
        }}>
        <Title level={5} style={{ color: "black" }}>
          {title}
        </Title>

        {hasPermission(permissions.add) && (
          <Button
            onClick={addComment}
            icon={
              <Plus
                fontSize="16px"
                style={{ color: "white", color: "black" }}
              />
            }
            style={{
              backgroundColor: "none",
              boxShadow: "none",

              marginTop: "10px",
              width: "30px",
              height: "30px",
              borderRadius: "100%",
            }}></Button>
        )}
      </div>
      <Modal
        className="create-order"
        visible={isModalVisible}
        width={800}
        height={500}
        title={
          <div
            className="custom-modal-header"
            style={{ display: "flex", justifyContent: "space-between" }}>
            <Title
              level={3}
              style={{
                color: "white",
                margin: 0,
                position: "relative",
                left: "12px",
              }}>
              {modalTitle}
            </Title>
            <CloseOutlined
              style={{ fontSize: "16px", marginRight: "10px" }}
              onClick={onClose}
            />
          </div>
        }
        closable={false}
        footer={null}
        onCancel={() => setIsModalVisible(false)}>
        {modalContent}
      </Modal>
      {comments.length > 0 ? (
        <Row>
          <Flex vertical gap={"34px"}>
            <div
              style={{
                padding: "0",
                minWidth: isMobile ? "auto" : "370px",
                minHeight: "300px",
                maxHeight: "500px",
                overflowX: "hidden",
                overflowY: "auto",
                width: "100%",
      
                backgroundColor: "white",
                padding: "10px",
                borderRadius: "10px",
              }}>
              {comments.map((comment) => (
                <Comment
                  userTypes={userTypes}
                  key={comment.uuid} // Use uuid as the key
                  comment={comment}
                  onDelete={onDeleteComment}
                  color={color}
                  permissions={permissions}
                  handleTabChange={handleTabChange}
                  commentType={commentType} // Pass comment type
                />
              ))}
            </div>
          </Flex>
        </Row>
      ) : (
        <div
          style={{
            width: "100%",
            padding: "0",
            minWidth: isMobile ? "auto" : "370px",
            maxHeight: "300px",
            minHeight: "300px",
            overflowY: "scroll",
            backgroundColor: "white",
            padding: "10px",
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Empty description={`No ${title.toLowerCase()} available`} />
        </div>
      )}
    </div>
  );
};

const InternalComments = ({ internalComments, userTypes, handleTabChange }) => (
  <CommentsList
    userTypes={userTypes}
    comments={internalComments}
    title="INTERNAL COMMENTS"
    handleTabChange={handleTabChange}
    color="#007bff"
    permissions={{
      add: "can_add_internal_comments",
      forward: "can_make_internal_comment_forward",
      moveToClient: "can_move_internal_comment_to_client_comment",
      moveToFL: "can_move_internal_comment_to_fl_comment",
      delete: "can_delete_internal_comments",
    }}
  />
);

const ClientComments = ({ clientComments, userTypes, handleTabChange }) => (
  <CommentsList
    comments={clientComments}
    title="CLIENT COMMENTS"
    color="#00bfa5"
    userTypes={userTypes}
    handleTabChange={handleTabChange}
    permissions={{
      add: "can_add_client_comments",
      forward: null,
      moveToFL: "can_move_client_comment_to_fl_comment",
      moveToInternal: "can_move_client_comment_to_internal_comment",
      delete: "can_delete_client_comments",
    }}
  />
);

const FreelancerComments = ({
  freelancerComments,
  userTypes,
  handleTabChange,
}) => (
  <CommentsList
    comments={freelancerComments}
    title="FREELANCER COMMENTS"
    color="#ff9800"
    userTypes={userTypes}
    handleTabChange={handleTabChange}
    permissions={{
      add: "can_add_freelancer_comments",
      forward: "can_move_freelancer_comment_to_internal_comment",
      moveToClient: "can_move_freelancer_comment_to_client_comment",
      delete: "can_delete_freelancer_comments",
    }}
  />
);

const CommentsSection = ({
  orderData,
  loading,
  handleTabChange,
  moveToPortal,
}) => {
  const { hasPermission } = usePermissions();

  const [userTypes, setUserTypes] = useState([]); // State for user types

  // Fetch user types once when the component mounts
  useEffect(() => {
    const fetchUserTypes = async () => {
      const types = await orderService.getUserTypes();
      setUserTypes(types);
    };
    fetchUserTypes();
  }, []);

  if (loading) return <Spin size="large" />;

  return (
    <Flex
      style={{ padding: "10px", width: "100%", flexWrap: "wrap" }}
      align="flex-start"
      flexWrap="wrap">
      {hasPermission("can_view_internal_comments") && (
        <Col xs={24} sm={24} md={8}>
          <InternalComments
            internalComments={orderData.internal_comments || []}
            handleTabChange={handleTabChange}
            userTypes={userTypes}
          />
        </Col>
      )}
      {hasPermission("can_view_client_comments") && (
        <Col xs={24} sm={24} md={8}>
          <ClientComments
            clientComments={orderData.client_comments || []}
            handleTabChange={handleTabChange}
            userTypes={userTypes}
          />
        </Col>
      )}
      {hasPermission("can_view_freelancer_comments") && moveToPortal && (
        <Col xs={24} sm={24} md={8}>
          <FreelancerComments
            freelancerComments={orderData.fl_comments || []}
            handleTabChange={handleTabChange}
            userTypes={userTypes}
          />
        </Col>
      )}
    </Flex>
  );
};

export default CommentsSection;
