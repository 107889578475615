import { Spin } from 'antd'
import React from 'react'

const SpinLoading = () => {
    return (
        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Spin tip="Loading..." />
        </div>
    )
}

export default SpinLoading