import React, { useState, useEffect } from "react";
import { DatePicker, Button, Input, Form, message, Spin, Col, Row } from "antd";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { catchError } from "../../utils/errorHandler";
import axiosInstance from "../../services/axiosInstance";

const { TextArea } = Input;

const EditClientDeadline = ({ orderData, handleModalClose, fetchOrderDetails }) => {
    const [form] = Form.useForm();
    const [submitLoading, setSubmitLoading] = useState(false);
    const { orderKey } = useParams();

    const initialDeadline = orderData?.data?.deadline_hard
        ? dayjs(orderData.data.deadline_hard)
        : null;

    const [selectedDate, setSelectedDate] = useState(initialDeadline);


    const handleDateChange = (date) => {
        setSelectedDate(date);
        form.setFieldsValue({ deadline: date });
    };

    const handleSubmit = async (values) => {
        const { deadline, change_reason } = values;

        if (!deadline || !change_reason.trim()) {
            message.error("All fields are mandatory!");
            return;
        }

        const payload = {
            deadline_hard: dayjs(deadline).toISOString(),
            change_reason,
        };

        setSubmitLoading(true);
        try {

            const response = await axiosInstance.post(
                `/task/v2/update-deadline/${orderKey}/`,
                payload
            );

            if (response?.status === 200 && response?.data?.status === "success") {
                message.success(response?.data?.data);
                fetchOrderDetails();
                handleModalClose();
            }
        } catch (error) {
            await catchError(error);
        } finally {
            setSubmitLoading(false);
        }
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={{
                deadline: selectedDate,
                change_reason: "",
            }}
        >
            <Row style={{
                flexDirection: window.innerWidth < 768 ? "column" : "row",
                width: "100%",
            }}>
                <Col order={1} lg={24}>
                    <Form.Item
                        name="deadline"
                        label="Deadline"
                        rules={[{ required: true, message: "Please select a deadline!" }]}
                    >
                        <DatePicker
                            value={selectedDate}
                            style={{ width: window.innerWidth < 500 ? "375px" : "100%" }}
                            showTime={{
                                format: "HH:mm",
                                use12Hours: true,
                            }}
                            format="YYYY-MM-DD hh:mm A"
                            onChange={handleDateChange}
                        // popupStyle={{ position: "fixed" }}
                        />
                    </Form.Item>
                </Col>

                <Col order={2} lg={24}>

                    <Form.Item
                        name="change_reason"
                        label="Change Reason"
                        rules={[
                            {
                                required: true,
                                message: "Please provide a reason for the deadline change!",
                            },
                        ]}
                    >
                        <TextArea
                            rows={4}
                            placeholder="Provide a reason for the deadline change"
                        />
                    </Form.Item>
                </Col>



            </Row>
            <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginTop: 20 }}>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={submitLoading}
                >
                    Submit
                </Button>
            </div>

        </Form>
    );
};

export default EditClientDeadline;
