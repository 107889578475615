// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-modal-call .ant-modal-content {
  padding: 0 !important;
}
.outcome .ant-modal-content {
  padding: 0 !important;
}

.outcome .ant-modal-footer{
    padding: 10px ;
}

.outcome .ant-btn {
    background-color: black!important;
    
}


.custom-modal2 .ant-modal-content {
  padding: 0 0px 13px 0 !important;
  border-radius: 12px;
}

.custom-modal2 .ant-modal-body {
  padding: 16px;
}

.custom-modal2 .ant-modal-footer {
  padding: 10px 20px;
}

.custom-modal2 .ant-btn-primary {
  background-color: #040724 !important;
}

.custom-modal2 .ant-btn-disabled:hover {
  background-color: #040724 !important;
}

.custom-modal-header {
  color: white;
  background-color: #040724;
  padding: 10px 0px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin: 0;
  display: flex;

  width: 100%;
  align-items: center;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/page-components/Leads/Modal.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;AACA;EACE,qBAAqB;AACvB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,iCAAiC;;AAErC;;;AAGA;EACE,gCAAgC;EAChC,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,iBAAiB;EACjB,2BAA2B;EAC3B,4BAA4B;EAC5B,SAAS;EACT,aAAa;;EAEb,WAAW;EACX,mBAAmB;EACnB,8BAA8B;AAChC","sourcesContent":[".custom-modal-call .ant-modal-content {\n  padding: 0 !important;\n}\n.outcome .ant-modal-content {\n  padding: 0 !important;\n}\n\n.outcome .ant-modal-footer{\n    padding: 10px ;\n}\n\n.outcome .ant-btn {\n    background-color: black!important;\n    \n}\n\n\n.custom-modal2 .ant-modal-content {\n  padding: 0 0px 13px 0 !important;\n  border-radius: 12px;\n}\n\n.custom-modal2 .ant-modal-body {\n  padding: 16px;\n}\n\n.custom-modal2 .ant-modal-footer {\n  padding: 10px 20px;\n}\n\n.custom-modal2 .ant-btn-primary {\n  background-color: #040724 !important;\n}\n\n.custom-modal2 .ant-btn-disabled:hover {\n  background-color: #040724 !important;\n}\n\n.custom-modal-header {\n  color: white;\n  background-color: #040724;\n  padding: 10px 0px;\n  border-top-left-radius: 8px;\n  border-top-right-radius: 8px;\n  margin: 0;\n  display: flex;\n\n  width: 100%;\n  align-items: center;\n  justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
