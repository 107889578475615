import axiosInstance from "./axiosInstance";
import { catchError } from "../utils/errorHandler";
import { Device } from "@twilio/voice-sdk";
import { message } from "antd";
const LeadsList = {
  fetchNewLeads: async (page = page, pageSize = pageSize, filters = {}) => {
    const {
      name,
      email,
      phone_no,
      assignee,
      manager,
      next_activity,
      status,
      source,
      author,
      active_till,
      created,
      assigned_on,
      manager_assigned_on,
      assignee_assigned_on,
      month,
      activity_date,
      activity_start_date,
      activity_end_date

    } = filters;
    const query = new URLSearchParams({
      page,
      page_size: pageSize,
      ...(name && { name }),
      ...(email && { email }),
      ...(phone_no && { phone_no }),
      ...(assignee && {
        assignee: Array.isArray(assignee) ? assignee.join(",") : assignee,
      }),
      ...(manager && {
        manager: Array.isArray(manager) ? manager.join(",") : manager,
      }),
      ...(next_activity && { next_activity }),
      ...(status && { status }),
      ...(source && { source }),
      ...(author && { author }),
      ...(month && { month }),
      ...(active_till && { active_till }),
      ...(created && { created }),
      ...(assigned_on && { assigned_on }),
      ...(assignee_assigned_on && { assignee_assigned_on }),
      ...(manager_assigned_on && { manager_assigned_on }),
      ...(activity_date && { activity_date }),
      ...(activity_start_date && { activity_start_date }),
      ...(activity_end_date && { activity_end_date }),
    }).toString();

    try {
      const response = await axiosInstance.get(`/lead/v2/all-leads/?${query}`);
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  fetchManagerLeads: async (page = page, pageSize = pageSize, filters = {}) => {
    const {
      name,
      email,
      phone_no,
      assignee,
      manager,
      next_activity,
      status,
      source,
      author,
      active_till,
      created,
      assigned_on,
      manager_assigned_on,
      assignee_assigned_on,
      month,
      activity_date,
      activity_start_date,
      activity_end_date
    } = filters;
    const query = new URLSearchParams({
      page,
      page_size: pageSize,
      ...(name && { name }),
      ...(email && { email }),
      ...(phone_no && { phone_no }),
      ...(month && { month }),
      ...(assignee && {
        assignee: Array.isArray(assignee) ? assignee.join(",") : assignee,
      }),
      ...(manager && {
        manager: Array.isArray(manager) ? manager.join(",") : manager,
      }),
      ...(next_activity && { next_activity }),
      ...(status && { status }),
      ...(source && { source }),
      ...(author && { author }),
      ...(active_till && { active_till }),
      ...(created && { created }),
      ...(assigned_on && { assigned_on }),
      ...(assignee_assigned_on && { assignee_assigned_on }),
      ...(manager_assigned_on && { manager_assigned_on }),
      ...(activity_date && { activity_date }),
      ...(activity_start_date && { activity_start_date }),
      ...(activity_end_date && { activity_end_date }),
    }).toString();
    try {
      const response = await axiosInstance.get(
        `/lead/v2/manager-leads/list/?${query}`
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  fetchAssignedLeads: async (page = page, pageSize = pageSize, filters = {}) => {
    const {
      name,
      email,
      phone_no,
      assignee,
      manager,
      next_activity,
      status,
      source,
      author,
      active_till,
      created,
      assigned_on,
      manager_assigned_on,
      assignee_assigned_on,
      month,
      activity_date,
      activity_start_date,
      activity_end_date
    } = filters;
    const query = new URLSearchParams({
      page,
      page_size: pageSize,
      ...(name && { name }),
      ...(month && { month }),
      ...(email && { email }),
      ...(phone_no && { phone_no }),
      ...(assignee && {
        assignee: Array.isArray(assignee) ? assignee.join(",") : assignee,
      }),
      ...(manager && {
        manager: Array.isArray(manager) ? manager.join(",") : manager,
      }),
      ...(next_activity && { next_activity }),
      ...(status && { status }),
      ...(source && { source }),
      ...(author && { author }),
      ...(active_till && { active_till }),
      ...(created && { created }),
      ...(assigned_on && { assigned_on }),
      ...(assignee_assigned_on && { assignee_assigned_on }),
      ...(manager_assigned_on && { manager_assigned_on }),
      ...(activity_date && { activity_date }),
      ...(activity_start_date && { activity_start_date }),
      ...(activity_end_date && { activity_end_date }),
    }).toString();
    try {
      const response = await axiosInstance.get(
        `/lead/v2/assigned-leads/?${query}`
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  updateAssignee: async (data) => {
    try {
      const response = await axiosInstance.post(
        `/lead/v2/update-assingee/`,
        data
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },

  updateManager: async (data) => {
    try {
      const response = await axiosInstance.post(
        `/lead/v2/update-manager/`,
        data
      );

      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },

  getLeadDetail: async (orderUid) => {
    try {
      const response = await axiosInstance.get(`/lead/v2/detail/${orderUid}/`);
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      return { success: false };
    }
  },

  fetchAuthorsList: async () => {
    try {
      const response = await axiosInstance.get(`/lead/v2/author-names/list/`);
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },

  fetchSourceList: async () => {
    try {
      const response = await axiosInstance.get(`/lead/v2/lead-sources/list/`);
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  fetchAssignees: async () => {
    try {
      const response = await axiosInstance.get(`/lead/v2/assignees-list/`);
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },

  fetchManagers: async () => {
    try {
      const response = await axiosInstance.get(`/lead/v2/managers-list/`);
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },

  getOutcomesDropdownList: async () => {
    try {
      const response = await axiosInstance.get(`/lead/v2/outcomes/dropdown/`);
      if (response.status === 200 && response.data.status === "success") {
        return { success: true, data: response.data.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  getOutcomesLogs: async (leadUid, type) => {
    try {
      const response = await axiosInstance.get(
        `/lead/v2/outcomes/${leadUid}/?type=${type}`
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },

  getStatusDropdownList: async () => {
    try {
      const response = await axiosInstance.get(`/lead/v2/status/dropdown/`);
      if (response.status === 200 && response.data.status === "success") {
        const statuses = response.data.data.map(([value, label]) => ({
          value,
          label,
        }));
        return { success: true, data: statuses };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },

  getPriortiesList: async () => {
    try {
      const response = await axiosInstance.get(`/lead/v2/priority-list/`);
      if (response.status === 200 && response.data.status === "success") {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  getLeadLevelDropdownList: async () => {
    try {
      const response = await axiosInstance.get(`/lead/v2/level-list/`);
      if (response.status === 200 && response.data.status === "success") {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  getLeadSourceDropdownList: async () => {
    try {
      const response = await axiosInstance.get(`/lead/v2/lead-sources/list/`);
      if (response.status === 200 && response.data.status === "success") {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  fetchTwilioToken: async () => {
    try {
      // Sending a GET request with query parameter 'type=lead'
      const response = await axiosInstance.get(`/tc/twilio-token/`, {
        params: {
          type: "lead",
        },
      });

      if (response.status === 200) {
        const token = response.data.data?.token;

        if (typeof token === "string") {
          return { success: true, token };
        } else {
          console.error("Invalid token format");
          return { success: false };
        }
      }
      return { success: false };
    } catch (error) {
      console.error("Error fetching Twilio token:", error);
      // Handle the error appropriately, but do not throw uncaught errors
      return { success: false };
    }
  },

  initializeTwilioDevice: async () => {
    const { success, token } = await LeadsList.fetchTwilioToken();
    if (!success || !token) {
      console.error("Failed to fetch token");
      return null;
    }

    try {
      const device = new Device(token, {
        codecPreferences: ["opus", "pcmu"],
        fakeLocalDTMF: true,
        enableRingingState: true,
      });

      console.log("Initializing Twilio device with token:", token);

      // Refresh the token when it's about to expire
      device.on(Device.EventName.TokenWillExpire, async () => {
        console.log("Twilio token will expire soon, fetching a new one...");
        const { success: newSuccess, token: newToken } =
          await LeadsList.fetchTwilioToken();
        if (newSuccess && newToken) {
          device.updateToken(newToken);
          console.log("Updated Twilio token successfully");
        } else {
          console.error("Failed to update Twilio token");
        }
      });

      // Handle the call disconnect event
      device.on(Device.EventName.Disconnect, () => {
        console.log("Call disconnected");
      });

      device.addListener("registered", () => {
        console.log("The device is ready to receive incoming calls.");
      });

      return device;
    } catch (error) {
      console.error("Error initializing Twilio device:", error);
      // Catch error without crashing the app
      return null;
    }
  },

  makeTwilioCall: async (lead_uid, uid, type) => {
    try {
      // Step 1: Always fetch a new token
      const { success, token } = await LeadsList.fetchTwilioToken();

      if (!success || !token) {
        console.error("Failed to fetch token");
        return null; // Abort the call process if token fetching fails
      }
      console.log(token, "tokem");
      // Step 2: Initialize Twilio Device with the new token
      const device = new Device(token, {
        codecPreferences: ["opus", "pcmu"],
        fakeLocalDTMF: true,
        enableRingingState: true,
      });

      // Step 3: Handle token expiration as a fallback
      device.on(Device.EventName.TokenWillExpire, async () => {
        console.log("Twilio token will expire soon, fetching a new one...");
        const { success: newSuccess, token: newToken } =
          await LeadsList.fetchTwilioToken();
        if (newSuccess && newToken) {
          device.updateToken(newToken);
          console.log("Updated Twilio token successfully");
        } else {
          console.error("Failed to update Twilio token");
        }
      });

      // Step 4: Handle device events (optional, based on your use case)
      device.on(Device.EventName.Disconnect, () => {
        console.log("Call disconnected");
      });

      device.addListener("registered", () => {
        console.log("The device is ready to receive incoming calls.");
      });

      // Step 5: Make the call
      const params = { lead_uid, uid, type }; // Include type in params
      console.log("Sending params to Twilio device.connect:", params);

      const connection = await device.connect({ params });
      console.log("Call initiated:", connection);

      connection.on("accept", () => {
        console.log("Call accepted, conversation started");
        // You can start a call duration timer here if needed
      });

      return connection; // Return the connection object if needed
    } catch (error) {
      // console.error("Error in makeTwilioCall:", error);
      // Gracefully handle the error to prevent unhandled errors
      return null;
    }
  },

  // fetchTwilioToken: async () => {
  //   try {
  //     // Sending a GET request with query parameter 'type=task'
  //     const response = await axiosInstance.get(`/tc/twilio-token/`, {
  //       params: {
  //         type: "lead",
  //       },
  //     });

  //     if (response.status === 200) {
  //       const token = response.data.data?.token;

  //       if (typeof token === "string") {
  //         return { success: true, token };
  //       } else {
  //         console.error("Invalid token format");
  //         return { success: false };
  //       }
  //     }
  //     return { success: false };
  //   } catch (error) {
  //     // Handle the error
  //     await catchError(error);
  //     return { success: false };
  //   }
  // },
  // initializeTwilioDevice: async () => {
  //   const { success, token } = await LeadsList.fetchTwilioToken();
  //   if (!success || !token) {
  //     console.error("Failed to fetch token");
  //     return null;
  //   }

  //   try {
  //     const device = new Device(token, {
  //       codecPreferences: ["opus", "pcmu"],
  //       fakeLocalDTMF: true,
  //       enableRingingState: true,
  //     });

  //     console.log("Initializing Twilio device with token:", token);

  //     device.on(Device.EventName.TokenWillExpire, async () => {
  //       const { success: newSuccess, token: newToken } =
  //         await LeadsList.fetchTwilioToken();
  //       if (newSuccess && newToken) {
  //         device.updateToken(newToken);
  //       } else {
  //         console.error("Failed to update Twilio token");
  //       }
  //     });

  //     device.on(Device.EventName.Disconnect, () => {
  //       console.log("Call disconnected");
  //     });

  //     device.addListener("registered", () => {
  //       console.log("The device is ready to receive incoming calls.");
  //     });

  //     return device;
  //   } catch (error) {
  //     console.error("Error initializing Twilio device:", error);
  //     return null;
  //   }
  // },

  // // Assuming this function is in the LeadsList service
  // makeTwilioCall: async (lead_uid, uid, type) => {
  //   try {
  //     // Step 1: Always fetch a new token
  //     const { success, token } = await LeadsList.fetchTwilioToken();

  //     if (!success || !token) {
  //       console.error("Failed to fetch token");
  //       return null; // Abort the call process if token fetching fails
  //     }

  //     // Step 2: Initialize Twilio Device with the new token
  //     const device = new Device(token, {
  //       codecPreferences: ["opus", "pcmu"],
  //       fakeLocalDTMF: true,
  //       enableRingingState: true,
  //     });

  //     // Step 3: Handle token expiration as a fallback
  //     device.on(Device.EventName.TokenWillExpire, async () => {
  //       console.log("Twilio token will expire soon, fetching a new one...");
  //       const { success: newSuccess, token: newToken } =
  //         await LeadsList.fetchTwilioToken();
  //       if (newSuccess && newToken) {
  //         device.updateToken(newToken);
  //         console.log("Updated Twilio token successfully");
  //       } else {
  //         console.error("Failed to update Twilio token");
  //       }
  //     });

  //     // Step 4: Handle device events (optional, based on your use case)
  //     device.on(Device.EventName.Disconnect, () => {
  //       console.log("Call disconnected");
  //     });

  //     device.addListener("registered", () => {
  //       console.log("The device is ready to receive incoming calls.");
  //     });

  //     // Step 5: Make the call
  //     const params = { lead_uid, uid, type }; // Include type in params
  //     console.log("Sending params to Twilio device.connect:", params);

  //     const connection = await device.connect({ params });
  //     console.log("Call initiated:", connection);

  //     connection.on("accept", () => {
  //       console.log("Call accepted, conversation started");
  //       // You can start a call duration timer here if needed
  //     });

  //     return connection; // Return the connection object if needed
  //   } catch (error) {
  //     console.error("Error in makeTwilioCall:", error);
  //     throw error;
  //   }
  // },

  // Function to submit an outcome
  submitOutcome: async (leadUid, outcomeData) => {
    try {
      const response = await axiosInstance.post(
        `/lead/v2/outcomes/${leadUid}/`,
        outcomeData
      );
      if (response.status === 201) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  // In LeadsList service file
  getCallLogs: async (leadUid) => {
    try {
      const response = await axiosInstance.get(`/lead/v2/call/logs/${leadUid}`);
      if (response.status === 200 && response.data.status === "success") {
        return { success: true, data: response.data.data.call_logs };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  // New Function to Post Activity Logs
  postActivityLogs: async (leadId, action) => {
    const validActions = ["whatsapp", "call", "social_media", "mail"];
    if (!validActions.includes(action)) {
      console.error(
        `Invalid action: ${action}. Must be one of ${validActions.join(", ")}`
      );
      return { success: false, error: "Invalid action parameter" };
    }

    try {
      const response = await axiosInstance.get(
        `/lead/v2/track/activity-logs/${leadId}/?action=${action}`
      );

      if (response.status === 201) {
        // console.log(`Activity log posted for Lead ID: ${leadId}, Action: ${action}`);
        return { success: true, data: response.data };
      }

      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  getActionsData: async (leadId) => {
    try {
      const response = await axiosInstance.get(
        `/lead/v2/manage-lead/${leadId}`
      );

      if (response.status === 200) {
        // console.log(`Activity log posted for Lead ID: ${leadId}, Action: ${action}`);
        return { success: true, data: response.data };
      }

      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },

  getActivityLogs: async (leadId) => {
    try {
      const response = await axiosInstance.get(`/lead/v2/logs/${leadId}`);

      if (response.status === 200) {
        // console.log(`Activity log posted for Lead ID: ${leadId}, Action: ${action}`);
        return { success: true, data: response.data };
      }

      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  getCountryList: async () => {
    try {
      const response = await axiosInstance.get(`api/v2/country-list`);

      if (response.status === 200) {
        // console.log(`Activity log posted for Lead ID: ${leadId}, Action: ${action}`);
        return { success: true, data: response.data };
      }

      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  createNewLead: async (leadData) => {
    try {
      const response = await axiosInstance.post(`/lead/v2/create/`, leadData);
      if (response.status === 201) {
        return { success: true, data: response.data };
      }
      return { success: false, message: "Failed to create lead" };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },

  postImportLeads: async (leadData) => {
    try {
      const response = await axiosInstance.post(`/lead/v2/create/`, leadData);
      if (response.status === 201) {
        return { success: true, data: response.data };
      }
      return { success: false, message: "Failed to create lead" };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
};

export default LeadsList;
