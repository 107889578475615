import React, { useEffect, useState } from "react";
import { Collapse, Card, Typography, Row, Col, Button, Skeleton } from "antd";
import { useSelector } from "react-redux";
import OrderSummaryTable from "./OrderSummaryTable";
import SubscriberInfoPanel from "./SubscriberInfoPanel";
import ClientDetailsPanel from "./ClientDetailsPanel";
import OrderMenu from "./OrderMenu";
import "./Details.css";
import { getCurrencySymbol } from "../../helpers/helper";
import SalesInfoPanel from "./SalesInfoAccordian";
import PaymentDetailsAccord from "./PaymentDetailsAccord";
import orderService from "../../services/OrderLists";
import FilesTable from "./FilesTable";
import { MoreOutlined } from "@ant-design/icons";
import FreelancerAccord from "./FreelancerAccord";
import { useParams } from "react-router-dom";
import { col } from "framer-motion/client";

const Details = ({
  permissions,
  filesData,
  orderData,
}) => {
  const { orderKey } = useParams();
  
  const { Paragraph } = Typography;
  const [loadingStates, setLoadingStates] = useState({
    subscriber: false,
    client: false,
    freelancer: false,
    sales: false,
    payment: false,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [subscriberInfo, setSubscriberInfo] = useState([]);
  const [clientInfo, setClientInfo] = useState({});
  const [freelancerInfo, setFreelancerInfo] = useState({});
  const [orderDetails, setOrderDetails] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { Panel } = Collapse;
  const userType = useSelector((state) => state.auth.userType);
  console.log("freelancerInfo", freelancerInfo);

  // Track which panels have been loaded
  const [loadedPanels, setLoadedPanels] = useState({
    subscriber: false,
    client: false,
    freelancer: false,
  });



  const fetchPanelData = async (panelKey) => {
    setLoadingStates((prev) => ({ ...prev, [panelKey]: true }));
    
    try {
      switch (panelKey) {
        case "subscriber":
          const subscriberResult = await orderService.getSubscriberInfo(orderKey);
          if (subscriberResult.success) {
            setSubscriberInfo(subscriberResult.data.data || []);
            setLoadedPanels((prev) => ({ ...prev, subscriber: true }));
          }
          break;
        case "client":
          const clientInfoResult = await orderService.getClientInfo(orderKey);
          if (clientInfoResult.success) {
            setClientInfo(clientInfoResult.data.data);
            setLoadedPanels((prev) => ({ ...prev, client: true }));
          }
          break;
        case "freelancer":
          const freelancerInfoResult = await orderService.getFreelancerInfo(orderKey);
          if (freelancerInfoResult.success) {
            setFreelancerInfo(freelancerInfoResult.data.data);
            setLoadedPanels((prev) => ({ ...prev, freelancer: true }));
          }
          break;
        // Add cases for other panels if needed
        default:
          break;
      }
    } catch (error) {
      console.error(`Failed to fetch ${panelKey} data:`, error);
    } finally {
      setLoadingStates((prev) => ({ ...prev, [panelKey]: false }));
    }
  };


  useEffect(() => {
    if (orderData?.data) {
      const data = orderData.data;
      const tags = data.tags || [];
      const paymentLinks = data?.payment_links || [];
      const budget = parseFloat(data?.payment?.budget) || 0;
      const budgetWithoutDiscount = parseFloat(data?.payment?.budget_without_discount) || 0;
      const discount = budgetWithoutDiscount - budget;
  
      // Predefined fields
      const orderDetails = {
        // payment: data?.payment?.payment_status || null,
        // budget: budget
        //   ? `${data?.payment?.budget} ${getCurrencySymbol(data?.payment?.currency)}`
        //   : null,
        // amountPaid: data?.payment?.amount_paid
        //   ? `${data?.payment?.amount_paid} ${getCurrencySymbol(data?.payment?.currency)}`
        //   : null,
        // discount: discount
        //   ? `${discount.toFixed(2)} ${getCurrencySymbol(data?.payment?.currency)}`
        //   : null,
        // qualityRequirement: data?.quality || null,
        // referencingStyle: data?.referencing_style || "N/A",
        // tags,
        internalStatus: data?.internal_status || "N/A",
        specification: data?.specification || "None",
        // paymentLinks: paymentLinks || null,
        // otherPaymentLinks: paymentLinks.slice(1) || [],
        descriptions: data?.description || "N/A",
        salessituation: data?.salessituation,
      };
  
      // Dynamically add any additional keys that are not predefined
      Object.keys(data).forEach((key) => {
        if (!orderDetails.hasOwnProperty(key)) {
          orderDetails[key] = data[key] || "N/A";
        }
      });
  
      setOrderDetails(orderDetails);
    }
  }, [orderData]);
  

  // useEffect(() => {
  //   if (orderData?.data) {
  //     const data = orderData.data;
  //     const tags = data.tags ? data.tags : [];
  //     const paymentLinks = data?.payment_links || [];
  //     const budget = parseFloat(data?.payment?.budget) || 0;
  //     const budgetWithoutDiscount = parseFloat(data?.payment?.budget_without_discount) || 0;
  //     const discount = budgetWithoutDiscount - budget;

  //     setOrderDetails({
  //       payment: data?.payment?.payment_status || null,
  //       budget: budget
  //         ? `${data?.payment?.budget} ${getCurrencySymbol(data?.payment?.currency)}`
  //         : null,
  //       amountPaid: data?.payment?.amount_paid
  //         ? `${data?.payment?.amount_paid} ${getCurrencySymbol(data?.payment?.currency)}`
  //         : null,
  //       discount: discount
  //         ? `${discount.toFixed(2)} ${getCurrencySymbol(data?.payment?.currency)}`
  //         : null,
  //       qualityRequirement: data?.quality || null,
  //       referencingStyle: data?.referencing_style || "N/A",
  //       tags,
  //       internalStatus: data?.internal_status || "N/A",
  //       specification: data?.specification || "None",
  //       paymentLink: paymentLinks[0] || null,
  //       otherPaymentLinks: paymentLinks.slice(1) || [],
  //       descriptions: data?.description || "N/A",
  //       salessituation: data?.salessituation,

  //     });
  //   }
  // }, [orderData]);

  const handlePanelChange = (activeKeys) => {
    if (activeKeys.includes("1") && !loadedPanels.subscriber) fetchPanelData("subscriber");
    if (activeKeys.includes("2") && !loadedPanels.client) fetchPanelData("client");
    if (activeKeys.includes("5") && !loadedPanels.freelancer) fetchPanelData("freelancer");
  };

  if (!Object.keys(orderDetails).length) return <div>Loading...</div>;

  const handleViewMore = () => setIsModalVisible(true);
  const handleModalClose = () => setIsModalVisible(false);

  const columns = Object.keys(orderDetails)
    .filter((key) => orderDetails[key] !== null && orderDetails[key] !== undefined && key !== "descriptions")
    .map((key) => ({
      key,
      field: key.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase()),
      value: orderDetails[key],
    }));

  return (
    <div>
      <Row gutter={[16, 16]} style={{ width: "99%", margin: "auto", marginBottom: "20px" }}>
        <Col xs={{ span: 24, order: 2 }} md={{ span: 16, order: 1 }}>
          <Card
            title={<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><span>Description</span></div>}
            style={{ marginBottom: "20px", textAlign: "left" }}
            headStyle={{ textAlign: "left" }}
          >
            <Paragraph style={{ textAlign: "left", maxHeight: "300px" }}>
              <div style={{ maxHeight: "300px", overflowY: "auto" }} dangerouslySetInnerHTML={{
                __html: orderDetails.descriptions.replace(/<br\s*\/?>/gi, "").replace(/<\/?p>/gi, ""),
              }}/>
            </Paragraph>
          </Card>
          {permissions.includes("can_view_order_files") && <FilesTable filesData={filesData} />}
          <OrderSummaryTable orderDetails={orderDetails} columns={columns} />
        </Col>

        <Col xs={{ span: 24, order: 1 }} md={{ span: 8, order: 2 }}>
  <Collapse onChange={handlePanelChange}>
    {permissions.includes("can_view_order_subscriber_list") && (
      <Panel style={{ textAlign: "left" }} header="Subscriber Details" key="1">
        {loadingStates.subscriber ? (
          <Skeleton active />
        ) : (
          <SubscriberInfoPanel
            subscriberInfo={subscriberInfo}
            isModalVisible={isModalVisible}
            handleViewMore={handleViewMore}
            handleModalClose={handleModalClose}
          />
        )}
      </Panel>
    )}
    {permissions.includes("can_view_order_client_info") && (
      <Panel style={{ textAlign: "left" }} header="Client Details" key="2">
        {loadingStates.client ? (
          <Skeleton active />
        ) : (
          <ClientDetailsPanel clientInfo={clientInfo} />
        )}
      </Panel>
    )}
    {permissions.includes("can_view_order_sales_details") && (
      <Panel style={{ textAlign: "left" }} header="Sales Details" key="3">
        {loadingStates.sales ? (
          <Skeleton active />
        ) : (
          <SalesInfoPanel orderData={orderData} />
        )}
      </Panel>
    )}
    {permissions.includes("can_view_order_payment_details") && (
      <Panel style={{ textAlign: "left" }} header="Payment Details" key="4">
        {loadingStates.payment ? (
          <Skeleton active />
        ) : (
          <PaymentDetailsAccord orderData={orderData} />
        )}
      </Panel>
    )}
    {permissions.includes("can_view_order_freelancer_info") && (
      <Panel style={{ textAlign: "left" }} header="Freelancer Details" key="5">
        {loadingStates.freelancer ? (
          <Skeleton active />
        ) : (
          <FreelancerAccord freelancerInfo={freelancerInfo} />
        )}
      </Panel>
    )}
  </Collapse>
</Col>

      </Row>
    </div>
  );
};

export default Details;
