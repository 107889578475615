import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Flex,
  Select,
  Table,
  message,
  Button,
  Typography,
  Tag,
  Spin,
} from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import moment from "moment";
import { debounce } from "lodash";
import deductionsService from "../../services/deductionsService";
import DeductionSearchFilters from "../../page-components/Deduction/DeductionsSearchFilters";
import { useMediaQuery } from "react-responsive";
import MobileViewDeductions from "../../page-components/Deduction/MobileViewDeductions";
import usePermissions from "../../hooks/usePermissions";
const { Option } = Select;
const { Paragraph } = Typography;

const Deductions = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [deductions, setDeductions] = useState([]);
  const { hasPermission } = usePermissions();
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(() => {
    if (hasPermission("can_view_my_subscriber_deductions")) {
      return "myDeductions"; // Default to "My Deductions" if permission exists
    } else if (hasPermission("can_view_all_subscriber_deductions")) {
      return "allDeductions"; // Default to "All Deductions" if only this permission exists
    }
    return ""; // No valid options available
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const [filtersList, setFiltersList] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({});

  // Fetch deductions with debounce to prevent excessive API calls
  const fetchDeductions = useCallback(
    debounce(async (page, pageSize, activeFilters) => {
      setLoading(true);
      try {
        const response =
          selectedOption === "myDeductions"
            ? await deductionsService.getMyDeductions(
                page,
                pageSize,
                activeFilters
              )
            : await deductionsService.getAllDeductions(
                page,
                pageSize,
                activeFilters
              );

        if (response.success) {
          setFiltersList(response.data.data.filters);
          setDeductions(response.data.data.results);
          setPagination((prev) => ({
            ...prev,
            total: response.data.data.count || 0,
          }));
        } else {
          message.error("Failed to fetch deductions");
        }
      } catch (error) {
        message.error("An error occurred while fetching deductions");
      } finally {
        setLoading(false);
      }
    }, 300),
    [selectedOption, pagination.current]
  );

  // Fetch data when selectedOption or filters change

  useEffect(() => {
    fetchDeductions(pagination.current, pagination.pageSize, filters);
  }, [selectedOption, filters, pagination.current, pagination.pageSize]);

  // Memoized column generation
  const columns = useMemo(() => {
    if (deductions.length === 0) return [];

    const keys = Object.keys(deductions[0]).filter(
      (key) => !["uid", "id", "added_on"].includes(key)
    );

    // Define the key column first
    const keyColumn = {
      title: "Key",
      dataIndex: "key",
      key: "key",
      fixed: "left",
      render: (text) => (
        <Link
          to={`/order-management/my-orders/${text}`}
          style={{ color: "#1890ff" }}>
          {text}
        </Link>
      ),
    };

    // Generate other columns dynamically
    const otherColumns = keys
      .filter((key) => key !== "key")
      .map((key) => ({
        title: key
          .replace(/_/g, " ")
          .replace(/\b\w/g, (char) => char.toUpperCase()),
        dataIndex: key,
        key: key,
        render: (text, record) => {
          if (key === "remaining_words") {
            return <Tag color="purple">{text}</Tag>;
          }
          if (key === "deducted_word") {
            return <Tag color="red">{text}</Tag>;
          }
          if (key === "added_by" && record.added_on) {
            return (
              <div>
                <div>{text}</div>
                <div style={{ fontSize: "12px", color: "#888" }}>
                  {moment(record.added_on).local().format("DD-MM-YYYY hh:mm a")}
                </div>
              </div>
            );
          }
          return text ?? "N/A";
        },
      }));

    return [keyColumn, ...otherColumns]; // Place "key" column first
  }, [deductions]);

  const handleSearchClick = (newFilters) => {
    setFilters(newFilters);
    setPagination((prev) => ({ ...prev, current: 1 }));
  };

  const handleCancelSearch = () => {
    setFilters({});
    setPagination((prev) => ({ ...prev, current: 1 }));
  };

  return (
    <Flex vertical style={{ padding: 10 }} gap={10}>
      {showFilters && (
        <div className="filter-container">
          <DeductionSearchFilters
            filtersList={filtersList}
            onSearch={handleSearchClick}
            getAllClients={handleCancelSearch}
          />
        </div>
      )}
      {!loading && (
        <Flex
          gap={isMobile ? 8 : 0}
          style={{ width: "100%", flexDirection: isMobile ? "column" : "row" }}
          justify="space-between"
          align={isMobile ? "flex-end" : "center"}>
          <Paragraph
            style={{
              fontWeight: 500,
              opacity: 0.6,
              textAlign: "left",
              margin: 0,
              padding: 0,
            }}>
            Showing{" "}
            {Math.min(
              (pagination.current - 1) * pagination.pageSize + 1,
              pagination.total
            )}{" "}
            -{" "}
            {Math.min(
              pagination.current * pagination.pageSize,
              pagination.total
            )}{" "}
            of {pagination.total} Deductions
          </Paragraph>
          <Flex gap={4} align="center">
            <Select
              value={selectedOption}
              style={{ width: 200, textAlign: "left" }}
              onChange={setSelectedOption}>
              {hasPermission("can_view_my_subscriber_deductions") && (
                <Option value="myDeductions">My Deductions</Option>
              )}

              {hasPermission("can_view_all_subscriber_deductions") && (
                <Option value="allDeductions">All Deductions</Option>
              )}
            </Select>

            <Button
              type="primary"
              icon={<FilterOutlined />}
              onClick={() => setShowFilters(!showFilters)}
              style={{ marginLeft: "10px", background: "#040724" }}
            />
          </Flex>
          {/* <Flex gap={4} align="center">
            <Select
              defaultValue="myDeductions"
              style={{ width: 200, textAlign: "left" }}
              onChange={setSelectedOption}>
              <Option value="myDeductions">My Deductions</Option>
              <Option value="allDeductions">All Deductions</Option>
            </Select>
            <Button
              type="primary"
              icon={<FilterOutlined />}
              onClick={() => setShowFilters(!showFilters)}
              style={{ marginLeft: "10px", background: "#040724" }}
            />
          </Flex> */}
        </Flex>
      )}
      {isMobile ? (
        loading ? (
          <Spin size="large" />
        ) : (
          <MobileViewDeductions
            deductions={deductions}
            pagination={pagination}
            setPagination={setPagination}
          />
        )
      ) : (
        <Table
          columns={columns}
          dataSource={deductions}
          loading={loading}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            onChange: (page, pageSize) => {
              setPagination({ ...pagination, current: page, pageSize });
            },
          }}
          rowKey="id"
        />
      )}
    </Flex>
  );
};

export default Deductions;
