import axiosInstance from "./axiosInstance";
import { catchError } from "../utils/errorHandler";
import { Device } from "@twilio/voice-sdk";
import store from "../redux/store";
import { startLoading, stopLoading } from "../redux/slices/loadingSlice";
const orderService = {
  getTransaction: async (orderKey) => {
    try {
      const response = await axiosInstance.get(
        `task/v2/client-transactions/${orderKey}/`
      );

      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  addTransaction: async (orderKey, transactionData) => {
    try {
      store.dispatch(startLoading());
      const response = await axiosInstance.post(
        `task/v2/add-transactions/${orderKey}/`,
        transactionData,
        {
          headers: {
            "Content-Type": "application/json", // Assuming you're sending JSON data
          },
        }
      );
      store.dispatch(stopLoading());

      if (response.status === 201) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      store.dispatch(stopLoading());
      await catchError(error);
      return { success: false };
    }
  },
  addPayment: async (orderKey, paymentData) => {
    try {
      store.dispatch(startLoading());
      const response = await axiosInstance.post(
        `task/v2/quotation/${orderKey}/`,
        paymentData,
        {
          headers: {
            "Content-Type": "application/json", // Assuming you're sending JSON data
          },
        }
      );
      store.dispatch(stopLoading());

      if (response.status === 201) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      store.dispatch(stopLoading());
      await catchError(error);
      return { success: false };
    }
  },
  editPayment: async (orderKey, paymentData) => {
    try {
      store.dispatch(startLoading());
      const response = await axiosInstance.put(
        `task/v2/quotation/${orderKey}/`,
        paymentData,
        {
          headers: {
            "Content-Type": "application/json", // Assuming you're sending JSON data
          },
        }
      );
      store.dispatch(stopLoading());

      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      store.dispatch(stopLoading());
      await catchError(error);
      return { success: false };
    }
  },

  createTask: async (formData) => {
    try {
      store.dispatch(startLoading());
      const response = await axiosInstance.post(`task/v2/create/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      store.dispatch(stopLoading());
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      store.dispatch(stopLoading());
      await catchError(error);
      return { success: false };
    }
  },
  fetchOrders: async (
    urgencyLevel,
    filters = {},
    page = 1,
    pageSize = 10,
    selectedOption
  ) => {
    try {
      store.dispatch(startLoading());
      //   if (filters.subscriber && typeof filters.subscriber === "string") {
      //     filters.subscriber = filters.subscriber.split(",");
      // }

      // Destructure filters
      const {
        key,
        title,
        status,
        deadline_hard,
        moved_to_fl,
        payment_status,
        client_deadline,
        allotted_deadline,
        order,
        sort,
        subscriber,
        email,
        username,
      } = filters;

      // Create query string with dynamic parameters
      const query = new URLSearchParams({
        page,
        page_size: pageSize,
        ...(urgencyLevel !== null &&
          urgencyLevel !== undefined && { urgency_level: urgencyLevel }), // Include only if not null/undefined
        ...(key && { key }),
        ...(title && { title }),
        ...(status && { status }),
        ...(deadline_hard && { deadline_hard }),
        ...(client_deadline && { client_deadline }),
        ...(allotted_deadline && { allotted_deadline }),
        ...(moved_to_fl !== null &&
          moved_to_fl !== undefined && {
            moved_to_fl,
          }),
        ...(payment_status && { payment_status }),
        ...(subscriber &&
          (Array.isArray(subscriber)
            ? { subscriber: subscriber.join(",") }
            : { subscriber })),
        ...(order && { order }),
        ...(sort && { sort }),
        ...(email && { email }),
        ...(username && { username }),
        // Add subscriber filter as a comma-separated list
      }).toString();

      // Make API request with query parameters
      var response;
      if (selectedOption === "my-orders") {
        response = await axiosInstance.get(`task/v2/order-list/?${query}`);
      } else if (selectedOption === "team-orders") {
        response = await axiosInstance.get(
          `task/v2/order-list/?${query}&team_orders=true`
        );
      }

      store.dispatch(stopLoading());

      if (response.status === 200) {
        return { success: true, data: response.data };
      }

      return { success: false };
    } catch (error) {
      store.dispatch(stopLoading());
      await catchError(error);
      return { success: false };
    }
  },

  getFreelancerDetail: async (orderKey) => {
    try {
      store.dispatch(startLoading());
      const response = await axiosInstance.get(
        `task/v2/freelancer-info/${orderKey}`
      );
      store.dispatch(stopLoading());
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      store.dispatch(stopLoading());
      await catchError(error);
      return { success: false };
    }
  },
  getClientComments: async (orderKey) => {
    try {
      store.dispatch(startLoading());
      const response = await axiosInstance.get(
        `task/v2/comment/client/${orderKey}/`
      );
      store.dispatch(stopLoading());

      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      store.dispatch(stopLoading());
      await catchError(error);
      return { success: false };
    }
  },

  getSubscribersDropDown: async () => {
    try {
      store.dispatch(startLoading());
      const response = await axiosInstance.get(
        `task/v2/order-list/subscribers/filter/dropdown`
      );
      store.dispatch(stopLoading());

      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      store.dispatch(stopLoading());
      await catchError(error);
      return { success: false };
    }
  },

  getInternalComments: async (orderKey) => {
    try {
      store.dispatch(startLoading());
      const response = await axiosInstance.get(
        `task/v2/comment/internal-comment/${orderKey}/`
      );
      store.dispatch(stopLoading());

      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      store.dispatch(stopLoading());
      await catchError(error);
      return { success: false };
    }
  },
  getFreelancerComments: async (orderKey) => {
    try {
      store.dispatch(startLoading());
      const response = await axiosInstance.get(
        `task/v2/comment/freelancer-comment/${orderKey}/`
      );
      store.dispatch(stopLoading());

      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      store.dispatch(stopLoading());
      await catchError(error);
      return { success: false };
    }
  },
  deleteInternalComment: async (orderKey, uuid) => {
    try {
      store.dispatch(startLoading());
      const response = await axiosInstance.delete(
        `task/v2/comment/internal-comment/${orderKey}/?uid=${uuid}`
      );

      store.dispatch(stopLoading());

      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      store.dispatch(stopLoading());
      await catchError(error);
      return { success: false };
    }
  },
  // fetchOrders: async () => {
  //   try {
  //     store.dispatch(startLoading());
  //     const response = await axiosInstance.get(`task/v2/api/order-list/`);
  //     store.dispatch(stopLoading());
  //     if (response.status === 200) {
  //       return { success: true, data: response.data };
  //     }
  //     return { success: false };
  //   } catch (error) {
  //     store.dispatch(stopLoading());
  //     await catchError(error);
  //     return { success: false };
  //   }
  // },

  // searchOrders: async (orderKey) => {
  //   try {
  //     store.dispatch(startLoading());
  //     const response = await axiosInstance.get(
  //       `/api/v2/task/list/?search=${orderKey}`
  //     );
  //     store.dispatch(stopLoading());
  //     if (response.status === 200) {
  //       return { success: true, data: response.data };
  //     }
  //     return { success: false };
  //   } catch (error) {
  //     store.dispatch(stopLoading());
  //     await catchError(error);
  //     return { success: false };
  //   }
  // },
  getQualitiesDropdown: async () => {
    try {
      store.dispatch(startLoading());
      const response = await axiosInstance.get(`task/v2/quality/dropdown`);
      store.dispatch(stopLoading());

      if (response.status === 200) {
        return response.data;
      }
      return null;
    } catch (error) {
      store.dispatch(stopLoading());
      await catchError(error);
      return null;
    }
  },
  getSpecificationDropdown: async () => {
    try {
      store.dispatch(startLoading());
      const response = await axiosInstance.get(
        `task/v2/specification/dropdown/`
      );
      store.dispatch(stopLoading());

      if (response.status === 200) {
        return response.data;
      }
      return null;
    } catch (error) {
      store.dispatch(stopLoading());
      await catchError(error);
      return null;
    }
  },
  getReferenceDropdown: async () => {
    try {
      store.dispatch(startLoading());
      const response = await axiosInstance.get(`task/v2/reference/dropdown/`);
      store.dispatch(stopLoading());

      if (response.status === 200) {
        return response.data;
      }
      return null;
    } catch (error) {
      store.dispatch(stopLoading());
      await catchError(error);
      return null;
    }
  },
  editTask: async (taskId, taskData) => {
    try {
      store.dispatch(startLoading());
      const response = await axiosInstance.put(
        `task/v2/update/${taskId}/`,
        taskData,
        {
          headers: {
            "Content-Type": "application/json", // Assuming taskData is JSON
          },
        }
      );
      store.dispatch(stopLoading());

      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      store.dispatch(stopLoading());
      await catchError(error);
      return { success: false };
    }
  },
  getSituationDropdown: async () => {
    try {
      store.dispatch(startLoading());
      const response = await axiosInstance.get(`task/v2/situation/dropdown/`);
      store.dispatch(stopLoading());

      if (response.status === 200) {
        return response.data;
      }
      return null;
    } catch (error) {
      store.dispatch(stopLoading());
      await catchError(error);
      return null;
    }
  },

  getBdmMappingDropdown: async () => {
    try {
      store.dispatch(startLoading());
      const response = await axiosInstance.get(`task/v2/bdm-mapping/dropdown/`);
      store.dispatch(stopLoading());

      if (response.status === 200) {
        return response.data;
      }
      return null;
    } catch (error) {
      store.dispatch(stopLoading());
      await catchError(error);
      return null;
    }
  },

  getTimezonesDropdown: async () => {
    try {
      store.dispatch(startLoading());
      const response = await axiosInstance.get(`task/v2/timezones/dropdown/`);
      store.dispatch(stopLoading());

      if (response.status === 200) {
        return response.data;
      }
      return null;
    } catch (error) {
      store.dispatch(stopLoading());
      await catchError(error);
      return null;
    }
  },
  getOrderDetails: async (orderKey) => {
    try {
      store.dispatch(startLoading());
      const response = await axiosInstance.get(`task/v2/detail/${orderKey}`);
      store.dispatch(stopLoading());
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      console.log(error, "234567890-34567890-4567890-");
      store.dispatch(stopLoading());
      // await catchError(error);
      return { success: false };
    }
  },
  getWriterStatusDropdown: async () => {
    try {
      store.dispatch(startLoading());
      const response = await axiosInstance.get(
        `task/v2/dropdown/writer-status/`
      );
      store.dispatch(stopLoading());
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      store.dispatch(stopLoading());
      // await catchError(error);
      return { success: false };
    }
  },
  getRunningTask: async (orderKey) => {
    try {
      store.dispatch(startLoading());
      const response = await axiosInstance.get(`task/v2/writer/running-task/${orderKey}`);
      store.dispatch(stopLoading());
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      store.dispatch(stopLoading());
      // await catchError(error);
      return { success: false };
    }
  },
  updateWriterTask: async (data) => {
    try {
      store.dispatch(startLoading());
      const response = await axiosInstance.post(`task/v2/update/writer-status` , data);
      store.dispatch(stopLoading());
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      console.log(error, "234567890-34567890-4567890-");
      store.dispatch(stopLoading());
      await catchError(error);
      return { success: false };
    }
  },
  getOrderFiles: async (orderKey) => {
    try {
      store.dispatch(startLoading());
      const response = await axiosInstance.get(
        `task/v2/requirement/file/${orderKey}`
      );
      store.dispatch(stopLoading());
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      store.dispatch(stopLoading());
      await catchError(error);
      return { success: false };
    }
  },

  getSolutionFiles: async (orderKey) => {
    try {
      store.dispatch(startLoading());
      const response = await axiosInstance.get(
        `task/v2/solution/file/${orderKey}`
      );
      store.dispatch(stopLoading());
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      store.dispatch(stopLoading());
      await catchError(error);
      return { success: false };
    }
  },

  getOrderComments: async (orderKey) => {
    try {
      store.dispatch(startLoading());
      const response = await axiosInstance.get(`task/v2/comments/${orderKey}`);
      store.dispatch(stopLoading());
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      store.dispatch(stopLoading());
      await catchError(error);
      return { success: false };
    }
  },

  getOrderFeedback: async (orderKey) => {
    try {
      store.dispatch(startLoading());
      const response = await axiosInstance.get(
        `task/v2/task-feedback/${orderKey}`
      );
      store.dispatch(stopLoading());
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      store.dispatch(stopLoading());
      await catchError(error);
      return { success: false };
    }
  },
  getSubscriberInfo: async (orderKey) => {
    try {
      store.dispatch(startLoading());
      const response = await axiosInstance.get(
        `task/v2/subscriber-info/${orderKey}`
      );
      store.dispatch(stopLoading());
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      store.dispatch(stopLoading());
      await catchError(error);
      return { success: false };
    }
  },
  getFreelancerInfo: async (orderKey) => {
    try {
      store.dispatch(startLoading());
      const response = await axiosInstance.get(
        `/task/v2/freelancer-info/${orderKey}`
      );
      store.dispatch(stopLoading());
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      store.dispatch(stopLoading());
      // await catchError(error);
      return { success: false };
    }
  },
  getClientInfo: async (orderKey) => {
    try {
      store.dispatch(startLoading());
      const response = await axiosInstance.get(
        `task/v2/client-info/${orderKey}`
      );
      store.dispatch(stopLoading());
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      store.dispatch(stopLoading());
      await catchError(error);
      return { success: false };
    }
  },
  getStatusLogs: async (orderKey) => {
    try {
      store.dispatch(startLoading());
      const response = await axiosInstance.get(
        `task/v2/status/log/${orderKey}`
      );
      store.dispatch(stopLoading());
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      store.dispatch(stopLoading());
      await catchError(error);
      return { success: false };
    }
  },

  getStatusList: async () => {
    try {
      store.dispatch(startLoading());
      const response = await axiosInstance.get(`task/v2/status/dropdown`);
      // console.log(response , 'respmse')
      store.dispatch(stopLoading());
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      store.dispatch(stopLoading());
      await catchError(error);
      return { success: false };
    }
  },

  getTwilioNumberAndUid: async () => {
    try {
      const response = await axiosInstance.get(`/tc/api/twilio-numbers/`);
      store.dispatch(stopLoading());
      if (response.status === 200) {
        const data = response.data.data;
        return { success: true, data };
      }
      return { success: false, data: [] };
    } catch (error) {
      store.dispatch(stopLoading());
      await catchError(error);
      return { success: false, data: [] };
    }
  },
  fetchTwilioToken: async () => {
    try {
      // Sending a GET request with query parameter 'type=task'
      const response = await axiosInstance.get(`/tc/twilio-token/`, {
        params: {
          type: "task",
        },
      });

      if (response.status === 200) {
        const token = response.data.data?.token;

        if (typeof token === "string") {
          return { success: true, token };
        } else {
          console.error("Invalid token format");
          return { success: false };
        }
      }
      return { success: false };
    } catch (error) {
      // Handle the error
      await catchError(error);
      return { success: false };
    }
  },

  initializeTwilioDevice: async () => {
    const { success, token } = await orderService.fetchTwilioToken();
    if (!success || !token) {
      console.error("Failed to fetch token");
      return null;
    }

    try {
      const device = new Device(token, {
        codecPreferences: ["opus", "pcmu"],
        fakeLocalDTMF: true,
        enableRingingState: true,
      });

      console.log("Initializing Twilio device with token:", token);

      device.on(Device.EventName.TokenWillExpire, async () => {
        const { success: newSuccess, token: newToken } =
          await orderService.fetchTwilioToken();
        if (newSuccess && newToken) {
          device.updateToken(newToken);
        } else {
          console.error("Failed to update Twilio token");
        }
      });

      device.on(Device.EventName.Disconnect, () => {
        console.log("Call disconnected");
      });

      device.addListener("registered", () => {
        console.log("The device is ready to receive incoming calls.");
      });

      return device;
    } catch (error) {
      console.error("Error initializing Twilio device:", error);
      return null;
    }
  },

  makeTwilioCall: async (task_key, uid) => {
    const device = await orderService.initializeTwilioDevice();
    if (!device) return;

    try {
      const params = { task_key, uid };
      console.log("Sending params to Twilio device.connect:", params);

      const connection = await device.connect({ params });

      // Log the connection object for debugging
      console.log("Call initiated:", connection);
      return connection;
    } catch (error) {
      console.error("Error making call:", error);
      throw error;
    }
  },

  getUserTypes: async () => {
    try {
      const response = await axiosInstance.get("task/v2/comment/user-types/");
      if (response.status === 200) {
        return response.data.data;
      }
      return [];
    } catch (error) {
      await catchError(error);
      return [];
    }
  },

  forwardInternalComment: async (commentUid, payload) => {
    try {
      const response = await axiosInstance.post(
        `task/v2/comment/forward-internal-comment/${commentUid}/`,
        payload // Include the payload (array of user types) in the POST request
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error); // Handle the error with your existing function
      return { success: false };
    }
  },
  moveToClientComment: async (commentUid, payload) => {
    try {
      const response = await axiosInstance.post(
        `task/v2/comment/move-comment-client/${commentUid}/`,
        payload
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error); // Handle the error with your existing function
      return { success: false };
    }
  },
  moveToFreelancerComment: async (commentUid, payload) => {
    try {
      const response = await axiosInstance.post(
        `task/v2/comment/move-comment-fl/${commentUid}/`,
        payload
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  moveToInternalCommentClient: async (commentUid, payload) => {
    try {
      const response = await axiosInstance.post(
        `task/v2/comment/client/move-to-internal/${commentUid}/`,
        payload
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  deleteClientComment: async (orderKey, uuid) => {
    try {
      store.dispatch(startLoading());
      const response = await axiosInstance.delete(
        `task/v2/comment/client/${orderKey}/?uid=${uuid}`
      );

      store.dispatch(stopLoading());

      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      store.dispatch(stopLoading());
      await catchError(error);
      return { success: false };
    }
  },

  getCommentTypes: async () => {
    try {
      const response = await axiosInstance.get(`task/v2/comment/types/`, {});
      if (response.status === 200) {
        return { success: true, data: response.data.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  addInternalComment: async (orderKey, payload) => {
    try {
      const response = await axiosInstance.post(
        `task/v2/comment/internal-comment/${orderKey}/`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  addClientComment: async (orderKey, payload) => {
    try {
      const response = await axiosInstance.post(
        `task/v2/comment/client/${orderKey}/`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },

  addFreelancerComment: async (orderKey, payload) => {
    try {
      const response = await axiosInstance.post(
        `task/v2/comment/freelancer-comment/${orderKey}/`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  getSolutionFilesTypes: async () => {
    try {
      const response = await axiosInstance.get(`task/v2/file-types/`);
      if (response.status === 200) {
        return { success: true, data: response.data.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },

  createSolutionStageOne: async (orderKey, payload) => {
    try {
      const response = await axiosInstance.post(
        `task/v2/add-solutions-stage-one/${orderKey}/`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  createSolutionStageTwo: async (orderKey, payload) => {
    try {
      const response = await axiosInstance.post(
        `task/v2/add-solutions-stage-two/${orderKey}/`,
        payload,
        {}
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  getFileList: async (orderKey) => {
    try {
      const response = await axiosInstance.get(
        `task/v2/add-solutions-stage-one/${orderKey}/`
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  getSolutionSubscribers: async (orderKey) => {
    try {
      const response = await axiosInstance.get(
        `task/v2/task-subscribers/${orderKey}/`
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },

  duplitextSubmissionList: async (orderKey) => {
    try {
      const response = await axiosInstance.get(
        `copyscape/v2/submissions/${orderKey}/`
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },

  duplitextSubmissionDetail: async (submissionId) => {
    try {
      const response = await axiosInstance.get(
        `copyscape/v2/submission-detail/${submissionId}/`
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  submitDuplitext: async (orderKey, payload) => {
    try {
      const response = await axiosInstance.post(
        `copyscape/v2/submissions/${orderKey}/`,
        payload
      );
      if (response.status === 201) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },

  addToIndex: async (uid) => {
    try {
      const response = await axiosInstance.post(
        `copyscape/v2/submission-index/${uid}/`
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },

  markAsVerified: async (orderKey, payload) => {
    try {
      const response = await axiosInstance.post(
        `task/v2/mark-as-verified/${orderKey}/`,
        payload
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },

  markAsComplete: async (orderKey, payload) => {
    try {
      const response = await axiosInstance.post(
        `task/v2/mark-as-completed/${orderKey}/`,
        payload
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  markAsFlVerified: async (orderKey, payload) => {
    try {
      const response = await axiosInstance.post(
        `task/v2/marked-as-fl-complete/${orderKey}/`,
        payload
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },

  deleteSolutionFile: async (orderKey, payload) => {
    try {
      const response = await axiosInstance.post(
        `task/v2/delete-solution-file/${orderKey}/`,
        payload
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },

  addFeedback: async (orderKey, payload) => {
    try {
      const response = await axiosInstance.post(
        `task/v2/add-feedback/${orderKey}/`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 201) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  editFeedback: async (orderKey, payload) => {
    try {
      const response = await axiosInstance.put(
        `task/v2/edit-feedback/${orderKey}/`,
        payload
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  addFeedbackFlOrder: async (orderKey, payload) => {
    try {
      const response = await axiosInstance.post(
        `task/v2/add-fl-task-feedback/${orderKey}/`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 201) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  // New function to send solution files to the client
  moveFileToClient: async (orderKey, fileData) => {
    try {
      store.dispatch(startLoading());
      const response = await axiosInstance.post(
        `task/v2/send-solution-files-to-client/${orderKey}/`,
        fileData
      );
      store.dispatch(stopLoading());

      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      store.dispatch(stopLoading());
      await catchError(error);
      return { success: false };
    }
  },
  getFlSubscribers: async (orderKey) => {
    try {
      const response = await axiosInstance.get(
        `task/v2/task-fl-subscribers/${orderKey}/`
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  // New function for downloading the zip file
  downloadZipFile: async (taskKey) => {
    try {
      store.dispatch(startLoading());
      const response = await axiosInstance.get(
        `task/v2/download-files/${taskKey}/`,
        {
          responseType: "blob", // Important for file download
        }
      );
      store.dispatch(stopLoading());

      if (response.status === 200) {
        const blob = new Blob([response.data], { type: "application/zip" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `files_${taskKey}.zip`); // Filename for the downloaded file
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);

        return { success: true };
      }
      return { success: false };
    } catch (error) {
      store.dispatch(stopLoading());
      await catchError(error);
      return { success: false };
    }
  },
  markAsDone: async (orderKey, payload) => {
    try {
      const response = await axiosInstance.post(
        `task/v2/feedback-mark-as-done/${orderKey}/`,
        payload
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  markAsVerifiedFeedcback: async (orderKey, payload) => {
    try {
      const response = await axiosInstance.post(
        `task/v2/feedback-mark-as-verified/${orderKey}/`,
        payload
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  removeFeedback: async (orderKey, payload) => {
    try {
      const response = await axiosInstance.post(
        `task/v2/remove-feedback/${orderKey}/`,
        payload
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  addFeedbackFiles: async (orderKey, payload) => {
    try {
      const response = await axiosInstance.post(
        `task/v2/add-feedback-files/${orderKey}/`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },

  getAddFeedbackNotifee: async (orderKey) => {
    try {
      const response = await axiosInstance.get(
        `task/v2/feedback-files-notify/${orderKey}/`
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },

  getFeedbackFiles: async (orderKey) => {
    try {
      const response = await axiosInstance.get(
        `task/v2/remove-feedback-files/${orderKey}/`
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  removeFeedbackFiles: async (orderKey, payload) => {
    try {
      const response = await axiosInstance.post(
        `task/v2/remove-feedback-files/${orderKey}/`,
        payload
      );
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
};

export default orderService;
