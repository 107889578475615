import React, { useState } from "react";
import {
  Card,
  Tag,
  Tooltip,
  Row,
  Col,
  Button,
  Table,
  Popover,
  Dropdown,
  Menu,
  Flex,
  Empty,
  Descriptions,
} from "antd";
import "./Details.css";
import {
  CopyOutlined,
  FileOutlined,
  DownOutlined,
  UpOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import {
  CalendarOutlined,
  FileTextOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { MoreVerticalIcon } from "lucide-react";
import usePermissions from "../../hooks/usePermissions";
import MarkAsDone from "../../components/MyOrderActions/MarkAsDoneFeedback";
import RemoveFeedback from "../../components/MyOrderActions/RemoveFeedback";
import AddFeedbackFiles from "../../components/MyOrderActions/AddFeedbackFiles";
import MarkAsVerifiedFeedback from "../../components/MyOrderActions/MarkAsVerifiedFeedback";
import RemoveFeedbackFiles from "../../components/MyOrderActions/removeFeedbackFiles";
import EditFeedback from "../../components/MyOrderActions/EditFeedBack";
const FeedbackCard = ({ feedbackData, getFeedBack }) => {
  const [expandedKeys, setExpandedKeys] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalType, setModalType] = useState(null); // To track the modal type
  const { hasPermission } = usePermissions();
  const [selectedKey, setSelectedKey] = useState(null); // To track the selected feedback key
  const [selectedFeedback, setSelectedFeedback] = useState(null); // Store selected feedback data

  const toggleExpand = (key) => {
    setExpandedKeys((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const showModal = (type, key) => {
    console.log(key, "key");
    setModalType(type);
    setSelectedKey(key); // Save the key for use in the modal
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false); // Close modal
    setModalType(null); // Reset modal type
    setSelectedKey(null); // Reset selected feedback key
  };

  const menu = (feedbackKey) => (
    <Menu>
      {hasPermission("can_mark_as_done_task_feedback") && (
        <Menu.Item
          key="markAsDone"
          onClick={() => showModal("markAsDone", feedbackKey)}>
          Mark as Done
        </Menu.Item>
      )}

      {hasPermission("can_mark_as_verified_task_feedback") && (
        <Menu.Item
          key="markAsVerified"
          onClick={() => showModal("markAsVerified", feedbackKey)}>
          Mark as Verified
        </Menu.Item>
      )}
      {hasPermission("can_edit_task_feedback") && (
        <Menu.Item key="edit" onClick={() => showModal("edit", feedbackKey)}>
          Edit Feedback
        </Menu.Item>
      )}

      {hasPermission("can_delete_task_feedback") && (
        <Menu.Item
          key="delete"
          onClick={() => showModal("delete", feedbackKey)}>
          Delete Feedback
        </Menu.Item>
      )}
      {hasPermission("can_remove_task_feedback_files") && (
        <Menu.Item
          key="remove"
          onClick={() => showModal("remove", feedbackKey)}>
          Remove Files
        </Menu.Item>
      )}
      {hasPermission("can_add_task_feedback_files") && (
        <Menu.Item
          key="addFiles"
          onClick={() => showModal("addFiles", feedbackKey)}>
          Add Files
        </Menu.Item>
      )}
    </Menu>
  );
  const getDarkenedColor = (color) => {
    switch (color) {
      case "#FDE8E8":
        return "#F05252"; // Darkened Red
      case "#ECFDF5":
        return "#0E9F6E"; // Darkened Green
      case "#FDFDEA":
        return "#E3A008"; // Darkened Yellowish
      case "#E5E7EB":
        return "#374151";
      default:
        return color; // Return the original color if no match
    }
  };

  return (
    <Row style={{ padding: " 0 10px" }} gutter={[16, 16]} justify="start">
      {feedbackData?.feedback?.length === 0 ? (
        <Flex
          justify="center"
          align="center"
          style={{ width: "100%", height: "50vh" }}>
          <Empty></Empty>
        </Flex>
      ) : (
        feedbackData?.feedback.map((feedback) => {
          // const backgroundColor = !feedback.is_visible
          //   ? "#E5E7EB" // Black if is_visible is false
          //   : feedback.is_verified
          //   ? "#FDFDEA" // Yellow for is_verified
          //   : feedback.is_completed
          //   ? "#ecfdf5" // Green for is_completed
          //   : "#FDE8E8"; // Default color
          const getBackgroundColor = () => {
            if (!feedback.is_visible) return "#E5E7EB";
            if (feedback.is_verified) return "#FDFDEA";
            if (feedback.is_completed) return "#ECFDF5";
            return "#FDE8E8";
          };

          const backgroundColor = getBackgroundColor();
          const borderColor = getDarkenedColor(backgroundColor); // Get specific darkened color
          // Determine if the menu should be disabled
          const isMenuDisabled = !feedback.is_visible;

          return (
            <Col xs={24} sm={12} lg={12} key={feedback.key}>
              <Card
                className="feedback-card"
                style={{
                  "--feedback-bg": backgroundColor,

                  maxHeight: 300,
                  minHeight: 300,
                  overflow: "hidden",
                  overflowY: "scroll",
                  textAlign: "left",
                  borderTop: `4px solid ${borderColor}`, // Top stroke
                  color: !feedback.is_visible ? "#6B7280" : "#111827",
                }}
                title={
                  <div
                    className="feedback-card-title"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}>
                      <span style={{ fontWeight: "500" }}>{feedback.key}</span>
                    </div>

                    <Flex align="center" justify="center" gap={10}>
                      <Tag
                        color={
                          feedback.status === "Pending" ? "orange" : "green"
                        }>
                        {feedback.status}
                      </Tag>

                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Dropdown
                          className="feedback-drop"
                          overlayStyle={{ boxShadow: "none", borderTop: "0px" }}
                          overlay={menu(feedback.key)}
                          trigger={["click"]}
                          disabled={isMenuDisabled}>
                          <MoreVerticalIcon
                            style={{
                              fontSize: "16px",
                              cursor: "pointer",
                              backgroundColor: "#f0f0f0", // Grey background
                              borderRadius: "50%", // Circular button
                              padding: "5px",
                              border: "1px solid #d9d9d9", // Border color
                            }}
                          />
                        </Dropdown>
                      </div>
                    </Flex>
                  </div>
                }>
                <Descriptions column={1} bordered size="small">
                  <Descriptions.Item
                    label={
                      <span>
                        <CalendarOutlined style={{ marginRight: 8 }} />
                        Deadline
                      </span>
                    }>
                    <span style={{ color: "#ff4d4f" }}>
                      {moment(feedback.deadline_hard)
                        .local()
                        .format("DD-MM-YYYY hh:mm A")}
                    </span>
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={
                      <span>
                        <FileTextOutlined style={{ marginRight: 8 }} />
                        Word Count
                      </span>
                    }>
                    {feedback.extra_word_count}
                  </Descriptions.Item>

                  <Descriptions.Item
                    label={
                      <span>
                        <FileOutlined style={{ marginRight: 8 }} />
                        Files
                      </span>
                    }
                    style={{ height: "10px" }}>
                    {Array.isArray(feedback.files) &&
                    feedback.files.length > 0 ? (
                      <div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}>
                          <Tooltip title="Click to view attached files">
                            <Popover
                              placement="left"
                              trigger="hover"
                              content={
                                <Table
                                  style={{ width: 500 }}
                                  dataSource={feedback.files}
                                  columns={[
                                    {
                                      title: "File Name",
                                      dataIndex: "file_name",
                                      key: "file_name",
                                      render: (text, record) => (
                                        <Popover content={text}>
                                          <a
                                            href={record.file_url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{
                                              color: "#1890ff",
                                              display: "block",
                                              maxWidth: "300px",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                            }}>
                                            {text}
                                          </a>
                                        </Popover>
                                      ),
                                    },
                                    {
                                      title: "Created At",
                                      dataIndex: "created_at",
                                      key: "created_at",
                                      render: (date) =>
                                        moment(date)
                                          .local()
                                          .format("DD-MM-YYYY hh:mm A"),
                                    },
                                  ]}
                                  pagination={false}
                                  scroll={{ y: 150 }}
                                  rowKey="uid"
                                  size="small"
                                />
                              }>
                              <span
                                style={{
                                  cursor: "pointer",
                                  color: "#1890ff",
                                }}>
                               {feedback.files.length}{" "}
                                {feedback.files.length === 1 ? "file" : "files"}
                              </span>
                            </Popover>
                          </Tooltip>
                        </div>
                      </div>
                    ) : (
                      <span>No files</span>
                    )}
                  </Descriptions.Item>
                </Descriptions>
                <div
                  dangerouslySetInnerHTML={{
                    __html: feedback.details,
                  }}
                />
              </Card>
            </Col>
          );
        })
      )}
      {/* Render Modals based on selected type */}
      {isModalVisible && modalType === "markAsDone" && (
        <MarkAsDone
          isVisible={isModalVisible}
          setIsModalVisible={closeModal}
          feedbackKey={selectedKey} // Pass the feedback key
          getFeedBack={getFeedBack}
        />
      )}{" "}
      {isModalVisible && modalType === "markAsVerified" && (
        <MarkAsVerifiedFeedback
          isVisible={isModalVisible}
          setIsModalVisible={closeModal}
          feedbackKey={selectedKey} // Pass the feedback key
          getFeedBack={getFeedBack}
        />
      )}
      {isModalVisible && modalType === "delete" && (
        <RemoveFeedback
          isVisible={isModalVisible}
          setIsModalVisible={closeModal}
          feedbackKey={selectedKey}
          getFeedBack={getFeedBack}
        />
      )}
      {isModalVisible && modalType === "addFiles" && (
        <AddFeedbackFiles
          isVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          feedbackKey={selectedKey} // Pass the selected feedback key here
          getFeedBack={getFeedBack}
        />
      )}
      {isModalVisible && modalType === "remove" && (
        <RemoveFeedbackFiles
          isVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          feedbackKey={selectedKey} // Pass the selected feedback key here
          getFeedBack={getFeedBack}
        />
      )}
      {isModalVisible && modalType === "edit" && (
        <EditFeedback
          isVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          feedbackKey={selectedKey} // Pass the selected feedback key here
          feedbackData={feedbackData}
          getFeedBack={getFeedBack}
        />
      )}
    </Row>
  );
};

export default FeedbackCard;
