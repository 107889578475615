import React, { useState, useEffect } from "react";
import axiosInstance from "../../services/axiosInstance";
import { catchError } from "../../utils/errorHandler";
import { useParams } from "react-router-dom";
import { Checkbox, List, Spin, Button, message } from "antd";
import orderService from "../../services/OrderLists";

function MarkAsVerified({ setIsModalVisible }) {
  const { orderKey } = useParams();
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const fetchSubscribersList = async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(
        `task/v2/task-subscribers-list/${orderKey}/`
      );
      const data = res?.data?.data || [];
      setDataSource(data);
    } catch (err) {
      catchError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubscribersList();
  }, [orderKey]);

  const handleUserSelect = (uuid) => {
    setSelectedUsers((prev) => {
      if (prev.includes(uuid)) {
        return prev.filter((id) => id !== uuid);
      } else {
        return [...prev, uuid];
      }
    });
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitLoading(true);
      const selectedUsernames = dataSource
        .filter((item) => selectedUsers.includes(item.uuid))
        .map((item) => item.username);

      const response = await orderService.markAsVerified(orderKey, {
        subscribers: selectedUsernames,
      });

      if (response.success) {
        setIsSubmitLoading(false);
        message.success("Successfully marked as verified");
        setIsModalVisible(false);
      }
    } catch (err) {
      setIsSubmitLoading(false);

      message.error("An error occurred");
      console.error(err);
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div>
      <h2>Are you sure to move this order for next step?</h2>
      {dataSource.length > 0 && (
        <List
          dataSource={dataSource}
          renderItem={(item) => (
            <List.Item>
              <Checkbox
                checked={selectedUsers.includes(item.uuid)}
                onChange={() => handleUserSelect(item.uuid)}>
                {item.subscriber} ({item.type})
              </Checkbox>
            </List.Item>
          )}
        />
      )}

      <div style={{ marginTop: 16, textAlign: "right" }}>
        <Button
          type="primary"
          loading={isSubmitLoading}
          onClick={handleSubmit}
          style={{ color: "white" }}
          disabled={selectedUsers.length === 0}>
          Submit
        </Button>
      </div>
    </div>
  );
}

export default MarkAsVerified;
