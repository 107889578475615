import React, { useEffect, useState } from 'react';
import { Tabs, theme } from 'antd';
import StickyBox from 'react-sticky-box';
import OrderLogServivces from '../../../services/OrderLogServices';
import { useParams } from 'react-router-dom';
import TaskLogsComp from './LogsView/TaskLogs';
import SpinLoading from '../SpinLoading';
import SubscriberLogs from './LogsView/SubscriberLogs';
import PaymentTaskLogs from './LogsView/PaymentTaskLogs';
import CallLogsInfo from './LogsView/CallLogsInfo';
import AcceptRejectLogs from './LogsView/AcceptRejectLogs';
import ActivityLogs from './LogsView/ActivityLogs';


const Logs = ({ permissions }) => {
    const { token: { colorBgContainer } } = theme.useToken();
    const { orderKey } = useParams();

    const [taskLogsData, setTaskLogsData] = useState([]);
    const [subscriberLogsData, setSubscriberLogsData] = useState([]);
    const [paymentLogsData, setPaymentLogsData] = useState([]);
    const [callLogsData, setCallLogsData] = useState([]);
    const [acceptRejectLogsData, setAcceptRejectLogsData] = useState([]);
    const [activityLogsData, setActivityLogsData] = useState([]);


    const [loading, setLoading] = useState({
        taskLogs: false,
        subscriberLogs: false,
        paymentLogs: false,
        acceptLogs: false,
        callLogs: false,
        callAllocation: false,
        activityLogs: false
    });

    const fetchTaskLogs = async () => {
        try {
            setLoading((prev) => ({ ...prev, taskLogs: true }));
            const result = await OrderLogServivces.getOrderTaskLogsInfo(orderKey);
            if (result.success && result.data.status === "success") {
                setTaskLogsData(result?.data?.data?.results);
            }
        } catch (error) {
            console.error("Error fetching task logs:", error);
        } finally {
            setLoading((prev) => ({ ...prev, taskLogs: false }));
        }
    };

    const fetchSubscriberLogs = async () => {
        try {
            setLoading((prev) => ({ ...prev, subscriberLogs: true }));
            const result = await OrderLogServivces.getSubscriberLogsInfo(orderKey);
            if (result.success && result.data.status === "success") {
                setSubscriberLogsData(result?.data?.data?.results);
            }
        } catch (error) {
            console.error("Error fetching subscriber logs:", error);
        } finally {
            setLoading((prev) => ({ ...prev, subscriberLogs: false }));
        }
    };

    const fetchPaymentLogs = async () => {
        try {
            setLoading((prev) => ({ ...prev, paymentLogs: true }));
            const result = await OrderLogServivces.getPaymentLogsInfo(orderKey);
            if (result.success && result.data.status === "success") {
                setPaymentLogsData(result?.data?.data?.results);
            }
        } catch (error) {
            console.error("Error fetching Payment logs:", error);
        } finally {
            setLoading((prev) => ({ ...prev, paymentLogs: false }));
        }
    };

    const fetchCallLogs = async () => {
        try {
            setLoading((prev) => ({ ...prev, callLogs: true }));
            const result = await OrderLogServivces.getCallLogsInfo(orderKey);
            if (result.success && result.data.status === "success") {
                setCallLogsData(result?.data?.data?.results);
            }
        } catch (error) {
            console.error("Error fetching Call logs:", error);
        } finally {
            setLoading((prev) => ({ ...prev, callLogs: false }));
        }
    };

    const fetchAcceptRejectLogs = async () => {
        try {
            setLoading((prev) => ({ ...prev, acceptLogs: true }));
            const result = await OrderLogServivces.getAcceptRejectLogsInfo(orderKey);
            if (result.success && result.data.status === "success") {
                setAcceptRejectLogsData(result?.data?.data);
            }
        } catch (error) {
            console.error("Error fetching Accept/Reject logs:", error);
        } finally {
            setLoading((prev) => ({ ...prev, acceptLogs: false }));
        }
    };

    const fetchActivityLogs = async () => {
        try {
            setLoading((prev) => ({ ...prev, activityLogs: true }));
            const result = await OrderLogServivces.getActivityLogsInfo(orderKey);
            if (result.success && result.data.status === "success") {
                setActivityLogsData(result?.data?.data?.results);
            }
        } catch (error) {
            console.error("Error fetching Accept/Reject logs:", error);
        } finally {
            setLoading((prev) => ({ ...prev, activityLogs: false }));
        }
    }

    useEffect(() => {
        fetchTaskLogs();
    }, [orderKey]);

    const handleTabChange = async (key) => {
        if (key === "2") {
            fetchSubscriberLogs()
        } else if (key === "3") {
            fetchPaymentLogs()
        } else if (key === "4") {
            fetchAcceptRejectLogs()
        } else if (key === "5") {
            fetchCallLogs()
        } else if (key === "6") {
            fetchActivityLogs()
        }
    };

    const items = [
        permissions.includes("can_view_order_action_task_logs") && {
            label: 'Task Logs',
            key: '1',
            children: loading.taskLogs ? <SpinLoading /> : <TaskLogsComp taskLogsData={taskLogsData} />,
        },
        permissions.includes("can_view_order_action_subsrcibers_logs") && {
            label: 'Subscriber Logs',
            key: '2',
            children: loading.subscriberLogs ? <SpinLoading /> : <SubscriberLogs subscriberLogsData={subscriberLogsData} />,
        },
        permissions.includes("can_view_order_action_payment_logs") && {
            label: 'Payment Logs',
            key: '3',
            children: loading.paymentLogs ? <SpinLoading /> : <PaymentTaskLogs paymentLogsData={paymentLogsData} />,
        },
        permissions.includes("can_view_order_action_accept_reject_logs") && {
            label: 'Accept/Reject Logs',
            key: '4',
            children: loading.acceptLogs ? <SpinLoading /> : <AcceptRejectLogs acceptRejectLogsData={acceptRejectLogsData} />,
        },
        permissions.includes("can_view_order_action_call_logs") && {
            label: 'Call Logs',
            key: '5',
            children: loading.callLogs ? <SpinLoading /> : <CallLogsInfo callLogsData={callLogsData} />,
        },
        permissions.includes("can_view_order_action_activity_logs") && {
            label: 'Activity Logs',
            key: '6',
            children: loading.activityLogs ? <SpinLoading /> : <ActivityLogs activityLogsData={activityLogsData} />,
        },
        // {
        //     label: 'Call Allocation Logs',
        //     key: '6',
        //     children: 'Content of Call Allocation Logs',
        // },
    ].filter(Boolean);

    const renderTabBar = (props, DefaultTabBar) => (
        <StickyBox offsetTop={0} offsetBottom={20} style={{ zIndex: 1 }}>
            <DefaultTabBar {...props} style={{ background: colorBgContainer }} />
        </StickyBox>
    );

    return (
        <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} items={items} onChange={handleTabChange} />
    );
};

export default Logs;
