import React, { useEffect, useState } from "react";
import {
    Card,
    Form,
    Input,
    Select,
    Button,
    Typography,
    Flex,
    Statistic,
    message,
    Row,
    Col,
} from "antd";
import { SwapOutlined, SyncOutlined } from "@ant-design/icons";
import axiosInstance from "../../services/axiosInstance";
import commonService from "../../services/commonService";
import { catchError } from "../../utils/errorHandler";
import moment from 'moment';
import { useMediaQuery } from "react-responsive";

const { Title, Text } = Typography;
const { Option } = Select;

const CurrencyConverter = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);
    const [hasUserInteracted, setHasUserInteracted] = useState(false);
    const [dropdownCurrencies, setDropdownCurrencies] = useState({ currency: [] });
    const [disableConvert, setDisableConvert] = useState(false);
    const [disableFrom, setDisableFrom] = useState(false);
    const [disableTo, setDisableTo] = useState(false);
    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

    useEffect(() => {
        const fetchCurrencyList = async () => {
            try {
                const response = await commonService.getCurrencyList();
                if (response?.data?.data?.currency) {
                    setDropdownCurrencies({ currency: response.data.data.currency });
                } else {
                    message.error("Failed to load currency list");
                }
            } catch (error) {
                message.error("Error loading currency list");
            }
            setLoading(false);
        };

        fetchCurrencyList();
    }, []);

    const handleFromCurrencyChange = (value) => {
        if (!hasUserInteracted) setHasUserInteracted(true);

        if (value !== "INR") {
            form.setFieldsValue({ toCurrency: "INR" });
        }

        checkDisableButton();
    };

    const handleToCurrencyChange = (value) => {
        if (!hasUserInteracted) setHasUserInteracted(true);

        if (value !== "INR") {
            form.setFieldsValue({ fromCurrency: "INR" });
        }

        checkDisableButton();
    };

    const checkDisableButton = () => {
        const { fromCurrency, toCurrency } = form.getFieldsValue();

        if (fromCurrency === "INR" && toCurrency === "INR") {
            setDisableConvert(true);
            setDisableFrom(false);
            setDisableTo(false);
        } else {
            setDisableConvert(false);
            if (fromCurrency !== "INR") {
                setDisableFrom(false);
                setDisableTo(true);
            } else if (toCurrency !== "INR") {
                setDisableFrom(true);
                setDisableTo(false);
            } else {
                setDisableFrom(false);
                setDisableTo(false);
            }
        }
    };

    //   const handleSwapCurrencies = () => {
    //     if (!hasUserInteracted) return;

    //     const values = form.getFieldsValue();
    //     if (values.fromCurrency === "INR" || values.toCurrency === "INR") {
    //       form.setFieldsValue({
    //         fromCurrency: values.toCurrency,
    //         toCurrency: values.fromCurrency,
    //       });
    //     }
    //     checkDisableButton();
    //   };

    const onFinish = async (values) => {
        try {
            setResult(null);
            setLoading(true);
            const payload = {
                amount: values.amount,
                from_currency: values.fromCurrency,
                to_currency: values.toCurrency,
            };

            // Uncomment below when API is ready
            const response = await axiosInstance.post("/api/v2/inr/currency-convertor/", payload);
            if (response?.status && response?.data?.status) {
                setResult(response?.data?.data)
            }
        } catch (error) {
            await catchError(error)
            setLoading(false)
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ padding: isMobile ? "16px" : "24px" }}>
            <Row
                gutter={isMobile ? 0 : 16}
                style={{
                    flexDirection: isMobile ? "column" : "row",
                    width: "100%",
                }}>
                <Col xs={24} lg={12} order={1} style={{ marginBottom: isMobile ? 16 : 0 }}>
                    <Card
                        bordered={false}
                        style={{
                            borderRadius: "12px",
                            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)",
                            height: "100%",
                        }}
                    >
                        <Title level={3} style={{ marginBottom: "24px", color: "#1F2937" }}>
                            INR Currency Converter
                        </Title>

                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={onFinish}
                            initialValues={{
                                fromCurrency: "INR",
                                toCurrency: "USD",
                                amount: "",
                            }}
                        >
                            <Form.Item
                                name="amount"
                                label="Amount"
                                rules={[
                                    { required: true, message: "Please enter amount" },
                                    { pattern: /^\d*\.?\d*$/, message: "Please enter a valid number" },
                                ]}
                            >
                                <Input size="large" placeholder="Enter amount" style={{ width: "100%" }} />
                            </Form.Item>

                            <Flex gap="middle" align="flex-end">
                                <Form.Item name="fromCurrency" label="From" style={{ flex: 1 }} rules={[{ required: true, message: "Please select currency" }]}>
                                    <Select size="large" style={{ width: "100%" }} onChange={handleFromCurrencyChange} disabled={disableFrom}>
                                        {dropdownCurrencies?.currency?.map((currency) => (
                                            <Option key={currency} value={currency}>
                                                {currency}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Button type="link" icon={<SwapOutlined />} style={{ marginBottom: "24px" }} />

                                <Form.Item name="toCurrency" label="To" style={{ flex: 1 }} rules={[{ required: true, message: "Please select currency" }]}>
                                    <Select size="large" style={{ width: "100%" }} onChange={handleToCurrencyChange} disabled={disableTo}>
                                        {dropdownCurrencies?.currency?.map((currency) => (
                                            <Option key={currency} value={currency}>
                                                {currency}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Flex>

                            <Form.Item style={{ textAlign: "right" }}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    icon={loading && <SyncOutlined spin />}
                                    disabled={loading || disableConvert}
                                    style={{ backgroundColor: '#36c6d3' }}
                                >
                                    {loading ? "Converting..." : "Convert Now"}
                                </Button>
                            </Form.Item>

                        </Form>
                    </Card>
                </Col>

                <Col xs={24} lg={12} order={2}>
                    <Card
                        bordered={false}
                        style={{
                            borderRadius: "12px",
                            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)",
                            height: "100%",
                            background: "#f8fafc",
                        }}
                    >
                        {result ? (
                            <Flex vertical gap="large">
                                <Card
                                    bordered={false}
                                    style={{
                                        background: "white",
                                        borderRadius: "12px",
                                        boxShadow: "0 2px 8px rgba(54, 198, 211, 0.1)",
                                    }}
                                >
                                    <Flex vertical gap="middle">
                                        {/* Original Amount */}
                                        <Flex vertical>
                                            <Text type="secondary" style={{ fontSize: '14px', marginBottom: '4px' }}>
                                                Original Amount
                                            </Text>
                                            <Statistic
                                                value={form.getFieldValue("amount") || 0}
                                                suffix={form.getFieldValue("fromCurrency")}
                                                precision={2}
                                                valueStyle={{ color: '#1F2937', fontSize: '24px' }}
                                            />

                                        </Flex>

                                        {/* Exchange Rate Indicator */}
                                        <Flex align="center" style={{ margin: '16px 0' }}>
                                            <div style={{
                                                flex: 1,
                                                height: '1px',
                                                background: 'linear-gradient(to right, #36c6d3, #f0f0f0)'
                                            }} />
                                            <Flex
                                                align="center"
                                                justify="center"
                                                style={{
                                                    width: '40px',
                                                    height: '40px',
                                                    borderRadius: '50%',
                                                    background: '#36c6d3',
                                                    margin: '0 16px'
                                                }}
                                            >
                                                <SwapOutlined style={{ color: 'white', fontSize: '20px' }} />
                                            </Flex>
                                            <div style={{
                                                flex: 1,
                                                height: '1px',
                                                background: 'linear-gradient(to left, #36c6d3, #f0f0f0)'
                                            }} />
                                        </Flex>

                                        {/* Converted Amount */}
                                        <Flex vertical style={{
                                            background: '#f8fafc',
                                            padding: '20px',
                                            borderRadius: '12px',
                                            border: '1px solid #e5e7eb'
                                        }}>
                                            <Text type="secondary" style={{ fontSize: '14px', marginBottom: '8px' }}>
                                                Converted Amount
                                            </Text>
                                            <Statistic
                                                value={result?.converted_amount}
                                                suffix={result?.currency}
                                                precision={2}
                                                valueStyle={{
                                                    color: '#36c6d3',
                                                    fontSize: '32px',
                                                    fontWeight: '600'
                                                }}
                                            />
                                        </Flex>
                                    </Flex>
                                </Card>
                            </Flex>
                        ) : (
                            <Flex
                                vertical
                                align="center"
                                justify="center"
                                style={{
                                    height: "300px",
                                    color: "#6B7280",
                                    background: 'white',
                                    borderRadius: '12px',
                                    border: '1px dashed #e5e7eb'
                                }}
                            >
                                <SwapOutlined style={{ fontSize: '32px', color: '#36c6d3', marginBottom: '16px' }} />
                                <Text type="secondary" style={{ fontSize: "16px" }}>
                                    Enter an amount and select currencies to see the conversion result
                                </Text>
                            </Flex>
                        )}
                    </Card>
                </Col>
            </Row>
        </div >
    );
};

export default CurrencyConverter; 