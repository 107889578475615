import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  Select,
  Upload,
  Flex,
  message,
  Button,
  Switch,
  Modal,
  Typography,
} from "antd";
import {
  FileTextOutlined,
  DeleteOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";

import "react-quill/dist/quill.snow.css";
import dayjs from "dayjs";
import orderService from "../../services/OrderLists";
import { useParams } from "react-router-dom";
import moment from "moment";
const { Dragger } = Upload;
const { Option } = Select;
const { Title } = Typography;
const AddFeedbackFiles = ({ isVisible, setIsModalVisible, feedbackKey ,getFeedBack }) => {
  const { orderKey } = useParams();
  const [notifyAssociate, setNotifyAssociate] = useState(false); // State for switch
  const [notifyOptions, setNotifyOptions] = useState([]);
  const [selectedNotifiees, setSelectedNotifiees] = useState([]);
  const [formData, setFormData] = useState({
    files: [],
  });
  const [isLoading, setIsLoading] = useState(false);

  const [fileList, setFileList] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    if (!feedbackKey) {
      // console.warn("No feedback key passed.");
      return;
    }

    const fetchNotifiees = async () => {
      try {
        const response = await orderService.getAddFeedbackNotifee(orderKey);
        if (response.success) {
          setNotifyOptions(response?.data?.data);
        } else {
          // message.error("Failed to fetch notify options.");
        }
      } catch (error) {
        // console.error("Error fetching notify options:", error);
      }
    };

    fetchNotifiees();
  }, [feedbackKey]); // Add feedbackKey as a dependency

  const handleNotifyChange = (notifiee, checked) => {
    setSelectedNotifiees((prev) =>
      checked ? [...prev, notifiee] : prev.filter((item) => item !== notifiee)
    );
  };
  const handleFileChange = (info) => {
    const updatedFileList = info.fileList.slice(-5);
    setFileList(updatedFileList);
  };

  const handleDeleteFile = (index) => {
    const newFileList = [...fileList];
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const getFormattedFileName = (fileName) => {
    const extensionIndex = fileName.lastIndexOf(".");
    const baseName =
      extensionIndex !== -1 ? fileName.substring(0, extensionIndex) : fileName;
    const extension =
      extensionIndex !== -1 ? fileName.substring(extensionIndex) : "";

    if (baseName.length > 14) {
      return {
        displayName: `${baseName.substring(0, 14)}...${extension}`,
        fullName: fileName,
      };
    }
    return {
      displayName: fileName,
      fullName: fileName,
    };
  };

  const getFileIcon = (fileName) => {
    const extension = fileName?.split(".").pop().toLowerCase();
    switch (extension) {
      case "pdf":
        return "https://i.ibb.co/Xzcwvy6/images-q-tbn-ANd9-Gc-TGAA4-Wd4bco5-Xv33-Gas-Xrn-Dd-QT5-OFXwa3-HUQ-s.png";
      case "doc":
      case "docx":
        return "https://i.ibb.co/mXGsc7g/images-q-tbn-ANd9-Gc-Rs3n-Wc9jmd-M7-Wm-Dc-EAy3-PC2z-Mur7-A1ee-bw-s.png";
      case "jpg":
      case "jpeg":
        return "https://i.ibb.co/NC2DbKN/images-q-tbn-ANd9-Gc-Qgd-Crtwnin-NU1-Nxzpu-IP6-F9h-Kkpfv-SSgr7cw-s.png";
      case "png":
        return "https://i.ibb.co/mtLjYdr/2048px-pptx-icon-2019-svg.png";
      case "zip":
        return "https://i.ibb.co/WV6wJv4/zip-file-line-icon-vector.jpg";
      default:
        return "https://i.ibb.co/NC2DbKN/images-q-tbn-ANd9-Gc-Qgd-Crtwnin-NU1-Nxzpu-IP6-F9h-Kkpfv-SSgr7cw-s.png";
    }
  };

  const handleSubmit = async () => {
    const formDataToSend = new FormData();

    fileList.forEach((file) =>
      formDataToSend.append("files", file.originFileObj)
    );

    if (selectedNotifiees.length > 0) {
      formDataToSend.append("notify", selectedNotifiees.join(", "));
    }
    try {
      setIsLoading(true);
      const response = await orderService.addFeedbackFiles( 
        feedbackKey,
        formDataToSend
      );

      if (response?.success) {
        message.success("Files added successfully.");
        await getFeedBack("4")
        setIsModalVisible();
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      loading={isLoading}
      className="custom-modal2"
      title={
        <Flex justify="space-between" className="custom-modal-header">
          <Title
            level={3}
            style={{
              color: "white",
              margin: 0,
              position: "relative",
              marginLeft: "10px",
            }}>
            Add Files
          </Title>
        </Flex>
      }
      visible={isVisible}
      // Ensures modal closes on cancel
      onCancel={() => setIsModalVisible()} // Ensures modal closes on cancel
      footer={
        <Flex style={{ width: "100%" }} justify="flex-end" align="flex-end">
          <Button loading={isLoading} type="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Flex>
      }>
      <Form
        form={form}
        layout="vertical"
        encType="multipart/form-data"
        onFinish={() => console.log("Form submitted", formData)}>
        <Flex vertical justify="center" style={{ width: "100%" }} gap={24}>
          <Row>
            <label>Files</label>
            <div className="file-parent">
              <div className="file-container">
                {fileList.length === 0 ? (
                  <Flex
                    vertical
                    justify="center"
                    style={{ width: "100%", minHeight: "150px" }}
                    className="file-preview-container">
                    <Dragger
                      style={{
                        width: "100%",
                        background: "none",
                        border: "none",
                      }}
                      showUploadList={false} // Hide Ant Design's automatic file list
                      beforeUpload={() => false} // Prevent automatic upload
                      onChange={handleFileChange} // Handle file selection
                      fileList={fileList} // Set fileList state for controlled uploads
                      multiple // Allow multiple file uploads
                      listType="text" // Display file names below the input
                    >
                      <p
                        className="ant-upload-text "
                        style={{ color: "gray", marginTop: "20px" }}>
                        Drag & drop files here or click to upload
                      </p>
                    </Dragger>
                  </Flex>
                ) : (
                  <div
                    className="file-preview-container"
                    style={{ display: "flex", flexWrap: "wrap" }}>
                    {fileList.map((file, index) => {
                      const { displayName, fullName } = getFormattedFileName(
                        file.name
                      );

                      return (
                        <div
                          key={index}
                          className="file-preview-item"
                          style={{
                            margin: "10px",
                            display: "flex",
                            alignItems: "center",
                            border: "1px solid #d9d9d9",
                            padding: "2px 4px",
                            borderRadius: "4px",
                          }}>
                          <img
                            style={{
                              height: "40px",
                              width: "40px",
                              marginRight: "8px",
                            }}
                            src={getFileIcon(file.name)}
                            alt="file-icon"
                          />
                          <span title={fullName}>{displayName}</span>
                          <DeleteOutlined
                            style={{ marginLeft: "8px", cursor: "pointer" }}
                            onClick={() => handleDeleteFile(index)} // Call delete function on click
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              <Upload
                showUploadList={false} // Hide Ant Design's automatic file list
                beforeUpload={() => false} // Prevent automatic upload
                onChange={handleFileChange}
                fileList={fileList} // Set fileList state for controlled uploads
                multiple
                listType="text">
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}>
                  <PaperClipOutlined
                    style={{ fontSize: "24px", marginRight: "8px" }}
                  />
                </div>
              </Upload>
            </div>
          </Row>
          <Flex style={{ marginTop: "16px" }} vertical>
            <Col>
              <label>Notify:</label>
            </Col>

            <Row>
              {notifyOptions?.map((notifiee) => (
                <Flex key={notifiee} style={{ marginBottom: "8px" }} gap={4}>
                  <span style={{ marginLeft: "8px" }}>{notifiee}</span>
                  <Switch
                    checked={selectedNotifiees.includes(notifiee)}
                    onChange={(checked) =>
                      handleNotifyChange(notifiee, checked)
                    }
                  />
                </Flex>
              ))}
            </Row>
          </Flex>

          {/* <Row>
        <label>Files</label>
        <div className="file-container">
          {fileList.length === 0 ? (
            <Dragger
              style={{ width: "100%", minHeight: "150px" }}
              fileList={fileList}
              beforeUpload={() => false}
              onChange={handleFileChange}
              multiple>
              <p>Drag & drop files here or click to upload</p>
            </Dragger>
          ) : (
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {fileList.map((file, index) => {
                const { displayName, fullName } = getFormattedFileName(
                  file.name
                );

                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #d9d9d9",
                      padding: "4px",
                      marginBottom: "5px",
                    }}>
                    <img
                      src={getFileIcon(file.name)}
                      alt="file-icon"
                      style={{ height: "40px", marginRight: "8px" }}
                    />
                    <span title={fullName}>{displayName}</span>
                    <DeleteOutlined
                      style={{ marginLeft: "auto", cursor: "pointer" }}
                      onClick={() => handleDeleteFile(index)}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </Row> */}
        </Flex>
      </Form>
    </Modal>
  );
};

export default AddFeedbackFiles;
