// import { message } from "antd";
// import { updatePermissions } from "../slices/authSlice";
// import commonService from "../../services/commonService";

// const commonInfoMiddleware = (store) => (next) => async (action) => {
//   if (action.type === "FETCH_COMMON_INFO") {
//     try {
//       const response = await commonService.getCommonInfo();

//       if (response.success) {
//         const permissions = response.data.data.permissions;
//         store.dispatch({ type: "auth/updatePermissions", payload: permissions });
//       }
      
      
//     } catch (error) {
//       console.error("Failed to fetch common info:", error);
//       message.error("Failed to fetch common info");
//     }
//   }

//   return next(action);
// };

// export default commonInfoMiddleware;

import { message } from "antd";
import { updatePermissions } from "../slices/authSlice";
import { fetchUnreadCountSuccess } from "../slices/notificationsSlice";
import commonService from "../../services/commonService";

const commonInfoMiddleware = (store) => (next) => async (action) => {
  if (action.type === "FETCH_COMMON_INFO") {
    try {
      const response = await commonService.getCommonInfo();

      if (response.success) {
        const { permissions, notifications } = response.data.data;
        
        console.log(response.data.data.notifications.total_unread_count, 'Notifications Count'); // Log to verify data
        
        // Dispatch action to save permissions
        store.dispatch(updatePermissions(permissions));

        // Dispatch action to save notifications data
        store.dispatch(fetchUnreadCountSuccess({
          totalUnreadCount: notifications.total_unread_count,
          notificationsData: notifications.data,
        }));
        
      }
    } catch (error) {
      console.error("Failed to fetch common info:", error);
      message.error("Failed to fetch common info");
    }
  }

  return next(action);
};

export default commonInfoMiddleware;
