import React from "react";
import {
  Row,
  Col,
  Card,
  Select,
  Checkbox,
  DatePicker,
  Space,
  Flex,
  Spin,
  Skeleton,
  Typography,
  Button,
  Popover,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { useEffect, useState } from "react";
import { debounce } from "lodash";
import { useMediaQuery } from "react-responsive";
import dayjs from "dayjs";
import CountryWiseTaskAndSalesMap from "../../page-components/Bdm Analytics/CountryWiseTaskAndSalesMap";
import OrderBudgetAndAmountDetails from "../../page-components/Bdm Analytics/OrderBudgetAndAmountDetails";
import ComparisonAnalytics from "../../page-components/Bdm Analytics/ComparisonAnalytics";
import RefundAnalytics from "../../page-components/Bdm Analytics/RefundAnalytics";
import hierarchyService from "../../services/hierarchyService";
import bdmAnalyticsService from "../../services/bdmAnalyticsService";

import "./bdm.css";
import { CircleX } from "lucide-react";
import { CrossIcon, Search } from "lucide-react";
import {
  CloseCircleFilled,
  CloseSquareFilled,
  SearchOutlined,
} from "@ant-design/icons";
const { Title } = Typography;
const { RangePicker } = DatePicker;
function AnalyticsParent() {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const [hierarchyData, setHierarchyData] = useState([]);
  const [selectedHierarchy, setSelectedHierarchy] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [selectedMonth, setSelectedMonth] = useState(dayjs().month() + 1); // Numeric month (1-12)
  const [selectedYear, setSelectedYear] = useState(dayjs().year()); // Change to single month selection
  const [analyticsData, setAnalyticsData] = useState(null); // State for fetched analytics data
  const [isInitialLoad, setIsInitialLoad] = useState(true); // New flag for initial load
  const [loading, setLoading] = useState(false); // Loading state for analytics data
  const [parentUid, setParentUid] = useState(null);
  const [parentDetail, setParentDetail] = useState({ user: null, uid: null });
  const [myTeamloading, setMyTeamLoading] = useState(false);
  // Function to fetch analytics data
  // Function to fetch analytics data

  const fetchAnalyticsData = async (startDate, endDate, month, year, uids) => {
    setLoading(true); // Start loading
    try {
      const result = await bdmAnalyticsService.fetchBdmAnalyticsData(
        startDate,
        endDate,
        month,
        year,
        uids
      );
      if (result.success) {
        console.log(result, "res");
        setAnalyticsData(result.data.data);
      }
    } catch (error) {
      console.error("Error fetching analytics data:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };
  const debouncedFetchAnalyticsData = debounce(fetchAnalyticsData, 300);
  const orderAnalytics = analyticsData?.order_analytics || {};

  const {
    order_budget = [],
    amount_paid = [],
    task_count = 0,
    word_count = 0,
    sales_data = [],
  } = orderAnalytics;
  // const { monthwise = {}, yearwise = {} } = data?.comparision_analytics || {};

  //   const CustomPopover = ({ active, payload, label }) => {
  //     if (active && payload && payload.length > 0) {
  //       const currencyData = payload[0].payload.currencywiseAmounts;
  // // console.log(payload[0].payload.order_analytics.task_count , 'pay')
  // const {task_count,word_count} = payload[0].payload.order_analytics
  //       return (
  //         <div
  //           style={{
  //             display: "flex",
  //             flexDirection: "column",
  //             background: "#f3f4f6",
  //             minWidth: "210px",
  //             backdropFilter: "10px",
  //             padding: "10px",
  //             borderRadius: "10px",
  //             border: " 1px solid #9ca3af",
  //             gap: "10px",
  //           }}>
  //           <div
  //             style={{
  //               display: "flex",
  //               width: "100%",
  //               justifyContent: "space-between",
  //             }}>
  //             <div
  //               style={{
  //                 display: "flex",
  //                 width: "100%",
  //                 flexDirection: "column",
  //               }}>
  //               <p style={{ margin: 0 }}>Total Task</p>
  //               <strong>{task_count}</strong>
  //             </div>
  //             <div
  //               style={{
  //                 display: "flex",
  //                 width: "100%",
  //                 flexDirection: "column",
  //                 justifyContent: "flex-end",
  //                 textAlign: "right",
  //               }}>
  //               <p style={{ margin: 0 }}>Word Count</p>
  //               <strong>{word_count}</strong>
  //             </div>
  //           </div>

  //           <div
  //             style={{
  //               backdropFilter: 10,
  //               background: "white",
  //               border: " 1px solid #9ca3af",
  //               padding: "10px",
  //               borderRadius: "10px",
  //             }}>
  //             {showInrValues ? (
  //               <div
  //                 style={{
  //                   display: "flex",
  //                   width: "100%",
  //                   justifyContent: "space-between",
  //                 }}>
  //                 <p style={{ margin: 0, color: "gray" }}>INR</p>
  //                 <strong>{payload[0].payload.totalInrAmount}</strong>
  //               </div>
  //             ) : (
  //               Object.entries(currencyData).map(([currency, amount]) => (
  //                 <div
  //                   key={currency}
  //                   style={{
  //                     display: "flex",
  //                     width: "100%",
  //                     justifyContent: "space-between",
  //                   }}>
  //                   <p style={{ margin: 0, color: "gray" }}>{currency}</p>
  //                   <strong>{amount}</strong>
  //                 </div>
  //               ))
  //             )}
  //           </div>
  //         </div>
  //       );
  //     }

  //     return null; // Return null if active is false
  //   };
  useEffect(() => {
    // Prepare start and end dates
    const startDate = dateRange[0]
      ? dayjs(dateRange[0]).format("YYYY-MM-DD")
      : null;
    const endDate = dateRange[1]
      ? dayjs(dateRange[1]).format("YYYY-MM-DD")
      : null;

    // Fetch data based on selected filters
    debouncedFetchAnalyticsData(
      startDate,
      endDate,
      selectedMonth,
      selectedYear,
      selectedHierarchy
    );

    return () => {
      debouncedFetchAnalyticsData.cancel(); // Cancel any pending calls
    };
  }, []);
  // useEffect(() => {
  //   // On initial load, fetch data without date range
  //   if (isInitialLoad) {
  //     fetchAnalyticsData(null, null, selectedMonth, selectedYear);
  //     setIsInitialLoad(false);
  //   } else {
  //     // If date range is selected, fetch data with the range
  //     const startDate = dateRange[0]
  //       ? dayjs(dateRange[0]).format("YYYY-MM-DD")
  //       : null;
  //     const endDate = dateRange[1]
  //       ? dayjs(dateRange[1]).format("YYYY-MM-DD")
  //       : null;
  //     fetchAnalyticsData(
  //       startDate,
  //       endDate,
  //       selectedMonth,
  //       selectedYear,
  //       selectedHierarchy
  //     );
  //   }
  // }, [
  //   dateRange,
  //   selectedMonth,
  //   selectedYear,
  //   isInitialLoad,
  //   selectedHierarchy,
  // ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setMyTeamLoading(true);
        const result = await hierarchyService.fetchBdmAnalyticsHierarchy();

        if (result.success) {
          const data = result.data.data;
          console.log(result.data.data, "hierarchy data");

          setHierarchyData(data.hierarchy);
          setParentDetail({ uid: data.uid, user: data.user }); // Save UID and user
          setMyTeamLoading(false);
          // Set selected hierarchy with parent UID
          setSelectedHierarchy([data.uid]);
        }
      } catch (error) {
        setMyTeamLoading(false);
        console.error(error);
      }
    };

    fetchData();
  }, []);

  // In AnalyticsParent component
  const handleSelectChange = (value) => {
    if (value.includes("Total")) {
      const allUids = hierarchyData.map((item) => item.uid);
      setSelectedHierarchy([parentDetail.uid, ...allUids]);
    } else {
      setSelectedHierarchy(value);
    }
  };

  // const handleSelectChange = (value) => {
  //   console.log("Selected value:", value); // Debug log

  //   if (value.includes("Total")) {
  //     // When "Select All" is selected, set selectedHierarchy to all user uids
  //     setSelectedHierarchy(hierarchyData.map((item) => item.uid));
  //   } else {
  //     // For single or multiple selections, update selectedHierarchy
  //     setSelectedHierarchy(value);
  //   }
  // };

  // const handleSelectChange = (value) => {
  //   console.log("Selected value:", value); // Debug log

  //   if (value === "Total") {
  //     // For "Total" selection, get all UIDs
  //     setSelectedHierarchy(hierarchyData.map((item) => item.uid));
  //     // setSelectedHierarchy(hierarchyData.map((item) => item.uid));
  //   } else if (value) {
  //     // For single selection, wrap in array to maintain consistent format
  //     setSelectedHierarchy(value);
  //   }
  // };

  const handleMonthChange = (value) => {
    setSelectedMonth(value);
    setDateRange([null, null]);
  };

  const handleYearChange = (value) => {
    setSelectedYear(value);
    setDateRange([null, null]);
    setSelectedMonth(null);
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates || [null, null]);
    setSelectedMonth(null);
    setSelectedYear(null);
  };
  // const hierarchyOptions = [
  //   { value: "Total", label: "Total" }, // "Total" remains the first option
  //   ...hierarchyData.map((item) => ({
  //     value: item.uid,  // UID should be the value
  //     label: item.user, // Name should be the label
  //   })),
  // ];
  const hierarchyOptions = [
    { value: "Total", label: "Total" }, // "Total" remains the first option
    {
      value: parentDetail.uid,
      label: ` ${parentDetail.user} (You)`, // Display parent details in label
      // disabled: true // Option to disable the parent if needed
    },
    ...hierarchyData.map((item) => ({
      value: item.uid, // UID should be the value
      label: item.user, // Name should be the label
    })),
  ];

  const monthOptions = [
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
  ];
  const yearOptions = Array.from({ length: 10 }, (_, i) => {
    const year = dayjs().year() - i;
    return { label: year, value: year };
  });
  const handleSearch = () => {
    // Trigger fetch immediately for manual submissions
    fetchAnalyticsData(
      dateRange[0] ? dayjs(dateRange[0]).format("YYYY-MM-DD") : null,
      dateRange[1] ? dayjs(dateRange[1]).format("YYYY-MM-DD") : null,
      selectedMonth,
      selectedYear,
      selectedHierarchy
    );
  };

  const handleCancel = () => {
    setSelectedMonth(dayjs().month() + 1); // Set to current month (1-12)
    setSelectedYear(dayjs().year()); // Set to current year
    setDateRange([null, null]); // Clear date range

    // Fetch data with default values
    fetchAnalyticsData(
      null, // No date range filter
      null, // No date range filter
      dayjs().month() + 1, // Current month
      dayjs().year(), // Current year
      [] // No specific hierarchy filter
    );
  };

  return (
    <Flex style={{ padding: "10px", width: "100%" }} gap={10} vertical>
      <div style={{ width: "100%" }}>
        <div
          style={{
            background: "#eceff1",
            padding: "20px",
            borderRadius: "8px",
          }}>
          <div
            style={{
              marginBottom: 16,
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}>
            <Select
              className="bdm-user"
              placeholder={myTeamloading ? "Fetching Team" : "Select Users"}

              options={hierarchyOptions}
              value={selectedHierarchy}
              onChange={handleSelectChange}
              // style={{ width: 220, textAlign: "left" }}
              style={{
                width: 220,
                maxHeight: 100,
                overflowY: "auto",
                textAlign: "left",
              }}
              allowClear
              mode="multiple"
              loading={myTeamloading}
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
            />

            <Select
              placeholder="Select Year"
              value={selectedYear}
              onChange={handleYearChange}
              allowClear
              style={{ width: 220, textAlign: "left" }}>
              {yearOptions.map((year) => (
                <Select.Option key={year.value} value={year.value}>
                  {year.label}
                </Select.Option>
              ))}
            </Select>

            {/* Month-wise Checkbox */}

            <Select
              placeholder="Select Month"
              value={selectedMonth}
              onChange={handleMonthChange}
              style={{ width: 220, textAlign: "left" }}
              allowClear>
              {monthOptions.map((month) => (
                <Select.Option key={month.value} value={month.value}>
                  {month.label}
                </Select.Option>
              ))}
            </Select>

            {/* Date Range Picker */}
            <RangePicker
              value={dateRange}
              onChange={handleDateRangeChange}
              style={{ width: 220, textAlign: "left" }}
              format="YYYY-MM-DD"
            />
            <div style={{ display: "flex", gap: "10px" }}>
              <Button
                type="primary"
                style={{ background: "#040724" }}
                icon={<SearchOutlined />}
                onClick={handleSearch}></Button>
              <Button
                style={{ border: "none" }}
                type="default"
                icon={<CircleX />}
                onClick={handleCancel}></Button>
            </div>
          </div>
        </div>
      </div>

      {loading ? (
        <Flex
          style={{ width: "100%", height: "80vh" }}
          justify="center"
          align="center">
          <Spin size="large" tip="Loading analytics data..." />
        </Flex>
      ) : (
        <Row
          gutter={[16, 16]}
          style={{
            flexDirection: isMobile ? "column" : "row",
            width: "100%",
          }}>
          <Col order={1} lg={12}>
            <Flex
              style={{
                width: "100%",
                flexDirection: isMobile ? "column" : "row",
              }}
              gap={10}>
              <Flex
                justify={isMobile ? "flex-start" : "center"}
                align="center"
                style={{
                  background: "white",
                  padding: 8,
                  borderRadius: 4,
                }}>
                <Title level={5} style={{ margin: 0, padding: 0 }}>
                  Task Count : {task_count}
                </Title>
              </Flex>
              <Flex
                justify={isMobile ? "flex-start" : "center"}
                align="center"
                style={{
                  background: "white",
                  padding: 8,
                  borderRadius: 4,
                  textAlign: "left",
                }}>
                <Title level={5} style={{ margin: 0, padding: 0 }}>
                  Word Count : {word_count}
                </Title>
              </Flex>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  background: "white",
                  padding: 8,
                  borderRadius: 4,
                }}>
                <Popover
                  trigger="click"
                  content={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        background: "#f3f4f6",
                        minWidth: "210px",
                        backdropFilter: "10px",
                        padding: "10px",
                        borderRadius: "10px",
                        border: " 1px solid #9ca3af",
                        gap: "10px",
                      }}>
                      <strong>Sales Data</strong>
                      <div
                        style={{
                          padding: "8px",
                          backgroundColor: "#f4f6f9",
                        }}>
                        {sales_data.length > 0 ? (
                          sales_data.map((sale, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                              }}>
                              <div> {sale.currency}</div>
                              <div>{sale.amount}</div>
                            </div>
                          ))
                        ) : (
                          <div>No sales data available</div>
                        )}
                      </div>
                    </div>
                  }>
                  <Flex justify="center" align="center">
                    <Title level={5} style={{ margin: 0, padding: 0 }}>
                      Sales Value:
                    </Title>
                    <Button type="link">Click to View</Button>
                  </Flex>
                </Popover>
              </div>
            </Flex>
            <Card
              title={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  <span>Order Analytics</span>
                  <Tooltip title="Result excludes cancelled, unassigned, and unpaid tasks (Without Any Solution)">
                    <InfoCircleOutlined
                      style={{
                        marginLeft: 8,
                        fontSize: 16,
                        color: "#1890ff",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </div>
              }
              bordered
              style={{
                textAlign: "left",

                minHeight: isMobile ? "max-content" : 420,
                maxHeight: isMobile ? "max-content" : 420,
                marginTop: "10px",
              }}>
              <OrderBudgetAndAmountDetails data={analyticsData} />
            </Card>
          </Col>
          <Col order={2} sm={24} lg={12}>
            <Card
              title={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  <span>Comparison Analytics</span>
                  <Tooltip title="Note : Compare with the last year same month if you select 'Month Wise' and with the last 3 previous year if you choose 'Year Wise'.">
                    <InfoCircleOutlined
                      style={{
                        marginLeft: 8,
                        fontSize: 16,
                        color: "#1890ff",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </div>
              }
              bordered
              style={{
                textAlign: "left",
                minHeight: isMobile ? "max-content" : 470,
                maxHeight: isMobile ? "max-content" : 470,
              }}>
              <ComparisonAnalytics
                data={
                  analyticsData || {
                    comparision_analytics: { monthwise: {}, yearwise: {} },
                  }
                }
              />
            </Card>
          </Col>

          <Col order={3} lg={12}>
            <Card
              title={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  <span>Country and Sales</span>
                  <Tooltip title="Tasks in the 'Others' category are those for which the client's country or contact number (with country code) has not been updated.">
                    <InfoCircleOutlined
                      style={{
                        marginLeft: 8,
                        fontSize: 16,
                        color: "#1890ff",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </div>
              }
              bordered
              style={{
                textAlign: "left",
                minHeight: isMobile ? "max-content" : "max-content",
                maxHeight: isMobile ? "max-content" : "max-content",
              }}>
              <CountryWiseTaskAndSalesMap data={analyticsData} />
            </Card>
          </Col>
          <Col order={4} lg={12}>
            <Card
              title="Refund Analytics"
              bordered
              style={{
                textAlign: "left",
                minHeight: isMobile ? "max-content" : 470,
                maxHeight: isMobile ? "max-content" : 470,
              }}>
              <RefundAnalytics refundData={analyticsData?.refund_analytics} />
            </Card>
          </Col>
        </Row>
      )}
    </Flex>
  );
}

export default AnalyticsParent;
