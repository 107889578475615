import React, { useState, useEffect } from "react";
import {
  Table,
  Checkbox,
  Button,
  message,
  Typography,
  Spin,
  Modal,
  Form,
  Input,
  Popover,
  Tag,
  Pagination,
  Select,
  Flex,
  Card,
  Descriptions,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { EditOutlined } from "@ant-design/icons";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import usePermissions from "../../hooks/usePermissions";
import hierarchyService from "../../services/hierarchyService";
import clientService from "../../services/clientService";
const { Title } = Typography;
const MobileViewadLeads = ({
  adLeads,
  showModal,
  pagination,
  setPagination,
  selectedLeads,
  handleSelectLead,
  hasPermission
}) => {
  return (
    <div>
      {adLeads.map((user) => (
        <Card
          key={user.uuid}
          style={{ marginBottom: "1rem" }}
          actions={[
            hasPermission("can_view_oz_assignment_new_upload") && (
              <Button
                type="link"
                icon={<EditOutlined />}
                onClick={() => showModal(user)}>
                Edit Remarks
              </Button>
            ),
          ]}>
          {/* Checkbox for selection */}

          <Flex style={{ width: "100%" }}>
            <Checkbox
              checked={selectedLeads?.includes(user.uuid)}
              onChange={(e) => handleSelectLead(user.uuid, e.target.checked)}
              style={{
                marginBottom: "10px",
                position: "relative",
                left: 0,
              }}></Checkbox>
          </Flex>

          <Descriptions bordered size="small">
            <Descriptions.Item label=" Name">
              {user.name || "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Contact">
              {user.contact_no || "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Assigned To">
              {user.assigned_bdm || "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Assigned On">
              {user.assigned_on
                ? moment(user.assigned_on).local().format("DD-MM-YYYY hh:mm A")
                : "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Email">
              {user.email || "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Country">
              {user.country || "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Subject">
              <Tag color="purple">{user.subject || "N/A"}</Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Created">
              {user.obj_created_at
                ? moment(user.obj_created_at)
                    .local()
                    .format("DD-MM-YYYY hh:mm A")
                : "N/A"}
            </Descriptions.Item>

            <Descriptions.Item label="Updated At">
              {user.updated_at
                ? moment(user.updated_at).local().format("DD-MM-YYYY hh:mm A")
                : "N/A"}
            </Descriptions.Item>

            <Descriptions.Item label="Remarks">
              <Popover
                style={{ maxWidth: "100px" }}
                content={
                  <p style={{ wordBreak: "break-word", maxWidth: "190px" }}>
                    {user.remarks || "No remarks available"}
                  </p>
                }
                title="Remarks"
                trigger="hover"
                placement="center">
                <Button type="link" style={{ padding: 0 }}>
                  View
                </Button>
              </Popover>
            </Descriptions.Item>
            <Descriptions.Item label="Description">
              <Popover
                content={
                  <div style={{ wordBreak: "break-word", maxWidth: "190px" }}>
                    {user.description || "No Description available"}
                  </div>
                }
                title="Description"
                trigger="hover"
                placement="topLeft">
                <Button type="link" style={{ padding: 0 }}>
                  View
                </Button>
              </Popover>
            </Descriptions.Item>
          </Descriptions>
        </Card>
      ))}
      <Pagination
        current={pagination.current}
        pageSize={pagination.pageSize}
        total={pagination.total}
        onChange={(page, pageSize) =>
          setPagination((prev) => ({ ...prev, current: page, pageSize }))
        }
        style={{ textAlign: "center", marginTop: 16 }}
      />
    </div>
  );
};

const AdLeadsTable = ({
  adLeads,
  loading,
  pagination,
  getAdLeads,
  setPagination,
  showAssignLeadsModal,
  setShowAssignLeadsModal,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { hasPermission } = usePermissions();
  const [hierarchyData, setHierarchyData] = useState([]);
  const [selectedHierarchy, setSelectedHierarchy] = useState(null);

  const [selectedLeads, setSelectedLeads] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentClientId, setCurrentClientId] = useState(null);
  const [remarksOld, setRemarksOld] = useState("");
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);

  // Fetch hierarchy data on mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await hierarchyService.fetchBdmAnalyticsHierarchy();

        if (result.success) {
          setHierarchyData(result?.data?.data?.hierarchy);

          // Set selected hierarchy with parent UID
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (showAssignLeadsModal) {
      fetchData();
    }
  }, [showAssignLeadsModal]);

  // Convert hierarchy data to TreeSelect format

  // Handle individual selection
  const handleSelectLead = (uid, checked) => {
    setSelectedLeads((prev) =>
      checked ? [...prev, uid] : prev.filter((id) => id !== uid)
    );
  };

  const handleSelectAll = (checked) => {
    setSelectAll(checked);
    setSelectedLeads(checked ? adLeads.map((lead) => lead.uuid) : []);
  };

  //   const handleSelectLead = (uid, checked) => {
  //     setSelectedLeads((prev) =>
  //       checked ? [...prev, uid] : prev.filter((id) => id !== uid)
  //     );
  //   };

  //   // Handle "Select All"
  //   const handleSelectAll = (checked) => {
  //     setSelectAll(checked);
  //     setSelectedLeads(checked ? adLeads.map((lead) => lead.uuid) : []);
  //   };

  const handleSubmit = async () => {
    if (selectedLeads.length === 0) {
      message.warning("Please select leads before submitting.");
      return;
    }

    const data = {
      uuid: selectedLeads, // This will be an array of lead UUIDs
      assigned_bdm: selectedHierarchy, // The selected hierarchy's UID
    };

    try {
      const response = await clientService.assignAdLead(data); // Passing the correct data object
      if (response.success) {
        message.success("Leads assigned successfully!");
        setShowAssignLeadsModal(false); // Close the modal
        setSelectedHierarchy("");
        setSelectedLeads([]);
        await getAdLeads();
      } else {
        message.error("Failed to assign leads.");
      }
    } catch (error) {
      console.error(error);
      message.error("Error while assigning leads.");
    }
  };

  // Handle modal open
  const handleOpenModal = (clientId) => {
    setIsModalVisible(true);
    setCurrentClientId(clientId?.uuid);
    setRemarksOld(clientId?.remarks);
  };

  // Handle modal close
  const handleCloseModal = () => {
    setShowAssignLeadsModal(false);
    setSelectedLeads([]);
    setSelectedHierarchy("");
  };
  const handleCloseEditRemarksModal = () => {
    setIsModalVisible(false);
    setRemarksOld("");
  };
  // Table columns
  const columns = [
    {
      title: (
        <Checkbox
          checked={selectAll}
          onChange={(e) => handleSelectAll(e.target.checked)}
        />
      ),
      key: "select",
      width:50,
      render: (_, record) => (
        <Checkbox
          checked={selectedLeads.includes(record.uuid)}
          onChange={(e) => handleSelectLead(record.uuid, e.target.checked)}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        const formattedDate = moment(record.obj_created_at)
          .local()
          .format("DD-MM-YYYY hh:mm A");
        return (
          <div>
            {text}
            <span style={{ opacity: 0.5, fontSize: "12px", display: "block" }}>
              {formattedDate || "N/A"}
            </span>
          </div>
        );
      },
    },
    {
      title: "Contact",
      key: "contact",
      width: 200, // ✅ Set column width to 200px
      render: (_, record) => (
        <div>
          <div>{record.email || "N/A"}</div>
          <div>{record.contact_no || "N/A"}</div>
        </div>
      ),
    },
    {
      title: "Country",
      key: "country",
      width:100,
      render: (_, record) => <>{record.country}</>,
    },
    {
      title: "Subject",
      key: "subject",
      render: (_, record) => <Tag  style={{wordBreak:'break-all' , whiteSpace:'collapse'}} color="purple">{record.subject}</Tag>,
    },
    {
      title: "Assigned To",
      key: "assigned_bdm",
      render: (_, record) => {
        console.log(record.assigned_bdm); // For debugging, can be removed after testing

        // If there's no valid assigned_on, show "Not Assigned"
        if (!record?.assigned_on && record.assigned_on) {
          return <div>Not Assigned</div>;
        }

        // Format the valid assigned_on date
        const formattedDate = moment(record?.assigned_on)
          .local()
          .format("DD-MM-YYYY hh:mm A");

        return (
          <div>
            <p style={{ margin: 0 }}>{record.assigned_bdm || "Not Assigned"}</p>
            <span style={{ color: "gray", fontSize: 10 }}>
              {record.assigned_on ? formattedDate : null}
            </span>
          </div>
        );
      },
    },

    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text, record) => {
        const formattedDate = moment(record.updated_at)
          .local()
          .format("DD-MM-YYYY hh:mm A");
        return <div>{formattedDate || "N/A"}</div>;
      },
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      render: (text) => (
        <Popover
          content={
            <div
            style={{
              maxWidth: "180px",
              maxHeight: "200px", // Set max height
              overflowY: "auto",  // Enable vertical scroll
            }}
          >
              {text || "No remarks available"}
            </div>
          }
          title="Remarks"
          trigger="hover"
          placement="topLeft">
          <Button type="link" style={{ padding: 0 }}>
            View
          </Button>
        </Popover>
      ),
    },
    {
        title: "Description",
        dataIndex: "description",
        key: "description",
        render: (text) => (
          <Popover
            content={
              <div
                style={{
                  maxWidth: "180px",
                  maxHeight: "200px", // Set max height
                  overflowY: "auto",  // Enable vertical scroll
                }}
              >
                {text || "No Description available"}
              </div>
            }
            title="Description"
            trigger="hover"
            placement="topLeft"
          >
            <Button type="link" style={{ padding: 0 }}>View</Button>
          </Popover>
        ),
      },
      
    ...(hasPermission("can_view_oz_assignment_new_upload")
      ? [
          {
            title: "Edit Remark",
            key: "action",
            render: (_, record) => (
              <Button onClick={() => handleOpenModal(record)} type="link">
                <EditOutlined />
              </Button>
            ),
          },
        ]
      : []),
  ];

  const hierarchyOptions = hierarchyData.map((item) => ({
    value: item.username, // Use uid as the value, not username
    label: item.user, // Display the user's name
  }));

  return (
    <div>
      {loading ? (
        <Spin size="large" />
      ) : isMobile ? (
        <MobileViewadLeads
          adLeads={adLeads}
          showModal={handleOpenModal}
          pagination={pagination}
          setPagination={setPagination}
          selectedLeads={selectedLeads} // Add this line
          handleSelectLead={handleSelectLead} // Add this line
          hasPermission={hasPermission}
        />
      ) : (
        <Table
          dataSource={adLeads}
          columns={columns}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            onChange: (page, pageSize) =>
              setPagination({ ...pagination, current: page, pageSize }),
          }}
          rowKey="uuid"
          tableLayout="fixed"
        />
      )}

      <Modal
        className="custom-modal"
        title={
          <Flex justify="space-between" className="custom-modal-header">
            <Title
              level={3}
              style={{
                color: "white",
                margin: 0,
                position: "relative",
                marginLeft: "10px",
              }}>
              Assign Leads
            </Title>
          </Flex>
        }
        open={showAssignLeadsModal}
        onCancel={handleCloseModal}
        footer={[
          <Button key="cancel" onClick={handleCloseModal}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            style={{ background: "#040724" }}
            onClick={handleSubmit}
            loading={loading}>
            Submit
          </Button>,
        ]}
        destroyOnClose>
        <Flex style={{ width: "100%" }}>
          <Form style={{ marginTop: "25px", width: "100%" }}>
            {/* Hierarchy Select */}
            <Form.Item
              style={{ width: "100%" }}
              vertical
              rules={[
                {
                  required: true,
                  message: "Please select a user from the hierarchy",
                },
              ]}>
              <label>Assign To:</label>
              <Select
                showSearch
                style={{ width: "100%", marginTop: "12px" }}
                options={hierarchyOptions}
                value={selectedHierarchy || undefined}
                placeholder="Select User"
                allowClear
                onChange={(value) => setSelectedHierarchy(value)} // `value` should be `uid`
              />
            </Form.Item>
            {/* 
          <Button type="primary" htmlType="submit">
            {formSubmitLoading ? <Spin /> : "Submit"}
          </Button> */}
          </Form>
        </Flex>
      </Modal>
      <Modal
        className="custom-modal2"
        title={
          <div
            className="custom-modal-header"
            style={{ display: "flex", justifyContent: "space-between" }}>
            <Title
              level={3}
              style={{
                color: "white",
                margin: 0,
                position: "relative",
                marginLeft: "10px",
              }}>
              Edit Remarks
            </Title>
          </div>
        }
        visible={isModalVisible}
        onCancel={handleCloseEditRemarksModal}
        footer={null}
        destroyOnClose>
        <Form
          initialValues={{
            remarks: remarksOld,
          }}
          onFinish={async (values) => {
            setFormSubmitLoading(true);
            try {
              const data = {
                remarks: values.remarks,
              };
              const response = await clientService.updateAdLeadRemarks(
                currentClientId,
                data
              );
              if (response.success) {
                message.success("Remarks updated Successfully");
                getAdLeads();
                handleCloseEditRemarksModal();
                setFormSubmitLoading(false);
              }
            } catch (error) {
              setFormSubmitLoading(false);
              //   await catchError(error);
            } finally {
              setFormSubmitLoading(false);
            }
          }}>
          <Form.Item
            name="remarks"
            rules={[{ required: true, message: "Please write remarks" }]}>
            <Input.TextArea placeholder="Write remarks" />
          </Form.Item>
          <Flex align="flex-end" justify="flex-end" style={{ width: "100%" }}>
            <Button type="primary" htmlType="submit">
              {formSubmitLoading ? <Spin /> : "Submit"}
            </Button>
          </Flex>
        </Form>
      </Modal>
    </div>
  );
};

export default AdLeadsTable;
