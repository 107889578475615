import React, { useMemo } from "react";
import { Table, Tooltip, Avatar, Popover, Button, Flex, Tag } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import moment from "moment";

// Function to render avatars with popover for Visibility and Ownership
const getInitials = (name) => {
  const words = name.split(" ");
  return words
    .map((word) => word.charAt(0).toUpperCase())
    .slice(0, 2)
    .join(""); // Get up to two initials
};

const paymentModeColors = {
  Razorpay: "#f36d44", // Razorpay logo color
  Stripe: "#6772e5", // Stripe logo color

  " N/A": "#808080", // Grey for N/A
};
const renderAvatars = (dataArray, title) => {
  if (!dataArray || dataArray.length === 0) return "N/A";

  const displayedAvatars = dataArray.slice(0, 2);
  const remainingAvatars = dataArray.slice(2);

  const getInitials = (name) => {
    const words = name.split(" ");
    return words
      .map((word) => word.charAt(0).toUpperCase())
      .slice(0, 2)
      .join(""); // Get up to two initials
  };

  return (
    <Avatar.Group maxCount={3}>
      {displayedAvatars.map((name, index) => (
        <Tooltip key={index} title={name}>
          <Avatar style={{ backgroundColor: "#87d068" }}>
            {getInitials(name)}
          </Avatar>
        </Tooltip>
      ))}
      {remainingAvatars.length > 0 && (
        <Popover
          content={
            <div>
              {remainingAvatars.map((name, index) => (
                <p key={index}>{name}</p>
              ))}
            </div>
          }
          title={title}>
          <Avatar style={{ backgroundColor: "#f56a00" }}>
            +{remainingAvatars.length}
          </Avatar>
        </Popover>
      )}
    </Avatar.Group>
  );
};

const TransactionsTable = ({
  transactions,
  loading,
  pagination,
  setPagination,
  dropdownValue,
}) => {
  const columns = useMemo(() => {
    // Start with an empty array of columns
    const dynamicColumns = [];
  
    if (transactions && transactions.length > 0) {
      // Get the keys of the first record to generate dynamic columns
      const sampleRecord = transactions[0];
  
      Object.keys(sampleRecord).forEach((key) => {
        // Skip "Rejection_remarks" and "created" columns
        if (
          key === "rejection_remarks" ||
          key === "created" ||
          key === "comments" ||
          key === "currency"
        )
          return;
  
        const formattedTitle = key
          .replace(/_/g, " ") // Replace underscores with spaces
          .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize each word
  
        let column = {
          title: formattedTitle,
          dataIndex: key,
          key: key,
        };
  
        // Special render logic for specific fields
        if (key === "transaction_id") {
          column.render = (transactionId, created) => (
            <div>
              <div>{transactionId}</div>
              {created && (
                <div style={{ fontSize: "12px", color: "#888" }}>
                  {moment(created).local().format("DD-MM-YYYY hh:mm a")}
                </div>
              )}
            </div>
          );
        } else if (key === "payment_status") {
          column.render = (paymentStatus) => {
            let color;
            let statusText;
  
            switch (paymentStatus) {
              case "COMPLETED":
                color = "green";
                statusText = "Completed";
                break;
              case "FAILED":
                color = "red";
                statusText = "Failed";
                break;
              case "PENDING":
                color = "orange";
                statusText = "Pending";
                break;
              default:
                color = "default"; // Default color if status is neither Completed, Failed, nor Pending
                statusText = paymentStatus || "N/A";
                break;
            }
  
            return (
              <Tag color={color} style={{ textTransform: "capitalize" }}>
                {statusText}
              </Tag>
            );
          };
        } else if (key === "payment_mode") {
          column.render = (paymentMode) => {
            // Use the paymentModeColors object to get the corresponding color
            const color =
              paymentModeColors[paymentMode] ||
              `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  
            return (
              <Tag color={color} style={{ textTransform: "capitalize" }}>
                {paymentMode || "NA"}{" "}
                {/* If paymentMode is falsy, it will display "NA" */}
              </Tag>
            );
          };
        } else if (key === "remarks") {
          column.render = (remarks) => {
            const maxLength = 30;
            if (!remarks) return "N/A";
            const isLongText = remarks.length > maxLength;
            const displayedText = isLongText
              ? `${remarks.slice(0, maxLength)}...`
              : remarks;
  
            return (
              <Popover
                content={<div style={{ maxWidth: 200 }}>{remarks}</div>}
                title="Remarks">
                <span style={{ cursor: "pointer" }}>{displayedText}</span>
              </Popover>
            );
          };
        } else if (key === "ownership" || key === "visibility") {
          column.render = (value) =>
            renderAvatars(value, key.charAt(0).toUpperCase() + key.slice(1));
        } else if (key === "status") {
          column.render = (status) =>
            status === "Approved" ? (
              <Tooltip title="Approved">
                <CheckCircleOutlined style={{ color: "green" }} />
              </Tooltip>
            ) : (
              <Tooltip title="Unapproved">
                <CloseCircleOutlined style={{ color: "red" }} />
              </Tooltip>
            );
        } else if (key === "amount") {
          column.render = (amount, record) => (
            <span>
              {record.currency} {amount}
            </span>
          );
        } else if (key === "author") {
          column.render = (author) =>
            author ? (
              <Tooltip title={author}>
                <Avatar style={{ backgroundColor: "#1890ff" }}>
                  {getInitials(author)}
                </Avatar>
              </Tooltip>
            ) : (
              "N/A"
            );
        } else {
          // Default render if no special logic is defined
          column.render = (text) => text || "N/A";
        }
  
        // Push the column to the dynamicColumns array
        dynamicColumns.push(column);
      });
    }
  
    // Conditionally add the "Action" column only if dropdownValue is "allTransactions"
    if (dropdownValue === "allTransactions") {
      dynamicColumns.push({
        title: "Action",
        key: "action",
        render: (_, record) => (
          <div style={{ display: "flex", gap: "10px" }}>
            <Tooltip title="Approve">
              <Button
                type="primary"
                icon={<CheckCircleOutlined />}
                shape="circle"
                style={{ background: "#22c55e" }}
              />
            </Tooltip>
            <Tooltip title="Reject">
              <Button
                type="danger"
                icon={<CloseCircleOutlined />}
                shape="circle"
              />
            </Tooltip>
          </div>
        ),
      });
    }
  
    // Ensure the status column is always the last column
    const statusColumn = dynamicColumns.find((col) => col.key === "status");
    if (statusColumn) {
      // Remove status column from its original position and push it to the end
      dynamicColumns.splice(dynamicColumns.indexOf(statusColumn), 1);
      dynamicColumns.push(statusColumn);
    }
  
    return dynamicColumns;
  }, [transactions, dropdownValue]);
  
  return (
    <Table
      style={{ width: "100%" }}
      columns={columns}
      dataSource={transactions}
      loading={loading}
      pagination={{
        current: pagination.current,
        pageSize: pagination.pageSize,
        total: pagination.total,
        onChange: (page, pageSize) => {
          setPagination({ ...pagination, current: page, pageSize });
        },
        onShowSizeChange: (current, size) => {
          setPagination({ ...pagination, current: 1, pageSize: size });
        },
      }}
      rowKey="transaction_id"
    />
  );
};

export default TransactionsTable;
