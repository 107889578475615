import React from "react";
import { Skeleton, Table } from "antd";

const SkeletonTable = () => {
  // Create 5 rows with 9 columns of skeletons
  const columns = Array.from({ length: 9 }).map((_, index) => ({
    title: <Skeleton.Input active style={{ width: 100 }} size="small" />,
    dataIndex: `col${index}`,
    key: `col${index}`,
    render: () => <Skeleton.Input active style={{ width: 100 }} size="small" />,
  }));

  const dataSource = Array.from({ length: 5 }).map((_, index) => ({
    key: index,
    ...Array.from({ length: 9 }).reduce((acc, _, colIndex) => {
      acc[`col${colIndex}`] = "";
      return acc;
    }, {}),
  }));

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      showHeader={false}
      rowKey="key"
    />
  );
};

export default SkeletonTable;
