import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Spin,
  Checkbox,
  Table,
  Select,
  message,
} from "antd";

const AddTransaction = ({
  dropdownDataPayment,
  loading,
  transactionData,
  setTransactionData,
  transactionList,
  currency, // Prop for default currency value (e.g., 1, 2, 3)
}) => {
  const [form] = Form.useForm();
  const [selectedUids, setSelectedUids] = useState([]);
  const selectedCurrencyLabel =
    dropdownDataPayment.currency.find(
      (item) => item.value === currency // Matching the currency value from dropdown
    )?.label || "Unknown Currency"; // Fallback to avoid undefined labels

  useEffect(() => {
    setTransactionData((prev) => ({
      ...prev,
      currency: currency,
    }));
  }, [currency, setTransactionData]);

  // Handle checkbox selection
  const handleCheckboxChange = (checked, uid) => {
    let updatedSelectedUids;
    if (checked) {
      updatedSelectedUids = [...selectedUids, uid];
    } else {
      updatedSelectedUids = selectedUids.filter((id) => id !== uid);
    }

    setSelectedUids(updatedSelectedUids);
    setTransactionData((prev) => ({
      ...prev,
      client_transaction: updatedSelectedUids, // Store selected UIDs in client_transaction
    }));
  };

  // Table columns definition
  const columns = [
    {
      title: "Select",
      key: "select",
      render: (text, record) => (
        <Checkbox
          checked={selectedUids.includes(record.uid)}
          disabled={
            selectedCurrencyLabel === "INR" || record.currency === "INR" // Enable all rows if INR is selected or the row's currency is INR
              ? false
              : selectedCurrencyLabel !== record.currency // Enable rows matching the selected currency
          }
          onChange={(e) => handleCheckboxChange(e.target.checked, record.uid)}
        />
      ),
    },
    {
      title: "Transaction ID",
      dataIndex: "transaction_id",
      key: "transaction_id",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Total Mapped Amount",
      dataIndex: "total_mapped_amount",
      key: "total_mapped_amount",
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Remaining Amount",
      dataIndex: "Remaining amount",
      key: "Remaining amount",
    },
  ];

  return (
    <Form form={form} layout="vertical" style={{ marginTop: "1rem" }}>
      {loading ? (
        <Spin tip="Loading currency options..." />
      ) : (
        <>
          <Row gutter={16}>
            <Col sm={24} md={12}>
              <Form.Item label="Amount" required>
                <Input
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  placeholder="Enter Amount"
                  onChange={(e) =>
                    setTransactionData((prev) => ({
                      ...prev,
                      amount: e.target.value,
                    }))
                  }
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <Form.Item label="Currency" required>
                <Select
                  value={currency} // Show the label that corresponds to the currency value
                  disabled={true} // Disable the select input, user can't change the currency
                  options={dropdownDataPayment.currency.map((currency) => ({
                    label: currency.label, // Display the label
                    value: currency.value, // Corresponding value
                  }))}
                />
              </Form.Item>
            </Col>
          </Row>


          {/* Transaction Table */}
          <Table
            style={{ marginTop: "18px" }}
            columns={columns}
            dataSource={transactionList?.data} // Ensure to pass the array here
            rowKey="uid" // Use uid as the unique key
            pagination={false}
          />
        </>
      )}
    </Form>
  );
};

export default AddTransaction;
