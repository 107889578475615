// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-modal-call .ant-modal-content {
  padding: 0 !important;
}
.outcome .ant-modal-content {
  padding: 0 !important;
}

.outcome .ant-modal-footer{
    padding: 10px ;
}

.outcome .ant-btn {
    background-color: black!important;
    
}`, "",{"version":3,"sources":["webpack://./src/page-components/Leads/Modal.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;AACA;EACE,qBAAqB;AACvB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,iCAAiC;;AAErC","sourcesContent":[".custom-modal-call .ant-modal-content {\n  padding: 0 !important;\n}\n.outcome .ant-modal-content {\n  padding: 0 !important;\n}\n\n.outcome .ant-modal-footer{\n    padding: 10px ;\n}\n\n.outcome .ant-btn {\n    background-color: black!important;\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
