import React, { useEffect } from "react";
import { Table, Checkbox, Form, Spin, Button, Row, Popover } from "antd";

function CreateSolutionStageTwo({
  solutionFiles,
  loading,
  subscribers,
  setSolutionFormdataStageTwo,
  solutionFormdataStageTwo,
  handleSubscriberChange,
  onSubmit,
  isSubmitLoading,
}) {
  const [form] = Form.useForm();

  // Set finalized files as checked on initial load
  useEffect(() => {
    const finalizedFileUids = solutionFiles
      .filter((file) => file.is_finalized)
      .map((file) => file.file_uid);

    setSolutionFormdataStageTwo((prevData) => ({
      ...prevData,
      file_uids: finalizedFileUids,
    }));
  }, [solutionFiles, setSolutionFormdataStageTwo]);

  // Handle checkbox change for a specific file UID
  const handleCheckboxChange = (file_uid) => {
    setSolutionFormdataStageTwo((prevData) => ({
      ...prevData,
      file_uids: prevData.file_uids.includes(file_uid)
        ? prevData.file_uids.filter((uid) => uid !== file_uid) // Remove if already selected
        : [...prevData.file_uids, file_uid], // Add if not selected
    }));
  };

  // Function to truncate the file name
  const truncateFileName = (fileName) => {
    const parts = fileName.split(".");
    const words = parts[0].split(" ");
    console.log(words,'words')
    if (words.length > 10) {
      return `${words.slice(0, 1).join(" ")}...${parts.slice(-1)[0]}`; // Return first 10 words, a "..." and the last part
    }
    return `${words.join(" ")}.${parts.slice(-1)[0]}`; // Return the original name if it has less than 11 words
  };

  // Columns configuration for the Ant Design Table
  const columns = [
    {
      title: "Flag",
      dataIndex: "flag",
      key: "flag",
      width: 65,
      render: (_, record) => (
        <Checkbox
          checked={
            solutionFormdataStageTwo.file_uids.includes(record.file_uid) ||
            false
          }
          onChange={() => handleCheckboxChange(record.file_uid)}
        />
      ),
    },
    {
      title: "File",
      dataIndex: "file_name",
      key: "file_name",
      render: (file_name, record) => (
        <Popover content={file_name} title="Full File Name">
          <span>{truncateFileName(file_name)}</span>
        </Popover>
      ),
    },
    {
      title: "File Type",
      dataIndex: "file_type",
      key: "file_type",
    },

    {
      title: "Duplitext (Matched %)",
      dataIndex: "duplitext",
      key: "duplitext",
      render: (duplitext) => {
        const percentage = Number(duplitext).toFixed(2);
        return `${percentage}%`;
      },
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
    },
  ];

  // Prepare the data for each row in the table with unique keys
  const dataSource = solutionFiles.map((file) => ({
    key: file.file_uid,
    file_name: file.file_name,
    file_type: file.file_type || "PDF",
    duplitext: file?.copyscape?.avg_percent_match || null,
    remarks: file.remarks || "No remarks",
    file_uid: file.file_uid,
  }));

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 200,
            width: "100%",
          }}>
          <Spin size="large" />
        </div>
      ) : (
        <Form form={form} layout="vertical">
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            rowKey="file_uid"
            bordered
            scroll={{ y: 200 }}
            loading={loading}
          />

          {subscribers?.length > 0 && (
            <div
              style={{
                marginTop: "20px",
                border: "1px solid #fofofo",
                width: "100%",
                borderRadius: "4px",
                padding: "10px 0px",
              }}>
              <label
                style={{ color: "black", marginLeft: "10px" }}
                aria-required>
                Select Subscribers
              </label>
              <div
                className="custom-checkbox-container"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "10px",
                }}>
                {subscribers?.map((subscriber) => (
                  <div
                    key={subscriber.username}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "5px",
                      padding: "3px 5px",
                      borderRadius: "5px",
                    }}>
                    <Checkbox
                      value={subscriber.username}
                      checked={solutionFormdataStageTwo.subscribers.includes(
                        subscriber.username
                      )}
                      onChange={() =>
                        handleSubscriberChange(subscriber.username)
                      }
                    />
                    <span style={{ marginLeft: "8px" }}>
                      {subscriber.subscriber}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}

          <Row justify="end" style={{ width: "100%", marginTop: "20px" }}>
            <Button
              loading={isSubmitLoading}
              type="primary"
              htmlType="submit"
              onClick={onSubmit}>
              Submit
            </Button>
          </Row>
        </Form>
      )}
    </>
  );
}

export default CreateSolutionStageTwo;
