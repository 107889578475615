import { createSlice } from "@reduxjs/toolkit";
import authService from "../../services/authService";
import { current } from 'immer';


const initialState = {
  user: null,
  accessToken: null,
  refreshToken: null,
  isAuthenticated: false,
  userType: null,
  permissions: [],
  loading: false,
  error: null,
  permissionsLoading: false,  // Add loading state for permissions
  lastPermissionsFetch: null,
};
// console.log(initialState.permissions , '123')

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginStart(state) {
      state.loading = true;
      state.error = null;
    },
    loginSuccess(state, action) {
      state.loading = false;
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.userType = action.payload.user.type;
      state.permissions = action.payload.user.permissions;
    },
    loginFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    logout(state) {
      state.isAuthenticated = false;
      state.user = null;
      state.userType = null;
      state.permissions = [];
      state.accessToken = null;
      state.refreshToken = null;
    },

    updatePermissions(state, action) {
      console.log('Updating permissions with payload:', action.payload);
      console.log('Previous state:', current(state));
      
      state.permissions = action.payload;
      if (state.user) {
        state.user = {
          ...current(state.user),
          user: {
            ...current(state.user.user),
            permissions: action.payload || []
          }
        };
      }
      
      console.log('Updated state:', current(state));
    }
    
,    

    resetPassword(state) {
      state.loading = !state.loading;
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFailure,
  logout,
  resetPassword,
  updatePermissions,
} = authSlice.actions;

export const selectUserType = (state) => state.auth.userType;
export const selectPermissions = (state) => state.auth.permissions;

export const signup = (userData) => async (dispatch) => {
  try {
    dispatch(loginStart());
    const response = await authService.signup(userData);
    if (response.success) {
      dispatch(
        loginSuccess({
          user: response.user,
          accessToken: response.access_token,
          refreshToken: response.refresh_token,
        })
      );
      return { success: true };
    }
    dispatch(loginFailure("Something went wrong, please try again later!"));
    return { success: false };
  } catch (error) {
    dispatch(loginFailure(error.message));
    return { success: false };
  }
};

export const login = (credentials) => async (dispatch) => {
  try {
    dispatch(loginStart());
    const response = await authService.login(credentials);
    if (response.success) {
      dispatch(
        loginSuccess({
          user: response.user,
          accessToken: response.access_token,
          refreshToken: response.refresh_token,
        })
      );
      return { success: true };
    }
    dispatch(loginFailure("Something went wrong, please try again later!"));
    return { success: false };
  } catch (error) {
    dispatch(loginFailure(error.message));
    return { success: false };
  }
};

export const reset = (email) => async (dispatch) => {
  try {
    dispatch(resetPassword());
    const response = await authService.reset(email);
    if (response.success) {
      dispatch(resetPassword());
      return { success: true };
    }
    dispatch(loginFailure("Something went wrong, please try again later!"));
    return { success: false };
  } catch (error) {
    dispatch(loginFailure(error.message));
    return { success: false };
  }
};

export const resetconfirm = (payload) => async (dispatch) => {
  try {
    dispatch(resetPassword());
    const response = await authService.resetconfirm(payload);
    if (response.success) {
      dispatch(resetPassword());
      return { success: true };
    }
    dispatch(loginFailure("Something went wrong, please try again later!"));
    return { success: false };
  } catch (error) {
    dispatch(loginFailure(error.message));
    return { success: false };
  }
};

export default authSlice.reducer;
