import React, { useEffect, useState, useCallback } from "react";
import NotificationList from "../../page-components/Notification/NotificationsList";
import NotificationsTabs from "../../page-components/Notification/NotificationTabs";
import { Flex, Spin, message } from "antd";
import notificationsService from "../../services/notificationService";
import { debounce } from "lodash"; // Importing debounce function
import { useLocation } from "react-router-dom";
import {Input} from "antd";
import { Search } from "lucide-react";
const Notification = () => {
  const location = useLocation();
  const categories = [
    { key: "urgent", label: "Urgent", color: "#f87171" },
    { key: "high_priority", label: "High Priority", color: "#fdba74" },
    { key: "feedbacks", label: "Feedbacks", color: "#93c5fd" },
    { key: "resolved", label: "Resolved", color: "#d8b4fe" },
    { key: "info", label: "Info", color: "#cbd5e1" },
  ];
  const [searchValue , setSearchValue] = useState("")
  const queryParams = new URLSearchParams(location.search);
  const level = queryParams.get("level");
  const searchQuery = queryParams.get("search") || ""; // Get search param
  const [activeTab, setActiveTab] = useState(level);
  const [isLoading, setIsLoading] = useState(false);
  const [showUnread, setShowUnread] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [levelCounts,setLevelCounts] = useState([])
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: false,
  });

  useEffect(() => {
    // Set activeTab to the corresponding category key if level matches
    if (level && categories.some((category) => category.key === level)) {
      setActiveTab(level);
    } else {
      setActiveTab(categories[0].key); // Default to first category if no match
    }
  }, [location.search]);

  const getNotifications = useCallback(
    debounce(
      async (level = activeTab, search = "", page = 1, pageSize = 50) => {
        try {
          setIsLoading(true);
          
          // Set level to "all" only if searching
          const adjustedLevel = search ? "all" : level;
          
          const response = await notificationsService.getNotificationsList(
            adjustedLevel,
            search,
            page,
            pageSize
          );
  
          if (response.success) {
     
            const fetchedNotifications = response?.data?.data.results.map(
              (notif) => ({
                verb: notif.html_verb,
                uid: notif.uid,
                unread: notif.unread,
                timestamp: notif.timestamp,
                type: notif.level,
                data: notif.data,
              })
            );
            setLevelCounts(response?.data?.data?.level_counts)
            // setSearchValue("")
            setPagination((prev) => ({
              ...prev,
              total: response?.data?.data?.count || 0,
              current: page,
              pageSize,
            }));
            setIsLoading(false);
            setNotifications(fetchedNotifications);
          }
        } catch (error) {
          setIsLoading(false);
          console.error("Failed to fetch notifications:", error);
        }
      },
      500
    ),
    [ pagination]
  );
  
  const handleSearch = debounce(async (value) => {
    if (value.trim() !== "") {  // ✅ Only fetch when there's input
      setSearchValue(value);
      await getNotifications("all", value, 1, 50);
    }
  }, 500);
  
  
  const handleMarkAsRead = async (uids) => {
    const response = await notificationsService.markAsReadNotification(uids);
    if (response.success) {
      setNotifications((prev) =>
        prev.map((notif) =>
          uids.includes(notif.uid) ? { ...notif, unread: false } : notif
        )
      );
      if (uids.length > 1) {
        message.success("notifications marked as read.");
      }
    } else {
      message.error(
        response.message || "Failed to mark notifications as read."
      );
    }
  };
  console.log(searchQuery , 'search')
  // // Trigger the debounced function when pagination changes
  // useEffect(() => {
  //   getNotifications(activeTab, "", pagination.current, pagination.pageSize);
  // }, [activeTab, pagination.current]);
  useEffect(() => {
    console.log("Triggered useEffect with:", { searchValue, activeTab, pagination });
    
    if (!searchValue) {  // This will check both null & empty string in one go
      console.log("Calling getNotifications...");
      getNotifications(activeTab, "", pagination.current, pagination.pageSize);
    }
  }, [pagination.current]); // ✅ Remove searchValue from dependency array
  
  
  const handleTabChange = (key) => {
    setActiveTab(key);
    setPagination((prev) => ({ ...prev, current: 1 })); // Reset pagination
  
    if (!searchValue) {
      getNotifications(key, "", 1, 50); // Only fetch if no active search
    }
  };
  
  // const handleTabChange = (key) => {
  //   setActiveTab(key); // Update active tab and fetch relevant notifications
  //   setPagination((prev) => ({ ...prev, current: 1 })); // Reset pagination to first page
  // };

  const handleShowUnreadToggle = () => {
    setShowUnread(!showUnread);
  };

  const handleNotificationClick = async (notification) => {
    const { uid, unread } = notification;

    // If the notification is unread, mark it as read
    if (unread) {
      await handleMarkAsRead([uid]);
    }
    if (!notification.data) {
      // Handle the case where data is null
      message.error("Notification data is unavailable.");
      return;
    }
    let redirectType = null;
    const { redirection, order_key } = notification.data;
    console.log(redirection, "re");
    if (redirection.includes("Comment")) {
      redirectType = "Comment";
    } else if (redirection.includes("Feedback")) {
      redirectType = "Feedback";
    } else if (redirection.includes("Solution")) {
      redirectType = "Solution";
    }
    if (redirection.includes("Task_Detail")) {
      window.location.href = `/order-management/my-orders/${order_key}`;
    }
    if (redirection.includes("Transactions")) {
      window.location.href = `/payment-operations/transactions`;
    }

    if (redirectType) {
      window.location.href = `/order-management/my-orders/${order_key}?type=${redirectType}`;
    } else {
      // window.location.href = `/order-management/my-orders/`;
    }
  };

  const filteredNotifications = notifications.filter((notif) => {
    if (activeTab === "all") return showUnread ? notif.unread : true;
    return (
      notif.type.toLowerCase() === activeTab &&
      (showUnread ? notif.unread : true)
    );
  });

  return (
    <Flex
      vertical
      align="flex-end"
      justify="flex-start"
      style={{
        width: "100%",
        margin: "0 auto",
        padding: "20px",
        background: "white",
      }}>
      
      <NotificationsTabs
        activeTab={activeTab}
        onTabChange={handleTabChange}
        onShowUnreadToggle={handleShowUnreadToggle}
        onMarkAllAsRead={async () => {
          const unreadIds = notifications
            .filter((n) => n.unread)
            .map((n) => n.uid);
          if (unreadIds.length > 0) {
            await handleMarkAsRead(unreadIds); // Mark all unread notifications as read
          } else {
            message.info("No unread notifications to mark as read.");
          }
        }}
        onMarkSelectedAsRead={handleMarkAsRead} // Pass the mark selected handler
        showUnread={showUnread}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        categories={categories}
        onSearch={handleSearch} // Pass search function
        levelCounts={levelCounts}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />

      {isLoading ? (
        <Flex style={{ width: "100%" }} justify="center">
          <Spin size="large" />
        </Flex>
      ) : (
        <NotificationList
          notifications={filteredNotifications}
          onMarkAsRead={handleMarkAsRead}
          onNotificationClick={handleNotificationClick}
          pagination={pagination}
          setPagination={setPagination}
        />
      )}
    </Flex>
  );
};

export default Notification;