import React, { useState } from 'react';
import BudgetComp from './FreelancerInfoComp/BudgetComp';
import FlSubscribersComp from './FreelancerInfoComp/FlSubscribersComp';
import BidsComp from './FreelancerInfoComp/BidsComp';
import { Table } from 'antd';

const FreelancerAccord = ({ freelancerInfo, orderData, fetchOrderDetails }) => {


    const { budget = [], bid = [] || "", subscribers = [] } = freelancerInfo || {};

    const tableData = [
        {
            key: '1',
            label: 'Moved to Freelancer Portal:',
            value: orderData?.data?.moved_to_freelancer_portal ? "Yes" : "No",
        },
        {
            key: '2',
            label: 'Freelancer Budget',
            value: budget && budget[0]?.inr_budget
                ? `${budget[0]?.inr_budget} INR`
                : "None",
        },
    ];



    return (
        <>
            <Table
                dataSource={tableData}
                pagination={false}
                bordered
                showHeader={false}
                rowKey="key"
                style={{ marginBottom: "10px" }}
            >
                <Table.Column
                    title="Key"
                    dataIndex="label"
                    key="label"
                    render={(text) => <strong>{text}</strong>}
                />
                <Table.Column
                    title="Value"
                    dataIndex="value"
                    key="value"
                    render={(value) => <div style={{ textAlign: 'right' }}>{value}</div>}
                />
            </Table>
            {budget.length > 1 && <BudgetComp budget={budget} />}
            <BidsComp bids={bid} fetchOrderDetails={fetchOrderDetails} />
            <FlSubscribersComp subscribers={subscribers} />
        </>
    );
};

export default FreelancerAccord;
