import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Col, Form, Input, Row, Card, Typography } from "antd";
import { LockOutlined, KeyOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { resetconfirm } from "../../redux/slices/authSlice";
import { motion } from "framer-motion";

const { Title, Text } = Typography;

const ResetPasswordConfirm = () => {
  const { uid, token } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const next = queryParams.get("next");
  const { loading } = useSelector((state) => state.auth);
  const [form] = Form.useForm();

  const validatePassword = (_, value) => {
    if (!value) {
      return Promise.reject("Please enter your password");
    }
    if (value.length < 8) {
      return Promise.reject("Password must be at least 8 characters long");
    }
    if (!/\d/.test(value)) {
      return Promise.reject("Password must contain at least one number");
    }
    if (!/[a-zA-Z]/.test(value)) {
      return Promise.reject("Password must contain at least one letter");
    }
    return Promise.resolve();
  };

  const validateConfirmPassword = (_, value) => {
    if (!value) {
      return Promise.reject("Please confirm your password");
    }
    if (value !== form.getFieldValue("password1")) {
      return Promise.reject("The two passwords do not match");
    }
    return Promise.resolve();
  };

  const onFinish = async (values) => {
    let payload = {
      new_password1: values.password1,
      new_password2: values.password2,
      uid: uid,
      token: token,
    };
    const { success } = await dispatch(resetconfirm(payload));
    if (success) {
      navigate(next || "/");
    }
  };

  return (
    <Row
      justify="center"
      align="middle"
      style={{
        minHeight: "100vh",
        background: "#f5f5f5",
        padding: "0px"
      }}
    >
      <Col xl={8} xxl={6} lg={10} md={14} sm={22} xs={22}>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Card
            bordered={false}
            style={{
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.05)",
              borderRadius: "12px",
              overflow: "hidden"
            }}
          >
            <Form
              form={form}
              name="reset_password_confirm"
              onFinish={onFinish}
              layout="vertical"
              style={{ padding: "16px" }}
            >
              <div style={{ textAlign: "center", marginBottom: "32px" }}>
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ delay: 0.2 }}
                >
                  <KeyOutlined
                    style={{
                      fontSize: "48px",
                      color: "#36c6d3",
                    }}
                  />
                </motion.div>
                <Title level={4} style={{ marginBottom: "8px", color: "#262626" }}>
                  Create New Password
                </Title>
              </div>

              <Form.Item
                name="password1"
                label="New Password"
                rules={[{ validator: validatePassword }]}
                validateFirst
              >
                <Input.Password
                  prefix={<LockOutlined style={{ color: "#bfbfbf" }} />}
                  placeholder="Enter your new password"
                  size="large"
                  style={{ borderRadius: "6px" }}
                />
              </Form.Item>

              <Form.Item
                name="password2"
                label="Confirm Password"
                rules={[{ validator: validateConfirmPassword }]}
                validateFirst
                dependencies={['password1']}
              >
                <Input.Password
                  prefix={<CheckCircleOutlined style={{ color: "#bfbfbf" }} />}
                  placeholder="Confirm your new password"
                  size="large"
                  style={{ borderRadius: "6px" }}
                />
              </Form.Item>

              <Form.Item style={{ marginBottom: 0, marginTop: "24px" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={loading}
                  style={{
                    width: "100%",
                    height: "40px",
                    background: "#36c6d3",
                    borderColor: "#36c6d3",
                    borderRadius: "6px"
                  }}
                >
                  Reset Password
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </motion.div>
      </Col>
    </Row>
  );
};

export default ResetPasswordConfirm;
