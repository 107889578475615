import React, { useState } from 'react';
import { Button, Modal, message } from 'antd';
import { useParams } from 'react-router-dom';
import { catchError } from '../../utils/errorHandler';
import axiosInstance from '../../services/axiosInstance';

const MoveToAccRejPage = ({ handleModalClose }) => {
    const { orderKey } = useParams()
    const [loading, setLoading] = useState(false)

    const handleSubmitAccRej = async () => {
        setLoading(true)
        try {
            const response = await axiosInstance.post(`/task/v2/move-to-accept-reject/${orderKey}/`)
            if (response.status === 200 && response.data.status === 'success') {
                message.success(response.data.data)
                handleModalClose()
            }
            setLoading(false)

        } catch (err) {
            await catchError(err)
            setLoading(false)

        }
    }

    return (
        <>
            <div style={{ marginBottom: '16px' }}>
                <p>Order Key: <strong>{orderKey}</strong></p>
                <p>Are you sure you want to move to the Accept/Reject page?</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                <Button onClick={() => handleModalClose()}>No</Button>
                <Button onClick={handleSubmitAccRej} type="primary" loading={loading}>
                    {loading ? "Loading..." : 'Submit'}
                </Button>
            </div>
        </>
    );
};

export default MoveToAccRejPage;
