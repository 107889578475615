import React from "react";
import { Table, Tag } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import './orderProgress.css'
const OrderProgressTrackingTableList = ({ data }) => {
  const columns = [
    {
      title: "Expert",
      dataIndex: "expert",
      key: "expert",
    },
    {
      title: "Order Id",
      dataIndex: "order_id",
      key: "orderId",
      render: (order_id) => (
        <Link to={`/order-management/my-orders/${order_id}`} style={{ color: "#1890ff", fontWeight: "bold" }}>
          {order_id}
        </Link>
      ),
    },
    {
      title: "Manager Deadline",
      dataIndex: "manager_deadline",
      key: "managerDeadline",
    },
    {
      title: "Writer Deadline",
      dataIndex: "writer_deadline",
      key: "writerDeadline",
      render: (writer_deadline) => {
        const isToday = moment(writer_deadline).isSame(moment(), "day");
        return (
          <span style={{ color: isToday ? "red" : "inherit", fontWeight: isToday ? "bold" : "normal" }}>
            {writer_deadline}
          </span>
        );
      },
    },
    {
      title: "Client Deadline",
      dataIndex: "client_deadline",
      key: "clientDeadline",
    },
    {
      title: "Started On",
      dataIndex: "started_on",
      key: "startedOn",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        const statusColors = {
          Started: "orange",
          "In Progress": "blue",
          Overdue: "red",
          Complete: "green",
        };

        return (
          <Tag
            color={statusColors[status] || "default"}
            style={{ fontWeight: "bold", borderRadius: 10, padding: "3px 14px" }}
          >
            {status}
          </Tag>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey="order_id"
      pagination={false}
      bordered
      style={{ width: "100%" }}
      rowClassName={(record) =>
        moment(record.writer_deadline).isSame(moment(), "day") ? "row-red" : ""
      }
    />
  );
};

export default OrderProgressTrackingTableList;
