import React from "react";
import { Empty, Table } from "antd";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import moment from "moment";

const MobileCallLogsInfo = ({ data }) => {
  const columns = [
    {
      dataIndex: "key",
      key: "key",
      render: (text) => <strong>{text}</strong>,
    },
    {
      dataIndex: "value",
      key: "value",
      render: (text) => (
        <span style={{ wordWrap: "break-word", wordBreak: "break-word", whiteSpace: "normal" }}>
          {text || "N/A"}
        </span>
      ),
    },
  ];

  const transformDataForTable = (item) => [
    { key: "Call SID", value: item.call_sid },
    { key: "Call Date and Time", value: item.call_date_time ? moment(item.call_date_time).local().format("YYYY-MM-DD hh:mm A") : 'N/A' },
    { key: "Status", value: item.status },
    { key: "Call Duration", value: item.call_duration },
    { key: "Called To", value: item.called_to },
    { key: "Called From", value: item.called_from },
  ];

  return (
    <div style={{ backgroundColor: "#f2f6f9", padding: "10px" }}>
      {data && data.length > 0 ? (
        data.map((item, index) => (
          <Table
            key={index}
            columns={columns}
            dataSource={transformDataForTable(item)}
            pagination={false}
            bordered
            size="small"
            style={{ marginBottom: "25px", borderRadius: "5px" }}
            showHeader={false}
          />
        ))
      ) : (
        <Empty description="No data available" />
      )}
    </div>

  );
};

const CallLogsInfo = ({ callLogsData }) => {

  const { orderKey } = useParams();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const columns = [
    {
      title: "Call SID",
      dataIndex: "call_sid",
      key: "call_sid",
      render: (text) => text || "N/A",
    },
    {
      title: "Call Date and Time",
      dataIndex: "call_date_time",
      key: "call_date_time",
      render: (text) => {
        return text
          ? moment(text).local().format("YYYY-MM-DD hh:mm A")
          : 'N/A'
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => text || "N/A",
    },
    {
      title: "Call Duration",
      dataIndex: "call_duration",
      key: "call_duration",
      render: (text) => text || "N/A",
    },
    {
      title: "Called To",
      dataIndex: "called_to",
      key: "called_to",
      render: (text) => text || "N/A",
    },
    {
      title: "Called From",
      dataIndex: "called_from",
      key: "called_from",
      render: (text) => text || "N/A",
    },
  ];

  const dataSource = callLogsData
    ? callLogsData.map((log, index) => ({
      key: index,
      call_sid: log.call_sid,
      call_date_time: log.call_date_time,
      status: log.status,
      call_duration: log.call_duration,
      called_to: log.called_to,
      called_from: log.called_from,
    }))
    : [];

  return (
    <div>
      <h3>Call Logs - {orderKey}</h3>
      {isMobile ? (
        <MobileCallLogsInfo data={dataSource} />
      ) : (
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          bordered
          scroll={{ y: 350, x: "100%" }}
        />
      )}
    </div>
  );
};

export default CallLogsInfo;
