import React from "react";
import { Modal, Button, Avatar, Flex, Typography } from "antd";
import { PhoneOutlined, UserOutlined } from "@ant-design/icons";
import { resetWarned } from "antd/es/_util/warning";
import "../Leads/Modal.css"
const OrderCallModal = ({ visible, onHangUp, callDuration, onCancel, orderData }) => {
  const { Title } = Typography;
  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  // const getStatusDisplay = () => {
  //   switch (status) {
  //     case "Connected":
  //       return "Connected";
  //     case "Initiating":
  //     case "pending":
  //     case "connecting":
  //       return "Calling...";
  //     case "Disconnected":
  //     case "closed":
  //       return "Call Ended";
  //     case "Rejected":
  //       return "Call Rejected";
  //     case "Canceled":
  //       return "Call Canceled";
  //     case "Error":
  //       return "Call Error";
  //     default:
  //       return status;
  //   }
  // };

  return (
    <Modal
    className="custom-modal-call"
      style={{ padding: 0 }}
      title={
        <Flex className="custom-modal-header">
          <Title
            level={3}
            style={{
              color: "white",
              margin: 0,

              marginLeft: "10px",
            }}
          >
            Calling...
          </Title>
          {/* <CloseOutlined style={{ fontSize: "16px", marginRight: "10px" }} onClick={onClose} /> */}
        </Flex>
      }
      visible={visible}
      onCancel={onCancel}
      footer={null}
      centered
      closable={false}
      width={400}
      height={200}
    >
      <Flex style={{ padding: "10px" }} gap={"small"}>
        <Avatar
          style={{ backgroundColor: "black" }}
          icon={<UserOutlined />}
          size={"large"}
        />

        <Flex vertical>
          <p style={{ margin: 0 }}>{orderData?.data?.data?.key}</p>
          <p
            color="green"
            style={{ color: "green", fontSize: "12px", margin: 0 }}
          >
            Connected
          </p>
        </Flex>
      </Flex>
      <Flex style={{ width: "95%" , padding:'10px' }} justify="flex-end">
        <Button
          type="primary"
          danger
          onClick={onHangUp}

          // icon={<PhoneOutlined rotate={90} />}
        >
          End
        </Button>
      </Flex>
    </Modal>
  );
};

export default OrderCallModal;
