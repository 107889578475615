import React, { useState, useEffect } from "react";
import { Button, Form, Typography, message, Flex } from "antd";
import PayoutModal from "./PayoutModal";
import {
  DownloadOutlined,
  FilterOutlined,
  ExportOutlined,
} from "@ant-design/icons";

import moveToFlAssociatesService from "../../services/moveFlAssociatesService";
import FlOrdersForPayoutService from "../../services/flOrdersForPayout";
import { useMediaQuery } from "react-responsive";
import MobileViewForRequestPayout from "./MobileViewForRequestPayout";
import FlOrderPayoutFilters from "./FlPayoutFilters";
import PayoutRequestTable from "./PayoutRequestTable";
const FlOrderForPayoutTable = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [showPaytmModal, setShowPaytmModal] = useState(false);

  const [selectedRecord, setSelectedRecord] = useState(null);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [modalType, setModalType] = useState("payoutList");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [statusList, setStatusList] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const [showFilters, setShowFilters] = useState(false);
  const [selectedUuid, setSelectedUuid] = useState(null);

  const [filters, setFilters] = useState({});
  const [payoutList, setPayoutList] = useState([]);

  const [filtersList, setFiltersList] = useState([]);
  const [fetchingFl, setFetchingFl] = useState(false);
  const [currency, setCurrency] = useState(null);
  const [flList, setFlList] = useState([]);

  const fetchFlList = async () => {
    setFetchingFl(true);
    try {
      const response = await moveToFlAssociatesService.getFlList();

      if (response) {
        setFetchingFl(false);

        setFlList(response.data.data);
        // console.log(newStatusList , 'new')
      }
    } catch (error) {
      setFetchingFl(false);

      console.error("Error fetching status list:", error);
    }
  };
  const onRowSelect = (record) => {
    setSelectedRecord(record);
  };
  // const handleOpenModal = async (type) => {
  //   setModalType(type);
  // };

  // Fetch moveToFLassociatesOrderList with debounce to prevent excessive API calls
  const fetchStatusList = async () => {
    try {
      const response = await FlOrdersForPayoutService.payoutStatusList();

      if (response) {
        setStatusList(response.data.data); // Store the status list from API
      } else {
        console.error("Failed to fetch status list or invalid response format");
        console.error("Response:", response);
      }
    } catch (error) {
      console.error("Error fetching status list:", error);
    }
  };
  const handleOpenModal = (type) => {
    setModalType(type);
    setShowPaytmModal(true); // Open the modal  
  };

  const handleCloseModal = () => {
    setSelectedUuid(null);
    // setTableData([]);
    form.resetFields();
    setShowPaytmModal(false); // Close the modal
  };
  const handleFormSubmit = async (values) => {
    console.log(values, "values");

    // if (!selectedUuid) {
    //   message.error("No UUID selected for status update.");
    //   return;
    // }

    try {
      let response = null; // Declare response outside the if blocks
      let successMessage = ""; // Variable for dynamic success message

      if (modalType === "deduction") {
        setSubmittingForm(true);
        response =
          await FlOrdersForPayoutService.addFlOrdersPayoutRequestDeduction(
            selectedRecord.uuid,
            values
          );
        successMessage = "Deduction applied successfully.";
      } else {
        setSubmittingForm(true);

        response = await FlOrdersForPayoutService.editStatus(
          selectedRecord.uuid,
          values
        );
        successMessage = "Status updated successfully.";
      }

      if (response.success) {
        message.success(successMessage);
        form.resetFields();
        handleCloseModal();
        fetchFlOrderForPayoutRequestList()
        setSubmittingForm(false);
      } else {
        setSubmittingForm(false);
      }
    } catch (error) {
      setSubmittingForm(false);

      console.error("Error updating:", error);
      //   message.error("An error occurred while updating.");
    } finally {
      setSubmittingForm(false);
    }
  };

  const fetchFlOrderForPayoutRequestList = async () => {
    setLoading(true);
    try {
      const response =
        await FlOrdersForPayoutService.flOrderForPayoutRequestList(
          pagination.current,
          pagination.pageSize,
          filters
        );

      if (response.success) {
        const data = response.data.data.results || [];
        setFiltersList(response?.data?.data?.filters);
        setPayoutList(data);
        setPagination((prev) => ({
          ...prev,
          total: response.data.data.count || 0,
        }));
      } else {
        message.error("Failed to fetch order payouts.");
      }
    } catch (error) {
      message.error("Error fetching order payouts.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFlOrderForPayoutRequestList();
    fetchFlList();
    fetchStatusList();
  }, [pagination.current, pagination.pageSize, filters]);
  const handleSearchClick = (newFilters) => {
    setFilters(newFilters);
    setPagination((prev) => ({ ...prev, current: 1 }));
  };

  const handleCancelSearch = () => {
    setFilters({});
    setPagination((prev) => ({ ...prev, current: 1 }));
  };

  return (
    <Flex vertical style={{ padding: 10 }}>
      {showFilters && (
        <div className="filter-container">
          <FlOrderPayoutFilters
            filtersList={filtersList}
            onSearch={handleSearchClick}
            onCancel={handleCancelSearch}
            flList={flList}
            fetchingFl={fetchingFl}
            statusList={statusList}
          />
        </div>
      )}
      {!loading && (
        <Flex
          gap={isMobile ? 8 : 0}
          style={{ width: "100%", flexDirection: isMobile ? "column" : "row" ,marginTop:10}}
          justify="space-between"
          align={isMobile ? "flex-end" : "center"}>
          <Typography.Paragraph
            style={{
              fontWeight: 500,
              opacity: 0.6,
              textAlign: "left",
              margin: 0,
              padding: 0,
            }}>
            Showing{" "}
            {Math.min(
              (pagination.current - 1) * pagination.pageSize + 1,
              pagination.total
            )}{" "}
            -{" "}
            {Math.min(
              pagination.current * pagination.pageSize,
              pagination.total
            )}{" "}
            of {pagination.total} Orders
          </Typography.Paragraph>
          <Flex gap={4} align="center">
            {/* <Button
              type="primary"
              icon={<ExportOutlined />}
              //   onClick={handleDownload} // ✅ Triggers download only
              style={{ marginLeft: "10px", background: "#040724" }}>
              Export
            </Button>
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              //   onClick={handleDownload} // ✅ Triggers download only
              style={{ marginLeft: "10px", background: "#040724" }}>
              Download CSV
            </Button> */}

            <Button
              type="primary"
              icon={<FilterOutlined />}
              onClick={() => setShowFilters(!showFilters)}
              style={{ marginLeft: "10px", background: "#040724" }}
            />
          </Flex>
        </Flex>
      )}
      {/* ✅ Pass showModal to Mobile View */}
      {isMobile ? (
        <MobileViewForRequestPayout
          payoutData={payoutList}
          handleOpenModal={(type, uuid, record) =>
            handleOpenModal(type, uuid, record)
          }
          onRowSelect={onRowSelect}
          loading={loading}
          pagination={pagination}
          setPagination={setPagination}
        />
      ) : (
        <PayoutRequestTable
          payoutData={payoutList}
          loading={loading}
          pagination={pagination}
          setPagination={setPagination}
          handleOpenModal={(type, uuid, record) =>
            handleOpenModal(type, uuid, record)
          } // ✅ Ensure record is passed\onRow
          onRowSelect={onRowSelect}
        />
      )}

      <PayoutModal
        modalType={modalType}
        handleCloseModal={handleCloseModal}
        handleOpenModal={handleOpenModal}
        handleFormSubmit={handleFormSubmit}
        form={form}
        selectedUuid={selectedUuid} // ✅ Pass the UUID
        currency={currency}
        selectedRecord={selectedRecord}
        submittingForm={submittingForm}
        tableData={tableData}
        setTableData={setTableData}
        visible={showPaytmModal} // Control modal visibility
      />
    </Flex>
  );
};

export default FlOrderForPayoutTable;
