import React from "react";
import { Button, Layout } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import {
  toggleSidebarCollapsed,
  toggleSidebarDrawer,
} from "../../redux/slices/uiSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styles from "./layout.module.css";
import { Typography } from "antd";
import getPageTitle from "../../routes/routeTitles";
import { routeTitles } from "../../routes/routeTitles";

const { Header } = Layout;

const HeaderContent = ({ isAuthenticated }) => {
  const { Title } = Typography;
  const { collapsed, drawerOpen } = useSelector((state) => ({
    collapsed: state.ui.collapsed,
    drawerOpen: state.ui.drawerOpen,
  }));
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPath = location.pathname;
  const pageTitle = getPageTitle(currentPath, routeTitles); // Get the page title

  // Do not render the header if the user is not authenticated
  if (!isAuthenticated) {
    return null;
  }

  return (
    <Header className={styles.global_header}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Button
          type="text"
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => dispatch(toggleSidebarCollapsed())}
          className={styles.header_btn}
        />
        <Button
          type="text"
          icon={drawerOpen ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => dispatch(toggleSidebarDrawer())}
          className={styles.mobile_toggle}
        />
        <Title level={2} style={{ margin: 0 }}>
          {pageTitle}
        </Title>
        {/* Display the title */}
      </div>
    </Header>
  );
};

export default HeaderContent;
