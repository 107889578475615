import {
  Form,
  Input,
  Button,
  Col,
  Row,
  Upload,
  Radio,
  Flex,
  Checkbox,
  message,
  Spin,
  Select,
} from "antd";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import OrderService from "../../services/OrderLists";
import { useState, useEffect } from "react";
import { PaperClipOutlined, DeleteOutlined } from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import "./addCooment.css";
const { Option } = Select;
const AddInternalComment = ({ orderKey, handleTabChange, onClose }) => {
  const [internalCommentData, setInternalCommentData] = useState({
    comment: "",
    comment_type: "",
    user_type: [],
    uploads: [],
  });
  const [dropdownData, setDropdownData] = useState({
    comment_types: [],
    user_types: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const handleCheckboxChange = (selectedUsers) => {
    console.log(selectedUsers, "selectedUsers");
    setInternalCommentData((prevData) => ({
      ...prevData,
      user_type: selectedUsers, // Store as an array of numbers
    }));
  };

  const getFormattedFileName = (fileName) => {
    const extensionIndex = fileName.lastIndexOf(".");
    const baseName =
      extensionIndex !== -1 ? fileName.substring(0, extensionIndex) : fileName;
    const extension =
      extensionIndex !== -1 ? fileName.substring(extensionIndex) : "";

    if (baseName.length > 14) {
      return {
        displayName: `${baseName.substring(0, 14)}...${extension}`,
        fullName: fileName,
      };
    }
    return {
      displayName: fileName,
      fullName: fileName,
    };
  };
  const getFileIcon = (fileName) => {
    const extension = fileName?.split(".").pop().toLowerCase();

    switch (extension) {
      case "pdf":
        return "https://i.ibb.co/Xzcwvy6/images-q-tbn-ANd9-Gc-TGAA4-Wd4bco5-Xv33-Gas-Xrn-Dd-QT5-OFXwa3-HUQ-s.png";
      case "doc":
      case "docx":
        return "https://i.ibb.co/mXGsc7g/images-q-tbn-ANd9-Gc-Rs3n-Wc9jmd-M7-Wm-Dc-EAy3-PC2z-Mur7-A1ee-bw-s.png";
      case "jpg":
      case "jpeg":
        return "https://i.ibb.co/NC2DbKN/images-q-tbn-ANd9-Gc-Qgd-Crtwnin-NU1-Nxzpu-IP6-F9h-Kkpfv-SSgr7cw-s.png";
      case "png":
        return "https://i.ibb.co/mtLjYdr/2048px-pptx-icon-2019-svg.png";
      case "zip":
        return "https://i.ibb.co/WV6wJv4/zip-file-line-icon-vector.jpg";
      default:
        return "https://i.ibb.co/NC2DbKN/images-q-tbn-ANd9-Gc-Qgd-Crtwnin-NU1-Nxzpu-IP6-F9h-Kkpfv-SSgr7cw-s.png";
    }
  };

  const handleRadioChange = (selectedId) => {
    setInternalCommentData({
      ...internalCommentData,
      comment_type: selectedId,
    });
  };
  useEffect(() => {
    const fetchCommentTypes = async () => {
      try {
        setIsLoading(true);
        const response = await OrderService.getCommentTypes();
        const userTypesResponse = await OrderService.getUserTypes();
  
        if (response.success) {
          const transformedUserTypes = [
            {
              user_type: "User Types",
              users: userTypesResponse.map((user) => ({
                full_name: user.name,
                username: user.value.toString(),
              })),
            },
          ];
  
          setDropdownData({
            comment_types: response.data,
            user_types: transformedUserTypes,
          });
  
          // ❌ Removed default selection for comment_type
          setInternalCommentData((prevData) => ({
            ...prevData,
            comment_type: "", // ✅ Set to empty instead of preselecting
          }));
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching comment types:", error);
        setIsLoading(false);
      }
    };
  
    fetchCommentTypes();
  }, []);
  
  // useEffect(() => {
  //   const fetchCommentTypes = async () => {
  //     try {
  //       setIsLoading(true);
  //       const response = await OrderService.getCommentTypes();
  //       const userTypesResponse = await OrderService.getUserTypes();

  //       if (response.success) {
  //         // Transform the user types response into the format we need
  //         const transformedUserTypes = [
  //           {
  //             user_type: "User Types",
  //             users: userTypesResponse.map((user) => ({
  //               full_name: user.name,
  //               username: user.value.toString(), // Convert value to string since it's a number
  //             })),
  //           },
  //         ];

  //         setDropdownData({
  //           comment_types: response.data,
  //           user_types: transformedUserTypes,
  //         });

  //         // Set default comment_type to the id of the first option
  //         setInternalCommentData((prevData) => ({
  //           ...prevData,
  //           comment_type: response.data[0].id,
  //         }));
  //       }
  //       setIsLoading(false);
  //     } catch (error) {
  //       console.error("Error fetching comment types:", error);
  //       setIsLoading(false);
  //     }
  //   };

  //   fetchCommentTypes();
  // }, []);

  const handleSubmitInternalComment = async () => {
    setIsLoading(true); // Loading state during submission
    if (internalCommentData.user_type.length === 0) {
      message.error("Please select user types");
      setIsLoading(false);
      return;
    } else if (!internalCommentData.comment) {
      message.error("Please enter comment");
      setIsLoading(false);
      return;
    } else {
      const formData = new FormData();
      formData.append("comment", internalCommentData.comment);
      formData.append(
        "comment_type",
        parseInt(internalCommentData.comment_type)
      ); // Ensure it's an integer

      internalCommentData.user_type.forEach((userType, index) => {
        formData.append(`user_type[${index}]`, parseInt(userType)); // Convert each to an integer
      });
      // formData.append("comment_type", internalCommentData.comment_type);
      // internalCommentData.user_type.forEach((userType, index) => {
      //   formData.append(`user_type[${index}]`, userType);
      // });

      if (
        internalCommentData.uploads &&
        internalCommentData.uploads.length > 0
      ) {
        internalCommentData.uploads.forEach((file) => {
          formData.append("uploads", file);
        });
      }

      try {
        const response = await OrderService.addInternalComment(
          orderKey,
          formData
        );
        if (response.success) {
          message.success("Comment added successfully");
          setFileList([]); // Clear files after success
          handleTabChange("3");
          onClose();
          setInternalCommentData({
            comment: "",
            comment_type: "",
            user_type: [],
            uploads: [],
          });
        }
      } catch (error) {
        console.error("Error adding internal comment:", error);
      } finally {
        setIsLoading(false); // Reset loading state
      }
    }
  };

  const handleFileChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);

    // Extract only the originFileObj from each file entry
    const files = newFileList.map((file) => file.originFileObj).filter(Boolean);

    setInternalCommentData((prevData) => ({
      ...prevData,
      uploads: files,
    }));
  };

  const handleDeleteFile = (index) => {
    // Remove the file from fileList
    const newFileList = [...fileList];
    newFileList.splice(index, 1);
    setFileList(newFileList);

    // Remove the file from internalCommentData.uploads
    const newUploads = [...internalCommentData.uploads];
    newUploads.splice(index, 1);
    setInternalCommentData((prevData) => ({
      ...prevData,
      uploads: newUploads,
    }));
  };

  return (
    <Row>
      {isLoading ? (
        <Flex justify="center" align="center" style={{ width: "100%" }}>
          <Spin size="large" />
        </Flex>
      ) : (
        <Flex vertical gap="20px" style={{ width: "100%" }}>
          <Form>
            <Col sm={24}>
              <Flex gap="10px" style={{ position: "relative" }}>
                <label>Comment</label>
                <span style={{ color: "red" }}>*</span>
              </Flex>

              <Form.Item required style={{ marginTop: "4px" }}>
                <Input.TextArea
                  required={true}
                  rows={6}
                  value={internalCommentData.comment}
                  onChange={(e) =>
                    setInternalCommentData({
                      ...internalCommentData,
                      comment: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </Col>

            <Row gutter={[16, 16]}>
              <Col sm={24} md={12}>
                <Flex
                  vertical
                  gap="10px"
                  style={{ position: "relative", width: "100%" }}>
                  <Flex
                    justify="space-between"
                    style={{
                      position: "relative",
                      alignItems: "center",
                      width: "100%",
                    }}>
                    <label style={{ color: "black" }} aria-required>
                      Select Comment Type
                      <span style={{ color: "red" }}>*</span>
                    </label>

                    <Tooltip title="Select the appropriate comment type to ensure the notification is sent to the correct category.">
                      <InfoCircleOutlined
                        style={{
                          color: "#1890ff",
                          cursor: "pointer",
                          marginLeft: "5px",
                        }}
                      />
                    </Tooltip>
                  </Flex>
                  <Select
                    placeholder="Select a comment type"
                    value={internalCommentData.comment_type || undefined}
                    onChange={handleRadioChange}
                    style={{ width: "100%" }}>
                    {dropdownData.comment_types.map((option) => (
                      <Option key={option.id} value={option.id}>
                        {option.name}
                      </Option>
                    ))}
                  </Select>
                </Flex>
              </Col>
              <Col sm={24} md={12}>
                <Flex vertical>
                  <Flex gap="10px" style={{ position: "relative" }}>
                    <label style={{ color: "black" }} aria-required>
                      Select User Type
                    </label>
                    <span style={{ color: "red" }}>*</span>
                  </Flex>
                  {dropdownData.user_types.length > 0 ? (
                    <Select
                      mode="multiple" // Allows multi-selection
                      style={{ width: "100%", marginTop: 10 }}
                      placeholder="Select users"
                      value={internalCommentData.user_type} // Ensure this is an array of selected values
                      onChange={handleCheckboxChange}
                      optionFilterProp="label" // Enables search by user name
                    >
                      {dropdownData.user_types.map((userType) =>
                        userType.users.map((user) => (
                          <Select.Option
                            key={user.username}
                            value={user.username}
                            label={user.full_name}>
                            {user.full_name}
                          </Select.Option>
                        ))
                      )}
                    </Select>
                  ) : (
                    <p>No user types available</p>
                  )}
                </Flex>
              </Col>
            </Row>

            <Form.Item style={{ marginTop: "16px" }}>
              <label>Files</label>
              <div className="file-parent " style={{ marginTop: "8px" }}>
                <div className="file-container">
                  {fileList.length === 0 ? (
                    <Flex
                      vertical
                      justify="center"
                      style={{ width: "100%", minHeight: "150px" }}
                      className="file-preview-container">
                      <Dragger
                        style={{
                          width: "100%",
                          background: "none",
                          border: "none",
                        }}
                        showUploadList={false} // Hide Ant Design's automatic file list
                        beforeUpload={() => false} // Prevent automatic upload
                        onChange={handleFileChange} // Handle file selection
                        fileList={fileList} // Set fileList state for controlled uploads
                        multiple // Allow multiple file uploads
                        listType="text" // Display file names below the input
                      >
                        <p
                          className="ant-upload-text "
                          style={{ color: "gray", marginTop: "20px" }}>
                          Drag & drop files here or click to upload
                        </p>
                      </Dragger>
                    </Flex>
                  ) : (
                    <div
                      className="file-preview-container"
                      style={{ display: "flex", flexWrap: "wrap" }}>
                      {fileList.map((file, index) => {
                        const { displayName, fullName } = getFormattedFileName(
                          file.name
                        );

                        return (
                          <div
                            key={index}
                            className="file-preview-item"
                            style={{
                              margin: "10px",
                              display: "flex",
                              alignItems: "center",
                              border: "1px solid #d9d9d9",
                              padding: "2px 4px",
                              borderRadius: "4px",
                            }}>
                            <img
                              style={{
                                height: "40px",
                                width: "40px",
                                marginRight: "8px",
                              }}
                              src={getFileIcon(file.name)}
                              alt="file-icon"
                            />
                            <span title={fullName}>{displayName}</span>
                            <DeleteOutlined
                              style={{ marginLeft: "8px", cursor: "pointer" }}
                              onClick={() => handleDeleteFile(index)} // Call delete function on click
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
                <Upload
                  showUploadList={false} // Hide Ant Design's automatic file list
                  beforeUpload={() => false} // Prevent automatic upload
                  onChange={handleFileChange}
                  fileList={fileList} // Set fileList state for controlled uploads
                  multiple // Allow multiple file uploads
                  listType="text" // Display file names below the input
                >
                  <div
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <PaperClipOutlined
                      style={{ fontSize: "24px", marginRight: "8px" }}
                    />
                  </div>
                </Upload>
              </div>
            </Form.Item>
            <Flex justify="end">
              {" "}
              <Button
                type="primary"
                loading={isLoading}
                onClick={handleSubmitInternalComment}
                style={{ background: "#040724" }}>
                Submit
              </Button>{" "}
            </Flex>
          </Form>
        </Flex>
      )}
    </Row>
  );
};

const AddClientComment = ({ orderKey, handleTabChange, onClose }) => {
  const [clientCommentData, setClientCommentData] = useState({
    comment: "",
    uploads: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const getFormattedFileName = (fileName) => {
    const extensionIndex = fileName.lastIndexOf(".");
    const baseName =
      extensionIndex !== -1 ? fileName.substring(0, extensionIndex) : fileName;
    const extension =
      extensionIndex !== -1 ? fileName.substring(extensionIndex) : "";

    if (baseName.length > 14) {
      return {
        displayName: `${baseName.substring(0, 14)}...${extension}`,
        fullName: fileName,
      };
    }
    return {
      displayName: fileName,
      fullName: fileName,
    };
  };
  const getFileIcon = (fileName) => {
    const extension = fileName?.split(".").pop().toLowerCase();

    switch (extension) {
      case "pdf":
        return "https://i.ibb.co/Xzcwvy6/images-q-tbn-ANd9-Gc-TGAA4-Wd4bco5-Xv33-Gas-Xrn-Dd-QT5-OFXwa3-HUQ-s.png";
      case "doc":
      case "docx":
        return "https://i.ibb.co/mXGsc7g/images-q-tbn-ANd9-Gc-Rs3n-Wc9jmd-M7-Wm-Dc-EAy3-PC2z-Mur7-A1ee-bw-s.png";
      case "jpg":
      case "jpeg":
        return "https://i.ibb.co/NC2DbKN/images-q-tbn-ANd9-Gc-Qgd-Crtwnin-NU1-Nxzpu-IP6-F9h-Kkpfv-SSgr7cw-s.png";
      case "png":
        return "https://i.ibb.co/mtLjYdr/2048px-pptx-icon-2019-svg.png";
      case "zip":
        return "https://i.ibb.co/WV6wJv4/zip-file-line-icon-vector.jpg";
      default:
        return "https://i.ibb.co/NC2DbKN/images-q-tbn-ANd9-Gc-Qgd-Crtwnin-NU1-Nxzpu-IP6-F9h-Kkpfv-SSgr7cw-s.png";
    }
  };
  const [fileList, setFileList] = useState([]);
  const handleFileChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);

    // Extract only the originFileObj from each file entry
    const files = newFileList.map((file) => file.originFileObj).filter(Boolean);

    setClientCommentData((prevData) => ({
      ...prevData,
      uploads: files,
    }));
  };
  const handleSubmitClientComment = async () => {
    setIsLoading(true); // Loading state during submission
    if (clientCommentData.comment === "") {
      message.error("Please enter comment");
      setIsLoading(false);
      return;
    } else {
      const formData = new FormData();
      formData.append("comment", clientCommentData.comment);

      if (clientCommentData.uploads && clientCommentData.uploads.length > 0) {
        clientCommentData.uploads.forEach((file) => {
          formData.append("uploads", file);
        });
      }

      try {
        const response = await OrderService.addClientComment(
          orderKey,
          formData
        );
        if (response) {
          message.success("Comment added successfully");
          setFileList([]); // Clear files after success
          handleTabChange("3");
          onClose();
          setClientCommentData({
            comment: "",
            uploads: [],
          });
        }
      } catch (error) {
        console.error("Error adding internal comment:", error);
      } finally {
        setIsLoading(false); // Reset loading state
      }
    }
  };

  const handleDeleteFile = (index) => {
    // Remove the file from fileList
    const newFileList = [...fileList];
    newFileList.splice(index, 1);
    setFileList(newFileList);

    // Remove the file from internalCommentData.uploads
    const newUploads = [...clientCommentData.uploads];
    newUploads.splice(index, 1);
    setClientCommentData((prevData) => ({
      ...prevData,
      uploads: newUploads,
    }));
  };

  return (
    <Flex
      vertical
      gap="20px"
      style={{
        width: "100%",
      }}>
      <Form>
        <Row gutter={[16, 16]}>
          <Col sm={24} md={12}>
            <label>Comment</label>
            <Form.Item style={{ marginTop: "4px" }}>
              <Input.TextArea
                rows={7}
                value={clientCommentData.comment}
                onChange={(e) =>
                  setClientCommentData({
                    ...clientCommentData,
                    comment: e.target.value,
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col sm={24} md={12}>
            <Form.Item style={{ marginTop: "6px" }}>
              <label>Files</label>
              <div
                className="file-parent "
                style={{
                  marginTop: "8px",
                  minHeight: "160px",
                  maxHeight: "160px",
                  overflowY: "scroll",
                }}>
                <div className="file-container">
                  {fileList.length === 0 ? (
                    <Flex
                      vertical
                      justify="center"
                      style={{ width: "100%", minHeight: "180px" }}
                      className="file-preview-container">
                      <Dragger
                        style={{
                          width: "100%",
                          background: "none",
                          border: "none",
                        }}
                        showUploadList={false} // Hide Ant Design's automatic file list
                        beforeUpload={() => false} // Prevent automatic upload
                        onChange={handleFileChange} // Handle file selection
                        fileList={fileList} // Set fileList state for controlled uploads
                        multiple // Allow multiple file uploads
                        listType="text" // Display file names below the input
                      >
                        <p
                          className="ant-upload-text "
                          style={{ color: "gray", marginTop: "20px" }}>
                          Drag & drop files here or click to upload
                        </p>
                      </Dragger>
                    </Flex>
                  ) : (
                    <div
                      className="file-preview-container"
                      style={{ display: "flex", flexWrap: "wrap" }}>
                      {fileList.map((file, index) => {
                        const { displayName, fullName } = getFormattedFileName(
                          file.name
                        );

                        return (
                          <div
                            key={index}
                            className="file-preview-item"
                            style={{
                              margin: "10px",
                              display: "flex",
                              alignItems: "center",
                              border: "1px solid #d9d9d9",
                              padding: "2px 4px",
                              borderRadius: "4px",
                            }}>
                            <img
                              style={{
                                height: "40px",
                                width: "40px",
                                marginRight: "8px",
                              }}
                              src={getFileIcon(file.name)}
                              alt="file-icon"
                            />
                            <span title={fullName}>{displayName}</span>
                            <DeleteOutlined
                              style={{ marginLeft: "8px", cursor: "pointer" }}
                              onClick={() => handleDeleteFile(index)} // Call delete function on click
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
                <Upload
                  showUploadList={false} // Hide Ant Design's automatic file list
                  beforeUpload={() => false} // Prevent automatic upload
                  onChange={handleFileChange}
                  fileList={fileList} // Set fileList state for controlled uploads
                  multiple // Allow multiple file uploads
                  listType="text" // Display file names below the input
                >
                  <div
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <PaperClipOutlined
                      style={{ fontSize: "24px", marginRight: "8px" }}
                    />
                  </div>
                </Upload>
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Flex justify="end">
          {" "}
          <Button
            type="primary"
            loading={isLoading}
            onClick={handleSubmitClientComment}
            style={{ background: "#040724" }}>
            Submit
          </Button>{" "}
        </Flex>
      </Form>
    </Flex>
  );
};

const AddFreelancerComment = ({ orderKey, handleTabChange, onClose }) => {
  const [freelancerCommentData, setFreelancerCommentData] = useState({
    comment: "",
    file: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const handleFileChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);

    // Extract only the originFileObj from each file entry
    const files = newFileList.map((file) => file.originFileObj).filter(Boolean);

    setFreelancerCommentData((prevData) => ({
      ...prevData,
      file: files,
    }));
  };
  const getFormattedFileName = (fileName) => {
    const extensionIndex = fileName.lastIndexOf(".");
    const baseName =
      extensionIndex !== -1 ? fileName.substring(0, extensionIndex) : fileName;
    const extension =
      extensionIndex !== -1 ? fileName.substring(extensionIndex) : "";

    if (baseName.length > 14) {
      return {
        displayName: `${baseName.substring(0, 14)}...${extension}`,
        fullName: fileName,
      };
    }
    return {
      displayName: fileName,
      fullName: fileName,
    };
  };
  const getFileIcon = (fileName) => {
    const extension = fileName?.split(".").pop().toLowerCase();

    switch (extension) {
      case "pdf":
        return "https://i.ibb.co/Xzcwvy6/images-q-tbn-ANd9-Gc-TGAA4-Wd4bco5-Xv33-Gas-Xrn-Dd-QT5-OFXwa3-HUQ-s.png";
      case "doc":
      case "docx":
        return "https://i.ibb.co/mXGsc7g/images-q-tbn-ANd9-Gc-Rs3n-Wc9jmd-M7-Wm-Dc-EAy3-PC2z-Mur7-A1ee-bw-s.png";
      case "jpg":
      case "jpeg":
        return "https://i.ibb.co/NC2DbKN/images-q-tbn-ANd9-Gc-Qgd-Crtwnin-NU1-Nxzpu-IP6-F9h-Kkpfv-SSgr7cw-s.png";
      case "png":
        return "https://i.ibb.co/mtLjYdr/2048px-pptx-icon-2019-svg.png";
      case "zip":
        return "https://i.ibb.co/WV6wJv4/zip-file-line-icon-vector.jpg";
      default:
        return "https://i.ibb.co/NC2DbKN/images-q-tbn-ANd9-Gc-Qgd-Crtwnin-NU1-Nxzpu-IP6-F9h-Kkpfv-SSgr7cw-s.png";
    }
  };
  const handleSubmitFreelancerComment = async () => {
    setIsLoading(true); // Loading state during submission
    if (freelancerCommentData.comment === "") {
      message.error("Please enter comment");
      setIsLoading(false);
      return;
    } else {
      const formData = new FormData();
      formData.append("comment", freelancerCommentData.comment);

      if (freelancerCommentData.file && freelancerCommentData.file.length > 0) {
        freelancerCommentData.file.forEach((file) => {
          formData.append("file", file);
        });
      }

      try {
        const response = await OrderService.addFreelancerComment(
          orderKey,
          formData
        );
        if (response.success) {
          message.success("Comment added successfully");
          setFileList([]); // Clear files after success
          handleTabChange("3");
          onClose();
          setFreelancerCommentData({
            comment: "",
            file: [],
          });
        }
      } catch (error) {
        console.error("Error adding internal comment:", error);
      } finally {
        setIsLoading(false); // Reset loading state
      }
    }
  };

  const handleDeleteFile = (index) => {
    // Remove the file from fileList
    const newFileList = [...fileList];
    newFileList.splice(index, 1);
    setFileList(newFileList);

    // Remove the file from internalCommentData.uploads
    const newUploads = [...freelancerCommentData.file];
    newUploads.splice(index, 1);
    setFreelancerCommentData((prevData) => ({
      ...prevData,
      file: newUploads,
    }));
  };

  return (
    <Row>
      <Col span={24}>
        <Flex vertical gap="20px" style={{ width: "100%" }}>
          <Form>
            <Row gutter={[16, 16]}>
              <Col sm={24} md={12}>
                <label>Comment</label>
                <Form.Item style={{ marginTop: "4px" }}>
                  <Input.TextArea
                    rows={7}
                    value={freelancerCommentData.comment}
                    onChange={(e) =>
                      setFreelancerCommentData({
                        ...freelancerCommentData,
                        comment: e.target.value,
                      })
                    }
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={12}>
                <Form.Item style={{ marginTop: "6px" }}>
                  <label>Files</label>
                  <div
                    className="file-parent "
                    style={{
                      marginTop: "8px",
                      minHeight: "160px",
                      maxHeight: "160px",
                      overflowY: "scroll",
                    }}>
                    <div className="file-container">
                      {fileList.length === 0 ? (
                        <Flex
                          vertical
                          justify="center"
                          style={{ width: "100%", minHeight: "170px" }}
                          className="file-preview-container">
                          <Dragger
                            style={{
                              width: "100%",
                              background: "none",
                              border: "none",
                            }}
                            showUploadList={false} // Hide Ant Design's automatic file list
                            beforeUpload={() => false} // Prevent automatic upload
                            onChange={handleFileChange} // Handle file selection
                            fileList={fileList} // Set fileList state for controlled uploads
                            multiple // Allow multiple file uploads
                            listType="text" // Display file names below the input
                          >
                            <p
                              className="ant-upload-text "
                              style={{ color: "gray", marginTop: "20px" }}>
                              Drag & drop files here or click to upload
                            </p>
                          </Dragger>
                        </Flex>
                      ) : (
                        <div
                          className="file-preview-container"
                          style={{ display: "flex", flexWrap: "wrap" }}>
                          {fileList.map((file, index) => {
                            const { displayName, fullName } =
                              getFormattedFileName(file.name);

                            return (
                              <div
                                key={index}
                                className="file-preview-item"
                                style={{
                                  margin: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                  border: "1px solid #d9d9d9",
                                  padding: "2px 4px",
                                  borderRadius: "4px",
                                }}>
                                <img
                                  style={{
                                    height: "40px",
                                    width: "40px",
                                    marginRight: "8px",
                                  }}
                                  src={getFileIcon(file.name)}
                                  alt="file-icon"
                                />
                                <span title={fullName}>{displayName}</span>
                                <DeleteOutlined
                                  style={{
                                    marginLeft: "8px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleDeleteFile(index)} // Call delete function on click
                                />
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                    <Upload
                      showUploadList={false} // Hide Ant Design's automatic file list
                      beforeUpload={() => false} // Prevent automatic upload
                      onChange={handleFileChange}
                      fileList={fileList} // Set fileList state for controlled uploads
                      multiple // Allow multiple file uploads
                      listType="text" // Display file names below the input
                    >
                      <div
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }}>
                        <PaperClipOutlined
                          style={{ fontSize: "24px", marginRight: "8px" }}
                        />
                      </div>
                    </Upload>
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Flex justify="end">
              {" "}
              <Button
                type="primary"
                loading={isLoading}
                onClick={handleSubmitFreelancerComment}
                style={{ background: "#040724" }}>
                Submit
              </Button>{" "}
            </Flex>
          </Form>
        </Flex>
      </Col>
    </Row>
  );
};

export { AddInternalComment, AddClientComment, AddFreelancerComment };
