import React, { useState, useEffect } from "react";
import { Flex, Typography } from "antd";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
const { Title, Text } = Typography;

function Home() {
  const [greeting, setGreeting] = useState("");
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    const updateGreeting = () => {
      const currentHour = new Date().getHours();
      if (currentHour < 12) {
        setGreeting("Good Morning");
      } else if (currentHour < 18) {
        setGreeting("Good Afternoon");
      } else {
        setGreeting("Good Evening");
      }
    };

    updateGreeting();
    const timer = setInterval(updateGreeting, 60000); // Update every minute

    return () => clearInterval(timer);
  }, []);

  return (
    <Flex align="flex-start" vertical style={{width:'100%', padding:'0px 16px'}}>
      <Title level={2} style={{ color: "#040724", marginTop:'2em' }}>
        {greeting}, {user.user.full_name} 👋
      </Title>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay: 0.5 }}
      >
        <Text style={{ fontSize: '18px', color: '#555' }}>
          Get ready for a customized experience! Your personalized homepage will be introduced in the upcoming release phase.
        </Text>
      </motion.div>
    </Flex>
  );
}

export default Home;
