import React, { useState } from "react";
import {
  Tabs,
  Table,
  Empty,
  Tag,
  Typography,
  Modal,
  message,
  Button,
  Spin,
} from "antd";
import { DownloadOutlined, DeleteOutlined } from "@ant-design/icons";
import "./Details.css";
import DupliTextParent from "../../components/MyOrderActions/DupliTextParent";
import orderService from "../../services/OrderLists";
import { useParams } from "react-router-dom";
import usePermissions from "../../hooks/usePermissions";
import useIsMobile from "../../hooks/useIsMobile";
import moment from "moment";

import SolutionFilesMobile from "./SolutionMobileViewComponent";
import { linearGradient } from "framer-motion/client";
import DupliTextDetail from "./DuplitextDetail";
const { TabPane } = Tabs;
const SolutionFilesAccordion = ({
  finalFilesData = [],
  similarityReportData = [],
  otherFilesData = [],
  getSolutionFiles,
  setSolutionFiles,
  solutionFiles,
  isClientVerified
}) => {

  const isMobile = useIsMobile(); // Check if device is mobile
  const { hasPermission } = usePermissions();
  const { orderKey } = useParams();
  const [deletingFileUid, setDeletingFileUid] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
 
  const [isDuplitextVisible, setIsDuplitextVisible] = useState(false);
  const [activeKey, setActiveKey] = useState("1");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [copyscapeUid, setCopyscapeUid] = useState(null);

  const handleDelete = async (orderKey, del_obj, tabKey) => {
    console.log(solutionFiles, 123456787654321);
    console.log('hello')
    try {
      setDeletingFileUid(del_obj[0]); // Track the specific file being deleted
      const payload = { del_obj };
      const response = await orderService.deleteSolutionFile(orderKey, payload);

      if (response.success) {
        message.success("File deleted successfully");

        // Update the corresponding file list based on the tab
        setSolutionFiles((prev) => {
          if (!prev) return prev; // Guard clause if prev is undefined
          return {
            ...prev,
            finalFilesData:
              tabKey === "1"
                ? prev.finalFilesData.filter(
                    (file) => !del_obj.includes(file.file_uid)
                  )
                : prev.finalFilesData,
            similarityReportData:
              tabKey === "2"
                ? prev.similarityReportData.filter(
                    (file) => !del_obj.includes(file.file_uid)
                  )
                : prev.similarityReportData,
            otherFilesData:
              tabKey === "3"
                ? prev.otherFilesData.filter(
                    (file) => !del_obj.includes(file.file_uid)
                  )
                : prev.otherFilesData,
          };
        });
      } else {
        message.error("Failed to delete file");
      }
    } catch (err) {
      console.error("Error during file deletion:", err);
      message.error("An error occurred while deleting file");
    } finally {
      setDeletingFileUid(null); // Reset after completion
    }
  };

  const renderTable = (data) => {
    console.log(data, "data is here ");
    // Format each date in the data array
    const formattedData = data.map((item) => ({
      ...item,
      created: moment(item.created).local().format("YYYY-MM-DD hh:mm A"),
    }));

    const handleOpenDuplitext = (uid) => {
      setCopyscapeUid(uid);
      setIsDuplitextVisible(true);
    };

    return (
      <Table
        style={{ width: "100%" }}
        columns={[
          {
            title: "Title",
            dataIndex: "file_name",
            key: "file_name",
          },
          {
            title: "File Type",
            dataIndex: "file_type",
            key: "file_type",
            render: (text) => (
              <Tag color={text === "PDF" ? "green" : "blue"}>{text}</Tag>
            ),
          },
          {
            title: "Created At",
            dataIndex: "created",
            key: "created",
            render: (text) => <span>{text}</span>, // text here will be the formatted date
          },
          {
            title: "Duplitext Score (match percentage )",
            key: "copyscape",
            render: (_, record) =>
              record?.copyscape?.percent ? (
                <Button
                  onClick={() => handleOpenDuplitext(record?.copyscape?.uid)}
                  type="link">
                  {`${record?.copyscape?.percent}%`}
                </Button>
              ) : (
                "N/A"
              ),
          },

        //  ...(hasPermission("can_download_order_solution_files") ? [


        ...(isClientVerified
          ? [
              {
                title: "Download",
                key: "download",
                render: (_, record) => (
                  <a
                    href={record.file_url}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#1890ff" }}>
                    <DownloadOutlined />
                  </a>
                ),
              },
            ]
          : [])
          ,
        // ]
        //   :[]),
          ...(hasPermission("can_view_order_action_delete_solutions")
            ? [
                {
                  title: "Action",
                  key: "actions",
                  render: (_, record) =>
                    deletingFileUid === record.file_uid ? (
                      <Spin size="small" />
                    ) : (
                      <DeleteOutlined
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() =>
                          handleDelete(orderKey, [record.file_uid], activeKey)
                        } // Pass activeKey
                      />
                    ),
                },
              ]
            : []),
        ]}
        dataSource={formattedData} // Use the formatted data here
        pagination={false}
        bordered
        locale={{ emptyText: <Empty description="No Files" /> }}
      />
    );
  };

  const handleSubmissionClicked = () => {
    setIsModalVisible(true);
  };

  const handleTabClick = (key) => {
    setActiveKey(key);
  };

  return (
    <div style={{ padding: 20, border: "none" }}>
      <Tabs
        activeKey={activeKey}
        onTabClick={handleTabClick}
        tabBarExtraContent={
          !isMobile && hasPermission("can_create_duplitext_submissions") ? (
            <Button
              style={{
                background: "#36c6d3",
                color: "white",
                border: "none",
                marginTop: "1rem", // Add space between the button and tabs
              }}
              onClick={handleSubmissionClicked}>
              Duplitext Submission
            </Button>
          ) : null
        }
        className="solution-tabs"
        tabBarGutter={10}
        tabBarStyle={{ boxShadow: "none" }}
        renderTabBar={(props, DefaultTabBar) => (
          <div style={{ padding: "5px", border: "none", boxShadow: "none" }}>
            <DefaultTabBar
              style={{ border: "none", background: "yellow" }}
              {...props}
            />
          </div>
        )}>
        <TabPane
          tab={
            <span
              style={{
                padding: "8px 20px",
                fontWeight: 400,
                borderRadius: "20px",
                backgroundColor: activeKey === "1" ? "#001529" : "transparent",
                color: activeKey === "1" ? "whitesmoke" : "#000000",
                boxShadow:
                  activeKey === "1" ? "0 4px 8px rgba(0, 0, 0, 0.1)" : "none",
              }}>
              Final Files
            </span>
          }
          key="1"
          style={{ textAlign: "center" }}>
          {isMobile ? (
            <SolutionFilesMobile
              finalFilesData={finalFilesData}
              activeKey={activeKey}
              handleDelete={handleDelete}
              handleSubmissionClicked={handleSubmissionClicked}
            />
          ) : (
            renderTable(finalFilesData)
          )}
        </TabPane>
        <TabPane
          tab={
            <span
              style={{
                padding: "8px 20px",
                fontWeight: 400,
                borderRadius: "20px",
                backgroundColor: activeKey === "2" ? "#001529" : "transparent",
                color: activeKey === "2" ? "whitesmoke" : "#000000",
                boxShadow:
                  activeKey === "2" ? "0 4px 8px rgba(0, 0, 0, 0.1)" : "none",
              }}>
              Similarity Files
            </span>
          }
          key="2"
          style={{ textAlign: "center" }}>
          {isMobile ? (
            <SolutionFilesMobile
              similarityReportData={similarityReportData}
              activeKey={activeKey}
              handleDelete={handleDelete}
              handleSubmissionClicked={handleSubmissionClicked}
            />
          ) : (
            renderTable(similarityReportData)
          )}
        </TabPane>
        <TabPane
          tab={
            <span
              style={{
                padding: "8px 20px",
                fontWeight: 400,
                borderRadius: "20px",
                backgroundColor: activeKey === "3" ? "#001529" : "transparent",
                color: activeKey === "3" ? "whitesmoke" : "#000000",
                boxShadow:
                  activeKey === "3" ? "0 4px 8px rgba(0, 0, 0, 0.1)" : "none",
              }}>
              Other Files
            </span>
          }
          key="3"
          style={{ textAlign: "center" }}>
          {isMobile ? (
            <SolutionFilesMobile
              otherFilesData={otherFilesData}
              activeKey={activeKey}
              handleDelete={handleDelete}
              handleSubmissionClicked={handleSubmissionClicked}
            />
          ) : (
            renderTable(otherFilesData)
          )}
        </TabPane>
      </Tabs>
      {isModalVisible && (
        <Modal
          className="custom-modal"
          title={
            <div className="custom-modal-header">
              <Typography.Title
                style={{
                  color: "white",
                  marginLeft: "15px",
                  position: "relative",
                  fontSize: "clamp(14px, 3vw, 20px)",
                  top: "-5px",
                  padding: "0px",
                }}>
                Duplitext Submission
              </Typography.Title>
            </div>
          }
          onCancel={() => setIsModalVisible(false)}
          okText="Submit"
          visible={isModalVisible}
          footer={null}
          height={500}
          width={900}>
          <DupliTextParent />
        </Modal>
      )}

      {isDuplitextVisible && (
        <Modal
          className="custom-modal"
          title={
            <div className="custom-modal-header">
              <Typography.Title
                style={{
                  color: "white",
                  marginLeft: "15px",
                  position: "relative",
                  fontSize: "clamp(14px, 3vw, 20px)",
                  top: "-5px",
                  padding: "0px",
                }}>
                Duplitext Report
              </Typography.Title>
            </div>
          }
          onCancel={() => setIsDuplitextVisible(false)}
          okText="Submit"
          visible={isDuplitextVisible}
          footer={null}
          width={900}
          height={500}>
          <DupliTextDetail uid={copyscapeUid} />
        </Modal>
      )}
    </div>
  );
};

export default SolutionFilesAccordion;
