import React, { useEffect } from "react";
import "./App.css";
import { Provider } from "react-redux";
import store, { persistor } from "./redux/store";
import { ConfigProvider } from "antd";
import { customTheme } from "./customTheme";
import { PersistGate } from "redux-persist/integration/react";
import { MainLayout } from "./components/Layout/MainLayout";
import { fetchPermissions } from "./redux/slices/authSlice";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import ErrorBoundary from "./components/ErrBoundary";
const App = () => {
 

  // const dispatch = useDispatch();
  // const location = useLocation();
  // useEffect(() => {
  //   // Dispatch the action on navigation
  //   dispatch({ type: "FETCH_COMMON_INFO" });
  // }, [dispatch, location.pathname]); // Dependency on location.pathname to trigger on route changes

  return (
    <ErrorBoundary>
      <ConfigProvider theme={customTheme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <MainLayout />
          </PersistGate>
        </Provider>
      </ConfigProvider>
    </ErrorBoundary>
  );
};

export default App;
