import axiosInstance from "./axiosInstance";
import { catchError } from "../utils/errorHandler";
import { message } from "antd";

const deductionsService = {
  getAllDeductions: async (page = 1, pageSize = 10, filters = {}) => {
    const query = new URLSearchParams({
      page,
      page_size: pageSize,
      ...filters, // Dynamically append all filters
    }).toString();
    try {
      const response = await axiosInstance.get(`/deductions/v2/all/?${query}`);
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      if (error.response && error.response.status === 500) {
        message.error("Internal Server Error");
      }
      await catchError(error);
      return { success: false };
    }
  },
  getMyDeductions: async (page = 1, pageSize = 10, filters = {}) => {
    const query = new URLSearchParams({
      page,
      page_size: pageSize,
      ...filters, // Dynamically append all filters
    }).toString();
    try {
      const response = await axiosInstance.get(`/deductions/v2/my/?${query}`);
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      if (error.response && error.response.status === 500) {
        message.error("Internal Server Error");
      }
      await catchError(error);
      return { success: false };
    }
  },
};

export default deductionsService;