import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Row,
  Col,
  message,
  Spin,
  Checkbox,
  Flex,
} from "antd";
import { MoneyCollectOutlined, DisconnectOutlined } from "@ant-design/icons"; // Example icons
import "../css/OrderTable.css";
import dayjs from "dayjs";
import orderService from "../../services/OrderLists";
import commonService from "../../services/commonService";
import { useParams } from "react-router-dom";
const AddQuotation = ({ handleModalClose, fetchOrderDetails }) => {
  const [form] = Form.useForm();
  const { orderKey } = useParams();
  const [selectedRadioPayment, setSelectedRadioPayment] = useState(null); // For currency selection
  const [selectedCurrency, setSelectedCurrency] = useState(null);

  const [quotationData, setQuotationData] = useState({
    budget_without_discount: null,
    discount: null,
    currency: null,
    reminder: true,
    reminder_mail_date: null,
    is_vat_inclusive: false,
  });

  const [dropdownDataPayment, setDropdownDataPayment] = useState({
    currency: [],
  }); // Initialize with empty currency array
  // Fetch currency data on component mount
  const [loading, setLoading] = useState(true); // Add loading state

  const [selectedRadio, setSelectedRadio] = useState(null);
  useEffect(() => {
    const fetchCurrencyList = async () => {
      const response = await commonService.getCurrencyList();
      if (response) {
        setDropdownDataPayment({ currency: response.data.data.currency });
      } else {
        message.error("Failed to load currency list");
      }
      setLoading(false); // Loading complete
    };

    fetchCurrencyList();
  }, []);

  useEffect(() => {
    if (
      dropdownDataPayment.currency &&
      dropdownDataPayment.currency.length > 0
    ) {
      // Check if "USD" exists, else fallback to the first element
      const defaultCurrency = dropdownDataPayment.currency.includes("USD")
        ? "USD"
        : dropdownDataPayment.currency[0];
      // console.log(defaultCurrency, "defaut; curremcy");
      setSelectedRadioPayment(defaultCurrency);
      setSelectedRadio(defaultCurrency);
      setQuotationData((prev) => ({
        ...prev,
        currency: defaultCurrency,
      }));
    }
  }, [dropdownDataPayment]);

  const handleRadioChange = (value) => {
    // console.log(value, 'HELO')
    setSelectedRadio(value);
    setQuotationData((prev) => ({
      ...prev,
      currency: value,
    }));
  };
  const handleVATChange = (e) => {
    setQuotationData((prevData) => ({
      ...prevData,
      is_vat_inclusive: e.target.checked, // Update VAT inclusive state
    }));
  };

  const handleAddPayment = async () => {
    try {

      // console.log(quotationData)
      // Prepare payment data
      const paymentData = {
        ...quotationData,
        reminder_mail_date: quotationData.reminder_mail_date?.toISOString(), // Convert date to ISO string
      };
      // Call the addPayment function from orderService
      const addPaymentResponse = await orderService.addPayment(
        orderKey,
        paymentData
      );

      if (addPaymentResponse.success) {
        message.success("Payment added successfully!");
        setSelectedCurrency(addPaymentResponse?.data?.currency_display);

        handleModalClose();
        await fetchOrderDetails(orderKey);
        setQuotationData({
          budget_without_discount: null,
          discount: null,
          currency: null,
          reminder: true,
          reminder_mail_date: null,
          is_vat_inclusive: false,
        });
        form.resetFields(); // Reset the form fields after successful submission
      } else {
        // message.error("Failed to add payment.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      message.error("Failed to create task: " + error.message);
    }
  };
  return (
    <Form
      form={form}
      layout="vertical"
      encType="multipart/form-data"
      style={{ marginTop: "1rem" }}>
      {loading ? (
        <Flex vertical align="center" justify='center' style={{ width: "100%" }}>
          <Spin tip="Loading currency options..." /></Flex>

      ) : (
        <>
          <Flex vertical style={{ width: "100%" }}>
            <Row gutter={16}>
              <Col lg={8}>
                <Form.Item label="Budget Without Discount" required>
                  <Input
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    suffix={
                      <MoneyCollectOutlined
                        style={{ color: "rgba(0, 0, 0, 0.25)" }}
                      />
                    } // Add the budget icon here
                    placeholder="Budget Without Discount"
                    onChange={(e) =>
                      setQuotationData({
                        ...quotationData,
                        budget_without_discount: e.target.value,
                      })
                    }
                  />
                </Form.Item>
              </Col>
              <Col lg={8}>
                <Form.Item label="Discount" required>
                  <Input
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    suffix={
                      <DisconnectOutlined
                        style={{ color: "rgba(0, 0, 0, 0.25)" }}
                      />
                    } // Add the discount icon here
                    placeholder="Discount"
                    onChange={(e) =>
                      setQuotationData({
                        ...quotationData,
                        discount: e.target.value,
                      })
                    }
                  />
                </Form.Item>
              </Col>
              <Col lg={8}>
                <Form.Item label="Reminder Mail Date">
                  <DatePicker
                    style={{ width: "100%" }}
                    disabledDate={(current) =>
                      current && current < dayjs().startOf("day")
                    }
                    showTime
                    format="YYYY-MM-DD HH:mm A"
                    onChange={(date) =>
                      setQuotationData({
                        ...quotationData,
                        reminder_mail_date: date,
                      })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <div>
              <label style={{ color: "black" }}>
                <span style={{ color: "red" }}>*</span> Select Currency
              </label>
              <div
                className="custom-radio-container"
                style={{ marginTop: "10px" }}>
                {dropdownDataPayment.currency.length > 0 ? (
                  dropdownDataPayment.currency.map((currency) => (
                    <div key={currency}>
                      <input
                        type="radio"
                        id={`custom-radio-${currency}`}
                        name="currency"
                        value={currency}
                        className="custom-radio-input"
                        checked={selectedRadio === currency}
                        onChange={() => handleRadioChange(currency)}
                      />
                      <label
                        style={{ minWidth: "70px", padding: "2px" }}
                        htmlFor={`custom-radio-${currency}`}
                        className="custom-radio-label">
                        {currency}
                      </label>
                    </div>
                  ))
                ) : (
                  <p>No currency options available</p>
                )}
              </div>
            </div>

            {/* <Row gutter={16} style={{ marginTop: "18px" }}>
            <Col sm={24}>
              <Form.Item label="Reason" required>
                <Input.TextArea
                  placeholder="Enter the reason"
                  onChange={(e) =>
                    setQuotationData({
                      ...quotationData,
                      reason: e.target.value,
                    })
                  }
                  rows={4} // Adjust the number of rows as needed
                />
              </Form.Item>
            </Col>
          </Row> */}

            {/* VAT Inclusive Checkbox */}
            <Row gutter={16} style={{ marginTop: "10px" }}>
              <Col xs={24}>
                <Form.Item>
                  <Checkbox
                    checked={quotationData.is_vat_inclusive}
                    onChange={handleVATChange}>
                    VAT Inclusive
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>

            <Flex style={{ width: "100%", marginBottom: "20px" }} align="flex-end" justify="flex-end">
              <Button onClick={handleAddPayment} type="primary">
                Submit
              </Button>
            </Flex>
          </Flex>
        </>
      )}
    </Form>
  );
};

export default AddQuotation;
