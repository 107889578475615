import React, { useState, useEffect } from "react";
import { Modal, Select, Button, Form, message } from "antd";
import orderService from "../../../services/OrderLists";
import CommentsList from "./CommentList";

const { Option } = Select;

const ClientComments = ({ clientComments, userTypes, handleTabChange }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [commentTypes, setCommentTypes] = useState([]);
  const [selectedUserTypes, setSelectedUserTypes] = useState([]);
  const [selectedCommentType, setSelectedCommentType] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchCommentTypes = async () => {
      try {
        setIsLoading(true);
        const response = await orderService.getCommentTypes();
        if (response.success) {
          setCommentTypes(response.data);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching comment types:", error);
        setIsLoading(false);
      }
    };

    fetchCommentTypes();
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    if (!selectedCommentType || selectedUserTypes.length === 0) {
      message.error("Please select a comment type and at least one user type.");
      return;
    }

    try {
      setIsLoading(true);
      const payload = {
        comment_type: selectedCommentType,
        user_types: selectedUserTypes,
      };

      const result = await orderService.moveClientCommentToInternal(payload);
      if (result.success) {
        message.success("Comment moved to internal successfully");
        handleTabChange("3");
        setIsModalVisible(false);
      }
    } catch (error) {
      console.error("Error moving comment:", error);
      message.error("Failed to move comment.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <CommentsList
        comments={clientComments}
        title="CLIENT COMMENTS"
        color="#00bfa5"
        userTypes={userTypes}
        handleTabChange={handleTabChange}
        permissions={{
          add: "can_add_client_comments",
          forward: null,
          moveToFL: "can_move_client_comment_to_fl_comment",
          moveToInternal: "can_move_client_comment_to_internal_comment",
          delete: "can_delete_client_comments",
        }}
        onMoveToInternalClick={showModal} // Pass the function to open the modal
      />

      <Modal
        title="Move to Internal Comment"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={isLoading}
      >
        <Form layout="vertical">
          <Form.Item label="Comment Type" required>
            <Select
              placeholder="Select a comment type"
              value={selectedCommentType}
              onChange={(value) => setSelectedCommentType(value)}
            >
              {commentTypes.map((type) => (
                <Option key={type.id} value={type.id}>
                  {type.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="User Types" required>
            <Select
              mode="multiple"
              placeholder="Select user types"
              value={selectedUserTypes}
              onChange={(values) => setSelectedUserTypes(values)}
            >
              {userTypes.map((type) => (
                <Option key={type.value} value={type.value}>
                  {type.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ClientComments;