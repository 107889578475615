import React from 'react';
import { Empty, Table } from 'antd';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';

const MobileAcceptRejectLogs = ({ data }) => {
    const columns = [
        {
            title: 'Key',
            dataIndex: 'key',
            key: 'key',
            render: (text) => <strong>{text}</strong>,
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            render: (text) => Array.isArray(text)
                ? text.map((item, index) => <div key={index}>{item}</div>)
                : text || 'N/A',
        },
    ];

    const transformDataForTable = (log) => [
        { key: 'Task', value: log.title },
        { key: 'Date/Time', value: log.history_date ? moment(log.history_date).local().format("DD-MM-YYYY hh:mm A") : 'N/A' },
        { key: 'Status', value: log.task_status },
        { key: 'Word', value: log.word },
        { key: 'Word Internal', value: log.word || 'N/A' },
        { key: 'Deadline Soft', value: log.deadline_soft ? moment(log.deadline_soft).local().format("DD-MM-YYYY hh:mm A") : 'N/A' },
        { key: 'Deadline Hard', value: log.deadline_hard ? moment(log.deadline_hard).local().format("DD-MM-YYYY hh:mm A") : 'N/A' },
        { key: 'Accepted By', value: log.accepted_by },
        { key: 'Accepted On', value: log.accepted_on ? moment(log.accepted_on).local().format("DD-MM-YYYY hh:mm A") : 'N/A' },
        {
            key: 'Rejected By',
            value: log.rejection_reasons
                ? log.rejection_reasons.map((reason) => reason.rejected_by || 'N/A').join(', ')
                : 'N/A',
        },
        {
            key: 'Rejected On',
            value: log.rejection_reasons
                ? log.rejection_reasons.map((reason) => reason.rejected_on ? moment(reason.rejected_on).local().format("DD-MM-YYYY hh:mm A") : 'N/A').join(', ')
                : 'N/A',
        },
        {
            key: 'Reason',
            value: log.rejection_reasons
                ? log.rejection_reasons.map((reason) => reason.rejected_reason || 'N/A').join(', ')
                : 'N/A',
        },
    ];

    return (
        <div style={{ backgroundColor: "#f2f6f9", padding: '10px' }}>
            {data && data.length > 0 ? (
                data.map((log, index) => (
                    <Table
                        key={index}
                        columns={columns}
                        dataSource={transformDataForTable(log)}
                        pagination={false}
                        bordered
                        size="small"
                        style={{ marginBottom: '25px', borderRadius: '5px' }}
                        showHeader={false}
                    />
                ))
            ) : (
                <Empty description="No data available" />
            )}
        </div>

    );
};

const AcceptRejectLogs = ({ acceptRejectLogsData }) => {
    const { orderKey } = useParams();
    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

    const columns = [
        { title: 'Task', dataIndex: 'title', key: 'title', render: (text) => text || 'N/A' },
        {
            title: 'Date/Time', dataIndex: 'history_date', key: 'history_date', render: (text) => {
                return text
                    ? moment(text).local().format("DD-MM-YYYY hh:mm A")
                    : 'N/A';
            }
        },
        { title: 'Status', dataIndex: 'status', key: 'status', render: (text) => text || 'N/A' },
        { title: 'Word', dataIndex: 'word', key: 'word', render: (text) => text || 'N/A' },
        { title: 'Word Internal', dataIndex: 'word', key: 'word', render: (text) => text || 'N/A' },
        {
            title: 'Deadline Soft', dataIndex: 'deadline_soft', key: 'deadline-soft', render: (text) => {
                return text
                    ? moment(text).local().format("DD-MM-YYYY hh:mm A")
                    : 'N/A';
            }
        },
        {
            title: 'Deadline Hard', dataIndex: 'deadline_hard', key: 'deadline_hard', render: (text) => {
                return text
                    ? moment(text).local().format("DD-MM-YYYY hh:mm A")
                    : 'N/A';
            }
        },
        { title: 'Accepted By', dataIndex: 'accepted_by', key: 'accepted_by', render: (text) => text || 'N/A' },
        {
            title: 'Accepted On', dataIndex: 'accepted_on', key: 'accepted_on', render: (text) => {
                return text
                    ? moment(text).local().format("DD-MM-YYYY hh:mm A")
                    : 'N/A';
            }
        },
        {
            title: 'Rejected By',
            dataIndex: 'rejection_reasons',
            key: 'rejected_by',
            render: (reasons) => reasons && reasons.length > 0
                ? reasons.map((reason, index) => (
                    <span key={index}>{reason.rejected_by || 'N/A'}{index < reasons.length - 1 && ', '}</span>
                ))
                : 'N/A'
        },
        {
            title: 'Rejected On',
            dataIndex: 'rejection_reasons',
            key: 'rejected_on',
            render: (reasons) => reasons && reasons.length > 0
                ? reasons.map((reason, index) => (
                    <span key={index}>
                        {reason.rejected_on
                            ? moment(reason.rejected_on).local().format("DD-MM-YYYY hh:mm A")
                            : 'N/A'}
                        {index < reasons.length - 1 && ', '}
                    </span>
                ))
                : 'N/A'
        },
        {
            title: 'Reason',
            dataIndex: 'rejection_reasons',
            key: 'rejection_reason',
            render: (reasons) => reasons && reasons.length > 0
                ? reasons.map((reason, index) => (
                    <span key={index}>
                        {reason.rejection_reason || 'N/A'}
                        {index < reasons.length - 1 && ', '}
                    </span>
                ))
                : 'N/A'
        }
    ];

    const dataSource = acceptRejectLogsData
        ? acceptRejectLogsData.map((log, index) => ({
            key: index,
            title: log.title,
            history_date: log.history_date,
            status: log.task_status,
            word: log.word,
            word_internal: log.word_internal,
            deadline_soft: log.deadline_soft,
            deadline_hard: log.deadline_hard,
            accepted_by: log.accepted_by,
            accepted_on: log.accepted_on,
            rejection_reasons: log.rejection_reasons.map((reason) => ({
                rejected_by: reason.rejected_by,
                rejected_on: reason.rejected_on,
                rejection_reason: reason.rejected_reason
            }))
        }))
        : [];


    return (
        <div>
            <h3>Accept/Reject Logs - {orderKey}</h3>
            {isMobile ? (
                <MobileAcceptRejectLogs data={acceptRejectLogsData} />
            ) : (
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                    bordered
                    scroll={{ y: 350, x: '100%' }}
                />
            )}
        </div>
    );
}

export default AcceptRejectLogs;
