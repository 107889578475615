import React, { useState, useEffect } from "react";
import axiosInstance from "../../services/axiosInstance";
import { catchError } from "../../utils/errorHandler";
import { useParams } from "react-router-dom";
import {
  Modal,
  Checkbox,
  List,
  Spin,
  Button,
  message,
  Flex,
  Typography,
} from "antd";
import orderService from "../../services/OrderLists";


function MarkAsDone({ isVisible, setIsModalVisible, feedbackKey , getFeedBack}) {
  const { Title } = Typography;
  const { orderKey } = useParams();
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchSubscribersList = async () => {
    setIsLoading(true);
    try {
      const res = await axiosInstance.get(
        `task/v2/task-subscribers-list/${orderKey}/`
      );
      const data = res?.data?.data || [];
      if (data) setIsLoading(false);
      setDataSource(data);
    } catch (err) {
      catchError(err);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSubscribersList();
  }, [orderKey]);

  const handleUserSelect = (uuid) => {
    setSelectedUsers((prev) =>
      prev.includes(uuid) ? prev.filter((id) => id !== uuid) : [...prev, uuid]
    );
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const selectedUsernames = dataSource
        .filter((item) => selectedUsers.includes(item.uuid))
        .map((item) => item.username);

      const response = await orderService.markAsDone(feedbackKey, {
        subscribers: selectedUsernames,
      });

      if (response.success) {
        message.success("Successfully marked as verified");
        await getFeedBack("4")
        setIsLoading(false);

        setIsModalVisible(false); // Close modal after successful action
      } else {
        message.error("Failed to mark as verified");
      }
    } catch (err) {
      setIsLoading(false);

      message.error("An error occurred");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <Modal
      loading={isLoading}
      className="custom-modal2"
      title={
        <Flex justify="space-between" className="custom-modal-header">
          <Title
            level={3}
            style={{
              color: "white",
              margin: 0,
              position: "relative",
              marginLeft: "10px",
            }}>
            Mark As Done
          </Title>
        </Flex>
      }
      visible={isVisible}
      onCancel={setIsModalVisible} // Ensures modal closes on cancel
      footer={
        <Flex style={{ width: "100%" }} justify="flex-end" align="flex-end">
          <Button loading={isLoading} type="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Flex>
      }>
      <h2>Are you sure to move this order for the next step?</h2>
      <List
        dataSource={dataSource}
        renderItem={(item) => (
          <List.Item>
            <Checkbox
              checked={selectedUsers.includes(item.uuid)}
              onChange={() => handleUserSelect(item.uuid)}>
              {item.subscriber} ({item.type})
            </Checkbox>
          </List.Item>
        )}
      />
    </Modal>
  );
}

export default MarkAsDone;
