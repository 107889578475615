import { Table, List, Card, Empty } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../services/axiosInstance';
import { useMediaQuery } from 'react-responsive';
import Title from 'antd/es/typography/Title';


const MobileViewComponent = ({ data }) => {

    const columns = [
        {
            dataIndex: "key",
            key: "key",
            render: (text) => <strong>{text}</strong>,
        },
        {
            dataIndex: "value",
            key: "value",
            render: (text) => (
                <span style={{ wordWrap: "break-word", wordBreak: "break-word", whiteSpace: "normal" }}>
                    {text || "N/A"}
                </span>
            ),
        },
    ];

    const transformDataForTable = (item) => [
        { key: "Refund Amount", value: ` ${item.currency} ${item.refund_amount} ` },
        { key: "Refund Mode", value: item.refund_mode },
        { key: "Applied By", value: item.applied_by },
        { key: "Date", value: item.created_at },
        { key: "Status", value: item.refund_status },
        { key: "Action Date", value: item.action_at },
    ];

    return (
        <div style={{ backgroundColor: "#f2f6f9", padding: "10px" }}>
            {data && data.length > 0 ? (
                data.map((item, index) => (
                    <Table
                        key={index}
                        columns={columns}
                        dataSource={transformDataForTable(item)}
                        pagination={false}
                        bordered
                        size="small"
                        style={{ marginBottom: "25px", borderRadius: "5px" }}
                        showHeader={false}
                    />
                ))
            ) : (
                <Empty description="No data available" />
            )}
        </div>

    );
};

const OrderRefundList = () => {
    const { orderKey } = useParams();
    const [dataSource, setDataSource] = useState([]);
    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchOrderRefundList = async () => {
            setLoading(true)
            try {
                const res = await axiosInstance.get(`task/v2/order-refund/${orderKey}/`);
                const data = res?.data?.data;
                setDataSource(data);
                setLoading(false)
            } catch (err) {
                console.log("Error fetching order refund list", err);
                setLoading(false)

            }
        };
        fetchOrderRefundList();
    }, [orderKey]);

    const columns = [
        {
            title: 'Refund Amount',
            dataIndex: 'refund_amount',
            key: 'refund_amount',
            render: (text, record) => `${record.currency} ${text}`
        },
        {
            title: 'Refund Mode',
            dataIndex: 'refund_mode',
            key: 'refund_mode',
        },
        {
            title: 'Applied By',
            dataIndex: 'applied_by',
            key: 'applied_by',
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: 'Status',
            dataIndex: 'refund_status',
            key: 'refund_status',
        },
        {
            title: 'Action Date',
            dataIndex: 'action_at',
            key: 'action_at',
        },
    ];

    return (
        <div>
            <Title level={isMobile ? 5 : 4} style={{ marginTop: "0px", textAlign: 'left' }}>
                ORDER REFUND - {orderKey}
            </Title>
            {isMobile ? (
                <MobileViewComponent data={dataSource} />
            ) : (
                <Table dataSource={dataSource} columns={columns} pagination={false} loading={loading} />
            )}
        </div>
    );
};

export default OrderRefundList;
