import React, { useState } from "react";
import { Input, Select, Button, DatePicker, Spin, Flex } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import useIsMobile from "../../hooks/useIsMobile";
import moment from "moment";

const { Option } = Select;

const SearchFilters = ({
  onSearch,
  assignees,
  managers,
  statusOptions,
  filtersList,
  isFiltersDataFetched,
  onCancel,
  authorsList,
  sourcesList,
}) => {
  const isMobile = useIsMobile();
  const [filters, setFilters] = useState({
    name: "",
    email: "",
    phone_no: "",
    assignee: "",
    manager: "",
    status: "",
    next_activity: null,
    author: "",
    source: "",
    created: "",
    assigned_on: "",
    assignee_assigned_on: "",
    manager_assigned_on: "",
  });

  // const handleSearch = () => {
  //   const formattedNextActivity = filters.next_activity
  //     ? dayjs(filters.next_activity).format("M/D/YYYY") // Formats as MM/DD/YYYY
  //     : null;

  //   const formattedActiveTill = filters.active_till
  //     ? dayjs(filters.active_till).format("M/D/YYYY") // Formats as MM/DD/YYYY
  //     : null;

  //   onSearch({
  //     ...filters,
  //     next_activity: formattedNextActivity,
  //     active_till: formattedActiveTill,
  //   });
  // };
  const handleSearch = () => {
    const formattedNextActivity = filters.next_activity
      ? dayjs(filters.next_activity).format("YYYY-MM-DD") // Formats as ISO string
      : null;

    const formattedCreated = filters.created
      ? dayjs(filters.created).format("YYYY-MM-DD") // Formats as ISO string
      : null;

    const formattedActiveTill = filters.active_till
      ? dayjs(filters.active_till).format("YYYY-MM-DD") // Formats as ISO string
      : null;
    const formattedAssignedOn = filters.assigned_on
      ? dayjs(filters.assigned_on).format("YYYY-MM-DD") // Formats as ISO string
      : null;
    const formattedAssgineeAssignedOn = filters.assignee_assigned_on
      ? dayjs(filters.assignee_assigned_on).format("YYYY-MM-DD") // Formats as ISO string
      : null;
    const formattedManagerAssignedOn = filters.manager_assigned_on
      ? dayjs(filters.manager_assigned_on).format("YYYY-MM-DD") // Formats as ISO string
      : null;

    onSearch({
      ...filters,
      next_activity: formattedNextActivity,
      created: formattedCreated,
      active_till: formattedActiveTill,
      assigned_on: formattedAssignedOn,
      assignee_assigned_on: formattedAssgineeAssignedOn,
      manager_assigned_on: formattedManagerAssignedOn,
    });
  };

  const handleCancelSearch = async () => {
    setFilters({
      name: "",
      email: "",
      phone_no: "",
      assignee: "",
      manager: "",
      status: "",
      next_activity: null,
      author: "",
      source: "",
      created: "",
      assigned_on: "",
    });
    await onCancel();
  };

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const formatOptions = (list) =>
    list.map(({ uid, name }) => ({
      value: uid,
      label: name,
    }));

  const renderFilter = (filter) => {
    switch (filter) {
      case "name":
        return (
          <Input
            placeholder="Filter by Name"
            style={{ width: isMobile ? "80vw" : 300 }}
            value={filters.name}
            onChange={(e) => handleFilterChange("name", e.target.value)}
          />
        );
      case "email":
        return (
          <Input
            placeholder="Filter by Email"
            style={{ width: isMobile ? "80vw" : 300 }}
            value={filters.email}
            onChange={(e) => handleFilterChange("email", e.target.value)}
          />
        );
      case "phone_no":
        return (
          <Input
            placeholder="Filter by Phone"
            style={{ width: isMobile ? "80vw" : 300 }}
            value={filters.phone_no}
            onChange={(e) => handleFilterChange("phone_no", e.target.value)}
          />
        );
      case "assignee":
        return (
          <Select
            mode="multiple" // Enables multi-select
            placeholder="Filter by Assignee"
            style={{ width: isMobile ? "80vw" : 300, textAlign: "left" }}
            value={filters.assignee || []} // Multi-select requires an array for the value
            onChange={(value) => handleFilterChange("assignee", value)}
            options={formatOptions(assignees)}
            allowClear
          />
        );

      case "manager":
        return (
          <Select
            mode="multiple" // Enables multi-select
            showSearch
            placeholder="Filter by Manager"
            style={{ width: isMobile ? "80vw" : 300, textAlign: "left" }}
            value={filters.manager || []} // Multi-select requires an array for the value
            onChange={(value) => handleFilterChange("manager", value)}
            options={formatOptions(managers)}
            allowClear
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
          />
        );

      case "status":
        return (
          <Select
            placeholder="Select Status"
            style={{ width: isMobile ? "80vw" : 300, textAlign: "left" }}
            value={filters.status || undefined}
            onChange={(value) => handleFilterChange("status", value)}
            allowClear>
            {statusOptions.map(({ value, label }) => (
              <Option key={value} value={value}>
                {label}
              </Option>
            ))}
          </Select>
        );
      case "next_activity":
        return (
          <DatePicker
            style={{ width: isMobile ? "80vw" : 300 }}
            placeholder="Filter by Next Activity"
            value={filters.next_activity}
            onChange={(value) => handleFilterChange("next_activity", value)}
          />
        );
      case "created":
        return (
          <DatePicker
            style={{ width: isMobile ? "80vw" : 300 }}
            placeholder="Filter by Created Date"
            value={filters.created}
            onChange={(value) => handleFilterChange("created", value)}
          />
        );
      case "author":
        return (
          <Select
            placeholder="Filter by Author"
            style={{ width: isMobile ? "80vw" : 300, textAlign: "left" }}
            value={filters.author || undefined}
            onChange={(value) => handleFilterChange("author", value)}
            options={
              Array.isArray(authorsList)
                ? authorsList.map((author) => ({
                    value: author.uid, // Use the `uid` as the value
                    label: author.full_name, // Use the `full_name` as the display label
                  }))
                : []
            } // Fallback to an empty array if authorsList is not an array
            allowClear
          />
        );

      case "source":
        return (
          <Select
            s
            placeholder="Filter by Source"
            style={{ width: isMobile ? "80vw" : 300, textAlign: "left" }}
            value={filters.source || undefined}
            onChange={(value) => handleFilterChange("source", value)}
            options={sourcesList?.data?.map((source) => ({
              value: source.key, // Assuming you want to use 'key' as the value
              label: source.label, // Assuming you want to use 'label' as the display text
            }))}
            allowClear
          />
        );

      case "active_till":
        return (
          <DatePicker
            style={{ width: isMobile ? "80vw" : 300 }}
            placeholder="Filter by Active Till"
            value={filters.active_till}
            onChange={(value) => handleFilterChange("active_till", value)}
          />
        );
      case "assigned_on":
        return (
          <DatePicker
            style={{ width: isMobile ? "80vw" : 300 }}
            placeholder="Filter by Assigned On"
            value={filters.assigned_on}
            onChange={(value) => handleFilterChange("assigned_on", value)}
          />
        );
      case "assignee_assigned_on":
        return (
          <DatePicker
            style={{ width: isMobile ? "80vw" : 300 }}
            placeholder="Filter by Assignee assigned on"
            value={filters.assignee_assigned_on}
            onChange={(value) =>
              handleFilterChange("assignee_assigned_on", value)
            }
          />
        );
      case "manager_assigned_on":
        return (
          <DatePicker
            style={{ width: isMobile ? "80vw" : 300 }}
            placeholder="Filter by Manager assigned on"
            value={filters.manager_assigned_on}
            onChange={(value) =>
              handleFilterChange("manager_assigned_on", value)
            }
          />
        );
      default:
        return null;
    }
  };

  return (
    <div
      style={{
        width: "100%",
        background: "#eceff1",
        padding: "20px 20px",
        borderRadius: "8px",
      }}>
      <div
        style={{
          marginBottom: 16,
          display: "flex",
          gap: 10,
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}>
        {isFiltersDataFetched ? (
          <Flex style={{ width: "100%" }} justify="center" align="center">
            <Spin />
          </Flex>
        ) : (
          filtersList.map((filter) => renderFilter(filter))
        )}
      </div>
      {console.log(authorsList, "author List")}
      <div style={{ display: "flex", justifyContent: "flex-end", gap: 20 }}>
        <Button
          type="primary"
          icon={<SearchOutlined />}
          onClick={handleSearch}
          style={{ width: 140, background: "#040724" }}>
          Search
        </Button>
        <Button style={{ width: 140 }} onClick={handleCancelSearch}>
          Reset
        </Button>
      </div>
    </div>
  );
};

export default SearchFilters;
