import axiosInstance from "./axiosInstance";
import { catchError } from "../utils/errorHandler";
import { message } from "antd";
const commonService = {
    getCurrencyList: async () => {
        try {
          const response = await axiosInstance.get("api/v2/currency/all/");
          if (response.status === 200) {
            return { success: true, data: response.data };
          }
          return { success: false };
        } catch (error) {
          await catchError(error);
          return { success: false };
        }
      },
};

export default commonService;
