import React from "react";
import { useState } from "react";
import { List, Checkbox, Tag, Button, Flex } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import { Circle, Dot } from "lucide-react";
const NotificationList = ({
  notifications,
  onMarkAsRead,
  onCheckboxChange,
}) => {
  const [notificationState, setNotificationState] = useState(
    notifications.map((notif) => ({ ...notif, showButton: true }))
  );
  const handleButtonClick = (id) => {
    setNotificationState((prev) =>
      prev.map((notif) =>
        notif.id === id ? { ...notif, showButton: false } : notif
      )
    );
  };
  return (
    <List
      style={{ width: "100%", background: "transparent" }}
      dataSource={notifications}
      renderItem={(item) => (
        <List.Item
          style={{
            borderBottom: "1px solid #D1D5DB",
            borderRadius: 4,
            padding: 16,
            marginBottom: 12,
            backgroundColor: item.read ? "#f9f9f9" : "transparent",
            width: "100%",
          }}>
          <Flex
            justify="space-between"
            align="flex-start"
            style={{ width: "100%" }}>
            <Flex gap={8} align="flex-start" style={{ cursor: "pointer" }}>
              <p style={{ color: "#595959", margin: 0, padding: 0 }}>
                {item.description}
              </p>
              <Tag color={"yellow"}>{item.label}</Tag>
            </Flex>

            <div
              style={{
                display: "flex",

                justifyContent: "space-between",
                alignItems: "center",
              }}>
              <Button
                style={{
                  border: "transparent",
                  boxShadow: "none",
                  cursor: "pointer",
                }}
                icon={<Dot size={76} color="#1C64F2" />}
                onClick={() => handleButtonClick(item.id)}
              />

              <span style={{ color: "#8c8c8c", fontSize: 12 }}>
                <ClockCircleOutlined /> {item.timestamp}
              </span>
            </div>
          </Flex>
        </List.Item>
      )}
    />
  );
};

export default NotificationList;
