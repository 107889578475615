import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Button,
  Empty,
  Spin,
  Modal,
  message,
  Flex,
} from "antd";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import Comment from "./Comment";
import useIsMobile from "../../../hooks/useIsMobile";
import { useParams } from "react-router-dom";
import usePermissions from "../../../hooks/usePermissions";
import {
  AddInternalComment,
  AddClientComment,
  AddFreelancerComment,
} from "../AddCmomment";

const { Title } = Typography;

const CommentsList = ({
  comments,
  title,
  color,
  onDeleteComment,
  permissions,
  handleTabChange,
  userTypes,
  onMoveToInternalClick,
  setOrderData,
  moveToPortal,
}) => {
  const isMobile = useIsMobile();
  const { hasPermission } = usePermissions();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const { orderKey } = useParams();

  const onClose = () => setIsModalVisible(false);

  const commentType =
    title === "INTERNAL COMMENTS"
      ? "internal"
      : title === "CLIENT COMMENTS"
      ? "client"
      : "freelancer";

  const addComment = async () => {
    try {
      let modalTitle = "";
      let modalContent = null;

      switch (commentType) {
        case "internal":
          modalTitle = "Add Internal Comment";
          modalContent = (
            <AddInternalComment
              orderKey={orderKey}
              handleTabChange={handleTabChange}
              onClose={onClose}
            />
          );
          break;
        case "client":
          modalTitle = "Add Client Comment";
          modalContent = (
            <AddClientComment
              orderKey={orderKey}
              handleTabChange={handleTabChange}
              onClose={onClose}
            />
          );
          break;
        case "freelancer":
          modalTitle = "Add Freelancer Comment";
          modalContent = (
            <AddFreelancerComment
              orderKey={orderKey}
              handleTabChange={handleTabChange}
              onClose={onClose}
            />
          );
          break;
        default:
          message.error("Invalid comment type");
          return;
      }

      setModalTitle(modalTitle);
      setModalContent(modalContent);
      setIsModalVisible(true);
    } catch (error) {
      console.error("Error in addComment:", error);
      message.error("An error occurred while adding the comment");
    }
  };

  return (
    <div style={{ padding: "10px" , }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",

  
        }}>
        <Title level={5} style={{ color: "black" }}>
          {title}
        </Title>
        {hasPermission(permissions.add) && (
          <Button
            onClick={addComment}
            icon={
              <PlusOutlined
                fontSize="16px"
                style={{ color: "white", color: "black" }}
              />
            }
            style={{
              backgroundColor: "none",
              boxShadow: "none",
              marginTop: "10px",
              width: "30px",
              height: "30px",
              borderRadius: "100%",
            }}
          />
        )}
      </div>
      <Modal
        className="create-order"
        visible={isModalVisible}
        width={800}
        height={500}
        title={
          <div
            className="custom-modal-header"
            style={{ display: "flex", justifyContent: "space-between" }}>
            <Title
              level={3}
              style={{
                color: "white",
                margin: 0,
                position: "relative",
                left: "12px",
              }}>
              {modalTitle}
            </Title>
            <CloseOutlined
              style={{ fontSize: "16px", marginRight: "10px" }}
              onClick={onClose}
            />
          </div>
        }
        closable={false}
        footer={null}
        onCancel={() => setIsModalVisible(false)}>
        {modalContent}
      </Modal>
      {comments.length > 0 ? (
       <>
          <Flex vertical gap={"34px"}>
            <div
              style={{
                padding: "0",
                minWidth: isMobile ? "auto" : "370px",
                minHeight: "300px",
                maxHeight: "500px",
                overflowX: "hidden",
                overflowY: "auto",
                width: "100%",
                backgroundColor: "white",
                padding: "10px",
                borderRadius: "10px",
              }}>
              {comments.map((comment) => (
                <Comment
                  userTypes={userTypes}
                  key={comment.uuid}
                  comment={comment}
                  onDelete={onDeleteComment}
                  color={color}
                  permissions={permissions}
                  handleTabChange={handleTabChange}
                  commentType={commentType}
                  onMoveToInternalClick={onMoveToInternalClick}
                  setOrderData={setOrderData}
                  moveToPortal={moveToPortal}
                />
              ))}
            </div>
          </Flex>
 </>
      ) : (
        <div
          style={{
            width: "100%",
            padding: "0",
            minWidth: isMobile ? "auto" : "370px",
            maxHeight: "300px",
            minHeight: "300px",
            overflowY: "scroll",
            backgroundColor: "white",
            padding: "10px",
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Empty description={`No ${title.toLowerCase()} available`} />
        </div>
      )}
    </div>
  );
};

export default CommentsList;
