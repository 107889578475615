import React from 'react'
import { Empty, Table } from 'antd';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';


const MobilePaymentTaskLogs = ({ data }) => {
    const columns = [
        {
            dataIndex: 'key',
            key: 'key',
            render: (text) => <strong>{text}</strong>,
        },
        {
            dataIndex: 'value',
            key: 'value',
            render: (text) => text || 'N/A',
        },
    ];

    const transformDataForTable = (item) => [
        { key: 'Amount Paid', value: item.amount_paid },
        { key: 'Budget', value: item.budget },
        { key: 'Currency', value: item.currency },
        { key: 'Date/Time', value: item.history_date },
        { key: 'Changed By', value: item.changed_by },
        { key: 'Comment', value: item.comment },
    ];

    return (
        <div style={{ backgroundColor: "#f2f6f9", padding: '10px' }}>
            {data && data.length > 0 ? (
                data.map((item, index) => (
                    <Table
                        key={index}
                        columns={columns}
                        dataSource={transformDataForTable(item)}
                        pagination={false}
                        bordered
                        size="small"
                        style={{ marginBottom: '25px', borderRadius: '5px' }}
                        showHeader={false}
                    />
                ))
            ) : (
                <Empty description="No data available" />
            )}
        </div>
    );
};

const PaymentTaskLogs = ({ paymentLogsData }) => {

    const { orderKey } = useParams();
    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

    const columns = [
        {
            title: 'Amount Paid',
            dataIndex: 'amount_paid',
            key: 'amount_paid',
            render: (text) => text || 'N/A',
        },
        {
            title: 'Budget',
            dataIndex: 'budget',
            key: 'budget',
            render: (text) => text || 'N/A',
        },
        {
            title: 'Currency',
            dataIndex: 'currency',
            key: 'currency',
            render: (text) => text || 'N/A',
        },
        {
            title: 'Date/Time',
            dataIndex: 'history_date',
            key: 'history_date',
            render: (text) => text || 'N/A',
        },
        {
            title: 'Changed By',
            dataIndex: 'changed_by',
            key: 'changed_by',
            render: (text) => text || 'N/A',
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
            render: (text) => text || 'N/A',
        },
    ];

    const dataSource = paymentLogsData
        ? paymentLogsData.map((log, index) => ({
            key: index,
            amount_paid: log.amount_paid,
            budget: log.budget,
            changed_by: log.changed_by,
            currency: log.currency,
            comment: log.comment,
            history_date: log?.history_date
        }))
        : [];

    return (
        <div>
            <h3>Payment Data Logs - {orderKey}</h3>
            {
                isMobile
                    ? <MobilePaymentTaskLogs data={dataSource} />
                    :
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                        bordered
                        scroll={{ y: 350, x: '100%' }}
                    />
            }
        </div>
    );
}

export default PaymentTaskLogs;
