import React, { useState } from "react";
import "./Table.css";
import {
  Table,
  Tag,
  Button,
  Checkbox,
  Tooltip,
  Flex,
  Spin,
  message,
  Badge,
} from "antd";
import usePermissions from "../../hooks/usePermissions";
import {
  EditOutlined,
  EyeFilled,
  FacebookFilled,
  LinkedinFilled,
} from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import ManageLeadContent from "./ManageLeadContent";
import LeadMobileView from "./LeadMobileView";
import LeadsList from "../../services/LeadsList";
import { render } from "@testing-library/react";
import moment from "moment";
const LeadsTable = ({
  data,
  loading,
  pagination,
  setPagination,
  fetchData,
  selectedUids,
  setSelectedUids,
  leadType,
}) => {
  const [loadingDetails, setLoadingDetails] = useState(false); // Track loading state for a specific record
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [leadDetails, setLeadDetails] = useState({});
console.log(data , 'data')
  const { hasPermission } = usePermissions();
  const [expandedInfoKeys, setExpandedInfoKeys] = useState([]);
  const [managedRowKey, setManagedRowKey] = useState(null);

  const onCheckboxChange = (uid) => {
    setSelectedUids((prevUids) =>
      prevUids.includes(uid)
        ? prevUids.filter((id) => id !== uid)
        : [...prevUids, uid]
    );
  };

  const handleInfoExpand = async (record) => {
    if (!record) return;

    // Check if we're already loading the details for this record
    if (loadingDetails === record.uid) return;

    // Call handleViewDetails with the record to fetch details
    await handleViewDetails(record);

    const newExpandedInfoKeys = expandedInfoKeys.includes(record.key)
      ? expandedInfoKeys.filter((key) => key !== record.key)
      : [record.key];
    setExpandedInfoKeys(newExpandedInfoKeys);
    setManagedRowKey(null);
  };

  const handleManageExpand = (record) => {
    setManagedRowKey(managedRowKey === record.key ? null : record.key);
    setExpandedInfoKeys(expandedInfoKeys.filter((key) => key !== record.key));
  };

  const handleViewDetails = async (record) => {
    if (!record) return;

    // Set the loading state for the specific record
    setLoadingDetails(true);

    try {
      const result = await LeadsList.getLeadDetail(record.uid);
      if (result.success) {
        setLoadingDetails(false);

        message.success("Data fetched successfully");
        setLeadDetails((prevDetails) => ({
          ...prevDetails,
          [record.uid]: result.data,
        }));
      } else {
        setLoadingDetails(false);

        message.error("Failed to fetch lead details");
      }
    } catch (error) {
      setLoadingDetails(false);

      console.error(error);
      message.error("An error occurred while fetching lead details");
    } finally {
      // Reset loading state after fetching
      setLoadingDetails(false);
    }
  };

  const expandedInfoRowRender = (record) => {
    const details = leadDetails[record.uid]?.data || {};
    const { social_links, tags } = details;
    const socialLinks = social_links || {};
    const tagList = tags || [];

    return (
      <div>
        <Table
          dataSource={[details]}
          loading={loadingDetails}
          columns={[
            { title: "Email", dataIndex: "email", key: "email" },
            { title: "Phone Number", dataIndex: "phone_no", key: "phone_no" },
            // { title: "Assignee", dataIndex: "assignee", key: "assignee" },
            { title: "Type", dataIndex: "type", key: "type" },
            { title: "Author", dataIndex: "author", key: "author" },
            {
              title: "Social Links",
              key: "social_links",
              render: () => (
                <div>
                  {socialLinks.Facebook && (
                    <Tooltip title="Facebook">
                      <a
                        href={socialLinks.Facebook}
                        target="_blank"
                        rel="noopener noreferrer">
                        <FacebookFilled
                          style={{ fontSize: "20px", marginRight: "8px" }}
                        />
                      </a>
                    </Tooltip>
                  )}
                  {socialLinks.LinkedIn && (
                    <Tooltip title="LinkedIn">
                      <a
                        href={socialLinks.LinkedIn}
                        target="_blank"
                        rel="noopener noreferrer">
                        <LinkedinFilled
                          style={{ fontSize: "20px", marginRight: "8px" }}
                        />
                      </a>
                    </Tooltip>
                  )}
                </div>
              ),
            },
            {
              title: "Tags",
              key: "tags",
              render: () => (
                <div>
                  {tagList.map((tag, index) => (
                    <Tag color="geekblue" key={index}>
                      {tag}
                    </Tag>
                  ))}
                </div>
              ),
            },
          ]}
          pagination={false}
          rowKey="uid"
        />
      </div>
    );
  };

  const columns = [
    {
      title: "",
      dataIndex: "select",
      key: "select",
      render: (_, record) => (
        <Checkbox
          checked={selectedUids.includes(record.uid)}
          onChange={() => onCheckboxChange(record.uid)}
        />
      ),
    },

    {
      title: "Full Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Flex align="flex-start" gap={4}>
          {/* Conditionally render EyeFilled if the user has permission */}
          {hasPermission("can_view_lead_details") && (
            <EyeFilled
              onClick={() => handleInfoExpand(record)}
              style={{ cursor: "pointer", marginTop: "6px", color: "black" }}
            />
          )}
          <div>
            <div style={{ margin: 0 }}>{text}</div>
            {record.assignedOn && (
              <div style={{ fontSize: "12px", color: "gray", opacity: 0.7 }}>
                {moment(record.assignedOn) // use text as the date value
                  .local()
                  .format("YYYY-MM-DD hh:mm A")}
              </div>
            )}
          </div>
        </Flex>
      ),
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      render: (text) => <Tag typeof="#008000" color="magenta">{text}</Tag>,
    },
    {
      title: "Active Till",
      dataIndex: "activeTill",
      key: "activeTill",
      render: (text) => {
        if (!text) return null;
        return (
          <div>
            {moment(text) // use text as the date value
              .local()
              .format("YYYY-MM-DD hh:mm A")}
          </div>
        );
      },
    },

    {
      title: "Next Activity",
      dataIndex: "nextActivity",
      key: "nextActivity",
      render: (text, record) => {
        if (!text) return null;
        return (
          <div>
            {moment(text) // use text as the date value
              .local()
              .format("YYYY-MM-DD hh:mm A")}
          </div>
        );
      },
    },


    { title: "Assignee", dataIndex: "assignee", key: "assignee" },
    { title: "Manager", dataIndex: "manager", key: "manager" },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => <Tag color="green">{text}</Tag>,
    },
    // Conditionally include the Manage column if the user has permission
    ...(hasPermission("can_manage_leads")
      ? [
          {
            title: "Manage",
            key: "edit",
            render: (_, record) => (
              <Button
                type="link"
                onClick={() => handleManageExpand(record)}
                icon={<EditOutlined style={{ color: "#040724" }} />}
              />
            ),
          },
        ]
      : []),
  ];

  return (
    <Flex vertical style={{ width: "100%" }} align="flex-start">
      {isMobile ? (
        <LeadMobileView
          data={data}
          leadDetails={leadDetails}
          loading={loading}
          selectedUids={selectedUids}
          setSelectedUids={setSelectedUids}
          leadType={leadType}
          fetchData={fetchData}
        />
      ) : (
        <Table
          style={{ width: "100%" }}
          columns={columns}
          dataSource={data}
          rowClassName={(record) =>
            expandedInfoKeys.includes(record.key) ||
            managedRowKey === record.key
              ? "expanded-row"
              : ""
          }
          expandable={{
            expandedRowRender: (record) =>
              managedRowKey === record.key ? (
                <ManageLeadContent
                  record={record}
                  fetchData={fetchData}
                  leadType={leadType}
                />
              ) : (
                expandedInfoRowRender(record)
              ),
            expandedRowKeys: [...expandedInfoKeys, managedRowKey].filter(
              Boolean
            ),
            onExpand: (expanded, record) => {
              if (expanded) {
                // Check if we are already loading details for this record

                handleInfoExpand(record); // Call your function to fetch details
              }
            },

            expandIconColumnIndex: -1,
          }}
          loading={loading}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            showSizeChanger: false,
            pageSizeOptions: ["10", "20", "50", "100"],
            onChange: (page, pageSize) => {
              setPagination({ ...pagination, current: page, pageSize });
            },
            onShowSizeChange: (current, size) => {
              setPagination({ ...pagination, current: 1, pageSize: size });
            },
          }}
        />
      )}
    </Flex>
  );
};

export default LeadsTable;
