import { Card, Divider, Flex, Select, Button, Row, Col, Typography, Spin } from 'antd';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import React, { useEffect, useState } from 'react';
import { AreaChartOutlined, SearchOutlined } from "@ant-design/icons";
import { useMediaQuery } from 'react-responsive';
import { catchError } from '../../utils/errorHandler';
import axiosInstance from '../../services/axiosInstance';
import axios from 'axios';

const { Title } = Typography

const allMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const currentYear = new Date().getFullYear();
const years = Array.from({ length: currentYear - 2010 + 1 }, (_, i) => currentYear - i);
const currentMonthIndex = new Date().getMonth();


const SubscriberAnalytics = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(allMonths[currentMonthIndex]);
  const [getCenterData, setCenterData] = useState()
  const centerData = getCenterData?.location_wise_data
  const [loading, setLoading] = useState(false)

  const getAvailableMonths = () => {
    const currentMonth = new Date().getMonth();
    return selectedYear === currentYear ? allMonths.slice(0, currentMonth + 1) : allMonths;
  };

  const handleYearChange = (year) => {
    setSelectedYear(year);
    if (year == currentYear) {
      setSelectedMonth(allMonths[currentMonthIndex]);
    }
  };

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const monthNumber = allMonths.indexOf(selectedMonth) + 1;
      const res = await axiosInstance.get(`analytics/subscriber-analytics/?month=${monthNumber}&year=${selectedYear}`)

      if (res.status && res.data.status == "success") {
        setCenterData(res?.data?.data)
        setLoading(false)
      }
    } catch (err) {
      await catchError(err)
      setLoading(false)
    }
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { location, percentage } = payload[0].payload;
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            background: "#f3f4f6",
            width: "100%", padding: "10px",
            borderRadius: "10px",
            border: "1px solid #9ca3af",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            gap: "10px",
          }}>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Location: </span>
            <strong>{location}</strong>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Percentage: </span>
            <strong style={{ color: "#36c6d3" }}>{percentage}%</strong>
          </div>
        </div>
      );
    }

    return null;
  };

  const stats = [
    { label: `Order Queues`, value: getCenterData?.total_task_queues },
    { label: 'Total Assigned Orders', value: getCenterData?.total_assigned_tasks },
    { label: 'Total Unassigned Orders', value: getCenterData?.total_unassigned_tasks }
  ];

  const cardStyle = {
    background: "white",
    padding: 12,
    borderRadius: 5,
    textAlign: 'left'
  };

  const getData = async () => {
    const monthNumber = allMonths.indexOf(selectedMonth) + 1;
    setLoading(true)
    try {
      const res = await axiosInstance.get(`analytics/subscriber-analytics/?month=${monthNumber}&year=${selectedYear}`)

      if (res.status && res.data.status == "success") {
        setCenterData(res?.data?.data)
        setLoading(false)
      }
    } catch (err) {
      await catchError(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])


  if (loading) {
    return <div style={{ margin: "50px" }}> <Spin size="large" /></div>
  }

  return (
    <Flex style={{ height: '100%', width: '100%', padding: 10 }} vertical>

      <Flex style={{ width: "100%", flexDirection: isMobile ? 'column' : 'row', alignItems: "center", marginBottom: "20px", justifyContent: "space-between" }}
        gap={20}>
        <Flex style={{ flexDirection: isMobile ? 'column' : 'row', width: "100%" }} gap={10}>
          {stats?.map((stat, index) => (
            <Flex
              key={index}
              justify={"center"}
              align="center"
              style={cardStyle}
            >
              <Title level={5} style={{ margin: 0, padding: 0 }}>
                <span style={{ fontWeight: "normal" }}>{stat.label} : </span>
                <span style={{ fontWeight: "semibold" }}>{stat.value}</span>
              </Title>
            </Flex>
          ))}
        </Flex>

        <Flex gap={10}>
          <Select
            style={{ textAlign: "left", width: 150 }}
            placeholder="Select Year"
            options={years.map(y => ({ value: y, label: y }))}
            onChange={handleYearChange}
            value={selectedYear}
          />
          <Select
            style={{ textAlign: "left", width: 150 }}
            placeholder="Select Month"
            options={getAvailableMonths().map(m => ({ value: m, label: m }))}
            value={selectedMonth}
            onChange={setSelectedMonth}
          />
          <Button
            style={{
              backgroundColor: "#36c6d3",
              width: "auto",
              padding: "10px"
            }}
            onClick={handleSubmit} type="primary"
            icon={<SearchOutlined />}
            size="medium">
          </Button>
        </Flex>

      </Flex>


      <Card style={{ marginBottom: "20px" }} className="p-4">
        {isMobile ?
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={centerData} layout="vertical">
              <YAxis dataKey="location" type="category" />
              <XAxis type="number" />
              <Tooltip content={<CustomTooltip />} />
              <Bar dataKey="percentage" fill="#36C3D3" />
            </BarChart>
          </ResponsiveContainer>

          :
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={centerData} barSize={30}>
              <XAxis dataKey="location" />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Bar dataKey="percentage" fill="#36C3D3" />
            </BarChart>
          </ResponsiveContainer>
        }

      </Card>

      <div>
        <h3 style={{ textAlign: "left", marginTop: "0px", fontSize: "22px", fontWeight: "600", marginLeft: "10px" }}>
          Location Wise Order Allocation
        </h3>

        <Row gutter={[24, 24]} justify="start" style={{ flexDirection: "row", flexWrap: "wrap" }}>
          {centerData?.map((center, index) => (
            <Col key={index} xs={24} sm={12} md={8} lg={6}>
              <Card
                bordered={false}
                style={{
                  textAlign: "left",
                  borderRadius: "8px",
                  transition: "transform 0.3s ease-in-out",
                }}
              >
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "10px", fontSize: "28px", fontWeight: "bold", color: "#36C3D3" }}>
                  {center.percentage}%
                  <AreaChartOutlined style={{ color: '#36C3D3' }} />
                </div>

                <div style={{ color: "#666", fontSize: "18px", fontWeight: "500", marginBottom: "15px" }}>
                  {center.location}
                </div>

                <div style={{ color: "#666", fontSize: "14px", fontWeight: "500" }}>
                  <span style={{ fontWeight: "normal" }}>TOTAL WORD COUNT:</span>{" "}
                  <span style={{ fontWeight: "bold" }}>{center?.word_sum}</span>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </div>

      <Divider />

    </Flex >
  );
};

export default SubscriberAnalytics;