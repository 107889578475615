import React, { useState, useEffect } from "react";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Typography, Steps, Popover, Tag, Tooltip } from "antd";
import orderService from "../../services/OrderLists";
import { useParams } from "react-router-dom";
import moment from "moment";
import usePermissions from "../../hooks/usePermissions";
const { Title } = Typography;

function FixedOrderDetails({ orderData }) {
  const [statusHistory, setStatusHistory] = useState([]);
  const [currentStatus, setCurrentStatus] = useState({});
  const [timeInCurrentStatus, setTimeInCurrentStatus] = useState("");
  const { orderKey } = useParams();
  const { hasPermission } = usePermissions();
  useEffect(() => {
    let intervalId;

    const fetchAndSetStatus = async () => {
      if (!orderKey) return;

      try {
        const response = await orderService.getStatusLogs(orderKey);
        if (response.success) {
          const historyData = Array.isArray(response.data.data)
            ? response.data.data
            : [];
          setStatusHistory(historyData);

          const latestStatusIndex = historyData.reduce(
            (latestIndex, item, index) =>
              item.history_date ? index : latestIndex,
            -1
          );

          const newCurrentStatus =
            latestStatusIndex !== -1 ? historyData[latestStatusIndex] : {};

          setCurrentStatus(newCurrentStatus);

          if (newCurrentStatus?.history_date) {
            const updateTime = () => {
              const timePassed = calculateTimeInCurrentStatus(
                newCurrentStatus.history_date
              );
              setTimeInCurrentStatus(timePassed);
            };

            updateTime();
            intervalId = setInterval(updateTime, 60000);
          }
        }
      } catch (error) {
        console.error("Error fetching status logs:", error);
        setStatusHistory([]);
        setCurrentStatus({});
      }
    };

    fetchAndSetStatus();

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [orderKey]);

  const calculateTimeInCurrentStatus = (statusDate) => {
    if (!statusDate) return "N/A";

    const now = moment();
    const lastStatusTime = moment(statusDate);
    const duration = moment.duration(now.diff(lastStatusTime));

    const hours = duration.hours();
    const minutes = duration.minutes();
    return `${hours > 0 ? `${hours} hr` : ""} ${
      minutes > 0 ? `${minutes} min` : "just now"
    }`;
  };

  const statusColors = {
    Unassigned: "gray",
    "Assigned to manager": "blue",
    "In Progress": "orange",
    "Quality Check": "purple",
    Completed: "cyan",
    Delivered: "green",
    Feedback: "blue",
    Rejected: "red",
    Cancelled: "gray",
    Failed: "gray",
  };

  const paymentColors = {
    Full: "green",
    Partial: "yellow",
    Unpaid: "red",
  };

  const latestStatusIndex = statusHistory.reduce(
    (latestIndex, item, index) => (item.history_date ? index : latestIndex),
    -1
  );

  const items = statusHistory.map((item) => ({
    title: (
      <Tooltip
        title={
          item.history_date
            ? `Date: ${moment(item.history_date)
                .local()
                .format("YYYY-MM-DD hh:mm A")}`
            : ""
        }>
        <div style={{ fontSize: "14px" }}>
          {item.status}
          <p style={{ color: "gray", margin: 0, fontSize: "12px" }}>
            {item.history_date
              ? moment(item.history_date).local().format("YYYY-MM-DD hh:mm A")
              : "No date available"}
          </p>
        </div>
      </Tooltip>
    ),
    status: item.history_date ? "finish" : "wait",
    icon: item.history_date ? (
      <CheckCircleOutlined style={{ color: "#00C000" }} />
    ) : null,
    color: item.history_date ? "green" : "gray",
  }));

  const content = (
    <div style={{ maxHeight: "300px", overflowY: "auto" }}>
      <Steps
        size="small"
        direction="vertical"
        current={latestStatusIndex}
        items={items}
      />
    </div>
  );

  return (
    <div style={{ padding: "10px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          marginBottom: "15px",
        }}>
        <Title
          style={{
            color: "#001529",
            fontSize: "clamp(12px, 3vw, 25px)",
            margin: "0 0 0px 0px",
          }}
          level={2}>
          {orderData?.data?.title?.slice(0, 50)}
        </Title>
        <div style={{ fontSize: "16px", color: "black", fontWeight: "500" }}>
          Been this stage for{" "}
          <span style={{ color: "#001529", fontWeight: "700" }}>
            {timeInCurrentStatus}
          </span>
        </div>
      </div>
      <div className="fixed-container">
        <Popover content={content} title="Order Status">
          <Tag color={statusColors[currentStatus.status] || "default"}>
            {orderData?.data?.status}
          </Tag>
        </Popover>
        {hasPermission("can_view_order_payment_details") && (
          <Tooltip
            placement="bottom"
            title={
              hasPermission("can_view_order_payment_details") &&
              orderData?.data?.payment?.updated_at
                ? moment(orderData.data.payment.updated_at)
                    .local()
                    .format("YYYY-MM-DD hh:mm A")
                : "No update available"
            }>
            <Tag
              color={
                paymentColors[orderData?.data?.payment?.payment_status] ||
                "default"
              }>
              {orderData?.data?.payment?.payment_status || "Not Updated"}
            </Tag>
          </Tooltip>
        )}
      </div>
    </div>
  );
}

export default FixedOrderDetails;
