// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-width: 200px; /* Set max-width to ensure text does not overflow */
  }
  
  .mainParent{
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: flex-end;
    gap:1em;
    padding: 0px 16px;
  }
  .full-width-link {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  .urgency-red {
    background-color: rgba(255, 0, 0, 0.1); /* Light red */
  }
  .urgency-orange {
    background-color: rgba(255, 165, 0, 0.1); /* Light orange */
  }
  .urgency-yellow {
    background-color: rgba(255, 255, 0, 0.1); /* Light yellow */
  }
  .urgency-green {
    background-color: rgba(0, 255, 0, 0.1); /* Light green */
  }`, "",{"version":3,"sources":["webpack://./src/page-components/Orders/tasks.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,uBAAuB;IACvB,cAAc;IACd,gBAAgB,EAAE,mDAAmD;EACvE;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,oBAAoB;IACpB,qBAAqB;IACrB,OAAO;IACP,iBAAiB;EACnB;EACA;IACE,aAAa;IACb,2BAA2B;IAC3B,uBAAuB;IACvB,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,sBAAsB;EACxB;EACA;IACE,sCAAsC,EAAE,cAAc;EACxD;EACA;IACE,wCAAwC,EAAE,iBAAiB;EAC7D;EACA;IACE,wCAAwC,EAAE,iBAAiB;EAC7D;EACA;IACE,sCAAsC,EAAE,gBAAgB;EAC1D","sourcesContent":[".ellipsis {\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    display: block;\n    max-width: 200px; /* Set max-width to ensure text does not overflow */\n  }\n  \n  .mainParent{\n    display: flex;\n    flex-direction: column;\n    justify-content: end;\n    align-items: flex-end;\n    gap:1em;\n    padding: 0px 16px;\n  }\n  .full-width-link {\n    display: flex;\n    justify-content: flex-start;\n    align-items: flex-start;\n    width: 100%;\n    height: 100%;\n    padding: 8px;\n    box-sizing: border-box;\n  }\n  .urgency-red {\n    background-color: rgba(255, 0, 0, 0.1); /* Light red */\n  }\n  .urgency-orange {\n    background-color: rgba(255, 165, 0, 0.1); /* Light orange */\n  }\n  .urgency-yellow {\n    background-color: rgba(255, 255, 0, 0.1); /* Light yellow */\n  }\n  .urgency-green {\n    background-color: rgba(0, 255, 0, 0.1); /* Light green */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
