import React, { useEffect, useState } from 'react';
import { Form, Input, Button, DatePicker, Select, Spin, message, Row, Col } from 'antd';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../services/axiosInstance';
import dayjs from 'dayjs';
import { catchError } from '../../utils/errorHandler';

const { Option } = Select;

const ApplyBidComp = ({ handleModalClose, fetchOrderDetails }) => {
  const [form] = Form.useForm();
  const { orderKey } = useParams();
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [currencyData, setCurrencyData] = useState([]);

  const fetchCurrencies = async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get('api/v2/currency/all/');
      if (res?.data?.status === "success") {
        setCurrencyData(res?.data?.data?.currency);
      }
      setLoading(false);
    } catch (err) {
      console.error("Error fetching currencies:", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCurrencies();
  }, []);

  const handleSubmit = async (values) => {
    setSubmitLoading(true);
    const formattedTime = values.required_time ? dayjs(values.required_time).toISOString() : null;
    const payload = {
      budget: values.budget,
      currency: values.currency,
      required_time: formattedTime,
    }

    try {
      setLoading(true);

      const response = await axiosInstance.post(`/task/v2/apply-bid/${orderKey}/`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.data.status == 'success') {
        message.success(response.data.data)
        form.resetFields();
        fetchOrderDetails()
        handleModalClose()
      }
    } catch (err) {
      await catchError(err)
    } finally {
      setLoading(false);
      setSubmitLoading(false);
    }
  };

  return (
    <div>
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Row style={{
            flexDirection: window.innerWidth < 768 ? "column" : "row",
            width: "100%",
          }}>
            <Col order={1} lg={24}>
              <Form.Item
                style={{ width: "100%" }}
                name="budget"
                label="Budget"
                rules={[{ required: true, message: 'Please enter your budget' }]}
              >
                <Input
                  type="number"
                  placeholder="Enter budget"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>

            <Col order={2} lg={24}>
              <Form.Item
                style={{ width: "100%" }}
                name="currency"
                label="Currency"
                rules={[{ required: true, message: 'Please select a currency' }]}
              >
                <Select placeholder="Select a currency" style={{ width: '100%' }}>
                  {currencyData.length > 0 ? (
                    currencyData.map((currency) => (
                      <Option key={currency} value={currency}>
                        {currency}
                      </Option>
                    ))
                  ) : (
                    <Option value="" disabled>
                      No currencies available
                    </Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col order={3} lg={24}>
              <Form.Item
                name="required_time"
                label="Required Time"
                rules={[{ required: true, message: 'Please select the required time' }]}
              >

                <DatePicker
                  style={{ width: window.innerWidth < 500 ? "375px" : "100%" }}
                  showTime={{
                    format: "HH:mm",
                    use12Hours: true
                  }}
                  format="YYYY-MM-DD hh:mm A"
                />

              </Form.Item>
            </Col>
          </Row>


          <Form.Item style={{ width: "100%", display: "flex", justifyContent: "end" }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={submitLoading}
              block
            >
              Submit Bid
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default ApplyBidComp;
