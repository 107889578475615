import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  Upload,
  Flex,
  Typography,
  Col,
  Row,
  message,
  Spin,
} from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import countryList from "react-select-country-list";
import { UploadOutlined } from "@ant-design/icons";
import { CloseOutlined } from "@ant-design/icons";
import "./Modal.css";
import LeadsList from "../../services/LeadsList";

const { Option } = Select;

const CreateLeadForm = ({ isVisible, onClose, fetchData }) => {
  const [form] = Form.useForm();
  const [phone, setPhone] = useState("");
  const [fileList, setFileList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [priorityOptions, setPriorityOptions] = useState([]);
  const [levelOptions, setLevelOptions] = useState([]);
  const [sourceOptions, setSourceOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]); // Initialize state for countries
  const [isLoading, setIsLoading] = useState(false);
  const { Title } = Typography;
  // Options for dropdowns
  const typeOptions = [
    { key: "individual", label: "Individual" },
    { key: "group", label: "Group" },
  ];

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    const { social_links, ...otherValues } = values;

    const socialLinksArray = Object.entries(social_links || {}).map(
      ([platform, url]) => ({
        platform, // Key, e.g., 'linkedin'
        url, // Value, e.g., 'https://www.linkedin.com/in/johndoe'
      })
    );

    const payload = {
      ...otherValues,
      phone_no: phone,
      lead_tags: values.lead_tags ? values.lead_tags.split(",") : [],
      social_links: socialLinksArray, // Attach the social links as an array
    };

    try {
      const response = await LeadsList.createNewLead(payload);
      if (response?.success) {
        message.success("Lead created successfully");
        setIsSubmitting(false);
        await fetchData();
        form.resetFields();
        setPhone("");
        setFileList([]);
        onClose();
      }
    } catch (error) {
      setIsSubmitting(false);
      console.error("Failed to create lead:", error);
    }
  };

  const fetchDropdownData = async () => {
    setIsLoading(true); // Set loading to true when fetching dropdown data
    try {
      const priorityResponse = await LeadsList.getPriortiesList();
      const levelResponse = await LeadsList.getLeadLevelDropdownList();
      const sourceResponse = await LeadsList.getLeadSourceDropdownList();
      const countryLists = await LeadsList.getCountryList();

      if (priorityResponse.success) {
        const priorities = priorityResponse.data.data.data.map((item) => ({
          value: item.key,
          label: item.label,
        }));
        setPriorityOptions(priorities);
      }

      if (levelResponse.success) {
        const levels = levelResponse.data.data.data.map((item) => ({
          value: item.key,
          label: item.label,
        }));
        setLevelOptions(levels);
      }

      if (sourceResponse.success) {
        const sources = sourceResponse.data.data.map((item) => ({
          value: item.key,
          label: item.label,
        }));
        setSourceOptions(sources);
      }

      if (countryLists.success) {
        const countryOptions = countryLists.data.data.map((item) => ({
          value: item.code,
          label: item.name,
        }));
        setCountryOptions(countryOptions);
      }
    } catch (error) {
      console.error("Failed to fetch dropdown data:", error);
      message.error("An error occurred while fetching dropdown data.");
    } finally {
      setIsLoading(false); // Set loading to false after fetching data
    }
  };

  useEffect(() => {
    if (isVisible) {
      fetchDropdownData();
    }
  }, [isVisible]);

  return (
    <Modal
      className="custom-modal2"
      title={
        <Flex justify="space-between" className="custom-modal-header">
          <Title
            level={3}
            style={{
              color: "white",
              margin: 0,
              position: "relative",
              marginLeft: "10px",
            }}>
            Create New Lead
          </Title>
          {/* <CloseOutlined style={{ fontSize: "16px", marginRight: "10px" }} /> */}
        </Flex>
      }
      visible={isVisible}
      onCancel={onClose}
      showClose={false}
      footer={null}
      // style={{ maxHeight: 500, overflowY: "scroll" }}
      width={800}
      height={500}
      destroyOnClose>
      {isLoading ? (
        <Flex style={{ width: "100%" }} justify="center" align="center">
          <Spin size="lg" />
        </Flex>
      ) : (
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          style={{
            width: "100%",
            overflowY: "scroll",
            maxHeight: "500px",
            display: "flex",
            flexDirection: "column",
            overflowX: "hidden",
            padding: "0 10px",
          }}>
          <Row gutter={16}>
            <Col sm={24} md={12}>
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: "Please enter the name" }]}>
                <Input placeholder="Enter name" />
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please enter a valid email",
                  },
                ]}>
                <Input placeholder="Enter email" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={24} md={12}>
              <Form.Item label="Phone Number" name="phone_no" required={true}>
                <PhoneInput
                  country={"us"}
                  value={phone}
                  onChange={setPhone}
                  inputStyle={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <Form.Item label="Country" name="country">
                <Select
                  showSearch
                  placeholder="Select country"
                  filterOption={(input, option) =>
                    (option?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }>
                  {countryOptions.map((country) => (
                    <Option key={country.value} value={country.value}>
                      {country.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {/* <Row gutter={16}>
            <Col sm={24} md={12}>
              <Form.Item label="College" name="college">
                <Input placeholder="Enter college name" />
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <Form.Item label="Priority" name="priority">
                <Select placeholder="Select priority">
                  {priorityOptions.map((priority) => (
                    <Option key={priority.value} value={priority.value}>
                      {priority.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row> */}

          {/* <Col sm={24} md={12}>
              <Form.Item label="Level" name="level">
                <Select placeholder="Select level">
                  {levelOptions.map((level) => (
                    <Option key={level.value} value={level.value}>
                      {level.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col> */}

          <Row gutter={16}>
            <Col sm={24} md={12}>
              <Form.Item label="Type" name="type">
                <Select placeholder="Select type">
                  {typeOptions.map((type) => (
                    <Option key={type.key} value={type.key}>
                      {type.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <Form.Item label="Source" name="source">
                <Select placeholder="Select source">
                  {sourceOptions.map((source) => (
                    <Option key={source.value} value={source.value}>
                      {source.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {/* <Col sm={24} md={12}>
              <Form.Item label="Website" name="website">
                <Input placeholder="Enter website URL" />
              </Form.Item>
            </Col> */}
          </Row>
          {/* <Row gutter={16}>
            <Col sm={24} md={12}>
              <Form.Item label="Additional Info" name="additional_info">
                <Input.TextArea placeholder="Enter additional info" rows={8} />
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <Form.Item label="Details" name="details">
                <Input.TextArea placeholder="Enter detailed notes" rows={8} />
              </Form.Item>
            </Col>
          </Row> */}
          <Row gutter={16}>
            <Col sm={24} md={24}>
              <Form.Item label="Lead Tags" name="lead_tags">
                <Input placeholder="Enter lead tags (comma separated)" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={24} md={12}>
              <Form.Item label="LinkedIn" name={["social_links", "linkedin"]}>
                <Input placeholder="Enter LinkedIn profile URL" />
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <Form.Item label="Facebook" name={["social_links", "facebook"]}>
                <Input placeholder="Enter Facebook profile URL" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={24} md={12}>
              <Form.Item label="Twitter" name={["social_links", "twitter"]}>
                <Input placeholder="Enter Twitter profile URL" />
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <Form.Item label="Instagram" name={["social_links", "instagram"]}>
                <Input placeholder="Enter Instagram profile URL" />
              </Form.Item>
            </Col>
          </Row>
          <Flex justify="flex-end" align="flex-end" style={{ width: "100%" }}>
            <Form.Item>
              <Button
                loading={isSubmitting}
                type="primary"
                htmlType="submit"
                block>
                Create Lead
              </Button>
            </Form.Item>
          </Flex>
        </Form>
      )}
    </Modal>
  );
};

export default CreateLeadForm;
