import React, { useState } from "react";

import {
  Table,
  Popover,
  Button,
  Avatar,
  Tag,
  Tooltip,
  Modal,
  Form,
  Input,
  Radio,
  message,
  Flex,
  Typography,
} from "antd";
import paymentOperationsService from "../../services/paymentOperationsService";
import { InfoCircleOutlined } from "@ant-design/icons";

import {
  CloseCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import MobileViewRefunds from "./MobileViewRefunds";
import { useMediaQuery } from "react-responsive";
import SubscriberDeductionForm from "./SubscriberDeductionForm";
import moment from "moment";
import usePermissions from "../../hooks/usePermissions";
import { Link } from "react-router-dom";
const RefundsTable = ({
  refunds,
  loading,
  pagination,
  setPagination,
  dropdownValue,
  fetchData,
}) => {
  const { hasPermission } = usePermissions();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [subscriberDeductionModal, setSubscriberDeductionModal] =
    useState(false);
    const [submitiing,setSubmitting] = useState(false)
  const [showForm, setShowForm] = useState(false);
  const [deductionAmounts, setDeductionAmounts] = useState({});
  const [selectedOrder, setSelectedOrder] = useState("");
  const [applyFlDeductionModal, setApplyFlModalDeduction] = useState(false);
  const [modalData, setModalData] = useState([]); // Store data for modal
  const [selectedRowKeys, setSelectedRowKeys] = useState([]); // State for selected rows
  const [isFlDetailsLoading, setIsFlDetailsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [orderKey, setOrderKey] = useState("");
  const [refundFlDeduction, setRefundFlDeduction] = useState({
    amount: null,
    uids: selectedRowKeys,
  });
  const showDeleteConfirmationModal = (record) => {
    Modal.confirm({
      title: `Are you sure you want to delete refund for order ${record?.order_id}?`,
      content: `This action will permanently delete the record.`,
      okText: "Yes",
      cancelText: "No",
      onOk: () => handleDelete(record),
      okButtonProps: {
        style: {
          backgroundColor: "#001529", // Change the color of the OK button (red)
          borderColor: "#001529", // Make the border color match
          color: "white", // Change the text color to white
        },
      },
      cancelButtonProps: {
        style: {
          color: "#1890ff", // Blue color for the cancel button
          borderColor: "#1890ff",
        },
      },
    });
  };
  const handleDeductionChange = (uuid, value) => {
    setDeductionAmounts({
      ...deductionAmounts,
      [uuid]: value,
    });
  };
  const handleSubmit = async () => {
    if (!selectedRowKeys.length) {
      message.warning("Please select at least one row to submit deductions!");
      return;
    }

    const payload = {
      deductions: selectedRowKeys.map((uid) => ({
        uid,
        amount: deductionAmounts[uid] || 0, // Default to 0 if no amount is entered
      })),
    };

    // Prepare the payload

    try {
      setIsLoading(true);
      const response = await paymentOperationsService.postFlDeductionData(
        selectedRecord.uid,
        payload
      );

      if (response.success) {
        message.success("Deductions submitted successfully!");
        setSelectedRowKeys([]);
        setDeductionAmounts({});
      } else {
        message.error("Failed to submit deductions.");
      }
    } catch (error) {
      console.error("Error submitting deductions:", error);
      message.error("An error occurred while submitting deductions.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (record) => {
    try {
      const response = await paymentOperationsService.deleteRefund(record.uid);
      if (response.success) {
        message.success(
          `Refund for order ${record.order_id} deleted successfully.`
        );
        await fetchData();
      } else {
        message.error("Something went wrong while deleting the refund.");
      }
    } catch (error) {
      console.error("Delete failed:", error);
      message.error("Delete failed. Please try again.");
    }
  };
  // Define how specific fields should be rendered
  const getInitials = (name) => {
    const nameArray = name.split(" ");
    const initials = nameArray
      .map((word) => word.charAt(0).toUpperCase())
      .join("");
    return initials;
  };

  const renderers = {
    key: (key, record) => (
      <div>
        {record.source === "Order" ? (
          <Link to={`/order-management/my-orders/${key}`}>{key}</Link>
        ) : (
          <span>{key}</span>
        )}

        <br />
        <span style={{ color: "gray", fontSize: "0.9em" }}>
          {record?.date
            ? moment(record.date).local().format("DD-MM-YYYY hh:mm a")
            : null}
        </span>
      </div>
    ),
    refund_amount: (amount, record) => (
      <div>
        <div>
     
          {record.currency} {amount}
        </div>
      </div>
    ),
    source: (amount, record) => (
      <div>
        {record.source === "Transaction" ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span>{record.source}</span>
            <Tooltip title={record.payment_source}>
              <InfoCircleOutlined
                style={{
                  marginLeft: "8px",
                  cursor: "pointer",
                  color: "orange",
                }}
              />
            </Tooltip>
          </div>
        ) : (
          <span>{record.source}</span>
        )}
      </div>
    ),

    refund_mode: (amount, record) => (
      <div>
        {record.refund_mode && (
          <Tag color="green" style={{ marginTop: "4px" }}>
            {" "}
            {record.refund_mode}{" "}
          </Tag>
        )}
      </div>
    ),
    move_to_fl_task: (move_to_fl_task) => {
      if (move_to_fl_task === null) {
        return "N/A";
      }
      return move_to_fl_task ? "YES" : "NO";
    },

    payment_modes: (payment_modes) =>
      payment_modes ? (
        payment_modes.map((mode, index) => (
          <Tag color="blue" key={index} style={{ marginTop: 4 }}>
            {mode.trim()}
          </Tag>
        ))
      ) : (
        <Tag color="red">No Modes</Tag>
      ),
    status: (status, record) => (
      <div>
        <span
          style={{
            color:
              status === "Accepted"
                ? "green"
                : status === "Pending"
                ? "orange"
                : "red",
          }}>
          {status}
        </span>

        {/* Conditional rendering based on dropdown value */}
        {dropdownValue === "allRefunds" ? (
          <div style={{ fontSize: "0.8em", color: "gray", marginTop: "4px" }}>
            {record.action_date
              ? moment(record.action_date).local().format("DD-MM-YYYY hh-mm a")
              : "No action taken"}
          </div>
        ) : record.admin_action_date ? (
          <div style={{ fontSize: "0.8em", color: "gray", marginTop: "4px" }}>
            {moment(record.admin_action_date)
              .local()
              .format("DD-MM-YYYY hh-mm a")}
          </div>
        ) : (
          <div style={{ fontSize: "0.8em", color: "gray", marginTop: "4px" }}>
            No action taken
          </div>
        )}
      </div>
    ),

    bdm_reason: (reason) => (
      <Popover
        content={
          <div
            style={{
              maxWidth: 600,
              maxHeight: 300, // Set a maximum height to enable scrolling
              whiteSpace: "pre-wrap",
              overflowY: "scroll",
            }}>
            {reason}
          </div>
        }
        title="BDM Reason">
        {reason ? <a href="#">View</a> : "N/A"}
      </Popover>
    ),
    my_reason: (reason) => (
      <Popover
        content={
          <div
            style={{
              maxWidth: 600,
              maxHeight: 300, // Set a maximum height to enable scrolling
              whiteSpace: "pre-wrap",
              overflowY: "scroll",
            }}>
            {reason}
          </div>
        }
        title="My Reason">
        {reason ? <a href="#">View</a> : "N/A"}
      </Popover>
    ),
    feedback: (feedback) => (
      <Popover
        content={
          <div
            style={{
              maxWidth: 600,
              maxHeight: 300, // Set a maximum height to enable scrolling
              whiteSpace: "pre-wrap",
              overflowY: "scroll",
            }}>
            {feedback}
          </div>
        }
        title="Feedback">
        {feedback ? <a href="#">View</a> : "N/A"}
      </Popover>
    ),
    receipt: (receipt) =>
      receipt ? (
        <Popover
          content={
            <a href={receipt} target="_blank" rel="noopener noreferrer">
              {receipt}
            </a>
          }
          title="Receipt">
          <Button style={{ padding: 0 }} type="link">
            View Receipt
          </Button>
        </Popover>
      ) : (
        "N/A"
      ),
    user_type: (userType, record) => (
      <Popover
        content={
          <div>
            <strong>{record.user_type}</strong>
          </div>
        }
        trigger="hover">
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar style={{ backgroundColor: "#87d068" }}>
            {getInitials(record.user_type)}
          </Avatar>
        </div>
      </Popover>
    ),
    applied_by: (userType, record) => (
      <Popover
        content={
          <div>
            <strong>{record.applied_by}</strong>
          </div>
        }
        trigger="hover">
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar style={{ backgroundColor: "#87d068" }}>
            {getInitials(record.applied_by)}
          </Avatar>
        </div>
      </Popover>
    ),
    task_author_name: (userType, record) => (
      <Popover
        content={
          <div>
            <strong>{record.task_author_name}</strong>
          </div>
        }
        trigger="hover">
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar style={{ backgroundColor: "#87d068" }}>
            {getInitials(record.task_author_name)}
          </Avatar>
        </div>
      </Popover>
    ),
  };

  const columns =
    refunds.length > 0
      ? [
          ...Object.keys(refunds[0])
            .filter(
              (key) =>
                key !== "date" &&
                key !== "admin_action_date" &&
                key !== "currency" &&
                key !== "action_date" &&
                key !== "uid" &&
                key !== "payment_source"
            )
            .map((key) => ({
              title:
                key === "status"
                  ? `Status / Action Date` // For 'status' key, display as 'STATUS / ACTION DATE'
                  : key === "key"
                  ? `${key
                      .replace(/_/g, " ") // Convert snake_case to readable text
                      .replace(/\b\w/g, (c) => c.toUpperCase())} / Date` // For 'key', append '/ Date'
                  : key
                      .replace(/_/g, " ") // Convert snake_case to readable text
                      .replace(/\b\w/g, (c) => c.toUpperCase()), // For other keys, capitalize each word
              dataIndex: key,
              key,
              render: renderers[key] || ((text) => <span>{text}</span>), // Use custom renderers if available
              width: 100, // Adjust the width of columns as needed
            })),

          ...(dropdownValue === "allRefunds"
            ? [
                {
                  title: "Apply FL Deduction",
                  key: "apply_fl_deduction",
                  render: (text, record) => (
                    <Button
                      type="link"
                      style={{
                        opacity:
                          record.move_to_fl_task ||
                          record?.source === "Transaction"
                            ? 1
                            : 0.5,
                      }}
                      disabled={
                        !record.move_to_fl_task ||
                        record?.source === "Transaction"
                      }
                      onClick={() => showFlDeductionModal(record)}>
                      Apply
                    </Button>
                  ),
                  // fixed: "right", // Fix the column to the right
                  // width: 180, // Adjust the width as needed
                },
                {
                  title: "Subscriber Deduction",
                  key: "subscriber_deduction",
                  render: (text, record) => (
                    <Button
                      style={{
                        opacity: record?.source === "Transaction" ? 0.5 : 1,
                      }}
                      disabled={record?.source === "Transaction"}
                      type="link"
                      onClick={() => showSubscriberDeductionModal(record)}>
                      View
                    </Button>
                  ),
                  // fixed: "right", // Fix the column to the right
                  // width: 180, // Adjust the width as needed
                },
                ...(hasPermission("can_accept_reject_refund")
                  ? [
                      {
                        title: "Action",
                        key: "action",
                        render: (text, record) =>
                          record.status === "Pending" ? ( // Check if status is "Pending"
                            <div style={{ display: "flex", gap: "10px" }}>
                              <Tooltip title="Approve">
                                <Button
                                  type="primary"
                                  icon={<CheckCircleOutlined />}
                                  shape="circle"
                                  onClick={() => {
                                    setModalType("approve");
                                    showModal(record, "approve");
                                  }}
                                  style={{ background: "#22c55e" }} // Green background
                                />
                              </Tooltip>
                              <Tooltip title="Reject">
                                <Button
                                  type="danger"
                                  icon={<CloseCircleOutlined />}
                                  shape="circle"
                                  onClick={() => {
                                    setModalType("reject");
                                    showModal(record, "reject")
                                  }}
                                />
                              </Tooltip>
                            </div>
                          ) : (
                            "Action Taken"
                          ), // Return null for rows where status is not "Pending"
                        fixed: "right", // Fix the column to the right
                        width: 100,
                      },
                    ]
                  : []),

                // ...(hasPermission("can_accept_reject_refund")
                //   ? [
                //       {
                //         title: "Action",
                //         key: "action",
                //         render: (text, record) => (
                //           <div style={{ display: "flex", gap: "10px" }}>
                //             <Tooltip title="Approve">
                //               <Button
                //                 type="primary"
                //                 icon={<CheckCircleOutlined />}
                //                 shape="circle"
                //                 onClick={() => showModal(record, "approve")}
                //                 style={{ background: "#22c55e" }} // Green background
                //               />
                //             </Tooltip>
                //             <Tooltip title="Reject">
                //               <Button
                //                 type="danger"
                //                 icon={<CloseCircleOutlined />}
                //                 shape="circle"
                //                 onClick={() => showModal(record, "reject")}
                //               />
                //             </Tooltip>
                //           </div>
                //         ),
                //         fixed: "right", // Fix the column to the right
                //         width: 100,
                //       },
                //     ]
                //   : []),
              ]
            : []),
          ...(dropdownValue === "myRefunds" &&
          hasPermission("can_delete_refund")
            ? [
                {
                  title: "Action",
                  key: "action",
                  render: (text, record) => (
                    <div style={{ display: "flex", gap: "10px" }}>
                      <Tooltip title="Delete">
                        <Button
                          type="danger"
                          icon={<DeleteOutlined />}
                          shape="circle"
                          onClick={() => showDeleteConfirmationModal(record)}
                          style={{
                            opacity: record?.status === "Pending" ? 1 : 0.3,
                            color: "#ff4d4f", // Faded red when disabled
                          }}
                          disabled={record?.status !== "Pending"} // Disable if status isn't "Pending"
                        />
                      </Tooltip>
                    </div>
                  ),
                  fixed: "right", // Fix the column to the right
                  width: 80,
                },
              ]
            : []),
        ]
      : [];

  // Handle table pagination

  // States for modals
  const [form] = Form.useForm();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalType, setModalType] = useState(""); // approve | reject
  const [selectedRecord, setSelectedRecord] = useState(null);

  const [showCustomText, setShowCustomText] = useState(false);

  const showFlDeductionModal = async (record) => {
    setSelectedRecord(record);
    try {
      setIsFlDetailsLoading(true);
      setApplyFlModalDeduction(true);
      const response = await paymentOperationsService.getFlDeductionData(
        record.uid
      );
      console.log(response.data, "Response data");

      if (response.success) {
        setModalData(
          Array.isArray(response.data.data) ? response.data.data : []
        ); // Access the 'data' array
        setIsFlDetailsLoading(false);
      } else {
        setIsFlDetailsLoading(false);

        message.error("Failed to load refund details.");
      }
    } catch (error) {
      setIsFlDetailsLoading(false);
      console.error("Error fetching refund details:", error);
      message.error("Error fetching refund details.");
    }
  };
  const showModal = async (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };
  const showSubscriberDeductionModal = async (record) => {
    setSelectedRecord(record);

    setSubscriberDeductionModal(true);
  };
  const handleReasonChange = (e) => {
    setShowCustomText(e.target.value === "custom");
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setShowCustomText(false);
  };
  const hideModal = async () => {
    setApplyFlModalDeduction(false);
    setDeductionAmounts({});
    setSelectedRowKeys([]);
    setModalData([]);
  };
  const hideSubscriberDeductionModal = async () => {
    setSubscriberDeductionModal(false);
  };
  // Handle row selection
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  // Define table columns
  const columnsRefund = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Amount", dataIndex: "amount", key: "amount" },
    {
      title: "Amount Deduction",
      dataIndex: "amount_deduction",
      key: "amount_deduction",
    },
    { title: "Currency", dataIndex: "currency", key: "currency" },
    {
      title: "Enter Deduction Amount",
      dataIndex: "deduction",
      key: "deduction",
      render: (_, record) => (
        <Input
          placeholder="Enter amount"
          value={deductionAmounts[record.uuid] || ""}
          onChange={(e) => handleDeductionChange(record.uuid, e.target.value)}
        />
      ),
    },
  ];
  // Handle row selection
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const handleOk = async () => {
    try {
      setSubmitting(true)
      const values = await form.validateFields();
      const payload = {
        accept: modalType === "approve", // true for approve, false for reject
        feedback: values.reason || "",
      };

      // Call the refundAction API
      const response = await paymentOperationsService.refundAction(
        selectedRecord.uid, // Pass the key or id for the record
        payload
      );

      if (response.success) {
        setSubmitting(false)
        message.success(
          modalType === "approve"
            ? `Record approved`
            : `Record rejected`
        );
        await fetchData();
      } else {
        message.error("Something went wrong. Please try again.");
      }
      setIsModalVisible(false);
      setSubmitting(false)
      form.resetFields();
    } catch (error) {
      setSubmitting(false)
      console.error("Action failed:", error);
      message.error("Action failed. Please try again.");
    }
  };
  // const handleSubmit = () => {
  //   if (!selectedRowKeys.length) {
  //     message.warning("Please select at least one row!");
  //     return;
  //   }

  //   // Pass selected uids to the form
  //   form.setFieldsValue({
  //     selectedUids: selectedRowKeys.join(", "), // Join for display
  //   });

  //   setShowForm(true);
  // };

  const handleTableChange = (pagination) => {
    setPagination((prev) => ({
      ...prev,
      current: pagination.current,
    }));
  };

  return (
    <>
      {isMobile ? (
        <MobileViewRefunds
          data={refunds}
          pagination={pagination}
          setPagination={setPagination}
          fetchData={fetchData}
          dropdownValue={dropdownValue}
          showFlDeductionModal={showFlDeductionModal}
          showSubscriberDeductionModal={showSubscriberDeductionModal}
          showDeleteConfirmationModal={showDeleteConfirmationModal}
          setSelectedRecord={setSelectedRecord}
          showModal={showModal}
        />
      ) : (
        <Table
          columns={columns}
          dataSource={refunds}
          loading={loading}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            showTotal: (total) => `Total ${total} items`,
          }}
          rowKey="order_id"
          onChange={handleTableChange}
          scroll={{ x: "max-content" }} // Enable horizontal scrolling
        />
      )}

      {/* Modal for Approve and Reject */}
      <Modal
        style={{ width: "100%" }}
        className="custom-modal"
        title={
          <Flex justify="space-between" className="custom-modal-header">
            <Typography.Title
              level={3}
              style={{
                color: "white",
                margin: 0,
                position: "relative",
                marginLeft: "10px",
              }}>
              {modalType === "approve" ? "Approve Refund" : "Reject Refund"}
            </Typography.Title>
            {/* <CloseOutlined
              style={{ fontSize: "16px", marginRight: "10px" }}
              onClick={handleModalClose}
            /> */}
          </Flex>
        }
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={null}
        footer={null}
        okButtonProps={null}>
        {modalType === "approve" ? (
          <>
    
          <p>
            Are you sure you want to approve the refund for order{" "}
            {selectedRecord?.order_id}?
          </p>
           <Flex justify="flex-end" align="flex-end" style={{ width: "100%" }}>
           <Button
             type="primary"
             onClick={handleOk}
             loading={submitiing}
             style={{ background: "#001529", marginTop: 10 }}>
             Submit
           </Button>
         </Flex>
         </>
        ) : (
          <Form form={form} layout="vertical" style={{ width: "100%" }}>
            <Form.Item
              style={{ width: "100%" }}
              label="Select Reason"
              name="reason"
              rules={[{ required: true, message: "Please select a reason" }]}>
              <Radio.Group onChange={handleReasonChange}>
                <Radio value="Refund Request Rejected">
                  Default Feedback
                  <small style={{ color: "gray" }}>
                    {" "}
                    (Refund Request Rejected)
                  </small>
                </Radio>
                <Radio value="custom">Custom</Radio>
              </Radio.Group>
            </Form.Item>
            {showCustomText && (
              <Form.Item
                style={{ width: "100%" }}
                label="Custom Reason"
                name="customReason"
                rules={[
                  { required: true, message: "Please provide a custom reason" },
                ]}>
                <Input.TextArea rows={4} placeholder="Enter custom reason..." />
              </Form.Item>
            )}
            <Flex justify="flex-end" align="flex-end" style={{ width: "100%" }}>
              <Button
                type="primary"
                onClick={handleOk}
                loading={submitiing}
                style={{ background: "#001529", marginTop: 10 }}>
                Submit
              </Button>
            </Flex>
          </Form>
        )}
      </Modal>

      <Modal
        className="custom-modal"
        title={
          <Flex justify="space-between" className="custom-modal-header">
            <Typography.Title
              level={3}
              style={{
                color: "white",
                margin: 0,
                position: "relative",
                marginLeft: "10px",
              }}>
              Apply FL Deduction
            </Typography.Title>
            {/* <CloseOutlined
              style={{ fontSize: "16px", marginRight: "10px" }}
              onClick={handleModalClose}
            /> */}
          </Flex>
        }
        width={900}
        visible={applyFlDeductionModal}
        onCancel={hideModal}
        footer={[
          <Button key="cancel" onClick={hideModal}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            style={{ color: "white" }}
            onClick={handleSubmit}
            disabled={!selectedRowKeys.length}
            loading={isLoading}>
            Submit
          </Button>,
        ]}>
        <Table
          dataSource={modalData}
          columns={columnsRefund}
          rowSelection={rowSelection}
          rowKey="uuid"
          pagination={false}
          loading={isFlDetailsLoading}
        />
      </Modal>
      <Modal
        className="custom-modal"
        // style={{maxHeight:600, overflow:'auto'}}
        title={
          <Flex justify="space-between" className="custom-modal-header">
            <Typography.Title
              level={3}
              style={{
                color: "white",
                margin: 0,
                position: "relative",
                marginLeft: "10px",
              }}>
              Subscriber Deduction
            </Typography.Title>
            {/* <CloseOutlined
              style={{ fontSize: "16px", marginRight: "10px" }}
              onClick={handleModalClose}
            /> */}
          </Flex>
        }
        width={900}
        visible={subscriberDeductionModal}
        onCancel={hideSubscriberDeductionModal}
        footer={null}>
        <SubscriberDeductionForm
          selectedRecord={selectedRecord}
          hideSubscriberDeductionModal={hideSubscriberDeductionModal}
        />
      </Modal>
    </>
  );
};

export default RefundsTable;
