import { Table } from 'antd';
import React from 'react';

const BudgetComp = ({ budget }) => {

    const budgetColumns = [
        {
            title: 'Extra Budget',
            dataIndex: 'inr_budget',
            key: 'inr_budget',
            render: (value) => `${value} INR`,
        },
        {
            title: 'Converted To (INR)',
            dataIndex: 'converted',
            key: 'converted',
            render: (converted) => (converted ? 'Yes' : 'No'),
        },
    ];

    return (
        <div style={{ marginBottom: '16px' }}>
            <Table
                columns={budgetColumns}
                dataSource={budget}
                rowKey={(record, index) => index}
                pagination={false}
                bordered
                size="small"
                scroll={{ y: 150 }}
            />
        </div>
    );
};

export default BudgetComp;
