import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const getMonthWiseData = (currencyData) => {
  const monthWiseData = [];
  const monthSet = new Set();

  Object.keys(currencyData).forEach((currency) => {
    const months = Object.keys(currencyData[currency]);
    months.forEach((month) => {
      const [monthName, year] = month.split("_"); // Extract the month name
      monthSet.add(monthName); // Add the month name to the set
      let existingMonth = monthWiseData.find(
        (item) => item.month === monthName
      );
      if (!existingMonth) {
        existingMonth = { month: monthName };
        monthWiseData.push(existingMonth);
      }
      existingMonth[currency] = parseFloat(
        currencyData[currency][month].amount
      );
    });
  });

  // Add missing months with default values
  monthSet.forEach((monthName) => {
    let existingMonth = monthWiseData.find((item) => item.month === monthName);
    if (!existingMonth) {
      existingMonth = { month: monthName };
      monthWiseData.push(existingMonth);
    }
  });

  // Sort by month order
  const monthOrder = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];
  monthWiseData.sort(
    (a, b) => monthOrder.indexOf(a.month.toLowerCase()) - monthOrder.indexOf(b.month.toLowerCase())
  );

  console.log('Processed month wise data:', monthWiseData);
  return monthWiseData;
};

export default function MultiCurrencyBarChart({ data }) {
  const [chartWidth, setChartWidth] = useState(300);

  const updateChartWidth = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 768) {
      setChartWidth(windowWidth * 0.8); // 80% width for mobile devices
    } else {
      setChartWidth(400); // Default width for larger screens
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateChartWidth);
    updateChartWidth(); // initial call
    return () => window.removeEventListener("resize", updateChartWidth);
  }, []);

  const colors = [
    "#8884d8",
    "#82ca9d",
    "#ffc658",
    "#d08484",
    "#8dd1e1",
    "#ff8042",
    "#a4de6c",
    "#d0ed57",
  ]; // Add more colors if needed

  return (
    <div>
      <Row gutter={[16, 16]}>
        {Object.keys(data).map((region, index) => (
          <Col key={index} xs={24} sm={24} md={12} lg={8} xl={12}>
            <h3>{region}</h3>
            {console.log('Region data:', data[region])}
            <BarChart
              width={chartWidth}
              height={400}
              data={getMonthWiseData(data[region])}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              {Object.keys(data[region]).map((currency, currencyIndex) => (
                <Bar
                  key={currencyIndex}
                  dataKey={currency}
                  fill={colors[currencyIndex % colors.length]}
                  name={currency}
                />
              ))}
            </BarChart>
          </Col>
        ))}
      </Row>
    </div>
  );
}

