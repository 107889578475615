import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { useParams } from "react-router-dom";
import {
  Typography,
  Button,
  Spin,
  Popover,
  Menu,
  Checkbox,
  Dropdown,
  Tag,
  Flex,
  message,
  Form,
  Select,
} from "antd";
import {
  ForwardOutlined,
  UserSwitchOutlined,
  DeleteOutlined,
  SwapOutlined,
  ForwardFilled,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Paperclip } from "lucide-react";
// import useLocalTime from "../../hooks/useLocalTime";
import usePermissions from "../../../hooks/usePermissions";
import orderService from "../../../services/OrderLists";
import { useLocalTime } from "../../../hooks/useLocalTime";
import OrderLogServivces from "../../../services/OrderLogServices";

const { Title } = Typography;

const Comment = ({
  comment,

  permissions,
  userTypes,
  handleTabChange,
  commentType,
  setOrderData,
  moveToPortal,
}) => {
  const { orderKey } = useParams();
  const localTime = useLocalTime(comment.submit_date);
  const { hasPermission } = usePermissions();
  const [isMoveToInternalModalVisible, setIsMoveToInternalModalVisible] =
    useState(false);
  const [isMoveToClientModalVisible, setIsMoveToClientModalVisible] =
    useState(false);
    const [ismoveToFlCommentModalVisible, setIsmoveToFlCommentModalVisible] =
    useState(false);
  const [selectedUserTypes, setSelectedUserTypes] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState([]);
  const [selectedCommentType, setSelectedCommentType] = useState("");
  const [commentTypes, setCommentTypes] = useState([]);
  const [isLoadingCommentTypes, setIsLoadingCommentTypes] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedUserType, setSelectedUserType] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [orderOwners, setOrderOwners] = useState([]);
  const [activeModal, setActiveModal] = useState(null); // null means no modal open

// Constants for modal types
const MODAL_TYPES = {
  INTERNAL: 'internal',
  CLIENT: 'client',
  FREELANCER: 'freelancer',
};
// Function to handle opening specific modals
const openModal = (modalType) => setActiveModal(modalType);

// Function to close the modal
const closeModal = () => setActiveModal(null);
  const handleForwardClick = async () => {
    setLoading(true);
    const payload = { user_type: selectedUserType };

    if (commentType === "internal") {
      const result = await orderService.forwardInternalComment(
        comment.uuid,
        payload
      );
      if (result.success)
        message.success("Internal comment forwarded successfully");
    } else {
      const result = await orderService.forwardInternalComment(
        comment.uuid,
        payload
      );
      if (result.success)
        message.success("Client comment forwarded successfully");
    }

    setLoading(false);
    setDropdownVisible(false);
  };

  useEffect(() => {
    const fetchCommentTypes = async () => {
      setIsLoadingCommentTypes(true);
      try {
        const response = await orderService.getCommentTypes();
        if (response.success) {
          setCommentTypes(response.data);
        }
      } catch (error) {
        console.error("Error fetching comment types:", error);
      } finally {
        setIsLoadingCommentTypes(false);
      }
    };
    const fetchOrderOwners = async () => {
      setIsLoadingCommentTypes(true);
      try {
        const response = await OrderLogServivces.getTaskOwners(orderKey);
        if (response.success) {
          console.log(response.data.data.owner, "owner");
          setOrderOwners(response?.data?.data?.owner);
        }
      } catch (error) {
        console.error("Error fetching comment types:", error);
      } finally {
        setIsLoadingCommentTypes(false);
      }
    };
    if (isMoveToInternalModalVisible) {
      fetchCommentTypes();
    }
    if (isMoveToClientModalVisible) {
      fetchOrderOwners();
    }
    if (ismoveToFlCommentModalVisible) {
      fetchOrderOwners();
    }
  }, [isMoveToInternalModalVisible, isMoveToClientModalVisible, ismoveToFlCommentModalVisible]);

  const handleMoveToFL = async () => {
    setIsDeleteLoading(true);
    try {
      const payloadTwo = {
        owner: selectedOwner,
  
      };
      if (commentType === "internal") {

        const result = await orderService.moveToFreelancerComment(comment.uuid,payloadTwo);
        setIsDeleteLoading(false);
        if (result.success) {
          message.success("Comment moved to freelancer successfully");
          handleTabChange("3");
        }
      }
    } catch (error) {
      setIsDeleteLoading(false);
    }
  };

  const handleMoveToInternalSubmit = async () => {
    if (commentType === "client") {
      if (!selectedCommentType || selectedUserTypes.length === 0) {
        message.error(
          "Please select a comment type and at least one user type."
        );
        return;
      }
    }

    setIsDeleteLoading(true);
    try {
      const payload = {
        user_type: selectedUserTypes,
        comment_type: selectedCommentType,
      };
      const payloadTwo = {
        owner: selectedOwner,
  
      };
      if (commentType === "internal") {
        const result = await orderService.moveToClientComment(comment.uuid , payloadTwo);
     
        if (result.success) {
          setIsDeleteLoading(false);
          setLoading(false);
          message.success("Comment moved to client successfully");
          handleTabChange("3");
        }
        setIsDeleteLoading(false);
      } else if (commentType === "client") {
        const result = await orderService.moveToInternalCommentClient(
          comment.uid,
          payload
        );
        if (result.success) {
          setIsDeleteLoading(false);
          message.success("Comment moved to internal successfully");
          handleTabChange("3");
          setIsMoveToInternalModalVisible(false);
        }
      }
    } catch (error) {
      console.error("Error moving comment:", error);
      message.error("Failed to move comment.");
    } finally {
      setIsDeleteLoading(false);
    }
  };


  const userTypeMenu = (
    <Menu>
      {userTypes?.map((type) => (
        <Menu.Item key={type.value}>
          <Checkbox
            onChange={(e) => {
              const { checked } = e.target;
              if (checked) {
                setSelectedUserType((prev) => [...prev, type.value]);
              } else {
                setSelectedUserType((prev) =>
                  prev.filter((val) => val !== type.value)
                );
              }
            }}>
            {type.name}
          </Checkbox>
        </Menu.Item>
      ))}
      <Menu.Item key="submit">
        <Button
          type="primary"
          onClick={handleForwardClick}
          loading={loading}
          disabled={userTypes.length === 0}>
          Submit
        </Button>
      </Menu.Item>
    </Menu>
  );

  const maxLength = 60;
  const toggleExpand = () => setIsExpanded(!isExpanded);
  const truncateText = (text) => {
    if (!text) return "";
    if (text.length <= maxLength) return text;
    return isExpanded ? text : `${text.slice(0, maxLength)}...`;
  };

  if (!comment) return null;
  const bgColor = comment?.comment_type?.is_urgent ? "#fecaca" : "#ecfdf5";
  const tagColor = comment.is_urgent
    ? "red"
    : comment.comment_type && comment.comment_type.name === "internal"
    ? "#007bff"
    : comment.comment_type && comment.comment_type.name === "client"
    ? "#00bfa5"
    : "#ff9800";
  const handleDeleteComment = async (orderKey, uuid) => {
    setIsDeleteLoading(true);
    try {
      let result;
      if (commentType === "internal") {
        result = await orderService.deleteInternalComment(orderKey, uuid);
      } else if (commentType === "client") {
        result = await orderService.deleteClientComment(orderKey, uuid);
      }

      if (result.success) {
        message.success("Comment deleted successfully");

        // Remove the deleted comment from the list
        setOrderData((prevComments) =>
          prevComments.filter((c) => c.uuid !== uuid)
        );

        setIsDeleteLoading(false);
      }
    } catch (error) {
      setIsDeleteLoading(false);
      message.error("Failed to delete comment.");
    }
  };

  // const handleDeleteComment = async (orderKey, uuid) => {
  //   setIsDeleteLoading(true);
  //   try {
  //     if (commentType === "internal") {
  //       const result = await orderService.deleteInternalComment(orderKey, uuid);
  //       if (result.success) {
  //         message.success("Comment deleted successfully");
  //         // handleTabChange("3");
  //       }
  //       setIsDeleteLoading(false);
  //     } else if (commentType === "client") {
  //       const result = await orderService.deleteClientComment(orderKey, uuid);
  //       if (result.success) {
  //         message.success("Comment deleted successfully");
  //         // handleTabChange("3");
  //       }
  //       setIsDeleteLoading(false);
  //     }
  //   } catch (error) {
  //     setIsDeleteLoading(false);
  //   }
  // };

  return isDeleteLoading ? (
    <Spin />
  ) : (
    <Flex
      vertical
      justify="flex-start"
      align="flex-start"
      gap={"4px"}
      style={{
        padding: 10,
        border: "1px solid #F5F5F5",
        marginTop: "10px",
        borderRadius: "10px",
        maxWidth: "100%",
        minWidth: "100%",
        background: bgColor,
      }}>
      <Flex
        justify="space-between"
        align="flex-start"
        style={{ width: "100%", padding: "0px", marginTop: "10px" }}>
        <Title
          level={5}
          style={{
            textAlign: "left",
            color: "black",
            margin: "0px",
            maxWidth: "70%",
          }}>
          {commentType === "internal"
            ? comment.user?.user_type &&
              `${comment.user.user_type} ${comment.user.user_id} ${
                comment.user?.name || ""
              }`
            : comment.user_type}
        </Title>
        {comment.comment_type && (
          <Tag type="primary" color={"blue"}>
            {comment.comment_type.name}
          </Tag>
        )}
      </Flex>
      {comment?.user_type.length > 0 && (
        <Typography.Text
          type="secondary"
          style={{
            margin: 0,
            padding: 0,
            textAlign: "left",
            display: "flex",
            alignItems: "flex-start",
            gap: "4px",
          }}>
          {Array.isArray(comment?.user_type) && (
            <svg
              style={{ marginTop: "6px" }}
              width="13"
              height="10"
              viewBox="0 0 13 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2.14031 4.35686C2.60382 3.75294 3.20328 3.25099 3.89665 2.8862C4.59002 2.52141 5.36051 2.30263 6.15406 2.24519V1.54519C6.15092 1.31694 6.2175 1.09259 6.34601 0.898372C6.47451 0.704157 6.65963 0.54812 6.87969 0.448523C7.0902 0.353167 7.32527 0.315173 7.55788 0.338906C7.79049 0.362639 8.01121 0.447136 8.19469 0.58269L11.6266 3.09569C11.8605 3.26937 12.0243 3.51209 12.0911 3.78406C12.1579 4.05602 12.1236 4.34109 11.9941 4.59252C11.9078 4.75934 11.7822 4.90573 11.6266 5.02069L8.19594 7.53369C8.01268 7.66969 7.79184 7.75438 7.55907 7.77792C7.3263 7.80147 7.09116 7.76289 6.88094 7.66669C6.66185 7.56662 6.47754 7.41078 6.34926 7.21716C6.22099 7.02353 6.15397 6.8 6.15594 6.57236V6.02286C2.82469 6.77186 2.27344 9.08594 2.24969 9.19677C2.22302 9.32376 2.15188 9.43887 2.04745 9.52399C1.94301 9.6091 1.81122 9.65939 1.67281 9.66694H1.63531C1.5022 9.66707 1.37252 9.62753 1.26516 9.55409C1.1578 9.48064 1.07837 9.37712 1.03844 9.25861C0.83016 8.41587 0.820835 7.54093 1.01111 6.6945C1.20138 5.84806 1.58669 5.0504 2.14031 4.35686Z"
                fill="#B3B3B3"
              />
            </svg>
          )}
          {Array.isArray(comment?.user_type) && comment.user_type.length > 0
            ? comment.user_type.join(", ")
            : ""}
        </Typography.Text>
      )}
      <p
        style={{ textAlign: "left", margin: "0px", wordBreak: "break-word" }}
        onClick={toggleExpand}>
        {truncateText(comment?.comment)}
        {comment?.comment.length > maxLength && !isExpanded && (
          <span style={{ color: "blue", cursor: "pointer" }}> Show More</span>
        )}
        {isExpanded && (
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={toggleExpand}>
            {" "}
            Show Less
          </span>
        )}
      </p>
      {comment?.uploads?.length > 0 && (
        <div>
          <Popover
            content={
              <div style={{ display: "flex", flexDirection: "column" }}>
                {comment.uploads.map((upload) => (
                  <Link
                    key={upload.title}
                    to={upload.url}
                    target="_blank"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "5px",
                    }}>
                    {upload.title}
                  </Link>
                ))}
              </div>
            }
            title="Uploaded Files"
            trigger="hover">
            <p
              style={{
                margin: 0,
                color: "#B3B3B3",
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}>
              <Paperclip
                style={{ color: "#B3B3B3", width: "16px", height: "16px" }}
              />
              {comment.uploads.length} file
              {comment.uploads.length > 1 ? "s" : ""}
            </p>
          </Popover>
        </div>
      )}
      {comment?.uploaded_files?.length > 0 && (
        <div>
          <Popover
            content={
              <div style={{ display: "flex", flexDirection: "column" }}>
                {comment.uploaded_files?.map((upload) => (
                  <Link
                    key={upload.title}
                    to={upload.url}
                    target="_blank"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "5px",
                    }}>
                    {upload.title}
                  </Link>
                ))}
              </div>
            }
            title="Uploaded Files"
            trigger="hover">
            <p
              style={{
                margin: 0,
                color: "#B3B3B3",
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}>
              <Paperclip
                style={{ color: "#B3B3B3", width: "16px", height: "16px" }}
              />
              {comment.uploaded_files.length} file
              {comment.uploaded_files.length > 1 ? "s" : ""}
            </p>
          </Popover>
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}>
        <div style={{ display: "flex", gap: "2px" }}>
          {hasPermission(permissions.forward) && (
            <Dropdown
              overlay={userTypeMenu}
              trigger={["click"]}
              visible={dropdownVisible}
              onVisibleChange={(visible) => setDropdownVisible(visible)}>
              <Button
                type="link"
                icon={<ForwardFilled />}
                title="Forward Comment"
                loading={isDeleteLoading}
                disabled={userTypes.length === 0 || isDeleteLoading}
              />
            </Dropdown>
          )}

          {hasPermission(
            commentType === "internal"
              ? permissions.moveToClient
              : commentType === "client"
              ? permissions.moveToInternal
              : commentType === "freelancer"
              ? permissions.moveToInternal
              : ""
          ) && (
            <Button
              type="link"
              icon={<UserSwitchOutlined />}
              onClick={
                commentType === "client"
                  ? () => setIsMoveToInternalModalVisible(true)
                  : () => setIsMoveToClientModalVisible(true)
              } // Updated to open modal
              title={
                commentType === "internal"
                  ? "Move to Client Comments"
                  : "Move to Internal Comments"
              }
              loading={isDeleteLoading}
              disabled={userTypes.length === 0 || isDeleteLoading}
            />
          )}

          {hasPermission(permissions.moveToFL) && moveToPortal && (
            <Button
              type="link"
              icon={<SwapOutlined />}
              onClick={() => (setIsmoveToFlCommentModalVisible(true))}
              title="Move to FL Comments"
            />
          )}
          {hasPermission(permissions.delete) && (
            <Button
              type="link"
              icon={<DeleteOutlined style={{ color: "red" }} />}
              title="Delete Comment"
              loading={isDeleteLoading}
              disabled={isDeleteLoading}
              onClick={() =>
                handleDeleteComment(
                  orderKey,
                  commentType === "client" ? comment.uid : comment.uuid
                )
              }
            />
          )}
        </div>
        <p style={{ color: "gray", margin: 0 }}>{localTime}</p>
      </div>
      <Modal
        title="Move to Internal Comment"
        visible={isMoveToInternalModalVisible}
        onOk={handleMoveToInternalSubmit}
        onCancel={() => setIsMoveToInternalModalVisible(false)}
      loading={isDeleteLoading}>
        <Form layout="vertical">
          <Form.Item label="Comment Type" required>
            <Select
              placeholder="Select a comment type"
              value={selectedCommentType}
              onChange={(value) => setSelectedCommentType(value)}
              loading={isLoadingCommentTypes}>
              {commentTypes.map((type) => (
                <Select.Option key={type.id} value={type.id}>
                  {type.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="User Types" required>
            <Select
              mode="multiple"
              placeholder="Select user types"
              value={selectedUserTypes}
              onChange={(values) => setSelectedUserTypes(values)}>
              {userTypes.map((type) => (
                <Select.Option key={type.value} value={type.value}>
                  {type.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Move to Client Comments"
        visible={isMoveToClientModalVisible}
        onOk={handleMoveToInternalSubmit}
        onCancel={() => setIsMoveToClientModalVisible(false)}
        loading={isDeleteLoading}>
        <Form layout="vertical">
          <Form.Item label="Select a Owner" required>
            <Select
              placeholder="Select an Owner"
              mode="multiple" // Enables multiple selection
              value={selectedOwner || []} // Ensure value is an array for multiple select
              onChange={(value) => setSelectedOwner(value)} // Handles multiple values
              loading={isLoadingCommentTypes}>
              {orderOwners.map((type) => (
                <Select.Option key={type.id} value={type.username}>
                  {type.username}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Move to Freelancer Comments"
        visible={ismoveToFlCommentModalVisible}
        onOk={handleMoveToFL}
        onCancel={() => setIsmoveToFlCommentModalVisible(false)}
        loading={isDeleteLoading}>
        <Form layout="vertical">
          <Form.Item label="Select a Owner" required>
            <Select
              placeholder="Select an Owner"
              mode="multiple" // Enables multiple selection
              value={selectedOwner || []} // Ensure value is an array for multiple select
              onChange={(value) => setSelectedOwner(value)} // Handles multiple values
              loading={isLoadingCommentTypes}>
              {orderOwners.map((type) => (
                <Select.Option key={type.id} value={type.username}>
                  {type.username}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </Flex>
  );
};

export default Comment;
