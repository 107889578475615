import React, { useState, useEffect } from "react";
import { Spin, message } from "antd";
import orderService from "../../services/OrderLists";

function ZipFilesDownload({ orderData ,  handleModalClose }) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleDownload = async () => {
      setLoading(true); // Start loading spinner
      try {
        const result = await orderService.downloadZipFile(orderData?.data?.key);
        if (result.success) {
          message.success("Files downloaded successfully!");
          handleModalClose()
        } 
      } catch (error) {
        // message.error("An error occurred during the download.");
        handleModalClose()
      } finally {
        setLoading(false); // Stop loading spinner
        handleModalClose()
      }
    };

    if (orderData?.data?.key) {
      handleDownload(); // Trigger download on component mount
    }
  }, [orderData]);

  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <Spin spinning={loading} tip="Downloading files...">
        {/* Spin loader will show while downloading */}
      </Spin>
    </div>
  );
}

export default ZipFilesDownload;
