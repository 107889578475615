import { startLoading, stopLoading } from "../redux/slices/loadingSlice";
import store from "../redux/store";
import { catchError } from "../utils/errorHandler";
import axiosInstance from "./axiosInstance";


const OrderLogServivces = {

    getOrderTaskLogsInfo: async (orderKey) => {
        try {
            store.dispatch(startLoading());
            const response = await axiosInstance.get(
                `/task/v2/task-logs/${orderKey}`
            );
            store.dispatch(stopLoading());
            if (response.status === 200) {
                return { success: true, data: response.data };
            }
            return { success: false };
        } catch (error) {
            store.dispatch(stopLoading());
            // await catchError(error);
            return { success: false };
        }
    },
    getSubscriberLogsInfo: async (orderKey) => {
        try {
            store.dispatch(startLoading());
            const response = await axiosInstance.get(
                `/task/v2/subscribers-logs/${orderKey}`
            );
            store.dispatch(stopLoading());
            if (response.status === 200) {
                return { success: true, data: response.data };
            }
            return { success: false };
        } catch (error) {
            store.dispatch(stopLoading());
            // await catchError(error);
            return { success: false };
        }
    },
    getPaymentLogsInfo: async (orderKey) => {
        try {
            store.dispatch(startLoading());
            const response = await axiosInstance.get(
                `/task/v2/payment-history/${orderKey}`
            );
            store.dispatch(stopLoading());
            if (response.status === 200) {
                return { success: true, data: response.data };
            }
            return { success: false };
        } catch (error) {
            store.dispatch(stopLoading());
            // await catchError(error);
            return { success: false };
        }
    },
    getAcceptRejectLogsInfo: async (orderKey) => {
        try {
            store.dispatch(startLoading());
            const response = await axiosInstance.get(
                `/task/v2/task-accept-reject-logs/${orderKey}`
            );
            store.dispatch(stopLoading());
            if (response.status === 200) {
                return { success: true, data: response.data };
            }
            return { success: false };
        } catch (error) {
            store.dispatch(stopLoading());
            // await catchError(error);
            return { success: false };
        }
    },
    getCallLogsInfo: async (orderKey) => {
        try {
            store.dispatch(startLoading());
            const response = await axiosInstance.get(
                `/task/v2/order-call-logs/${orderKey}`
            );
            store.dispatch(stopLoading());
            if (response.status === 200) {
                return { success: true, data: response.data };
            }
            return { success: false };
        } catch (error) {
            store.dispatch(stopLoading());
            // await catchError(error);
            return { success: false };
        }
    },
    getActivityLogsInfo: async (orderKey) => {
        try {
            store.dispatch(startLoading());
            const response = await axiosInstance.get(
                `/task/v2/activity-logs/${orderKey}`
            );
            store.dispatch(stopLoading());
            if (response.status === 200) {
                return { success: true, data: response.data };
            }
            return { success: false };
        } catch (error) {
            store.dispatch(stopLoading());
            // await catchError(error);
            return { success: false };
        }
    },



}
export default OrderLogServivces