import React from "react";
import {Layout} from "antd";
import {AppRoutes} from "../../routes/AppRoutes";
import styles from "./layout.module.css";

const {Content} = Layout;

const MainContent = () => {
    return (
        <Content className={styles.content}>
            <div className={styles.inner}>
                <AppRoutes/>
            </div>
        </Content>
    );
};

export default MainContent;
