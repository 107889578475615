import axiosInstance from "./axiosInstance";
import { catchError } from "../utils/errorHandler";
import { Device } from "@twilio/voice-sdk";
import { message } from "antd";
const commonService = {
    getCurrencyList: async () => {
        try {
          const response = await axiosInstance.get("api/v2/currency/all/");
          if (response.status === 200) {
            return { success: true, data: response.data };
          }
          return { success: false };
        } catch (error) {
          await catchError(error);
          return { success: false };
        }
      },
      getCommonInfo: async () => {
        try {
          const response = await axiosInstance.get("api/v2/common-info/");
          if (response.status === 200) {
            return { success: true, data: response.data };
          }
          return { success: false };
        } catch (error) {
          await catchError(error);
          return { success: false };
        }
      },
      fetchTwilioToken: async () => {
        try {
          // Sending a GET request with query parameter 'type=task'
          const response = await axiosInstance.get(`/tc/twilio-token/`, {
            params: {
              type: "task",
            },
          });
    
          if (response.status === 200) {
            const token = response.data.data?.token;
            console.log("Fetched token:", token); // Log the token for debugging
    
            if (typeof token === "string") {
              return { success: true, token };
            } else {
              console.error("Invalid token format");
              return { success: false };
            }
          }
          return { success: false };
        } catch (error) {
          // Handle the error
          await catchError(error);
          return { success: false };
        }
      },
      initializeTwilioDevice: async () => {
        const { success, token } = await commonService.fetchTwilioToken();
        if (!success || !token) {
          console.error("Failed to fetch Twilio token");
          return null;
        }
    
        try {
          const device = new Device(token, {
            codecPreferences: ["opus", "pcmu"],
            fakeLocalDTMF: true,
            enableRingingState: true,
          });
    
          console.log("Initializing Twilio device with token:", token);
    
          device.on(Device.EventName.TokenWillExpire, async () => {
            const { success: newSuccess, token: newToken } =
              await commonService.fetchTwilioToken();
            if (newSuccess && newToken) {
              device.updateToken(newToken);
            } else {
              console.error("Failed to update Twilio token");
            }
          });
    
          device.on(Device.EventName.Disconnect, () => {
            console.log("Call disconnected");
          });
    
          device.addListener("registered", () => {
            console.log("The device is ready to receive incoming calls.");
          });
    
          return device;
        } catch (error) {
          console.error("Error initializing Twilio device:", error);
          return null;
        }
      },
    
      // Assuming this function is in the LeadsList service
      makeTwilioCall: async (task_key, uid ) => {
        const device = await commonService.initializeTwilioDevice();
        if (!device) return;
    
        try {
          const params = { task_key, uid}; // Include type in params
          console.log("Sending to Twilio device.connect:", params);
    
          const connection = await device.connect({ params });
          console.log(connection);
          // Log the connection object for debugging
          connection.on("accept", () => {
            console.log("Call accepted, conversation started");
    
            // Start the call duration timer
          });
          return connection;
        } catch (error) {
          console.error("Error making call:", error);
          throw error;
        }
      },
    
};

export default commonService;
