import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const PermissionProtectedRoute = ({ permissionsRequired }) => {
  const { user } = useSelector((state) => state.auth);

  if (!user) {
 
    return <Navigate to="/login" />;
  }

  const permissions = user.user.permissions || [];

  const hasPermissions = permissionsRequired.every((perm) =>
    permissions.includes(perm)
  );


  return hasPermissions ? <Outlet /> : <Navigate to="/unauthorized" />;
};

export default PermissionProtectedRoute;
