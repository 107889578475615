import React, { useState, useEffect } from 'react';
import { Select, Button, Spin, Input, Form, message } from 'antd';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../services/axiosInstance';
import SpinLoading from './SpinLoading';
import orderService from '../../services/OrderLists';
import axios from 'axios';
import { catchError } from '../../utils/errorHandler';

const { Option } = Select;
const { TextArea } = Input;

const EditFlTaskStatus = ({ orderData, handleModalClose, fetchOrderDetails }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [statusList, setStatusList] = useState({});
    const { orderKey } = useParams();  // Assuming orderKey still needed from params

    useEffect(() => {
        const fetchStatusList = async () => {
            setLoading(true);
            try {
                const response = await axiosInstance.get(`/task/v2/fl-status/dropdown/`)
                if (response && response.data && response.data.data) {
                    const newStatusList = Object.entries(response.data.data).reduce((acc, [key, value]) => {
                        acc[value] = parseInt(key);
                        return acc;
                    }, {});
                    setStatusList(newStatusList);
                } else {
                    console.error("Failed to fetch status list or invalid response format");
                }
            } catch (error) {
                console.error("Error fetching status list:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchStatusList();
    }, []);

    const handleSubmit = async (values) => {
        const { status, change_reason } = values;

        if (!status || !change_reason.trim()) {
            message.error("All fields are mandatory!");
            return;
        }

        const payload = {
            fl_status: statusList[status],  // Send the key (ID)
            change_reason,
        };


        setSubmitLoading(true);
        try {
            const response = await axiosInstance.put(`/task/v2/update-flstatus/${orderKey}/`, payload);
            if (response?.status === 200 && response?.data?.status === "success") {
                message.success(response?.data?.data);
                form.resetFields()
                handleModalClose()
                fetchOrderDetails()
            }
        } catch (error) {
            await catchError(error)
        } finally {
            setSubmitLoading(false);
        }
    };

    if (loading) {
        return <Spin tip="Loading status options..." style={{ display: 'block', marginTop: '0' }} />;
    }

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={{
                status: orderData?.data?.fl_status || null,
                change_reason: '',
            }}
        >
            <Form.Item
                style={{ width: "100%" }}
                name="status"
                label="Select Status"
                rules={[{ required: true, message: 'Please select a status!' }]}
            >
                <Select placeholder="Select Status" loading={loading}>
                    {loading ? (
                        <Option disabled value="loading">
                            <Spin />
                        </Option>
                    ) : (
                        Object.keys(statusList).map((status) => (
                            <Option key={statusList[status]} value={status}>
                                {status}
                            </Option>
                        ))
                    )}
                </Select>
            </Form.Item>

            <Form.Item
                style={{ width: "100%" }}
                name="change_reason"
                label="Change Reason"
                rules={[{ required: true, message: 'Please provide a reason for the status change!' }]}
            >
                <TextArea
                    rows={4}
                    placeholder="Provide a reason for the status change"
                />
            </Form.Item>

            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20, width: "100%" }}>
                {
                    submitLoading
                        ? <SpinLoading />
                        :
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={submitLoading}
                        >
                            Submit
                        </Button>
                }
            </div>
        </Form>
    );
};

export default EditFlTaskStatus;
