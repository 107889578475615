import React, { useState, useEffect } from "react";
import { Tabs, Typography, Flex, Switch, message } from "antd";
import CreateSolution from "./CreateSolution";
import CreateSolutionStageTwo from "./CreateSolutionStageTwo";
import orderService from "../../services/OrderLists";
import usePermissions from "../../hooks/usePermissions";
import { useParams } from "react-router-dom";
function CreateSolutionParent({ setIsModalVisible, getSolutionFiles }) {
  const { TabPane } = Tabs;
  const { orderKey } = useParams();
 
  const { hasPermission } = usePermissions();
  const [subscribers, setSubscribers] = useState([]);
  const [fileTypes, setFileTypes] = useState([]);

  const [solutionFiles, setSolutionFiles] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState("1");
  const [formData, setFormData] = useState({
    files: [],
    file_type: "",
    remarks: "",
  });
  const [solutionFormdataStageTwo, setSolutionFormdataStageTwo] = useState({
    file_uids: [],
    subscribers: [],
  });
  const [dropdownData, setDropdownData] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [description, setDescription] = useState("");
  const [selectedRadio, setSelectedRadio] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const handleRadioChange = (value) => {
    setSelectedRadio(value);
  };
  const handleSubscriberChange = (username) => {
    setSolutionFormdataStageTwo((prevData) => ({
      ...prevData,
      subscribers: prevData.subscribers.includes(username)
        ? prevData.subscribers.filter((subscriber) => subscriber !== username) // Remove if already selected
        : [...prevData.subscribers, username], // Add if not selected
    }));
  };

  // const handleSwitchChange = (checked) => {
  //   setFormData({ ...formData, is_partial_work: checked });
  // };
  useEffect(() => {
    try {
      setLoading(true);
      if (activeTabKey === "1") {
        const getFileTypes = async () => {
          const fileTypesResponse = await orderService.getSolutionFilesTypes();

          if (fileTypesResponse.success) {
            setLoading(false);
            setFileTypes(fileTypesResponse?.data);
          }
        };
        getFileTypes();
      } else if (activeTabKey === "2") {
        setLoading(true);
        const getFileList = async () => {
          const response = await orderService.getSolutionSubscribers(orderKey);
          const getFileListResponse = await orderService.getFileList(orderKey);
          if (response.success && getFileListResponse.success) {
            setSubscribers(response?.data?.data);

            setSolutionFiles(getFileListResponse?.data?.data);
            setLoading(false);
          }
        };
        getFileList();
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    // finally{
    //   setLoading(false);
    // }
  }, [activeTabKey]);

  const handleFileChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);

    // Extract only the originFileObj from each file entry
    const files = newFileList.map((file) => file.originFileObj).filter(Boolean);

    setFormData((prevData) => ({
      ...prevData,
      files: files,
    }));
  };
  const handleDeleteFile = (index) => {
    const updatedFileList = [...fileList];
    updatedFileList.splice(index, 1); // Remove the file at the specified index
    setFileList(updatedFileList); // Update the state with the new file list

    // Update formData.files to remove the corresponding file
    const updatedFiles = [...formData.files];
    updatedFiles.splice(index, 1); // Remove the file at the specified index
    setFormData((prevData) => ({ ...prevData, files: updatedFiles })); // Update files in formData
  };

  const handleTabChange = (key) => {
    setActiveTabKey(key);
  };

  const handleCreateSolution = async () => {
    try {
      setIsSubmitLoading(true);

      if (activeTabKey === "1") {
        const data = new FormData();
        data.append("file_type", formData.file_type);
        data.append("remarks", formData.remarks);

        if (formData.files && formData.files.length > 0) {
          formData.files.forEach((file) => {
            data.append("files", file);
          });
        }

        const response = await orderService.createSolutionStageOne(
          orderKey,
          data
        );

        if (response.success) {
          message.success("Solution created successfully");
         await  getSolutionFiles()
          setActiveTabKey("2");
        }
      }

      if (activeTabKey === "2") {
        const response = await orderService.createSolutionStageTwo(
          orderKey,
          solutionFormdataStageTwo
        );

        if (response.success) {
          message.success("Files added successfully");
          await  getSolutionFiles()
          setIsModalVisible(false);
        }
      }
    } catch (error) {
      // message.error("An error occurred while creating the solution.");
      console.log(error, "error");
    } finally {
      // setIsModalVisible(false)
      setIsSubmitLoading(false); // Ensure loading state is reset in all cases
    }
  };

  return (
    <div>
      <Tabs
        activeKey={activeTabKey}
        onChange={handleTabChange}
        // tabBarExtraContent={
        //   activeTabKey === "1" && (
        //     <div style={{ display: "flex", alignItems: "center" }}>
        //       <label style={{ marginRight: "8px" }}>Work Is Partial</label>
        //       <Switch
        //         checked={formData.is_partial_work}
        //         onChange={handleSwitchChange}
        //       />
        //     </div>
        //   )
        // }
      >
        <TabPane tab="Add Solution" key="1">
          <CreateSolution
            fileTypes={fileTypes}
            formData={formData}
            onSubmit={handleCreateSolution}
            loading={loading}
            fileList={fileList}
            setFileList={setFileList}
            handleFileChange={handleFileChange}
            handleDeleteFile={handleDeleteFile}
            setFormData={setFormData}
            isSubmitLoading={isSubmitLoading}
          />
        </TabPane>
        {hasPermission("can_view_order_action_add_solution") && (
          <TabPane tab="Files List" key="2">
            <CreateSolutionStageTwo
              solutionFiles={solutionFiles}
              subscribers={subscribers}
              solutionFormdataStageTwo={solutionFormdataStageTwo}
              loading={loading}
              setSolutionFormdataStageTwo={setSolutionFormdataStageTwo}
              handleSubscriberChange={handleSubscriberChange}
              handleRadioChange={handleRadioChange}
              onSubmit={handleCreateSolution}
              isSubmitLoading={isSubmitLoading}
            />
          </TabPane>
        )}
      </Tabs>
    </div>
  );
}

export default CreateSolutionParent;
