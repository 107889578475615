import React, { useState, useEffect } from "react";
import axiosInstance from "../../services/axiosInstance";
import { catchError } from "../../utils/errorHandler";
import { useParams } from "react-router-dom";
import { Checkbox, List, Spin, Button, message, Flex, Typography, Tag } from "antd";
import orderService from "../../services/OrderLists";

function MarkAsCompleteFlTask({ setIsModalVisible ,orderData}) {
  const { orderKey } = useParams();
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const fetchSubscribersList = async () => {
    setLoading(true);
    try {
      const res = await orderService.getFlSubscribers(orderKey);

      const data = res?.data?.data || [];
  
      setDataSource(data);
    } catch (err) {
      catchError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubscribersList();
  }, [orderKey]);

  const handleUserSelect = (uuid) => {
    setSelectedUsers((prev) => {
      if (prev.includes(uuid)) {
        return prev.filter((id) => id !== uuid);
      } else {
        return [...prev, uuid];
      }
    });
  };

  const handleSubmit = async () => {
    try {
      const selectedUsernames = dataSource
        .filter((item) => selectedUsers.includes(item.uuid))
        .map((item) => item.username);

      const response = await orderService.markAsFlVerified(orderKey, {
        fl_subscribers: selectedUsernames,
      });

      if (response.success) {
        message.success("Successfully marked as verified");
        setIsModalVisible(false);
      } else {
        message.error("Failed to mark as verified");
      }
    } catch (err) {
      message.error("An error occurred");
      console.error(err);
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div>
      <h2>Are you sure to move this order for next step?</h2>
      <Flex gap={10} align="center">
        {" "}
        <Typography.Text>Current FL Status:</Typography.Text>
        <Tag color="purple">{orderData?.data?.fl_status}</Tag>
      </Flex>
      <List
        dataSource={dataSource}
        renderItem={(item) => (
          <List.Item>
          <Checkbox
  checked={selectedUsers.includes(item.uuid)}
  onChange={() => handleUserSelect(item.uuid)}
>
  {item.fl_subscriber} ({item.amount})
</Checkbox>

          </List.Item>
        )}
      />
      <div style={{ marginTop: 16, textAlign: "right" }}>
        <Button
          type="primary"
          onClick={handleSubmit}
          style={{ color: "white" }}
          disabled={selectedUsers.length === 0}>
          Submit
        </Button>
      </div>
    </div>
  );
}

export default MarkAsCompleteFlTask;
