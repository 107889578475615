import { FileTextOutlined, TagOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Switch,
  Tooltip,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import orderService from "../../services/OrderLists";
import moment from "moment";
import dayjs from "dayjs";
import { Divide } from "lucide-react";
import { catchError } from "../../utils/errorHandler";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../services/axiosInstance";

const { Option } = Select;

const CopyTask = ({ orderData, handleModalClose }) => {
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const [selectedRadio, setSelectedRadio] = useState(null);
  const [isFormSubmitiing, setIsFormSubmtting] = useState(false);
  const { orderKey } = useParams();
  const navigate = useNavigate();

  const initialDeadline = orderData?.data?.deadline_hard
    ? dayjs(orderData.data.deadline_hard)
    : null;

  const [formData, setFormData] = useState({
    title: orderData.data.title,
    description: orderData.data.description,
    client_words: orderData.data.writer_word_count || 0,
    tags: orderData.data.tags || [],
    client_deadline: initialDeadline,
    quality_needed: parseInt(orderData.data.quality, 10),
    makes_me_owner: false,
    create_payment: false,
    timezone: "Asia/Kolkata",
    bdms: [],
  });

  const [dropdownData, setDropdownData] = useState({
    qualities: [],
    references: [],
    timeZone: [],
    situation: [],
    bdm: [],
  });

  const handleSubmit = async () => {
    setIsFormSubmtting(true);
    try {
      const response = await axiosInstance.post(
        `task/v2/copy/${orderKey}/`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200 && response.data.status === "success") {
        const NewOrderKey = response?.data?.data?.key;
        navigate(`/order-management/my-orders/${NewOrderKey}`);
        message.success("Order Copy Success");
      }
      setIsFormSubmtting(false);
    } catch (err) {
      await catchError(err);
      setIsFormSubmtting(false);
    }
  };

  useEffect(() => {
    const fetchDropdownData = async () => {
      setLoading(true);
      try {
        const qualityData = await orderService.getQualitiesDropdown();
        const referenceData = await orderService.getReferenceDropdown();
        const timezoneData = await orderService.getTimezonesDropdown();
        const situationData = await orderService.getSituationDropdown();
        const response = await orderService.getBdmMappingDropdown();

        const qualities = Object.entries(qualityData.data).map(
          ([key, value]) => ({
            key,
            value,
          })
        );

        // Find the key for the quality in orderData
        const initialQualityKey = qualities.find(
          (option) => option.value === orderData.data.quality
        )?.key;

        // Set the initial selected radio based on the key
        if (initialQualityKey) {
          setSelectedRadio(initialQualityKey);
          setFormData((prevData) => ({
            ...prevData,
            quality_needed: parseInt(initialQualityKey, 10),
          }));
        }

        setDropdownData({
          qualities,
          references: referenceData.data,
          timeZone: timezoneData.data,
          situation: situationData.data,
          bdm: response.data,
        });
      } catch (error) {
        console.error("Error fetching dropdown data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDropdownData();
  }, [orderData.data]);

  const handleDescriptionChange = (value) => {
    setFormData((prevData) => ({ ...prevData, description: value }));
  };

  const handleTimezoneChange = (value) => {
    setFormData((prevData) => ({ ...prevData, timezone: value }));
  };

  const handleSituationChange = (value) => {
    setFormData((prevData) => ({ ...prevData, task_situation: value }));
  };

  const handleBdmsChange = (value) => {
    setFormData((prevData) => ({ ...prevData, bdms: [value] }));
  };

  const handleDeadlineChange = (date) => {
    setFormData((prev) => {
      const updatedData = {
        ...prev,
        client_deadline: date ? dayjs(date).toISOString() : null,
      };
      return updatedData;
    });
  };

  const handleRadioChange = (value) => {
    setSelectedRadio(value);
    setFormData((prevData) => ({
      ...prevData,
      quality_needed: parseInt(value, 10),
    }));
  };

  return (
    <>
      {loading ? (
        <Flex justify="center" style={{ width: "100%" }}>
          <Spin />
        </Flex>
      ) : (
        <>
          <strong>ARE YOU SURE TO COPY THE ORDER ?</strong>
          <Divider />

          <Form
            style={{ width: "100%" }}
            form={form}
            layout="vertical"
            encType="multipart/form-data"
            initialValues={{
              deadline: formData.client_deadline,
            }}>
            {/* Title Here */}
            <Row gutter={16} style={{ width: "100%" }}>
              <Col sm={24}>
                <Form.Item label="Title" required>
                  <Input
                    placeholder="Type Title here"
                    value={formData.title}
                    onChange={(e) =>
                      setFormData({ ...formData, title: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            {/* Description Here  */}
            <Row gutter={16} style={{ width: "100%" }}>
              <Col xs={24}>
                <Form.Item label="Description" required>
                  <ReactQuill
                    value={formData.description}
                    onChange={handleDescriptionChange}
                    style={{ height: "150px" }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row style={{ marginTop: "3rem", width: "100%" }} gutter={16}>
              {/* Client Word Count Here */}
              <Col lg={12}>
                <Form.Item label="Client Words">
                  <Input
                    pattern="\d*"
                    placeholder="Word count"
                    value={formData.client_words}
                    suffix={
                      <FileTextOutlined
                        style={{ color: "rgba(0, 0, 0, 0.25)" }}
                      />
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value)) {
                        setFormData({
                          ...formData,
                          client_words: value,
                        });
                      }
                    }}
                  />
                </Form.Item>
              </Col>

              {/* Tags Here */}
              <Col lg={12}>
                <Form.Item label="Tags">
                  <Input
                    placeholder="Tags Here"
                    value={formData.tags.join(", ")}
                    suffix={
                      <TagOutlined style={{ color: "rgba(0, 0, 0, 0.25)" }} />
                    }
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const tagsArray = inputValue
                        .split(",")
                        .map((tag) => tag.trim())
                        .filter(Boolean);

                      setFormData((prev) => ({
                        ...prev,
                        tags: tagsArray,
                      }));
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row style={{ width: "100%" }} gutter={16}>
              {/* Client Deadline here  */}
              <Col lg={12}>
                <Form.Item
                  name="deadline"
                  label="Deadline"
                  rules={[
                    { required: true, message: "Please select a deadline!" },
                  ]}>
                  <DatePicker
                    value={formData.client_deadline}
                    style={{
                      width: window.innerWidth < 802 ? "375px" : "100%",
                    }}
                    showTime={{
                      format: "HH:mm",
                      use12Hours: true,
                    }}
                    format="YYYY-MM-DD hh:mm A"
                    onChange={handleDeadlineChange}
                  />
                </Form.Item>
              </Col>

              {/* TimeZone Here  */}
              <Col lg={12}>
                <Form.Item label="Timezone">
                  <Select
                    value={formData.timezone}
                    placeholder="Select timezone"
                    onChange={handleTimezoneChange}>
                    {dropdownData.timeZone.map((timezone) => (
                      <Option key={timezone} value={timezone}>
                        {timezone}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row style={{ width: "100%" }} gutter={16}>
              {/* Select BDM */}
              <Col lg={12}>
                <Form.Item label="Select BDM">
                  <Select
                    value={formData.bdms}
                    placeholder="Select BDM"
                    onChange={handleBdmsChange}>
                    {dropdownData.bdm.map((item, index) => (
                      <Option key={index} value={item.username}>
                        {item.username}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              {/* Task Situation */}
              <Col lg={12}>
                <Form.Item label="Order Situation">
                  <Select
                    placeholder="Select Order Situation"
                    onChange={handleSituationChange}
                    value={formData.task_situation}>
                    {dropdownData.situation.map((situation) => (
                      <Option key={situation.id} value={situation.id}>
                        {situation.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row style={{ width: "100%" }} gutter={16}>
              {/* Client UserName */}
              <Col lg={12}>
                <Form.Item label="Client's Username">
                  <Input
                    placeholder="client username"
                    value={formData.client_username}
                    onChange={(e) => {
                      const value = e.target.value;
                      setFormData({
                        ...formData,
                        client_username: value,
                      });
                    }}
                  />
                </Form.Item>
              </Col>

              {/* Refrencing Style  */}
              <Col lg={12}>
                <Form.Item label="Referencing Style">
                  <Select
                    placeholder="Select referencing style"
                    onChange={(value) => {
                      setFormData({ ...formData, referencing_style: value });
                    }}
                    value={formData.referencing_style}>
                    {dropdownData.references.map((ref) => (
                      <Option key={ref.id} value={ref.id}>
                        {ref.style}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16} style={{ width: "100%" }}>
              {/* Quality  */}
              <div>
                <label
                  style={{ color: "black", marginLeft: "10px" }}
                  aria-required>
                  Select Quality
                </label>
                <div className="custom-radio-container">
                  {dropdownData.qualities.map((option) => (
                    <div
                      key={option.key}
                      style={{
                        marginTop: "10px",
                        position: "relative",
                        left: "10px",
                      }}>
                      <input
                        type="radio"
                        id={`custom-radio-${option.key}`}
                        name="quality"
                        value={option.key}
                        className="custom-radio-input"
                        checked={selectedRadio === option.key}
                        onChange={() => handleRadioChange(option.key)} // Store the selected ID
                      />
                      <label
                        style={{ padding: "3px 2px" }}
                        htmlFor={`custom-radio-${option.key}`}
                        className="custom-radio-label">
                        {option.value}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </Row>

            <Row
              style={{
                marginTop: "2rem",
                width: "100%",
              }}
              gutter={16}>
              <Col lg={8}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignContent: "center",
                    gap: "20px",
                  }}>
                  <Tooltip
                    title="Select this option to access and view the order, even if you are not the owner
">
                    {" "}
                    Enable Visibility
                  </Tooltip>
                  <Switch
                    checked={formData.makes_me_owner || false} // Controlled state
                    onChange={(checked) =>
                      setFormData((prev) => ({
                        ...prev,
                        makes_me_owner: checked,
                      }))
                    }
                  />
                </div>
              </Col>

              {/* Make Payment Checkbox/Button */}

              <Col lg={8} style={{ marginBottom: "10px" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignContent: "center",
                    gap: "20px",
                  }}>
                  <div>Create Payment </div>
                  <Switch
                    checked={formData.create_payment || false} // Controlled state
                    onChange={(checked) =>
                      setFormData((prev) => ({
                        ...prev,
                        create_payment: checked,
                      }))
                    }
                  />
                </div>
              </Col>
            </Row>
          </Form>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 20,
              width: "100%",
              marginBottom: "50px",
            }}>
            <Button
              onClick={handleSubmit}
              type="primary"
              htmlType="submit"
              loading={isFormSubmitiing}>
              Copy Order
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default CopyTask;
