import React from "react";
import OrderProgressTrackingTableList from "../../page-components/OrderProgressTracking/OrderProgressTrackingTableList";
import { Flex } from "antd";

function OrderProgressTracking() {
  const data = [
    {
      expert: "Chirag Bansal",
      order_id: "XDJKGPV4M9",
      manager_deadline: "2024-12-01",
      client_deadline: "2024-12-01",
      writer_deadline: "2024-12-01",
      started_on: "2024-12-01",
      status: "Started",
    },
    {
        expert: "Vidhhi Tomar",
        order_id: "XDJKGPV349",
        manager_deadline: "2024-1-01",
        client_deadline: "2024-8-01",
        writer_deadline: "2024-3-01",
        started_on: "2024-12-01",
        status: "In Progress",
      },
      {
        expert: "Vaibhav",
        order_id: "XDJKHOV349",
        manager_deadline: "2024-9-30",
        client_deadline: "2024-8-12",
        writer_deadline: "2024-3-3",
        started_on: "2024-12-01",
        status: "Complete",
      },
      {
        expert: "Ankit",
        order_id: "XDJKHOV349",
        manager_deadline: "2024-9-30",
        client_deadline: "2024-8-12",
        writer_deadline: "2025-2-10",
        started_on: "2024-12-01",
        status: "Overdue",
      },
      {
        expert: "Gaurav",
        order_id: "XDJKHOV349",
        manager_deadline: "2024-9-30",
        client_deadline: "2024-8-12",
        writer_deadline: "2025-2-5",
        started_on: "2024-12-01",
        status: "Overdue",
      },
      {
        expert: "Sid",
        order_id: "XDJKGPV349",
        manager_deadline: "2024-1-01",
        client_deadline: "2024-8-01",
        writer_deadline: "2024-3-01",
        started_on: "2024-12-01",
        status: "In Progress",
      },
      {
        expert: "Gauraviiiii",
        order_id: "XDJKHOV349",
        manager_deadline: "2024-9-30",
        client_deadline: "2024-8-12",
        writer_deadline: "2025-2-5",
        started_on: "2024-12-01",
        status: "Overdue",
      },
  ];
  return (
    <>
    <Flex style={{height:'100%' , width:'100%' ,padding:10 }} vertical >
    <OrderProgressTrackingTableList data={data} />


    </Flex>
    </>
  );
}

export default OrderProgressTracking;
