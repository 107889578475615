import axiosInstance from "./axiosInstance";
import { catchError } from "../utils/errorHandler";
import moment from "moment";

const FlOrdersForPayoutService = {
  flOrderForPayoutRequestList: async (
    page = 1,
    pageSize = 10,
    filters = {},
    isDownload = false
  ) => {
    const {
      month,
      year,
      key,
      status,
      hierarchy,
      start_date,
      end_date,
      username,
      fl_name,
      requested_by
    } = filters;

    // ✅ Ensure the download param is only appended when needed
    const queryParams = {
      page,
      page_size: pageSize,

      ...(key && { key }),
      ...(status && { status }),
      ...(hierarchy && { hierarchy }),
      ...(start_date && { start_date }),
      ...(isDownload ? { download: "true" } : {}), // ✅ Append download only when requested
      ...(username && { username }),
      ...(fl_name && { fl_name }),
      ...(end_date && { end_date}),
      ...(requested_by && { requested_by}),
    };

    const queryString = new URLSearchParams(queryParams).toString();

    try {
      const response = await axiosInstance.get(
        `task/v2/fl-orders-payout-request/?${queryString}`,
        {
          responseType: isDownload ? "blob" : "json", // ✅ Only fetch as blob when downloading
        }
      );

      if (isDownload) {
        // ✅ Handle file download
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `order_deductions_${moment().format(
          "YYYYMMDD_HHmmss"
        )}.xlsx`; // ✅ Unique filename
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        return { success: true };
      }

      // ✅ Return data for UI
      if (response.status === 200) {
        return { success: true, data: response.data };
      }

      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  // ✅ Fetch details of a single payout request using UUID
  getFlOrderPayoutDetail: async (uuid) => {
    try {
      const response = await axiosInstance.get(
        `task/v2/fl-orders-payout-request/detail/${uuid}`
      );

      // ✅ Return data for UI
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  editStatus: async (uuid, data) => {
    try {
      const response = await axiosInstance.post(
        `task/v2/fl-orders-payout-request/edit-status/${uuid}/`,
        data
      );

      // ✅ Return data for UI
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  payoutStatusList: async () => {
    try {
      const response = await axiosInstance.get(
        `task/v2/fl-orders-payout-request/dropdown/`
      );

      // ✅ Return data for UI
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  payoutStatusChangeList: async () => {
    try {
      const response = await axiosInstance.get(
        `task/v2/fl-orders-payout-request/dropdown/?edit-status=True`
      );

      // ✅ Return data for UI
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  addFlOrdersPayoutRequestDeduction: async (uuid, data) => {
    try {
      const response = await axiosInstance.post(
        `task/v2/fl-orders-payout-request/deduction/${uuid}/`,
        data
      );

      // ✅ Return data for UI
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
};

export default FlOrdersForPayoutService;
