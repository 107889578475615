import React, { useEffect, useState } from "react";
import { Tree, Spin, message, Typography, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";
import hierarchyService from "../../services/hierarchyService";
import "./grid.css";

const { Title } = Typography;

const UserHierarchy = ({ onSelectNode }) => {
  const [hierarchyData, setHierarchyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [selectedUids, setSelectedUids] = useState([]);

  const transformHierarchyData = (data, path = "0") => {
    return {
      title: data.user ? `${data.user}` : "",
      key: path,
      uid: data.uid,
      children: data.hierarchy
        ? data.hierarchy.map((child, index) =>
            transformHierarchyData(child, `${path}-${index}`)
          )
        : [],
    };
  };

  const getDefaultExpandedKeys = (data, path = "0") => {
    const keys = [path];
    if (data.hierarchy) {
      data.hierarchy.forEach((item, index) => {
        keys.push(...getDefaultExpandedKeys(item, `${path}-${index}`));
      });
    }
    return keys;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await hierarchyService.fetchHierarchy();
        if (result.data.status === "success") {
          const data = result.data.data;
          setHierarchyData(data);

          // Automatically check the parent node on first load
          const defaultCheckedKeys = [`0`]; // Assuming the key for the first node is '0'
          setCheckedKeys(defaultCheckedKeys);
          console.log(data.uid, "data i uid");
          setSelectedUids([data.uid]); // This line sets the UID of the root node
        } else {
          message.error("Failed to fetch hierarchy data");
        }
      } catch (error) {
        message.error("Failed to fetch hierarchy data");
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  const onCheck = (checkedKeysValue) => {
    let checkedKeys;
    if (Array.isArray(checkedKeysValue)) {
      checkedKeys = checkedKeysValue;
    } else if (checkedKeysValue.checked) {
      // If it's an object, use the 'checked' property
      checkedKeys = checkedKeysValue.checked;
    } else {
      // Fallback to an empty array if neither condition is met
      checkedKeys = [];
    }

    setCheckedKeys(checkedKeys);
    const newSelectedUids = [];

    const addNodeUid = (node) => {
      if (checkedKeys.includes(node.key)) {
        newSelectedUids.push(node.uid);
      }
      if (node.children) {
        node.children.forEach(addNodeUid);
      }
    };

    if (hierarchyData) {
      const treeData = [transformHierarchyData(hierarchyData)];
      treeData.forEach(addNodeUid);
    }
    setSelectedUids(newSelectedUids.filter((uid) => uid !== undefined));
  };

  const handleApply = () => {
    if (selectedUids.length === 0) {
      message.error("You must select at least one person.");
    } else {
      onSelectNode(selectedUids);
    }
  };

  const titleRender = (nodeData) => {
    const isParent = nodeData.key.split("-").length <= 2;
    return (
      <span className={isParent ? "parent-node" : "child-node"}>
        {nodeData.title}
      </span>
    );
  };

  if (loading) {
    return <Spin size="large" />;
  }

  if (!hierarchyData) {
    return null;
  }

  const treeData = [transformHierarchyData(hierarchyData)];
  const defaultExpandedKeys = getDefaultExpandedKeys(hierarchyData);
  console.log(treeData, "treeData");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        padding: "10px 10px",
      }}
    >
      <Title level={4} style={{ textAlign: "left", marginLeft: 10 }}>
        Your Team
      </Title>
      <Tree
        checkable
        showLine
        checkStrictly
        switcherIcon={<DownOutlined />}
        defaultExpandedKeys={defaultExpandedKeys}
        defaultCheckedKeys={checkedKeys} // Set initial checked keys
        treeData={treeData}
        titleRender={titleRender}
        onCheck={onCheck}
        checkedKeys={checkedKeys} // Use the state for checked keys
      />
      <Button
        type="primary"
        onClick={handleApply}
        style={{ marginTop: "10px" }}
      >
        Apply
      </Button>
    </div>
  );
};

export default UserHierarchy;
