// src/redux/store.js
import { configureStore } from "@reduxjs/toolkit";
import uiReducer from "./slices/uiSlice";
import authReducer from "./slices/authSlice";
import loadingReducer from "./slices/loadingSlice";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

const authPersistConfig = {
  key: "auth",
  storage,
  whitelist: ["user", "isAuthenticated", "userType"], // Persist userType as well
};

const persistedAuthReducer = persistReducer(authPersistConfig, authReducer);

const store = configureStore({
  reducer: {
    ui: uiReducer,
    auth: persistedAuthReducer,
    loading: loadingReducer,
  },
});

export const persistor = persistStore(store);
export default store;
