

import { message } from "antd";
import { updatePermissions } from "../slices/authSlice";
import { fetchUnreadCountSuccess } from "../slices/notificationsSlice";
import commonService from "../../services/commonService";

const commonInfoMiddleware = (store) => (next) => async (action) => {
  if (action.type === "FETCH_COMMON_INFO") {
    try {
      const response = await commonService.getCommonInfo();

      if (response.success) {
        const { permissions, notifications } = response.data.data;
        
        
        // Dispatch action to save permissions
        store.dispatch(updatePermissions(permissions));

        // Dispatch action to save notifications data
        store.dispatch(fetchUnreadCountSuccess({
          totalUnreadCount: notifications.total_unread_count,
          notificationsData: notifications.data,
        }));
        
      }
    } catch (error) {
      console.error("Failed to fetch common info:", error);
      message.error("Failed to fetch common info");
    }
  }

  return next(action);
};

export default commonInfoMiddleware;
