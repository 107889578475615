// MainLayout.js
import React from "react";
import { Layout, } from "antd";
import {
  BrowserRouter as Router,

} from "react-router-dom";
import Sidebar from "./Sidebar";
import HeaderContent from "./HeaderContent";

import { useSelector } from "react-redux";
import MainContent from "./MainContent";

export const MainLayout = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  

  return (
    <Router>
      <Layout hasSider={isAuthenticated}>
        {isAuthenticated ? <Sidebar /> : null}
        <Layout style={{ height: "100vh", background: "white" }}>
          <HeaderContent

            isAuthenticated={isAuthenticated}
   
          />

          <MainContent />
        </Layout>
      </Layout>
    </Router>
  );
};

