import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Typography } from "antd";

const { Text } = Typography;

const BarChartGraph = ({ data }) => {
  const [barSize, setBarSize] = useState(40); // Default bar size
  const windowWidth = window.innerWidth
  useEffect(() => {
    const updateBarSize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth < 768) {
        setBarSize(50); // Smaller bar size for mobile devices
      } else if (windowWidth < 1024) {
        setBarSize(30); // Medium bar size for tablets
      } else {
        setBarSize(40); // Default bar size for desktops
      }
    };

    window.addEventListener("resize", updateBarSize);
    updateBarSize(); // Initial call

    return () => window.removeEventListener("resize", updateBarSize);
  }, []);

  if (!data) {
    return null; // Or render a loading indicator
  }

  const uniqueMonths = [
    ...new Set(
      data.flatMap((item) =>
        Object.keys(item).filter((key) => key !== "currency")
      )
    ),
  ];
  const colors = ["#8884d8", "#82ca9d", "#ffc658", "#d08484"];

  // Calculate total volume for each currency
  const totalsByCurrency = data.reduce((acc, item) => {
    const currency = item.currency;
    const total = uniqueMonths.reduce(
      (sum, month) => sum + (item[month] || 0),
      0
    );
    acc[currency] = total;
    return acc;
  }, {});

  return (
    <div className="graphcont" >
      <ResponsiveContainer width={windowWidth < 768 ? '100%':'80%'} height={400}>
        <BarChart
          data={data}
          margin={{
            top: 20,
            
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="currency" />
          <YAxis />
          <Tooltip />
          <Legend />
          {uniqueMonths.map((month, index) => (
            <Bar
              key={index}
              dataKey={month}
              fill={colors[index % colors.length]}
              name={month}
              barSize={barSize}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
      <div style={{ marginLeft: "20px" }} className="volume">
        Total By Volume
        {Object.keys(totalsByCurrency).map((currency) => (
          <Text key={currency} type="success" strong style={{ display: "flex" , flexDirection:'row' }}>
         {totalsByCurrency[currency].toFixed(2)} {currency}
          </Text>
        ))}
      </div>
    </div>
  );
};

export default BarChartGraph;
