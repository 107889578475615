import React, { useState } from 'react'
import useIsMobile from '../../hooks/useIsMobile';
import { Button, DatePicker, Flex, Input, Select, Spin } from 'antd';
import { SearchOutlined } from "@ant-design/icons";
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

const formatFilterName = (name) => {
    return name.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
};

const FiltersAllocationAdjustment = ({
    filtersList,
    onSearch,
    loadOrders,
    statusList,
    // fetchingStatusList,
    subscribers,
    ownersData,

}) => {

    const { isMobile } = useIsMobile();

    const [filters, setFilters] = useState({
        key: "",
        status: 1,
        visibility: "",
        subscriber: "",
        source: "",
        start_date: "",
        end_date: "",
        client_deadline_start: "",
        client_deadline_end: "",
    });
    // console.log("filtersfilters", filters)
    const handleFilterChange = (key, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [key]: value,
        }));
    };

    const handleSearch = () => {
        const formattedFilters = {
            ...filters,
            start_date: filters.start_date
                ? dayjs(filters.start_date).format("YYYY-MM-DD")
                : null,
            end_date: filters.end_date
                ? dayjs(filters.end_date).format("YYYY-MM-DD")
                : null,
        };
        onSearch(formattedFilters);
    };

    const renderFilter = (filter) => {
        if (filter === "start_date") {
            return (
                <RangePicker
                    style={{ width: isMobile ? "80vw" : 300 }}
                    placeholder={["Start Date", "End Date"]}
                    value={[filters.start_date, filters.end_date]}
                    onChange={(dates) => {
                        handleFilterChange("start_date", dates ? dates[0] : null);
                        handleFilterChange("end_date", dates ? dates[1] : null);
                    }}
                />
            );
        }

        // if (filter === "end_date") {
        //     return null;
        // }

        if (filter === "client_deadline_start") {
            return (
                <RangePicker
                    style={{ width: isMobile ? "80vw" : 300 }}
                    placeholder={["Client Start Date", "Client End Date"]}
                    value={[filters.client_deadline_start, filters.client_deadline_end]}
                    onChange={(dates) => {
                        handleFilterChange("client_deadline_start", dates ? dates[0] : null);
                        handleFilterChange("client_deadline_end", dates ? dates[1] : null);
                    }}
                />
            );
        }
        // if (filter === "client_deadline_end") {
        //     return null;
        // }

        // Other filters remain unchanged
        switch (filter) {

            case "status":
                // const defaultStatus = statusList.status == undefined && 1;
                return (
                    <Select
                        // allowClear
                        style={{ width: isMobile ? "80vw" : 300 }}
                        placeholder="Filter by Status"
                        value={filters?.status || undefined}
                        onChange={(value) => handleFilterChange("status", value)}
                    >
                        {Object.entries(statusList).map(([label, value]) => (
                            <Select.Option key={value} value={value}>
                                {label}
                            </Select.Option>
                        ))}
                    </Select>
                );
            case "source":
                return (
                    <Select
                        allowClear
                        style={{ width: isMobile ? "80vw" : 300 }}
                        placeholder="Filter by Source"
                        value={filters.source || undefined}
                        onChange={(value) => handleFilterChange("source", value)}
                    >
                        <Select.Option value="manual">Manually Moved</Select.Option>
                        <Select.Option value="automatic">Automatically Added</Select.Option>
                    </Select>
                );
            case "visibility":
                return (
                    <Select
                        allowClear
                        mode="multiple"
                        showSearch
                        style={{ width: isMobile ? "80vw" : 300 }}
                        placeholder="Filter by Visibility"
                        value={filters.visibility || undefined}
                        onChange={(value) => handleFilterChange("visibility", value)}
                        optionFilterProp="children"
                    >
                        {ownersData.map(({ username, name }) => (
                            <Select.Option key={username} value={username}>
                                {name}
                            </Select.Option>
                        ))}
                    </Select>
                );
            case "subscriber":
                return (
                    <Select
                        allowClear
                        mode="multiple"
                        showSearch
                        style={{ width: isMobile ? "80vw" : 300 }}
                        placeholder="Filter by Subscribers"
                        value={filters.subscriber || undefined}  // This ensures the selected value is managed
                        onChange={(value) => handleFilterChange("subscriber", value || "")}  // Clear value will send an empty string
                        optionFilterProp="children"
                    >
                        {subscribers.map(({ username, name }) => (
                            <Select.Option key={username} value={username}>
                                {name}
                            </Select.Option>
                        ))}
                    </Select>
                );

            default:
                return (
                    <Input
                        placeholder={`Filter by ${formatFilterName(filter)}`}
                        style={{ width: 300 }}
                        value={filters[filter] || ""}
                        onChange={(e) => handleFilterChange(filter, e.target.value)}
                    />
                );
        }
    };


    const handleReset = async () => {
        setFilters({
            key: "",
            status: "", // reset to empty
            visibility: "",
            subscriber: "",
            source: "",
            start_date: "",
            end_date: "",
            client_deadline_start: "",
            client_deadline_end: "",
        });

        await loadOrders(); // reload data after reset
    };

    return (
        <div style={{ width: "100%", }}>
            <div
                style={{
                    background: "#eceff1",
                    padding: "20px",
                    borderRadius: "8px",
                }}
            >
                <div
                    style={{
                        marginBottom: 16,
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "20px",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                    }}
                >
                    {filtersList
                        .filter((filter) => (filter !== "client_deadline_end" && filter !== "end_date"))
                        .map((filter) => (
                            <div key={filter} style={{ textAlign: "left", marginRight: 16 }}>
                                {renderFilter(filter)}
                            </div>

                        ))
                    }

                    <div
                        style={{
                            display: "flex",
                            gap: "20px",
                            marginRight: isMobile ? 0 : "12px",
                            justifyContent: "space-between",
                            width: "auto",
                        }}
                    >
                        <Button
                            type="primary"
                            style={{ width: 140, background: "#040724" }}
                            icon={<SearchOutlined />}
                            onClick={handleSearch}
                        >
                            Search
                        </Button>
                        <Button style={{ width: 140 }} type="default" onClick={handleReset}>
                            Reset
                        </Button>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default FiltersAllocationAdjustment