import { Button, Col, Form, Input, Row, Typography } from "antd";
import { login } from "../../redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import loginImage from "../../img/login3.jpeg"; // Ensure this path is correct
import React from "react";

const { Title } = Typography;

const Login = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const next = queryParams.get("next");
  const { loading } = useSelector((state) => state.auth);

  const onFinish = async (values) => {
    const { success } = await dispatch(login(values));
    if (success) {
      navigate(next || "/");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Row
      style={{
        height: "100vh",
        backgroundImage: `url(${loginImage})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Col
        xs={24}
        sm={24}
        md={12}
        lg={8}
        style={{
          padding: "20px 18px",
          borderRadius: "12px",
          boxShadow: "rgba(0, 0, 0, 0.4) 10px 4px 12px",
          maxWidth: "400px",
          width: "100%",
          textAlign: "center",
          background: "white",
        }}
      >
        {/* Title */}
        {/* <Title level={2} style={{ marginBottom: "1.5rem", textAlign: "left" }}>
          Locus RAGS
        </Title> */}

        {/* Form */}
        <Form
          name="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          style={{
            textAlign: "start",
          }}
        >
          <Form.Item
            style={{
              textAlign: "center",
            }}
          >
            <Title level={2}>Sign in</Title>
          </Form.Item>

          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Email"
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item style={{ textAlign: "end", marginBottom: "1rem" }}>
            <Link to="/reset-password">Forgot password?</Link>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%", background: "black" }}
              loading={loading}
            >
              Log in
            </Button>
            <div style={{ marginTop: "1rem" }}>
              Or <Link to="/sign-up">register now!</Link>
            </div>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default Login;
