import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Flex,
  Card,
  Typography,
  Tag,
  Input,
  Popover,
  message,
  Spin,
} from "antd";
import { EyeOutlined } from "@ant-design/icons";
import orderService from "../../services/OrderLists";
import useIsMobile from "../../hooks/useIsMobile";

const { Text, Title } = Typography;

const TextAnalysis = ({ data }) => {
  // Check if data and output_json are available
  if (!data?.output_json) {
    return <Text>No Data Available</Text>;
  }

  const { input_text, exact_ranges } = data.output_json;

  const renderHighlightedText = () => {
    let lastIndex = 0;
    const elements = [];

    exact_ranges.forEach(([start, end], i) => {
      if (start > lastIndex) {
        elements.push(
          <Text key={`text-${i}-${lastIndex}`}>
            {input_text.substring(lastIndex, start)}
          </Text>
        );
      }
      elements.push(
        <Text
          key={`highlight-${i}`}
          style={{
            color: "red",
            padding: "0 2px",
            display: "inline-block",
          }}>
          {input_text.substring(start, end)}
        </Text>
      );
      lastIndex = end;
    });

    if (lastIndex < input_text.length) {
      elements.push(
        <Text key="text-final">{input_text.substring(lastIndex)}</Text>
      );
    }

    return elements;
  };

  return (
    <Card
      style={{ width: "100%" }}
      title={
        <Flex gap="middle" align="center">
          <Title level={5} style={{ margin: 0 }}>
            Text Analysis
          </Title>
          <Tag color="blue">
            Match: {data.output_json.avg_percent_match?.toFixed(2)}%
          </Tag>
          <Tag color="green">Total Words: {data.output_json.total_words}</Tag>
          <Tag color="purple">
            Matched Words: {data.output_json.avg_words_match}
          </Tag>
        </Flex>
      }
      className="mb-4">
      <div style={{ maxHeight: "400px", overflow: "auto", padding: "12px" }}>
        {renderHighlightedText()}
      </div>
    </Card>
  );
};

const MatchedDocuments = ({ data }) => {
  const isMobile = useIsMobile();
  const truncateTitle = (title, maxLength = 30) =>
    title?.length > maxLength ? `${title.slice(0, maxLength)}...` : title;
  return data?.output_json.data?.map((source, index) => (
    <Card
      key={source.uid}
      size="small"
      style={{
        width: "100%",
        maxWidth: isMobile ? "max-content" : "850px",
        maxHeight: isMobile ? "max-content" : "400px",
        overflowY: "scroll",
      }}
      title={
        <Flex gap="middle" align="center" style={{ maxWidth: "100px" }}>
          <Popover content={source.metadata.title} placement="top">
            <Text strong>
              Source {index + 1}: {truncateTitle(source.metadata.title)}
            </Text>
          </Popover>

          <Tag color="blue">
            Match: {source.z_calc.match_percent.toFixed(2)}%
          </Tag>
          <Tag color="cyan">Order ID: {source.metadata.order_id}</Tag>
        </Flex>
      }>
      {source?.matches?.map((match, matchIndex) => (
        <Card
          key={matchIndex}
          size="small"
          className="mb-2"
          bordered={false}
          style={{ width: "100%" }}>
          <Flex vertical gap="small" style={{ width: "100%" }}>
            <Flex gap="small">
              <Tag color="green">Words: {match.words}</Tag>
            </Flex>
            <Text>
              <span
                style={{
                  backgroundColor: "#ffd591",
                  padding: "2px 4px",
                  borderRadius: "2px",
                  display: "inline-block",
                  lineHeight: "1.6",
                }}>
                {match?.match}
              </span>
            </Text>
          </Flex>
        </Card>
      ))}
    </Card>
  ));
};

export default function DupliTextDetail({ uid }) {
  const [submissionDetail, setSubmissionDetail] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useIsMobile();
  useEffect(() => {
    handleFetchSubmissionDetail(uid);
  }, [uid]);
  const handleFetchSubmissionDetail = async (uid) => {
    try {
      setIsLoading(true);
      const response = await orderService.duplitextSubmissionDetail(uid);
      if (response.success) {
        setIsLoading(false);
        setSubmissionDetail(response?.data?.data);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <Flex
      vertical
      align="flex-end"
      style={{ width: "100%", overflowY: "scroll", height: "500px" }}
      gap={16}>
      {isLoading ? (
        <Flex
          vertical
          align="center"
          justify="center"
          style={{ width: "100%", height: "100%" }}>
          <Spin />
        </Flex>
      ) : (
        <Flex
          vertical
          style={{
            width: isMobile ? "80vw" : "100%",
            overflowY: "scroll",
          }}
          justify="center"
          gap={10}
          align="flex-end">
          <Flex
            vertical
            style={{ width: "100%", overflowY: "scroll" }}
            justify="flex-start"
            align="flex-start"
            gap={10}>
            <TextAnalysis data={submissionDetail} />
            {submissionDetail?.output_json?.data?.length > 0 && (
              <Flex
                vertical
                style={{ width: "100%" }}
                justify="flex-start"
                align="flex-start"
                gap={10}>
                <Title style={{ textAlign: "left" }} level={5}>
                  Matched Documents
                </Title>
                <MatchedDocuments data={submissionDetail} />
              </Flex>
            )}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}
