import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Form, Input, Select, Button, Upload, message, Checkbox, Collapse } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import axiosInstance from '../../services/axiosInstance';
import { catchError } from '../../utils/errorHandler';

const { Paragraph } = Typography;
const { Option } = Select;
const { Dragger } = Upload;
const { Panel } = Collapse;

const AddRefund = ({ handleModalClose, fetchOrderDetails }) => {
    const { orderKey } = useParams();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [showFullText, setShowFullText] = useState(false);

    const handleRefundTypeChange = (value) => {
        form.setFieldsValue({ refund_mode: value });
    };

    const handleFileChange = ({ fileList: newFileList }) => {
        setFileList(newFileList.slice(-1));
    };

    const handleSubmit = async (values) => {
        const { acceptedGuidelines, receipt, ...filteredValues } = values;

        const formData = new FormData();

        Object.entries(filteredValues).forEach(([key, value]) => {
            formData.append(key, value);
        });

        if (receipt && receipt.length > 0) {
            formData.append('receipt', receipt[0].originFileObj);
        }

        setLoading(true);
        try {
            const response = await axiosInstance.post(`task/v2/refund-add/${orderKey}/`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            if (response.status === 200 && response.data?.status === "success") {
                message.success("Refund added successfully");
                handleModalClose();
                fetchOrderDetails();
            }
        } catch (err) {
            await catchError(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ paddingRight: '16px' }}>

            <Collapse defaultActiveKey={['1']} style={{ marginBottom: '24px' }}>
                <Panel header="Refund Policy Guidelines" key="1">
                    <div>
                        <Typography>
                            <Paragraph style={{ fontSize: '14px', lineHeight: '1.8', color: '#555' }}>
                                {showFullText ? (
                                    <>
                                        <p>At Locus Assignments, we value customer satisfaction and aim to provide a transparent and fair refund policy. Please review the following guidelines regarding refunds:</p>
                                        <strong>1. Timely Processing:</strong> Refunds, if approved, will be processed within 7 days of initiation, regardless of the reason for the request.
                                        <br /><br />
                                        <strong>2. Non-Delivery:</strong> In the event that an order is not delivered within the specified timeframe, a refund will only be considered upon submission of evidence demonstrating the non-receipt of the order as well as evidence of missing deadline.
                                        <br /><br />
                                        <strong>3. Performance Guarantee:</strong>  If an order fails to meet the customer's expectations in terms of achieving desired results, a refund will only be approved upon submission of a marked copy of the assignment along with relevant tutor feedback.
                                        <br /><br />
                                        <strong>4. Cancellation Policy:</strong> Refunds will not be issued if a customer requests to cancel an order after it has been moved to the "In Progress" or "Feedback" stage.
                                        <br /><br />
                                        <strong>5. Refund Initiation Deadline:</strong> To be eligible for a refund, the request must be initiated within 90 days of receiving the results.
                                        <br /><br />
                                        Please note that our refund policy is subject to periodic review and may be updated without prior notice. For any inquiries or assistance regarding refunds, please contact our customer support team at <a href="mailto:support@locusassignments.com">support@locusassignments.com</a>.
                                        <br /><br />
                                        Thank you for choosing Locus Assignments. Your satisfaction is our priority.
                                    </>
                                ) : (
                                    <>
                                        <p>At Locus Assignments, we value customer satisfaction and aim to provide a transparent and fair refund policy. Please review the following guidelines regarding refunds:</p>
                                        <Button
                                            type="link"
                                            onClick={() => setShowFullText(!showFullText)}
                                            style={{ padding: 0, fontSize: '14px', color: '#1890ff', fontWeight: 'bold' }}
                                        >
                                            Show More
                                        </Button>
                                    </>
                                )}
                            </Paragraph>
                        </Typography>
                    </div>
                </Panel>
            </Collapse>

            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{
                    refund_mode: "Wallet",
                    acceptedGuidelines: false,
                }}
            >
                <Form.Item
                    style={{ width: "100%" }}
                    label="Type"
                    name="refund_mode"
                    rules={[{ required: true, message: 'Please select a refund type' }]}
                >
                    <Select onChange={handleRefundTypeChange}>
                        <Option value="Wallet">Wallet</Option>
                        <Option value="MoneyBack">Money Back</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    style={{ width: "100%" }}
                    label="Amount"
                    name="refund_amount"
                    rules={[
                        { required: true, message: 'Please enter the refund amount' },
                        { pattern: /^\d+(\.\d{1,2})?$/, message: 'Enter a valid amount' },
                    ]}
                >
                    <Input placeholder="Enter refund amount" />
                </Form.Item>

                <Form.Item
                    style={{ width: "100%" }}
                    label="Receipt"
                    name="receipt"
                    valuePropName="fileList"
                    getValueFromEvent={({ fileList }) => fileList}
                >
                    <Dragger
                        fileList={fileList}
                        beforeUpload={() => false}
                        onChange={handleFileChange}
                        maxCount={1}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p>Click or drag file to this area to upload</p>
                    </Dragger>
                </Form.Item>

                <Form.Item
                    style={{ width: "100%" }}
                    label="Reason"
                    name="reason"
                    rules={[{ required: true, message: 'Please provide a reason for the refund' }]}
                >
                    <Input.TextArea rows={3} placeholder="Enter refund reason" />
                </Form.Item>

                <Form.Item
                    style={{ width: "100%" }}
                    name="acceptedGuidelines"
                    valuePropName="checked"
                    rules={[
                        {
                            validator: (_, value) =>
                                value ? Promise.resolve() : Promise.reject(new Error('Please accept the guidelines to proceed')),
                        },
                    ]}
                >
                    <Checkbox>
                        I have read and agree to the refund policy guidelines
                    </Checkbox>
                </Form.Item>

                <Form.Item
                    style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                    <Button type="primary" htmlType="submit" loading={loading} block>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default AddRefund;
