import React from "react";
import { Card, Descriptions, Tag, Typography, Pagination, Flex } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";

const { Paragraph } = Typography;

const MobileViewMovedToFLAssociates = ({
  moveToFLassociatesOrderList,
  pagination,
  setPagination,
}) => {
  const statusColors = {
    Unassigned: "#F0F0F0", // Lighter Gray
    "Assigned to manager": "#C0C0C0", // Lighter Dark Gray
    "In Progress": "#D0E4F2", // Lighter Blue
    "Quality Check": "#A9C3E0", // Lighter Medium Blue
    Completed: "#C8E6C8", // Lighter Light Green
    Delivered: "#8FCF8F", // Lighter Medium Green
    Rejected: "#E8A0A0", // Lighter Soft Red
    Cancelled: "#A0A0A0", // Lighter Dark Gray
    Failed: "#D09090", // Lighter Darker Red
    Feedback: "#C2A892", // Lighter Muted Brown
  };
  return (
    <Flex vertical style={{ padding: "10px" }}>
      {moveToFLassociatesOrderList.map((deduction) => (
        <Card
          key={deduction.key}
          style={{ marginBottom: "16px", borderRadius: "8px" }}>
          <Descriptions column={1} bordered>
            <Descriptions.Item label="Key">
              <Link
                to={`/order-management/my-orders/${deduction.key}`}
                style={{ color: "#1890ff" }}>
                {deduction.key}
              </Link>
            </Descriptions.Item>

            {Object.entries(deduction).map(([key, value]) => {
              if (["uid", "id", "added_on", "key", "tags"].includes(key))
                return null; // Skip unwanted keys

              let displayValue = value;

              if (key === "key") {
                displayValue = (
                  <Link
                    to={`/order-management/my-orders/${deduction.key}`}
                    style={{ color: "#1890ff" }}>
                    {deduction.key}
                  </Link>
                );
              }
              if (key === "status") {
                displayValue = (
                  <Tag
                    color={statusColors[value] || "default"}
                    style={{ fontWeight: 500 , color:'black' }}>
                    {value}
                  </Tag>
                );
              } else if (key === "remaining_words") {
                displayValue = <Tag color="purple">{value}</Tag>;
              } else if (key === "moved_to_fl") {
                displayValue = deduction.moved_to_fl ? (
                  <Typography.Text style={{ fontWeight: 600 }}>
                    Yes
                  </Typography.Text>
                ) : (
                  <Typography.Text style={{ fontWeight: 600 }}>
                    No
                  </Typography.Text>
                );
              } else if (key === "feedback_due") {
                displayValue = deduction.feedback_due ? (
                  <Typography.Text style={{ fontWeight: 600 }}>
                    Yes
                  </Typography.Text>
                ) : (
                  <Typography.Text style={{ fontWeight: 600 }}>
                    No
                  </Typography.Text>
                );
              } else if (key === "deducted_word") {
                displayValue = <Tag color="red">{value}</Tag>;
              } else if (key === "fl_deadline" && deduction.fl_deadline) {
                displayValue = (
                  <div>
                    <div style={{ fontSize: "12px", color: "#888" }}>
                      {moment(deduction.fl_deadline)
                        .local()
                        .format("DD-MM-YYYY hh:mm a")}
                    </div>
                  </div>
                );
              } else if (
                key === "move_to_fl_date" &&
                deduction.move_to_fl_date
              ) {
                displayValue = (
                  <div>
                    <div style={{ fontSize: "12px", color: "#888" }}>
                      {moment(deduction.move_to_fl_date)
                        .local()
                        .format("DD-MM-YYYY hh:mm a")}
                    </div>
                  </div>
                );
              } else if (key === "start_date" && deduction.start_date) {
                displayValue = (
                  <div>
                    <div style={{ fontSize: "12px", color: "#888" }}>
                      {moment(deduction.start_date)
                        .local()
                        .format("DD-MM-YYYY hh:mm a")}
                    </div>
                  </div>
                );
              } else if (
                key === "client_deadline" &&
                deduction.client_deadline
              ) {
                displayValue = (
                  <div>
                    <div style={{ fontSize: "12px", color: "#888" }}>
                      {moment(deduction.client_deadline)
                        .local()
                        .format("DD-MM-YYYY hh:mm a")}
                    </div>
                  </div>
                );
              } else if (value === null || value === undefined) {
                displayValue = "N/A";
              }

              return (
                <Descriptions.Item
                  label={key
                    .replace(/_/g, " ")
                    .replace(/\b\w/g, (char) => char.toUpperCase())}
                  key={key}>
                  {displayValue}
                </Descriptions.Item>
              );
            })}
          </Descriptions>
        </Card>
      ))}
     <Pagination
  current={pagination.current}
  pageSize={pagination.pageSize}
  total={pagination.total}
  onChange={(page, pageSize) => {
    console.log("Page changed:", page); // Debugging
    setPagination((prev) => ({ ...prev, current: page, pageSize }));
  }}
  style={{ textAlign: "center", marginTop: 16 }}
/>

    </Flex>
  );
};

export default MobileViewMovedToFLAssociates;
