import React, { useEffect, useCallback, useState } from "react";
import clientService from "../../services/clientService";
import AllClientsTableSummary from "./AllClientsTableSummary";
import { Flex, Select, Button, message } from "antd";
import SearchFilters from "./SearchFilters";
import { Typography, Switch } from "antd";
import AddClientModal from "./AddClientModal";
import { CSSTransition } from "react-transition-group";
import { FilterOutlined } from "@ant-design/icons";
import "./ClientParent.css";
import usePermissions from "../../hooks/usePermissions";
function AllClientsParent() {
  const { hasPermission } = usePermissions();
  const { Option } = Select;
  const { Paragraph } = Typography;
  const [clients, setClients] = useState([]);
  const [filtersList , setFiltersList] = useState([])
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [websites, setWebsites] = useState([]);
  const [showFilters, setShowFilters] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
    showSizeChanger: false,
  });
  const [filters, setFilters] = useState({});
  const [viewMode, setViewMode] = useState("myClients");



  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleModalClose = () => {
    setIsModalVisible(false);
  };
  const getAllClients = useCallback(
    async (page = 1, pageSize = 20) => {
      setLoading(true);
      const result = await clientService.getAllClients(page, pageSize, filters);

      if (result.success) {
        setFiltersList(result?.data?.data?.filters)

        setClients(result.data.data.results);
        setPagination({
          ...pagination,
          total: result?.data?.data?.count || 0,
          current: page,
          pageSize,
        });
      }
      setLoading(false);
    },
    [pagination, filters]
  );

  const fetchWebsites = async () => {
    const result = await clientService.getWebsitesList();
    if (result) {
      setWebsites(result.data.data);
    } else {
      message.error("Failed to load websites");
    }
  };
  const getMyClients = useCallback(
    async (page = 1, pageSize = 20) => {
      setLoading(true);
      const result = await clientService.getMyClients(page, pageSize, filters); // Fetch only my clients

      if (result.success) {
        setFiltersList(result?.data?.data?.filters)
        
        setClients(result.data.data.results);
        setPagination({
          ...pagination,
          total: result?.data?.data?.count || 0,
          current: page,
          pageSize,
        });
      }
      setLoading(false);
    },
    [pagination, filters]
  );

  // Fetch clients based on view mode (My Clients or All Clients)
  useEffect(() => {
    fetchWebsites();
    if(showFilters){
  
    }
    if (viewMode === "allClients") {
      getAllClients(pagination.current, pagination.pageSize);
    } else {
      getMyClients(pagination.current, pagination.pageSize);
    }
  }, [viewMode, pagination.current, pagination.pageSize, filters]);

  const handleSearchClick = (newFilters) => {
    setFilters(newFilters);
    setPagination({ ...pagination, current: 1 }); // Reset to first page when applying new filters
  };

  const handleCancelSearch = async () => {
    setFilters({});
    if (viewMode === "allClients") {
      await getAllClients(1, pagination.pageSize);
    } else {
      await getMyClients(1, pagination.pageSize);
    }
    setPagination({ ...pagination, current: 1 });
  };

  // Handle switching between "My Clients" and "All Clients"
  const handleViewModeChange = (value) => {
    setShowFilters(false)
    const mode =
      value === true || value === "myClients" ? "myClients" : "allClients";

    setViewMode(mode);
    setPagination({ ...pagination, current: 1 }); // Reset pagination when view mode changes
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  return (
    <Flex vertical style={{ padding: "30px 20px" }}>
      {showFilters && (
        <CSSTransition
          in={showFilters}
          timeout={300}
          classNames="filter"
          unmountOnExit
        >
          <div className="filter-container">
            <SearchFilters
              filtersList={filtersList}
              onSearch={handleSearchClick}
              pagination={pagination}
              getAllClients={handleCancelSearch}
            />
          </div>
        </CSSTransition>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Flex style={{ padding: "10px 0px", margin: 0 }} align="flex-end">
          <Paragraph
            style={{
              fontWeight: 500,
              opacity: 0.6,
              textAlign: "left",
              margin: 0,
              padding: 0,
            }}
          >
            Showing{" "}
            {Math.min(
              (pagination.current - 1) * pagination.pageSize + 1,
              pagination.total
            )}{" "}
            -
            {Math.min(
              pagination.current * pagination.pageSize,
              pagination.total
            )}{" "}
            of {pagination.total} Clients
          </Paragraph>
        </Flex>

        <Flex align="center" style={{ margin: "10px 0px" }}>
          {hasPermission("can_view_all_clients") && (
            <Select
              value={viewMode}
              onChange={handleViewModeChange}
              style={{ width: 150, textAlign: "left", marginLeft: "10px" }}
            >
              <Option value="myClients">My Clients</Option>
              <Option value="allClients">All Clients</Option>
            </Select>
          )}
          {hasPermission("can_create_client") && (
            <Button
              type="primary"
              onClick={showModal}
              style={{ marginLeft: "10px", background: "#040724" }}
            >
              {" "}
              Add Client{" "}
            </Button>
          )}

          <Button
            type="primary"
            icon={<FilterOutlined />}
            onClick={toggleFilters}
            style={{ marginLeft: "10px", background: "#040724" }}
          />
        </Flex>
      </div>

      {/* Clients Table Summary */}
      <AllClientsTableSummary
        allclients={clients}
        loading={loading}
        pagination={pagination}
        setPagination={setPagination}
      />
      <AddClientModal
        visible={isModalVisible}
        onClose={handleModalClose}
        websites={websites}
      />
    </Flex>
  );
}

export default AllClientsParent;
