import React, { useState } from "react";
import { Tabs, Button, Switch, Input, Popover, Badge, Select } from "antd";
import { Check, CheckCheck, Search } from "lucide-react";
import { useMediaQuery } from "react-responsive";
const NotificationsTabs = ({
  activeTab,
  onTabChange,
  onShowUnreadToggle,
  showUnread,
  onMarkAllAsRead,
  categories,
  onSearch, // Calls handleSearch from parent
  levelCounts,
  searchValue,
  setSearchValue,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleSearchClick = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  const handleSubmitSearch = () => {
    if (searchValue.trim()) {
      onSearch(searchValue); // Call parent search function
      setIsPopoverOpen(false); // Close popover
    }
  };
  const activeTabColor =
    categories.find((category) => category.key === activeTab)?.color || "red";

  return (
    <>
      {isMobile ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 8,
            marginBottom: 16,

            width: "100%",
          }}>
          {/* Dropdown for selecting tab */}
          <Select
            value={activeTab} // Ensure it reflects the current activeTab
            onChange={onTabChange}
            style={{ width: 170, textAlign: "left", padding: 0 }}>
            {categories.map((category) => {
              const categoryCount =
                levelCounts.find((item) => item.level === category.key)
                  ?.count || 0; // No conversion
              return (
                <Select.Option
                  key={category.key}
                  value={category.key}
                  style={{
                    backgroundColor: category.color, // Set the background color dynamically

                    color: "black", // Optional: Set text color for better contrast
                  }}>
                  {category.label} ({categoryCount})
                </Select.Option>
              );
            })}
          </Select>

          {/* Actions - Unread switch, Mark all read, Search */}
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Switch
              checked={showUnread}
              checkedChildren="Unread"
              unCheckedChildren="Unread"
              onChange={onShowUnreadToggle}
            />
            <Button
              style={{ padding: 0 }}
              type="link"
              icon={<CheckCheck />}
              onClick={onMarkAllAsRead}>
              {" "}
            </Button>
            <Popover
              content={
                <div
                  style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                  <Input
                    autoFocus
                    placeholder="Search..."
                    onPressEnter={handleSubmitSearch}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    style={{ padding: "8px", fontSize: "16px", width: "200px" }}
                  />
                </div>
              }
              trigger="click"
              open={isPopoverOpen}
              onOpenChange={setIsPopoverOpen}
              placement="bottomRight">
              <Button
                style={{ padding: 0 }}
                type="link"
                icon={<Search />}
                onClick={handleSearchClick}
              />
            </Popover>
          </div>
        </div>
      ) : (
        <Tabs
          style={{ width: "100%" }}
          activeKey={activeTab}
          onChange={onTabChange}
          tabBarStyle={{ marginBottom: 16 }}
          tabBarExtraContent={
            <div style={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Switch
                checked={showUnread}
                checkedChildren="Unread"
                unCheckedChildren="Unread"
                onChange={onShowUnreadToggle}
              />

              <Button
                type="link"
                icon={<CheckCheck />}
                onClick={onMarkAllAsRead}>
                Mark all as read
              </Button>

              {/* Search Popover */}
              <Popover
                content={
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                      transition: "opacity 0.3s ease-in-out",
                    }}>
                    <Input
                      autoFocus
                      placeholder="Search..."
                      onPressEnter={handleSubmitSearch} // Call search on Enter press
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      style={{
                        padding: "8px",
                        fontSize: "16px",
                        width: "200px",
                      }}
                    />
                    {/* <Button
                  style={{borderRadius:'100%' , background:'#040724'}}
                    type="primary"
                    icon={<Check  color="black"/>}
                    onClick={handleSubmitSearch}
                  /> */}
                  </div>
                }
                trigger="click"
                open={isPopoverOpen}
                onOpenChange={setIsPopoverOpen}
                placement="bottomRight" // Positions below the search icon
              >
                <Button
                  type="link"
                  icon={<Search />}
                  onClick={handleSearchClick}
                />
              </Popover>
            </div>
          }>
          {categories.map((category) => {
            const categoryCount =
              levelCounts.find((item) => item.level === category.key)?.count ||
              0;

            return (
              <Tabs.TabPane
                key={category.key}
                tab={
                  <Badge
                    count={categoryCount} // Dynamically set the count
                    offset={[10, 0]}
                    size="large"
                    title=""
                    showZero>
                    <span
                      style={{
                        color: "black",
                        padding: "8px 16px",
                        borderRadius: "8px 8px 0 0",
                        backgroundColor: category.color,
                        display: "inline-block", // Ensure proper layout
                      }}>
                      {category.label}
                    </span>
                  </Badge>
                }
              />
            );
          })}
        </Tabs>
      )}
    </>
  );
};

export default NotificationsTabs;
