import React, { useState, useEffect } from "react";
import {
  Select,
  Alert,
  Typography,
  Descriptions,
  Flex,
  Button,
  Spin,
  message,
} from "antd";
import orderService from "../../services/OrderLists";
import { catchError } from "../../utils/errorHandler";

const { Option } = Select;
const { Text } = Typography;

const UpdateWriterStatus = ({
  orderData,
  handleModalClose,
  fetchOrderDetails,
}) => {
  const [statusOptions, setStatusOptions] = useState([]);
  const [runningTasks, setRunningTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [statusMap, setStatusMap] = useState({});
  const [submitting, setSubmitting] = useState(false); // Track submission loading state
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch status options
        const statusResponse = await orderService.getWriterStatusDropdown();
        let statusMapData = {};
        let foundStatusKey = "not_started";

        if (
          statusResponse.success &&
          Array.isArray(statusResponse.data.data) &&
          statusResponse.data.data.length > 0
        ) {
          setStatusOptions(statusResponse.data.data);
          statusMapData = statusResponse.data.data.reduce((acc, curr) => {
            acc[curr.label] = curr.key;
            return acc;
          }, {});

          // Find the correct status key for the main order status
          if (orderData?.data?.writer_status) {
            foundStatusKey =
              statusMapData[orderData.data.writer_status] || "not_started";
          }
        }

        setStatusMap(statusMapData);
        setSelectedStatus(foundStatusKey);

        // Fetch running tasks
        const taskResponse = await orderService.getRunningTask(
          orderData?.data?.key
        );
        if (
          taskResponse.success &&
          Array.isArray(taskResponse.data.data) &&
          taskResponse.data.data.length > 0
        ) {
          const updatedTasks = taskResponse.data.data.map((task) => ({
            key: task.key,
            statusKey: statusMapData[task.status] || "not_started",
          }));
          setRunningTasks(updatedTasks);
        } else {
          setRunningTasks([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [orderData]);

  // Handle task status change
  const handleTaskStatusChange = (taskKey, value) => {
    setRunningTasks((prevTasks) =>
      prevTasks.map((task) =>
        task.key === taskKey ? { ...task, statusKey: value } : task
      )
    );
  };

  // Handle form submission
  const handleSubmit = async () => {
    setSubmitting(true); // Show loading on submit button
    const submissionData = [
      { key: orderData?.data?.key, status: selectedStatus }, // Main order status
      ...runningTasks.map((task) => ({
        key: task.key,
        status: task.statusKey,
      })),
    ];

    try {
      // // Call updateWriterTask API
      const response = await orderService.updateWriterTask(submissionData);

      if (response.success) {
        handleModalClose();
        await fetchOrderDetails();
        message.success("Status Updated Successfully");
        // Optionally show success message or refresh data
      }
    } catch (error) {
      catchError(error);
    } finally {
      setSubmitting(false); // Hide loading indicator
    }
  };

  return (
    <div>
      {/* General Status Selection */}
      <Select
        style={{ width: "100%", marginBottom: 20 }}
        value={selectedStatus}
        onChange={(value) => setSelectedStatus(value)}
        loading={loading}
        placeholder={loading ? "Fetching statuses..." : "Choose Status"}
        disabled={loading || statusOptions.length === 0}>
        {statusOptions.map((option) => (
          <Option key={option.key} value={option.key}>
            {option.label}
          </Option>
        ))}
      </Select>

      {/* Show Loading State */}
      {loading ? (
        <div style={{ textAlign: "center", marginBottom: 20 }}>
          <Spin size="large" />
        </div>
      ) : runningTasks.length > 0 ? (
        <>
          {/* Info Message */}
          <Alert
            message="Please set the active order to Not Started or Paused before starting the current one."
            type="info"
            showIcon
            style={{ marginBottom: 20 }}
          />

          {/* Running Tasks */}
          <Descriptions bordered column={1}>
            {runningTasks.map((task, index) => (
              <React.Fragment key={index}>
                <Descriptions.Item label="Order Key">
                  <Text style={{ fontWeight: 600 }}>{task.key || "N/A"}</Text>
                </Descriptions.Item>
                <Descriptions.Item label={`Running Task ${index + 1}`}>
                  <Select
                    style={{ width: "100%", marginTop: 5 }}
                    value={task.statusKey}
                    onChange={(value) =>
                      handleTaskStatusChange(task.key, value)
                    }
                    loading={loading}
                    placeholder={
                      statusOptions.length > 0
                        ? "Choose Status"
                        : "No statuses available"
                    }
                    disabled={statusOptions.length === 0}>
                    {statusOptions.map((option) => (
                      <Option key={option.key} value={option.key}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                </Descriptions.Item>
              </React.Fragment>
            ))}
          </Descriptions>
        </>
      ) : null}

      {/* Submit Button */}
      <Flex
        gap={6}
        justify="flex-end"
        align="flex-end"
        style={{ width: "100%", marginTop: 17 }}>
        <Button
          type="primary"
          onClick={handleSubmit}
          loading={submitting}
          disabled={loading || statusOptions.length === 0}>
          Submit
        </Button>
      </Flex>
    </div>
  );
};

export default UpdateWriterStatus;
