import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Table,
  Typography,
  message,
  Flex,
  Grid,
} from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import orderService from "../../services/OrderLists";
import {
  MessageOutlined,
  PhoneOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import commonService from "../../services/commonService";
import OrderCallModal from "../../page-components/OrderDetail/OrderCallModal";
import axiosInstance from "../../services/axiosInstance";
import { catchError } from "../../utils/errorHandler";
import usePermissions from "../../hooks/usePermissions";
import CarouselButton from "../../components/CarouselButton";
import EnabledCountriesModal from "../../components/EnabledCountriesModal ";
import hierarchyService from "../../services/hierarchyService";
const { TextArea } = Input;
const { Option } = Select;
const { useBreakpoint } = Grid;

const ClientConnect = () => {
  const [form] = Form.useForm();
  const { hasPermission } = usePermissions();

  const [twilioNumbers, setTwilioNumbers] = useState([]);
  const [isCallModalVisible, setIsCallModalVisible] = useState(false);
  const [uid, setUid] = useState("");
  const [phoneNo, setPhoneNumber] = useState("");
  const [currentConnection, setCurrentConnection] = useState(null);
  const intervalRef = useRef(null);
  const [callDuration, setCallDuration] = useState(0);
  const [loading, setLoading] = useState(false);
  const [callLogMessages, setCallLogMessages] = useState([]);
  const [isEnabledCountriesModalVisible, setIsEnabledCountriesModalVisible] =
    useState(false);
  const [smsForm, setSmsForm] = useState({
    uid: "",
    phone_no: "",
    message: "",
  });

  const fetchTwilioNumbers = async () => {
    const response = await orderService.getTwilioNumberAndUid();
    if (response.success) {
      setTwilioNumbers(response?.data);
    }
  };

  const [activityLoading, setActivityLoading] = useState(false);

  const fetchActivityLogsDirect = async () => {
    setActivityLoading(true);
    try {
      const response = await axiosInstance.get("/tc/api/activity-logs/");
      if (response.status && response.data.status) {
        setCallLogMessages(response?.data?.data?.results || []);
        setActivityLoading(false);
      }
    } catch (err) {
      console.log("err", err);
      setActivityLoading(false);
    }
  };

  useEffect(() => {
    fetchTwilioNumbers();
    fetchActivityLogsDirect();
  }, []);

  const startTimer = () => {
    intervalRef.current = setInterval(() => {
      setCallDuration((prev) => {
        const newDuration = prev + 1;
        console.log(`Call duration: ${newDuration} seconds`);
        return newDuration;
      });
    }, 1000);
  };

  const handleHangUp = useCallback(() => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    setCallDuration(0);
    setIsCallModalVisible(false);
    if (currentConnection) currentConnection.disconnect();
  }, [currentConnection]);

  const doMakeTwilioCall = async (phoneNo, uid) => {
    const device = await commonService.initializeTwilioDeviceForClient();
    if (!device) return;

    try {
      const params = {
        phone_no: phoneNo,
        uid: uid,
      };

      console.log("Sending to Twilio device.connect:", params);

      const connection = await device.connect({ params });

      connection.on("accept", () => {
        console.log("Call accepted, conversation started");
      });

      return connection;
    } catch (error) {
      console.error("Error making call:", error);
      throw error;
    }
  };

  const handleCall = useCallback(async () => {
    try {
      // ✅ Step 1: Check Twilio Balance
      const balanceResponse = await commonService.getTwillioBalanceStatus();
      // if (!balanceResponse.success) {
      //   message.error("Failed to fetch balance status.");
      //   return;
      // }
      // const balanceMessage = balanceResponse.data.message; // ✅ This is where balance message is
      // const lowBalance = balanceResponse.data.low_balance;

      const { low_balance, message: balanceMessage } =
        balanceResponse.data.data;

      if (low_balance) {
        message.warning(balanceMessage);
        return;
      }


      try{
         await navigator.mediaDevices.getUserMedia({ audio: true });
        message.success("Microphone access granted.");
       }catch(error){
         throw new Error("Microphone access is not allowed.",error);
      }

      // if (!stream) {
      //   throw new Error("Microphone access is not allowed.");
      // }else{
      //   message.success("Microphone access granted.");
      // }
      const selectedPhone = twilioNumbers.find(
        (number) => number.uid === uid
      )?.phone_no;

      if (!selectedPhone) {
        message.error("Invalid phone number selected.");
        return;
      }

      if (!phoneNo) {
        message.error("Please enter a phone number before making the call.");
        return;
      }
      setIsCallModalVisible(true);

      console.log("Initiating Twilio call...");
      const connection = await doMakeTwilioCall(phoneNo, uid);

      setCurrentConnection(connection);

      connection.on("statusChange", (status) => {
        console.log(`Call status changed to: ${status}`);
        // Handle call status change
        if (status === "ringing") {
          const timeoutId = setTimeout(() => {
            // Timeout handling for unanswered calls
            if (connection.status === "ringing") {
              console.log("Call not answered in time, hanging up...");
              handleHangUp();
            }
          }, 30000); // Timeout after 30 seconds
          connection.timeoutId = timeoutId; // Store timeout ID for clearing it later
        } else if (status === "open") {
          // Call answered, start timer
          console.log("Call answered, conversation started");
          startTimer();

          // Clear the ringing timeout if the call is answered
          if (connection.timeoutId) {
            clearTimeout(connection.timeoutId);
          }
        } else if (status === "closed" || status === "disconnected") {
          // Call disconnected or closed, reset state
          console.log("Call closed or disconnected");
          handleHangUp();
        }
      });

      connection.on("disconnect", async () => {
        console.log("'disconnect' event fired");
        const callSid = connection?.parameters?.CallSid;

        try {
          const res = await axiosInstance.post(`tc/capture/activity-log/`, {
            call_sid: callSid,
          });
          console.log("res", res);
          fetchActivityLogsDirect();
        } catch (error) {
          console.error("Failed to update activity log:", error);
        }

        handleHangUp();
      });

      connection.on("error", (error) => {
        console.error("Twilio connection error:", error);
        // Handle any errors and hang up the call
        console.log("Call encountered an error, hanging up...");
        handleHangUp();
      });

      // Check call status regularly (e.g., closed)
      const checkStatus = setInterval(() => {
        const currentStatus = connection.status();

        if (currentStatus === "closed") {
          clearInterval(checkStatus);
          handleHangUp();
        }
      }, 1000);
    } catch (error) {
      console.error("Error in handleCall:", error);
      setIsCallModalVisible(false);
      message.error(`Failed to initiate call: ${error.message}`);
    }
  }, [uid, twilioNumbers, phoneNo]);

  const columns = [
    {
      title: "Activity Log",
      dataIndex: "verb",
      key: "verb",
      render: (htmlContent) => (
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      ),
    },
  ];

  const data = callLogMessages.map((item, index) => ({
    key: index,
    verb: item.verb,
  }));

  const handleSms = async (k) => {
    setLoading(true);
    try {
      await form.validateFields();
      const res = await axiosInstance.post("/tc/api/direct-sms/", smsForm);

      if (res?.status && res?.data?.status) {
        form.resetFields();
        // setSmsForm({
        //     uid: "",
        //     phone_no: "",
        //     message: ""
        // });
        message.success(res.data.data);
        fetchActivityLogsDirect();
      } else {
        console.error("Unexpected API response format:", res);
      }
    } catch (err) {
      await catchError(err);
    } finally {
      setLoading(false);
    }
  };

  const [selectedHierarchy, setSelectedHierarchy] = useState([]);
  const [hierarchyData, setHierarchyData] = useState([]);
  const [parentDetail, setParentDetail] = useState({ user: null, uid: null });
  const [myTeamloading, setMyTeamLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setMyTeamLoading(true)
      try {
        const result = await hierarchyService.fetchClientConnectHierarchy();

        if (result.success) {
          const data = result.data.data;
          setHierarchyData(data.hierarchy);
          setParentDetail({ uid: data.uid, user: data.user });
          setSelectedHierarchy([data.uid]);
          setMyTeamLoading(false)
        }
      } catch (error) {
        console.error(error);
        setMyTeamLoading(false)
      }
    };

    fetchData();
  }, []);

  const hierarchyOptions = [
    // { value: "Total", label: "Total" },
    {
      value: parentDetail.uid,
      label: ` ${parentDetail.user} (You)`,
    },
    ...hierarchyData.map((item) => ({
      value: item.uid,
      label: item.user,
    })),
  ];

  const handleSelectChange = (value) => {
    if (value.includes("Total")) {
      const allUids = hierarchyData.map((item) => item.uid);
      console.log("first", allUids);
      setSelectedHierarchy([parentDetail.uid, ...allUids]);
    } else {
      setSelectedHierarchy(value);
    }
  };

  const handleActivityUsers = async () => {

    // const uuidsParam = selectedHierarchy.join(",");
    setActivityLoading(true)
    try {
      const response = await axiosInstance.get(`/tc/api/activity-logs/?uuids=${selectedHierarchy}`);

      // console.log("response", response)
      if (response.status && response.data.status) {
        setCallLogMessages(response?.data?.data?.results || []);
        setActivityLoading(false);
      }
    } catch (err) {
      await catchError(err);
      setActivityLoading(false);
    }
  };
  const screens = useBreakpoint();

  return (
    <div style={{ width: "100%", padding: " 1px 20px" }}>
      <Flex style={{ width: "100%" }} align="flex-end" justify="flex-end">
        <CarouselButton
          onClick={() => setIsEnabledCountriesModalVisible(true)}
        />
      </Flex>
      <Row
        style={{ textAlign: "left", marginBottom: "50px" }}
        gutter={[32, 24]}>
        {hasPermission("can_initiate_direct_call") && (
          <Col lg={12} md={24}>
            <div
              style={{
                borderRadius: "10px",
                backgroundColor: "white",
                padding: "16px",
              }}>
              <Typography.Title
                level={4}
                style={{ marginBottom: "20px", marginTop: "0" }}>
                Direct Calling Interface
              </Typography.Title>
              <Form layout="vertical">
                <Form.Item label="Select Phone Number">
                  <Select
                    onChange={(value) => setUid(value)}
                    placeholder="Select a phone number"
                    style={{ width: "100%" }}>
                    {twilioNumbers?.map((numbers) => (
                      <Option key={numbers?.uid} value={numbers?.uid}>
                        {numbers?.phone_no}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Enter Phone Number"
                  name="phoneNo"
                  rules={[
                    { required: true, message: "Please enter a phone number" },
                  ]}>
                  <Input
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="Enter phone number"
                  />
                </Form.Item>

                <Form.Item
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}>
                  <Button
                    onClick={handleCall}
                    style={{ backgroundColor: "#36c6d3" }}
                    type="primary"
                    icon={<PhoneOutlined rotate={90} />}
                    size="medium">
                    Call
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        )}
        {hasPermission("can_sent_direct_sms") && (
          <Col lg={12} md={24}>
            <div
              style={{
                borderRadius: "10px",
                backgroundColor: "white",
                padding: "16px",
              }}>
              <Typography.Title
                level={4}
                style={{ marginBottom: "20px", marginTop: "0" }}>
                Direct Message Interface
              </Typography.Title>
              <Form form={form} layout="vertical">
                <Row gutter={[16, 16]}>
                  <Col lg={12}>
                    <Form.Item
                      name="uid"
                      label="Select Phone Number"
                      rules={[
                        {
                          required: true,
                          message: "Please select a phone number",
                        },
                      ]}>
                      <Select
                        value={smsForm.uid || undefined}
                        onChange={(value) =>
                          setSmsForm({ ...smsForm, uid: value })
                        }
                        placeholder="Select a phone number"
                        style={{ width: "100%" }}>
                        {twilioNumbers?.map((numbers) => (
                          <Select.Option
                            key={numbers?.uid}
                            value={numbers?.uid}>
                            {numbers?.phone_no}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col lg={12}>
                    <Form.Item
                      name="phone_no"
                      rules={[
                        {
                          required: true,
                          message: "Please select a phone number",
                        },
                      ]}
                      label="Enter Phone Number">
                      <Input
                        value={smsForm.phone_no}
                        placeholder="Enter phone number"
                        onChange={(e) =>
                          setSmsForm({ ...smsForm, phone_no: e.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  name="message"
                  rules={[
                    { required: true, message: "Please select a phone number" },
                  ]}
                  label="Message">
                  <TextArea
                    value={smsForm.message}
                    onChange={(e) =>
                      setSmsForm({ ...smsForm, message: e.target.value })
                    }
                    placeholder="Enter your message"
                    rows={1}
                  />
                </Form.Item>
                <Form.Item
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}>
                  <Button
                    onClick={handleSms}
                    style={{ backgroundColor: "#36c6d3" }}
                    type="primary"
                    icon={<MessageOutlined />}
                    size="medium">
                    {loading ? <Spin /> : "SMS"}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        )}
      </Row>

      <Divider />

      <Row
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: screens.xs ? "10px" : "0",
          justifyContent: "flex-end",
          gap: "10px",
          marginBottom: "20px",
        }}
      >
        {/* <Col style={{ width: "auto", marginRight: "10px", textAlign: "left" }}>
          <div style={{ margin: "0px" }}>Select a team member to check their call history and monitor communication details</div>
        </Col> */}
        <Row
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px"
          }}
        >
          <Col style={{ width: "auto", marginRight: "10px" }}>
            <Select
              placeholder={myTeamloading ? "Fetching Team" : "Select Users"}
              options={hierarchyOptions}
              value={selectedHierarchy}
              onChange={handleSelectChange}
              style={{
                width: 200,
                maxWidth: 200,
                textAlign: "left",
              }}
              // allowClear
              // mode="multiple"
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              loading={myTeamloading}
            />
          </Col>

          <Col>
            <Button
              onClick={handleActivityUsers}
              style={{
                backgroundColor: "#36c6d3",
                width: "auto",
                padding: "10px"
              }}
              type="primary"
              icon={<SearchOutlined />}
              size="medium"
            >
              {/* Search */}
            </Button>
          </Col>
        </Row>
      </Row>

      <Row
        gutter={[24, 24]}
        style={{ textAlign: "left", marginTop: "0px", marginBottom: "20px" }}>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            bordered
            loading={activityLoading}
          />
        </Col>
      </Row>

      <OrderCallModal
        visible={isCallModalVisible}
        onHangUp={handleHangUp}
        callDuration={callDuration}
        onCancel={handleHangUp}
      />
      <EnabledCountriesModal
        isVisible={isEnabledCountriesModalVisible}
        onClose={() => setIsEnabledCountriesModalVisible(false)}
      />
    </div>
  );
};

export default ClientConnect;
