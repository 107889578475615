import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Divider, Form, Input, Radio, Button, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axiosInstance from '../../services/axiosInstance';
import { catchError } from '../../utils/errorHandler';

const { Title } = Typography;

const AddRefund = ({ setIsModalVisible }) => {
    const { orderKey } = useParams();
    const [form] = Form.useForm();
    const [refundType, setRefundType] = useState("Wallet");
    const [loading, setLoading] = useState(false)

    const handleRefundTypeChange = (e) => {
        setRefundType(e.target.value);
    };

    const onFinish = async (values) => {

        const formData = new FormData();
        Object.keys(values).forEach((key) => {
            if (key === 'receipt') {
                formData.append(key, values[key][0].originFileObj);
            } else {
                formData.append(key, values[key]);
            }
        });
        setLoading(true);
        try {
            const response = await axiosInstance.post(`task/v2/refund-add/${orderKey}/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log("res", response);
            if (response.status === 200 && response.data?.status === "success") {
                message.success("Refund added successfully");
                form.resetFields();
                setIsModalVisible(false)
            }
            setLoading(false);
        } catch (err) {
            await catchError(err);
            setLoading(false);
        }
    };

    return (
        <div>
            <div>Refund Details for Order: {orderKey}</div>
            <Divider />

            <Form
                form={form}
                layout="horizontal"
                onFinish={onFinish}
                autoComplete="off"
                labelCol={{ span: 4 }}
                style={{ maxWidth: '600px', margin: '0 auto' }}
            >
                <Form.Item style={{ width: "100%" }} label="Type" name="refund_mode" initialValue="Wallet">
                    <Radio.Group onChange={handleRefundTypeChange}>
                        <Radio value="Wallet">Wallet</Radio>
                        <Radio value="MoneyBack">MoneyBack</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item style={{ width: "100%" }}
                    label="Amount"
                    name="refund_amount"
                    rules={[
                        { required: true, message: 'Please enter the refund amount' },
                        { pattern: /^\d+(\.\d{1,2})?$/, message: 'Please enter a valid amount' }
                    ]}
                >
                    <Input placeholder="Enter refund amount" />
                </Form.Item>

                <Form.Item style={{ width: "100%" }}
                    label="Receipt"
                    name="receipt"
                    valuePropName="fileList"
                    getValueFromEvent={(e) => e.fileList}
                    rules={[{ required: true, message: 'Please upload the receipt' }]}
                >
                    <Upload beforeUpload={() => false} maxCount={1}>
                        <Button icon={<UploadOutlined />}>Upload Receipt</Button>
                    </Upload>
                </Form.Item>

                <Form.Item style={{ width: "100%" }}
                    label="Reason"
                    name="reason"
                    rules={[{ required: true, message: 'Please provide a reason for the refund' }]}
                >
                    <Input.TextArea rows={3} placeholder="Enter the reason for the refund" />
                </Form.Item>

                <Form.Item style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                    <Button type="primary" htmlType="submit">
                        {loading ? "Loading" : "Submit"}
                    </Button>
                </Form.Item>
            </Form>

            <Divider />
            Policy Guidelines can be uncommented as needed
            <div>
                At Locus Assignments, we value customer satisfaction and aim to provide a transparent and fair refund policy. Please review the following guidelines regarding refunds:

                1. Timely Processing: Refunds, if approved, will be processed within 7 days of initiation, regardless of the reason for the request.

                2. Non-Delivery: In the event that an order is not delivered within the specified timeframe, a refund will only be considered upon submission of evidence demonstrating the non-receipt of the order as well as evidence of missing deadline.

                3. Performance Guarantee: If an order fails to meet the customer's expectations in terms of achieving desired results, a refund will only be approved upon submission of a marked copy of the assignment along with relevant tutor feedback.

                4. Cancellation Policy: Refunds will not be issued if a customer requests to cancel an order after it has been moved to the "In Progress" or "Feedback" stage.

                5. Refund Initiation Deadline: To be eligible for a refund, the request must be initiated within 90 days of receiving the results.

                Please note that our refund policy is subject to periodic review and may be updated without prior notice. For any inquiries or assistance regarding refunds, please contact our customer support team at support@locusassignments.com.

                Thank you for choosing Locus Assignments. Your satisfaction is our priority.
            </div>
        </div>
    );
};

export default AddRefund;
