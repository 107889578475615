import { Button, Select, Spin, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../services/axiosInstance';
import { catchError } from '../../utils/errorHandler';
import SpinLoading from './SpinLoading';
import orderService from '../../services/OrderLists';
const { Option } = Select;

const AssignOwner = ({ handleModalClose, fetchOrderDetails }) => {
    const [ownersData, setOwnersData] = useState([]);
    const [selectedOwners, setSelectedOwners] = useState([]);
    const [loading, setLoading] = useState(false);
    const { orderKey } = useParams();

    const fetchOwners = async () => {
        setLoading(true)
        try {
            const { data } = await axiosInstance.get(`/task/v2/owners/${orderKey}`);
            if (data?.status === "success") {
                const initialOwners = data.data.owners_data.map(owner => ({
                    value: owner.uid,
                    label: owner.name,
                })) || [];
                setOwnersData(data.data.dropdown_data);
                setSelectedOwners(initialOwners);
            }
            setLoading(false)
        } catch (error) {
            console.error("Error fetching owners:", error);
            message.error("Failed to fetch owners.");
            setLoading(false)
        }
    };


    useEffect(() => {
        fetchOwners();
    }, [orderKey]);

    const handleSelectChange = (value) => {
        setSelectedOwners(value);
    };

    const handleSubmit = async () => {
        if (!selectedOwners.length) {
            return message.error("Please select at least one owner before submitting.");
        }

        setLoading(true);
        try {
            const usernames = selectedOwners.map(owner => owner.value);
            const response = await axiosInstance.post(`task/v2/assign-owners/${orderKey}/`, {
                owners: usernames,
            });

            if (response.status === 200 && response.data?.status === "success") {
                await orderService.getOrderDetails(orderKey);
                fetchOrderDetails()
                handleModalClose()
                message.success(response.data.data);
            } else {
                message.error("Failed to assign the owners.");
            }
        } catch (error) {
            await catchError(error);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Spin tip="Loading status options..." style={{ display: 'block', marginTop: '0' }} />;
    }

    return (
        <div>
            <div style={{ marginBottom: 10 }}>Select Owners</div>
            <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Select Owners"
                onChange={handleSelectChange}
                value={selectedOwners}
                labelInValue
                optionLabelProp="label"
                filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              } // Custom search filtering
            >
                {loading ? (
                    <Option disabled value="loading">
                        <SpinLoading />
                    </Option>
                ) : (
                    ownersData.map(item => (
                        <Option key={item.uid} value={item.uid} label={item.name}>
                            {item.name}
                        </Option>
                    ))
                )}
            </Select>
            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20 }}>
                <Button
                    className='handle_submit_action_button'
                    onClick={handleSubmit}
                    loading={loading}
                    disabled={!selectedOwners.length}
                >
                    Submit
                </Button>
            </div>
        </div>
    );
};

export default AssignOwner;
