import React, { useState } from "react";
import { Tabs, Button, Switch, Input, Popover ,Badge} from "antd";
import { Check, CheckCheck, Search } from "lucide-react";

const NotificationsTabs = ({
  activeTab,
  onTabChange,
  onShowUnreadToggle,
  showUnread,
  onMarkAllAsRead,
  categories,
  onSearch, // Calls handleSearch from parent
  levelCounts,
  searchValue,
  setSearchValue
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);


  const handleSearchClick = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  const handleSubmitSearch = () => {
    if (searchValue.trim()) {
      onSearch(searchValue); // Call parent search function
      setIsPopoverOpen(false); // Close popover
    }
  };

  return (
    <>
      <Tabs
        style={{ width: "100%" }}
        activeKey={activeTab}
        onChange={onTabChange}
        tabBarStyle={{ marginBottom: 16 }}
        tabBarExtraContent={
          <div style={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Switch
              checked={showUnread}
              checkedChildren="Unread"
              unCheckedChildren="Unread"
              onChange={onShowUnreadToggle}
            />

            <Button type="link" icon={<CheckCheck />} onClick={onMarkAllAsRead}>
              Mark all as read
            </Button>

            {/* Search Popover */}
            <Popover
              content={
                <div
                  style={{
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                    transition: "opacity 0.3s ease-in-out",
                  }}>
                  <Input
                    autoFocus
                    placeholder="Search..."
                    onPressEnter={handleSubmitSearch} // Call search on Enter press
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    style={{
                      borderRadius: "15px",
                      padding: "8px",
                      fontSize: "16px",
                      width: "200px",
                    }}
                  />
                  {/* <Button
                  style={{borderRadius:'100%' , background:'#040724'}}
                    type="primary"
                    icon={<Check  color="black"/>}
                    onClick={handleSubmitSearch}
                  /> */}
                </div>
              }
              trigger="click"
              open={isPopoverOpen}
              onOpenChange={setIsPopoverOpen}
              placement="bottomRight" // Positions below the search icon
            >
              <Button
                type="link"
                icon={<Search />}
                onClick={handleSearchClick}
              />
            </Popover>
          </div>
        }>
        {categories.map((category) => {
          const categoryCount =
            levelCounts.find((item) => item.level === category.key)?.count || 0;

          return (
            <Tabs.TabPane
              key={category.key}
              tab={
                <Badge
                  count={categoryCount} // Dynamically set the count
                  offset={[10, 0]}
                  size="large"
                  showZero>
                  <span
                    style={{
                      color: "black",
                      padding: "8px 16px",
                      borderRadius: "8px 8px 0 0",
                      backgroundColor: category.color,
                      display: "inline-block", // Ensure proper layout
                    }}>
                    {category.label}
                  </span>
                </Badge>
              }
            />
          );
        })}
      </Tabs>
    </>
  );
};

export default NotificationsTabs;
