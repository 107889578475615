import {
  HomeOutlined,
  TeamOutlined,
  ShoppingCartOutlined,
  WalletOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import usePermissions from "../hooks/usePermissions";
export const useFilteredNavMenu = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { hasPermission } = usePermissions();
  if (!user?.user?.permissions) {
    // console.log("No permissions found.");
    return [];
  }

  const filteredNavMenu = [
    {
      key: "home",
      icon: <HomeOutlined />,
      label: "Home",
      route: "/",
      onClick: () => navigate("/"),
    },
    hasPermission("can_view_sales_operations") && {
      key: "sales-operations",
      icon: <SolutionOutlined />,
      label: "Sales Operations",
      children: [
        hasPermission("can_view_my_clients") && {
          key: "clients",
          label: "Clients",
          route: "/sales-operations/clients",
          onClick: () => navigate("/sales-operations/clients"),
        },
        hasPermission("can_view_assigned_leads") && {
          key: "leads",
          label: "Leads",
          route: "/sales-operations/leads",
          onClick: () => navigate("/sales-operations/leads"),
        },
        hasPermission("can_view_calling_and_sms") && {
          key: "client-connect",
          label: "Client Connect",
          route: "/sales-operations/client-connect",
          onClick: () => navigate("/sales-operations/client-connect"),
        },
        hasPermission("can_view_sales_and_analytics") && {
          key: "sales-analytics",
          label: "Sales Analytics",
          route: "/sales-operations/sales-analytics",
          onClick: () => navigate("/sales-operations/sales-analytics"),
        },
        hasPermission("can_view_inr_currency_convertor") && {
          key: "currency-converter",
          label: "Currency Converter",
          route: "/sales-operations/currency-converter",
          onClick: () => navigate("/sales-operations/currency-converter"),
        }        
      ].filter(Boolean),
    },
    hasPermission("can_view_order_management") && {
      key: "order-management",
      icon: <ShoppingCartOutlined />,
      label: "Order Management",
      children: [
        hasPermission("can_view_order_list") && {
          key: "my-orders",
          label: "My Orders",
          route: "/order-management/my-orders",
          onClick: () => navigate("/order-management/my-orders"),
        },
        hasPermission("can_view_order_operations") && {
          key: "order-operations",
          label: "Order Operations",
          children: [
            hasPermission("can_view_my_call_allocation_tasks") && {
              key: "call-allocation",
              label: "Call Allocation",
              route: "/order-management/order-operations/call-allocation",
              onClick: () =>
                navigate("/order-management/order-operations/call-allocation"),
            },
            hasPermission("can_view_my_subscriber_deductions") && {
              key: "deduction",
              label: "Deductions",
              route: "/order-management/order-operations/deductions",
              onClick: () =>
                navigate("/order-management/order-operations/deductions"),
            },
            hasPermission("can_view_move_to_fl_associate_orders") && {
              key: "movetoflassociate",
              label: "Moved to FL Associates",
              route:
                "/order-management/order-operations/moved-to-fl-associates",
              onClick: () =>
                navigate(
                  "/order-management/order-operations/moved-to-fl-associates"
                ),
            },
            hasPermission("can_view_order_progress_tracking") &&
            {
              key: "orderprogress",
              label: "Order Progress Tracking",
              route:
                "/order-management/order-operations/order-progress-tracking",
              onClick: () => {
                navigate(
                  "/order-management/order-operations/order-progress-tracking"
                );
              },
            },
            hasPermission("can_view_order_fl_deduction") &&
            {
              key: "florderdeduction",
              label: "FL Order Deductions",
              route:
                "/order-management/order-operations/fl-order-deduction",
              onClick: () => {
                navigate(
                  "/order-management/order-operations/fl-order-deduction",
                );
              },
            },
            // ,
            //  {
            //   key: "subscriberanalytics",
            //   label: "Subscriber Analytics",
            //   route: "/order-management/order-operations/subscriber-analytics",
            //   onClick: () => {
            //     navigate("/order-management/order-operations/subscriber-analytics");
            //   }
            // },
            hasPermission("can_view_task_allocation_adjustment_task_list") &&
             {
              key: "allocationadjustment",
              label: "Allocation Adjustment",
              route: "/order-management/order-operations/allocation-adjustment",
              onClick: () => {
                navigate("/order-management/order-operations/allocation-adjustment");
              }
            },
            hasPermission("can_view_fl_payment_requests") &&
            {
             key: "florderpayout",
             label: "FL Orders For Payout",
             route: "/order-management/order-operations/fl-orders-for-payout",
             onClick: () => {
               navigate("/order-management/order-operations/fl-orders-for-payout");
             }
           }
           , 
            hasPermission("can_view_accept_reject_page") &&
           {
            key: "acceptreject",
            label: "Accept / Reject Orders",
            route: "/order-management/order-operations/accept-reject-orders",
            onClick: () => {
              navigate("/order-management/order-operations/accept-reject-orders");
            }
          }
          ],
          // : []
          // ].filter(Boolean), // Ensure children are not removed due to empty array
        },
      ].filter(Boolean),
    },
    hasPermission("can_view_payment_operations") && {
      key: "payment-operations",
      icon: <WalletOutlined />,
      label: "Payment Operations",
      children: [
        hasPermission("can_view_my_transactions") && {
          key: "transactions",
          label: "Transactions",
          route: "/payment-operations/transactions",
          onClick: () => navigate("/payment-operations/transactions"),
        },
        hasPermission("can_view_wallet") && {
          key: "wallet",
          label: "Wallet",
          route: "/payment-operations/wallet",
          onClick: () => navigate("/payment-operations/wallet"),
        },
      ].filter(Boolean),
    },
    {
      key: "team-management",
      icon: <TeamOutlined />,
      label: "Team Management",
      route: "/hierarchy-form",
      onClick: () => navigate("/hierarchy-form"),
    },
  ].filter(Boolean);

  return filteredNavMenu;
};
