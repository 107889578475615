import { Button, Col, Form, Input, Row, Space } from "antd";
import { reset } from "../../redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { UserOutlined } from "@ant-design/icons";
import React, { useState } from "react";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm(); // Create a form instance
  const [data, setData] = useState({
    mailSend: false,
  });
  const { loading } = useSelector((state) => state.auth);

  const onFinish = async (values) => {
    const { success } = await dispatch(reset(values));
    if (success) {
      setData((prevData) => ({
        ...prevData,
        mailSend: true,
      }));
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <Row justify="space-around" align="middle" style={{ height: "100%" }}>
      <Col xl={6} xxl={6} lg={8} md={12} sm={22} xs={22}>
        <Form
          form={form}
          name="basic"
          style={{
            textAlign: "start",
            padding: "2rem",
            boxShadow:
              "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
          }}
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {data.mailSend ? (
            <>
              <Form.Item style={{ textAlign: "center" }}>
                <h2>{form.getFieldValue("email")}</h2>
                <p style={{color:"green"}}>Password reset e-mail has been sent.</p>
              </Form.Item>
              <Form.Item>
                <Space
                  direction={"vertical"}
                  style={{ width: "100%" }}
                  size={10}
                >
                  <Button
                    onClick={() => onFinish(form.getFieldValue())}
                    style={{ width: "100%" }}
                    loading={loading}
                    type="link"
                  >
                    Resend Email
                  </Button>
                  <Button
                    type="text"
                    onClick={async () =>
                      await setData((prevData) => ({
                        ...prevData,
                        mailSend: false,
                      }))
                    }
                    style={{ width: "100%" }}
                  >
                    Change Email
                  </Button>
                </Space>
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item
                wrapperCol={{
                  span: 24,
                }}
                style={{ textAlign: "center" }}
              >
                <h2>Forgotten your password?</h2>
                <p>
                  Enter your e-mail address below, and we'll send you an e-mail
                  allowing you to reset it.
                </p>
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Email"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  loading={loading}
                >
                  Submit
                </Button>
              </Form.Item>
            </>
          )}
        </Form>
      </Col>
    </Row>
  );
};

export default ResetPassword;
