import React from "react";
import { DatePicker, Space } from "antd";
import dayjs from 'dayjs';
const DateRangePicker = ({
  onStartChange,
  onEndChange,
  startDate,
  endDate,
}) => {
  const disabledDate = (current) => {
    // Can not select days after today
    return current && current > dayjs().endOf("day");
  };
  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"];

  return (

    <Space   direction="horizontal" size={12}>
      
      <DatePicker
        defaultValue={startDate}
        format={dateFormatList}
        onChange={onStartChange}
        disabledDate={disabledDate}
      />
        <DatePicker
        defaultValue={endDate}
        format={dateFormatList}
        onChange={onEndChange}
        disabledDate={disabledDate}
      />
    </Space>
  );
};

export default DateRangePicker;
