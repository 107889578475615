import React from "react";
import { Table, Dropdown, Menu, Button, Card, Row, Col, Empty } from "antd";
import { CloudDownloadOutlined, MoreOutlined } from "@ant-design/icons";
import moment from "moment";

const FilesTable = ({ filesData }) => {
  const menu = (
    <Menu>
      <Menu.Item key="1">Add Task Files</Menu.Item>
      <Menu.Item key="2">Delete Files</Menu.Item>
      <Menu.Item key="3">ZIP Files</Menu.Item>
      <Menu.Item key="3">Download All Files</Menu.Item>
    </Menu>
  );

  const getFileIcon = (fileName) => {
    const extension = fileName?.split(".").pop().toLowerCase();

    switch (extension) {
      case "pdf":
        return "https://i.ibb.co/Xzcwvy6/images-q-tbn-ANd9-Gc-TGAA4-Wd4bco5-Xv33-Gas-Xrn-Dd-QT5-OFXwa3-HUQ-s.png";
      case "doc":
      case "docx":
        return "https://i.ibb.co/mXGsc7g/images-q-tbn-ANd9-Gc-Rs3n-Wc9jmd-M7-Wm-Dc-EAy3-PC2z-Mur7-A1ee-bw-s.png";
      case "jpg":
      case "jpeg":
        return "https://i.ibb.co/NC2DbKN/images-q-tbn-ANd9-Gc-Qgd-Crtwnin-NU1-Nxzpu-IP6-F9h-Kkpfv-SSgr7cw-s.png";
      case "png":
        return "https://i.ibb.co/mtLjYdr/2048px-pptx-icon-2019-svg.png";
      case "zip":
        return "https://i.ibb.co/WV6wJv4/zip-file-line-icon-vector.jpg";
      default:
        return "https://i.ibb.co/NC2DbKN/images-q-tbn-ANd9-Gc-Qgd-Crtwnin-NU1-Nxzpu-IP6-F9h-Kkpfv-SSgr7cw-s.png";
    }
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      <Card
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>Order Files</span>
            {/* <Dropdown overlay={menu} trigger={["click"]}>
              <Button shape="circle" icon={<MoreOutlined />} />
            </Dropdown> */}
          </div>
        }
        style={{ marginBottom: "20px", textAlign: "left" }}
        headStyle={{ textAlign: "left" }}
      >
        <div
          style={{
            maxHeight: "300px",
            overflowX: "hidden",
          }}
        >
          {filesData && filesData.length > 0 ? (
            <Row gutter={[16, 16]}>
              {filesData.map((item, index) => (
                <Col xs={24} sm={8} key={index}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #D3D3D3",
                      padding: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    <div
                      style={{
                        height: "50px",
                        width: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        style={{ height: "60px", width: "60px" }}
                        src={getFileIcon(item?.file_name)}
                        alt="file-icon"
                      />
                    </div>
                    <div style={{ flex: 1, marginLeft: "10px" }}>
                      <div
                        className="field-label"
                        style={{ fontWeight: "normal", wordBreak: "break-all" }}
                      >
                        {item?.file_name
                          ? item?.file_name.length <= 15
                            ? item?.file_name
                            : `${item?.file_name?.slice(
                                0,
                                15
                              )}.${item?.file_name?.split(".")?.pop()}`
                          : "No file name"}
                      </div>
                      <div style={{color:"#555555"}}>{moment(item?.created).format('DD-MM-YYYY hh:mm A')}</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          gap: "20px",
                          marginTop: "5px",
                          color: "gray",
                        }}
                      >
                        <div>{item?.file_size} KB</div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: "10px",
                          }}
                        >
                          <a
                            href={item?.file_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <CloudDownloadOutlined
                              style={{ fontSize: "19px", color: "gray" }}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          ) : (
            <Empty description="No Files Available" />
          )}
        </div>
      </Card>
    </div>
  );
};

export default FilesTable;
