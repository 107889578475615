import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from '../pages/Dashboard/Dashboard';
import Login from '../pages/Login/Login';
import Signup from '../pages/Signup/Signup';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import ResetPasswordConfirm from '../pages/ResetPassword/ResetPasswordConfirm';
import OrderDetail from '../pages/OrderDetail/OrderDetail';
import Leads from '../pages/Leads/Leads';
import AllClient from '../pages/All Clients/AllClient';
import Orders from '../pages/Orders/Orders';
import UnauthorizedPage from '../components/Error/UnauthorizedPage';
import NotFoundPage from '../components/Error/NotFoundPage';
import PermissionProtectedRoute from './PermissionProtectedRoute'; // Import your component
import Home from '../pages/Home/Home';
import LeadsPage from '../pages/Leads/Leads';
export const AppRoutes = () => {
  return (
    <Routes>
      {/* Auth Routes */}
      <Route path="/login" element={<Login />} />
      <Route path="/sign-up" element={<Signup />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route
        path="/reset-password/confirm/:uid/:token"
        element={<ResetPasswordConfirm />}
      />

      {/* Protected Routes */}
      <Route element={<PermissionProtectedRoute permissionsRequired={[]} />}>
        <Route path="/sales-operations/sales-analytics" element={<Dashboard />} />
        <Route path="/" element={<Home />} />
        {/* Order Management Routes */}
        <Route path="/orders" element={<Orders />} />
        <Route path="/order-management/my-orders" element={<Orders />} />
        <Route path="/order-management/my-orders/:orderKey" element={<OrderDetail />} />

        {/* Leads Management Routes */}
        <Route path="/sales-operations/leads" element={<Leads />} />

        {/* Sales Operatiaons */}
        <Route path="/sales-operations/clients" element={<AllClient />} />
      </Route>

      {/* Error Handling */}
      <Route path="/unauthorized" element={<UnauthorizedPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
