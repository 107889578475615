import React from "react";
import {
  Descriptions,
  Typography,
  Card,
  Button,
  Popover,
  Tooltip,
  Tag,
  Pagination,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import usePermissions from "../../hooks/usePermissions";
const MobileViewForRequestPayout = ({
  payoutData,
  handleOpenModal,
  onRowSelect,
  pagination,
  setPagination,
  loading,
}) => {
  const { hasPermission } = usePermissions();
  const formatLabel = (label) =>
    label.replace(/_/g, " ").replace(/\b\w/g, (c) => c.toUpperCase());
  const canViewDetails = hasPermission("can_view_fl_payment_request_detail"); // Check permission

  return (
    <div style={{ padding: 10 }}>
      {payoutData.length > 0 ? (
        payoutData.map((payout, index) => {
          const {
            uuid,
            created_at,
            requested_by,
            currency,
            disbursal_status,
            amount_approved_to_fl,
            disbursed_amount,
            ...rest
          } = payout;

          return (
            <Card key={index} style={{ marginBottom: 10 }} loading={loading}>
              <Descriptions size="small" column={1} bordered>
                {/* ✅ Requested By */}
                <Descriptions.Item label="Requested by">
                  <div>{requested_by}</div>
                  <small style={{ color: "gray" }}>
                    {moment(created_at).local().format("DD-MM-YYYY HH:mm A")}
                  </small>
                </Descriptions.Item>

                {/* ✅ Dynamically render all fields except excluded ones */}
                {Object.entries(rest).map(([key, value]) => {
                  switch (key) {
                    case "total_alloted_amount":
                      return (
                        <Descriptions.Item
                          key={key}
                          label="Total Allotted Amount">
                          <Tooltip title="Total Allotted Amount (All Expenses Included)">
                            <Typography.Text>
                              {value} {currency}
                            </Typography.Text>
                          </Tooltip>
                        </Descriptions.Item>
                      );

                    case "payable_amount":
                      return (
                        <Descriptions.Item key={key} label="Payable Amount">
                          <Tooltip title="Payable Amount (After Deductions)">
                            <div
                              style={{
                                fontWeight: "bold",
                                fontSize: "16px",
                                color: "#046C4E", // Highlight with an attention-grabbing color
                                // backgroundColor: "#FFF3E0", // Light background for contrast
                                padding: "5px 10px",
                                borderRadius: "5px",
                                display: "inline-block",
                              }}>
                              {value} {currency}
                            </div>
                          </Tooltip>
                        </Descriptions.Item>
                      );

                    case "fl_deduction":
                      return (
                        <Descriptions.Item key={key} label="FL Deduction">
                          <Tooltip title="Amount Deducted from FL">
                            <Typography.Text style={{ color: "red" }}>
                              {value > 0 ? `${value} ${currency}` : value}
                            </Typography.Text>
                          </Tooltip>
                        </Descriptions.Item>
                      );

                    case "fl_arear":
                      return (
                        <Descriptions.Item key={key} label="FL Arear">
                          <Tooltip title="Pending Amount from FL">
                            <Typography.Text style={{ color: "green" }}>
                              {value > 0 ? `${value} ${currency}` : value}
                            </Typography.Text>
                          </Tooltip>
                        </Descriptions.Item>
                      );

                    case "fl_request_status":
                      return (
                        <Descriptions.Item key={key} label="FL Request Status">
                          <Tag color={value === "Pending" ? "orange" : "green"}>
                            {value}
                          </Tag>
                        </Descriptions.Item>
                      );

                    case "remarks":
                      return (
                        <Descriptions.Item key={key} label="Remarks">
                          {Array.isArray(value) ? (
                            <Popover
                              content={
                                <div
                                  style={{
                                    width: 300,
                                    maxHeight: 300,
                                    overflowY: "auto",
                                  }}>
                                  <Descriptions
                                    size="small"
                                    column={1}
                                    bordered>
                                    {value.map((r, i) => (
                                      <Descriptions.Item
                                        key={i}
                                        label={r.label}>
                                        <Typography.Text
                                          style={{ whiteSpace: "pre-line" }}>
                                          {r.reason}
                                        </Typography.Text>
                                      </Descriptions.Item>
                                    ))}
                                  </Descriptions>
                                </div>
                              }
                              title="Remarks">
                              <Button type="link">View</Button>
                            </Popover>
                          ) : (
                            value
                          )}
                        </Descriptions.Item>
                      );

                    default:
                      return (
                        <Descriptions.Item key={key} label={formatLabel(key)}>
                          {value}
                        </Descriptions.Item>
                      );
                  }
                })}

                {/* ✅ Action Button */}
                <Descriptions.Item label="Add Payout">
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      if (canViewDetails) {
                        onRowSelect(payout);
                        handleOpenModal("payout", payout.uuid, payout);
                      }
                    }}></Button>
                </Descriptions.Item>
              </Descriptions>
            </Card>
          );
        })
      ) : (
        <Typography.Text type="secondary">
          No payout data available
        </Typography.Text>
      )}
      <Pagination
        current={pagination.current}
        pageSize={pagination.pageSize}
        total={pagination.total}
        onChange={(page, pageSize) =>
          setPagination((prev) => ({ ...prev, current: page, pageSize }))
        }
        style={{ textAlign: "center", marginTop: 16 }}
      />
    </div>
  );
};

export default MobileViewForRequestPayout;
