import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select, Spin, message } from 'antd';
import axiosInstance from '../../services/axiosInstance';
import { catchError } from '../../utils/errorHandler';

const { TextArea } = Input;
const { Option } = Select;

const CallAllocationModalContent = ({ orderKey, handleModalClose }) => {
    const [loading, setLoading] = useState(false);
    const [callStatus, setCallStatus] = useState([]);
    const [form] = Form.useForm();
    const [submitLoading, setSubmitLoading] = useState(false);
    const [shouldShowComment, setShouldShowComment] = useState(false);

    const fetchCallStatusData = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get('/task/v2/call-allocation/status/dropdown/');
            if (response?.data?.data) {
                setCallStatus(response.data.data);
            }
        } catch (err) {
            console.error('Error fetching call status:', err);
        } finally {
            setLoading(false);
        }
    };

    const handleStatusChange = (value) => {
        const selectedStatus = callStatus.find(status => status.id === value);
        if (selectedStatus && (selectedStatus.name === 'Reroute' || selectedStatus.name === 'Others')) {
            setShouldShowComment(true);
        } else {
            setShouldShowComment(false);
        }
    };

    useEffect(() => {
        fetchCallStatusData();
    }, []);

    const handleSubmitAllocation = async () => {
        try {
            const values = await form.validateFields();
            setSubmitLoading(true);
            const response = await axiosInstance.post(`task/v2/move-call-allocation/${orderKey}/`, values);
            if (response.data?.status === "success") {
                message.success('Call allocation moved successfully');
                handleModalClose();
            }
        } catch (err) {
            await catchError(err);
        } finally {
            setSubmitLoading(false);
        }
    };

    if (loading) {
        return <Spin tip="Loading status options..." style={{ display: 'block', marginTop: '0' }} />;
    }

    return (
        <div>
            <Form
                form={form}
                layout="vertical"
                initialValues={{
                    orderKey: orderKey,
                }}
            >
                <Form.Item
                    label="Order Key"
                    style={{ width: "100%" }}
                >
                    <Input value={orderKey} disabled />
                </Form.Item>

                <Form.Item
                    label="Select Option"
                    name="status"
                    rules={[{ required: true, message: 'Please select an option' }]}
                    style={{ width: "100%" }}
                >
                    <Select
                        placeholder="Select Status"
                        onChange={handleStatusChange}
                    >
                        {callStatus?.length > 0 ? (
                            callStatus.map((status) => (
                                <Option key={status.id} value={status.id}>
                                    {status.name}
                                </Option>
                            ))
                        ) : (
                            <Option value="" disabled>No status available</Option>
                        )}
                    </Select>
                </Form.Item>

                {shouldShowComment && (
                    <Form.Item
                        label="Comment"
                        name="comment"
                        style={{ width: "100%" }}
                        rules={[{ required: true, message: 'Please provide a comment' }]}
                    >
                        <TextArea placeholder="Enter your comment here..." rows={4} />
                    </Form.Item>
                )}
            </Form>

            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end', gap: '20px' }}>
                <Button
                    type="primary"
                    onClick={handleSubmitAllocation}
                    loading={submitLoading}
                >
                    Submit
                </Button>
            </div>
        </div>
    );
};

export default CallAllocationModalContent;
