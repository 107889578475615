import React, { useState, useEffect } from "react";
import { Flex, Typography } from "antd";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import WorkSchedule from "../../page-components/Home/WorkSchedule";
import usePermissions from "../../hooks/usePermissions";
const { Title, Text } = Typography;

function Home() {
  const [greeting, setGreeting] = useState("");
  const { user } = useSelector((state) => state.auth);
  const { hasPermission } = usePermissions();

  useEffect(() => {
    const updateGreeting = () => {
      const currentHour = new Date().getHours();
      if (currentHour < 12) {
        setGreeting("Good Morning");
      } else if (currentHour < 18) {
        setGreeting("Good Afternoon");
      } else {
        setGreeting("Good Evening");
      }
    };

    updateGreeting();
    const timer = setInterval(updateGreeting, 60000); // Update every minute

    return () => clearInterval(timer);
  }, []);
  const allowedUserTypes = [2, 4, 5, 6, 8, 9];

  return (
    <Flex align="flex-start" vertical style={{width:'100%', padding:'0px 16px'}}>
      <Title
        level={2}
        style={{
          color: "#1F2937", 
          marginTop: "1em",
          fontWeight: "bold",
          textAlign: "center",
          letterSpacing: "0.5px",
         }}
      >
        {greeting}, <span style={{ color: "#36c6d3" }}>{user.user.full_name}</span>
      </Title>

      {!allowedUserTypes.includes(user?.user?.type) && <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay: 0.5 }}
        style={{marginBottom: '20px'}}
      >
        <Text style={{ fontSize: '18px', color: '#555' }}>
          Get ready for a customized experience! Your personalized homepage will be introduced in the upcoming release phase.
        </Text>
      </motion.div>}

      {hasPermission("can_view_ops_work_schedule") && allowedUserTypes?.includes(user?.user?.type) && (
        <WorkSchedule />
    )}
    </Flex>
  );
}

export default Home;
