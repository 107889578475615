import React, { useState } from "react";
import {
  Table,
  Button,
  Flex,
  Card,
  Typography,
  Tag,
  Input,
  Popover,
  message,
} from "antd";
import { EyeOutlined } from "@ant-design/icons";
import orderService from "../../services/OrderLists";
import useIsMobile from "../../hooks/useIsMobile";

const { Text, Title } = Typography;

const TextAnalysis = ({ data }) => {
  const { input_text, exact_ranges } = data.output_json;

  const renderHighlightedText = () => {
    let lastIndex = 0;
    const elements = [];

    exact_ranges.forEach(([start, end], i) => {
      if (start > lastIndex) {
        elements.push(
          <Text key={`text-${i}-${lastIndex}`}>
            {input_text.substring(lastIndex, start)}
          </Text>
        );
      }
      elements.push(
        <Text
          key={`highlight-${i}`}
          style={{
            color: "red",
            padding: "0 2px",
            display: "inline-block",
          }}>
          {input_text.substring(start, end)}
        </Text>
      );
      lastIndex = end;
    });

    if (lastIndex < input_text.length) {
      elements.push(
        <Text key="text-final">{input_text.substring(lastIndex)}</Text>
      );
    }

    return elements;
  };

  return (
    <Card
      style={{ width: "100%" }}
      title={
        <Flex gap="middle" align="center">
          <Title level={5} style={{ margin: 0 }}>
            Text Analysis
          </Title>
          <Tag color="blue">
            Match: {data.output_json.avg_percent_match.toFixed(2)}%
          </Tag>
          <Tag color="green">Total Words: {data.output_json.total_words}</Tag>
          <Tag color="purple">
            Matched Words: {data.output_json.avg_words_match}
          </Tag>
        </Flex>
      }
      className="mb-4">
      <div style={{ maxHeight: "400px", overflow: "auto", padding: "12px" }}>
        {renderHighlightedText()}
      </div>
    </Card>
  );
};

const MatchedDocuments = ({ data }) => {
  const isMobile = useIsMobile();
  const truncateTitle = (title, maxLength = 30) =>
    title?.length > maxLength ? `${title.slice(0, maxLength)}...` : title;
  return data?.output_json.data?.map((source, index) => (
    <Card
      key={source.uid}
      size="small"
      style={{
        width: "100%",
        maxWidth: isMobile ? "max-content" : "850px",
        maxHeight: isMobile ? "max-content" : "400px",
        overflowY: "scroll",
      }}
      title={
        <Flex gap="middle" align="center" style={{ maxWidth: "100px" }}>
          <Popover content={source.metadata.title} placement="top">
            <Text strong>
              Source {index + 1}: {truncateTitle(source.metadata.title)}
            </Text>
          </Popover>

          <Tag color="blue">
            Match: {source.z_calc.match_percent.toFixed(2)}%
          </Tag>
          <Tag color="cyan">Order ID: {source.metadata.order_id}</Tag>
        </Flex>
      }>
      {source?.matches?.map((match, matchIndex) => (
        <Card
          key={matchIndex}
          size="small"
          className="mb-2"
          bordered={false}
          style={{ width: "100%" }}>
          <Flex vertical gap="small" style={{ width: "100%" }}>
            <Flex gap="small">
              <Tag color="green">Words: {match.words}</Tag>
            </Flex>
            <Text>
              <span
                style={{
                  backgroundColor: "#ffd591",
                  padding: "2px 4px",
                  borderRadius: "2px",
                  display: "inline-block",
                  lineHeight: "1.6",
                }}>
                {match?.match}
              </span>
            </Text>
          </Flex>
        </Card>
      ))}
    </Card>
  ));
};

export default function DuplitextSubmissionList({
  submissionList,
  isLoading,
  setIsLoading,
  orderKey,
  getSubmissionList,
  setSubmissionList,
}) {
  const [isAddingSubmission, setIsAddingSubmission] = useState(false); // New state
  const [expandedRow, setExpandedRow] = useState(null);
  const [submissionDetail, setSubmissionDetail] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [text, setText] = useState("");
  const [addingToIndex, setAddingToIndex] = useState(false);
  const isMobile = useIsMobile();
  const handleFetchSubmissionDetail = async (uid) => {
    try {
      setIsLoading(true);
      const response = await orderService.duplitextSubmissionDetail(uid);
      if (response.success) {
        setIsLoading(false);
        setSubmissionDetail(response?.data?.data);
        setExpandedRow(expandedRow === uid ? null : uid);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleAddtoIndex = async (uid) => {
    try {
      setIsLoading(true);
      const response = await orderService.addToIndex(uid);
      if (response.success) {
        message.success("Indexed Successfully")
        await getSubmissionList(); // Refresh the list to reflect the change
      }
    } catch (error) {
      console.error("Error adding to index:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      title: "Assignment",
      dataIndex: "assignment",
      key: "assignment",
      width: "10%",
      render: (text, record) => (
        <Button
          type="text"
          icon={<EyeOutlined />}
          onClick={() => handleFetchSubmissionDetail(record.uid)}
        >
          {text.length > 20 ? `${text.slice(0, 20)}...` : text}
        </Button>
      ),
    },
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      width: "10%",
    },
    {
      title: "Indexed",
      dataIndex: "is_indexed",
      key: "is_indexed",
      width: "10%",
      render: (isIndexed) => (
        <Tag color={isIndexed ? "green" : "red"}>
          {isIndexed ? "Yes" : "No"}
        </Tag>
      ),
    },
    {
      title: "Searched",
      dataIndex: "is_searched",
      key: "is_searched",
      width: "10%",
      render: (isSearched) => (
        <Tag color={isSearched ? "green" : "red"}>
          {isSearched ? "Yes" : "No"}
        </Tag>
      ),
    },
  ];
  const handleAddSubmissionClick = () => {
    setIsAddingSubmission(true);
  };

  const handleSaveSubmission = async () => {
    try {
      setIsSaving(true);
      const response = await orderService.submitDuplitext(orderKey, { text });
      if (response.success) {
        setSubmissionList([]);
        setIsSaving(false);

        setIsAddingSubmission(false);
        await getSubmissionList();

        if (submissionList.length > 0) {
          console.log(submissionList[0]?.uid, "submisison");

          await handleFetchSubmissionDetail(response?.data?.data.uid);
        }

        setText("");
      }
    } catch (error) {
      setIsSaving(false);
      console.error("Error submitting text:", error);
    } finally {
      setIsSaving(false);

      setIsLoading(false);
    }
  };
  return (
    <Flex vertical align="flex-end" style={{ width: "100%" }} gap={16}>
      {!isAddingSubmission ? (
        <>
          <Button type="primary" onClick={handleAddSubmissionClick}>
            Add Submission
          </Button>
          <Table
            loading={isLoading}
            columns={columns}
            dataSource={submissionList}
            scroll={{ y: 350 }}
            rowKey="uid"
            pagination={false}
            bordered
            size="small"
            style={{ width: "100%" }}
            expandable={{
              expandedRowKeys: expandedRow ? [expandedRow] : [],
              showExpandColumn: false,
              expandedRowRender: (record) =>
                submissionDetail && record.uid === expandedRow ? (
                  <Flex
                    vertical
                    style={{ width: isMobile ? "80vw" : "100%" }}
                    justify="center"
                    gap={10}
                    align="flex-end">
                    {!record.is_indexed && (
                      <Button
                        type=""
                        loading={isLoading}
                        onClick={() => handleAddtoIndex(record.uid)}>
                        Add to Index
                      </Button>
                    )}
                    <TextAnalysis data={submissionDetail} />

                    {submissionDetail?.output_json?.data?.length > 0 && (
                      <Flex
                        vertical
                        style={{ width: "100%" }}
                        justify="flex-start"
                        align="flex-start">
                        <Title style={{ textAlign: "left" }} level={5}>
                          Matched Documents
                        </Title>
                        <MatchedDocuments data={submissionDetail} />
                      </Flex>
                    )}
                  </Flex>
                ) : null,
            }}
          />
        </>
      ) : (
        <div style={{ width: "100%" }}>
          <Title level={5}>Text</Title>
          <Input.TextArea
            rows={10}
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="Enter File Text here..."
          />
          <Flex
            gap={8}
            style={{ marginTop: 16, width: "100%" }}
            justify="flex-end"
            align="flex-end">
            <Button
              type="primary"
              loading={isSaving}
              onClick={handleSaveSubmission}>
              Save
            </Button>
            <Button onClick={() => setIsAddingSubmission(false)}>Cancel</Button>
          </Flex>
        </div>
      )}
    </Flex>
  );
}
