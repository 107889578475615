import { Button, Empty, Modal, Table } from 'antd';
import moment from 'moment';
import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive';

const MobileSubscriberTable = ({ data }) => {

    const columns = [
        {
            title: 'Key',
            dataIndex: 'key',
            key: 'key',
            render: (text) => <strong>{text}</strong>,
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            render: (text) => (
                <span style={{ wordWrap: "break-word", wordBreak: "break-word", whiteSpace: "normal" }}>
                    {text || "N/A"}
                </span>
            ),
        },
    ];

    const transformDataForTable = (log) => [
        { key: 'Freelancer', value: log.fl_subscriber },
        { key: 'Allotted Deadline To Freelancer:', value: log.deadline ? moment(log.deadline).local().format("DD-MM-YYYY hh:mm A") : 'N/A' },
        { key: 'Amount', value: log.amount },
        { key: 'Currency', value: log.currency },
        { key: 'Amount Deduction', value: log.amount_deduction },

    ];

    return (
        <div style={{ backgroundColor: "#f2f6f9", padding: '10px' }}>
            {data && data.length > 0 ? (
                data.map((log, index) => (
                    <Table
                        key={index}
                        columns={columns}
                        dataSource={transformDataForTable(log)}
                        pagination={false}
                        bordered
                        size="small"
                        style={{ marginBottom: '25px', borderRadius: '5px' }}
                        showHeader={false}
                    />
                ))
            ) : (
                <Empty description="No data available" />
            )}
        </div>
    );

}


const FlSubscribersComp = ({ subscribers }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

    const showModal = () => setIsModalVisible(true);
    const closeModal = () => setIsModalVisible(false);


    const columns = [
        { title: 'Freelancer', dataIndex: 'fl_subscriber', key: 'fl_subscriber', render: (text) => text || 'N/A' },
        {
            title: 'Allotted Deadline To Freelancer:', dataIndex: 'deadline', key: 'deadline', render: (text) => {
                return text
                    ? moment(text).local().format("DD-MM-YYYY hh:mm A")
                    : 'N/A';
            },
        },
        { title: 'Amount:', dataIndex: 'amount', key: 'amount', render: (text) => text || 'N/A' },
        { title: 'Currency', dataIndex: 'currency', key: 'currency', render: (text) => text || 'N/A' },
        { title: 'Amount Deduction', dataIndex: 'amount_deduction', key: 'amount_deduction', render: (text) => text || 'N/A' },
    ];

    const dataSource = subscribers
        ? subscribers.map((log, index) => ({
            key: index,
            fl_subscriber: log.fl_subscriber,
            deadline: log.deadline,
            amount: log.amount,
            currency: log.currency,
            amount_deduction: log.amount_deduction,

        }))
        : [];


    const keyValueData = [
        {
            key: '1',
            label: 'FL Subscribers',
            value: subscribers.length > 0 ? (
                <Button type="link" onClick={showModal}>
                    View FL Subscribers
                </Button>
            ) : (
                'No Subscribers'
            ),
        },
    ];

    return (
        <div>

            <Table
                dataSource={keyValueData}
                pagination={false}
                bordered
                showHeader={false}
                rowKey="key"
                style={{ padding: '0px' }}
            >
                <Table.Column
                    title="Key"
                    dataIndex="label"
                    key="label"
                    render={(text) => <strong>{text}</strong>}
                    width="50%"
                />
                <Table.Column
                    title="Value"
                    dataIndex="value"
                    key="value"
                    render={(value) => <div style={{ textAlign: 'center' }}>{value}</div>}
                    width="50%"
                />
            </Table>

            <Modal
                title="Freelancer Details"
                visible={isModalVisible}
                onOk={closeModal}
                onCancel={closeModal}
                footer={[
                    <Button key="close" onClick={closeModal}>
                        Close
                    </Button>,
                ]}
                width={1000}
                style={{
                    maxHeight: "500px",
                    overflowY: "auto",
                }}

            >
                {
                    isMobile
                        ? <MobileSubscriberTable data={subscribers} />
                        :
                        <Table
                            dataSource={dataSource}
                            columns={columns}
                            pagination={false}
                            bordered
                            size="small"
                            footer={null}
                            scroll={{ y: 350 }}
                        />
                }

            </Modal>

        </div>
    )
};


export default FlSubscribersComp