import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  Button,
  Tooltip,
  Avatar,
  Popover,
  Typography,
  Flex,
} from "antd";
import {
  EyeOutlined,
  CheckOutlined,
  CloseOutlined,
  CheckCircleOutlined,
  EyeFilled,
} from "@ant-design/icons";
import usePermissions from "../../hooks/usePermissions";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const AcceptRejectOrdersTable = ({
  payoutData,
  loading,
  pagination,
  setPagination,
  handleOpenModal,
  onRowSelect,
  onAccept,
  onReject,
}) => {
  const { hasPermission } = usePermissions();

  const [columns, setColumns] = useState([]);
  const [timeElapsedArray, setTimeElapsedArray] = useState({});
  const timeElapsedRef = useRef({});
  const navigate = useNavigate();

  // Function to calculate elapsed time
  const calculateTimeElapsed = (created_at) => {
    if (!created_at) return "N/A";

    const createdDate = moment(created_at);
    const now = moment();
    const duration = moment.duration(now.diff(createdDate));

    const days = duration.days();
    const hours = String(duration.hours()).padStart(2, "0");
    const minutes = String(duration.minutes()).padStart(2, "0");
    const seconds = String(duration.seconds()).padStart(2, "0");

    return `${days > 0 ? `${days}d ` : ""}${hours}:${minutes}:${seconds}`;
  };

  // Update "Time Elapsed" every second
  useEffect(() => {
    if (!payoutData || payoutData.length === 0) return;

    const updateElapsedTimes = () => {
      payoutData.forEach((record) => {
        timeElapsedRef.current[record.key] = calculateTimeElapsed(
          record.created_at
        );
      });

      setTimeElapsedArray({ ...timeElapsedRef.current });
    };

    updateElapsedTimes(); // Initial update
    const interval = setInterval(updateElapsedTimes, 1000); // Update every second

    return () => clearInterval(interval);
  }, [payoutData]);

  // Generate table columns
  useEffect(() => {
    if (!payoutData || payoutData.length === 0) return;

    const sampleRecord = payoutData[0];
    const dynamicColumns = Object.keys(sampleRecord)
      .map((key) => {
        if (
          [
            "created_at",
            "tags",
            "client_deadline",
            "source",
            "move_to_call_allocation",
          ].includes(key)
        )
          return null;

        const columnConfig = {
          title: key
            .replace(/_/g, " ")
            .replace(/\b\w/g, (c) => c.toUpperCase()),
          dataIndex: key,
          key,
        };

        switch (key) {
          case "key":
            return {
              ...columnConfig,
              title: "Order ID",
              fixed: "left",
              render: (text, record) => (
                <Flex vertical align="flex-start">
                  <Typography.Link
                    onClick={() =>
                      navigate(`/order-management/my-orders/${text}`)
                    }>
                    {text}
                  </Typography.Link>
                  <small style={{ color: "grey" }}>
                    {moment(record.created_at)
                      .local()
                      .format("DD-MM-YYYY hh:mm a")}
                  </small>
                </Flex>
              ),
            };
          case "description":
            return {
              ...columnConfig,
              title: "Description",
              render: (desc) =>
                desc ? (
                  <Popover
                    content={
                      <div
                        style={{
                          maxHeight: "300px",
                          maxWidth: "500px",
                          overflowY: "scroll",
                        }}
                        dangerouslySetInnerHTML={{ __html: desc }}
                      />
                    }>
                    <Button type="link" style={{ padding: 0 }}>
                      View
                    </Button>
                  </Popover>
                ) : (
                  "-"
                ),
            };

          case "title":
            return {
              ...columnConfig,
              title: "Title",
              render: (text) =>
                text.length > 20 ? (
                  <Tooltip title={text}>{text.substring(0, 20)}...</Tooltip>
                ) : (
                  text
                ),
            };
          case "allotted_deadline":
            return {
              ...columnConfig,
              title: "Allotted Deadline",
              render: (_, record) => {
                const deadline =
                  record.source === "Manual"
                    ? record.allotted_deadline
                    : record.client_deadline; // Default to client_deadline if source is null or automatic

                return (
                  <Typography.Text>
                    {deadline
                      ? moment(deadline).local().format("DD-MM-YYYY hh:mm a")
                      : "-"}
                  </Typography.Text>
                );
              },
            };

          case "word_count":
            return {
              ...columnConfig,
              title: "Word Count",
              render: (text) => text || "-",
            };
          case "visibility":
            return {
              ...columnConfig,
              title: "Visibility",
              render: (visibilityList) => {
                if (!visibilityList || visibilityList.length === 0) return "-";
                const avatars = visibilityList
                  .slice(0, 2)
                  .map((name, index) => {
                    const initials = name
                      .split(" ")
                      .map((part) => part[0])
                      .join("")
                      .toUpperCase();
                    return (
                      <Tooltip title={name} key={index}>
                        <Avatar style={{ backgroundColor: "#9F580A" }}>
                          {initials}
                        </Avatar>
                      </Tooltip>
                    );
                  });

                return (
                  <div style={{ display: "flex", gap: 4 }}>
                    {avatars}
                    {visibilityList.length > 2 && (
                      <Popover
                        content={visibilityList.slice(2).map((name, index) => (
                          <div key={index}>{name}</div>
                        ))}
                        title="More Members">
                        <Avatar style={{ backgroundColor: "#ccc" }}>
                          +{visibilityList.length - 2}
                        </Avatar>
                      </Popover>
                    )}
                  </div>
                );
              },
            };

          case "files":
            return {
              ...columnConfig,
              title: "Files",
              render: (files) => {
                if (!files || files.length === 0) return "-";
                return (
                  <Popover
                    content={files.map((file, index) => (
                      <div key={index} style={{ textAlign: "left" }}>
                        <a
                          href={file.url}
                          target="_blank"
                          rel="noopener noreferrer">
                          {file.name || `File ${index + 1}`}
                        </a>
                      </div>
                    ))}
                    title="Files">
                    <Button type="link" style={{ padding: 0 }}>
                      {files.length > 1 ? "View Files" : "View File"}
                    </Button>
                  </Popover>
                );
              },
            };

          default:
            return columnConfig;
        }
      })
      .filter(Boolean);

    // Add "Time Elapsed" column
    dynamicColumns.push({
      title: "Time Elapsed",
      key: "elapsed_time",
      render: (_, record) => (
        <div style={{ color: "red" }}>
          {timeElapsedArray[record.key] || "Calculating..."}
        </div>
      ),
    });

    // Add "Action" column
  // Add "Action" column only if the user has permission
if (hasPermission("can_accept_or_reject_task")) {
  dynamicColumns.push({
    title: "Action",
    key: "action",
    fixed: "right",
    render: (_, record) => {
      const moveToCallAllocation = record.move_to_call_allocation;
      const showButtons =
        moveToCallAllocation === "closed" || moveToCallAllocation === null;
      const message =
        moveToCallAllocation === "open"
          ? "Requirement Request Raised to Call Allocation"
          : moveToCallAllocation === "closed"
          ? "Call Allocation Requirement Provided"
          : null;

      return (
        <Flex align="flex-start" justify="flex-start">
          <Flex gap="small" style={{ opacity: showButtons ? 1 : 0.5 }}>
            <Tooltip title="Accept">
              <Button
                type="primary"
                style={{ background: "#22c55e" }}
                shape="circle"
                icon={<CheckOutlined />}
                onClick={() => onAccept(record)}
                disabled={!showButtons}
              />
            </Tooltip>
            <Tooltip title="Reject">
              <Button
                type="danger"
                style={{ background: "#E02424", padding: 0 }}
                shape="circle"
                icon={<CloseOutlined />}
                onClick={() => onReject(record)}
                disabled={!showButtons}
              />
            </Tooltip>
          </Flex>

          <Popover
            content={
              <Typography.Text style={{ display: "block", width: 200 }}>
                {message}
              </Typography.Text>
            }
            title="Allocation Status"
            trigger="click">
            <Button
              disabled={!message}
              shape="circle"
              type="link"
              style={{
                padding: 0,
                background: "#4B5563",
                cursor: "pointer",
                marginLeft: 8,
                opacity: message ? 1 : 0.5,
                cursor: message ? "pointer" : "not-allowed",
              }}
              icon={<EyeFilled style={{ color: "white" }} />}
            />
          </Popover>
        </Flex>
      );
    },
  });
}


    setColumns(dynamicColumns);
  }, [payoutData, timeElapsedArray]); // Re-generate columns when payoutData or time updates

  return (
    <Table
      dataSource={payoutData}
      columns={columns}
      loading={loading}
      pagination={pagination}
      onChange={(pagination) => setPagination(pagination)}
      scroll={{ x: "max-content" }}
      rowKey="key"
    />
  );
};

export default AcceptRejectOrdersTable;
