import React, { useEffect, useState } from "react";
import { Modal, Spin,Button } from "antd"; // Ant Design components
import OrderLogServices from "../services/OrderLogServices";
import { CloseOutlined } from "@ant-design/icons";
import "./css/OrderTable.css"
const EnabledCountriesModal = ({ isVisible, onClose }) => {
  const [enabledCountries, setEnabledCountries] = useState(null); // Store API data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await OrderLogServices.getTwiliioEnabledCountries();
        if (response.success) {
          setLoading(false);
          setEnabledCountries(response.data.data.enabled_countries);
        }
        // Assuming API response has this format
      } catch (err) {
        setError("Failed to load countries.");
      } finally {
        setLoading(false);
      }
    };

    if (isVisible) {
      fetchData();
    }
  }, [isVisible]);

  return (
    <Modal
    className="country-modal"
      title="Enabled Countries"
      visible={isVisible}
      onCancel={onClose} // Keep this for proper closing behavior
      footer={[
        <Button key="cancel" onClick={onClose} style={{ background: "#f0f0f0", border: "1px solid #d9d9d9" }}>
          Cancel
        </Button>,
      ]}
  closeIcon={<CloseOutlined color="black" style={{  color: "#000", fontSize: "16px" }} />} 
      width={600}>
      <div style={{ maxHeight: "400px", overflowY: "auto" }}>
        {loading ? (
          <div style={{ textAlign: "center", padding: "20px" }}>
            <Spin size="large" />
          </div>
        ) : error ? (
          <div style={{ textAlign: "center", color: "red", padding: "20px" }}>
            {error}
          </div>
        ) : (
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr style={{ backgroundColor: "#f0f0f0" }}>
                <th
                  style={{
                    padding: "8px",
                    border: "1px solid #ddd",
                    textAlign: "left",
                  }}>
                  Country
                </th>
                <th
                  style={{
                    padding: "8px",
                    border: "1px solid #ddd",
                    textAlign: "left",
                  }}>
                  Country Code
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(enabledCountries || {}).map(([country, code]) => (
                <tr key={country} style={{ borderBottom: "1px solid #ddd" }}>
                  <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                    {country}
                  </td>
                  <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                    {code}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </Modal>
  );
};

export default EnabledCountriesModal;
