import axiosInstance from "./axiosInstance";
import { catchError } from "../utils/errorHandler";
import { message } from "antd";

const moveToFlAssociatesService = {
  getmoveToFlAssociatesOrderList: async (page = 1, pageSize = 10, filters = {}) => {
    const query = new URLSearchParams({
      page,
      page_size: pageSize,
      ...filters, // Dynamically append all filters
    }).toString();
    try {

      const response = await axiosInstance.get(`/task/v2/move-to-fl-associate/?${query}`);
      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {
      if (error.response && error.response.status === 500) {
        message.error("Internal Server Error");
      }
      await catchError(error);
      return { success: false };
    }
  },
  getFlList: async () => {
    try {

      const response = await axiosInstance.get(`task/v2/fl-users/`);
      // console.log(response , 'respmse')

      if (response.status === 200) {
        return { success: true, data: response.data };
      }
      return { success: false };
    } catch (error) {

      await catchError(error);
      return { success: false };
    }
  },

};

export default moveToFlAssociatesService;