import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Button, Upload, Row, Col, Spin, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axiosInstance from '../../services/axiosInstance';
import { useParams } from 'react-router-dom';
import { catchError } from '../../utils/errorHandler';

const { Option } = Select;
const { TextArea } = Input;

const SubscriberDeductions = ({ handleModalClose }) => {
    const { orderKey } = useParams();
    const [form] = Form.useForm();
    const [subscribersList, setSubscribersList] = useState([]);
    const [subscribersListLoading, setSubscribersListLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);

    const fetchSubscribersList = async () => {
        setSubscribersListLoading(true);
        try {
            const response = await axiosInstance.get(`task/v2/task-subscribers/${orderKey}`);
            if (response?.status === 200 && response?.data?.status === "success") {
                setSubscribersList(response?.data?.data);
            } else {
                setSubscribersList([]);
            }
        } catch (err) {
            console.log("Error fetching subscribers list:", err);
            setSubscribersList([]);
        } finally {
            setSubscribersListLoading(false);
        }
    };

    useEffect(() => {
        fetchSubscribersList();
    }, []);

    const handleSubmit = async (values) => {
        setSubmitLoading(true);
        const payload = {
            files: values.files ? values.files.map(file => file.originFileObj) : [],
            uuid: values.uuid,
            word: values.word,
            reason: values.reason,
        };

        try {
            const response = await axiosInstance.post(`task/v2/task-subscribers-deductions/${orderKey}/`, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response?.data?.status === "success") {
                message.success(response.data.data);
                handleModalClose()
                form.resetFields();
            }
        } catch (err) {
            console.log('Error submitting form:', err);
            catchError(err);
        } finally {
            setSubmitLoading(false);
        }
    };

    return (
        <div>
            <Spin spinning={subscribersListLoading}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                >
                    <Form.Item
                        style={{ width: "100%" }}
                        name="uuid"
                        label="Subscriber"
                        rules={[{ required: true, message: 'Please select a subscriber' }]}
                    >
                        <Select
                            style={{ width: "100%" }}
                            placeholder="Select a subscriber" loading={subscribersListLoading}>
                            {subscribersList.map(subscriber => (
                                <Option key={subscriber.uuid} value={subscriber.uuid}>
                                    {subscriber.username}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        style={{ width: "100%" }}
                        name="word"
                        label="Word"
                        rules={[{ required: true, message: 'Please enter a word' }]}
                    >
                        <Input style={{ width: "100%" }} placeholder="Enter word" />
                    </Form.Item>

                    <Form.Item
                        style={{ width: "100%" }}
                        name="reason"
                        label="Reason"
                        rules={[{ required: true, message: 'Please enter a reason' }]}
                    >
                        <TextArea style={{ width: "100%" }} rows={4} placeholder="Enter reason" />
                    </Form.Item>

                    <Form.Item
                        style={{ width: "100%" }}
                        name="files"
                        label="Upload Files"
                        valuePropName="fileList"
                        getValueFromEvent={(e) => (Array.isArray(e) ? e : e && e.fileList)}
                    >
                        <Upload style={{ width: "100%" }} beforeUpload={() => false} multiple>
                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                        </Upload>
                    </Form.Item>



                    <Form.Item
                        style={{ width: "100%", display: "flex", justifyContent: "end" }} >
                        <Button type="primary" htmlType="submit" loading={submitLoading} block>
                            Submit
                        </Button>
                    </Form.Item>

                </Form>
            </Spin>
        </div >
    );
};

export default SubscriberDeductions;
