import React, { useEffect } from "react";
import {
  Tag,
  Avatar,
  Tooltip,
  Popover,
  Typography,
  Pagination,
  Spin,
  Flex,
} from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
// Function to format the key by removing underscores and other special characters
const formatKey = (key) => {
  // Replace underscores or other non-alphanumeric characters with spaces
  return key
    .replace(/_/g, " ") // Replace underscores with spaces
    .replace(/([A-Z])/g, " $1") // Add a space before capital letters
    .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
};

const MobileViewComponent = ({
  data,
  pagination,
  loadOrders,
  setPagination,
  loading,
}) => {
  useEffect(() => {
    console.log(pagination);
  }, [pagination]);
  // Function to render subscribers with max count and popover
  const renderSubscribers = (subscribers) => {
    if (!subscribers || subscribers.length === 0) return "N/A";

    const displayedSubscribers = subscribers.slice(0, 2);
    const remainingSubscribers = subscribers.slice(2);

    return (
      <Avatar.Group maxCount={3}>
        {displayedSubscribers.map((name, index) => (
          <Tooltip key={index} title={name}>
            <Avatar style={{ backgroundColor: "#1890ff" }}>
              {name.charAt(0).toUpperCase()}
            </Avatar>
          </Tooltip>
        ))}
        {remainingSubscribers.length > 0 && (
          <Popover
            content={
              <div>
                {remainingSubscribers.map((name, index) => (
                  <p key={index}>{name}</p>
                ))}
              </div>
            }
            title="Additional Subscribers">
            <Avatar style={{ backgroundColor: "#f56a00" }}>
              +{remainingSubscribers.length}
            </Avatar>
          </Popover>
        )}
      </Avatar.Group>
    );
  };

  // Generic function to render a row with key-value pair
  const renderRow = (key, value) => (
    <div
      style={{
        display: "flex",
        borderBottom: "1px solid #e8e8e8",
        alignItems: "flex-start",
        minHeight: "44px",
        justifyContent: "flex-start",
      }}>
      <div
        style={{
          width: "35%",
          padding: "8px",
          fontWeight: "bold",
          borderRight: "1px solid #e8e8e8",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          textAlign: "left",
        }}>
        <strong>{key}: </strong>
      </div>
      <div
        style={{
          width: "65%",
          padding: "8px",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          textAlign: "left",
        }}>
        <span>{value}</span>
      </div>
    </div>
  );

  const handlePaginationChange = (page, pageSize) => {
    console.log(pageSize.page, "pahfe");
    // Update the pagination in parent state
    setPagination((prev) => ({
      ...prev,
      current: page,
      pageSize: pageSize,
    }));

    // Trigger data loading with updated pagination
    loadOrders(null, page, pageSize);
  };

  return (
    <>
      {loading ? (
        <Flex align="center" justify="center" style={{ width: "100%" }}>
          {" "}
          <Spin size="large" />
        </Flex>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            justifyContent: "center",
            width: "100%",
            position: "relative",
            left: 0,
          }}>
          {data?.map((record) => (
            <div
              key={record.key}
              style={{
                minWidth: "80%",
                background: "white",
                borderRadius: "8px",
                overflow: "hidden",
                border: "1px solid #e8e8e8",
              }}>
              {Object.keys(record).map((key) => {
                const value = record[key];
                // Handle special cases for formatting values
                console.log(key,'res')
                if (key === "client_deadline") {
                  return renderRow(
                    formatKey("Client Deadline"),
                    value
                      ? moment(value).local().format("DD-MM-YYYY hh:mm A")
                      : "N/A"
                  );
                }
                if (key === "start_date") {
                  return renderRow(
                    formatKey("Start Date"),
                    value
                      ? moment(value).local().format("DD-MM-YYYY hh:mm A")
                      : "N/A"
                  );
                }
                if (key === "key") {
                  return renderRow(
                    formatKey("Order ID"),
                    <Link to={`/order-management/my-orders/${record.key}`}>
                      <Typography.Text
                        style={{ color: "black", fontWeight: 600 }}>
                        {value}
                      </Typography.Text>
                    </Link>
                  );
                }
                if (key === "tags") {
                  return null;
                }
                if (key === "internal_status") {
                  return renderRow(
                    formatKey("Internal Status"),
                    value || "N/A"
                  );
                }
                if (key === "is_executive") {
                  return renderRow(
                    formatKey("Is Executive"),
                    value !== undefined ? (value ? "Yes" : "No") : "N/A"
                  );
                }
                if (key === "pending_Feedback") {
                  return renderRow(
                    formatKey("Pending Feedback"),
                    value !== undefined ? (value ? "Yes" : "No") : "N/A"
                  );
                }
                if (key === "moved_to_fl") {
                  return renderRow(
                    formatKey("Moved to FL"),
                    value ? "Yes" : "No"
                  );
                }
                if (key === "moved_to_fl_team") {
                  return renderRow(
                    formatKey("Moved to FL Team"),
                    record?.moved_to_fl_team ? "Yes" : "No"
                  );
                }
                if (key === "subscribers") {
                  return renderRow(
                    formatKey("Subscribers"),
                    renderSubscribers(value)
                  );
                }
                if (key === "status") {
                  return renderRow(
                    formatKey("Status"),
                    <Tag
                      color={
                        value === "In Progress"
                          ? "orange"
                          : value === "Cancelled"
                          ? "volcano"
                          : value === "Completed"
                          ? "green"
                          : value === "Unpaid"
                          ? "red"
                          : "default"
                      }>
                      {value || "N/A"}
                    </Tag>
                  );
                }
                if (key === "payment") {
                  return (
                    <>
                      {value?.budget
                        ? renderRow(
                            formatKey("Budget"),
                            `${value.currency} ${value.budget} (${value.payment_status})`
                          )
                        : renderRow(formatKey("Budget"), "N/A")}
                      {value?.amount_paid
                        ? renderRow(
                            formatKey("Amount Paid"),
                            `${value.currency} ${value.amount_paid}`
                          )
                        : renderRow(formatKey("Amount Paid"), "N/A")}
                    </>
                  );
                }
                // For all other keys, render them dynamically
                return renderRow(formatKey(key), value || "N/A");
              })}
            </div>
          ))}
          <Pagination
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.total}
            onChange={handlePaginationChange}
            style={{ textAlign: "center", marginTop: "20px" }}
          />
        </div>
      )}
    </>
  );
};

export default MobileViewComponent;
