import React, { useEffect, useState } from "react";
import FixedOrderDetails from "../../page-components/OrderDetail/FixedOrderDetails";
import FixedDetails2 from "../../page-components/OrderDetail/FixedDetails2";
import CustomTabs from "../../page-components/OrderDetail/Tabs";
import orderService from "../../services/OrderLists"; // Adjust the path as needed
import { useParams } from "react-router-dom";
import { Spin, Flex, message } from "antd";
import CallFunc from "../../page-components/OrderDetail/CallFunc";
import { useSelector } from "react-redux";
import { catchError } from "../../utils/errorHandler";
import axiosInstance from "../../services/axiosInstance";

function OrderDetail() {
  const [orderData, setOrderData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activeOrderTab, setActiveOrderTab] = useState("Overview");
  const [errorMessage, setErrorMessage] = useState(null); // New state for error message

  const { orderKey } = useParams();

  const fetchOrderDetails = async () => {
    try {
      setIsLoading(true);
      const result = await axiosInstance.get(`task/v2/detail/${orderKey}`);
  
      if (result.status === 200) {
        console.log(result.data.data.start_date , ")(*&^%$#$%^&*(*&^%$#$%^&*(")
        setOrderData(result?.data || null);
        setErrorMessage(null); // Clear any existing error message on success
      }
    } catch (error) {
      if (error.response?.status === 402) {
        setIsLoading(false);
        // message.error("Request failed with status code 402");
      } else if (error.response?.status === 401) {
        setErrorMessage("Unauthorized access - please check your permissions.");
        setIsLoading(false);
        // message.error("Unauthorized access - please check your permissions.");
      }
      
      
      else {
        await catchError(error);
        setIsLoading(false)
      }
   
    } finally {
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    fetchOrderDetails();
  }, [orderKey]);

  const { user } = useSelector((state) => state.auth);

  if (!user) {
    console.log("No user permissions available in order Details Main Page");
    return [];
  }

  const permissions = user?.user?.permissions;

  if (errorMessage) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <h2>{errorMessage}</h2>
      </div>
    );
  }

  return isLoading ? (
    <Flex
      justify="center"
      align="center"
      style={{ width: "100%", height: "80vh" }}>
      <Spin size="large" />
    </Flex>
  ) : (
    <>
    <FixedOrderDetails orderData={orderData} />
    <FixedDetails2 orderData={orderData} />
    <CustomTabs
      orderData={orderData}
      setActiveOrderTab={setActiveOrderTab}
    />
    <div style={{ position: "fixed", bottom: "30px", right: "35px" }}>
      <CallFunc
        orderData={orderData}
        activeOrderTab={activeOrderTab}
        permissions={permissions}
        fetchOrderDetails={fetchOrderDetails} // Pass the fetch function
      />
    </div>
  </>
  );
}

export default OrderDetail;
