import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// In your index.js or App.js
if (process.env.NODE_ENV === 'production') {
    window.addEventListener('error', (event) => {
      event.preventDefault(); // Prevent the error overlay
    });
  
    window.addEventListener('unhandledrejection', (event) => {
      event.preventDefault(); // Prevent unhandled promise rejection display
    });
  }
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
