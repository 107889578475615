import React, { useEffect, useState } from "react";
import {
  Table,
  Tag,
  message,
  Typography,
  Tooltip,
  Popover,
  Button,
} from "antd";

import { Link } from "react-router-dom";
import moment from "moment";
import FlOrdersForPayoutService from "../../services/flOrdersForPayout";

const { Text } = Typography;

const PayoutTable = ({
  selectedRecord,
  loading,
  setPanCardAvailaible,
  modalType,
  tableData,
  setTableData,
}) => {
  const [columns, setColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchPayoutDetails = async () => {
      setIsLoading(true);
      try {
        const response = await FlOrdersForPayoutService.getFlOrderPayoutDetail(
          selectedRecord.uuid
        );
        if (response.success) {
          const results = response.data?.data?.results || [];

          setPanCardAvailaible(response?.data?.data?.pancard);
          setTableData(results.map((item, index) => ({ key: index, ...item })));
          setIsLoading(false);
        }
      } catch (error) {
        message.error("Error fetching payout details.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchPayoutDetails();
  }, [selectedRecord]);

  // Generate columns dynamically (excluding multiple_freelancer)
  useEffect(() => {
    if (tableData.length > 0) {
      const dynamicColumns = Object.keys(tableData[0])
        .filter((key) => key !== "multiple_freelancer") // Exclude `multiple_freelancer`
        .map((key) => ({
          title: key
            .replace(/_/g, " ") // Replace underscores with spaces
            .replace(/\b\w/g, (char) => char.toUpperCase()), // Capitalize first letter of each word
          dataIndex: key,
          key,
          render: (text, record) => {
            if (key === "deduction_applied") {
              return text ? "Yes" : "No";
            }
            if (key === "key") {
              return (
                <Link
                  to={`/order-management/my-orders/${record[key]}`}
                  style={{ textDecoration: "underline" }}>
                  {record[key]}
                </Link>
              );
            }
            if (key === "status") {
              return <Tag color="purple">{text}</Tag>;
            }
            if (
              ["deadline", "date_of_completion"].includes(key) &&
              moment(text, moment.ISO_8601, true).isValid()
            ) {
              return moment(text).format("DD-MM-YYYY HH:mm");
            }

            if (key === "fl_budget" && Array.isArray(text)) {
              return renderFlBudget(text);
            }
            if (key === "client_budget" && text && typeof text === "object") {
              return text.budget
                ? `${text.budget} ${text.currency || ""}`
                : "N/A";
            }
            if (key === "alloted_amount" && text && typeof text === "object") {
              return text.budget
                ? `${text.budget} ${text.currency || ""}`
                : "N/A";
            }
            if (key === "word_count") {
              return <Text>{text}</Text>;
            }
            return text || "N/A";
          },
        }));
      setColumns(dynamicColumns);
    }
  }, [tableData]);

  // Function to render FL Budget column
  const renderFlBudget = (budgetArray) => {
    if (!budgetArray || budgetArray.length === 0) return "N/A";

    const taskBudget = budgetArray[0]; // First item (FL Task Budget)
    const extraBudgets = budgetArray.slice(1); // Remaining items (Extra Budgets)

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}>
        <Text strong>
          {taskBudget} <span style={{ color: "#888" }}>(INR)</span>
        </Text>
        {extraBudgets.length > 0 && (
          <Popover
            content={
              <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
                {extraBudgets.map((item, index) => (
                  <li key={index}>
                    {item} <span style={{ color: "#888" }}>(INR)</span>
                  </li>
                ))}
              </ul>
            }
            title="Extra Budget">
            <Button type="link" size="small" style={{ padding: 0 }}>
              View Extra Budget
            </Button>
          </Popover>
        )}
      </div>
    );
  };

  return (
    <div>
      {/* Note above the table */}

{tableData.some((record) => record.multiple_freelancer) && (
  <Text
    strong
    style={{ display: "block", marginBottom: 10, color: "#84E1BC" }}>
    This color indicates that the Order is assigned to multiple freelancers.
  </Text>
)}



      <Table
        dataSource={tableData}
        columns={columns}
        loading={loading || isLoading}
        pagination={{ pageSize: 10 }}
        scroll={{ x: "max-content" }}
        rowClassName={(record) =>
          record.multiple_freelancer ? "highlight-row" : ""
        } // Apply class if multiple_freelancer is true
      />

      {/* Row Styling */}
      <style>
        {`
          .highlight-row {
            background-color: #84E1BC !important;
          }
        `}
      </style>
    </div>
  );
};

export default PayoutTable;
