import React, { useState, useEffect } from "react";
import { Input, Button, Select, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import useIsMobile from "../../hooks/useIsMobile";
import { DatePicker } from "antd";
const { MonthPicker, RangePicker } = DatePicker; // Import MonthPicker

const { Option } = Select;

const FlOrderPayoutFilters = ({
  onSearch,
  pagination,
  onCancel,
  filtersList,
  typeChoices,

  flList,
  fetchingFl,
  statusList,
}) => {
  const { isMobile } = useIsMobile();
  console.log(statusList, "status");
  const [filters, setFilters] = useState({});

  const handleSearch = () => {
    const formatedStartDate = filters.start_date
      ? dayjs(filters.start_date).isValid()
        ? dayjs(filters.start_date).format("YYYY-MM-DD")
        : null
      : null;
    const formatedEndDate = filters.end_date
      ? dayjs(filters.end_date).isValid()
        ? dayjs(filters.end_date).format("YYYY-MM-DD")
        : null
      : null;

    const updatedFilters = { ...filters };

    // Apply formatted dates if valid, otherwise remove them
    if (formatedStartDate) {
      updatedFilters.start_date = formatedStartDate;
    } else {
      delete updatedFilters.start_date;
    }

    if (formatedEndDate) {
      updatedFilters.end_date = formatedEndDate;
    } else {
      delete updatedFilters.end_date;
    }

    // Call the search function with updated filters
    onSearch(updatedFilters);
  };

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, [key]: value };

      if (!value) {
        delete updatedFilters[key];
      }

      return updatedFilters;
    });
  };

  const handleCancel = async () => {
    setFilters({});
    await onCancel();
  };

  const renderFilter = (filter) => {
    switch (filter) {
      case "status":
        return (
          <Select
            placeholder="Status"
            style={{
              width: isMobile ? "80vw" : 300,
              textAlign: "left",
            }}
            value={filters.status || undefined}
            onChange={(value) => handleFilterChange("status", value)}
            showSearch={true} // Enable search
            optionFilterProp="children" // Filter options by their label
            allowClear>
            {statusList.map((status) => (
              <Select.Option key={status.key} value={status.key}>
                {status.value}
              </Select.Option>
            ))}
          </Select>
        );

      case "start_date":
        if (
          filtersList.includes("start_date") &&
          filtersList.includes("end_date")
        ) {
          return (
            <RangePicker
              style={{ width: isMobile ? "80vw" : 300 }}
              placeholder={["Start Date", "End Date"]}
              value={[filters.start_date || null, filters.end_date || null]}
              onChange={(dates) => {
                handleFilterChange("start_date", dates ? dates[0] : null);
                handleFilterChange("end_date", dates ? dates[1] : null);
              }}
            />
          );
        }
        return null; // Prevent duplicate rendering

      case "requested_by":
        return (
          <Select
            loading={fetchingFl}
            placeholder="Select Freelancer"
            style={{ width: isMobile ? "80vw" : 300, textAlign: "left" }}
            value={filters.requested_by ?? undefined}
            onChange={(value) =>
              handleFilterChange("requested_by", value || null)
            } // ✅ If cleared, send null
            showSearch
            optionFilterProp="children"
            allowClear
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }>
            {flList.map((freelancer) => (
              <Select.Option
                key={freelancer.username}
                value={freelancer.username}>
                {freelancer.full_name}
              </Select.Option>
            ))}
          </Select>
        );

      default:
        return (
          <Input
            placeholder={
              filter === "fl_name" ? "Filter by FL Name" : `Filter by ${filter}`
            }
            style={{ width: 300 }}
            value={filters[filter] || ""}
            onChange={(e) => handleFilterChange(filter, e.target.value)}
          />
        );
    }
  };

  const isWebsiteFilterPresent = filtersList.includes("website");

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{ background: "#eceff1", padding: "20px", borderRadius: "8px" }}>
        <div
          style={{
            marginBottom: 16,
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}>
          {filtersList
            .filter((f) => f !== "end_date")
            .map((filter) => (
              <div key={filter} style={{ marginRight: 16 }}>
                {renderFilter(filter)}
              </div>
            ))}
          <div
            style={{
              display: "flex",
              gap: "20px",
              marginRight: isMobile ? 0 : "12px",
              justifyContent: isWebsiteFilterPresent
                ? "flex-end"
                : "space-between",
              width: isWebsiteFilterPresent ? "100%" : "auto",
            }}>
            <Button
              type="primary"
              style={{ width: 140, background: "#040724" }}
              icon={<SearchOutlined />}
              onClick={handleSearch}>
              Search
            </Button>
            <Button
              style={{ width: 140 }}
              type="default"
              onClick={handleCancel}>
              Reset
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlOrderPayoutFilters;
