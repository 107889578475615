import { Table } from 'antd';
import React from 'react';

const PaymentDetailsAccord = ({ orderData }) => {
    const paymentData = [];

    // Helper function to handle null or undefined values
    const getValueOrNA = (value) => (value !== null && value !== undefined ? value : 'N/A');

    // Extract payment information
    const payment = orderData?.data?.payment || {};

    if (payment.budget || payment.budget === 0) {
        paymentData.push({
            key: 'Budget',
            field: 'Budget',
            value: `${getValueOrNA(payment.budget)} ${getValueOrNA(payment.currency)}`,
        });
    }

    if (payment.amount_paid || payment.amount_paid === 0) {
        paymentData.push({
            key: 'AmountPaid',
            field: 'Amount Paid',
            value: `${getValueOrNA(payment.amount_paid)} ${getValueOrNA(payment.currency)}`,
        });
    }

    if (payment.budget_without_discount || payment.budget_without_discount === 0) {
        paymentData.push({
            key: 'Discount',
            field: 'Discount',
            value: `${getValueOrNA(payment.budget_without_discount)} ${getValueOrNA(payment.currency)}`,
        });
    }

    const clientColumns = [
        { title: 'Field', dataIndex: 'field', key: 'field' },
        { title: 'Value', dataIndex: 'value', key: 'value' },
    ];

    return (
        <Table
            columns={clientColumns}
            dataSource={paymentData}
            pagination={false}
            showHeader={false}
            bordered
            size="small"
            style={{ width: "100%" }}
        />
    );
};

export default PaymentDetailsAccord;
