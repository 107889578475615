import { Table, Tag } from "antd";
import React from "react";

const PaymentDetailsAccord = ({ orderData }) => {
  const paymentData = [];

  // Helper function to handle null or undefined values
  const getValueOrNA = (value) =>
    value !== null && value !== undefined ? value : "N/A";
  const toInteger = (value) => {
    if (typeof value === "string") {
      return parseInt(value, 10) || 0; // Convert to integer, default to 0 if parsing fails
    }
    return value || 0; // If already a number or null/undefined, default to 0
  };

  // Extract payment information
  const payment = orderData?.data?.payment || {};
  if (payment.budget || payment.budget === 0) {
    if (
      payment.budget_without_discount &&
      payment.budget_without_discount !== payment.budget
    ) {
      const budget = toInteger(payment.budget);
      const budgetWithoutDiscount =
        toInteger(payment.budget_without_discount) + budget;

      // If there's a discount, show the original budget with strike-through and the discounted budget
      paymentData.push({
        key: "Budget",
        field: "Budget",
        value: (
          <span>
            <s style={{ color: "red", fontWeight: 600 }}>{`${getValueOrNA(
              budgetWithoutDiscount
            )} ${getValueOrNA(payment.currency)}`}</s>
            <Tag
              style={{
                marginLeft: 10,
                background: "green",
                color: "white",
                fontWeight: 600,
              }}>{`   ${getValueOrNA(payment.budget)} ${getValueOrNA(
              payment.currency
            )}`}</Tag>
          </span>
        ),
      });
    } else {
      // If no discount, just show the budget
      paymentData.push({
        key: "Budget",
        field: "Budget",
        value: `${getValueOrNA(payment.budget)} ${getValueOrNA(
          payment.currency
        )}`,
      });
    }
  }

  // if (payment.budget || payment.budget === 0) {
  //     paymentData.push({
  //         key: 'Budget',
  //         field: 'Budget',
  //         value: `${getValueOrNA(payment.budget)} ${getValueOrNA(payment.currency)}`,
  //     });
  // }

  if (payment.amount_paid || payment.amount_paid === 0) {
    paymentData.push({
      key: "AmountPaid",
      field: "Amount Paid",
      value: `${getValueOrNA(payment.amount_paid)} ${getValueOrNA(
        payment.currency
      )}`,
    });
  }

  if (
    payment.budget_without_discount ||
    payment.budget_without_discount === 0
  ) {
    paymentData.push({
      key: "Discount",
      field: "Discount",
      value: `${getValueOrNA(payment.budget_without_discount)} ${getValueOrNA(
        payment.currency
      )}`,
    });
  }

  const clientColumns = [
    { title: "Field", dataIndex: "field", key: "field" },
    { title: "Value", dataIndex: "value", key: "value" },
  ];

  return (
    <Table
      columns={clientColumns}
      dataSource={paymentData}
      pagination={false}
      showHeader={false}
      bordered
      size="small"
      style={{ width: "100%" }}
    />
  );
};

export default PaymentDetailsAccord;
