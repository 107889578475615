import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Button,
  Typography,
  Select,
  Switch,
  Tabs,
  Flex,
  message,
  Spin,
  Tooltip,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import orderService from "../../services/OrderLists";
import "react-quill/dist/quill.snow.css";
import "./OrderListTable.css";
import AddPaymentForm from "./AddPayment";
import AddOrderForm from "./AddOrderForm";
import commonService from "../../services/commonService";
import AddTransaction from "./AddTransaction";
import usePermissions from "../../hooks/usePermissions";
const { TabPane } = Tabs;

const OrderFormParent = ({ visible, onClose, setVisible, loadOrders , modalKey }) => {
  const { hasPermission } = usePermissions();

  const [form] = Form.useForm();
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [description, setDescription] = useState("");
  const [fileList, setFileList] = useState([]);
  const [referencingFileList, setReferencingFileList] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState(null);
  const [orderKey, setOrderKey] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [transactionData, setTransactionData] = useState({
    amount: null,
    // receipt: null,
    notify: false,
    client_transaction: null,
  });
  const [quotationData, setQuotationData] = useState({
    budget_without_discount: null,
    discount: null,
    currency: null,
    reminder: true,
    reminder_mail_date: null,
    is_vat_inclusive: false,
  });
  const [selectedRadioPayment, setSelectedRadioPayment] = useState(null); // For currency selection
  const [dropdownDataPayment, setDropdownDataPayment] = useState({
    currency: [],
  }); // Initialize with empty currency array
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    client_words: "",
    client_username: "",
    client_deadline: null,
    timezone: "",
    tags: [], // Initialize as empty array
    bdms: null,
    task_situation: "",
    quality_needed: "",
    specification: "",
    referencing_style: "",
    referencing_style_guide: [],
    makes_me_owner: true,
    files: [],
  });

  const [loading, setLoading] = useState(true); // Add loading state
  const [activeTabKey, setActiveTabKey] = useState("1");
  const [isPaymentTabDisabled, setIsPaymentTabDisabled] = useState(true);
  const [isTransactionTabDisabled, setIsTransactionTabDisabled] =
    useState(true);
  const { Title } = Typography;
  const [transactionList, setTransactionList] = useState([]);
  const [dropdownData, setDropdownData] = useState({
    qualities: [],
    specifications: [],
    references: [],
    situations: [],
    timezones: [],
    bdmOptions: [],
  });
  const handleRadioChange = (value) => {
    console.log(value, "is here");
    setSelectedRadio(value);
    setQuotationData((prevData) => ({
      ...prevData,
      currency: value, // Update the currency in the form data
    }));
  };

  useEffect(() => {
    if (visible && activeTabKey === "1") {
      const fetchDropdownData = async () => {
        setLoading(true); // Set loading to true before fetching data
        try {
          const qualityData = await orderService.getQualitiesDropdown();
          const specificationData =
            await orderService.getSpecificationDropdown();
          const referenceData = await orderService.getReferenceDropdown();
          const situationData = await orderService.getSituationDropdown();
          const timezoneData = await orderService.getTimezonesDropdown();

          let bdmData = [];
          try {
            const response = await orderService.getBdmMappingDropdown();
            bdmData = response.data.map((bdm) => ({
              value: bdm.username,
              label: bdm.full_name,
            }));
          } catch (error) {
            console.error("Error fetching BDM data:", error);
            bdmData = [];
          }

          // Now set all dropdown data
          setDropdownData({
            qualities: Object.entries(qualityData.data).map(([key, value]) => ({
              key,
              value,
            })),
            specifications: specificationData.data,
            references: referenceData.data,
            situations: situationData.data,
            timezones: timezoneData.data,
            bdmOptions: bdmData,
          });
        } catch (error) {
          console.error("Error fetching dropdown data:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchDropdownData();
    }
  }, [visible, activeTabKey]); // Fetch data only when modal is visible and active tab is 1

  useEffect(() => {
    if (visible && activeTabKey === "3") {
      const fetchTransactionList = async (orderKey) => {
        try {
          const response = await orderService.getTransaction(orderKey);
          setTransactionList(response.data);
        } catch (error) {
          console.error("Error fetching transaction list:", error);
        }
      };

      fetchTransactionList(orderKey);
    }
  }, [visible, activeTabKey, orderKey]); // Fetch transaction data when modal is visible and active tab is 3

  const handleTimezoneChange = (value) => {
    setFormData((prevData) => ({ ...prevData, timezone: value }));
  };

  const handleFileChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);

    // Extract only the originFileObj from each file entry
    const files = newFileList.map((file) => file.originFileObj).filter(Boolean);

    setFormData((prevData) => ({
      ...prevData,
      files,
    }));
  };

  const handleReferencingFileChange = ({ fileList: newFileList }) => {
    if (newFileList.length > 1) {
      newFileList = newFileList.slice(0, 1); // Limit the list to one file
    }
    setReferencingFileList(newFileList);

    // Extract only the originFileObj from each file entry
    const file = newFileList[0] ? newFileList[0].originFileObj : null;

    setFormData((prevData) => ({
      ...prevData,
      referencing_style_guide: file ? [file] : [], // Only keep one file in the referencing_style_guide
    }));
  };

  const handleSwitchChange = (checked) => {
    setFormData((prevData) => ({ ...prevData, makes_me_owner: checked }));
  };

  const handleReminderMail = (checked) => {
    setQuotationData((prevData) => ({ ...prevData, reminder: checked }));
  };
  const handleNotifyClient = (checked) => {
    setTransactionData((prevData) => ({ ...prevData, notify: checked }));
  };
  const handleDescriptionChange = (value) => {
    setDescription(value);
    setFormData((prevData) => ({ ...prevData, description: value }));
  };

  useEffect(() => {
    const fetchCurrencyList = async () => {
      const response = await commonService.getCurrencyList();
      if (response) {
        // console.log(response.data.data.currency, "response");
        setDropdownDataPayment({ currency: response.data.data.currency });
      } else {
        message.error("Failed to load currency list");
      }
      setLoading(false); // Loading complete
    };

    fetchCurrencyList();
  }, []);
  const handleDeleteFile = (index) => {
    const updatedFileList = [...fileList];
    updatedFileList.splice(index, 1); // Remove the file at the specified index
    setFileList(updatedFileList); // Update the state with the new file list

    // Update formData.files to remove the corresponding file
    const updatedFiles = [...formData.files];
    updatedFiles.splice(index, 1); // Remove the file at the specified index
    setFormData((prevData) => ({ ...prevData, files: updatedFiles })); // Update files in formData
  };
  const handleDeleteReferencingFile = (index) => {
    const updatedFileList = [...referencingFileList];
    updatedFileList.splice(index, 1); // Remove the file at the specified index
    setReferencingFileList(updatedFileList); // Update the state with the new file list

    // Update formData.referencing_style_guide to remove the corresponding file
    const updatedFiles = [...formData.referencing_style_guide];
    updatedFiles.splice(index, 1); // Remove the file at the specified index
    setFormData((prevData) => ({ ...prevData, referencing_style_guide: updatedFiles })); // Update files in formData
  };
  const handleRadioChangePayment = (value) => {
    setSelectedRadioPayment(value);

    setQuotationData((prevData) => ({
      ...prevData,
      currency: value, // Update the currency in the form data
    }));
  };

  const handleVATChange = (e) => {
    setQuotationData((prevData) => ({
      ...prevData,
      is_vat_inclusive: e.target.checked, // Update VAT inclusive state
    }));
  };
  const handleSubmit = async () => {
    setIsSubmitting(true); // Start loading state
    try {
      if (activeTabKey === "2") {
        await handleAddPayment(orderKey); // Pass the orderKey directly
        return; // Exit after handling payment
      }

      if (activeTabKey === "3") {
        await handleAddTransaction(orderKey);
        return;
      }
      // Your existing task submission logic
      await form.validateFields();

      const data = new FormData();
      Object.keys(formData).forEach((key) => {
        if (key !== "files") {
          if (key === "client_deadline" && formData[key]) {
            data.append(key, formData[key].toISOString());
          } else if (Array.isArray(formData[key])) {
            formData[key].forEach((item) => {
              if (item) data.append(key, item);
            });
          } else if (formData[key] !== null && formData[key] !== undefined) {
            data.append(key, formData[key]);
          }
        }
      });

      if (formData.files && formData.files.length > 0) {
        formData.files.forEach((file) => {
          data.append("files", file);
        });
      }

      const response = await orderService.createTask(data);
      if (response.success) {
        setOrderKey(response.data.data.key);
        setIsSubmitting(false); // End loading state
        setIsPaymentTabDisabled(false);

        message.success("Task created successfully");
        if (hasPermission("can_view_order_action_add_quotation")) {
          setActiveTabKey("2");
        }
        await loadOrders();

        form.resetFields();
        setFileList([]);
        setReferencingFileList([]);
        setDescription("");
        setFormData((prevData) => ({ ...prevData, files: [] }));
      } else {
        throw new Error(JSON.stringify(response.message));
      }
    } catch (error) {
      setIsSubmitting(false); // End loading state
      console.log(error, "erorrrr");
      //   message.error("Error submitting form: " + error.message);
      //   message.error("Failed to create task: " + error.message);
    } finally {
      setIsSubmitting(false); // End loading state
    }
  };
  const handleAddPayment = async (orderKey) => {
    try {
      console.log(quotationData, 1234);
      // Prepare payment data
      const paymentData = {
        ...quotationData,
        reminder_mail_date: quotationData.reminder_mail_date?.toISOString(), // Convert date to ISO string
      };
      // Call the addPayment function from orderService
      const addPaymentResponse = await orderService.addPayment(
        orderKey,
        paymentData
      );

      if (addPaymentResponse.success) {
        message.success("Payment added successfully!");
        console.log("kjh", addPaymentResponse?.data?.data?.currency_display);
        setSelectedCurrency(addPaymentResponse?.data?.data?.currency_display);
        setIsTransactionTabDisabled(false);
        setIsPaymentTabDisabled(true);
        if (hasPermission("can_view_order_action_add_transaction")) {
          setActiveTabKey("3");
        }
        await loadOrders();

        setQuotationData({
          budget_without_discount: null,
          discount: null,
          currency: null,
          reminder: true,
          reminder_mail_date: null,
          is_vat_inclusive: false,
        });
        form.resetFields(); // Reset the form fields after successful submission
      } else {
        message.error("Failed to add payment.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      message.error("Failed to create task: " + error.message);
    }
  };
  const handleAddTransaction = async (orderKey) => {
    try {
      // Prepare payment data
      const transactiondata = {
        ...transactionData,
      };
      // Call the addPayment function from orderService
      const addTransaction = await orderService.addTransaction(
        orderKey,
        transactiondata
      );

      if (addTransaction.success) {
        message.success("Payment added successfully!");

        setVisible(false);
        await loadOrders();
        setTransactionData({
          amount: null,
          // receipt: null,
          notify: true,
          client_transaction: null,
        });
        form.resetFields(); // Reset the form fields after successful submission
      } else {
        message.error("Failed to add payment.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      message.error("Failed to create task: " + error.message);
    }
  };
  const handleTabChange = (key) => {
    setActiveTabKey(key);
  };

  const handleCloseModal = () => {
    setFormData({
      title: "",
      description: "",
      client_words: "",
      client_username: "",
      client_deadline: null,
      timezone: "",
      tags: [], // Initialize as empty array
      bdms: null,
      task_situation: "",
      quality_needed: "",
      specification: "",
      referencing_style: "",
      referencing_style_guide: [],
      makes_me_owner: true,
      files: [],
    });
    setReferencingFileList([])
    setVisible(false);
  };

  return (
    <Modal
      key={modalKey}
      styles={{ body: { height: "500px" } }}
      className="create-order"
      width={800}
      title={
        <div
          className="custom-modal-header"
          style={{ display: "flex", justifyContent: "space-between" }}>
          <Title
            level={3}
            style={{
              color: "white",
              margin: 0,
              position: "relative",
              left: "12px",
            }}>
            Create Order
          </Title>
          <CloseOutlined
            style={{ fontSize: "16px", marginRight: "10px" }}
            onClick={handleCloseModal}
          />
        </div>
      }
      closable={false}
      okText="Submit"
      visible={visible}
      onCancel={handleCloseModal}
      bodyStyle={{ maxHeight: "80vh", overflowY: "auto" }}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          loading={isSubmitting}
          style={{ background: "#000" }}>
          Submit
        </Button>,
      ]}>
      {loading ? ( // Show loader or skeleton while loading
        <Flex
          align="center"
          justify="center"
          style={{
            textAlign: "center",
            padding: "20px",
            width: "90%",
            height: "100%",
          }}>
          <Spin size="large" />
        </Flex>
      ) : (
        <Tabs
          activeKey={activeTabKey}
          onChange={handleTabChange}
          tabBarExtraContent={
            activeTabKey === "1" ? (
              <div style={{ display: "flex", alignItems: "center" }}>
               <Tooltip title="Select this option to access and view the order, even if you are not the owner">
                  <label style={{ marginRight: "8px" }}>
                    Enable Visibility
                  </label>
                  </Tooltip>
                <Switch
                  checked={formData.makes_me_owner}
                  onChange={handleSwitchChange}
                />
              </div>
            ) : activeTabKey === "2" ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <label style={{ marginRight: "8px" }}>Reminder mail</label>
                <Switch
                  checked={quotationData.reminder} // Reflect the reminderMail state
                  onChange={handleReminderMail} // Use handleReminderMail for this switch
                />
              </div>
            ) : activeTabKey === "3" ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <label style={{ marginRight: "8px" }}>Notify Client</label>
                <Switch
                  checked={transactionData.notify} // Reflect the reminderMail state
                  onChange={handleNotifyClient} // Use handleReminderMail for this switch
                />
              </div>
            ) : null
          }>
          <TabPane tab="Order Details" key="1">
            <AddOrderForm
              formData={formData}
              dropdownData={dropdownData}
              setFormData={setFormData}
              setFileList={setFileList}
              setReferencingFileList={setReferencingFileList}
              referencingFileList={referencingFileList}
              fileList={fileList}
              description={description}
              handleDescriptionChange={handleDescriptionChange}
              setDropdownData={setDropdownData}
              setSelectedRadio={setSelectedRadio}
              selectedRadio={selectedRadio}
              handleFileChange={handleFileChange}
              handleReferencingFileChange={handleReferencingFileChange}
              handleRadioChange={handleRadioChange}
              handleTimezoneChange={handleTimezoneChange}
              handleDeleteFile={handleDeleteFile}
              handleDeleteReferencingFile={handleDeleteReferencingFile}
              loading={loading}
            />
          </TabPane>
          {hasPermission("can_view_order_action_add_quotation") && (
            <TabPane
              tab="Add Payment Details"
              key="2"
              disabled={isPaymentTabDisabled}>
              <Flex vertical gap={10}>
                <Typography.Title level={3} style={{ margin: 0 }}>
                  Order Key : {orderKey}
                </Typography.Title>
                <AddPaymentForm
                  orderKey={orderKey}
                  handleRadioChange={handleRadioChangePayment}
                  handleVATChange={handleVATChange}
                  dropdownData={dropdownDataPayment}
                  quotationData={quotationData}
                  selectedRadio={selectedRadioPayment}
                  setSelectedRadioPayment={setSelectedRadioPayment}
                  setQuotationData={setQuotationData}
                />
              </Flex>
            </TabPane>
          )}
          {hasPermission("can_view_order_action_add_transaction") && (
            <TabPane
              tab="Add Transaction"
              key="3"
              disabled={isTransactionTabDisabled}>
              <Flex vertical gap={10}>
                <Typography.Title level={3} style={{ margin: 0 }}>
                  Order Key : {orderKey}
                </Typography.Title>
                <AddTransaction
                  currency={selectedCurrency}
                  dropdownDataPayment={dropdownDataPayment}
                  transactionData={transactionData}
                  setTransactionData={setTransactionData}
                  transactionList={transactionList}
                />
              </Flex>
            </TabPane>
          )}
        </Tabs>
      )}
    </Modal>
  );
};

export default OrderFormParent;
