import React, { useState } from "react";
import {
  Card,
  Tooltip,
  Typography,
  Avatar,
  Button,
  Spin,
  Pagination,
  Row,
  Col,
  Empty,
  Descriptions,
  Popover,
  message,
  Tag,
  Form,
} from "antd";

import { EyeFilled, EyeInvisibleFilled ,EditOutlined} from "@ant-design/icons";
import ClientTabs from "./ClientTabs";
import usePermissions from "../../hooks/usePermissions";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import axiosInstance from "../../services/axiosInstance";
import { catchError } from "../../utils/errorHandler";
const { Text } = Typography;

const VisibilityGroup = ({ visibility }) => (
  <Avatar.Group>
    {visibility.map((vis, index) => (
      <Tooltip key={index} title={vis}>
        <Avatar style={{ backgroundColor: "#87d068" }}>
          {vis[0].toUpperCase()}
        </Avatar>
      </Tooltip>
    ))}
  </Avatar.Group>
);

const ClientsMobileView = ({
  data,
  clientDetails,
  getMyClients,
  loading,
  pagination,
  setPagination,
  getDetails, // Function to fetch and toggle client details
  setIsModalVisible,
  setSelectedRecord,
  setSelectedFlag,
  setIsOtpModalVisible,
  setSaveContact,
  viewMode
}) => {
  const [expandedClient, setExpandedClient] = useState(null);
  const { hasPermission } = usePermissions();
  const [visible, setVisible] = useState(false);

  const toggleExpand = async (client) => {
    const isExpanded = expandedClient === client.uid;
    if (isExpanded) {
      setExpandedClient(null);
    } else {
      setExpandedClient(client.uid);
      if (!clientDetails[client.uid]) {
        await getDetails(client); // Fetch details if not already fetched
      }
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      message.success("Payment link copied to clipboard!");
    });
  };

  const getDomainName = (url) => {
    try {
      const { hostname } = new URL(url);
      return hostname;
    } catch (error) {
      return url;
    }
  };

  const getLinkText = (url) => {
    const domain = getDomainName(url);
    if (domain.includes("oz")) {
      return "Link for OZ Assignments";
    } else if (domain.includes("anayalearning")) {
      return "Link for Anaya Learning";
    } else if (domain.includes("locusrags")) {
      return "Link for Locusrags";
    }
  };

  const handleOpenModalMobile = (record)=>{
     setSelectedRecord(record?.uid);
    setSelectedFlag(record?.bypass_otp_verification)
    setIsModalVisible(true);
   }

  const handleOpenOtpClick = (mobileNomber) => {
     setSaveContact(mobileNomber?.contactNo);
    setIsOtpModalVisible(true);
  };

  const handleUpdateContact = async (uid, values) => {

    const payload = {
      uid: uid,
      phone_number: values?.update.startsWith("+") ? values.update : `+${values.update}`,
    };
    
    // console.log(payload);
    

    try {
      const res = await axiosInstance.post(
        `useraccount/v2/client-update-phone-number/`,
        payload
      );
      if (res.status && res?.data?.status) {
        message.success(res?.data?.data);
        getMyClients()
      }
    } catch (err) {
      await catchError(err);
    }
  };

  return (
    <div style={{ padding: "2px", width: "100%" }}>
      {loading ? (
        <Spin tip="Loading clients..." />
      ) : data.length > 0 ? (
        <>
          {data.map((client) => {
            const formatedDate = moment(client.created_at)
              .local()
              .format("DD-MM-YYYY A");
            const clientPaymentLinks = clientDetails[client.uid] || [];
            const content = (
              <div>
                {clientPaymentLinks.length > 0 ? (
                  clientPaymentLinks.map((link, index) => (
                    <div
                      key={index}
                      onClick={() => copyToClipboard(link)}
                      style={{
                        cursor: "pointer",
                        color: "#1890ff",
                        marginBottom: "8px",
                      }}>
                      {getLinkText(link)}
                    </div>
                  ))
                ) : (
                  <div>No payment links available</div>
                )}
              </div>
            );

            return (
              <Card
                key={client.uid}
                style={{ marginBottom: "16px" }}
                hoverable
                bordered
                actions={[
                  hasPermission("can_view_lead_details") && (
                    <Button
                      style={{ float: "right", marginRight: "10%" }}
                      onClick={() => toggleExpand(client)}
                      icon={
                        expandedClient === client.uid ? (
                          <EyeInvisibleFilled />
                        ) : (
                          <EyeFilled />
                        )
                      }
                    />
                  ),
                ]}
              >
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ width: "100%" }}
                >
                  <Col span={24}>
                    <Descriptions
                      column={1}
                      size="small"
                      bordered
                      style={{ textAlign: "left" }}
                    >
                      <Descriptions.Item label="Name">
                        {client.name || "N/A"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Created At">
                        {formatedDate || "N/A"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Email">
                        {client.email || "N/A"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Username">
                        {client.username || "N/A"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Contact">
                        {client.contactNo || "N/A"}
                        {viewMode === "myClients" &&
                          client?.is_contact_verified === false && (
                            <Popover
                              placement="center"
                              content={
                                <Form
                                  layout="vertical"
                                  onFinish={(value) =>
                                    handleUpdateContact(client?.uid, value)
                                  }
                                  initialValues={{ update: client?.contactNo }}
                                >
                                  <Form.Item name="update">
                                    <PhoneInput
                                      inputStyle={{
                                        width: "220px",
                                        borderRadius: "4px",
                                        height: "32.5px",
                                      }}
                                      containerStyle={{
                                        borderRadius: "4px",
                                        marginLeft: "10px",
                                      }}
                                      country={"au"}
                                      placeholder={"Update Contact"}
                                      disableDropdown={true}
                                      value={client?.contactNo} // Ensure the field has initial value
                                      onChange={(phone) => {
                                        console.log(phone);
                                      }}
                                    />
                                  </Form.Item>

                                  <div
                                    style={{
                                      marginTop: "10px",
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      gap: "10px",
                                    }}
                                  >
                                    <Button htmlType="submit" type="primary">
                                      Update
                                    </Button>
                                  </div>
                                </Form>
                              }
                              trigger="click"
                            >
                              <Button
                                type="link"
                                icon={
                                  <EditOutlined
                                    style={{
                                      color: "#1890ff",
                                      fontSize: "16px",
                                    }}
                                  />
                                }
                              />
                            </Popover>
                          )}
                      </Descriptions.Item>
                      <Descriptions.Item label="Visibility">
                        <Row justify="space-between">
                          <VisibilityGroup visibility={client.visibility} />
                        </Row>
                      </Descriptions.Item>
                      <Descriptions.Item label="Ownership">
                        <Row justify="space-between">
                          {client?.ownership || "N/A"}
                        </Row>
                      </Descriptions.Item>
                      <Descriptions.Item label="Website">
                        <Row justify="space-between">
                          {client?.website || "N/A"}
                        </Row>
                      </Descriptions.Item>
                      <Descriptions.Item label="Status">
                        {client.status === "Active" ? (
                          <Tag color="green">{client.status}</Tag>
                        ) : (
                          <Tag color="red">{client.status}</Tag>
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item label="Bypass OTP Verification">
                        <Tag
                          color={
                            client?.bypass_otp_verification === false
                              ? "volcano"
                              : "green"
                          }
                        >
                          {client?.bypass_otp_verification === false
                            ? "Disabled"
                            : "Enabled"}
                        </Tag>
                        {hasPermission(
                          "can_bypass_otp_verification_clientwise"
                        ) && (
                          <Button
                            type="link"
                            icon={
                              <EditOutlined
                                style={{ color: "#1890ff", fontSize: "16px" }}
                              />
                            }
                            onClick={() => handleOpenModalMobile(client)}
                          />
                        )}
                      </Descriptions.Item>

                      <Descriptions.Item label="Contact Verified">
                        <Tag
                          color={
                            client?.is_contact_verified === true
                              ? "green"
                              : "volcano"
                          }
                        >
                          {client?.is_contact_verified === true ? "Yes" : "No"}
                        </Tag>
                        {viewMode === "myClients" &&
                          client?.is_contact_verified == false && (
                            <Button
                              type="link"
                              icon={
                                <EditOutlined
                                  style={{ color: "#1890ff", fontSize: "16px" }}
                                />
                              }
                              onClick={() => handleOpenOtpClick(client)}
                            />
                          )}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>

                {expandedClient === client.uid && (
                  <div style={{ marginTop: "16px" }}>
                    {hasPermission("can_view_client_details") &&
                      clientPaymentLinks.length > 0 && (
                        <Popover
                          content={content}
                          title={
                            <span
                              style={{
                                color: "#8c8c8c",
                                fontWeight: "normal",
                              }}
                            >
                              Click to copy
                            </span>
                          }
                          trigger="hover"
                          visible={visible}
                          onVisibleChange={(visible) => setVisible(visible)}
                        >
                          <Button type="link">View Payment Links</Button>
                        </Popover>
                      )}
                    <div style={{ marginTop: "16px" }}>
                      <ClientTabs
                        uid={client.uid}
                        username={client.username || "N/A"}
                        clientDetails={clientDetails[client.uid] || []}
                      />
                    </div>
                  </div>
                )}
              </Card>
            );
          })}

          <Pagination
            style={{ textAlign: "center", marginTop: "16px" }}
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.total}
            onChange={(page, pageSize) =>
              setPagination({ ...pagination, current: page, pageSize })
            }
            onShowSizeChange={(current, size) =>
              setPagination({ ...pagination, current: 1, pageSize: size })
            }
          />
        </>
      ) : (
        <Empty description="No clients available" />
      )}
    </div>
  );
};

export default ClientsMobileView;
