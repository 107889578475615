import React from "react";
import { Table, Tag, Descriptions, Pagination, Flex, Typography } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import "./orderProgress.css";

const OrderProgressTrackingTableList = ({
  data,
  pagination,
  setPagination,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  if (!data || data.length === 0) return <p>No data available</p>;

  // Function to format column titles
  const formatTitle = (key) =>
    key
      .replace(/_/g, " ")
      .toLowerCase()
      .replace(/^\w/, (c) => c.toUpperCase());

  // Function to render different column values
  const renderValue = (key, value) => {
    if (value === null || value === undefined || value === "") {
      return "-"; // ✅ Show "-" if value is null, undefined, or empty
    }
    if (key === "key") {
      return (
        <Link
          to={`/order-management/my-orders/${value}`}
          style={{ color: "#1890ff", fontWeight: "bold" }}>
          {value}
        </Link>
      );
    }

    if (
      [
        "writer_deadline",
        "manager_deadline",
        "client_deadline",
        "started_on",
      ].includes(key)
    ) {
      return moment(value).local().format("DD-MM-YYYY hh:mm a");
    }

    
    if (key === "status") {
      const statusColors = {
        "Not Started": "#F0F0F0",
        "In Progress": "#D0E4F2",
        Started: "#F0F0F0",
        Completed: "#C8E6C8",
        Failed: "#E8A0A0",
        Cancelled: "#A0A0A0",
        Paused: "#C2A892",
      };

      return (
        <Tag
          color={statusColors[value]}
          style={{ fontWeight: "500", color: "black" }}>
          {value.replace("_", " ")}
        </Tag>
      );
    }

    return value;
  };

  if (isMobile) {
    return (
      <Flex
        vertical
        justify="flex-end"
        align="flex-end"
        style={{ width: "100%" }}>
        {data.map((item, index) => {
          const isDeadlineToday = moment(item.writer_deadline).isSameOrBefore(
            moment(),
            "day"
          );

          return (
            <Descriptions
              key={index}
              bordered
              column={1}
              style={{
                marginBottom: 20,
                width: "100%",

                padding: "10px",
                borderRadius: "8px",
              }}>
              {Object.entries(item).map(([key, value]) => (
                <Descriptions.Item
                  key={key}
                  label={formatTitle(key)}
                  style={{
                    background:
                      key === "writer_deadline"
                        ? "#F8B4B4"
                        : isDeadlineToday
                        ? "#F8B4B4"
                        : "white", // ✅ Red only for writer_status
                    border: isDeadlineToday
                      ? "1px solid red"
                      : "1px solid #d9d9d9", // ✅ Optional border for visibility
                  }}>
                  {renderValue(key, value)}
                </Descriptions.Item>
              ))}
            </Descriptions>
          );
        })}
        <Pagination
          current={pagination.current}
          pageSize={pagination.pageSize}
          total={pagination.total}
          onChange={(page, pageSize) => {
            console.log("Page changed:", page);
            setPagination((prev) => ({ ...prev, current: page, pageSize }));
          }}
          style={{ textAlign: "center", marginTop: 16 }}
        />
      </Flex>
    );
  }

  // const columns = Object.keys(data[0] || {}).map((key) => ({
  //   title: formatTitle(key),
  //   dataIndex: key,
  //   key: key,
  //   render: (text) => (text ? text : "-"), // ✅ Show "-" if value is null or undefined
  // }));

  const columns = Object.keys(data[0])
    .filter((key) => key !== "status") // Exclude status temporarily
    .map((key) => ({
      title: formatTitle(key),
      dataIndex: key,
      key: key,
      render: (value) => renderValue(key, value),
    }));

  // Add "status" column at the end
  columns.push({
    title: formatTitle("status"),
    dataIndex: "status",
    key: "status",
    render: (status) => renderValue("status", status),
  });

  return (
    <Table
      rowClassName={(record) =>
      moment(record.writer_deadline).isSameOrBefore(moment(), "day")
          ? "highlight-row"
          : ""
      }
      columns={columns}
      dataSource={data}
      rowKey="task"
      pagination={{
        current: pagination.current,
        pageSize: pagination.pageSize, // ✅ Make sure this is 20
        total: pagination.total,
        showSizeChanger: true, // ✅ Allow pageSize change
        pageSizeOptions: ["10", "20", "50", "100"], // ✅ Allow selecting 20 explicitly
        onChange: (page, pageSize) => {
          setPagination((prev) => ({ ...prev, current: page, pageSize }));
        },
      }}
    />
  );
};

export default OrderProgressTrackingTableList;
