import React, { useState, useEffect } from "react";
import moment from "moment";
import dayjs from "dayjs";
import DateRangePicker from "../../components/DatePicker";
import salesService from "../../services/salesService";
import { Checkbox, Spin, Button, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import RegionTable from "./RegionTable";
import BarChartGraph from "./BarChartGraph";
import MultiCurrencyBarChart from "./MonthWiseGraphRegion";
import "./salesGraph.css";
const SalesGraph = ({
  setIsGraph,
  custom = false,
  uids = [],
  hierarchyClicked,
}) => {
  const startOfPreviousMonth = moment().subtract(1, "months").startOf("month");
  const endOfPreviousMonth = moment().subtract(1, "months").endOf("month");

  const [startDate, setStartDate] = useState(dayjs(startOfPreviousMonth));
  const [endDate, setEndDate] = useState(dayjs(endOfPreviousMonth));
  const [showSearchButton, setShowSearchButton] = useState(false);

  const [data, setData] = useState([]);
  const [showInr, setShowInr] = useState(false);
  const [fetchedData, setFetchedData] = useState(null);
  const [showByRegion, setShowByRegion] = useState(false);
  const [showTotal, setShowTotal] = useState(false);
  const [regionData, setRegionData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchDataAsync = async () => {
      try {
        const result = await fetchData(custom, uids);
      } catch (error) {
        message.error(error);
      }
    };

    fetchDataAsync();
  }, []); // Empty dependency array
  const fetchData = async (custom, uids) => {
    if (endDate.isBefore(startDate)) {
      message.error(
        "End date may not be smaller than the start date. Please select a valid date range."
      );
      return;
    }
    setLoading(true);
    const formattedStartDateAPI = startDate.format("YYYY-MM-DD");
    const formattedEndDateAPI = endDate.format("YYYY-MM-DD");
    try {
      const response = await salesService.fetchCurrencyDeals(
        formattedStartDateAPI,
        formattedEndDateAPI,
        custom,
        uids.filter((uid) => uid !== undefined)
      );
      if (response.success) {
        setFetchedData(response.data);
        updateChartData(response.data, showInr);
        setIsGraph(true);
      } else if (response.error) {
        message.error(response.message);
        console.error("Error in response:", response);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
    setShowSearchButton(false);
  };

  useEffect(() => {
    // Fetch data when hierarchyClicked changes
    if (uids.length > 0) {
      if (showByRegion) {
        fetchRegionData();
      } else {
        fetchData(custom, uids);
      }
    }
  }, [hierarchyClicked]);

  const updateChartData = (data, showInr) => {
    let chartData = [];

    if (showInr) {
      chartData = Object.keys(data.data_by_inr).map((month) => ({
        currency: "INR",
        [month]: parseFloat(data.data_by_inr[month].amount),
      }));
    } else {
      Object.keys(data.data_by_currency).forEach((currency) => {
        let currencyData = { currency };
        Object.keys(data.data_by_currency[currency]).forEach((month) => {
          currencyData[month] = parseFloat(
            data.data_by_currency[currency][month].amount
          );
        });
        chartData.push(currencyData);
      });
    }

    chartData = chartData.map((item) => {
      let newItem = { currency: item.currency };
      Object.keys(item).forEach((key) => {
        if (key !== "currency") {
          const [month, year] = key.split("_");
          newItem[month.slice(0, 3)] = item[key];
        }
      });
      return newItem;
    });

    setData(chartData);
  };

  const fetchRegionData = async () => {
    if (endDate.isBefore(startDate)) {
      message.error(
        "End date may not be smaller than the start date. Please select a valid date range."
      );
      return;
    }
    setRegionData([]);
    setLoading(true);
    const formattedStartDateAPI = startDate.format("YYYY-MM-DD");
    const formattedEndDateAPI = endDate.format("YYYY-MM-DD");
    const response = await salesService.fetchCurrencyDealsRegionwise(
      formattedStartDateAPI,
      formattedEndDateAPI,
      custom,
      uids.filter((uid) => uid !== undefined)
    );
    if (response.success) {
      setRegionData(response.data.data_by_currency);
      setIsGraph(false);
    }
    setLoading(false);
    setShowSearchButton(false);
  };

  const handleCheckboxChange = (e) => {
    setShowInr(e.target.checked);
    if (e.target.checked) {
      setShowByRegion(false);
      setShowTotal(false);
      if (fetchedData) {
        updateChartData(fetchedData, true);
        setIsGraph(true);
      }
    } else {
      if (fetchedData) {
        updateChartData(fetchedData, false);
        setIsGraph(true);
      }
    }
  };

  const handleShowByRegionChange = async (e) => {
    const isChecked = e.target.checked;
    setShowByRegion(isChecked);
    if (isChecked) {
      setShowInr(false);
      setShowTotal(false);

      await fetchRegionData();
    } else {
      await fetchData();
    }
    setIsGraph(!isChecked);
  };

  const handleShowTotalChange = (e) => {
    setShowTotal(e.target.checked);
  };

  const handleDatePickerChange = () => {
    setShowSearchButton(true);
  };

  const handleSearchClick = () => {
    if (showByRegion) {
      console.log(custom, uids, "hello");
      fetchRegionData();
    } else {
      fetchData(custom, uids);
    }
  };

  return (
    <div>
      <div className="inputsContainer">
        <DateRangePicker
          onStartChange={(date) => {
            setStartDate(dayjs(date));
            handleDatePickerChange();
          }}
          onEndChange={(date) => {
            setEndDate(dayjs(date));
            handleDatePickerChange();
          }}
          startDate={startDate}
          endDate={endDate}
        />
        {showSearchButton && (
          <Button
            type="primary"
            icon={<SearchOutlined />}
            onClick={handleSearchClick}
          >
            Search
          </Button>
        )}
        <Checkbox
          checked={showInr}
          style={{ display: "flex", alignItems: "center" }}
          onChange={handleCheckboxChange}
        >
          Show INR
        </Checkbox>
        <Checkbox
          style={{ display: "flex", alignItems: "center" }}
          checked={showByRegion}
          onChange={handleShowByRegionChange}
        >
          Show by Region
        </Checkbox>
        {showByRegion && (
          <Checkbox
            style={{ display: "flex", alignItems: "center" }}
            checked={showTotal}
            onChange={handleShowTotalChange}
          >
            Show Total
          </Checkbox>
        )}
      </div>

      <Spin spinning={loading}>
        {showByRegion ? (
          showTotal ? (
            <RegionTable data={regionData} />
          ) : (
            <MultiCurrencyBarChart data={regionData} />
          )
        ) : (
          <BarChartGraph data={data} />
        )}
      </Spin>
    </div>
  );
};

export default SalesGraph;
