import { message } from "antd";
import NotFoundPage from "../components/Error/NotFoundPage";
import ReactDOM from "react-dom";
export const catchError = async (response) => {
  try {
    // Check if the response indicates success
    if (response.success) {
      message.success("Operation completed successfully!");
      return; // Exit the function after showing the success message
    }
    const statusCode = response?.status || response?.response?.status;

    // Handle errors if the response does not indicate success
    const errorData =
      response?.data?.message || 
      response?.data || 
      response?.response?.data?.message || 
      response?.response?.data;
    console.log(errorData, "errorData");
    if (statusCode === 403) {
      // Render ErrorCom in case of 403 status
   message.success("Permission denied")
      return; // Exit the function after rendering ErrorCom
    }
    if (errorData) {
      if (typeof errorData === "object") {
        const errorMessages = [];

        for (const [key, value] of Object.entries(errorData)) {
          if (Array.isArray(value)) {
            value.forEach((err) => errorMessages.push(`${key}: ${err}`));
          } else {
            errorMessages.push(`${key}: ${value}`);
          }
        }

        if (errorMessages.length > 0) {
          message.error(errorMessages.join("\n"));
          return; // Exit the function once the error message is shown
        }
      } else {
        message.error(errorData);
        return; // Exit the function once the error message is shown
      }
    }

    // If no error data is found, do nothing (or optionally log)
    console.warn("No error data found in response.");
  } catch (error) {
    console.log("Error:", error);
    message.error("An unexpected error occurred.");
  }
};
