import React, { useState } from 'react';
import { Form, Select, Input, Button, Row, Col } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';

const { Option } = Select;

const AssignFreelancer = ({ setShowAssign }) => {
    const [form] = Form.useForm(); // Create a form instance
    const [formData, setFormData] = useState({
        skills: "",
        budget: "",
        currency: "",
        freelancers: []
    });

    const handleFinish = async () => {
        try {
            await form.validateFields(); // Validate all fields
            // console.log("Form Data", formData);
        } catch (error) {
            console.log("Validation Failed:", error);
        }
    };

    const addFreelancer = () => {
        const newFreelancer = { id: uuidv4(), freelancer: '', amount: '', currency: '' };
        setFormData({ ...formData, freelancers: [...formData.freelancers, newFreelancer] });
        form.setFieldsValue({ [`freelancer-${newFreelancer.id}`]: '', [`amount-${newFreelancer.id}`]: '', [`currency-${newFreelancer.id}`]: '' });
    };

    const removeFreelancer = (id) => {
        const updatedFreelancers = formData.freelancers.filter(freelancer => freelancer.id !== id);
        setFormData({ ...formData, freelancers: updatedFreelancers });
        form.setFieldsValue({ [`freelancer-${id}`]: undefined, [`amount-${id}`]: undefined, [`currency-${id}`]: undefined });
    };

    const handleFreelancerChange = (id, key, value) => {
        const updatedFreelancers = formData.freelancers.map(freelancer =>
            freelancer.id === id ? { ...freelancer, [key]: value } : freelancer
        );
        setFormData({ ...formData, freelancers: updatedFreelancers });
    };

    return (
        <div style={{ padding: '20px', backgroundColor: '#f5f5f5', borderRadius: '8px', marginBottom: "30px" }}>
            <h2 style={{ margin: "0px 0px 20px 0px" }}>Assign Freelancer</h2>
            <Form layout="vertical" form={form} onFinish={handleFinish}>
                {/* Default Fields */}
                <Row style={{ width: "100%" }} gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name="skills"
                            label="Skills"
                            rules={[{ required: true, message: 'Please select skills!' }]}
                        >
                            <Select
                                onChange={(e) => setFormData({ ...formData, skills: e })}
                                style={{ width: "100%" }}
                                placeholder="Select Skills"
                            >
                                <Option value="web-development">Web Development</Option>
                                <Option value="graphic-design">Graphic Design</Option>
                                <Option value="content-writing">Content Writing</Option>
                                <Option value="digital-marketing">Digital Marketing</Option>
                                <Option value="data-analysis">Data Analysis</Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            name="budget"
                            label="Budget"
                            rules={[{ required: true, message: 'Please enter your budget!' }]}
                        >
                            <Input
                                onChange={(e) => setFormData({ ...formData, budget: e.target.value })}
                                style={{ width: "100%" }}
                                type="number"
                                placeholder="Enter Budget"
                            />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            name="currency"
                            label="Currency"
                            rules={[{ required: true, message: 'Please select a currency!' }]}
                        >
                            <Select
                                onChange={(e) => setFormData({ ...formData, currency: e })}
                                style={{ width: "100%" }}
                                placeholder="Select Currency"
                            >
                                <Option value="usd">USD</Option>
                                <Option value="eur">EUR</Option>
                                <Option value="inr">INR</Option>
                                <Option value="gbp">GBP</Option>
                                <Option value="aud">AUD</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                {/* Dynamic Freelancers */}
                {formData.freelancers.map((freelancer, index) => (
                    <Row key={freelancer.id} style={{ width: "100%" }} gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                label="Freelancer"
                                name={`freelancer-${freelancer.id}`} // Unique name for each freelancer
                                rules={[{ required: true, message: 'Please select a freelancer!' }]}
                            >
                                <Select
                                    style={{ width: "100%" }}
                                    placeholder="Select Freelancer"
                                    onChange={(value) => handleFreelancerChange(freelancer.id, 'freelancer', value)}
                                >
                                    <Option value="freelancer1">Freelancer 1</Option>
                                    <Option value="freelancer2">Freelancer 2</Option>
                                    <Option value="freelancer3">Freelancer 3</Option>
                                    <Option value="freelancer4">Freelancer 4</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                label="Amount"
                                name={`amount-${freelancer.id}`} // Unique name for each amount
                                rules={[{ required: true, message: 'Please enter the amount!' }]}
                            >
                                <Input
                                    style={{ width: "100%" }}
                                    type="number"
                                    placeholder="Enter Amount"
                                    onChange={(e) => handleFreelancerChange(freelancer.id, 'amount', e.target.value)}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                label="Currency"
                                name={`currency-${freelancer.id}`} // Unique name for each currency
                                rules={[{ required: true, message: 'Please select a currency!' }]}
                            >
                                <Select
                                    style={{ width: "100%" }}
                                    placeholder="Select Currency"
                                    onChange={(value) => handleFreelancerChange(freelancer.id, 'currency', value)}
                                >
                                    <Option value="usd">USD</Option>
                                    <Option value="eur">EUR</Option>
                                    <Option value="inr">INR</Option>
                                    <Option value="gbp">GBP</Option>
                                    <Option value="aud">AUD</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Button
                                type="danger"
                                icon={<DeleteOutlined />}
                                onClick={() => removeFreelancer(freelancer.id)}
                            />
                        </Col>
                    </Row>
                ))}

                <Button type="dashed" onClick={addFreelancer} style={{ marginBottom: '20px' }}>
                    + Add Another Freelancer
                </Button>

                <Form.Item
                    style={{ display: "flex", margin: "0px", justifyContent: "flex-end", width: "100%", gap: "10px" }}
                >
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{ width: 'auto' }}
                    >
                        Assign Freelancer
                    </Button>
                    <Button
                        type="default"
                        style={{ width: 'auto', marginLeft: "10px" }}
                        onClick={() => setShowAssign(false)}
                    >
                        Cancel
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default AssignFreelancer;
