import CommentsList from "./CommentList";

const InternalComments = ({
  internalComments,
  userTypes,
  handleTabChange,
  setOrderData,
  moveToPortal,
}) => (
  <CommentsList
    userTypes={userTypes}
    comments={internalComments}
    title="INTERNAL COMMENTS"
    handleTabChange={handleTabChange}
    color="#007bff"
    setOrderData={setOrderData}
    moveToPortal={moveToPortal}
    permissions={{
      add: "can_add_internal_comments",
      forward: "can_make_internal_comment_forward",
      moveToClient: "can_move_internal_comment_to_client_comment",
      moveToFL: "can_move_internal_comment_to_fl_comment",
      delete: "can_delete_internal_comments",
    }}
  />
);

export default InternalComments;
