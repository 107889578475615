import { Avatar, Button, Card, Descriptions, Empty, Pagination, Popover, Spin, Table, Tooltip } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const calculateTimeElapsed = (accepted_on, created_at) => {

  if (!created_at) return "-"; // If `created_at` is missing, return "-"
  let result;

  if (!accepted_on) {
    // If `accepted_on` is null, calculate time from `created_at` to now
    const createdDate = moment(created_at);
    const now = moment();
    const duration = moment.duration(now.diff(createdDate));

    const hours = String(Math.floor(duration.asHours()) % 24).padStart(2, "0");
    const minutes = String(duration.minutes()).padStart(2, "0");
    const seconds = String(duration.seconds()).padStart(2, "0");

    result = `${hours}:${minutes}:${seconds}`;
  } else {
    // If `accepted_on` is present, calculate time from `created_at` to `accepted_on`
    const acceptedDate = moment(accepted_on, "DD-MM-YYYY hh:mm:ss");
    const createdDate = moment(created_at, "DD-MM-YYYY hh:mm:ss");
    const duration = moment.duration(acceptedDate.diff(createdDate));

    const days = Math.floor(duration.asDays());
    const hours = String(Math.floor(duration.asHours()) % 24).padStart(2, "0");
    const minutes = String(duration.minutes()).padStart(2, "0");
    const seconds = String(duration.seconds()).padStart(2, "0");

    result = `${hours}:${minutes}:${seconds}`;
  }

  return result;
};

const MobileCompAllocation = ({
  data,
  pagination,
  setPagination,
  loading,
}) => {


  const [timeElapsedArray, setTimeElapsedArray] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      const updatedElapsedTimes = data.map((record) =>
        calculateTimeElapsed(record.accepted_on, record.created_at)
      );
      setTimeElapsedArray(updatedElapsedTimes);
    }, 1000); // Updates every second

    return () => clearInterval(interval);
  }, [data]);


  return (
    <div style={{ marginBottom: "20px" }}>
      {loading ? (
        <Spin />
      ) : (
        data.length > 0 ?
          data?.map((allocationValue, index) => (
            <div
              key={index}
              style={{ marginBottom: "1rem" }}
            >
              <Descriptions bordered size="small">
                <Descriptions.Item label="Key">
                  <Link
                    target="_blank"
                    to={`/order-management/my-orders/${allocationValue.key}`}
                  >
                    {allocationValue.key || "-"}
                  </Link>
                </Descriptions.Item>
                <Descriptions.Item label="Title" style={{ maxWidth: "200px" }}>
                  {allocationValue.title || "-"}
                </Descriptions.Item>

                <Descriptions.Item label="Word Count">
                  {allocationValue.word_count || "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Source">
                  {allocationValue.source || "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Start Date">
                  {allocationValue.start_date
                    ? moment(allocationValue.start_date).local().format("DD-MM-YYYY hh:mm A")
                    : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Alloted Deadline">
                  {allocationValue.allotted_deadline
                    ? moment(allocationValue.allotted_deadline).local().format("DD-MM-YYYY hh:mm A")
                    : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Client Deadline">
                  {allocationValue.client_deadline
                    ? moment(allocationValue.client_deadline).local().format("DD-MM-YYYY hh:mm A")
                    : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Accepted By">
                  {allocationValue.accepted_by || "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Accepted On">
                  {allocationValue.accepted_on
                    ? moment(allocationValue.accepted_on).local().format("DD-MM-YYYY hh:mm A")
                    : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Pending On">
                  {Array.isArray(allocationValue?.pending) && allocationValue.pending.length > 0 ? (
                    <Avatar.Group max={{ count: 3 }}>
                      {allocationValue.pending.slice(0, 2).map((name, index) => (
                        <Tooltip key={index} title={name}>
                          <Avatar style={{ backgroundColor: "#1890ff", color: "#fff" }}>
                            {name.charAt(0).toUpperCase()}
                          </Avatar>
                        </Tooltip>
                      ))}
                      {allocationValue.pending.length > (2) && (
                        <Popover
                          content={
                            <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                              {allocationValue.pending.slice(2).map((name, index) => (
                                <p key={index}>{name}</p>
                              ))}
                            </div>
                          }
                          title="More Pending"
                          placement='left'
                        >
                          <Avatar style={{ backgroundColor: "#f56a00" }}>
                            +{allocationValue.pending.length - (2)}
                          </Avatar>
                        </Popover>
                      )}
                    </Avatar.Group>
                  ) : (
                    "-"
                  )}
                </Descriptions.Item>

                {/* <Descriptions.Item label="Rejected By">
                  {Array.isArray(allocationValue?.rejected_by) && allocationValue.rejected_by.length > 0 ? (
                    <Avatar.Group max={{ count: 3 }}>
                      {allocationValue.rejected_by.slice(0, 2).map((name, index) => (
                        <Tooltip key={index} title={name}>
                          <Avatar style={{ backgroundColor: "#ff4d4f", color: "#fff" }}>
                            {name.charAt(0).toUpperCase()}
                          </Avatar>
                        </Tooltip>
                      ))}
                      {allocationValue.rejected_by.length > 2 && (
                        <Popover
                          content={
                            <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                              {allocationValue.rejected_by.slice(2).map((name, index) => (
                                <p key={index}>{name}</p>
                              ))}
                            </div>
                          }
                          title="More Rejected By"
                          placement="left"
                        >
                          <Avatar style={{ backgroundColor: "#f56a00" }}>
                            +{allocationValue.rejected_by.length - 2}
                          </Avatar>
                        </Popover>
                      )}
                    </Avatar.Group>
                  ) : (
                    "-"
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Rejection On">
                  {allocationValue.rejected_on
                    ? moment(allocationValue.rejected_on).local().format("DD-MM-YYYY hh:mm A")
                    : "-"}
                </Descriptions.Item> */}
                <Descriptions.Item label="Rejections By">
                  <Popover
                    title={
                      <div style={{ maxHeight: "300px", overflowY: "auto", padding: "0" }}>
                        {allocationValue?.rejection_reasons?.map((reason, index) => (
                          <Card key={index} style={{ marginBottom: "8px" }} bodyStyle={{ padding: "5px" }}>
                            <p><strong>By:</strong> {reason.rejected_by}</p>
                            <p><strong>Reason:</strong> {reason.rejected_reason}</p>
                            <p>
                              <strong>Rejected on:</strong>{" "}
                              {moment(reason.rejected_on).format("DD MM YYYY h:mm A")}
                            </p>
                          </Card>
                        ))}
                      </div>
                    }
                  >
                    {allocationValue?.rejection_reasons?.length > 0 ?
                      <Button type="link" style={{ padding: 0 }}>
                        View
                      </Button> : "-"}
                  </Popover>
                </Descriptions.Item>
                <Descriptions.Item label="Visibility">
                  {Array.isArray(allocationValue?.visiblity) && allocationValue.visiblity.length > 0 ? (
                    <Avatar.Group max={{ count: 3 }}>
                      {allocationValue.visiblity.slice(0, 2).map((owner, index) => (
                        <Tooltip key={index} title={owner}>
                          <Avatar style={{ backgroundColor: "#87d068", color: "#fff" }}>
                            {owner.charAt(0).toUpperCase()}
                          </Avatar>
                        </Tooltip>
                      ))}
                      {allocationValue.visiblity.length > 2 && (
                        <Popover
                          content={
                            <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                              {allocationValue.visiblity.slice(2).map((owner, index) => (
                                <p key={index}>{owner}</p>
                              ))}
                            </div>
                          }
                          title="More Visibility"
                          placement="left"
                        >
                          <Avatar style={{ backgroundColor: "#f56a00" }}>
                            +{allocationValue.visiblity.length - 2}
                          </Avatar>
                        </Popover>
                      )}
                    </Avatar.Group>
                  ) : (
                    "-"
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Time Elapsed">
                  <span
                    style={{ color: allocationValue.accepted_on === null ? "red" : "green" }}
                  >
                    {timeElapsedArray.find(
                      (_, index) => index === data.indexOf(allocationValue)
                    ) || "Calculating..."}
                  </span>
                </Descriptions.Item>
                <Descriptions.Item label="Status">
                  {allocationValue.status || "-"}
                </Descriptions.Item>
              </Descriptions>
            </div>
          ))
          :
          <div style={{ padding: "10px" }}>
            <Empty description="No data available" />
          </div>
      )}
      <Pagination
        current={pagination.current}
        pageSize={pagination.pageSize}
        total={pagination.total}
        onChange={(page, pageSize) =>
          setPagination((prev) => ({ ...prev, current: page, pageSize }))
        }
        style={{ textAlign: "center", marginTop: 16 }}
      />
    </div>
  )
}

export default MobileCompAllocation