import {
  PhoneOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Menu, Modal, Steps, Tooltip, message } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import orderService from "../../services/OrderLists";
import OrderMenu from "./OrderMenu";
import usePermissions from "../../hooks/usePermissions";
const CallFunc = ({
  orderData,
  activeOrderTab,
  permissions,
  fetchOrderDetails,
}) => {
  const { hasPermission } = usePermissions();
  const [statusHistory, setStatusHistory] = useState([]);
  const [currentStatus, setCurrentStatus] = useState({});
  const [twilioNumbers, setTwilioNumbers] = useState([]);
  const [isCallModalVisible, setIsCallModalVisible] = useState(false);
  const [callDuration, setCallDuration] = useState(0);
  const [callInterval, setCallInterval] = useState(null);
  const [currentNumber, setCurrentNumber] = useState("");
  const [currentConnection, setCurrentConnection] = useState(null);
  const { orderKey } = useParams();

  // useEffect(() => {
  //   const fetchStatusHistory = async () => {
  //     const response = await orderService.getStatusLogs(orderKey);
  //     if (response.success) {
  //       const historyData = Array.isArray(response.data.data)
  //         ? response.data.data
  //         : [];
  //       setStatusHistory(historyData);

  //       const latestStatusIndex = historyData.reduce(
  //         (latestIndex, item, index) =>
  //           item.history_date ? index : latestIndex,
  //         -1
  //       );
  //       const latestStatus =
  //         latestStatusIndex !== -1 ? historyData[latestStatusIndex] : {};
  //       setCurrentStatus(latestStatus);
  //     } else {
  //       setStatusHistory([]);
  //       setCurrentStatus({});
  //     }
  //   };

  //   const fetchTwilioNumbers = async () => {
  //     const response = await orderService.getTwilioNumberAndUid();
  //     if (response.success) {
  //       setTwilioNumbers(response.data);
  //     }
  //   };

  //   fetchStatusHistory();
  //   fetchTwilioNumbers(); // Fetch Twilio numbers when component mounts
  // }, [orderKey]);

  const handleCall = async (task_key, uid) => {
    try {
      const connection = await orderService.makeTwilioCall(task_key, uid);
      if (connection) {
        message.success("Call initiated successfully");
        setCurrentConnection(connection);

        setCurrentNumber(connection.parameters.From);
        setIsCallModalVisible(true);

        const interval = setInterval(() => {
          setCallDuration((prev) => prev + 1);
        }, 1000);
        setCallInterval(interval);
      } else {
        message.error("Failed to initiate call");
      }
    } catch (error) {
      console.error("Failed to make call:", error);
      message.error("Failed to initiate call");
    }
  };

  const handleSelectNumber = ({ key }) => {
    const selectedNumber = twilioNumbers.find((item) => item.phone_no === key);
    if (selectedNumber) {
      handleCall(orderData.data.key, selectedNumber.uid);
    } else {
      message.error("Failed to find the selected number");
    }
  };

  const handleHangUp = () => {
    clearInterval(callInterval);
    setCallInterval(null);

    setCallDuration(0);

    setIsCallModalVisible(false);

    if (currentConnection) {
      currentConnection.disconnect();
      setCurrentConnection(null);
    }
  };

  const menu = (
    <Menu onClick={handleSelectNumber}>
      {twilioNumbers.map(({ phone_no }) => (
        <Menu.Item key={phone_no}>{phone_no}</Menu.Item>
      ))}
    </Menu>
  );
  const ActionsMenu = (
    <OrderMenu
      activeOrderTab={activeOrderTab}
      permissions={permissions}
      orderData={orderData}
      fetchOrderDetails={fetchOrderDetails}
    />
  );

  // console.log("permissions00000", permissions);

  return (
    <div>
      <div>
        {hasPermission("can_view_order_action_call") && (
          <div style={{ marginBottom: "10px" }}>
            <Tooltip placement="left" title="Call">
              <Dropdown overlay={menu} trigger={["click"]}>
                <Button
                  style={{ backgroundColor: "#36c6d3" }}
                  type="primary"
                  icon={<PhoneOutlined rotate={90} />}
                  shape="circle"
                  size="large"
                />
              </Dropdown>
            </Tooltip>
          </div>
        )}
          {hasPermission("can_view_order_action_call_allocation") && 
        <Tooltip placement="left" title="Call Allocations">
          <Button
            style={{ backgroundColor: "#36c6d3" }}
            type="primary"
            icon={<UserOutlined />}
            shape="circle"
            size="large"
          />
        </Tooltip>
}
        <div style={{ marginTop: "10px" }}>
          <Dropdown
            placement="left"
            overlay={ActionsMenu}
            overlayClassName="custom-dropdown"
            trigger={["click"]}>
            <Button
              style={{ backgroundColor: "#36c6d3" }}
              type="primary"
              icon={<SettingOutlined rotate={90} />}
              shape="circle"
              size="large"
            />
          </Dropdown>
        </div>
      </div>
      {/* Call Modal */}
      <Modal
        width={300}
        title={`Calling`}
        visible={isCallModalVisible}
        onCancel={handleHangUp}
        footer={[
          <Button
            key="hangup"
            style={{ background: "red" }}
            type="primary"
            onClick={handleHangUp}>
            Hang Up
          </Button>,
        ]}>
        <div>
          <p>
            Duration:{" "}
            {new Date(callDuration * 1000).toISOString().substr(11, 8)}
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default CallFunc;
