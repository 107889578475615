import {
  PhoneOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { Button, Dropdown, Menu, Modal, Steps, Tooltip, message } from "antd";
import React, { useEffect, useState, useCallback, useRef } from "react";
import commonService from "../../services/commonService";
import { useParams } from "react-router-dom";
import orderService from "../../services/OrderLists";
import OrderMenu from "./OrderMenu";
import usePermissions from "../../hooks/usePermissions";
import CallAllocationModalContent from "../../components/MyOrderActions/CallAllocationModalContent";
import CustomModal from "../../components/MyOrderActions/CustomModal";
import OrderCallModal from "./OrderCallModal";
const CallFunc = ({
  orderData,
  activeOrderTab,
  permissions,
  fetchOrderDetails,
  handleTabChange,
  getSolutionFiles,
}) => {
  console.log(orderData, "kjhg");
  const { hasPermission } = usePermissions();
  const [statusHistory, setStatusHistory] = useState([]);

  const [currentStatus, setCurrentStatus] = useState({});
  const [twilioNumbers, setTwilioNumbers] = useState([]);
  const [isCallModalVisible, setIsCallModalVisible] = useState(false);
  const [callDuration, setCallDuration] = useState(0);
  const [callInterval, setCallInterval] = useState(null);
  const [currentNumber, setCurrentNumber] = useState("");
  const [currentConnection, setCurrentConnection] = useState(null);
  const { orderKey } = useParams();
  const intervalRef = useRef(null);
  const [isCalllAllocationModalVisible, setCallAllocationModalVisible] =
    useState(false);
  const [modalKey, setModalKey] = useState(0);

  const handleModalOpen = () => {
    setModalKey((prevKey) => prevKey + 1);
    setCallAllocationModalVisible(true);
  };

  const handleModalClose = () => {
    setCallAllocationModalVisible(false);
  };

  useEffect(() => {
    const fetchTwilioNumbers = async () => {
      const response = await orderService.getTwilioNumberAndUid();
      if (response.success) {
        setTwilioNumbers(response.data);
      }
    };

    fetchTwilioNumbers(); // Fetch Twilio numbers when component mounts
  }, [orderKey]);

  const handleCall = useCallback(async (taskKey, uid) => {
    try {
      setIsCallModalVisible(true);

      console.log("Initiating Twilio call...");
      const connection = await commonService.makeTwilioCall(taskKey, uid);

      if (connection) {
      
        setCurrentConnection(connection);

        connection.on("statusChange", (status) => {
          console.log(`Call status changed to: ${status}`);
          // Update call status

          if (status === "ringing") {
            const timeoutId = setTimeout(() => {
              // Reset call status on error

              if (connection.status === "ringing") {
                console.log("Call not answered in time, hanging up...");
                handleHangUp();
              }
            }, 30000);
            // Store the timeout ID to clear it if needed
            connection.timeoutId = timeoutId;
          } else if (status === "open") {
            // Set status to Connected when call is answered

            console.log("Call answered, conversation started");
            startTimer();
            // Clear the ringing timeout if it exists
            if (connection.timeoutId) {
              clearTimeout(connection.timeoutId);
            }
          } else if (status === "closed" || status === "disconnected") {
            console.log("Call closed or disconnected"); // Reset call status on error
            handleHangUp();
          }
        });

        // if (connection._onAnswer) {
        //   connection._onAnswer = (payload) => {
        //     setModalFormValues({
        //       call_sid: payload.callsid,
        //     });

        //     startTimer();
        //   };
        // }

        connection.on("disconnect", () => {
          console.log("'disconnect' event fired");
          handleHangUp();
        });

        connection.on("error", (error) => {
          console.error("Twilio connection error:", error);
          // Instead of showing an error message, just log it and handle the hangup
          console.log("Call encountered an error, hanging up...");
          handleHangUp();
        });
        const checkStatus = setInterval(() => {
          const currentStatus = connection.status();

          if (currentStatus === "closed") {
            clearInterval(checkStatus);
            handleHangUp();
          }
        }, 1000);
      } else {
        throw new Error(
          "Failed to initiate call - connection object is null or undefined"
        );
      }
    } catch (error) {
      console.error("Error in handleCall:", error);
      setIsCallModalVisible(false);
      message.error(`Failed to initiate call: ${error.message}`);
    }
  }, []);

  const startTimer = () => {
    console.log("Starting timer");
    intervalRef.current = setInterval(() => {
      setCallDuration((prev) => {
        const newDuration = prev + 1;
        console.log(`Call duration: ${newDuration} seconds`);
        return newDuration;
      });
    }, 1000);
  };

  const handleHangUp = useCallback(() => {
    console.log("Hanging up call");
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      console.log("Timer cleared");
    }
    setCallDuration(0);
    setIsCallModalVisible(false);

    if (currentConnection) {
      currentConnection.disconnect();
      console.log("Connection disconnected");
    }
    // Show outcome modal after disconnecting
  }, [currentConnection]);

  const handleSelectNumber = async ({ key }) => {
    const selectedNumber = twilioNumbers.find((item) => item.phone_no === key);
    console.log(selectedNumber, "ser");
    if (selectedNumber) {
      await handleCall(orderKey, selectedNumber.uid);
    } else {
      message.error("Failed to find the selected number");
    }
  };

  const menu = (
    <Menu onClick={handleSelectNumber}>
      {twilioNumbers.map(({ phone_no }) => (
        <Menu.Item key={phone_no}>{phone_no}</Menu.Item>
      ))}
    </Menu>
  );
  const ActionsMenu = (
    <OrderMenu
      activeOrderTab={activeOrderTab}
      permissions={permissions}
      orderData={orderData}
      fetchOrderDetails={fetchOrderDetails}
      handleTabChange={handleTabChange}
      getSolutionFiles={getSolutionFiles}
      getData={handleTabChange}
    />
  );

  // console.log("permissions00000", permissions);

  return (
    <div>
      <div>
        {hasPermission("can_view_order_action_call") && (
          <div style={{ marginBottom: "10px" }}>
            <Tooltip placement="left" title="Call">
              <Dropdown overlay={menu} trigger={["click"]}>
                <Button
                  style={{ backgroundColor: "#36c6d3" }}
                  type="primary"
                  icon={<PhoneOutlined rotate={90} />}
                  shape="circle"
                  size="large"
                />
              </Dropdown>
            </Tooltip>
          </div>
        )}
        {hasPermission("can_view_order_action_call_allocation") && (
          <>
            <Tooltip placement="left" title="Call Allocations">
              <Button
                style={{ backgroundColor: "#36c6d3" }}
                type="primary"
                icon={<UserOutlined />}
                shape="circle"
                size="large"
                onClick={handleModalOpen}
              />
            </Tooltip>

            <CustomModal
              modalKey={modalKey}
              isModalVisible={isCalllAllocationModalVisible}
              handleModalClose={handleModalClose}
              selectedLabel="Call Allocation"
              selectedComponent={
                <CallAllocationModalContent
                  orderKey={orderKey}
                  handleModalClose={handleModalClose}
                />
              }
              modalWidth={550}
            />
          </>
        )}
        <div style={{ marginTop: "10px" }}>
          <Dropdown
            placement="left"
            overlay={ActionsMenu}
            overlayClassName="custom-dropdown"
            trigger={["click"]}>
            <Button
              style={{ backgroundColor: "#36c6d3" }}
              type="primary"
              icon={<SettingOutlined rotate={90} />}
              shape="circle"
              size="large"
            />
          </Dropdown>
        </div>
      </div>
      {/* Call Modal */}

      <OrderCallModal
        visible={isCallModalVisible}
        onHangUp={handleHangUp}
        callDuration={callDuration}
        onCancel={handleHangUp}
       orderData={orderData}
      />
      {/* <Modal
        width={300}
        title={`Calling`}
        visible={isCallModalVisible}
        onCancel={handleHangUp}
        footer={[
          <Button
            key="hangup"
            style={{ background: "red" }}
            type="primary"
            onClick={handleHangUp}>
            Hang Up
          </Button>,
        ]}>
        <div>
          <p>
            Duration:{" "}
            {new Date(callDuration * 1000).toISOString().substr(11, 8)}
          </p>
        </div>
      </Modal> */}
    </div>
  );
};

export default CallFunc;
