import React from 'react';
import { Empty, Table } from 'antd';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';

const MobileActivityLogs = ({ data }) => {
    const columns = [
        {
            title: 'Key',
            dataIndex: 'key',
            key: 'key',
            render: (text) => <strong>{text}</strong>,
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            render: (text) => (
                <span style={{ wordWrap: "break-word", wordBreak: "break-word", whiteSpace: "normal" }}>
                    {text || "N/A"}
                </span>
            ),
        },
    ];

    const transformDataForTable = (log) => [
        { key: 'Action', value: log.action_object },
        { key: 'Actor', value: log.actor },
        { key: 'Timestamp', value: log.timestamp ? moment(log.timestamp).local().format("DD-MM-YYYY hh:mm A") : 'N/A' },
        { key: 'Verb', value: log.verb },
    ];

    return (
        <div style={{ backgroundColor: "#f2f6f9", padding: '10px' }}>
            {data && data.length > 0 ? (
                data.map((log, index) => (
                    <Table
                        key={index}
                        columns={columns}
                        dataSource={transformDataForTable(log)}
                        pagination={false}
                        bordered
                        size="small"
                        style={{ marginBottom: '25px', borderRadius: '5px' }}
                        showHeader={false}
                    />
                ))
            ) : (
                <Empty description="No data available" />
            )}
        </div>
    );
};

const ActivityLogs = ({ activityLogsData }) => {
    const { orderKey } = useParams();
    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

    const columns = [
        { title: 'Action', dataIndex: 'action_object', key: 'action_object', render: (text) => text || 'N/A' },
        { title: 'Actor', dataIndex: 'actor', key: 'actor', render: (text) => text || 'N/A' },
        {
            title: 'Timestamp', dataIndex: 'timestamp', key: 'timestamp', render: (text) => {
                return text
                    ? moment(text).local().format("DD-MM-YYYY hh:mm A")
                    : 'N/A';
            },
        },
        { title: 'Verb', dataIndex: 'verb', key: 'verb', render: (text) => text || 'N/A' },
    ];

    const dataSource = activityLogsData
        ? activityLogsData.map((log, index) => ({
            key: index,
            action_object: log.action_object,
            actor: log.actor,
            timestamp: log.timestamp,
            verb: log.verb,
        }))
        : [];

    return (
        <div>
            <h3>Activity Logs - {orderKey}</h3>
            {isMobile ? (
                <MobileActivityLogs data={activityLogsData} />
            ) : (
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                    bordered
                    scroll={{ y: 350, x: '100%' }}
                />
            )}
        </div>
    );
};

export default ActivityLogs;
