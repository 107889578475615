import React from "react";
import { Form, Input, Button, Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { signup } from "../../redux/slices/authSlice";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.auth);

  const onFinish = async (values) => {
    const { success } = await dispatch(signup(values));
    if (success) {
      navigate("/");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Row justify="center" align="middle" style={{ height: "100%" }}>
      <Col xl={10} xxl={10} lg={16} md={18} sm={22} xs={22}>
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            textAlign: "start",
            padding: "2rem",
            boxShadow:
              "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            wrapperCol={{
              span: 24,
            }}
            style={{ textAlign: "center" }}
          >
            <h2>Sign Up</h2>
          </Form.Item>
          <Form.Item
            label="First name"
            name="first_name"
            rules={[
              {
                required: true,
                message: "Please input your first name!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Last name"
            name="last_name"
            rules={[
              {
                required: true,
                message: "Please input your last name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={"Password"}
            name="password1"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label={"Re-Password"}
            name="password2"
            rules={[
              {
                required: true,
                message: "Please re enter password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="contact_no"
            label={"Contact number"}
            rules={[
              {
                required: true,
                message: "Please input your Contact number!",
              },
            ]}
          >
            <PhoneInput
              inputStyle={{
                width: "100%",
              }}
              country={"in"}
            />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              xl: { offset: 8, span: 16 },
              xxl: { offset: 8, span: 16 },
              lg: { offset: 8, span: 16 },
              md: { offset: 8, span: 16 },
              sm: { offset: 8, span: 16 },
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
              loading={loading}
            >
              Sign Up
            </Button>
            <div>
              {" "}
              Or <Link to="/login">login now!</Link>
            </div>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default Signup;
