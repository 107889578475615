import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  collapsed: false,
  drawerOpen: false,
  mode: "light",
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    toggleSidebarCollapsed: (state) => {
      state.collapsed = !state.collapsed;
    },
    toggleSidebarDrawer: (state) => {
      state.drawerOpen = !state.drawerOpen;
    },
    toggleSidebar: (state) => {
      state.collapsed = !state.collapsed;
    },
  },
});

export const { toggleSidebarCollapsed, toggleSidebarDrawer  ,toggleSidebar} = uiSlice.actions;
export default uiSlice.reducer;
