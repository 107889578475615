import React, { useEffect, useState } from "react";
import { Tabs as AntdTabs, Badge, Spin, Flex, message } from "antd";
import Details from "./Details";
import FilesTable from "./FilesTable";
import SolutionFilesAccordion from "./SolutionFiles";
import CommentsSection from "./Comments";
import FeedbackCard from "./Feedback";
import orderService from "../../services/OrderLists";
import { useParams } from "react-router-dom";
import useIsMobile from "../../hooks/useIsMobile";
import { useSelector } from "react-redux";
import usePermissions from "../../hooks/usePermissions";
const CustomTabs = ({
  orderData,
  setActiveOrderTab,
  openAccordion,
  toggleAccordion,
}) => {
  const { hasPermission } = usePermissions();
  
  const [orderFiles, setOrderFiles] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  console.log(orderData);
  const [solutionFiles, setSolutionFiles] = useState({
    finalFilesData: [],
    similarityReportData: [],
    otherFilesData: [],
  });
  const [comments, setComments] = useState({
    client_comments: [],
    fl_comments: [],
    internal_comments: [],
  });
  const [feedback, setFeedback] = useState([]);
  const [loading, setLoading] = useState({
    orderFiles: false,
    solutionFiles: false,
    comments: false,
    feedback: false,
  });
  const { orderKey } = useParams();

  useEffect(() => {
    const fetchOrderFiles = async () => {
      setLoading((prev) => ({ ...prev, orderFiles: true }));
      try {
        const result = await orderService.getOrderFiles(orderKey);
        if (result.data.status === "success") {
          setOrderFiles(result.data.data || []);
        }
      } catch (error) {
        console.error("Error fetching order files:", error);
      } finally {
        setLoading((prev) => ({ ...prev, orderFiles: false }));
      }
    };

    fetchOrderFiles();
  }, [orderKey]);

  const getSolutionFiles = async () => {
    try {
      setLoading((prev) => ({ ...prev, solutionFiles: true }));
      const result = await orderService.getSolutionFiles(orderKey);
      if (result.success && result.data.status === "success") {
        setLoading((prev) => ({ ...prev, solutionFiles: false }));
        const { final_files, other_files, similarity_files } = result.data.data;
        setSolutionFiles({
          finalFilesData: final_files || [],
          similarityReportData: similarity_files || [],
          otherFilesData: other_files || [],
        });
      }
    } catch (error) {
      message.error("hello");
      setLoading((prev) => ({ ...prev, solutionFiles: false }));
    } finally {
      setLoading((prev) => ({ ...prev, solutionFiles: false }));
    }
  };
  const handleTabChange = async (key) => {
    if (key === "1") {
      setActiveOrderTab("Overview");
    } else if (key === "2") {
      setActiveOrderTab("SolutionFiles");
      getSolutionFiles();
    } else if (key === "3") {
      setLoading((prev) => ({ ...prev, comments: true }));
      setActiveOrderTab("Comments");

      // Call the getClientComments function
      if (hasPermission("can_view_client_comments")) {
        const result = await orderService.getClientComments(orderKey);
        console.log(result.data, "clg");
        if (result.data.status === "success") {
          setComments((prev) => ({
            ...prev,
            client_comments: result.data.data || [],
          }));
        }
      }

      if (hasPermission("can_view_internal_comments")) {
        const internalResult = await orderService.getInternalComments(orderKey);
        if (internalResult.data.status === "success") {
          setComments((prev) => ({
            ...prev,
            internal_comments: internalResult.data.data || [],
          }));
        }
      }

      if (hasPermission("can_view_freelancer_comments")) {
        const freelancerResult = await orderService.getFreelancerComments(
          orderKey
        );
        if (freelancerResult.data.status === "success") {
          setComments((prev) => ({
            ...prev,
            fl_comments: freelancerResult.data.data || [],
          }));
        }
      }
      setLoading((prev) => ({ ...prev, comments: false }));
    } else if (key === "4") {
      setLoading((prev) => ({ ...prev, feedback: true }));
      setActiveOrderTab("Feedback");
      const result = await orderService.getOrderFeedback(orderKey);
      console.log(result.data.data , 'order feed')
      if (result.data.status === "success") {
        setFeedback(result.data.data || []);
      }
      setLoading((prev) => ({ ...prev, feedback: false }));
    }
  };

  const { user } = useSelector((state) => state.auth);

  if (!user) {
    console.log("No user permissions available in order Tabs");
    return [];
  }

  const permissions = user?.user?.permissions;

  const renderLabelWithCount = (label, count) => (
    <span style={{ display: "flex", alignItems: "center" }}>
      {label}
      <span
        style={{
          backgroundColor: "#001529",
          color: "#fff",
          width: "10px",
          height: "10px",
          borderRadius: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: "8px",
          padding: "10px",
        }}>
        {count || 0}
      </span>
    </span>
  );

  const items = [
    permissions.includes("can_view_order_detail") && {
      label: <span style={{ padding: "10px" }}>Overview</span>,
      key: "1",
      children: (
        <Details
          orderData={orderData}
          permissions={permissions}
          filesData={orderFiles}
          openAccordion={openAccordion}
          toggleAccordion={toggleAccordion}
        />
      ),
    },
    permissions.includes("can_view_order_solution_files") && {
      label: renderLabelWithCount(
        "Solution Files",
        orderData.data.solution_files_count
      ),
      key: "2",
      children: loading.solutionFiles ? (
        <Flex
          justify="center"
          align="center"
          style={{ width: "100%", height: "50vh" }}>
          <Spin size="large" />
        </Flex>
      ) : (
        <SolutionFilesAccordion
          getSolutionFiles={getSolutionFiles}
          finalFilesData={solutionFiles.finalFilesData}
          similarityReportData={solutionFiles.similarityReportData}
          otherFilesData={solutionFiles.otherFilesData}
        />
      ),
    },
    permissions.includes("can_view_order_comments") && {
      label: renderLabelWithCount(
        "Comments",
        comments?.client_comments?.length
      ),
      key: "3",
      children: loading.comments ? (
        <Flex
          justify="center"
          align="center"
          style={{ width: "100%", height: "50vh" }}>
          <Spin size="large" />
        </Flex>
      ) : (
        <CommentsSection
          orderKey={orderData?.data?.data?.key}
          moveToPortal = {orderData?.data?.moved_to_freelancer_portal}
          orderData={comments}
          setOrderData={setComments}
          loading={loading.comments}
          handleTabChange={handleTabChange}
        />
      ),
    },
    permissions.includes("can_view_order_comments") && {
      label: renderLabelWithCount("Feedback", orderData.data.feedback_count),
      key: "4",
      children: loading.feedback ? (
        <Flex
          justify="center"
          align="center"
          style={{ width: "100%", height: "50vh" }}>
          <Spin size="large" />
        </Flex>
      ) : (
        <FeedbackCard feedbackData={{feedback}}/>
      ),
    },
  ];

  return (
    <div className="ant-design-tabs">
      <AntdTabs defaultActiveKey="1" onChange={handleTabChange} items={items} />
    </div>
  );
};

export default CustomTabs;
