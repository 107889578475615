// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* DynamicTable.css */
.custom-table .ant-table-thead > tr > th {
    font-size: 10px; /* Adjust font size as needed */
    font-weight: 600
  }
  
  .custom-table .ant-table-body {
    max-height: 400px; /* Adjust height as needed */
    overflow-y: auto;
  }
  `, "",{"version":3,"sources":["webpack://./src/page-components/All Clients/DynamicTable.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;IACI,eAAe,EAAE,+BAA+B;IAChD;EACF;;EAEA;IACE,iBAAiB,EAAE,4BAA4B;IAC/C,gBAAgB;EAClB","sourcesContent":["/* DynamicTable.css */\n.custom-table .ant-table-thead > tr > th {\n    font-size: 10px; /* Adjust font size as needed */\n    font-weight: 600\n  }\n  \n  .custom-table .ant-table-body {\n    max-height: 400px; /* Adjust height as needed */\n    overflow-y: auto;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
