// routeTitles.js
export const routeTitles = {
  "/": "Dashboard",
  "/order-management/my-orders": " My Orders",
  "/task-detail/:orderKey": "Order Detail",
  "/leads": "Leads",
  "/leads-management/leads": "Leads",
  "/sales-operations/clients": "Clients",
  "/leads-management/assigned-leads": "Assigned Leads",
  "/sales-operations/sales-analytics": "Sales Analytics",
  "/sales-operations/leads": "Leads",

  "/order-management/my-orders/:orderKey": "Order Detail",
  "/payment-operations/transactions": "Payment Operations",
  "/notifications": "Notifications",
  "/payment-operations/wallet": "Wallet",
  "/payment-operations/transactions": "Transactions",
  "/sales-operations/client-connect": "Client Connect",
  "/sales-operations/bdm-analytics": "BDM Analytics",
  "/hierarchy-form": "Map Team",
  "/order-management/order-operations/call-allocation": "Call Allocation",
  "/search=true": "Search Result",
  "/order-management/order-operations/deductions" :"Deductions",
  "/order-management/order-operations/moved-to-fl-associates" :"Moved To FL Associates"
  // Add other routes here
};

export const getPageTitle = (path, routeTitles) => {
  const routeKeys = Object.keys(routeTitles);
  const matchedRoute = routeKeys.find((route) => {
    // Handle dynamic routes
    const routeRegex = new RegExp(`^${route.replace(/:[^\s/]+/g, "[^/]+")}$`);
    return routeRegex.test(path);
  });

  return matchedRoute ? routeTitles[matchedRoute] : "Default Title";
};

export default getPageTitle;
