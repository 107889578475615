import axiosInstance from "./axiosInstance";
import { catchError } from "../utils/errorHandler";
import { message } from "antd";

const authService = {
  login: async (payload) => {
    try {
      const response = await axiosInstance.post("/api/v2/auth/login/", payload);
      if (response.status === 200) {
        message.success("You have successfully logged in!");
        return { success: true, user: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },

  signup: async (user) => {
    try {
      const response = await axiosInstance.post("/api/v2/auth/register/", user);
      if (response.status === 201) {
        message.success("You have successfully signed up!");
        return { success: true, user: response.data };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  reset: async (email) => {
    try {
      const response = await axiosInstance.post(
        "/api/v2/password-reset/",
        email
      );
      if (response.status === 200) {
        message.success(
          response?.data?.detail || "Password reset e-mail has been sent."
        );
        return { success: true };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  resetconfirm: async (payload) => {
    try {
      const response = await axiosInstance.post(
        "api/v2/password-reset-confirm/",
        payload
      );
      if (response.status === 200) {
        message.success(
          response?.data?.detail || "Your password has been reset"
        );
        return { success: true };
      }
      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
};

export default authService;
