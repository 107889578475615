import axiosInstance from "./axiosInstance";
import { catchError } from "../utils/errorHandler";
import moment from "moment";
const AcceptRejectOrdersService = {
  acceptRejectOrdersList: async (
    urgencyLevel,
    page = 1,
    pageSize = 10,
    filters = {},

    isDownload = false
  ) => {
    const { key, status, hierarchy, start_date, username, fl_name } = filters;

    const queryParams = {
      urgency_level: urgencyLevel, // Always include urgency_level
      page,
      page_size: pageSize,

      ...(key && { key }),
      ...(status && { status }),
      ...(hierarchy && { hierarchy }),
      ...(start_date && { start_date }),
      ...(isDownload ? { download: "true" } : {}),
      ...(username && { username }),
      ...(fl_name && { fl_name }),
    };

    const queryString = new URLSearchParams(queryParams).toString();

    try {
      const response = await axiosInstance.get(
        `task/v2/task-accept-reject/?${queryString}`
      );

      if (response.status === 200) {
        return { success: true, data: response.data };
      }

      return { success: false };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },

  // ✅ Accept Order Function
  acceptOrder: async (taskId,data) => {
    try {
      const response = await axiosInstance.post(
        `task/v2/task-accept/${taskId}/`,
        data
      )
      if (response.status === 200) {
        return { success: true, data: response.data };
      }

      return { success: false, message: "Failed to accept order" };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  requireTaskDetails: async (taskId, details) => {
    try {
      const formData = new FormData();
      formData.append("details", details);

      const response = await axiosInstance.post(
        `task/v2/require-task-details/${taskId}/`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (response.status === 200) {
        return { success: true, data: response.data };
      }

      return { success: false, message: "Failed to post task details" };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
  // ✅ Reject Order Function
  rejectOrder: async (taskId, body) => {
    try {
      const response = await axiosInstance.post(
        `task/v2/task-reject/${taskId}/`,
        body
      );

      if (response.status === 200) {
        return { success: true, data: response.data };
      }

      return { success: false, message: "Failed to reject order" };
    } catch (error) {
      await catchError(error);
      return { success: false };
    }
  },
};

export default AcceptRejectOrdersService;
