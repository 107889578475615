import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Upload,
  DatePicker,
  Row,
  Col,
  Typography,
  Select,
  Switch,
  Tabs,
  Empty,
  Flex,
} from "antd";
import dayjs from "dayjs";
import {
  PaperClipOutlined,
  DeleteOutlined,
  UserOutlined,
  FileTextOutlined,
  TagOutlined,
} from "@ant-design/icons";
import ReactQuill from "react-quill";
import orderService from "../../services/OrderLists";
import "react-quill/dist/quill.snow.css";
import "./OrderListTable.css";
import AddPaymentForm from "./AddPayment";

const { Option } = Select;
const { TabPane } = Tabs;

const AddOrderForm = ({
  visible,
  onClose,
  formData,
  handleDescriptionChange,
  description,
  fileList,
  setFormData,
  dropdownData,
  setFileList,
  setDescription,
  setDropdownData,
  setSelectedRadio,
  selectedRadio,
  handleFileChange,
  handleRadioChange,
  handleTimezoneChange,
}) => {
  const [form] = Form.useForm();
  const { Dragger } = Upload;
  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      timezone: "Asia/Kolkata", // Default timezone
      // quality_needed: "Good Quality", // Default quality
    }));
    // setSelectedRadio("Good Quality"); // Default selected radio value for quality
  }, []);
  // Set default selected quality to "Good Quality"
  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      timezone: "Asia/Kolkata",
    }));

    // Set default quality selection
    if (dropdownData.qualities && dropdownData.qualities.length > 0) {
      const firstQuality = dropdownData.qualities[0];
      setSelectedRadio(firstQuality.key);
      setFormData((prev) => ({
        ...prev,
        quality_needed: firstQuality.key,
      }));
    }
  }, [dropdownData.qualities]); // Depend on qualities data

  const getFileIcon = (fileName) => {
    const extension = fileName?.split(".").pop().toLowerCase();

    switch (extension) {
      case "pdf":
        return "https://i.ibb.co/Xzcwvy6/images-q-tbn-ANd9-Gc-TGAA4-Wd4bco5-Xv33-Gas-Xrn-Dd-QT5-OFXwa3-HUQ-s.png";
      case "doc":
      case "docx":
        return "https://i.ibb.co/mXGsc7g/images-q-tbn-ANd9-Gc-Rs3n-Wc9jmd-M7-Wm-Dc-EAy3-PC2z-Mur7-A1ee-bw-s.png";
      case "jpg":
      case "jpeg":
        return "https://i.ibb.co/NC2DbKN/images-q-tbn-ANd9-Gc-Qgd-Crtwnin-NU1-Nxzpu-IP6-F9h-Kkpfv-SSgr7cw-s.png";
      case "png":
        return "https://i.ibb.co/mtLjYdr/2048px-pptx-icon-2019-svg.png";
      case "zip":
        return "https://i.ibb.co/WV6wJv4/zip-file-line-icon-vector.jpg";
      default:
        return "https://i.ibb.co/NC2DbKN/images-q-tbn-ANd9-Gc-Qgd-Crtwnin-NU1-Nxzpu-IP6-F9h-Kkpfv-SSgr7cw-s.png";
    }
  };


  const handleDeleteFile = (index) => {
    const updatedFileList = [...fileList];
    updatedFileList.splice(index, 1); // Remove the file at the specified index
    setFileList(updatedFileList); // Update the state with the new file list

    // Update formData.files to remove the corresponding file
    const updatedFiles = [...formData.files];
    updatedFiles.splice(index, 1); // Remove the file at the specified index
    setFormData((prevData) => ({ ...prevData, files: updatedFiles })); // Update files in formData
  };

  const getFormattedFileName = (fileName) => {
    const extensionIndex = fileName.lastIndexOf(".");
    const baseName =
      extensionIndex !== -1 ? fileName.substring(0, extensionIndex) : fileName;
    const extension =
      extensionIndex !== -1 ? fileName.substring(extensionIndex) : "";

    if (baseName.length > 14) {
      return {
        displayName: `${baseName.substring(0, 14)}...${extension}`,
        fullName: fileName,
      };
    }
    return {
      displayName: fileName,
      fullName: fileName,
    };
  };
  return (
    <Form form={form} layout="vertical" encType="multipart/form-data">
      <Row gutter={16}>
        <Col sm={24}>
          <Form.Item label="Title" required>
            <Input
              placeholder="Type Title here"
              onChange={(e) =>
                setFormData({ ...formData, title: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24}>
          <Form.Item label="Description" required>
            <ReactQuill
              value={description}
              onChange={handleDescriptionChange}
              style={{ height: "150px" }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: "2rem" }}>
        <Col xs={24} sm={12} md={12}>
          <Form.Item label="Client's Username">
            <Input
              placeholder="Client's username"
              suffix={
                <UserOutlined
                  color="gray"
                  style={{ color: "rgba(0, 0, 0, 0.25)" }}
                />
              } // Add the user icon as a prefix
              onChange={(e) =>
                setFormData({
                  ...formData,
                  client_username: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12}>
          <Form.Item label="Client's Word Count">
            <Input
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              pattern="\d*"
              placeholder="Word count"
              suffix={
                <FileTextOutlined style={{ color: "rgba(0, 0, 0, 0.25)" }} />
              } // Add the document icon as a prefix
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  // Regex to allow only numbers
                  setFormData({
                    ...formData,
                    client_words: value,
                  });
                }
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item label="Client Deadline" required>
            <DatePicker
              disabledDate={(current) =>
                current && current < dayjs().startOf("day")
              }
              style={{ width: "375px" }}
              showTime
              format="YYYY-MM-DD HH:mm A"
              onChange={(date) =>
                setFormData({ ...formData, client_deadline: date })
              }
            />
          </Form.Item>
        </Col>

        <Col xs={24} md={12}>
          <Form.Item label="Timezone">
            <Select
              defaultValue="Asia/Kolkata" // Set default value here
              placeholder="Select timezone"
              onChange={handleTimezoneChange}>
           
              {dropdownData.timezones.map((timezone) => (
                <Option key={timezone} value={timezone}>
                  {timezone}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <label>Files</label>
        <div className="file-parent">
          <div className="file-container">
            {fileList.length === 0 ? (
              <Flex
                vertical
                justify="center"
                style={{ width: "100%", minHeight: "150px" }}
                className="file-preview-container">
                <Dragger
                  style={{ width: "100%", background: "none", border: "none" }}
                  showUploadList={false} // Hide Ant Design's automatic file list
                  beforeUpload={() => false} // Prevent automatic upload
                  onChange={handleFileChange} // Handle file selection
                  fileList={fileList} // Set fileList state for controlled uploads
                  multiple // Allow multiple file uploads
                  listType="text" // Display file names below the input
                >
                  <p
                    className="ant-upload-text "
                    style={{ color: "gray", marginTop: "20px" }}>
                    Drag & drop files here or click to upload
                  </p>
                </Dragger>
              </Flex>
            ) : (
              <div
                className="file-preview-container"
                style={{ display: "flex", flexWrap: "wrap" }}>
                {fileList.map((file, index) => {
                  const { displayName, fullName } = getFormattedFileName(
                    file.name
                  );

                  return (
                    <div
                      key={index}
                      className="file-preview-item"
                      style={{
                        margin: "10px",
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #d9d9d9",
                        padding: "2px 4px",
                        borderRadius: "4px",
                      }}>
                      <img
                        style={{
                          height: "40px",
                          width: "40px",
                          marginRight: "8px",
                        }}
                        src={getFileIcon(file.name)}
                        alt="file-icon"
                      />
                      <span title={fullName}>{displayName}</span>
                      <DeleteOutlined
                        style={{ marginLeft: "8px", cursor: "pointer" }}
                        onClick={() => handleDeleteFile(index)} // Call delete function on click
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <Upload
            showUploadList={false} // Hide Ant Design's automatic file list
            beforeUpload={() => false} // Prevent automatic upload
            onChange={handleFileChange}
            fileList={fileList} // Set fileList state for controlled uploads
            multiple // Allow multiple file uploads
            listType="text" // Display file names below the input
          >
            <div
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}>
              <PaperClipOutlined
                style={{ fontSize: "24px", marginRight: "8px" }}
              />
            </div>
          </Upload>
        </div>
      </Row>
      <Row gutter={16} style={{ marginTop: "14px" }}>
        <Col xs={24} sm={12}>
          <Form.Item label="Referencing Style">
            <Select
              placeholder="Select referencing style"
              onChange={(value) =>
                setFormData({ ...formData, referencing_style: value })
              }>
              {dropdownData.references.map((ref) => (
                <Option key={ref.id} value={ref.id}>
                  {ref.style}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item label="Tags">
            <Input
              placeholder="Type your tags here (comma-separated)"
              suffix={<TagOutlined style={{ color: "rgba(0, 0, 0, 0.25)" }} />} // Add the tag icon as a prefix
              onChange={(e) => {
                const inputValue = e.target.value;
                // Convert comma-separated string to array immediately
                const tagsArray = inputValue.includes(",")
                  ? inputValue
                      .split(",")
                      .map((tag) => tag.trim())
                      .filter(Boolean)
                  : inputValue
                  ? [inputValue.trim()]
                  : [];

                setFormData((prev) => ({
                  ...prev,
                  tags: tagsArray,
                }));
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item label="Select BDM">
            <Select
              mode="multiple" // Changed to multiple if you need multiple BDMs
              placeholder="Select BDM"
              onChange={(values) => setFormData({ ...formData, bdms: values })}>
              {dropdownData.bdmOptions.map((bdm) => (
                <Option key={bdm.value} value={bdm.value}>
                  {bdm.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item label="Task Situation">
            <Select
              placeholder="Select task situation"
              onChange={(value) =>
                setFormData({ ...formData, task_situation: value })
              }>
              {dropdownData.situations.map((situation) => (
                <Option key={situation.id} value={situation.id}>
                  {" "}
                  {situation.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Flex vertical gap={"10px"} style={{ position: "relative" }}>
        <label style={{ color: "black", marginLeft: "10px" }} aria-required>
          {"Select Quality"}
        </label>
        <div className="custom-radio-container">
          {dropdownData.qualities.map((option) => (
            <div key={option.id} style={{ marginLeft: "5px" }}>
              <input
                type="radio"
                id={`custom-radio-${option.key}`}
                name="quality"
                value={option.key}
                className="custom-radio-input"
                checked={selectedRadio === option.key}
                onChange={() => handleRadioChange(option.key)}
              />
              <label
                style={{ padding: "3px 2px" }}
                htmlFor={`custom-radio-${option.key}`}
                className="custom-radio-label">
                {option.value}
              </label>
            </div>
          ))}
        </div>
      </Flex>

      <Row
        gutter={8}
        style={{ width: "100%", marginTop: "18px" }}
        align={"center"}
        justify={"center"}
        dir="row">
        {/* <Col xs={24} sm={12}>
      <Form.Item label="Quality">
        <Select
          placeholder="Select quality"
          onChange={(value) =>
            setFormData({ ...formData, quality_needed: value })
          }
        >
          {dropdownData.qualities.map((quality) => (
            <Option key={quality.key} value={quality.key}>
              {quality.value}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Col> */}
        <Col xs={24}>
          <Form.Item label="Specification">
            <Select
            showSearch
              placeholder="Select specification"
              filterOption={(input, option) =>
                (option?.children ?? "").toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) =>
                setFormData({ ...formData, specification: value })
              }>
              {dropdownData.specifications.map((spec) => (
                <Option key={spec.uid} value={spec.uid}>
                  {spec.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default AddOrderForm;
