import {
  HomeOutlined,
  TeamOutlined,
  ShoppingCartOutlined,
  WalletOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import React from "react";
import { useSelector } from "react-redux";

export const useFilteredNavMenu = () => {
  const { user } = useSelector((state) => state.auth); // Access user from auth slice

  // Ensure user and permissions are defined before proceeding
  if (!user) {
    console.log("no permissions");
    return []; // Return an empty array or default menu if user is not available
  }

  const permissions = user.user.permissions;

  const filteredNavMenu = [
    {
      key: "1",
      icon: React.createElement(HomeOutlined),
      label: "Home",
      route: "/",
    },
 
    permissions.includes("can_view_sales_operations") && {
      key: "3",
      icon: React.createElement(SolutionOutlined),
      label: "Sales Operations",
      route: "/sales-operations",
      children: [
        permissions.includes("can_view_my_clients") && {
          key: "3-1",
          label: "Clients",
          route: "/sales-operations/clients",
        },
        // permissions.includes("can_view_client_referral") && {
        //   key: "3-2",
        //   label: "Client Referrals",
        //   route: "/sales-operations/client-referrals",
        // },
        // permissions.includes("can_view_requested_for_agent") && {
        //   key: "3-3",
        //   label: "Requested for Agent",
        //   route: "/sales-operations/requested-for-agent",
        // },
        // permissions.includes("can_view_calling_and_sms") && {
        //   key: "3-4",
        //   label: "Calling And SMS",
        //   route: "/sales-operations/calling-sms",
        // },
        permissions.includes("can_view_sales_and_analytics") && {
          key: "3-5",
          label: "Sales Analytics",
          route: "/sales-operations/sales-analytics",
        },
        permissions.includes("can_view_assigned_leads") && {
          key: "3-6",
          label: "Leads",
          route: "/sales-operations/leads",
        },
      ].filter(Boolean), // Filter out `null` entries
    },
    permissions.includes("can_view_order_management") && {
      key: "4",
      icon: React.createElement(ShoppingCartOutlined),
      label: "Order Management",
      route: "/order-management",
      children: [
        permissions.includes("can_view_order_list") && {
          key: "4-1",
          label: "My Orders",
          route: "/order-management/my-orders",
        },
        // permissions.includes("can_view_order_operations") && {
        //   key: "4-2",
        //   label: "Order Operations",
        //   route: "/order-management/order-operations",
        //   children: [
        //     permissions.includes("can_view_order_list") && {
        //       key: "4-1",
        //       label: "Accept/Reject Orders",
        //       route: "/order-management/my-orders",
        //     },
        //     // {
        //     //   key: "4-2",
        //     //   label: "Call Allocation Orders",
        //     //   route: "/order-management/my-orders",
        //     // },
        //     // {
        //     //   key: "4-3",
        //     //   label: "Moved to FL Associates",
        //     //   route: "/order-management/my-orders",
        //     // },
        //     {
        //       key: "4-4",
        //       label: "Allocation Adjustment Task",
        //       route: "/order-management/my-orders",
        //     },
        //     {
        //       key: "4-5",
        //       label: "FL Orders for Deduction",
        //       route: "/order-management/my-orders",
        //     },
        //   ],
        // },
        // permissions.includes("can_view_order_client_info") && {
        //   key: "4-3",
        //   label: "Order Client Info",
        //   route: "/order-management/order-client-info",
        // },
      ].filter(Boolean), // Filter out `null` entries
    },
    // permissions.includes("can_view_payment_operations") && {
    //   key: "5",
    //   icon: React.createElement(WalletOutlined),
    //   label: "Payment Operations",
    //   route: "/payment-operations",
    //   children: [
    //     permissions.includes("can_view_fl_payouts") && {
    //       key: "5-1",
    //       label: "FL Payouts",
    //       route: "/payment-operations/fl-payouts",
    //     },
    //     permissions.includes("can_view_wallets") && {
    //       key: "5-2",
    //       label: "Wallets",
    //       route: "/payment-operations/wallets",
    //     },
    //   ],
    // },
  ];

  return filteredNavMenu;
};
