import { useSelector } from "react-redux";

const usePermissions = () => {
    const { user } = useSelector((state) => state.auth);
  const permissions = useSelector((state) => state.auth.permissions);
  const hasPermission = (permission) => {
    return user.user.permissions?.includes(permission);

  };

  return { hasPermission };
};

export default usePermissions;
