import React from "react";
import {
  Descriptions,
  Card,
  Button,
  Spin,
  Tag,
  Typography,
  Flex,
  Popover,
  Pagination,
} from "antd";
import { EyeOutlined, PlusOutlined, EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import moment from "moment";

const { Text } = Typography;

const FlDeductionMobileView = ({
  data,
  logs,
  logsLoading,
  handleExpandRow,
  expandedRowKeys,
  handleOpenModal,
  handleEditClick,
  pagination,
  setPagination,
}) => {
  // ✅ Function to render amount deduction properly
  const renderAmountDeduction = (amount_deduction, record) => {
    if (!amount_deduction) return "-";

    const { total_amount_deduction, amount_deduction_list } = amount_deduction;

    return (
      <Flex align="center" gap={0}>
        <Text strong>
          {total_amount_deduction} {record?.currency}
          <Popover
            content={
              <div>
                <Text type="secondary">Breakup:</Text>
                <ul style={{ paddingLeft: "20px" }}>
                  {amount_deduction_list?.map((amount, index) => (
                    <li key={index} style={{ fontSize: "12px" }}>
                      {amount} INR
                    </li>
                  ))}
                </ul>
              </div>
            }
            title="Amount Deduction Details">
            <small
              style={{ cursor: "pointer", color: "#1890ff", marginLeft: 10 }}>
              View Breakup
            </small>
          </Popover>
        </Text>
      </Flex>
    );
  };

  return (
    <div>
      {data.map((record) => (
        <Card
          key={record.uuid}
          style={{ marginBottom: 16 }}
          actions={[
            <EyeOutlined
              key="eye"
              onClick={() =>
                handleExpandRow(expandedRowKeys.includes(record.uuid), record)
              }
              style={{ fontSize: 18, color: "#1890ff" }}
            />,
            <PlusOutlined
              key="add"
              onClick={() => handleOpenModal(record)}
              style={{ fontSize: 18, color: "#52c41a" }}
            />,
          ]}>
          <Descriptions column={1} bordered size="small">
            {Object.keys(record).map((key) =>
              key !== "uuid" && key !== "currency" ? (
                <Descriptions.Item
                  key={key}
                  label={key
                    .replace(/_/g, " ")
                    .replace(/^\w/, (c) => c.toUpperCase())} // ✅ Capitalize first letter
                >
                  {record[key] === null || record[key] === "" ? (
                    "-" // ✅ Render "-" if value is null or empty
                  ) : key === "completion_date" ? (
                    moment(record[key]).local().format("DD-MM-YYYY HH:mm a")
                  ) : key === "payment_status" ? (
                    <Flex align="center" gap={8}>
                      <Tag
                        color={
                          record[key] === "Paid"
                            ? "green"
                            : record[key] === "Pending"
                            ? "orange"
                            : "red"
                        }>
                        {record[key]}
                      </Tag>
                      <Button
                        type="text"
                        icon={<EditOutlined style={{ color: "#3F83F8" }} />}
                        onClick={() => handleEditClick(record.uuid)} // ✅ Pass UUID to edit function
                      />
                    </Flex>
                  ) : key === "key" ? (
                    <Link
                      to={`/order-management/my-orders/${record[key]}`}
                      style={{ color: "#1890ff", fontWeight: "bold" }}>
                      {record[key]}
                    </Link>
                  ) : key === "amount_deduction" ? (
                    renderAmountDeduction(record[key]) // ✅ Re-added `renderAmountDeduction`
                  ) : typeof record[key] === "object" ? (
                    JSON.stringify(record[key])
                  ) : (
                    record[key]
                  )}
                </Descriptions.Item>
              ) : null
            )}
          </Descriptions>

          {expandedRowKeys.includes(record.uuid) && (
            <div
              style={{
                padding: "10px 20px",
                background: "#f5f5f5",
                marginTop: 10,
              }}>
              {logsLoading[record.uuid] ? (
                <Spin />
              ) : Array.isArray(logs[record.uuid]) &&
                logs[record.uuid].length > 0 ? (
                logs[record.uuid].map((log, index) => (
                  <div key={index} style={{ marginBottom: 20 }}>
                    {" "}
                    {/* ✅ Force separation */}
                    <Descriptions
                      column={1}
                      bordered
                      style={{
                        borderRadius: "8px",
                      }}>
                      {Object.entries(log).map(([key, value]) => (
                        <Descriptions.Item
                          key={key}
                          label={key
                            .replace(/_/g, " ")
                            .toLowerCase()
                            .replace(/^\w/, (c) => c.toUpperCase())}>
                          {key.includes("date")
                            ? moment(value).format("DD-MM-YYYY HH:mm a")
                            : value}
                        </Descriptions.Item>
                      ))}
                    </Descriptions>
                  </div>
                ))
              ) : (
                <Text>No logs available</Text>
              )}
            </div>
          )}
        </Card>
      ))}
      <Pagination
        current={pagination.current}
        pageSize={pagination.pageSize}
        total={pagination.total}
        onChange={(page, pageSize) =>
          setPagination((prev) => ({ ...prev, current: page, pageSize }))
        }
        style={{ textAlign: "center", marginTop: 16 }}
      />
    </div>
  );
};

export default FlDeductionMobileView;
