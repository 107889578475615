import React, { useState, useEffect } from "react";
import {
  Button,
  Layout,
  Typography,
  Input,
  Spin,
  Divider,
  Pagination,
  Flex,
  Badge,
  Popover,
  List,
  Tag,
  Row,
  Col,
  Tooltip,
} from "antd";
import {
  selectNotificationsData,
  selectTotalUnreadCount,
} from "../../redux/slices/notificationsSlice";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  SearchOutlined,
  BellOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./layout.module.css";
import {
  toggleSidebarCollapsed,
  toggleSidebarDrawer,
} from "../../redux/slices/uiSlice";
import getPageTitle from "../../routes/routeTitles";
import { routeTitles } from "../../routes/routeTitles";
import axiosInstance from "../../services/axiosInstance";
import SearchDescriptionsList from "../SearchDescriptionsList";
import { useMediaQuery } from "react-responsive";

const { Header } = Layout;
const { Title } = Typography;

const HeaderContent = ({ isAuthenticated }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const notificationCount = 5;
  // State
  const [loading, setLoading] = useState(false);
  const [searched, setSearched] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResult, setSearchResult] = useState({ clients: [], tasks: [] });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const totalUnreadCount = useSelector(selectTotalUnreadCount);
  const notificationsData = useSelector(selectNotificationsData);
  const notificationOrder = [
    "urgent",
    "high_priority",
    "feedbacks",
    "resolved",
    "info",
  ];

  // Sort notificationsData based on the defined order
  const sortedNotificationsData = notificationsData.slice().sort((a, b) => {
    return (
      notificationOrder.indexOf(a.level) - notificationOrder.indexOf(b.level)
    );
  });
  // Verify state values
  // useEffect(() => {
  //   console.log("Total Unread Count:", totalUnreadCount); // Log total unread count
  //   console.log("Notifications Data:", notificationsData); // Log notifications data
  // }, [totalUnreadCount, notificationsData]);

  // Get notifications data from Redux
  // Get total unread count from Redux
  // const notifications = useSelector(selectNotificationsData);
  // Redux state
  const { collapsed, drawerOpen } = useSelector((state) => ({
    collapsed: state.ui.collapsed,
    drawerOpen: state.ui.drawerOpen,
  }));

  const currentPath = location.pathname;
  const pageTitle = getPageTitle(currentPath, routeTitles);
  const [popoverVisible, setPopoverVisible] = useState(false); // State to control Popover visibility
  // Reset search when navigating away from search page
  useEffect(() => {
    if (!currentPath.includes("search=true")) {
      setSearchQuery("");
      setSearchResult({ clients: [], tasks: [] });
      setSearched(false);
      setLoading(false);
    }
  }, [currentPath]);

  // Cleanup loading state when component unmounts
  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  const handleSearch = async (page = 1, pageSize = 10) => {
    if (!searchQuery.trim()) return;

    setLoading(true);
    setSearched(true);

    const validPage = Number.isInteger(page) && page > 0 ? page : 1;
    const validPageSize =
      Number.isInteger(pageSize) && pageSize > 0 ? pageSize : 10;

    // Navigate to search page
    navigate(
      `/search=true?query=${encodeURIComponent(
        searchQuery
      )}&page=${validPage}&pageSize=${validPageSize}`
    );

    try {
      const response = await axiosInstance.get(
        `/task/v2/global-search/?search=${encodeURIComponent(
          searchQuery
        )}&page=${validPage}&pageSize=${validPageSize}`
      );

      if (response?.data?.data) {
        const { data } = response.data;
        setSearchResult(data);

        // Update pagination based on tasks count
        setPagination((prev) => ({
          ...prev,
          current: validPage,
          pageSize: validPageSize,
          total: data.tasks?.count || 0,
        }));
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
      setSearchResult({ clients: [], tasks: [] });
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page, pageSize) => {
    handleSearch(page, pageSize);
  };

  if (!isAuthenticated) return null;
  const handleNotificationClick = (item) => {
    setPopoverVisible(false);
    // Directly pass the level as is without formatting
    navigate(`/notifications?level=${encodeURIComponent(item.level)}`);

  };

  const renderSearchResults = () => {
    if (!searched) return null;

    return (
      <>
        {loading ? (
          <Flex style={{ width: "100%" }} align="center" justify="center">
            <Spin size="large" />
          </Flex>
        ) : (
          <div
            style={{
              maxHeight: "100%",
              overflowY: "scroll",

              background: "#f2f6f9 ",

              // minHeight: "30%",
              padding: 14,
            }}>
            {/* Clients Section */}
            {Array.isArray(searchResult?.clients) &&
              searchResult.clients.length > 0 && (
                <>
                  <Title level={4}>Clients</Title>
                  <SearchDescriptionsList
                    items={searchResult.clients}
                    type="client"
                  />
                  <Divider />
                </>
              )}

            {/* Tasks Section */}
            {Array.isArray(searchResult?.tasks?.results) &&
              searchResult.tasks.results.length > 0 && (
                <>
                  <Title level={4}>Orders</Title>
                  <SearchDescriptionsList
                    items={searchResult.tasks.results}
                    type="task"
                  />
                  <Pagination
                    current={pagination.current}
                    pageSize={pagination.pageSize}
                    total={pagination.total}
                    onChange={handlePageChange}
                    className={styles.pagination}
                  />
                </>
              )}

            {/* No Results Message */}
            {searched &&
              !loading &&
              !searchResult?.clients?.length &&
              !searchResult?.tasks?.results?.length && (
                <p className={styles.noResults}>
                  No data found for your search.
                </p>
              )}
          </div>
        )}
      </>
    );
  };
  // Notification Popover content
  const formatLevel = (level) => {
    // Replace underscores with spaces and capitalize the first letter of each word
    return level
      .split("_")
      .map((word, index) => {
        if (index === 0) {
          return word.charAt(0).toUpperCase() + word.slice(1); // Capitalize the first word
        }
        return word.charAt(0).toUpperCase() + word.slice(1); // Capitalize subsequent words
      })
      .join(" "); // Join them back together
  };

  const notificationPopoverTitle = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}>
      <span>Notifications</span>
      <Tooltip title="Click a tab to view notifications">
        <InfoCircleOutlined
          style={{ marginLeft: 8, cursor: "pointer", color: "#555" }}
        />
      </Tooltip>
    </div>
  );
  const notificationContent = (
    <div>
      {sortedNotificationsData.map((item, index) => {
        // Define color mapping for levels
        const levelColorMapping = {
          urgent: "#f87171", // red
          high_priority: "#fdba74", // orange
          feedbacks: "#93c5fd", // blue
          resolved: "#d8b4fe", // purple
          info: "#cbd5e1", // gray
        };

        // Get the color for the current level, default to gray if not found
        const tagColor = levelColorMapping[item.level] || "#cbd5e1";

        return (
          <Flex vertical gap={4}>
            <Button
              onClick={() => handleNotificationClick(item)}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                background: tagColor,

                padding: "2px 8px",
                // minWidth: isMobile ? "170px" : "230px",
                borderRadius: "4px",
                marginTop: 4,
                cursor: "pointer",
                border: "transparent",
              }}>
              {/* <div
              key={index}
              style={{
                backgroundColor: tagColor,
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "10px",
              }}> */}
              <div
                style={{
                  display: "flex",
                  // flexDirection: "column",
                  // gap: "10px",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}>
                <div>{formatLevel(item.level)}</div>
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    color: "#333",
                  }}>
                  {item.count}
                </div>
              </div>
              {/* </div> */}
            </Button>
          </Flex>
          // <List.Item
          //   key={index}
          //   style={{
          //     padding: 8,
          //     cursor: "pointer",
          //     // borderBottom: "1px solid #ddd",
          //   }}
          //   onClick={() => handleNotificationClick(item)}>
          //   <Tag color={tagColor}>
          //     {`${formatLevel(item.level)} - ${item.count}`}
          //   </Tag>
          // </List.Item>
        );
      })}
    </div>
  );

  return (
    <>
      <Header className={styles.global_header}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => dispatch(toggleSidebarCollapsed())}
            className={styles.header_btn}
          />
          <Button
            type="text"
            icon={drawerOpen ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => dispatch(toggleSidebarDrawer())}
            className={styles.mobile_toggle}
          />
          {/* <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => dispatch({ type: "TOGGLE_SIDEBAR" })}
            className={styles.header_btn}
          /> */}
          <Title level={2} style={{ margin: 0 }}>
            {pageTitle}
          </Title>
        </div>

        <Flex align="center" gap={10}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Popover
              style={{ maxWidth: 70 }}
              content={notificationContent}
              title={notificationPopoverTitle}
              trigger="click"
              placement="bottomRight"
              visible={popoverVisible} // Controlled visibility
              onVisibleChange={(visible) => setPopoverVisible(visible)}>
              <Badge
                count={totalUnreadCount}
                offset={isMobile ? [-12, 6] : [-12, 6]}>
                <Button
                  type="text"
                  icon={<BellOutlined style={{ fontSize: 20 }} />}
                  // className={styles.header_btn}
                />
              </Badge>
            </Popover>
          </div>
          {/* <div style={{ display: "flex", alignItems: "center"  ,padding:'10px'}}>
        <Badge count={notificationCount} offset={[-20, 20]}>
          <Button
            type="text"
            onClick={handleNotificationClick}
            icon={<BellOutlined style={{ fontSize: 20 }} />}
            className={styles.header_btn}
          />
        </Badge>
      </div> */}
          <Input.Search
            placeholder="Search..."
            onSearch={() => handleSearch(1, pagination.pageSize)}
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
            style={{ width: isMobile ? 100 : 300, marginRight: 16 }}
            enterButton={
              <Button type="primary" style={{ backgroundColor: "#040724" }}>
                <SearchOutlined />
              </Button>
            }
          />
        </Flex>
      </Header>

      {renderSearchResults()}
    </>
  );
};

export default HeaderContent;
