import React, { useState, useEffect } from "react";
import {
  Table,
  Spin,
  message,
  Typography,
  Popover,
  Button,
  Modal,
  Form,
  Input,
  Tag,
  Flex,
  Tooltip,
} from "antd";
import FlDeductionMobileView from "../../page-components/FL Order Deduction/FlDeductionMobileView";
import {
  PlusOutlined,
  EyeOutlined,
  FilterOutlined,
  EditOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import moment from "moment";
import FlOrderDeductionsService from "../../services/flOrderDeductionsService";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import FlOrderDeductionsFilter from "../../page-components/FL Order Deduction/FlOrderDeductionFilter";
const { Text, Paragraph, Title } = Typography;

const FlOrderDeductions = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const [filtersList, setFiltersList] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [logs, setLogs] = useState({});
  const [logsLoading, setLogsLoading] = useState({});
  const [expanded, setExpanded] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [form] = Form.useForm();

  // Fetch order deductions
  const fetchDeductions = async () => {
    setLoading(true);
    try {
      const response = await FlOrderDeductionsService.flOrderDeductions(
        pagination.current,
        pagination.pageSize,
        filters
      );
      if (response.success) {
        setFiltersList(response.data.data.filters);
        setData(response.data.data.results || []);
        setPagination((prev) => ({
          ...prev,
          total: response.data.data.count || 0,
        }));
      } else {
        message.error("Failed to fetch order deductions.");
      }
    } catch (error) {
      message.error("Error fetching order deductions.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDeductions(pagination.current, pagination.pageSize, filters);
  }, [pagination.current, pagination.pageSize, filters]);
  const updatePaymentStatus = async (uuid) => {
    try {
      const response = await FlOrderDeductionsService.updatePaymentStatus(uuid);
      if (response.success) {
        message.success(response?.data?.data);
        await fetchDeductions();
      }
    } catch (error) {
      message.error("Failed to update payment status.");
    }
  };

  const handleEditClick = (uuid) => {
    Modal.confirm({
      title: "Confirm Payment Status Update",
      content: "Are you sure you want to update the payment status?",
      okText: "Yes",
      cancelText: "No",
      onOk: () => updatePaymentStatus(uuid), // ✅ Now passing UUID instead of status
    });
  };

  // Fetch logs dynamically when a row expands
  const fetchDeductionLogs = async (uuid) => {
    setLogsLoading((prev) => ({ ...prev, [uuid]: true }));
    try {
      const response = await FlOrderDeductionsService.getDeductionLogs(uuid);
      if (response.success) {
        setLogs((prev) => ({ ...prev, [uuid]: response.data.data || [] }));
      } else {
        message.error("Failed to fetch logs.");
      }
    } catch (error) {
      message.error("Error fetching logs.");
    } finally {
      setLogsLoading((prev) => ({ ...prev, [uuid]: false }));
    }
  };
  const handleExpandRow = (expanded, record) => {
    if (isMobile) {
      setExpandedRowKeys(
        (prevExpandedKeys) =>
          prevExpandedKeys.includes(record.uuid)
            ? prevExpandedKeys.filter((key) => key !== record.uuid) // Collapse if already expanded
            : [...prevExpandedKeys, record.uuid] // Expand if not already expanded
      );

      if (!expandedRowKeys.includes(record.uuid)) {
        fetchDeductionLogs(record.uuid);
      }
    } else {
      if (expanded) {
        setExpandedRowKeys([record.uuid]); // ✅ Keep only the newly expanded row
        fetchDeductionLogs(record.uuid);
      } else {
        setExpandedRowKeys([]); // ✅ Collapse all if clicking the same row
      }
    }
  };

  //   // Expand row and fetch logs
  //   const handleExpandRow = (expanded, record) => {
  //     console.log(record , 'record')
  //     if (expanded) {
  //       setExpandedRowKeys([record.uuid]); // Expand only one row at a time
  //       fetchDeductionLogs(record.uuid);
  //     } else {
  //       setExpandedRowKeys([]);
  //     }
  //   };
  // Open modal

  const handleDownload = async () => {
    await FlOrderDeductionsService.flOrderDeductions(
      pagination.current,
      pagination.pageSize,
      filters,
      true // ✅ Pass `true` to enable file download
    );
  };

  const handleOpenModal = (record) => {
    console.log("Opening Modal with Record:", record);
    setSelectedRecord(record);
    setIsModalOpen(true);
  
    // Wait until modal opens before setting values
    setTimeout(() => {
      form.setFieldsValue({
        currency: record?.currency,
      });
    }, 0);
  };
  

//   const handleOpenModal = (record) => {
//     setSelectedRecord(record);
//     form.setFieldsValue({
//       currency: record?.currency, // Ensure currency exists
//     });
//     setIsModalOpen(true);
//     form.resetFields();
//   };

  // Close modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedRecord(null);
  };

  // Format column titles (capitalize first letter)
  // Format column names
  const formatColumnTitle = (key) =>
    key
      .replace(/_/g, " ")
      .toLowerCase()
      .replace(/^\w/, (c) => c.toUpperCase());

  // Render amount_deduction
  const renderAssignedFlAmount = (assigned_fl_amount, record) => {
    return (
      <Text style={{ padding: 0, margin: 0 }} strong>
        {assigned_fl_amount} {record?.currency}
      </Text>
    );
  };
  const renderAmountDeduction = (amount_deduction, record) => {
    if (!amount_deduction) return "-";

    const { total_amount_deduction, amount_deduction_list } = amount_deduction;

    return (
      <div style={{ padding: 0 }}>
        <Text style={{ padding: 0, margin: 0 }} strong>
          {total_amount_deduction} {record?.currency}
        </Text>
        {amount_deduction.amount_deduction_list.length > 0.0 && (
          <Popover
            content={
              <div>
                <ul
                  style={{
                    display: "flex",
                    gap: "8px",
                    listStyle: "none",
                    padding: 0,
                    margin: 0,
                  }}>
                  {amount_deduction_list?.map((amount, index) => (
                    <li
                      key={index}
                      style={{
                        fontSize: "12px",
                        padding: "2px 6px",
                        background: "#f0f0f0",
                        borderRadius: "4px",
                      }}>
                      {amount}
                    </li>
                  ))}
                </ul>
              </div>
            }
            title="Amount Deduction Details">
            <div>
              <Text
                type="secondary"
                style={{
                  fontSize: "12px",
                  cursor: "pointer",
                  color: "#1890ff",
                }}>
                View Breakup
              </Text>
            </div>
          </Popover>
        )}
      </div>
    );
  };

  const renderKeyAsLink = (keyValue) => (
    <Link
      to={`/order-management/my-orders/${keyValue}`}
      style={{ color: "#1890ff", fontWeight: "bold" }}>
      {keyValue}
    </Link>
  );
  const renderPaymentStatus = (status, record) => {
    const statusColors = {
      Paid: "green",
      Unpaid: "red",
      Pending: "orange",
    };

    return (
      <Flex align="center" style={{ padding: 0, margin: 0 }}>
        <Tag color={statusColors[status] || "default"}>{status}</Tag>
        <Button
          type="text"
          icon={<EditOutlined style={{ color: "#3F83F8" }} />}
          onClick={() => handleEditClick(record.uuid)} // ✅ Pass record.uuid instead of status
        />
      </Flex>
    );
  };

  const renderWordCountDeduction = (word_count_deduction) => (
    <Text type="secondary">{word_count_deduction ?? "N/A"}</Text>
  );

  const renderCompletionDate = (completion_date) => (
    <Text type="secondary">
      {moment(completion_date).local().format("DD-MM-YYYY hh:mm a")}
    </Text>
  );

  // Render fl_name with eye icon
  const renderFreelancerName = (name, record) => (
    <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
      <Tooltip title="View Deduction Logs">
        <EyeOutlined
          style={{ cursor: "pointer", color: "#1890ff" }}
          onClick={() =>
            handleExpandRow(!expandedRowKeys.includes(record.uuid), record)
          }
        />
      </Tooltip>
      <Text style={{ fontSize: 13 }}>{name}</Text>
    </div>
  );

  // Generate columns dynamically, excluding 'uuid'
  const columns =
    data.length > 0
      ? Object.keys(data[0])
          .filter((key) => key !== "uuid" && key !== "currency")
          .map((key) => ({
            title: formatColumnTitle(key),
            dataIndex: key,
            key: key,
            width:
              key === "fl_name" ? 180 : key === "completion_date" ? 200 : 140, // Set column widths

            render:
              key === "amount_deduction"
                ? renderAmountDeduction
                : key === "payment_status"
                ? renderPaymentStatus
                : key === "word_count_deduction"
                ? renderWordCountDeduction
                : key === "completion_date"
                ? renderCompletionDate
                : key === "fl_name"
                ? renderFreelancerName
                : key === "key"
                ? renderKeyAsLink // Show as link for "key" field
                : key === "assigned_fl_amount"
                ? renderAssignedFlAmount
                : undefined,
          }))
      : [];

  // Add "Add Deduction" column
  columns.push({
    title: "Add Deduction",
    key: "add_deduction",
    render: (_, record) => (
      <Button
        type="primary"
        style={{ background: "#3F83F8", borderRadius: "50%" }}
        icon={<PlusOutlined style={{ fontWeight: 600 }} />}
        onClick={() => handleOpenModal(record)}
      />
    ),
  });

  // Handle deduction submission
  const handleAddDeduction = async (values) => {
    if (!selectedRecord) return;

    try {
      await FlOrderDeductionsService.addDeduction(selectedRecord.uuid, values);
      message.success("Deduction added successfully");

      setIsModalOpen(false);
      await fetchDeductions();
    } catch (error) {
      message.error("Failed to add deduction");
    }
  };

  // Generate log table columns dynamically
  const getLogColumns = (logData) => {
    if (!logData || logData.length === 0) return [];

    return Object.keys(logData[0]).map((key) => ({
      title: formatColumnTitle(key),
      dataIndex: key,
      key: key,

      render: (text) => {
        if (text === null || text === undefined || text === "") {
          return "N/A"; // ✅ Show "N/A" if value is null or empty
        }

        if (key === "deduction_date") {
          return moment(text).local().format("DD-MM-YYYY HH:mm a");
        }
        if (key === "remarks") {
          const words = text.split(" ");
          const isLong = words.length > 10;

          return (
            <div style={{ maxWidth: "280px", wordBreak: "break-word" }}>
              <Text type="secondary">
                {expanded
                  ? text
                  : words.slice(0, 10).join(" ") + (isLong ? "..." : "")}
              </Text>
              {isLong && (
                <Button
                  type="link"
                  style={{ padding: 0, marginLeft: 5 }}
                  onClick={() => setExpanded(!expanded)}>
                  {expanded ? "Show Less" : "Show More"}
                </Button>
              )}
            </div>
          );
        }
        return text; // ✅ Return normal value if not null
      },
    }));
  };

  const handleSearchClick = (newFilters) => {
    setFilters(newFilters);
    setPagination((prev) => ({ ...prev, current: 1 }));
  };

  const handleCancelSearch = () => {
    setFilters({});
    setPagination((prev) => ({ ...prev, current: 1 }));
  };

  return (
    <Flex vertical gap={"20px"} style={{ padding: 10 }}>
      {showFilters && (
        <div className="filter-container">
          <FlOrderDeductionsFilter
            filtersList={filtersList}
            onSearch={handleSearchClick}
            onCancel={handleCancelSearch}
          />
        </div>
      )}
      {!loading && (
        <Flex
          gap={isMobile ? 8 : 0}
          style={{ width: "100%", flexDirection: isMobile ? "column" : "row" }}
          justify="space-between"
          align={isMobile ? "flex-end" : "center"}>
          <Paragraph
            style={{
              fontWeight: 500,
              opacity: 0.6,
              textAlign: "left",
              margin: 0,
              padding: 0,
            }}>
            Showing{" "}
            {Math.min(
              (pagination.current - 1) * pagination.pageSize + 1,
              pagination.total
            )}{" "}
            -{" "}
            {Math.min(
              pagination.current * pagination.pageSize,
              pagination.total
            )}{" "}
            of {pagination.total} Deductions
          </Paragraph>
          <Flex gap={4} align="center">
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              onClick={handleDownload} // ✅ Triggers download only
              style={{ marginLeft: "10px", background: "#040724" }}>
              Download Sheet
            </Button>

            <Button
              type="primary"
              icon={<FilterOutlined />}
              onClick={() => setShowFilters(!showFilters)}
              style={{ marginLeft: "10px", background: "#040724" }}
            />
          </Flex>
          {/* <Flex gap={4} align="center">
            <Select
              defaultValue="myDeductions"
              style={{ width: 200, textAlign: "left" }}
              onChange={setSelectedOption}>
              <Option value="myDeductions">My Deductions</Option>
              <Option value="allDeductions">All Deductions</Option>
            </Select>
            <Button
              type="primary"
              icon={<FilterOutlined />}
              onClick={() => setShowFilters(!showFilters)}
              style={{ marginLeft: "10px", background: "#040724" }}
            />
          </Flex> */}
        </Flex>
      )}
      <Spin spinning={loading}>
        {isMobile ? (
          <FlDeductionMobileView
            data={data}
            logs={logs}
            logsLoading={logsLoading}
            handleExpandRow={handleExpandRow}
            expandedRowKeys={expandedRowKeys}
            handleOpenModal={handleOpenModal}
            pagination={pagination}
            setPagination={setPagination}
            handleEditClick={handleEditClick}
          />
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            rowKey={(record) => record.uuid}
            expandedRowKeys={expandedRowKeys}
            onExpand={handleExpandRow}
            expandable={{
              expandedRowRender: (record) => (
                <div style={{ padding: "10px 20px", background: "#f5f5f5" }}>
                  {logsLoading[record.uuid] ? (
                    <Spin />
                  ) : Array.isArray(logs[record.uuid]) &&
                    logs[record.uuid]?.length ? (
                    <Table
                      columns={getLogColumns(logs[record.uuid])}
                      dataSource={logs[record.uuid]}
                      rowKey={(log) => log.id}
                      pagination={false}
                      size="small"
                    />
                  ) : (
                    <Text>No logs available</Text>
                  )}
                </div>
              ),
              expandIconColumnIndex: -1,
            }}
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              total: pagination.total,
              onChange: (page, pageSize) => {
                setPagination((prev) => ({ ...prev, current: page, pageSize }));
              },
            }}
          />
        )}
      </Spin>

      {/* Add Deduction Modal */}
      <Modal
        className="custom-modal2"
        title={
          <div
            className="custom-modal-header"
            style={{ display: "flex", justifyContent: "space-between" }}>
            <Title
              level={3}
              style={{
                color: "white",
                margin: 0,
                position: "relative",
                marginLeft: "10px",
              }}>
              Add Deduction
            </Title>
          </div>
        }
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onOk={() => form.submit()}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleAddDeduction}
          onValuesChange={() =>
            form.validateFields(["deduction_amount", "deduction_word_count"])
          }>
          <Form.Item
            label="Deduction Amount"
            name="deduction_amount"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value || getFieldValue("deduction_word_count")) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "Either Deduction Amount or Deduction Word Count is required."
                    )
                  );
                },
              }),
            ]}>
            <Input type="number" placeholder="Enter deduction amount" />
          </Form.Item>
          <Form.Item label="Currency" name="currency" style={{color:'black'}}>
            <Input disabled />
          </Form.Item>

          <Form.Item
            label="Deduction Word Count"
            name="deduction_word_count"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value || getFieldValue("deduction_amount")) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "Either Deduction Amount or Deduction Word Count is required."
                    )
                  );
                },
              }),
            ]}>
            <Input type="number" placeholder="Enter word count" />
          </Form.Item>

          <Form.Item label="Remarks" name="remarks">
            <Input.TextArea rows={3} placeholder="Enter remarks" />
          </Form.Item>
        </Form>
      </Modal>
    </Flex>
  );
};

export default FlOrderDeductions;
