import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    totalUnreadCount: 0, // Default value
    notificationsData: [],  // Initialize as an empty array
    loading: false,
    error: null,
  };
  
  const notificationsSlice = createSlice({
    name: "notifications",
    initialState, // Ensure initial state is set correctly
    reducers: {
      fetchUnreadCountStart(state) {
        console.log("fetchUnreadCountStart called");
        state.loading = true;
        state.error = null;
      },
      fetchUnreadCountSuccess(state, action) {

        state.loading = false;
        state.totalUnreadCount = action.payload.totalUnreadCount;
        state.notificationsData = action.payload.notificationsData; // Save notification data
  
    
      },
      fetchUnreadCountFailure(state, action) {
  
        state.loading = false;
        state.error = action.payload;
      },
    },
  });
  
  export const {
    fetchUnreadCountStart,
    fetchUnreadCountSuccess,
    fetchUnreadCountFailure,
  } = notificationsSlice.actions;
  
  export const selectTotalUnreadCount = (state) => state.notifications?.totalUnreadCount || 0;  // Safely access state
  export const selectNotificationsData = (state) => state.notifications?.notificationsData || []; // Safely access notificationsData
  
  export default notificationsSlice.reducer;
  