// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  .filter-container {
    overflow: hidden;
  }

  .filter-enter {
    max-height: 0;
    opacity: 0;
  }

  .filter-enter-active {
    max-height: 500px; /* Adjust based on your filter's maximum height */
    opacity: 1;
    transition: max-height 300ms ease-in, opacity 300ms ease-in;
  }

  .filter-exit {
    max-height: 500px;
    opacity: 1;
  }

  .filter-exit-active {
    max-height: 0;
    opacity: 0;
    transition: max-height 300ms ease-out, opacity 300ms ease-out;
  }
`, "",{"version":3,"sources":["webpack://./src/page-components/All Clients/ClientParent.css"],"names":[],"mappings":";EACE;IACE,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,UAAU;EACZ;;EAEA;IACE,iBAAiB,EAAE,iDAAiD;IACpE,UAAU;IACV,2DAA2D;EAC7D;;EAEA;IACE,iBAAiB;IACjB,UAAU;EACZ;;EAEA;IACE,aAAa;IACb,UAAU;IACV,6DAA6D;EAC/D","sourcesContent":["\n  .filter-container {\n    overflow: hidden;\n  }\n\n  .filter-enter {\n    max-height: 0;\n    opacity: 0;\n  }\n\n  .filter-enter-active {\n    max-height: 500px; /* Adjust based on your filter's maximum height */\n    opacity: 1;\n    transition: max-height 300ms ease-in, opacity 300ms ease-in;\n  }\n\n  .filter-exit {\n    max-height: 500px;\n    opacity: 1;\n  }\n\n  .filter-exit-active {\n    max-height: 0;\n    opacity: 0;\n    transition: max-height 300ms ease-out, opacity 300ms ease-out;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
