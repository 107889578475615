import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Select,
  Button,
  Flex,
  Spin,
  message,
} from "antd";
import { FileTextOutlined, TagOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import orderService from "../../services/OrderLists";
import "react-quill/dist/quill.snow.css";
import TextArea from "antd/es/input/TextArea";

const { Option } = Select;

const EditTask = ({ orderData, fetchOrderDetails, handleModalClose }) => {
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const [selectedRadio, setSelectedRadio] = useState(null);
  const [isFormSubmitiing, setIsFormSubmtting] = useState(false);
  const [dropdownData, setDropdownData] = useState({
    qualities: [],
    status: [],
    references: [],
  });

  const [formData, setFormData] = useState({
    title: orderData.data.title,
    description: orderData.data.description,
    writer_words: orderData.data.writer_word_count || 0, // Default to 0 if undefined
    tags: orderData.data.tags || [], // Default to empty array if undefined
    referencing_style: orderData.data.referencing_style || null, // Default to null
    quality_needed: orderData.data.quality
      ? parseInt(orderData.data.quality, 10)
      : null, // Ensure it's an integer or null
    status: orderData.data.status ? parseInt(orderData.data.status, 10) : null, // Ensure it's an integer or null
    change_reason: "",
  });

  const handleSubmit = async () => {
    const taskKey = orderData.data.key; // Get the taskKey from orderData

    // Create a new object to hold updated data
    const updatedData = { ...formData, taskKey };

    setIsFormSubmtting(true);

    // Match initial values to dropdown data keys
    const initialQualityKey = Object.entries(dropdownData.qualities).find(
      ([key, value]) => value === formData.quality_needed
    )?.[0]; // Get the key for quality needed

    const initialStatusKey = Object.entries(dropdownData.status).find(
      ([key, value]) => value === formData.status
    )?.[0]; // Get the key for status

    const initialReferencingStyleId = dropdownData.references.find(
      (ref) => ref.style === formData.referencing_style
    )?.id; // Get the ID for referencing style

    console.log(
      (updatedData.quality_needed = initialQualityKey
        ? parseInt(initialQualityKey, 10)
        : 1), // Default to Good Quality
      (updatedData.status = initialStatusKey
        ? parseInt(initialStatusKey, 10)
        : 1), // Default to Unassigned
      (updatedData.referencing_style = initialReferencingStyleId || 1) // Default to first referencing style
    );
    // Update updatedData with found keys or IDs, or default values

    try {
      const response = await orderService.editTask(taskKey, updatedData);
      if (response.success) {
        message.success("Task Edited Successfully");
        fetchOrderDetails();
        handleModalClose();
      }
    } catch (error) {
      setIsFormSubmtting(false);
    } finally {
      setIsFormSubmtting(false);
    }
  };

  const handleRadioChange = (value) => {
    setSelectedRadio(value);
    setFormData((prevData) => ({
      ...prevData,
      quality_needed: parseInt(value, 10),
    })); // Convert to integer
  };

  useEffect(() => {
    const fetchDropdownData = async () => {
      setLoading(true);
      try {
        const qualityData = await orderService.getQualitiesDropdown();
        const statusData = await orderService.getStatusList();
        const referenceData = await orderService.getReferenceDropdown();
        const qualities = Object.entries(qualityData.data).map(
          ([key, value]) => ({
            key,
            value,
          })
        );

        const status = Object.entries(statusData.data.data).map(
          ([key, value]) => ({
            key: parseInt(key, 10),
            value: String(value),
          })
        );

        // Find the key for the quality in orderData
        const initialQualityKey = qualities.find(
          (option) => option.value === orderData.data.quality
        )?.key;

        // Set the initial selected radio based on the key
        if (initialQualityKey) {
          setSelectedRadio(initialQualityKey);
        }

        setDropdownData({
          qualities,
          status,
          references: referenceData.data,
        });
      } catch (error) {
        console.error("Error fetching dropdown data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDropdownData();
  }, [orderData.data]);

  const handleDescriptionChange = (value) => {
    setFormData((prevData) => ({ ...prevData, description: value }));
  };

  return (
    <>
      {loading ? (
        <Flex justify="center" style={{ width: "100%" }}>
          <Spin />
        </Flex>
      ) : (
        <Form
          style={{ width: "100%" }}
          form={form}
          layout="vertical"
          encType="multipart/form-data"
        >
          <Row gutter={16} style={{ width: "100%" }}>
            <Col sm={24}>
              <Form.Item label="Title" required>
                <Input
                  placeholder="Type Title here"
                  value={formData.title}
                  onChange={(e) =>
                    setFormData({ ...formData, title: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} style={{ width: "100%" }}>
            <Col xs={24}>
              <Form.Item label="Description" required>
                <ReactQuill
                  value={formData.description}
                  onChange={handleDescriptionChange}
                  style={{ height: "150px" }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginTop: "2rem", width: "100%" }}>
            <Col xs={24} sm={12} md={8}>
              <Form.Item label="Client's Word Count">
                <Input
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  pattern="\d*"
                  placeholder="Word count"
                  value={formData.writer_words}
                  suffix={
                    <FileTextOutlined
                      style={{ color: "rgba(0, 0, 0, 0.25)" }}
                    />
                  }
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      setFormData({
                        ...formData,
                        writer_words: value,
                      });
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item label="Referencing Style">
                <Select
                  placeholder="Select referencing style"
                  onChange={(value) => {
                    setFormData({ ...formData, referencing_style: value }); // Store the selected ID
                  }}
                  value={formData.referencing_style}
                >
                  {dropdownData.references.map((ref) => (
                    <Option key={ref.id} value={ref.id}>
                      {ref.style}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item label="Status">
                <Select
                  placeholder="Select Status"
                  onChange={(value) => {
                    setFormData({ ...formData, status: value }); // Store the selected ID
                  }}
                  value={orderData.data.status}
                >
                  {dropdownData.status.map((statusItem) => (
                    <Option key={statusItem.key} value={statusItem.key}>
                      {statusItem.value}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginTop: "14px", width: "100%" }}>
            <Col xs={24} sm={24}>
              <Form.Item label="Tags">
                <Input
                  placeholder="Type your tags here (comma-separated)"
                  suffix={
                    <TagOutlined style={{ color: "rgba(0, 0, 0, 0.25)" }} />
                  }
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const tagsArray = inputValue.includes(",")
                      ? inputValue
                          .split(",")
                          .map((tag) => tag.trim())
                          .filter(Boolean)
                      : inputValue
                      ? [inputValue.trim()]
                      : [];

                    setFormData((prev) => ({
                      ...prev,
                      tags: tagsArray,
                    }));
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <div style={{ margin: "20px 0" , position: 'relative', left: '-10px'}}>
            <label style={{ color: "black", marginLeft: "10px" }} aria-required>
              Select Quality
            </label>
            <div className="custom-radio-container">
              {dropdownData.qualities.map((option) => (
                <div key={option.key} style={{  marginTop: "10px"  , position: 'relative', left: '10px'}}>
                  <input
                    type="radio"
                    id={`custom-radio-${option.key}`}
                    name="quality"
                    value={option.key}
                    className="custom-radio-input"
                    checked={selectedRadio === option.key}
                    onChange={() => handleRadioChange(option.key)} // Store the selected ID
                  />
                  <label
                    style={{ padding: "3px 2px" }}
                    htmlFor={`custom-radio-${option.key}`}
                    className="custom-radio-label"
                  >
                    {option.value}
                  </label>
                </div>
              ))}
            </div>
          </div>

          <Row gutter={16} style={{ marginTop: "18px", width: "100%" }}>
            <Col sm={24}>
              <Form.Item label="Reason" required>
                <Input.TextArea
                  placeholder="Enter the reason"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      change_reason: e.target.value,
                    })
                  }
                  rows={4} // Adjust the number of rows as needed
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Form.Item>
              <Button
                onClick={handleSubmit}
                type="primary"
                htmlType="submit"
                loading={isFormSubmitiing} // Show loading state on submit
              >
                Submit
              </Button>
            </Form.Item>
          </Row>
        </Form>
      )}
    </>
  );
};

export default EditTask;
