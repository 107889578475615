import React from "react";
import "./App.css";
import { Provider } from "react-redux";
import store, { persistor } from "./redux/store";
import { ConfigProvider } from "antd";
import { customTheme } from "./customTheme";
import { PersistGate } from "redux-persist/integration/react";
import { MainLayout } from "./components/Layout/MainLayout";

const App = () => {
  return (
    <ConfigProvider theme={customTheme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <MainLayout />
        </PersistGate>
      </Provider>
    </ConfigProvider>
  );
};

export default App;
