import { Flex } from 'antd'
import React from 'react'
import AcceptRejectOrdersMain from '../../page-components/Accept Reject Orders/AcceptRejectOrdersMain'

function AcceptRejectOrdersParent() {
  return (


    <AcceptRejectOrdersMain />

  )
}

export default AcceptRejectOrdersParent