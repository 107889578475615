// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ant-checkbox-group{
    row-gap: 20px!important;
}

.custom-modal-header {
    color: white;
    background-color: #040724;
    padding: 10px 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin: 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
  .create-order .ant-modal-content {
    padding: 0 0px 13px 0 !important;
    border-radius: 12px;
  }
  
  .create-order .ant-modal-body {
    padding: 16px;
  }
  
  .create-order .ant-modal-footer {
    padding: 10px 20px;
  }
  
  .ccreate-order .ant-btn-primary {
    background-color: #040724 !important;
  }
  
  .create-order .ant-btn-disabled:hover {
    background-color: #040724 !important;
  }`, "",{"version":3,"sources":["webpack://./src/page-components/OrderDetail/addCooment.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,iBAAiB;IACjB,2BAA2B;IAC3B,4BAA4B;IAC5B,SAAS;IACT,aAAa;IACb,WAAW;IACX,mBAAmB;IACnB,8BAA8B;EAChC;EACA;IACE,gCAAgC;IAChC,mBAAmB;EACrB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,oCAAoC;EACtC;;EAEA;IACE,oCAAoC;EACtC","sourcesContent":["ant-checkbox-group{\n    row-gap: 20px!important;\n}\n\n.custom-modal-header {\n    color: white;\n    background-color: #040724;\n    padding: 10px 0px;\n    border-top-left-radius: 8px;\n    border-top-right-radius: 8px;\n    margin: 0;\n    display: flex;\n    width: 100%;\n    align-items: center;\n    justify-content: space-between;\n  }\n  .create-order .ant-modal-content {\n    padding: 0 0px 13px 0 !important;\n    border-radius: 12px;\n  }\n  \n  .create-order .ant-modal-body {\n    padding: 16px;\n  }\n  \n  .create-order .ant-modal-footer {\n    padding: 10px 20px;\n  }\n  \n  .ccreate-order .ant-btn-primary {\n    background-color: #040724 !important;\n  }\n  \n  .create-order .ant-btn-disabled:hover {\n    background-color: #040724 !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
